import React, { useState } from 'react'
import i18n from 'i18n-js';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    List,
    ListItem as MuiListItem,
    Typography as MuiTypography,
} from "@material-ui/core";

import BillingPersonGroup from './BillingPersonGroup';

// UI Consts
const Typography = styled(MuiTypography)(spacing);

const ListItem = styled(MuiListItem)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 15px 0 15px 0;

    &:last-child {
        border: none;
    }
`;

const PurchaseGroupMonth = props => {
    const { group, company } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <ListItem>
            <Box display="flex" flex={1} flexDirection="column">
                <Box display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsExpanded(prevValue => !prevValue)}>
                    <Typography variant="body2">{group.heading}</Typography>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h4">{i18n.toCurrency(group.total, { precision: 2, unit: '$' })}</Typography>
                    </Box>
                </Box>
                {isExpanded &&
                    <List>
                        {group.groupedByPeople.map((group, index) => {
                            return (
                                <BillingPersonGroup 
                                    key={group.key} 
                                    group={group} 
                                    company={company} 
                                    displayedUser={group.person}/>
                            )
                        })}
                    </List>
                }
            </Box>
        </ListItem>
    )
}

export default PurchaseGroupMonth;