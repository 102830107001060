export const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export  const getRTEValue = (value) => {
    if (value) {
        if (isJsonString(value))
            return JSON.parse(value);
    }
    return [
        {
            type: "paragraph",
            children: [
                { text: value ? value : "" },
            ]
        },
    ];
}
