import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from "formik"
import * as Yup from "yup";
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    FormGroup,
    FormControlLabel as MuiFormControlLabel,
    Grid,
    IconButton as MuiIconButton,
    InputAdornment,
    MenuItem,
    Popover as MuiPopover,
    Select,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypogrpahy,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoHelpCircleOutline as HelpIcon,
} from "react-icons/io5";

// svxvy import

import { addProduct, editProduct } from '../../../store/actions/productActions';
import StyledDialog from '../../framework/StyledDialog';
import SelectFormControl from "../../framework/SelectFormControl";
import ActionButton from "../../framework/ActionButton";
import ErrorDialog from '../../framework/ErrorDialog';
import UploadPhoto from '../photos/UploadPhoto';
import SelectUser from '../../company/SelectUser';

// constants

const IconButton = styled(MuiIconButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypogrpahy)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

const Popover = styled(MuiPopover)`
.MuiPopover-paper{
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 15px;
}
`;

const TipPopup = styled(Box)`
    width: 100%;
    max-width: 250px;
    background-color: ${(props) => props.theme.palette.background.paper};
    // border: 1px solid red;
    border-radius: 10px;
    padding: 15px;
`;



const validationSchema = Yup.object().shape({
    name: Yup.string()
        .max(250, "Must be 250 characters or less")
        .required("Required"),
    description: Yup.string()
        .nullable()
        .max(750, "Must be 750 characters or less"),
    price: Yup.number()
        .min(0, "Must be greater than $0.00")
        .required("Required"),
    freeTrialDays: Yup.number().integer("Must be a whole number")
        .min(0, "Must be greater than or equal to 0")
        .max(30, "Must be less than or equal to 30")
    ,
    intervalCount: Yup.number().integer("Must be a whole number")
        .min(0, "Must be greater than 0"),
    productSortOrder: Yup.number().integer("Must be a whole number")
        .required(),
    lowBalanceMessage: Yup.mixed().when('sendLowBalanceReminder', {
        is: true,
        then:
            Yup.string()
                .required("Required"),
    })
});

const ManageProductModal = (props) => {
    const { open, setOpen, product, manageProducts, isMakeCopy, isSubscription } = props;
    const theme = useTheme();
    const company = useSelector(state => state.company.company);

    const companyId = useSelector(state => state.company.companyId);
    const people = useSelector(state => state.company.people);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [photo, setPhoto] = useState(product && product.photos ? product.photos[0] : null);
    const [photoCleared, setPhotoCleared] = useState(false);

    const initialValues = {
        name: product ? product.name : "",
        productType: manageProducts ? 'Product' : isSubscription ? 'Membership' : 'Package',
        description: product && product.description ? product.description : "",
        productSortOrder: product && product.productSortOrder ? product.productSortOrder : "0",
        messageOnPurchase: product && product.messageOnPurchase ? product.messageOnPurchase : "",
        sendAsEmail: product ? product.sendAsEmail : false,
        terms: product && product.terms ? product.terms : "",
        price: product ? product.unitAmount : 0.00,
        oneTimeOnlyPurchase: product ? product.oneTimeOnlyPurchase : false,
        units: product ? product.units : 0,
        interval: product ? product.recurringInterval : "month",
        intervalCount: product ? product.recurringIntervalCount : 1,
        isHidden: product ? product.isHidden : false,
        autoBillPackage: product ? product.autoBillPackage : false,
        externalUrl: product ? product.externalUrl : "",
        sendLowBalanceReminder: product ? product.sendLowBalanceReminder : false,
        lowBalanceMessage: product ? product.lowBalanceMessage : "",
        allowedUsers: product && product.allowedUsers ? people.filter(x => product.allowedUsers.filter(y => y.appUserId === x.id).length > 0) : [],
        freeTrialDays: product && product.freeTrialDays !=null ? product.freeTrialDays + "" : "",
        autoCancelAfterFreeTrial: product ? product.autoCancelAfterFreeTrial : false,
        companyId: companyId,
    };

    const handleClose = useCallback((message) => {
        if (message) {
            window.alert(message);
        }
        setIsSubmitting(false);
        setOpen(false);
        setPhoto(null);
        setPhotoCleared(false);
        setError(null);
    }, [setOpen])

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (values, resetForm, companyId, product, changeStatus) => {
        setError(null);
        setIsSubmitting(true);
        let message = null;
        try {
            if (isMakeCopy)
                await dispatch(addProduct(values, companyId));
            else if (!product)
                await dispatch(addProduct(values, companyId));
            else if (!changeStatus) {
                const updatedProduct = await dispatch(editProduct(values, product.id, product.isActive, companyId));
                message = updatedProduct.messageToReturn;
            }
            // else
            //     await dispatch(setActiveStatus(values, product.id, companyId));
            if (resetForm)
                resetForm();
            handleClose(message);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch, handleClose, isMakeCopy]);

    const handleSubmit = (values, { resetForm }) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.price = parseFloat(valuesToSend.price);
        valuesToSend.units = valuesToSend.units ? parseInt(valuesToSend.units) : 0;
        valuesToSend.productSortOrder = valuesToSend.productSortOrder ? parseInt(valuesToSend.productSortOrder) : 0;
        valuesToSend.freeTrialDays = valuesToSend.freeTrialDays && valuesToSend.freeTrialDays.trim() !== "" ? parseInt(valuesToSend.freeTrialDays) : 0;
        valuesToSend.intervalCount = valuesToSend.intervalCount ? parseInt(valuesToSend.intervalCount) : 0;
        valuesToSend.photo = photo && product && product.photos && product.photos.length > 0 && product.photos[0].id === photo.id ? null : photo;
        valuesToSend.removeCurrentImage = photoCleared;

        if (valuesToSend.productType === 'Package' || valuesToSend.productType === 'Product')
            valuesToSend.interval = null;

        if (product && valuesToSend.interval && valuesToSend.price !== product.unitAmount) {
            const r = window.confirm('Changes to the price will update all active memberships to this new price at the start of the next billing cycle.\n\n This update may take a few minutes please do not leave this screen.');
            if (!r) {
                return false;
            }
        }

        submitCallback(valuesToSend, resetForm, companyId, product, false);
    };

    return (
        <StyledDialog
            open={open}
            setOpen={handleClose}
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        form="productform"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        my={3}
                        submitting={isSubmitting}
                    >
                        Save
                    </ActionButton>
                </React.Fragment>
            }
        >
            <Box display="flex" flexDirection="column">
                <Typography variant="h1" component="h1" mb={3}>
                    {product ? 'Edit ' + (product.recurringInterval ? 'Membership' : product.units > 0 ? 'Package' : 'Product') : manageProducts ? 'Add Product' : isSubscription ? 'Add Membership' : 'Add Package'}
                </Typography>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setSubmitting,
                        touched,
                        values,
                        status,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            <form id="productform" onSubmit={handleSubmit}>
                                {(values.productType !== '' || product) &&
                                    <React.Fragment>
                                        <Box display="flex"
                                            style={{ borderTop: '1px solid ' + theme.palette.divider }}
                                            pt={3}
                                        >
                                            {(values.productType === 'Membership' || (product && product.isRecurring)) &&
                                                <React.Fragment>
                                                    <SelectFormControl
                                                        labelText={'Repeats'} labelId="select-interval-label"
                                                        style={{ minWidth: 100 }}
                                                    >
                                                        <Select
                                                            labelId="select-interval-label"
                                                            id="interval"
                                                            value={values.interval}
                                                            label={"Repeats"} /// both this line and Input lavel
                                                            onChange={(event) => setFieldValue('interval', event.target.value)}
                                                            disabled={product && !isMakeCopy ? true : false}
                                                        >
                                                            {intevalOptions.map((option) => (
                                                                <MenuItem
                                                                    key={option.id}
                                                                    value={option.id}
                                                                >
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </SelectFormControl>
                                                    <TextField
                                                        name="intervalCount"
                                                        label="Every"
                                                        value={values.intervalCount}
                                                        error={Boolean(touched.intervalCount && errors.intervalCount)}
                                                        helperText={touched.intervalCount && errors.intervalCount}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        mx={2}
                                                        variant="outlined"
                                                        placeholder=""
                                                        disabled={product && !isMakeCopy ? true : false}
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment
                                                                    position="end">{values.interval + (values.intervalCount > 1 ? 's' : '')}
                                                                </InputAdornment>
                                                        }}
                                                    />

                                                </React.Fragment>
                                            }
                                            {(values.productType === 'Package' || (product && product.units > 0)) &&
                                                <TextField
                                                    name="units"
                                                    label={ACTIVITIES_TEXT}
                                                    value={values.units}
                                                    error={Boolean(touched.units && errors.units)}
                                                    helperText={touched.units && errors.units}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    placeholder=""
                                                    style={{ margin: '0 15px 15px 0' }}
                                                />
                                            }
                                            <TextField
                                                name="price"
                                                label="Price"
                                                value={values.price}
                                                error={Boolean(touched.price && errors.price)}
                                                helperText={touched.price && errors.price}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                placeholder=""
                                                // disabled={product && isSubscription && !isMakeCopy ? true : false}
                                                style={{ margin: '0 15px 15px 0' }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }} />
                                            {((values.productType === 'Package' || values.productType === 'Product') || (product && !product.isRecurring)) &&
                                                <Box ml={2} mt={2}>
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='oneTimeOnlyPurchase' component={Switch} onChange={(val) => setFieldValue('oneTimeOnlyPurchase', !values.oneTimeOnlyPurchase)} checked={values.oneTimeOnlyPurchase} />
                                                        } label={'Limit to 1 purchase per ' + CUSTOMER_TEXT} />
                                                    </FormGroup>
                                                </Box>
                                            }
                                        </Box>
                                        {(values.productType === 'Membership' || (product && product.isRecurring)) &&
                                            <Box 
                                                display="flex" 
                                                flexDirection="row"
                                                alignItems="center" 
                                                my={2} 
                                                >
                                                <TextField
                                                    name="freeTrialDays"
                                                    label={"Free Trial"}
                                                    value={values.freeTrialDays}
                                                    error={Boolean(touched.freeTrialDays && errors.freeTrialDays)}
                                                    helperText={touched.freeTrialDays && errors.freeTrialDays}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment
                                                                position="end">day(s)
                                                            </InputAdornment>
                                                    }}
                                                    placeholder=""
                                                    mr={5}
                                                    />
                                                {/* {values.freeTrialDays && values.freeTrialDays > 0 &&
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='autoCancelAfterFreeTrial' component={Switch} onChange={(val) => setFieldValue('autoCancelAfterFreeTrial', !values.autoCancelAfterFreeTrial)} checked={values.autoCancelAfterFreeTrial} />
                                                        } label={'Auto-Cancel at end of Free Trial'} />
                                                    </FormGroup>
                                                } */}

                                            </Box>
                                        }
                                        <Box style={{ borderTop: '1px solid ' + theme.palette.divider }} pt={3}>

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="name"
                                                        label={values.productType + " Name"}
                                                        value={values.name}
                                                        error={Boolean(touched.name && errors.name)}
                                                        fullWidth
                                                        helperText={touched.name && errors.name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        mb={3}
                                                        placeholder="" />
                                                    <TextField
                                                        name="description"
                                                        label="Description (optional)"
                                                        value={values.description}
                                                        error={Boolean(touched.description && errors.description)}
                                                        fullWidth
                                                        helperText={touched.description && errors.description}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        multiline
                                                        maxRows={10}
                                                        mb={3}
                                                        placeholder="" />
                                                    <TextField
                                                        name="productSortOrder"
                                                        label={"Sort Order - " + (!company.manuallySortProducts ? 'NOT ACTIVE -> To use this field turn on manual sort order under Manage -> Company Settings' : 'To turn on default sorting by price go to Manage -> Company Settings')}
                                                        value={values.productSortOrder}
                                                        error={Boolean(touched.productSortOrder && errors.productSortOrder)}
                                                        fullWidth
                                                        helperText={touched.productSortOrder && errors.productSortOrder}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        maxRows={10}
                                                        mb={3}
                                                        placeholder="" />
                                                    <Box ml={2} mr={2} mb={4}>
                                                        <UploadPhoto
                                                            id='heroImage'
                                                            image={photo}
                                                            square
                                                            onSuccess={(image) => {
                                                                setPhotoCleared(true);
                                                                setPhoto(image)
                                                            }}
                                                            removePhoto={() => { setPhotoCleared(true); setPhoto(null) }}
                                                        />
                                                    </Box>
                                                    {values.productType === 'Package' &&
                                                        <React.Fragment>
                                                            <Box ml={2} mb={2}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={
                                                                        <Field name='autoBillPackage' component={Switch} onChange={(val) => setFieldValue('autoBillPackage', !values.autoBillPackage)} checked={values.autoBillPackage} />
                                                                    } label={'When processing payments automatically deduct from this package.'} />
                                                                </FormGroup>
                                                            </Box>
                                                            <Box ml={2} mb={2}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={
                                                                        <Field name='sendLowBalanceReminder' component={Switch} onChange={(val) => setFieldValue('sendLowBalanceReminder', !values.sendLowBalanceReminder)} checked={values.sendLowBalanceReminder} />
                                                                    } label={'Send email when package balance has 1 use remaining.'} />
                                                                </FormGroup>
                                                                {values.sendLowBalanceReminder &&
                                                                    <TextField
                                                                        name="lowBalanceMessage"
                                                                        label="Low Balance Message"
                                                                        value={values.lowBalanceMessage}
                                                                        error={Boolean(touched.lowBalanceMessage && errors.lowBalanceMessage)}
                                                                        fullWidth
                                                                        helperText={touched.lowBalanceMessage && errors.lowBalanceMessage}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        variant="outlined"
                                                                        multiline
                                                                        maxRows={10}
                                                                        mt={2}
                                                                        placeholder="" />
                                                                }

                                                            </Box>
                                                        </React.Fragment>


                                                    }
                                                    {values.productType === 'Product' &&
                                                        <TextField
                                                            name="externalUrl"
                                                            label={"Web address to purchase (optional)"}
                                                            value={values.externalUrl}
                                                            error={Boolean(touched.externalUrl && errors.externalUrl)}
                                                            fullWidth
                                                            helperText={touched.externalUrl && errors.externalUrl}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            variant="outlined"
                                                            mb={3}
                                                            placeholder=""
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                                            {(popupState) => (
                                                                                <React.Fragment>
                                                                                    <IconButton aria-label="Public Help" size="medium" color='primary' ml={2} {...bindTrigger(popupState)}>
                                                                                        <HelpIcon fontSize="inherit" />
                                                                                    </IconButton>
                                                                                    <Popover
                                                                                        {...bindPopover(popupState)}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'center',
                                                                                        }}
                                                                                    >
                                                                                        <TipPopup>
                                                                                            <Typography>
                                                                                                Entering a URL here allows you to send users to another website to purchase this item.
                                                                                            </Typography>
                                                                                        </TipPopup>
                                                                                    </Popover>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </PopupState>
                                                                    </InputAdornment>
                                                                ),
                                                            }}

                                                        />
                                                    }
                                                    <TextField
                                                        name="terms"
                                                        label="Terms & Conditions (optional)"
                                                        value={values.terms}
                                                        error={Boolean(touched.terms && errors.terms)}
                                                        fullWidth
                                                        helperText={touched.terms && errors.terms}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        multiline
                                                        maxRows={15}
                                                        mb={3}
                                                        placeholder="" />
                                                    {(!values.externalUrl || values.externalUrl.length === 0) &&
                                                        <React.Fragment>
                                                            <TextField
                                                                name="messageOnPurchase"
                                                                label="Show After Purchase (optional)"
                                                                value={values.messageOnPurchase}
                                                                error={Boolean(touched.messageOnPurchase && errors.messageOnPurchase)}
                                                                fullWidth
                                                                helperText={touched.messageOnPurchase && errors.messageOnPurchaseterms}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                variant="outlined"
                                                                multiline={true}
                                                                maxRows={10}
                                                                mb={3}
                                                                placeholder="" />


                                                            <Box mb={2}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={
                                                                        <Field name='sendAsEmail' component={Switch} onChange={(val) => setFieldValue('sendAsEmail', !values.sendAsEmail)} checked={values.sendAsEmail} />
                                                                    } label={'Send \'Show After Purchase\' message as an email to purchaser'} />
                                                                </FormGroup>
                                                            </Box>
                                                        </React.Fragment>
                                                    }

                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='isHidden' component={Switch} onChange={(val) => setFieldValue('isHidden', !values.isHidden)} checked={values.isHidden} />
                                                        } label={'Only show this product to the users listed below.'} />
                                                    </FormGroup>
                                                    {values.isHidden &&
                                                        <SelectUser selectText={"Select People"}
                                                            scrollSelectedPeople initialValue={values.allowedUsers}
                                                            onChange={(val) => setFieldValue('allowedUsers', val)}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                            {error &&
                                                <ErrorDialog message={error} setMessage={setError} />
                                            }
                                        </Box>
                                    </React.Fragment>
                                }

                            </form>
                        </React.Fragment>
                    )
                    }
                </Formik >
            </Box>
        </StyledDialog>
    );
}

const intevalOptions = [
    {
        id: "day",
        name: 'Daily',
        returnName: 'days',
    }, {
        id: "week",
        name: 'Weekly',
        returnName: 'weeks',
    }, {
        id: "month",
        name: 'Monthly',
        returnName: 'months',
    },
]

export default ManageProductModal;