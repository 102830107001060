import React from "react";
import { useSelector } from 'react-redux';

import { Helmet } from "react-helmet-async";
import { withTheme } from "styled-components/macro";
import { Box } from "@material-ui/core";

// svxvy import
// import ActivityCalendar from '../../../components/company/activities/ActivityCalendar';
import ActivityList from "../../../components/company/activities/ActivityList";
import NonExpandedActivitiesList from '../../../components/company/activities/NonExpandedActivitiesList';

// constants

const SchedulePage = (props) => {
  const company = useSelector(state => state.company.company);

  return (
    <React.Fragment>
      <Helmet title="Schedule" />
      {/* <ActivityCalendar /> */}
      <Box mb={6}>
        {!company.isRABSite && <ActivityList hideTitle onlyPublic/>}
        {company.isRABSite && <NonExpandedActivitiesList hideTitle onlyPublic />}
      </Box>
    </React.Fragment>
  );
};

export default withTheme(SchedulePage);
