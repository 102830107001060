import React from 'react';
import { useSelector } from 'react-redux';


// UI Imports
import { Helmet } from 'react-helmet-async';

// svxvy imports
import ActivityList from '../../components/company/activities/ActivityList';
import NonExpandedActivitiesList from '../../components/company/activities/NonExpandedActivitiesList';


// UI Consts



const ActivitiesPage = props => {
  const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
  const company = useSelector(state => state.company.company);

  return (
    <React.Fragment>
      <Helmet title={ACTIVITIES_TEXT} />
      {!company.isRABSite && <ActivityList />}
      {company.isRABSite && <NonExpandedActivitiesList />}
    </React.Fragment>
  );
}

export default ActivitiesPage;
