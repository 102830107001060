import Payment from '../../models/Payment';
import PaymentMethod from '../../models/PaymentMethod';
import Purchase from '../../models/Purchase';

import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse, convertNumbersToStringsInObject } from '../../helpers/apiFunctions';
import { detailPerson } from './peopleActions';
import { refreshLogedInUser } from './authActions';

export const SET_PAYMENT_RECORDS = 'SET_PAYMENT_RECORDS';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const SET_AS_DEFAULT_PAYMENT_METHOD = 'SET_AS_DEFAULT_PAYMENT_METHOD';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_PURCHASES = 'SET_PURCHASES';

export const fetchPaymentRecords = (companyId, isBackground) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let call = NetworkConstants.SERVER + '/api/payment/' + companyId;
        let response = null;
        try {
            response = await fetch(call, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        } catch (ex) {
            const errorResData = await response.json();
            if (isBackground)
                return;
            else
                throw new Error(getErrorMessagesFromResponse(errorResData));

        }

        const resData = await response.json();
        const payments = [];
        for (const key in resData) {
            payments.push(
                new Payment(
                    resData[key].id,
                    resData[key].appUserId,
                    resData[key].userDisplayName,
                    resData[key].activityId,
                    resData[key].activityName,
                    resData[key].activityDate,
                    resData[key].occuranceId,
                    resData[key].amount,
                    resData[key].paymentDate,
                    resData[key].paymentType,
                    resData[key].paymentStatus,
                    resData[key].comment,
                    resData[key].allowedPackageIds,
                ));
        }
        dispatch({ type: SET_PAYMENT_RECORDS, payments: payments });
    }
}

export const updatePaymentStatus = (requestBody, packageUsed) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/updatepaymentstatus', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                requestBody
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        let payment = null;
        if (resData.userPayment) {
            payment = new Payment(
                resData.userPayment.id,
                resData.userPayment.appUserId,
                resData.userPayment.userDisplayName,
                resData.userPayment.activityId,
                resData.userPayment.activityName,
                resData.userPayment.activityDate,
                resData.userPayment.occuranceId,
                resData.userPayment.amount,
                resData.userPayment.paymentDate,
                resData.userPayment.paymentType,
                resData.userPayment.paymentStatus,
                resData.userPayment.comment,
                resData.userPayment.allowedPackageIds,

            );
        }
        let purchase = null;
        if (resData.purchase) {
            purchase = new Purchase(
                resData.purchase.id,
                resData.purchase.purchasedProduct,
                resData.purchase.datePurchased,
                resData.purchase.startDate,
                resData.purchase.expirationDate,
                resData.purchase.automaticallyRenew,
                resData.purchase.quantity,
                resData.purchase.subscriptionId,
                resData.purchase.invoiceId,
                resData.purchase.paymentChargeId,
                resData.purchase.amountPaid,
                resData.purchase.isTentantPurchase,
                resData.purchase.description,
                resData.purchase.memo,
                resData.purchase.wasMarkedUnpaid,
                resData.purchase.wasRefunded,
                resData.purchase.purchasedProductId,
                resData.purchase.userPayment,
                resData.purchase.nextAmountDue,
                resData.purchase.invoiceUrl,
                resData.purchase.invoicePdf,
                resData.purchase.companyId,
                resData.purchase.appUserId,
                resData.purchase.units,
                resData.purchase.tax,
                resData.purchase.status,
                resData.purchase.totalDiscount
            );
        }
        payment.justUpdated = true;
        if (packageUsed)
            packageUsed.units = packageUsed.units - 1;
        dispatch({ type: UPDATE_PAYMENT_STATUS, payment: payment, purchase: purchase, packageUsed: packageUsed });
    };
}

export const addPaymentMethod = (companyId, userId, paymentMethod, reloadProfile = true) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!userId) {
            throw new Error('User ID is blank.');
        }

        const response = await fetch(NetworkConstants.SERVER + '/api/payment/addPaymentMethod/' + companyId + '/' + userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                convertNumbersToStringsInObject(paymentMethod)
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        await dispatch({ type: ADD_PAYMENT_METHOD, userId: userId, paymentMethod: paymentMethod });
        if (reloadProfile) {
            if (getState().auth.person.id === userId)
                await (dispatch(refreshLogedInUser(getState().auth.token)))
            else
                await dispatch(detailPerson(userId, companyId, true));
        }

    };
}

export const setDefaultPaymentMethod = (paymentMethodId, companyId, userId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!userId) {
            throw new Error('User ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/setDefaultPaymentMethod/' + companyId + '/' + userId + "/" + paymentMethodId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: SET_AS_DEFAULT_PAYMENT_METHOD, userId: userId, paymentMethodId: paymentMethodId, companyId: companyId });
        await dispatch(detailPerson(userId, companyId, true));
        if (getState().auth.person.id === userId)
            await (dispatch(refreshLogedInUser(getState().auth.token)))

    };
}

export const removePaymentMethod = (paymentMethodId, companyId, userId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!userId) {
            throw new Error('User ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/removePaymentMethod/' + companyId + '/' + userId + "/" + paymentMethodId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: REMOVE_PAYMENT_METHOD, userId: userId, paymentMethodId: paymentMethodId });
        await dispatch(detailPerson(userId, companyId, true));
        if (getState().auth.person.id === userId)
            await (dispatch(refreshLogedInUser(getState().auth.token)))
    };
}

export const fetchPaymentMethodsForCurrentUser = (companyId, isBackground = false) => {
    return async (dispatch, getState) => {
        let queryUrl = NetworkConstants.SERVER + '/api/payment/listPaymentMethods';
        if (companyId)
            queryUrl += "/" + companyId;
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            if (isBackground)
                return;
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const paymentMethods = [];
        for (const key in resData) {
            paymentMethods.push(new PaymentMethod(resData[key].id, resData[key].paymentService, resData[key].paymentMethodId, resData[key].lastFourOnCard, resData[key].expirationMonth, resData[key].expirationYear, resData[key].zipCode, resData[key].companyId, resData[key].isDefault, resData[key].isParentCard));
        }

        dispatch({ type: SET_PAYMENT_METHODS, paymentMethods: paymentMethods });
    };
}

export const fetchPurchasesForCurrentUser = (companyId) => {
    return async (dispatch, getState) => {
        let queryUrl = NetworkConstants.SERVER + '/api/user/listPurchases/' + companyId;
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchases = [];
        for (const key in resData) {
            purchases.push(
                new Purchase(resData[key].id,
                    resData[key].purchasedProduct,
                    resData[key].datePurchased,
                    resData[key].startDate,
                    resData[key].expirationDate,
                    resData[key].automaticallyRenew,
                    resData[key].quantity,
                    resData[key].subscriptionId,
                    resData[key].invoiceId,
                    resData[key].paymentChargeId,
                    resData[key].amountPaid,
                    resData[key].isTentantPurchase,
                    resData[key].description,
                    resData[key].memo,
                    resData[key].wasMarkedUnpaid,
                    resData[key].wasRefunded,
                    resData[key].purchasedProductId,
                    resData[key].userPayment,
                    resData[key].nextAmountDue,
                    resData[key].invoiceUrl,
                    resData[key].invoicePdf,
                    resData[key].companyId,
                    resData[key].appUserId,
                    resData[key].units,
                    resData[key].tax,
                    resData[key].status,
                    resData[key].totalDiscount
                ))
        }

        dispatch({ type: SET_PURCHASES, purchases: purchases });
    };
}


export const requestRefund = (requestBody) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company/refundPurchase', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                requestBody
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        let payment = null;
        if (resData.userPayment) {
            payment = new Payment(
                resData.userPayment.id,
                resData.userPayment.appUserId,
                resData.userPayment.userDisplayName,
                resData.userPayment.activityId,
                resData.userPayment.activityName,
                resData.userPayment.activityDate,
                resData.userPayment.occuranceId,
                resData.userPayment.amount,
                resData.userPayment.paymentDate,
                resData.userPayment.paymentType,
                resData.userPayment.paymentStatus,
                resData.userPayment.comment,
                resData.userPayment.allowedPackageIds,

            );
            payment.justUpdated = true;
        }
        const purchase = new Purchase(
            resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );

        if (purchase.appUserId !== getState().auth.person.id)
            await dispatch(detailPerson(purchase.appUserId, purchase.companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));

        dispatch({ type: UPDATE_PAYMENT_STATUS, payment: payment, purchase: purchase });
    };
}

export const revertVoidToUnpaid = (companyId, purchaseId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!purchaseId) {
            throw new Error('Purchase ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/revertVoidToUnpaid/' + companyId + "/" + purchaseId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        let payment = null;

        if (resData.userPayment) {
            payment = new Payment(
                resData.userPayment.id,
                resData.userPayment.appUserId,
                resData.userPayment.userDisplayName,
                resData.userPayment.activityId,
                resData.userPayment.activityName,
                resData.userPayment.activityDate,
                resData.userPayment.occuranceId,
                resData.userPayment.amount,
                resData.userPayment.paymentDate,
                resData.userPayment.paymentType,
                resData.userPayment.paymentStatus,
                resData.userPayment.comment
            );
        }
        const purchase = new Purchase(
            resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        payment.justUpdated = true;
        dispatch({ type: UPDATE_PAYMENT_STATUS, payment: payment, purchase: purchase });
    };
}

export const updateComment = (companyId, purchaseId, comment) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!purchaseId) {
            throw new Error('Purchase ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/editPurchaseComment/' + companyId + "/" + purchaseId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    comment: comment,
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        let payment = null;

        if (resData.userPayment) {
            payment = new Payment(
                resData.userPayment.id,
                resData.userPayment.appUserId,
                resData.userPayment.userDisplayName,
                resData.userPayment.activityId,
                resData.userPayment.activityName,
                resData.userPayment.activityDate,
                resData.userPayment.occuranceId,
                resData.userPayment.amount,
                resData.userPayment.paymentDate,
                resData.userPayment.paymentType,
                resData.userPayment.paymentStatus,
                resData.userPayment.comment
            );
        }
        const purchase = new Purchase(
            resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        payment.justUpdated = true;
        if (purchase.appUserId === getState().auth.person.id)
            dispatch({ type: UPDATE_PAYMENT_STATUS, payment: payment, purchase: purchase });
        else
            await dispatch(detailPerson(purchase.appUserId, companyId, true));
    };
}

export const editPurchasedPackage = (companyId, purchaseId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!purchaseId) {
            throw new Error('Purchase ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/editPurchasedPackage/' + companyId + "/" + purchaseId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(values),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();

        const purchase = new Purchase(
            resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        if (purchase.appUserId === getState().auth.person.id)
            dispatch({ type: UPDATE_PAYMENT_STATUS, purchase: purchase });
        else
            await dispatch(detailPerson(purchase.appUserId, companyId, true));
    };
}

export const deleteUserPayment = (companyId, userPaymentId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!userPaymentId) {
            throw new Error('userPaymentId is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/deleteUserPayment/' + companyId + "/" + userPaymentId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            // body: ,
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        
        await dispatch(fetchPaymentRecords(companyId));
    };
}

