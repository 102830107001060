import { SET_MESSAGES, UPDATE_MESSAGE_READ_STATUS, NEW_SENT_MESSAGES } from '../actions/messageActions';
import { LOGOUT } from '../actions/authActions';
import { cloneDeep } from 'lodash';
// import { addToArray, deleteById } from '../../helpers/arrayFunctions';
// import * as Notifications from 'expo-notifications';


const initialState = {
    messages: [],
    fromMessages: [],
    unreadMessageCount: 0
}

const updateMessageStatus = (idToUpdate, readStatus, messagesArray) => {
    let updatedMessages = cloneDeep(messagesArray);
    let messageToUpdate = updatedMessages.find(item => item.id === idToUpdate);
    if (messageToUpdate) {
        messageToUpdate.read = readStatus;
        messageToUpdate.justUpdated = true;
    }
    return updatedMessages;
}

const messagingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            // Notifications.setBadgeCountAsync(0);
            return { ...initialState };
        case SET_MESSAGES:
            // Notifications.setBadgeCountAsync(action.unreadMessageCount);
            return { ...state, messages: action.messages, fromMessages: action.fromMessages, unreadMessageCount: action.unreadMessageCount };
        case NEW_SENT_MESSAGES:
            return {...state, fromMessages: [...state.fromMessages, ...action.newSentMessages]};
        case UPDATE_MESSAGE_READ_STATUS:
            const updatedMessages = updateMessageStatus(action.messageId, action.readStatus, state.messages);
            const unreadMessages = updatedMessages.filter(x => !x.read).length;
            // Notifications.setBadgeCountAsync(unreadMessages)
            return { ...state, messages: updatedMessages, unreadMessageCount: unreadMessages };
        default:
            return state;
    }
}

export default messagingReducer; 