import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    CircularProgress as MuiCircularProgress,
    Card as MuiCard,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as ExpandIcon,
    IoRemoveCircleOutline as CollapseIcon
} from "react-icons/io5";

// svxvy imports
import { fetchProducts, fetchInactiveProducts } from "../../../store/actions/productActions";
import ManageProductListItem from "./ManageProductListItem";
import ManageProductModal from "./ManageProductModal";

// constants
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const MembershipHeading = styled(Box)`
    padding: 14px 15px 17px 15px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    
    ${(props) => props.theme.breakpoints.up("md")} {

    }
`;

const MembershipItem = styled(Box)`
    padding: 17px 15px 15px 15px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};

    p {
        margin: 0;
    }

    &:last-child {
        border-bottom: none;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 0 0 30px;
        padding: 21px 15px 18px 0;
    }
`;

const ProductList = (props) => {
    const theme = useTheme();
    const { displayedUser, company, products, inactiveProducts } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [isProductManageModalOpen, setIsProductManageModalOpen] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false);

    const dispatch = useDispatch();
    const loadSvxvyProducts = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(fetchProducts(company.id));
            await dispatch(fetchInactiveProducts(company.id));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, company]);

    useEffect(() => {
        loadSvxvyProducts();
    }, [loadSvxvyProducts]);
    return (
        <React.Fragment>
            {error && <Alert severity="error" mb={2}>error</Alert>}
            <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                <MembershipHeading>
                    <Typography variant="h3" component="h3">Active</Typography>
                </MembershipHeading>
                {isLoading && <CircularProgress color="secondary" />}
                {products.map((product, index) => {
                    return (
                        <MembershipItem key={product.id}>
                            <ManageProductListItem key={product.id} product={product} company={props.company} displayedUser={displayedUser} />
                        </MembershipItem>
                    )
                })}
            </Card>
            <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                <MembershipHeading
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    justifyContent="space-between"
                    style={{ padding: '12px 16px 14px 16px', cursor: "pointer" }}
                    onClick={() => setIsExpanded(prevValue => !prevValue)}>
                    <Typography variant="h3" component="h3">Inactive</Typography>
                    {isExpanded && <CollapseIcon size={24} style={{ color: theme.palette.iconColor.color }} />}
                    {!isExpanded && <ExpandIcon size={24} style={{ color: theme.palette.iconColor.color }} />}
                </MembershipHeading>
                {isLoading && <CircularProgress color="secondary" />}
                {isExpanded && inactiveProducts.map((product, index) => {
                    return (
                        <MembershipItem key={product.id}>
                            <ManageProductListItem key={product.id} product={product} company={props.company} displayedUser={displayedUser} />
                        </MembershipItem>
                    )
                })}
                {isExpanded && inactiveProducts.length === 0 &&
                    <Box p={2}>
                        <Typography ml={4}>No inactive products found.</Typography>
                    </Box>
                }
            </Card>
            {isProductManageModalOpen && <ManageProductModal open={isProductManageModalOpen} setOpen={setIsProductManageModalOpen} manageProducts />}
        </React.Fragment>

    );
}

export default ProductList;