import React, { useCallback, useMemo, useEffect } from "react";

import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Element } from "../framework/RTE/SlateComponents/SlateElement";
import { Leaf } from "../framework/RTE/SlateComponents/SlateLeaf";
import { Box } from "@material-ui/core";

// svxvy imports
import { getRTEValue } from "../../helpers/rteFunctions";
import DefaultGrid from "./DefaultGrid";

const PageText = (props) => {
  const { pageText } = props;

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const pageTextNotEmpty = getRTEValue(pageText);

  useEffect(() => {
    if (editor) editor.children = getRTEValue(pageText);
  }, [editor, pageText]);

  if (pageTextNotEmpty)
    if (
      pageTextNotEmpty.length === 1 &&
      pageTextNotEmpty[0].children !== null &&
      pageTextNotEmpty[0].children.length === 1 &&
      pageTextNotEmpty[0].children[0].text !== null &&
      pageTextNotEmpty[0].children[0].text.trim() === ""
    )
      return <React.Fragment />;

  return (
    <DefaultGrid>
      <Box>
        <Slate editor={editor} value={getRTEValue(pageText)}>
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </Slate>
      </Box>
    </DefaultGrid>
  );
};

export default PageText;
