import { cloneDeep } from 'lodash';

import { SET_PRODUCTS, SET_INACTIVE_PRODUCTS, ADD_PRODUCT, EDIT_PRODUCT, CHANGE_PRODUCT_ACTIVE_STATUS, SET_SELECTED_PRODUCT, SET_SMARTCORE_PRODUCTS } from '../actions/productActions';
import { addToArray, deleteById } from '../../helpers/arrayFunctions';
import { LOGOUT } from '../actions/authActions';
import { SET_COMPANY } from '../actions/companyActions';

const initialState = {
    products: [],
    smartcoreProducts: [],
    inactiveProducts: [],
    selectedProduct: null,
    allLoaded: false,
    allInactiveLoaded: false
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return { ...state, products: action.company.products, inactiveProducts: action.company.inactiveProducts }
        case SET_SMARTCORE_PRODUCTS:
            return { ...state, smartcoreProducts: action.products };
        case SET_PRODUCTS:
            return { ...state, products: action.products, allLoaded: action.allLoaded };
        case SET_INACTIVE_PRODUCTS:
            return { ...state, inactiveProducts: action.products, allLoaded: action.allLoaded };
        case ADD_PRODUCT:
            return { ...state, products: addToArray(action.product, state.products) };
        case EDIT_PRODUCT:
            const arrayToUpdate = action.isActive ? state.products : state.inactiveProducts;
            const updatedProducts = deleteById(action.product.id, arrayToUpdate);
            if (action.isActive)
                return { ...state, products: addToArray(action.product, updatedProducts) };
            else
                return { ...state, inactiveProducts: addToArray(action.product, updatedProducts) };
        case CHANGE_PRODUCT_ACTIVE_STATUS:
            let updatedInactiveProducts = deleteById(action.product.id, state.inactiveProducts);
            let updatedActiveProducts = deleteById(action.product.id, state.products);
            if (action.product.isActive)
                updatedActiveProducts = addToArray(action.product, updatedActiveProducts);
            else
                updatedInactiveProducts = addToArray(action.product, updatedInactiveProducts);
            return { ...state, products: updatedActiveProducts, inactiveProducts: updatedInactiveProducts };
        case SET_SELECTED_PRODUCT:
            return { ...state, selectedProduct: action.selectedProduct };
        case LOGOUT:
            const publicProducts = cloneDeep(state.products).filter(x => !x.allowedUsers || x.allowedUsers.length === 0);
            return { ...state, products: publicProducts };
        default:
            return state;
    }
};

export default productsReducer;