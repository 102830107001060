import {
    Button,
    CircularProgress,
} from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const ActionButton = props => {
    const { submitting, disabled, ...buttonProps } = props;
    return (
        <Button {...buttonProps} disabled={submitting || disabled} startIcon={submitting ? null : buttonProps.startIcon}>
            {!submitting && props.children}
            {submitting && <CircularProgress color="inherit" size={20} />}
        </Button>
    )
}

export default styled(ActionButton)(spacing);