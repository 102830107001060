import React from "react";
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  CardContent,
  Card as MuiCard,
  LinearProgress as MuiLinearProgress,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

// svxvy imports
import { checkClaim } from '../../helpers/helperFunctions';
import * as NavigationConstants from '../../constants/NavigationConstants';

//constants
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

// For routes that can only be accessed by authenticated users
const SvxvyAuthGuard = (props) => {
  // let { sanitizedCompanyName } = useParams();
  const auth = useSelector((state) => state.auth);
  const currentUserClaims = useSelector(state => state.auth.claims);
  const company = useSelector((state) => state.company.company);
  const didTryLogin = useSelector(state => state.auth.didTryAutoLogin);
  const location = useLocation();

  if ((props.tryingLogin && !auth.token) || !didTryLogin)
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Checking your credentials please wait...
          </Typography>
          <Paper mt={3}>
            <LinearProgress my={2} color="primary" />
            <LinearProgress my={2} color="secondary" />
          </Paper>
        </CardContent>
      </Card>
    );
  else if (!auth.person || auth.person.companyId !== company.id || (props.guardClaim ? !checkClaim(currentUserClaims, props.guardClaim) : false)){
    return <Redirect to={NavigationConstants.LOGIN + "?returnURL=" + location.pathname} />;
  }
  else
    return props.children;
}

export default SvxvyAuthGuard;
