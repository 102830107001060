
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';

import { Formik } from "formik"
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Grid,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { signWaiver } from '../../../store/actions/peopleActions';
import ActionButton from "../../framework/ActionButton";
import ParagraphTypography from "../../framework/ParagraphTypography";
import StyledDialog from "../../framework/StyledDialog";

// constants

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WaiverModal = (props) => {
    const { waiverForUser, useButton, dontAllowClose } = props;
    const company = useSelector(state => state.company.company);
    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isWaiverModalOpenState = useButton || !company ? false : waiverForUser.waiverSigned !== company.waiverText;
    const [isWaiverModalOpen, setIsWaiverModalOpen] = useState(isWaiverModalOpenState);

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (appUserId, companyId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(signWaiver(appUserId, companyId));
            setIsSubmitting(false);
            setIsWaiverModalOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = () => {
        submitCallback(waiverForUser.id, company.id);
    };

    if (!company || !company.waiverText || company.waiverText.trim() === '')
        return <React.Fragment></React.Fragment>

    return (
        <React.Fragment>
            {useButton && waiverForUser.waiverSigned !== company.waiverText &&
                <ActionButton
                    variant="contained"
                    color="primary"
                    onClick={() => setIsWaiverModalOpen(true)}>
                    Sign Waiver
                </ActionButton>
            }
            <StyledDialog
                open={isWaiverModalOpen}
                setOpen={setIsWaiverModalOpen}
                dontAllowClose={dontAllowClose}
            >
                <Box display="flex" flexDirection="column">
                    <Typography component="h2" variant="h1" mb={5}>{company.name} Waiver</Typography>
                    {error && <Alert severity="error" my={2}>{error}</Alert>}
                    <ParagraphTypography>
                        {company.waiverText}
                    </ParagraphTypography>
                    {error && <Alert severity="error" my={2}>{error}</Alert>}
                    <Formik
                        initialValues={{
                            signature: ''
                        }}
                        validationSchema={Yup.object().shape({
                            signature: Yup.string()
                                .required("Required")
                                .test('match',
                                    'Typed name must match display name for signature',
                                    textEntered => {
                                        if (!textEntered)
                                            return false;
                                        return textEntered.replace(/[^A-Z0-9]/ig, "").toLowerCase() === waiverForUser.displayName.replace(/[^A-Z0-9]/ig, "").toLowerCase();
                                    })
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isValid,
                            touched,
                            values,
                            status,
                            setFieldValue,
                        }) =>
                        (
                            <React.Fragment>
                                <form id='messageForm' onSubmit={handleSubmit}>
                                    <Grid container >
                                        <Grid container spacing={3}>
                                            <TextField
                                                name="signature"
                                                label={"Agree by typing your name: " + waiverForUser.displayName}
                                                value={values.signature}
                                                error={Boolean(touched.signature && errors.signature)}
                                                fullWidth
                                                helperText={touched.signature && errors.signature}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                mt={4}
                                                mb={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ActionButton
                                                my={2}
                                                submitting={isSubmitting}
                                                onClick={handleSubmit}
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={!(values.signature && isValid)}
                                            >
                                                Agree to Waiver
                                            </ActionButton>
                                        </Grid >
                                    </Grid >
                                </form >
                            </React.Fragment >
                        )
                        }
                    </Formik >
                </Box>
            </StyledDialog>
        </React.Fragment >
    );
}

export default WaiverModal;
