import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  LinearProgress as MuiLinearProgress,
  Grid,
  Typography as MuiTypography,
  List as MuiList,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoAddCircleOutline as MuiAddCircle,
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { fetchReviews } from '../../../store/actions/reviewActions';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import ManageReviewCard from '../../../components/company/reviews/ManageReviewCard';
import ReviewForm from '../../../components/company/reviews/ReviewForm';

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ReviewList = styled(MuiList)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const AddCircle = styled(MuiAddCircle)`
  transform: scale(1.2);
`;

const useStyles = makeStyles((theme) => ({
  alert: {
    flex: "1",
  },
  updatingDiv: {
    flex: "1"
  },
  updatingTitle: {
    textAlign: "center"
  }
}));

const ManageReviews = props => {

  const currentUser = useSelector(state => state.auth.person);
  const currentUserClaims = useSelector(state => state.auth.claims);
  const allowAddEditPeople = currentUser && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);

  const classes = useStyles();

  const isMounted = React.useRef(true); // monitors if users navigates away from the page so no state is updated after the navigation.
  const authState = useSelector(state => state.auth.token);
  const company = useSelector(state => state.company.company);

  const reviews = useSelector(state => state.company.reviews);
  reviews.sort(compareObjects('createOn', 'desc'));

  //this is slow for large groups not sure why. 


  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();

  const loadReviews = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (await dispatch(fetchReviews(company.id)) && isMounted.current)
        setIsLoading(false);
    } catch (err) {
      if (isMounted.current) {
        setError(err.message);
        setIsLoading(false);
      }
    }
  }, [dispatch, company]);

  //authState is tracked here and forces a reload after login or logout to get the most current state.
  useEffect(() => {
    loadReviews();
  }, [loadReviews, authState]);

  // monitors if users navigates away from the page so no state is updated after the navigation.
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet title={"Manage Reviews - " + (company ? company.name : "")} />
      {isLoading &&
        <Grid container>
          {isLoading && <div className={classes.updatingDiv}>
            <LinearProgress my={2} color="primary" />
            <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading Reviews...</Typography>
            <LinearProgress my={2} color="secondary" />
          </div>
          }
        </Grid>
      }
      {error
        && <Grid container>
          {error && <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>}
        </Grid>
      }
      <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
        <Grid item xs={false} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '15px' }}>
            <Typography component="h1" variant="h1">Reviews</Typography>
            {allowAddEditPeople &&
              <Button startIcon={<AddCircle />} variant="contained" color="primary" my={1} onClick={() => setIsCreating(true)}>Add New Review</Button>
            }
          </Box>
          <ReviewList>
            {reviews && reviews.map((review, index) => {
              return (
                <ManageReviewCard key={review.id} review={review} showActions />
              )
            })}
          </ReviewList>
        </Grid>
      </Grid>
      {isCreating &&
        <ReviewForm
          isOpen={isCreating}
          setIsOpen={setIsCreating}
          company={company}
          showInDialog
        />
      }
    </React.Fragment>
  )
}

export default withTheme(ManageReviews);