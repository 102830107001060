import { checkClaim } from './helperFunctions'
import * as NavigationConstants from '../constants/NavigationConstants';

export const replaceDisplayName = (originalText, company) => {
    let updateDisplayName = originalText;
    // updateDisplayName = updateDisplayName.replace(NavigationConstants.OFFERINGS, company.activitiesText);
    // updateDisplayName = updateDisplayName.replace(NavigationConstants.ACTIVITIES, company.activitiesText);
    // console.log(company.people.filter(x => x.isEmployee && !x.isDeleted && x.isPublic).length);

    updateDisplayName = updateDisplayName.replace(NavigationConstants.ACTIVITIES, 'Schedule');
    updateDisplayName = updateDisplayName.replace(NavigationConstants.CUSTOMERS, company.customersText);
    updateDisplayName = updateDisplayName.replace(NavigationConstants.EMPLOYEES, company.people.filter(x => x.isEmployee && !x.isDeleted && x.isPublic).length === 1 ? company.employeeText : company.employeesText);
    updateDisplayName = updateDisplayName.replace(NavigationConstants.MANAGE_LOCATIONS, company.locationsText);
    updateDisplayName = updateDisplayName.replace(NavigationConstants.LOCATIONS, company.locations.filter(x => !x.isDeleted).length > 1 ? company.locationsText : company.locationText);

    return updateDisplayName;
}

export const showMenuItem = (category, company, auth) => {
    if (category.id && category.id.toLowerCase() === NavigationConstants.HOME_ID.toLowerCase() && company.usesWebsite === false)
        return false;

    if (category.id && category.id.toLowerCase() === "billing" && company.hideBilling)
        return false;

    if (!category.guard)
        return true;

    if (category.guard && !auth.token)
        return false;

    if (auth.person.companyId !== company.id)
        return false;

    // Specific claim is not set so any authenticated user can access
    if (!category.guardClaim && auth.person)
        return true;

    if (category.guardClaim && auth.person && auth.claims && checkClaim(auth.claims, category.guardClaim))
        return true;

    return false;
}

export const cleanURL = (sanitizedName, url) => {
    const lowerSanitizedName = sanitizedName.toLowerCase();
    let cleanedUrl = url.toLowerCase();
    cleanedUrl = cleanedUrl.replace(("/" + lowerSanitizedName), '');
    cleanedUrl = cleanedUrl.replace(lowerSanitizedName, '');
    cleanedUrl = cleanedUrl.startsWith('/') ? cleanedUrl.substring(1) : cleanedUrl;
    return cleanedUrl;
}