import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { withTheme } from "styled-components/macro";

// svxvy import
import * as SecurityContants from '../../../constants/SecurityConstants';
import { replaceDisplayName } from '../../../helpers/navigationFunctions';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import LeftRightComponent from '../../../components/companyPublic/LeftRightComponent';
import ProfileEditCard from '../../../components/company/profile/ProfileEditCard';

const EmployeesPage = (props) => {
    const company = useSelector(state => state.company.company);

    const people = useSelector(state => state.company.people).filter(x => x.isEmployee && !x.isDeleted && x.isPublic).sort(compareObjects('employeeSortOrder', 'asc'));

    const claims = useSelector(state => state.auth.claims);
    const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);

    let location = useLocation();
    const pageName = replaceDisplayName(location.pathname.split('/').slice(-1)[0], company);

    const [personToEdit, setPersonToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <React.Fragment>
            <Helmet title={pageName} />
            {people.map((person, index) => {
                const right = index % 2 === 1 ? true : false;
                return (
                    <LeftRightComponent
                        key={person.id}
                        title={person.displayName}
                        description={person.bio}
                        imageUrl={person.image}
                        right={right}
                        showEditButton={isOwner}
                        editAction={() => { setPersonToEdit(person); setIsEditing(true); }}
                    />

                );
            }
            )}
            {
                isEditing &&
                <ProfileEditCard
                    person={personToEdit}
                    open={isEditing}
                    setOpen={setIsEditing}
                />
            }
        </React.Fragment>
    );
}

export default withTheme(EmployeesPage);
