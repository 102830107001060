
import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';
import { getProfileFromResponse } from "../../models/Person";
import { refreshLogedInUser } from './authActions';

export const SET_PEOPLE = 'SET_PEOPLE';
export const ADD_PERSON = 'ADD_PERSON';
export const EDIT_PERSON = 'EDIT_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';
export const LOAD_PEOPLE = 'LOAD_PEOPLE';
export const LOAD_PEOPLE_ERROR = 'LOAD_PEOPLE_ERROR';

export const SET_CHILD_PROFILES = 'SET_CHILD_PROFILES';
export const ADD_EDIT_CHILD_PROFILE = 'ADD_EDIT_CHILD_PROFILE';
export const DELETE_CHILD_PROFILE = 'DELETE_CHILD_PROFILE';

export const fetchPeople = (companyId, isbackground) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        await dispatch({ type: LOAD_PEOPLE });
        let response = null;
        const token = getState().auth.token;
        if (getState().auth.token)
            response = await fetch(NetworkConstants.SERVER + '/api/extendedprofiles/' + companyId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        if (!response || !response.ok) {
            response = await fetch(NetworkConstants.SERVER + '/api/profiles/' + companyId + '?appVersion=website', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        }

        if (getState().auth.token !== token)
            return false; // the user logged out while this process happening so don't do anything.

        if (!response.ok) {
            await dispatch({ type: LOAD_PEOPLE_ERROR });
            if (isbackground)
                return;

            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const loadedPeople = [];
        for (const key in resData) {
            const personToAdd = getProfileFromResponse(resData[key]);
            loadedPeople.push(personToAdd);
        }
        dispatch({ type: SET_PEOPLE, people: loadedPeople });
        return true;
    }
}

export const addPerson = (values, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }
        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        dispatch({ type: ADD_PERSON, person: personToAdd });
        if (getState().auth.token && (getState().auth.person.id === personToAdd.parentAccountId))
            await dispatch(refreshLogedInUser(getState().auth.token));
        else if (personToAdd.parentAccountId)
            dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
    };
};

export const detailPerson = (id, companyId, getFullProfile) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('user ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        if (getState().auth.token && !getFullProfile) {
            response = await fetch(NetworkConstants.SERVER + '/api/extendedprofiles/' + companyId + '/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        }
        if (!response || !response.ok) {
            response = await fetch(NetworkConstants.SERVER + '/api/profiles/' + companyId + '/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        }
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        dispatch({ type: EDIT_PERSON, person: personToAdd });


        if (getState().auth.token && (getState().auth.person.id === personToAdd.id || getState().auth.person.id === personToAdd.parentAccountId))
            await dispatch(refreshLogedInUser(getState().auth.token));
    };
};

export const editPerson = (profileId, values, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!profileId) {
            throw new Error('Profile ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/' + companyId + '/' + profileId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        if (getState().auth.userId === personToAdd.id || getState().auth.userId === personToAdd.parentAccountId)
            await dispatch(refreshLogedInUser(getState().auth.token));
        else if (personToAdd.parentAccountId)
            dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
        dispatch({ type: EDIT_PERSON, person: personToAdd });

    };
};

export const editProfileImage = (formData, profile) => {
    return async (dispatch, getState) => {
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/photos/' + profile.companyId + "/" + profile.id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: formData,
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        profile.image = resData.url;

        dispatch({ type: EDIT_PERSON, person: profile });
    };
};

export const addAppProfile = (companyId, appUserId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/addappprofile/' + companyId + "/" + appUserId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
    };
};

export const listChildProfiles = (id, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        const token = getState().auth.token;
        if (!response || !response.ok) {
            response = await fetch(NetworkConstants.SERVER + '/api/profiles/listChildProfiles/' + companyId + "/" + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        }

        if (getState().auth.token !== token)
            return false; // the user logged out while this process happening so don't do anything.

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const loadedPeople = [];
        for (const key in resData) {
            const personToAdd = getProfileFromResponse(resData[key])
            loadedPeople.push(personToAdd);
        }
        await dispatch({ type: SET_CHILD_PROFILES, id: id, childProfiles: loadedPeople });
        return loadedPeople;
    }
}

export const signWaiver = (profileId, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!profileId) {
            throw new Error('Profile ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/signwaiver/' + companyId + '/' + profileId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        if (getState().auth.userId === personToAdd.id || getState().auth.userId === personToAdd.parentAccountId)
            await dispatch(refreshLogedInUser(getState().auth.token));
        else if (personToAdd.parentAccountId)
            dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
        dispatch({ type: EDIT_PERSON, person: personToAdd });
    };
};

export const setParentAccount = (profileId, companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!profileId) {
            throw new Error('Profile ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/setParentAccount/' + companyId + '/' + profileId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        // if (getState().auth.userId === personToAdd.id || getState().auth.userId === personToAdd.parentAccountId)
        //     await dispatch(refreshLogedInUser(getState().auth.token));
        // else if (personToAdd.parentAccountId)
        //     dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
        dispatch({ type: EDIT_PERSON, person: personToAdd });
    };
};

export const unsetParentAccount = (profileId, companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!profileId) {
            throw new Error('Profile ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/unsetparentaccount/' + companyId + '/' + profileId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        // if (getState().auth.userId === personToAdd.id || getState().auth.userId === personToAdd.parentAccountId)
        //     await dispatch(refreshLogedInUser(getState().auth.token));
        // else if (personToAdd.parentAccountId)
        //     dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
        dispatch({ type: EDIT_PERSON, person: personToAdd });
    };
};


export const setInactive = (profileId, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!profileId) {
            throw new Error('Profile ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/setinactive/' + companyId + '/' + profileId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const personToAdd = getProfileFromResponse(resData);

        // if (getState().auth.userId === personToAdd.id || getState().auth.userId === personToAdd.parentAccountId)
        //     await dispatch(refreshLogedInUser(getState().auth.token));
        // else if (personToAdd.parentAccountId)
        //     dispatch({ type: ADD_EDIT_CHILD_PROFILE, id: personToAdd.parentAccountId, childProfile: personToAdd });
        dispatch({ type: EDIT_PERSON, person: personToAdd });
    };
};



export const deletePerson = (id, companyId, childAccountByOwner) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/' + companyId + '/' + id, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: DELETE_PERSON, id: id });
        if (childAccountByOwner)
            await dispatch(refreshLogedInUser(getState().auth.token));
    };
}

export const checkHasValidMembership = (companyId, barcodeId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }

        if (barcodeId.length > 0) {
            // any asych code you want - like service call
            let response = null;
            response = await fetch(NetworkConstants.SERVER + '/api/profiles/hasvalidmembership/' + companyId + '?barcodeId=' + barcodeId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
            if (!response.ok) {
                const errorResData = await response.json();
                const message = getErrorMessagesFromResponse(errorResData);
                throw new Error(message);
            }
            const resData = await response.json();
            if (resData.profile)
                resData.profile = getProfileFromResponse(resData.profile);
            return resData;
        }
        else {
            return false;
        }
    };
};

export const validateAuthCodeGetCalendars = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/validateAuthCodeGetCalendars/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        return resData;
    };
};

export const setupSycnGoogleCalendar = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/setupSycnGoogleCalendar/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        await dispatch(refreshLogedInUser(getState().auth.token))
    };
};

export const stopSyncGoogleCalendar = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/stopSyncGoogleCalendar/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            )
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        await dispatch(refreshLogedInUser(getState().auth.token))
    };
};
