
import React from "react";
import { useSelector } from 'react-redux';
import moment from 'moment';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy import
// import ActionButton from "../../framework/ActionButton";
import ParagraphTypography from "../../framework/ParagraphTypography";
import StyledDialog from "../../framework/StyledDialog";

// constants

const Typography = styled(MuiTypography)(spacing);

const SignedWaiverModal = (props) => {
    const { user, isWaiverModalOpen, setIsWaiverModalOpen } = props;
    const company = useSelector(state => state.company.company);

    return (
        <StyledDialog
            open={isWaiverModalOpen}
            setOpen={setIsWaiverModalOpen}
        >
            {user &&
                < Box display="flex" flexDirection="column">
                    <Typography component="h2" variant="h1" mb={5}>{company.name} Waiver</Typography>
                    <ParagraphTypography>
                        {user.waiverSigned}
                    </ParagraphTypography>
                    <ParagraphTypography mt={4}>
                        Signed on {moment(user.waiverSignedOn).format('MMMM DD, YYYY')}
                    </ParagraphTypography>
                </Box>
            }
        </StyledDialog >

    );
}

export default SignedWaiverModal;
