import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  companyLayoutRoutes,
  svxvyPageRoutes,
} from "./indexSvxvy";

import Eula from "../pages/svxvyPages/Privacy";
import Page404 from "../pages/errorPages/Page404";

import Auxcillary from "../components/framework/Auxcillary";
import PublicAuthGuard from "../components/svxvyNavigation/PublicAuthGuard";
import { GoogleOAuthProvider } from '@react-oauth/google';

/// svxvy presentation
import * as NavigationConstants from "../constants/NavigationConstants";
import { getGoogleClientIDKey } from '../constants/NetworkConstants';
import { fetchCompanies } from '../store/actions/companyActions';
import { isSvxvySite } from '../constants/NetworkConstants';

import SmartCorePage from "../layouts/SmartCorePage";
import CompanyDashboardLayout from "../layouts/CompanyDashboard";
import ScrollToTop from "./ScrollToTop";

const childRoutes = (Layout, routes, loadingCompanies, company) =>
  routes.map(({ component: Component, guard, children, path, id, isPortalPage, hideChrome, hideBanner }, index) => {
    const Guard = !isPortalPage ? PublicAuthGuard : (guard || Auxcillary);
    let ComponentToShow = Component;
    if (company && id === 'Billing' && company.hideBilling && !loadingCompanies)
      ComponentToShow = null;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || Auxcillary;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout loadingCompanies={loadingCompanies} isPortalPage={isPortalPage} hideChrome={hideChrome} hideBanner={hideBanner} pageId={id}>
                <Guard loadingCompanies={loadingCompanies} pageId={id} guardClaim={element.guardClaim} isPublicPage={!isPortalPage}>
                  <element.component {...props} />
                </Guard>
              </Layout>
            )}
          />
        );
      })
    ) : ComponentToShow ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout loadingCompanies={loadingCompanies} isPortalPage={isPortalPage} hideChrome={hideChrome} hideBanner={hideBanner} pageId={id}>
            <Guard loadingCompanies={loadingCompanies} pageId={id}>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const company = useSelector(state => state.company.company)
  const employees = useSelector((state) => state.company.people).filter(x => x.isEmployee && !x.isDeleted);

  const dispatch = useDispatch();

  const loadCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    try {
      await dispatch(fetchCompanies());
    } catch (err) {
    }
    setLoadingCompanies(false);
  }, [dispatch]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);


  return (
    <Router Router >
      <GoogleOAuthProvider clientId={getGoogleClientIDKey(company)}>
        <ScrollToTop>
          <Switch>
            <Route path={"/" + NavigationConstants.EULA} render={() => (
                <Eula />
              )} />
            {isSvxvySite() && childRoutes(SmartCorePage, svxvyPageRoutes, loadingCompanies, company, employees)}
            {childRoutes(CompanyDashboardLayout, companyLayoutRoutes, loadingCompanies, company, employees)}
            <Route
              render={() => (
                <Page404 />
              )}
            />
          </Switch>
        </ScrollToTop>
      </GoogleOAuthProvider>
    </Router >
  )
};

export default Routes;
