import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Button as MuiButton,
    Card as MuiCardWithSpacing,
    Checkbox,
    Chip as MuiChip,
    DialogContent,
    Grid,
    LinearProgress as MuiLinearProgress,
    List,
    ListItem,
    Menu,
    TextField as MuiTextField,
    InputAdornment as MuiInputAdornment,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
// import DeleteIcon from '@material-ui/icons/Delete';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoSearchOutline as SearchIcon,
    IoChatboxEllipsesOutline as MessageIcon,
    IoCloseCircleOutline as DeleteIcon,
    IoAddCircleOutline as AddIcon,
    IoEllipsisHorizontalCircleOutline as CircleDotsIcon,
} from "react-icons/io5";


// svxvy import
import Person from '../../models/Person';
import ProfileListItem from './profile/ProfileListItem';
import ProfileDetailCard from './profile/ProfileDetailCard';

import { fetchPeople } from '../../store/actions/peopleActions';
import { compareObjects } from '../../helpers/helperFunctions';
import { addToArray, deleteById } from '../../helpers/arrayFunctions';
import StyledDialog from '../framework/StyledDialog';
import ProfileIcon from '../company/profile/ProfileIcon';
import MessageModal from './messages/MessageModal';

const everyone = new Person('00000000-0000-0000-0000-000000000001', '#everyone', '#everyone', '', '', 'any', '', [], false, false, false, false, '00000000-0000-0000-0000-000000000001');
everyone.isSelected = false;
everyone.isUserRegistered = true;

// constants

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Chip = styled(MuiChip)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const MuiCard = styled(MuiCardWithSpacing)(spacing);
const Typography = styled(MuiTypography)(spacing);

const SearchField = styled(MuiTextField)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }
`;

const ActionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    button {
        width: auto;
        min-width: 0;
        background-color: transparent !important;
        margin: 7px 0 8px 0;
        padding: 0;
        justify-content: left;
        font-weight: 400;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        flex-direction: row;

        button {
            margin: 0 35px 0 0;
        }
    }
`;


const InputAdornment = styled(MuiInputAdornment)`
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 9px;

  svg {
    width: 18px;
    height: 18px;
    color: ${(props) => props.theme.palette.primary.main};
    opacity: 1;
  }
`;

const ProfileList = styled(MuiCard)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
`;

const GroupTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};

  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    updatingTitle: {
        textAlign: "center"
    },
    scrollSelectedPeople: {
        overflowY: 'scroll',
        maxHeight: '150px'
    }
}));

const Card = styled(MuiCard)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  margin-bottom: 15px;
`;

const StyledChip = styled(Chip)`
    height: 20px;
    border-radius: 999999px;
    margin-top: -2px;
    padding: 0 9px;
    font-weight: 700;
    font-size: 12px !important;

    span {
        padding: 0;
    }
`;

const UserCheckbox = styled(Checkbox)`
    color: ${(props) => props.theme.palette.divider};
    margin-right: 10px;
    padding: 0;

    svg {
        width: 26px;
        height: 26px;
    }
`;

// const setSelected = (peopleToSet, toSet) => {
//     for (const p in toSet) {
//         const toUpdate = peopleToSet.find(x => x.id === toSet[p].id);
//         if (toUpdate)
//             toUpdate.isSelected = true
//     }
//     return peopleToSet;
// }

const getSelectablePeople = (people, showEveryoneOption, hideSelf, loggedInUserId) => {
    let selectable = cloneDeep(people);
    if (hideSelf)
        selectable = deleteById(loggedInUserId, selectable)
    if (showEveryoneOption)
        selectable = addToArray(everyone, selectable, true)
    return selectable;
}

const allOthers = '#';
const alphabetArray = [allOthers, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const extractItems = (items, companyList) => {
    const arrayToReturn = [];
    for (const l in alphabetArray) {
        const letter = alphabetArray[l];
        let data = null;
        if (letter !== allOthers)
            data = items.filter(x => x.displayName.toUpperCase().startsWith(letter));
        else
            data = items.filter(x => x.displayName.charAt(0) === allOthers || !alphabetArray.includes(x.displayName.toUpperCase().charAt(0)));

        if (data.length > 0)
            arrayToReturn.push({ title: letter, data: data })
    }

    return arrayToReturn;
}


const StyledMenu = withStyles({
    paper: {
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 8px 7px 15px',
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const SelectUser = (props) => {
    const { items, showEveryoneOption, hideSelf, initialValue, title, onChange, hideMessageButton, selectText, handleAdd, handleRemove, showProfile } = props;
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const theme = useTheme();
    const classes = useStyles();
    const company = useSelector(state => state.company.company);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);
    const loggedInUserId = useSelector(state => state.auth.userId)
    const people = useSelector(state => state.company.people).filter(p => !p.isDeleted);
    people.sort(compareObjects('displayName', 'asc'));

    const selectablePeople = getSelectablePeople(items ? items : people, showEveryoneOption, hideSelf, loggedInUserId);
    const [everyoneSelected, setEveryoneSelected] = useState(false);

    const [search, setSearch] = useState('');

    const peopleToDisplay = everyoneSelected ? selectablePeople.filter(p => p.id === everyone.id) : selectablePeople.filter(p =>
        search.length > 0
            ? p.displayName.toLowerCase().includes(search.toLowerCase()) | (p.email != null && p.email.toLowerCase().includes(search.toLowerCase())) | (p.phoneNumber != null && p.phoneNumber.includes(search))
            : true)

    const groupedPeople = extractItems(peopleToDisplay)

    const [chosenPeople, setChosenPeople] = useState(initialValue ? initialValue : []);
    const [selectedItems, setSelectedItems] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false)

    const [anchorMenu, setAnchorMenu] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [profileToShow, setProfileToShow] = useState(null);

    const dispatch = useDispatch();

    /******************************* load people from database **************************/

    const loadPeople = useCallback(async () => {
        try {
            setIsLoading(true);
            await dispatch(fetchPeople(company.id));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [company, dispatch]);


    useEffect(() => {
        loadPeople();
    }, [loadPeople]);

    /******************************* events and handlers **************************/

    const handleOnChange = (updatedSelection) => {
        setChosenPeople(updatedSelection);
        if (onChange)
            onChange(updatedSelection);
    }


    const profileNamePressedHandler = (personSelected, isSelected) => {
        if (personSelected.id === everyone.id && isSelected) {
            setEveryoneSelected(true);
            handleOnChange(deleteById(loggedInUserId, people));
        }
        else if (personSelected.id === everyone.id && !isSelected) {
            setEveryoneSelected(false);
            handleOnChange([]);
        }
        else {
            if (showEveryoneOption) {
                const everyoneLocal = selectablePeople.find(x => x.id === everyone.id);
                everyoneLocal.isSelected = false;
            }
            if (isSelected) {
                handleOnChange(addToArray(personSelected, chosenPeople));
                if (handleAdd)
                    handleAdd(personSelected)
            }
            else {
                handleOnChange(deleteById(personSelected.id, chosenPeople));
                if (handleRemove)
                    handleRemove(personSelected)
            }
        }
    }

    /************************************** selected items **********************************/

    const removeSelectedUser = () => {
        if (selectedItems.length === 1 && selectedItems[0].id === everyone.id) {
            setEveryoneSelected(false);
            handleOnChange([]);
            setSelectedItems([]);
        }
        else {
            let updatedPeople = cloneDeep(chosenPeople);
            for (const p in selectedItems) {
                updatedPeople = deleteById(selectedItems[p].id, updatedPeople)
                if (handleRemove)
                    handleRemove(selectedItems[p])
            }
            handleOnChange(updatedPeople);
            setSelectedItems([]);
        }
    }

    const handleSearchChange = event => {
        setSearch(event.target.value);
    }

    const actions = (
        <React.Fragment>
            <Button
                style={{ color: theme.palette.secondary.main, margin: isMobile ? '0 25px 10px 0' : '0 25px 0 0', padding: 0 }}
                startIcon={<StyledChip color="secondary" size="small" label={chosenPeople.length}></StyledChip>}
                onClick={() => setSelectedItems(selectedItems.length === chosenPeople.length ? [] : chosenPeople)}
            >
                {selectedItems.length === chosenPeople.length && chosenPeople.length !== 0 ? 'De-Select All' : 'Select All'}
            </Button>
            {!hideMessageButton && <Button
                style={{ margin: isMobile ? '0 25px 10px 0' : '0 25px 0 0', padding: 0 }}
                startIcon={<MessageIcon color={selectedItems.length === 0 ? theme.palette.divider : theme.palette.secondary.main} size={24} />}
                disabled={selectedItems.length === 0}
                onClick={() => setMessageModalOpen(true)}
            >
                Message
            </Button>
            }
            <Button
                style={{ margin: isMobile ? '0 25px 10px 0' : '0 25px 0 0', padding: 0 }}
                startIcon={<DeleteIcon color={selectedItems.length === 0 ? theme.palette.divider : theme.palette.error.main} size={24} />}
                disabled={selectedItems.length === 0}
                onClick={removeSelectedUser}
            >
                Remove
            </Button>
        </React.Fragment>
    )

    const addAction = (
        <Button
            style={{ margin: 0, padding: 0 }}
            startIcon={<AddIcon color={theme.palette.secondary.main} size={24} />}
            onClick={() => setIsOpen(true)}
        >
            Add
        </Button>
    )

    return (
        <React.Fragment>
            <Card>
                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '12px 14px 13px 14px' }}>
                    <Box display="flex" alignItems="center">
                        <Typography component="h4" variant="h4" mr={5}>{title}</Typography>
                        {!isMobile && actions}
                    </Box>
                    <Box>
                        {!isMobile && addAction}
                        {isMobile &&
                            <React.Fragment>
                                <Button aria-label="edit" onClick={(event) => setAnchorMenu(event.currentTarget)} style={{ color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: 400, padding: 0 }}>
                                    <CircleDotsIcon color={theme.palette.primary.main} size="32" style={{ marginRight: '5px' }} />Actions
                                </Button>
                                <StyledMenu
                                    id="menu-profile-detail-actions"
                                    anchorEl={anchorMenu}
                                    open={Boolean(anchorMenu)}
                                    onClose={() => setAnchorMenu(null)}
                                    style={{ padding: 0 }}
                                >
                                    <ActionsWrapper onClick={() => setAnchorMenu(null)}>{actions}{addAction}</ActionsWrapper>
                                </StyledMenu>
                            </React.Fragment>
                        }
                    </Box>
                </Box >
                <Box py={2} px={3}>
                    {(everyoneSelected || chosenPeople.length > 0) &&
                        <Box display="flex" minHeight={50} >
                            <List style={{ width: '100%', margin: 0, padding: 0, columns: isMobile ? 1 : 2, verticalAlign: 'top' }}>
                                {(everyoneSelected ? [everyone] : chosenPeople).map((person, index) => {
                                    return (
                                        <ListItem key={person.id} style={{ padding: '11px 0', display: 'inline-block' }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <UserCheckbox
                                                    checked={selectedItems.filter(x => x.id === person.id).length > 0}
                                                    onChange={() => setSelectedItems(prevState => prevState.filter(x => x.id === person.id).length > 0 ? deleteById(person.id, prevState) : addToArray(person, prevState))}
                                                ></UserCheckbox>
                                                <Box
                                                    style={{ display: 'flex', alignItems: 'center', cursor: showProfile ? "pointer" : "default" }}
                                                    onClick={() => {
                                                        setProfileToShow(person);
                                                        setDetailDialogOpen(true);
                                                    }}
                                                >
                                                    <ProfileIcon person={person} />
                                                    <Typography ml={2}>
                                                        {person.displayName}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Box>
                    }

                </Box>
            </Card>
            {messageModalOpen &&
                <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} sendInAppInitialState toUsers={selectedItems} />
            }
            {isOpen &&
                <StyledDialog
                    style={{ height: "90vh" }}
                    open={isOpen}
                    setOpen={setIsOpen}
                    aria-labelledby="act-dialog-title"
                    primaryAction={
                        <Button variant="contained" color="primary" fullWidth onClick={() => setIsOpen(false)} mb={3}>Close</Button>
                    }
                >
                    <DialogContent style={{ margin: 0, padding: 0 }} >
                        {error && <Alert severity="error">{error}</Alert>}
                        {isLoading && !error && <React.Fragment><LinearProgress my={2} color="primary" />
                            <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading {CUSTOMERS_TEXT}...</Typography>
                            <LinearProgress my={2} color="secondary" />
                        </React.Fragment>}
                        {!isLoading && !error && <React.Fragment>

                            <Typography component="h2" variant="h1" mb={5}>{selectText ? selectText : "Select"}</Typography>

                            {/* <TextField id="message-search" fullWidth label="Search" type="search" variant="outlined" onChange={e => handleSearchChange(e)} /> */}
                            <SearchField
                                id="standard-search"
                                type="search"
                                placeholder="Search"
                                onChange={e => handleSearchChange(e)}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {groupedPeople.map((group, index) => {
                                return (
                                    <Grid key={group.title} container style={{ paddingTop: '5px' }}>
                                        <Grid item xs={12}>
                                            <Box mt={index === 0 ? 2 : 6}>
                                                <Box display="flex" flex={1}>
                                                    <GroupTitle variant="h6">{group.title}</GroupTitle>
                                                </Box>
                                                <ProfileList>
                                                    {group.data.map((person, index) => {
                                                        return (
                                                            <ProfileListItem style={{
                                                                width: '100%',
                                                                maxWidth: 'none',
                                                                backgroundColor: 'transparent !important',
                                                                borderBottom: '1px solid ' + theme.palette.divider,
                                                            }} key={person.id}
                                                                person={person}
                                                                isSelected={chosenPeople.find(x => x.id === person.id) != null ? true : false}
                                                                profileNamePress={profileNamePressedHandler} />

                                                            // <ProfileCard key={person.id} person={person} urlBase={isEmployeePage ? NavigationConstants.EMPLOYEES : NavigationConstants.CUSTOMERS} />
                                                        )
                                                    })}
                                                </ProfileList>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </React.Fragment>
                        }
                    </DialogContent>
                </StyledDialog>
            }
            {detailDialogOpen &&
                <ProfileDetailCard person={profileToShow} open={detailDialogOpen} setOpen={setDetailDialogOpen} isChildProfile={false} />
            }
        </React.Fragment>

    );
}

export default SelectUser;