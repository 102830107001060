import Purchase from "../../models/Purchase";

import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

import { detailPerson } from "./peopleActions";
import { refreshLogedInUser } from './authActions';

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_SMARTCORE_PRODUCTS = "SET_SMARTCORE_PRODUCTS";
export const SET_INACTIVE_PRODUCTS = "SET_INACTIVE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const CHANGE_PRODUCT_ACTIVE_STATUS = "CHANGE_PRODUCT_ACTIVE_STATUS";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export const SVXVY_SUBSCRIPTION_BOUGHT = "BUY_SELECTED_PRODUCT";
export const PRODUCT_PURCHASED = "PRODUCT_PURCHASED";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";

export const fetchProducts = (companyId) => {
    return async (dispatch, getState) => {
        // any asych code you want - like service call
        let queryUrl = NetworkConstants.SERVER + '/api/products/listproducts';
        if (companyId)
            queryUrl += "/" + companyId;
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        if (companyId)
            await dispatch({ type: SET_PRODUCTS, products: resData });
        else
            await dispatch({ type: SET_SMARTCORE_PRODUCTS, products: resData });
    }
}

export const fetchInactiveProducts = (companyId) => {
    return async (dispatch, getState) => {
        // any asych code you want - like service call
        let queryUrl = NetworkConstants.SERVER + '/api/products/listinactiveproducts';
        if (companyId)
            queryUrl += "/" + companyId;
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        dispatch({ type: SET_INACTIVE_PRODUCTS, products: resData });
    }
}

export const addProduct = (values, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/products/createproduct/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        dispatch({ type: ADD_PRODUCT, product: resData });
    };
};

export const editProduct = (values, productId, isActive, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/products/edit/' + companyId + "/" + productId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        await dispatch({ type: EDIT_PRODUCT, product: resData, isActive: isActive });
        return resData;
    };
};

export const setActiveStatus = (values, productId, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/products/setactive/' + companyId + "/" + productId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        dispatch({ type: CHANGE_PRODUCT_ACTIVE_STATUS, product: resData });
    };
};



export const buyProduct = (companyId, userId, values) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/products/buyProduct/' + companyId + "/" + userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        await dispatch({ type: PRODUCT_PURCHASED, purchase: purchase });
        if (userId !== getState().auth.person.id)
            await dispatch(detailPerson(userId, companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));
        return purchase;
    }
}

export const customPurchase = (companyId, userId, values, isActivityPurchase) => {
    return async (dispatch, getState) => {
        const url = NetworkConstants.SERVER + (isActivityPurchase ? '/api/products/activityPrePurchase/' : '/api/products/customPurchase/');
        const response = await fetch(url + companyId + "/" + userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        dispatch({ type: PRODUCT_PURCHASED, purchase: purchase });
        if (userId !== getState().auth.person.id)
            await dispatch(detailPerson(userId, companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));
        return purchase;
    }
}

export const cancelTenantSubscription = (purchaseId, companyId, userId) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/products/cancelTenantSubscription/' + companyId + '/' + getState().auth.userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    purchaseId: purchaseId,
                    companyId: companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        dispatch({ type: PRODUCT_UPDATED, purchase: purchase });
        if (userId !== getState().auth.person.id)
            await dispatch(detailPerson(userId, companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));
    }
}

export const reactivateTenantSubscription = (purchaseId, companyId, userId) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/products/reactivateTenantSubscription/' + companyId + '/' + getState().auth.userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    purchaseId: purchaseId,
                    companyId: companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        dispatch({ type: PRODUCT_UPDATED, purchase: purchase });
        if (userId !== getState().auth.person.id)
            await dispatch(detailPerson(userId, companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));
    }
}

export const refreshSubscriptionDetails = (purchaseId, companyId, userId, priorStatus) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/products/refreshSubscriptionDetails/' + companyId + '/' + getState().auth.userId + '/' + purchaseId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    purchaseId: purchaseId,
                    companyId: companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        purchase.refreshed = true;
        purchase.priorStatus = priorStatus;
        await dispatch({ type: PRODUCT_UPDATED, purchase: purchase });
        if (userId !== getState().auth.person.id)
            await dispatch(detailPerson(userId, companyId, true));
        else
            await dispatch(refreshLogedInUser(getState().auth.token));
    }
}