import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux"
import parse from 'html-react-parser';
import i18n from 'i18n-js';

import { useTheme, withStyles } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Button as MuiButton,
    Box,
    CircularProgress as MuiCircularProgress,
    ListItem as MuiListItem,
    Menu,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoRemoveCircleOutline as DeactivateIcon,
    IoPencilOutline as EditIcon,
    IoEllipsisHorizontal as MuiMenuIcon,
    IoDuplicateOutline as CopyIcon,
} from "react-icons/io5";

// svxvy imports
import { setActiveStatus } from '../../../store/actions/productActions';
import ManageProductModal from "./ManageProductModal";

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ICON_SIZE = 24;

const MenuIcon = styled(MuiMenuIcon)`
    color: ${(props) => props.theme.palette.iconColor.color};

    &:hover {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const ListItem = styled(MuiListItem)`
    padding: 8px 15px 9px 15px;
`;

const StyledMenu = withStyles((theme) => ({
    paper: {
        marginTop: '19px !important',
        marginRight: '15px !important',
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 0 5px 0',
    }
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));


const ManageProductListItem = (props) => {
    const { product, company } = props;
    const theme = useTheme();

    const [isProductManageModalOpen, setIsProductManageModalOpen] = useState(false);
    const [anchorMenu, setAnchorMenu] = useState(null);

    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [isMakeCopy, setIsMakeCopy] = useState(false);

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (values, companyId, product) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(setActiveStatus(values, product.id, companyId));
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);


    const handleArchive = () => {
        const valuesToSend = { active: !product.isActive }
        submitCallback(valuesToSend, company.id, product);
    }

    return (
        <React.Fragment>
            <Box display="flex" flexDirection='column'>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" flexDirection="column">
                        <Typography component="p" style={{ marginBottom: '2px', fontWeight: '700' }}>{product.name}</Typography>
                        <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }}>{product.description ? parse(product.description) : ""}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="flex-start">
                        <Box display="flex" flexDirection="column" style={{ marginLeft: '15px', textAlign: 'right' }}>
                            <Typography component="p" style={{ fontWeight: '700', marginBottom: '2px' }}>
                                {i18n.toCurrency(product.unitAmount, { precision: 2, unit: '$' })}
                            </Typography>
                            <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }}>
                                {product.isRecurring ? "/ " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : ""}
                            </Typography>
                        </Box>

                        {!isSubmitting &&
                            <Button
                                id={product.id + "_menu_button"}
                                aria-label="payment menu button"
                                onClick={(event) => setAnchorMenu(event.currentTarget)}
                                style={{ minWidth: '0', color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: '400', marginTop: '-4px', marginLeft: '15px', padding: 0 }}>
                                <MenuIcon size="24" />
                            </Button>
                        }
                        {isSubmitting &&
                            <CircularProgress mx={4} size={24} color='primary' />
                        }
                    </Box>
                </Box>
                {error && <Alert mb={2} severity="error">{error}</Alert>}
            </Box>
            <StyledMenu
                id={product.id + "_menu"}
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={() => setAnchorMenu(null)}
            >
                <Box onClick={() => setAnchorMenu(null)} style={{ padding: 0 }}>
                    <ListItem
                        button
                        onClick={() => {setIsMakeCopy(false); setIsProductManageModalOpen(true)}}
                    >
                        <EditIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                        <Typography variant="body2">
                            Edit {product.isRecurring ? 'Membership' : product.units > 0 ? 'Package' : 'Product'}
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        onClick={handleArchive}
                    >
                        <DeactivateIcon size={ICON_SIZE} style={{ color: product.isActive ? theme.palette.error.main : theme.palette.secondary.main, marginRight: '5px' }} />
                        <Typography variant="body2">
                            {product.isActive ? 'Deactivate' : 'Activate'}  {product.isRecurring ? 'Membership' : product.units > 0 ? 'Package' : 'Product'}
                        </Typography>
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {setIsMakeCopy(true); setIsProductManageModalOpen(true)}}
                    >
                        <CopyIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                        <Typography variant="body2">
                            Create Copy of {product.isRecurring ? 'Membership' : product.units > 0 ? 'Package' : 'Product'}
                        </Typography>
                    </ListItem>
                </Box>
            </StyledMenu>
            {isProductManageModalOpen && <ManageProductModal 
                        open={isProductManageModalOpen}
                        setOpen={setIsProductManageModalOpen}
                        product={product}
                        isMakeCopy={isMakeCopy}
                        isSubscription={product.isRecurring}
                        manageProducts={!product.isRecurring && product.units === 0}
                        />}
        </React.Fragment>

    );
};

export default ManageProductListItem;
