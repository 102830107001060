import React, { useState, useCallback } from "react";
import { cloneDeep } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from "formik"
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button,
    Card as MuiCard,
    FormControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleOutline as SaveIcon,
} from "react-icons/io5";

/// sxvxy import
import { setCompanyPaymentService, synchPaymentService } from '../../../store/actions/companyActions';
import { isLiveSite } from '../../../constants/NetworkConstants';
import { ordinalSuffixOf } from "../../../helpers/helperFunctions";
import ActionButton from "../../framework/ActionButton";
import SelectFormControl from "../../framework/SelectFormControl";

// designs
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const validationSchema = Yup.object().shape({
    paymentServicePublicKey: Yup.string()
        .matches((isLiveSite() ? "pk_live_.*$" : "pk_test_.*$"), "The public key should start with pk_" + (isLiveSite() ? "live_" : "test_")),
    paymentServiceSecretKey: Yup.string()
        .matches((isLiveSite() ? "sk_live_.*$" : "sk_test_.*$"), "The secret key should start with sk_" + (isLiveSite() ? "live_" : "test_")),
    subscriptionStartDay: Yup.number().integer("Must be a whole number.").min(1, "Must be greater than equal to 1.").max(28, "Must be less than equal to 28."),
    freeTrialPeriod: Yup.number().integer("Must be a whole number.").min(0, "Must be greater than equal to 0.").max(100, "Must be less than equal to 100."),
});

const DAYS = Array.from(Array(28)).map((e, i) => i + 1)

const CompanyPaymentServiceSettingsForm = props => {
    const theme = useTheme();
    const { company } = props;
    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSaveSuccesful, setWasSaveSuccesful] = useState(false);

    const [isLoadingStripeData, setIsLoadingStripeData] = useState(false);
    const [wasLoadSuccesful, setWasLoadSuccesful] = useState(false);

    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const initialValues = {
        paymentServicePublicKey: '',
        paymentServiceSecretKey: '',
        resetWebhookKey: false,
        passOnPaymentServiceFee: company.passOnPaymentServiceFee,
        subscriptionStartDay: company.subscriptionStartDay ? company.subscriptionStartDay : 1,
        // freeTrialPeriod: company.freeTrialPeriod ? company.freeTrialPeriod + '' : '',
        proRateMonth: !company.subscriptionStartDay ? true : company.proRateMonth,
        billingDate: company.subscriptionStartDay ? 'dayofthemonth' : 'dayofsignup',
    };


    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values, currentPublicKey) => {
        setError(null);
        setIsSubmitting(true);
        setWasLoadSuccesful(false);
        try {
            const valuesToSend = cloneDeep(values);
            valuesToSend.subscriptionStartDay = values.billingDate === 'dayofthemonth' ? valuesToSend.subscriptionStartDay : null;
            await dispatch(setCompanyPaymentService(valuesToSend, companyId));
            setWasSaveSuccesful(true);
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values) => {
        submitCallback(company.id, values, company.paymentServicePublicKey);
    };


    const loadCallback = useCallback(async (companyId) => {
        setError(null);
        setIsLoadingStripeData(true);
        setWasLoadSuccesful(false);
        try {
            await dispatch(synchPaymentService(companyId));
            setWasLoadSuccesful(true);
            setIsLoadingStripeData(false);
        } catch (error) {
            setError(error.message);
            setIsLoadingStripeData(false);
        }
    }, [dispatch]);

    const handleLoadStripeData = (values) => {
        loadCallback(company.id, values, company.paymentServicePublicKey);
    };

    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                        <Typography variant="h1" component="h1" gutterBottom>Payment Settings</Typography>
                        <ActionButton
                            type="submit"
                            form="paymentserviceform"
                            variant="contained"
                            color="primary"
                            submitting={isSubmitting}
                            startIcon={<SaveIcon />}
                        >
                            Save Settings
                        </ActionButton>
                    </Box>
                    {error &&
                        <Alert mb={4} severity="error">
                            {error}
                        </Alert>
                    }
                    {wasSaveSuccesful &&
                        <Alert mb={4} severity="success">
                            Save succesful
                        </Alert>
                    }
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setValue,
                            isSubmitting,
                            touched,
                            values,
                            status,
                            setFieldValue,
                        }) => (
                            <form id="paymentserviceform" onSubmit={handleSubmit}>
                                {company.paymentServicePublicKey &&
                                    <Card mb={6} p={4} style={{ backgroundColor: theme.palette.background.paper, border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                        <Typography variant="h3" component="h2" mb={3}>Recurring Billing Date</Typography>
                                        <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>
                                            Set the day your {CUSTOMER_TEXT.toLowerCase()} membership will renew.
                                        </Typography>
                                        <Box display='flex' alignItems='center'>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="Billing Date"
                                                    name="billingDate"
                                                    value={values.billingDate}
                                                    onChange={event => {
                                                        setFieldValue('billingDate', event.target.value);
                                                        // event.target.value === 'dayofthemonth' ? setFieldValue('subscriptionStartDay', 1) : setFieldValue('subscriptionStartDay', '');
                                                    }}
                                                >
                                                    <FormControlLabel value="dayofsignup" control={<Radio />} label="Day of Sign-up" />
                                                    <FormControlLabel value="dayofthemonth" control={<Radio />} label="Day of the Month" />
                                                </RadioGroup>
                                            </FormControl>
                                            <SelectFormControl labelText={'Day'} labelId="select-day-label" style={{ minWidth: '100px', margin: '0' }}>
                                                <Select
                                                    labelId="select-day-label"
                                                    id="freq"
                                                    value={values.subscriptionStartDay}
                                                    onChange={(event) => setFieldValue('subscriptionStartDay', event.target.value)}
                                                    disabled={values.billingDate !== 'dayofthemonth'}
                                                >
                                                    {DAYS.map((day) => (
                                                        <MenuItem
                                                            key={day}
                                                            value={day}
                                                        >
                                                            {ordinalSuffixOf(day)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </SelectFormControl>
                                            {values.billingDate === 'dayofthemonth' &&
                                                <Box ml={2}>
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='proRateMonth' component={Switch} onChange={(val) => setFieldValue('proRateMonth', !values.proRateMonth)} checked={values.proRateMonth} />
                                                        } label={'Prorate partial month, setting this to false will result in no charges until the billing date set.'} />
                                                    </FormGroup>
                                                </Box>
                                            }
                                        </Box>
                                    </Card>
                                }
                                <Card mb={6} p={5} style={{ backgroundColor: theme.palette.background.paper, border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                    <Typography variant="h2" component="h2" mb={3}>Integrate Stripe</Typography>
                                    {!company.paymentServicePublicKey &&
                                        <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>
                                            To recieved credit card payments, please neter your Stripe keys below.
                                        </Typography>
                                    }
                                    {company.paymentServicePublicKey &&
                                        <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>If you are expriencing issues with your Stripe integration try reentering your publishable and secret keys below.</Typography>
                                    }
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="paymentServicePublicKey"
                                                label="Stripe Public Key"
                                                value={values.paymentServicePublicKey}
                                                fullWidth
                                                error={Boolean(touched.paymentServicePublicKey && errors.paymentServicePublicKey)}
                                                helperText={touched.paymentServicePublicKey && errors.paymentServicePublicKey}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="paymentServiceSecretKey"
                                                label="Stripe Secret Key"
                                                value={values.paymentServiceSecretKey}
                                                fullWidth
                                                error={Boolean(touched.paymentServiceSecretKey && errors.paymentServiceSecretKey)}
                                                helperText={touched.paymentServiceSecretKey && errors.paymentServiceSecretKey}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                    {company.paymentServicePublicKey &&
                                        <Alert severity="success" style={{ marginTop: '10px' }}>You have succesfully configured Stripe Payment Service</Alert>
                                    }
                                    {company.paymentServicePublicKey &&
                                        <Box mt={4}>
                                            <ActionButton
                                                variant="contained"
                                                color="primary"
                                                submitting={isLoadingStripeData}
                                                onClick={handleLoadStripeData}
                                                style={{ margin: '0 0 10px 15px', float: 'right' }}
                                            >
                                                Load Stripe Data
                                            </ActionButton>
                                            <Typography ml={2}>This will pull product, customer, payment method and subscription data from stripe into SmartCore.  It may take a couple of minutes so please be patient.</Typography>
                                        </Box>
                                    }
                                    {wasLoadSuccesful &&
                                        <Alert severity="success" mt={2}>You have succesfully loaded data from Stripe into SmartCore.</Alert>
                                    }
                                    {!company.paymentServicePublicKey &&
                                        <React.Fragment>
                                            <ul>
                                                <li><Button componente="a" href="https://www.stripe.com" target="blank">Click here to signup for a Stripe account</Button></li>
                                                <li><Typography variant="body2" ml={2} mb={2}>On the Stripe website verify your business, you cannot use a "test" Stripe account here.</Typography></li>
                                                <li><Typography variant="body2" ml={2} mb={2}>Once you have verified your Stripe account.  Copy and paste the Publishable key and Secret key into the form above.  These can be found in the "Developer" area on Stripe and start with 'pk_live_' and 'sk_live_'</Typography></li>
                                            </ul>
                                            <Typography variant="body2" mb={4}>
                                                As part of the configuration a webhook endpoint will be automatically created in your Stripe account.  Do not edit or delete this end point.
                                            </Typography>
                                            <Typography variant="body2" mb={4}>
                                                Once configured head back to your SmartCore dashboard for next steps.
                                            </Typography>
                                            <Alert severity="warning" mt={2}>Do not create products directly in Stripe as they will not work correctly with SmartCore.</Alert>
                                            <Typography variant="body2" my={4}>
                                                If you need help please reach out to support@smartcore.life.
                                            </Typography>
                                        </React.Fragment>
                                    }
                                </Card>
                            </form>
                        )
                        }
                    </Formik >
                    {error &&
                        <Alert mt={4} severity="error">
                            {error}
                        </Alert>
                    }
                    {wasSaveSuccesful &&
                        <Alert mb={4} severity="success">
                            Save succesful
                        </Alert>
                    }
                    <Box display="flex" justifyContent="end" alignItems="center" mb={4}>
                        <ActionButton
                            type="submit"
                            form="paymentserviceform"
                            variant="contained"
                            color="primary"
                            submitting={isSubmitting}
                            startIcon={<SaveIcon />}
                        >
                            Save Settings
                        </ActionButton>
                    </Box>
                </Box >
            </Grid>
        </Grid>
    )
};

export default CompanyPaymentServiceSettingsForm;

