import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { withTheme } from "styled-components/macro";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import {
  IoLocation as LocationIcon,
  IoPencilOutline as EditIcon,
} from "react-icons/io5";

// svxvy import
import * as SecurityContants from "../../../constants/SecurityConstants";
import { checkClaim } from "../../../helpers/helperFunctions";
import { replaceDisplayName } from "../../../helpers/navigationFunctions";
import LocationForm from "../../../components/company/location/LocationForm";

// constants
const Typography = styled(MuiTypography)(spacing);

const ImageTextBlock = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.paper};
  margin-bottom: 50px;
`;
const ImageTextBlockImage = styled(Box)`
  width: 100%;
  padding-top: 66%;

  ${(props) => props.theme.breakpoints.up("lg")} {
    height: 100%;
    min-height: 224px;
    padding-top: 0;
  }
`;
const ImageTextBlockContent = styled(Box)`
  padding: 22px 30px 24px 30px;

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 40px 59px 40px 59px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const formatImage = (url, format) => {
  if (!url) return "";
  // const scale = size ? "w_" + size + ",c_scale/" : "/";
  const scale = "c_thumb,w_500,c_scale/";
  const scaleImage = url.replace("/upload", "/upload/" + scale);
  const changeFormat = [
    scaleImage.slice(0, scaleImage.lastIndexOf(".")),
    format,
  ].join("");

  return changeFormat;
};

const LocationCard = (props) => {
  const { location, company, isOwner } = props;
  const [isEditing, setIsEditing] = useState(false);

  const address =
    location.address +
    ",+" +
    location.city +
    ",+" +
    location.state +
    "+" +
    location.zip;
  const showMap =
    location.city + ",+" + location.state + "+" + location.zip !== ",++"
      ? true
      : false;

  return (
    <React.Fragment>
      <ImageTextBlock>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <ImageTextBlockImage
              style={{
                backgroundImage:
                  "url(" + formatImage(location.image, ".webp") + ")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            ></ImageTextBlockImage>
          </Grid>
          <Grid item xs={12} lg={8}>
            <ImageTextBlockContent>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Typography component="h3" variant="h3">
                  {location.name}
                </Typography>
                {isOwner && !isEditing && (
                  <IconButton
                    aria-label="Edit FAQ"
                    size="small"
                    color="primary"
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon size={24} />
                  </IconButton>
                )}
              </Box>
              <Typography variant="body2" mb={3}>
                {location.notes}
              </Typography>
              <Typography variant="body2">{location.address}</Typography>
              <Typography variant="body2" mb={3}>
                {location.city +
                  (location.city && (location.state || location.zip)
                    ? ", "
                    : "") +
                  location.state +
                  " " +
                  location.zip}
              </Typography>
              {/* {company.phoneNumber && <Typography variant="body2" mb={3}>Call{company.allowTexts ? ' or text' : ''}: {formatPhoneNumber(company.phoneNumber)}</Typography>} */}
              {showMap && (
                <Link
                  underline="none"
                  color="primary"
                  variant="body2"
                  target="_blank"
                  href={"https://www.google.com/maps/dir/''/" + address}
                  style={{ display: "flex" }}
                >
                  <LocationIcon size={24} /> Map and Directions
                </Link>
              )}
              {/* {showEditButton && editAction && <Button variant="outlined" color="primary" onClick={editAction}>Edit</Button>} */}
            </ImageTextBlockContent>
          </Grid>
        </Grid>
      </ImageTextBlock>
      {isEditing && (
        <LocationForm
          isOpen={isEditing}
          setIsOpen={setIsEditing}
          location={location}
          company={company}
          showInDialog
        />
      )}
    </React.Fragment>
  );
};

const LocationsPage = (props) => {
  const locations = useSelector((state) => state.company.locations).filter(
    (x) => !x.isDeleted
  );
  const company = useSelector((state) => state.company.company);
  const claims = useSelector((state) => state.auth.claims);
  const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);
  const LOCATION_TEXT = useSelector(state => state.company.locationText);

  const [isEditing, setIsEditing] = useState(false);

  let location = useLocation();
  const pageName = replaceDisplayName(
    location.pathname.split("/").slice(-1)[0],
    company
  );

  return (
    <React.Fragment>
      <Helmet title={pageName} />
      {isOwner && (
        <Box style={{ margin: " 0 auto 30px" }}>
          <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
            Add {LOCATION_TEXT}
          </Button>
        </Box>
      )}
      {locations.map((location, index) => {
        return (
          <Grid container mb={21} key={location.id}>
            <Grid item xs={false} lg={2}></Grid>
            <Grid item xs={12} lg={8}>
              <LocationCard
                location={location}
                company={company}
                isOwner={isOwner}
              />
            </Grid>
          </Grid>
        );
      })}
      {isEditing && (
        <LocationForm
          isOpen={isEditing}
          setIsOpen={setIsEditing}
          company={company}
          showInDialog
        />
      )}
    </React.Fragment>
  );
};

export default withTheme(LocationsPage);
