import React from 'react';

import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Dialog,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// constants 
const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
    // (spacing)
    height: 100%;
    background-color: ${(props) => props.theme.palette.background.default};
    box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);

    ${(props) => props.theme.breakpoints.up("md")} {
        height: auto;
        border-radius: 10px;
    }
`;

const CardContent = styled(MuiCardContent)`
    max-height: calc((100%) - 154px);
    padding: 30px !important;
    overflow-y: auto;

    ${(props) => props.theme.breakpoints.up("sm")} {
        max-height: calc(100vh - 218px);
    }
`;

const ErrorDialog = props => {
    const { message, setMessage, severity } = props;
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));


    return (
        <Dialog
            open={message ? true : false}
            onClose={() => { setMessage(null) }}
            fullScreen={isSmallScreen ? true : false}
            fullWidth
            maxWidth='sm'
            disableEnforceFocus
        >
            <Card mb={3} style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} >
                <CardContent>
                    <Typography variant='h3' mb={3}>{severity ? severity : "Error"}</Typography>
                    <Alert severity={severity ? severity : "error"}>{message}</Alert>
                </CardContent>
                <Box style={{ padding: '15px 30px 15px 30px' }}>
                    <Button 
                        onClick={() => setMessage(null) }
                        variant='outlined'
                        fullWidth>
                        Close
                    </Button>
                </Box>
            </Card>
        </Dialog>
    );
}

export default ErrorDialog;