import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

// import * as Yup from "yup";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    FormControlLabel as MuiFormControlLabel,
    FormGroup as MuiFormGroup,
    Grid,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypograhy
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import {
    IoTrash as DeleteIcon,
} from "react-icons/io5";

// svxvy import
import { createOffering, editOffering, deleteOffering } from '../../store/actions/publicWebActions';
import { cleanURL } from '../../helpers/navigationFunctions'
import ActionButton from "../framework/ActionButton";
import StyledDialog from "../framework/StyledDialog";
import UploadPhoto from "../company/photos/UploadPhoto";

//constants
const Alert = styled(MuiAlert)(spacing);
const FormGroup = styled(MuiFormGroup)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypograhy)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
    margin: 0;

    span {
        margin: 0;
    }

    .MuiSwitch-root {
        margin-left: -12px;
    }
`;


const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0 26px 0 0;
    padding: 0 2px;
    font-weight: 400;
    font-size: 14px;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    &:last-child {
        margin: 0;
    }
`;

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;

const validationSchema = Yup.object().shape({
    sortOrder: Yup.number("Must be a whole number.")
        .min(0, "Must be greater than 0")
        .integer("Must be a whole number."),
});

const EditOfferingModal = props => {
    const theme = useTheme();

    const { offering, open, setOpen } = props;
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [photoCleared, setPhotoCleared] = useState(false);

    const company = useSelector(state => state.company.company);

    const dispatch = useDispatch();
    const initialValues = {
        id: offering ? offering.id : null,
        title: offering && offering.title ? offering.title : '',
        description: offering && offering.description ? offering.description : '',
        sortOrder: offering && offering.sortOrder ? offering.sortOrder : 1,
        photo: null,
        ctaText: offering && offering.ctaText ? offering.ctaText : '',
        ctaLink: offering && offering.ctaLink ? offering.ctaLink : '',
        ctaOpenInNewWindow: offering ? offering.ctaOpenInNewWindow : false,
        isExternalLink: offering ? offering.isExternalLink : false,
    };
    const submitCallback = useCallback(async (companyId, offeringId, values, setSubmitting, setOpen) => {
        setError(null);
        try {
            setIsSubmitting(true);
            if (!offeringId)
                await dispatch(createOffering(companyId, values));
            else
                await dispatch(editOffering(companyId, offeringId, values));
            setIsSubmitting(false);
            if (setOpen)
                setOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
        setPhotoCleared(false);
        setPhoto(null);
    }, [dispatch]);

    const deleteCallback = useCallback(async (companyId, offeringId, setOpen) => {
        setError(null);
        setIsDeleting(true);
        try {
            await dispatch(deleteOffering(companyId, offeringId));
            setIsDeleting(false);
            if (setOpen)
                setOpen(false);
        } catch (error) {
            setIsDeleting(false);
            setError(error.message);
        }
    }, [dispatch]);

    const handleSubmit = (values, { setSubmitting }) => {
        const valuesToSend = cloneDeep(values)
        valuesToSend.ctaLink = valuesToSend.isExternalLink ? values.ctaLink : cleanURL(company.sanitizedName, values.ctaLink);
        valuesToSend.photo = photo && offering && offering.photo && offering.photo.id === photo.id ? null : photo;
        valuesToSend.sortOrder = values.sortOrder ? parseInt(values.sortOrder) : 1;
        valuesToSend.removeCurrentImage = photoCleared;
        submitCallback(company.id, values.id, valuesToSend, setSubmitting, setOpen);
    };

    const handleDelete = () => {
        deleteCallback(company.id, offering.id, setOpen);
    };

    const handleClose = () => {
        setPhoto(null);
        setPhotoCleared(false);
        setError(null);
        setOpen(false)
    }

    return (
        <StyledDialog
            open={open}
            setOpen={handleClose}
            aria-labelledby="act-dialog-title"
            actions={
                <UtilityButtonWrapper>
                    {offering && <UtilityButton
                        type="submit"
                        onClick={handleDelete}
                        disabled={isSubmitting}
                        submitting={isDeleting}
                        ml={10}
                        startIcon={<DeleteIcon size={24} style={{ color: theme.palette.error.main }} />}
                    >
                        Delete
                    </UtilityButton>}
                </UtilityButtonWrapper>
            }
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isDeleting}
                        submitting={isSubmitting}
                        form="editOfferingForm"
                        fullWidth
                    >
                        Save
                    </ActionButton>
                </React.Fragment>
            }
        >
            <Box>
                {error && <Grid item xs={12}>
                    <Alert my={2} severity="error">
                        {error}
                    </Alert>
                </Grid>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        touched,
                        values,
                        status,
                    }) => (
                        <form id="editOfferingForm" onSubmit={handleSubmit}>
                            <Box>
                                <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Offering Details</Typography>
                                <Typography mb={2}>Select an image to display on the page (width 600px, height dependant on text)</Typography>
                                <UploadPhoto
                                    id='offeringImage'
                                    image={photo ? photo : offering && offering.photo && !photoCleared ? offering.photo : null}
                                    onSuccess={(image) => {
                                        setPhoto(image);
                                        setPhotoCleared(true);
                                        setOpen(true);
                                    }}
                                    removePhoto={() => { setPhotoCleared(true); setPhoto(null) }}
                                />
                                <TextField
                                    name="title"
                                    label="Title"
                                    value={values.title}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                                <TextField
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                    maxRows={15}
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                                <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Call to Action</Typography>
                                <TextField
                                    name="ctaText"
                                    label="Button Text"
                                    value={values.ctaText}
                                    error={Boolean(touched.ctaText && errors.ctaText)}
                                    helperText={touched.ctaText && errors.ctaText}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                                <TextField
                                    name="ctaLink"
                                    label="URL"
                                    value={values.ctaLink}
                                    error={Boolean(touched.ctaLink && errors.ctaLink)}
                                    helperText={touched.ctaLink && errors.ctaLink}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                                <Box display="flex">
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Field name='ctaOpenInNewWindow' component={Switch} onChange={(val) => setFieldValue('ctaOpenInNewWindow', !values.ctaOpenInNewWindow)} checked={values.ctaOpenInNewWindow} />
                                        } label={'Open Link in new tab'}></FormControlLabel>
                                    </FormGroup>
                                    <FormGroup ml={2}>
                                        <FormControlLabel control={
                                            <Field name='isExternalLink' component={Switch} onChange={(val) => setFieldValue('isExternalLink', !values.isExternalLink)} checked={values.isExternalLink} />
                                        } label={'Is External Link'}></FormControlLabel>
                                    </FormGroup>
                                </Box>
                                <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Sort Order</Typography>

                                <TextField
                                    name="sortOrder"
                                    label="Numeric value used for sorting offerings"
                                    value={values.sortOrder}
                                    error={Boolean(touched.sortOrder && errors.sortOrder)}
                                    helperText={touched.sortOrder && errors.sortOrder}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                            </Box>
                        </form>
                    )}
                </Formik>
                {error && <Grid item xs={12}>
                    <Alert my={2} severity="error">
                        {error}
                    </Alert>
                </Grid>}
            </Box>
        </StyledDialog>
    );
};

export default EditOfferingModal;