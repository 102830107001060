import moment from 'moment';
import { compareObjects, isToday, isTomorrow } from "../../../helpers/helperFunctions";

export const BY_DAY = 'BY_DAY';
export const BY_ACTIVITY = 'BY_ACTIVITY';
export const BY_PERSON = 'BY_PERSON';

export const getDateHeading = (date, mainHeading) => {
    if (isToday(date))
        return mainHeading ? moment(date).format('MMMM Do') : 'Today';
    if (isTomorrow(date))
        return mainHeading ? moment(date).format('MMMM Do') : 'Tomorrow';
    else
        return mainHeading ? moment(date).format('MMMM Do') : moment(date).format('dddd');
}

// 
// Returns the date of the next day. If today is friday and we are asking for next friday the friday of the next week is returned.
// @param dayOfWeek 0:Su,1:Mo,2:Tu,3:We,4:Th,5:Fr,6:Sa
// 
const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
const getNextDayOfWeek = (date, dayOfWeek) => {
    var resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay() - 1) % 7 + 1);
    return resultDate;
}

const getActivitiesPerDaysArray = (start, end, activities, additionalKey = 'none') => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        const startDate = new Date(dt);
        const endDate = new Date(startDate.getTime() + 86400000); //one day.
        const activitiesOnDay = activities.filter(x => x.displaybleStartDateTime >= startDate && x.displaybleStartDateTime <= endDate && !x.isDeleted).sort(compareObjects('displaybleStartDateTime', 'asc'));
        if (activitiesOnDay.length > 0) {
            arr.push({
                key: 'id_' + additionalKey + '_' + startDate.getTime(), dayHeading: new Date(startDate), activities: activitiesOnDay
            });
        }
    }
    return arr;
};

const getActivitiesByWeekArray = (start, end, activities) => {
    const today = new Date();
    const nextWeek = new Date(new Date().setDate((new Date().getDate()) + 7))
    for (var arr = [], dt = new Date(start), week = 0; dt <= end; dt = getNextDayOfWeek(dt, daysOfWeek.indexOf('Su')), week += 1) {
        const startDate = new Date(dt);
        const endDate = startDate.getDay() === 6 ? new Date(startDate).setHours(23, 59,59,99) : getNextDayOfWeek(startDate, daysOfWeek.indexOf('Sa')).setHours(23, 59,59,99); //one day.
        const activitiesInGroup = activities.filter(x => x.displaybleStartDateTime >= startDate && x.displaybleStartDateTime <= endDate && !x.isDeleted).sort(compareObjects('displaybleStartDateTime', 'asc'));
        if (activitiesInGroup.length > 0) {
            const activitiesToSend = getActivitiesPerDaysArray(start, end, activitiesInGroup, startDate);
            if (activitiesToSend.length > 0) {
                let headingText = moment(startDate).format('MMMM DD') + ' - ' + moment(endDate).format('MMMM DD');
                if (startDate <= today && endDate > today)
                    headingText = 'This Week'
                if (startDate <= nextWeek && endDate > nextWeek)
                    headingText = 'Next Week'
                arr.push({
                    key: 'id_' + startDate.getTime(), heading: headingText, headingIsDate: false, activitiesByDay: activitiesToSend, openByDefault: week > 1 ? false : true
                });
            }
        }
    }
    return arr;
};


const getActivitiesByActivity = (activities, start, end) => {
    const activityNames = [...new Set(activities.map(x => x.title))].sort();
    const arr = [];
    for (let i = 0; i < activityNames.length; i++) {
        const activitiesInGroup = activities.filter(x => x.title === activityNames[i]).sort(compareObjects('displaybleStartDateTime', 'asc'));
        if (activitiesInGroup.length > 0) {
            const activitiesToSend = getActivitiesPerDaysArray(start, end, activitiesInGroup, activityNames[i]);
            if (activitiesToSend.length > 0) {
                if (activitiesInGroup.length > 0) {
                    arr.push({
                        key: 'id' + activityNames[i], heading: activityNames[i], headingIsDate: false, activitiesByDay: activitiesToSend, openByDefault: false
                    });
                }
            }
        }
    }
    return arr;
};

const getActivitiesByPerson = (activities, people, start, end) => {
    const uniquePeopleSet = new Set();
    for (const a in activities) {
        const p = activities[a].people.filter(y => y.isRunningActivity).map(y => y.appUserId);
        for (const id in p)
            uniquePeopleSet.add(p[id]);
    }
    const uniquePeople = [...uniquePeopleSet];
    const arr = [];
    for (let i = 0; i < uniquePeople.length; i++) {
        const activitiesInGroup = activities.filter(x => x.people.filter(y => y.appUserId === uniquePeople[i] && y.isRunningActivity && x.isInActivityInstance(y, x)).length > 0).sort(compareObjects('displaybleStartDateTime', 'asc'));
        activitiesInGroup.sort(compareObjects('displaybleStartDateTime', 'asc'))
        if (activitiesInGroup.length > 0) {
            const activitiesToSend = getActivitiesPerDaysArray(start, end, activitiesInGroup, uniquePeople[i]);
            if (activitiesInGroup.length > 0) {
                arr.push({
                    key: 'id' + uniquePeople[i], heading: people.find(x => x.id === uniquePeople[i]).displayName, headingIsDate: false, activitiesByDay: activitiesToSend, openByDefault: false
                });
            }
        }
    }
    return arr;
};

export const groupActivities = (start, end, activities, people, grouping) => {
    switch (grouping) {
        case BY_DAY:
            return getActivitiesByWeekArray(start, end, activities);
        case BY_ACTIVITY:
            return getActivitiesByActivity(activities, start, end);
        case BY_PERSON:
            return getActivitiesByPerson(activities, people, start, end);
        default:
            return getActivitiesPerDaysArray(start, end, activities);
    }
}