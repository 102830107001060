import React from 'react';
import { useSelector } from 'react-redux';

// UI Imports

import { withTheme } from "styled-components/macro";
import { Helmet } from 'react-helmet-async';

import {
  Grid,
  Typography
} from "@material-ui/core";
// svxvy imports
import ManagePaymentMethods from "../../../components/company/paymentMethods/ManagePaymentMethods";

// UI Consts

const Profile = props => {

  const company = useSelector(state => state.company.company);
  const currentUser = useSelector(state => state.auth.person);

  return (
    <React.Fragment>
      {currentUser && <Helmet title={currentUser.displayName + " - " + (company ? company.name : "")} />}
      {currentUser &&
        <Grid container>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <ManagePaymentMethods companyId={company.id} userId={currentUser.id} showTenantPaymentMethods={true} open={true} />
          </Grid>
        </Grid>
      }
      {!currentUser &&
        <React.Fragment>
          <Helmet title={"Profile not found - " + (company ? company.name : "")} />
          <Typography variant="h2">Hmm... we couldn't find this profile.</Typography>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default withTheme(Profile);