import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { cloneDeep } from 'lodash';

// UI Imports
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Chip as MuiChip,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// svxvy imports
import ActivityCard from './ActivityCard'
import { compareObjects } from '../../../helpers/helperFunctions';

// UI Consts
const Chip = styled(MuiChip)(spacing);

// helper functions

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const isTomorrow = (someDate) => {
    const today = new Date(new Date().getTime() + 86400000);
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
}


const getActivitiesPerDaysArray = (start, end, activities, priorState) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        const startDate = new Date(dt);
        const endDate = new Date(startDate.getTime() + 86400000); //one day.
        const activitiesOnDay = activities.filter(x => x.startDateTime >= startDate && x.startDateTime <= endDate && !x.isDeleted).sort(compareObjects('displaybleStartDateTime', 'asc'));
        const priorStateItem = priorState.find(x => x.day.toUTCString() === startDate.toUTCString());
        arr.push({ day: new Date(startDate), activitiesOnDay: activitiesOnDay, isOpen: priorStateItem 
            ? priorStateItem.isOpen 
            : (priorState.length === 0 && (isToday(startDate) || isTomorrow(startDate)) && activitiesOnDay.length > 0 ? true : false) });
    }
    return arr;
};


const ActivitisListByDay = props => {
    const activities = useSelector(state => state.activity.activities).sort(compareObjects('displaybleStartDateTime', 'asc'));
    const [datesToShow, setDatesToShow] = useState(getActivitiesPerDaysArray(props.startDate, props.endDate, activities, []));

    //==================================================================

    useEffect(() => {
        setDatesToShow(prevItems => getActivitiesPerDaysArray(props.startDate, props.endDate, activities, prevItems));
    }, [props.startDate, props.endDate, activities]);

    //================================================================== events

    const handleExpandDay = (dayToUpdate) => {
        const datesToUpdate = cloneDeep(datesToShow);
        const itemToUpdate = datesToUpdate.find(x => x.day.toUTCString() === dayToUpdate.day.toUTCString());
        if (itemToUpdate && itemToUpdate.activitiesOnDay.length > 0)
            itemToUpdate.isOpen = !itemToUpdate.isOpen;
        setDatesToShow(datesToUpdate);
    };

    return (
        <Box pr={4} minWidth="100%">
            <List aria-labelledby="list-of-events-by-day" >
                {datesToShow.map((day, ind) => {
                    return (
                        <Box key={day.day.toUTCString()} mb={2}>
                            <Box bgcolor="background.paper" >
                                <ListItem button={day.activitiesOnDay.length > 0 ? true : false} onClick={() => handleExpandDay(day)}>
                                    <ListItemText disableTypography
                                        primary={<Box>
                                            <Typography component="span">{(isToday(day.day) ? "Today - " : isTomorrow(day.day) ? "Tomorrow - " : "") + moment(day.day).format(isToday(day.day) || isTomorrow(day.day) ? 'll' : 'dddd - ll')}</Typography>
                                            <Chip label={day.activitiesOnDay.length} color="secondary" size="small" variant="outlined" ml={3} />
                                        </Box>
                                        }
                                    />

                                    {day.activitiesOnDay.length > 0 && <React.Fragment>
                                        {day.isOpen ? <ExpandLess /> : <ExpandMore />}
                                    </React.Fragment>}
                                </ListItem>
                                <Collapse in={day.isOpen} timeout="auto" unmountOnExit>
                                    <List>
                                        {day.activitiesOnDay.map((activity, ind) => {
                                            return (
                                                <ActivityCard key={activity.id} activity={activity} viewStartDate={props.viewStartDate} viewEndDate={props.viewEndDate} />
                                            )
                                        })
                                        }
                                    </List>
                                </Collapse>
                            </Box>
                        </Box>
                    )
                })}
            </List>
        </Box>
    );
}

export default withTheme(ActivitisListByDay);
