import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Redirect } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Button,
  Card as MuiCard,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,

  // Container,
  // Grid,
  // CircularProgress as MuiCircularProgress,
  // Button as MuiButton,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoChevronForward as RigthIcon,
} from "react-icons/io5";


// svxvy imports
import { SET_NO_COMPANY } from "../../store/actions/companyActions";
import { refreshLogedInUser } from "../../store/actions/authActions";
import { logout } from "../../store/actions/authActions";
import { compareObjects } from "../../helpers/helperFunctions";
import * as NavigationConstants from '../../constants/NavigationConstants';

// constants

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 30px 0;
  padding: 22px 15px 20px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 22px 30px 20px 30px;
  }
`;
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CompanyBox = styled(Box)`
    background: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 14px 11px 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
`;


function CompanyListPage() {
  const theme = useTheme();
  const history = useHistory();

  const loggedInUser = useSelector(state => state.auth.person);
  const token = useSelector(state => state.auth.token);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  const dispatch = useDispatch();
  const loadUser = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      await dispatch({ type: SET_NO_COMPANY })
      await dispatch(refreshLogedInUser());
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const handleSignOut = async () => {
    await dispatch(logout());
  };

  const goToSite = useCallback(async (userToken, companyId, sanitizedCompanyName) => {
    setIsLoading(true);
    setError(null);
    try {
      await dispatch(refreshLogedInUser(userToken, companyId));
      history.push("/" + sanitizedCompanyName + "/" + NavigationConstants.DASHBOARD);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }, [dispatch, history]);

  const handleGoToSite = (companyId, sanitizedCompanyName) => {
    goToSite(token, companyId, sanitizedCompanyName)
  }


  if (!loggedInUser)
    return <Redirect to={"/"} />;

  return (
    <Box style={{ width: '100%' }}>
      {error && <Alert severity="error">{error}</Alert>}
      {isLoading && !error &&
        <Box display="flex" flexDirection="column" flex={1} m={10}>
          <LinearProgress my={2} color="primary" />
          <Typography variant={"h2"} align='center' gutterBottom>Loading...</Typography>
          <LinearProgress my={2} color="secondary" />
        </Box>
      }
      {!isLoading &&
        <Box>
          <Card>
            <Typography variant="h2" style={{ margin: '0 0 16px 0', fontSize: '28px', lineHeight: '30px' }}>Select a Business</Typography>
            {loggedInUser.additionalProfiles.sort(compareObjects('companyName', 'asc')).map((profile, index) => {
              return (
                <CompanyBox key={profile.companyId} onClick={() => handleGoToSite(profile.companyId, profile.sanitizedCompanyName)}>
                  <Typography variant="body2">{profile.companyName}</Typography>
                  <RigthIcon size={14} color={theme.palette.primary.main} style={{ marginTop: '1px' }} />
                </CompanyBox>
              )
            })}
          </Card>
          {loggedInUser &&
            <Typography variant="body1" align='center' style={{ fontSize: '14px', lineHeight: '18px' }}>
              Not {loggedInUser.displayName}
              <Typography
                component="span"
                variant='body2'
                color='primary'
                onClick={handleSignOut}
                style={{ cursor: 'pointer', fontSize: '14px', lineHeight: '18px' }}> click here.</Typography>
            </Typography>
          }
          <Button
            variant="outlined"
            color="primary"
            component={NavLink}
            to={"/" + NavigationConstants.REGISTER}
            fullWidth
            exact
          >
            Register Another Business
          </Button>
        </Box>
      }
    </Box >
  );
}

export default CompanyListPage;
