import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Grid,
    LinearProgress as MuiLinearProgress,
    List,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";


// svxvy import

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions'; 
import { fetchCompanyPurchases } from '../../../store/actions/companyActions';
import { groupPurchases } from '../../../pages/company/reports/billingGroupingFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { refreshLogedInUser } from '../../../store/actions/authActions';
import PurchaseGroup from '../../../components/company/purchases/Reporting/PurchaseGroupYear';
// UI Consts

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const getCurrentUserPurchases = (currentUser) => {
    let purchases = currentUser.purchases.filter(p => p.isTentantPurchase);

    for (const cp in currentUser.childProfiles) {
        // console.log(currentUser.childProfiles[cp].purchases.filter(p => p.isTentantPurchase))
        purchases = [...purchases, ...currentUser.childProfiles[cp].purchases.filter(p => p.isTentantPurchase)]
    }

    return purchases;
}

const CompanyPurchaseList = props => {
    const company = useSelector(state => state.company.company);
    const people = useSelector(state => state.company.people);

    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowUpdatePayments = currentUser && checkClaim(currentUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const companyPurchases = useSelector(state => state.company.purchases).filter( x => x.status !== "past_due" ).sort(compareObjects('datePurchased', 'desc'));
    const filteredPurchases = groupPurchases(allowUpdatePayments ? companyPurchases.filter(p => p.isTentantPurchase) : getCurrentUserPurchases(currentUser),
        (allowUpdatePayments ? people : [...currentUser.childProfiles, currentUser]));

    const dispatch = useDispatch();
    /********************* load data ************************ */
    const loadPuchases = useCallback(async () => {
        try {
            setIsLoading(true);
            if (allowUpdatePayments) {
                await dispatch(fetchPeople(company.id));
                await dispatch(fetchCompanyPurchases(company.id));
            }
            else {
                await dispatch(refreshLogedInUser());
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [company, dispatch, allowUpdatePayments]);

    useEffect(() => {
        loadPuchases();
    }, [loadPuchases]);


    return (
        <React.Fragment>
            {error &&
                <Grid container alignContent="center">
                    <Alert mb={4} severity="error">{error}</Alert>
                </Grid>
            }
            {isLoading
                && <Grid container alignContent="center">
                    <Grid item xs={12} >
                        <LinearProgress my={2} color="primary" />
                        <Typography variant={"h2"} gutterBottom>Refreshing data...</Typography>
                        <LinearProgress my={2} color="secondary" />
                    </Grid>
                </Grid>
            }
            {filteredPurchases.length === 0 && !isLoading &&
                <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                    <Typography variant="h3">No payments have been made.</Typography>
                </Box>
            }
            {filteredPurchases.length > 0 &&
                <List style={{ margin: '0 0 20px 0', padding: 0 }}>
                    {filteredPurchases.map((group, index) => {
                        return (
                            <PurchaseGroup group={group} key={group.key} company={company} />
                        )
                    })}
                </List>
            }
        </React.Fragment>

    );
}

export default CompanyPurchaseList;
