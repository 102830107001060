class PaymentMethod {
    constructor(id, paymentService, paymentMethodId, lastFourOnCard, expirationMonth, expirationYear, zipCode, companyId, isDefault, isParentCard) {
        this.id = id;
        this.paymentService = paymentService;
        this.paymentMethodId = paymentMethodId;
        this.lastFourOnCard = lastFourOnCard;
        this.expirationMonth = expirationMonth;
        this.expirationYear = expirationYear;
        this.zipCode = zipCode;
        this.companyId = companyId; // set this to null to go against svxvy payment service
        this.isDefault = isDefault;
        this.isParentCard = isParentCard;
    }
}

export default PaymentMethod;