import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment as MuiInputAdornment,
    LinearProgress as MuiLinearProgress,
    List,
    MenuItem,
    Select as MuiSelect,
    Tabs as MuiTabs,
    Tab as MuiTab,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoChevronDown as ChevronDown,
    IoSearchOutline as SearchIcon,
} from "react-icons/io5";

// svxvy import

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
// import { fetchPaymentRecords } from '../../../store/actions/paymentActions';
import { fetchCompanyPurchases } from '../../../store/actions/companyActions';
import { getMembershipsByPerson, BY_MEMBERSHIP, BY_PERSON, ACTIVE_MEMBERSHIPS_GROUP, ERROR_MEMBERSHIPS_GROUP, EXPIRED_MEMBERSHIPS_GROUP } from './billingGroupingFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { refreshLogedInUser } from '../../../store/actions/authActions';
import PersonSubscription from '../../../components/company/purchases/Reporting/PersonSubscription';
import ReportGroup from '../../../components/framework/ReportGroup';
import SubscriptionListItem from '../../../components/company/purchases/SubscriptionListItem';

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  margin: 0 0 30px 0;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
    margin: 0;
    float: right;

    input {
        width: 250px;
    }
  }
`;

const InputAdornment = styled(MuiInputAdornment)`
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 9px;

    svg {
        width: 18px;
        height: 18px;
        color: ${(props) => props.theme.palette.primary.main};
        opacity: 1;
    }
`;

const Tabs = styled(MuiTabs)`
    height: 35px;
    min-height: auto;
    max-height: none;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        height: 100%;
    }
`;

const Tab = styled(MuiTab)`
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: 0 29px 0 29px;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;

    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;

const Select = styled(MuiSelect)`
    margin: 0 25px 0 0;


    .MuiSelect-select {
        padding: 0 25px 0 0;
        font-weight: 700;
    }

    .MuiTypography-root {
        font-weight: 700;
    }

    .MuiSelect-icon {
        margin-top: 0;
        margin-right: 0;
    }

    svg {
        top: 1px;
        right: 4px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
        margin: 0 0 0 0;
    
    }
`;

const MembershipItem = styled(Box)`
    padding: 17px 15px 15px 15px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};

    p {
        margin: 0;
    }

    &:last-child {
        border-bottom: none;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 0 0 30px;
        padding: 21px 15px 18px 0;
    }
`;






const Billing = props => {
    const theme = useTheme();

    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const company = useSelector(state => state.company.company);
    const people = useSelector(state => state.company.people);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const currentUser = useSelector(state => state.auth.person);

    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowUpdatePayments = currentUser && checkClaim(currentUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [activeTab, setActiveTab] = useState(ACTIVE_MEMBERSHIPS_GROUP);
    const [grouping, setGrouping] = useState(BY_MEMBERSHIP);

    const companyPurchases = useSelector(state => state.company.purchases).sort(compareObjects('datePurchased', 'desc'));
    const subscriptions = getMembershipsByPerson(allowUpdatePayments ? companyPurchases.filter(p => p.isTentantPurchase) : currentUser.purchases.filter(p => p.isTentantPurchase), people, grouping);
    const subscriptionsToShow = activeTab === ACTIVE_MEMBERSHIPS_GROUP ? subscriptions.activeSubscriptions
        : activeTab === ERROR_MEMBERSHIPS_GROUP ? subscriptions.errorSubsciptions : subscriptions.inactiveSubsciptions;
    const groupDataToShow = activeTab === ACTIVE_MEMBERSHIPS_GROUP ? subscriptions.groupedActiveSubscriptions
        : activeTab === ERROR_MEMBERSHIPS_GROUP ? subscriptions.groupedErrorSubscriptions : subscriptions.groupedInactiveSubscriptions;

    // subscriptions.groupedErrorSubscriptions[keyValue].filter(x => x.personName.includes(search));

    const sortOptions = [
        {
            id: BY_MEMBERSHIP,
            name: "Membership"
        }, {
            id: BY_PERSON,
            name: CUSTOMERS_TEXT,
        }
    ]


    const dispatch = useDispatch();
    /********************* load data ************************ */
    const loadMessages = useCallback(async () => {
        try {
            setIsLoading(true);
            if (allowUpdatePayments) {
                await dispatch(fetchPeople(company.id));
                await dispatch(fetchCompanyPurchases(company.id));
            }
            else {
                await dispatch(refreshLogedInUser());
            }
            // await dispatch(fetchPaymentRecords(company.id));

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [company, dispatch, allowUpdatePayments]);

    useEffect(() => {
        loadMessages();
    }, [loadMessages]);


    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <Helmet title="Memberships" />
                {error &&
                    <Grid container alignContent="center">
                        <Alert mb={4} severity="error">{error}</Alert>
                    </Grid>
                }
                {isLoading
                    && <Grid container alignContent="center">
                        <Grid item xs={12} >
                            <LinearProgress my={2} color="primary" />
                            <Typography variant={"h2"} gutterBottom>Loading payments...</Typography>
                            <LinearProgress my={2} color="secondary" />
                        </Grid>
                    </Grid>
                }
                {!error && !isLoading &&
                    <Box>
                        <Grid container>
                            <Grid item xs={12} xl={6} style={{ display: 'flex', marginBottom: '25px' }}>
                                <Box display='flex' flexDirection={isMobile ? 'column' : 'row'}>
                                    <Box display='flex' flexDirection='column'>
                                        <Typography variant="h1">Memberships</Typography>
                                        <Box display='flex' flexDirection='row' minWidth='250px' mt={2}>
                                            <Typography component="p" variant="body2" mr={2} style={{ color: theme.palette.text.secondary, whiteSpace: 'nowrap' }}>Group By</Typography>
                                            <Select
                                                labelId="select-locationId-label"
                                                id="locationId"
                                                value={grouping}
                                                onChange={(event) => setGrouping(event.target.value)}
                                                IconComponent={ChevronDown}
                                                disableUnderline={true}
                                                ml={1}
                                            >
                                                {sortOptions.map((option) => (
                                                    <MenuItem key={option.id} value={option.id} selected={option === grouping ? true : false}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                    <Tabs
                                        value={activeTab}
                                        onChange={(event, newValue) => setActiveTab(newValue)}
                                        indicatorColor="primary"
                                        centered
                                    >
                                        <Tab value={ACTIVE_MEMBERSHIPS_GROUP} label={ACTIVE_MEMBERSHIPS_GROUP} />
                                        <Tab value={ERROR_MEMBERSHIPS_GROUP} label={ERROR_MEMBERSHIPS_GROUP} />
                                        <Tab value={EXPIRED_MEMBERSHIPS_GROUP} label={EXPIRED_MEMBERSHIPS_GROUP} />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="standard-search"
                                    type="search"
                                    placeholder="Search"
                                    onChange={e => setSearch(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <React.Fragment>
                            {activeTab === ACTIVE_MEMBERSHIPS_GROUP && subscriptions.activeSubscriptions.length === 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                    <Typography variant="h3">No active subscriptions issues identified.</Typography>
                                </Box>
                            }
                            {activeTab === ERROR_MEMBERSHIPS_GROUP && subscriptions.errorSubsciptions.length === 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                    <Typography variant="h3">No subscriptions with errors found.</Typography>
                                </Box>
                            }
                            {activeTab === EXPIRED_MEMBERSHIPS_GROUP && subscriptions.inactiveSubsciptions.length === 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                    <Typography variant="h3">No expired subscriptions were found.</Typography>
                                </Box>
                            }
                            {subscriptionsToShow.length > 0 && groupDataToShow &&
                                <React.Fragment>
                                    {Object.keys(groupDataToShow).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })).map((keyValue, index) => {
                                        const filteredData = groupDataToShow[keyValue].filter(x => x.personName.includes(search));
                                        return (
                                            <ReportGroup
                                                key={keyValue}
                                                heading={keyValue.split('|')[0]}
                                                total={filteredData.length}
                                                company={company}>
                                                <Card style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                                    <CardContent style={{ padding: '0 14px 0 14px' }}>
                                                        <List style={{ margin: '0 0 0 0', padding: 0 }}>
                                                            {filteredData.map((group, index) => {
                                                                return (
                                                                    <React.Fragment key={group.key}>
                                                                        {grouping === BY_MEMBERSHIP &&
                                                                            <PersonSubscription
                                                                                person={group.person}
                                                                                subscription={group.subscription}
                                                                                showRefresh
                                                                                priorStatus={activeTab}
                                                                                company={company} />
                                                                        }
                                                                        {grouping === BY_PERSON &&
                                                                            <MembershipItem>
                                                                                <SubscriptionListItem purchase={group.subscription} />
                                                                            </MembershipItem>
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </List>
                                                    </CardContent>
                                                </Card>
                                            </ReportGroup>
                                        )
                                    })}
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Box>
                }
            </Grid>
        </Grid >
    );
}

export default Billing;
