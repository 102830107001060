
import React, { useState, useCallback } from "react";
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@material-ui/core/styles';

import { Formik, Form } from 'formik'
import * as Yup from "yup";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    Card as MuiCard,
    Switch as MuiSwitch,
    TextField as MuiTextField,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoRadioButtonOffOutline as RadioButtonOff,
    IoRadioButtonOn as RadioButtonOn,
    IoCloseCircle as CardExpired
} from "react-icons/io5";


// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { ordinalSuffixOf, checkClaim, GUID_Generator } from '../../../helpers/helperFunctions'
import { buyProduct } from "../../../store/actions/productActions";
import { isCardExpired } from '../../../helpers/commerceFunctions';
import { addPaymentMethod } from "../../../store/actions/paymentActions";

import ActionButton from "../../framework/ActionButton";
import InvoiceCard from '../purchases/InvoiceCard';
import ProfileCard from '../profile/ProfileCard';
import PaymentMethod from '../../../models/PaymentMethod'
import StyledDialog from "../../framework/StyledDialog"


//consants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const Switch = styled(MuiSwitch)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);


const BuyAProductModal = props => {
    const { open, setOpen, displayedUser, product } = props;
    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();

    const company = useSelector(state => state.company.company);

    const loggedInUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowEdit = checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);
    const viewReports = checkClaim(currentUserClaims, SecurityConstants.VIEW_REPORTS)

    const parentAccount = useSelector(state => state.company.people).find(x => x.id === displayedUser.parentAccountId);

    const parentPaymentMethods = allowEdit && displayedUser.parentAccountId
        ? parentAccount.paymentMethods.filter(x => x.companyId === displayedUser.companyId) :
        loggedInUser.childProfiles.some(x => x.id === displayedUser.id) ? loggedInUser.paymentMethods.filter(x => x.companyId === displayedUser.companyId) : [];

    const displayedUserPaymentMethods = displayedUser.paymentMethods.filter(x => x.companyId === displayedUser.companyId && !x.isParentCard);
    const paymentMethodToShow = [...displayedUserPaymentMethods, ...parentPaymentMethods]

    // const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey);

    const [couponCode, setCouponCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [isSubmissionSuccesful, setIsSubmissionSuccesful] = useState(false);
    // const [purchasedItem, setPurchasedItem] = useState(null);
    const [error, setError] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(displayedUserPaymentMethods.length > 0
        ? displayedUserPaymentMethods.find(x => x.isDefault && !isCardExpired(x))
        : parentPaymentMethods.length > 0 ? parentPaymentMethods.find(x => x.isDefault && !isCardExpired(x)) : null)

    const hasAnyExistingDefaultPaymentMethod = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.filter(x => x.companyId === company.id && x.isDefault).length > 0 : null;

    const [subscriptionStartDate, setSubscriptionStartDate] = useState(product && product.freeTrialDays
        ? moment().add(product.freeTrialDays, 'd').toDate()
        : null);
    const [useNewCard, setUseNewCard] = useState(paymentMethodToShow.length === 0 ? true : false);
    const [storeCard, setStoreCard] = useState(true);
    const [itemPurchased, setItemPurchased] = useState(null);

    const [quantity, setQuantity] = useState('1');
    const [ownerDiscount, setOwnerDiscount] = useState('0');
    const [isCashPurchase, setIsCashPurchase] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const isFreeProduct = product.unitAmount > 0 && ((product.unitAmount * quantity) - ownerDiscount) > 0 ? false : true;

    const dispatch = useDispatch();
    const productPurchase = useCallback(async (paymentMethod, productId, companyId, quantity, ownerDiscount, coupon, startDate, displayedUser, profilePaymentMethods, isCashPurchase) => {
        setError(null);
        setIsSubmitting(true);

        let pm = paymentMethod;
        try {

            if ((useNewCard || !profilePaymentMethods || profilePaymentMethods.length === 0) && !isFreeProduct && !isCashPurchase) {
                const cardElement = elements.getElement(CardElement);
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });
                if (error) {
                    throw new Error("Error: " + error.code + " " + error.message)
                }
                else {
                    pm = paymentMethod;
                }
                pm = new PaymentMethod(GUID_Generator(),
                    "stripe",
                    paymentMethod.id,
                    paymentMethod.card.last4,
                    paymentMethod.card.exp_month,
                    paymentMethod.card.exp_year,
                    paymentMethod.billing_details.address.postal_code,
                    companyId,
                    !hasAnyExistingDefaultPaymentMethod,
                    false);
                pm.addToTenant = true;
                if (storeCard) {
                    await dispatch(addPaymentMethod(companyId, displayedUser.id, pm, false));
                }
            }

            if (!pm && !isFreeProduct && !isCashPurchase)
                throw new Error("You must select or enter a credit card.")

            const valuesToSend = {
                paymentMethodId: pm ? pm.id : null,
                productId: productId,
                quantity: quantity,
                ownerDiscount: ownerDiscount,
                coupon: coupon,
                startDate: startDate,
                nonStoredPaymentMethodId: pm ? pm.paymentMethodId : null,
                temporaryCard: useNewCard && !storeCard ? true : false,
                cashPurchase: isCashPurchase
            };
            console.log(valuesToSend)
            const purchase = await dispatch(buyProduct(
                companyId,
                displayedUser.id,
                valuesToSend
            ));
            setItemPurchased(purchase)
        } catch (err) {
            setError(err.message);
            setIsSubmitting(false);
        }
    }, [dispatch, elements, hasAnyExistingDefaultPaymentMethod, storeCard, stripe, useNewCard, isFreeProduct]);

    const handleBuyProduct = () => {
        if (company.passOnPaymentServiceFee && !product.isRecurring) {
            const r = window.confirm('You will be charged a processing fee $.30 plus 2.9% of the total.');
            if (!r) {
                return;
            }
        }
        productPurchase(selectedPaymentMethod,
            product.id,
            company.id,
            quantity ? parseInt(quantity) : 1,
            ownerDiscount ? parseFloat(ownerDiscount) : 0,
            couponCode,
            subscriptionStartDate,
            displayedUser,
            paymentMethodToShow,
            isCashPurchase
        );
    };


    return (
        <React.Fragment>
            <StyledDialog
                open={open}
                setOpen={setOpen}
                primaryAction={
                    <React.Fragment>
                        {!itemPurchased &&
                            <ActionButton my={2}
                                onClick={handleBuyProduct}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!isFormValid}
                                submitting={isSubmitting}
                            >
                                Purchase Item
                            </ActionButton>
                        }
                        {itemPurchased &&
                            <ActionButton
                                onClick={() => setOpen(false)}
                                fullWidth
                                variant="contained"
                                color="primary">
                                Close
                            </ActionButton>
                        }
                    </React.Fragment>
                }
            >
                {!itemPurchased &&
                    <React.Fragment>
                        <Card mb={4}>
                            <ProfileCard person={displayedUser} notClickable />
                        </Card>
                        {error &&
                            <Alert severity="error">{error}</Alert>
                        }
                        <Card>
                            <Box display="flex" flexDirection="column" alignContent="center" m={2}>
                                <Typography variant="h3" style={{ textAlign: 'center' }} mb={2}>{product.name}{product.isRecurring ? ' - Subscription' : ''}</Typography>
                                {allowEdit && product.isRecurring &&
                                    <Box width='100%' textAlign='center'>
                                        <KeyboardDatePicker inputVariant="outlined"
                                            name="subscriptionStartDate"
                                            margin="normal"
                                            label="Start On"
                                            value={subscriptionStartDate}
                                            onChange={date => setSubscriptionStartDate(date)}
                                            emptyLabel=""
                                            format="MM/dd/yy"
                                        />
                                    </Box>
                                }
                                {company.freeTrialPeriod && company.freeTrialPeriod > 0 &&
                                    <Typography variant="caption" mt={2}>{company.name} offers a free trial period of {company.freeTrialPeriod} day{company.freeTrialPeriod > 1 ? 's' : ''}.  Your first payment will be due once the trial ends.</Typography>
                                }
                                {product.freeTrialDays !=null && product.freeTrialDays > 0 && <Typography variant="body2" mb={2}>This membership offers a {product.freeTrialDays} day free trial.</Typography>}
                                {product.freeTrialDays !=null &&  product.freeTrialDays > 0 && !product.autoCancelAfterFreeTrial && <Typography variant="body2" mb={2}>Your first payment will be automatically charged at the end of the free trial unless you cancel this membership under purchases in your profile.</Typography>}
                                {product.freeTrialDays !=null &&  product.freeTrialDays > 0 && product.autoCancelAfterFreeTrial && <Typography variant="body2" mb={2}>This membership automatically ends after the free trial.<br />You will not be charged unless you reactivate this membership under purchases in your profile.</Typography>}

                                {company.subscriptionStartDay && company.subscriptionStartDay > 0 &&
                                    <Typography variant="caption" mt={2}>All memberships are billed on the {ordinalSuffixOf(company.subscriptionStartDay)} day of the month. {company.proRateMonth ? 'Your first payment will be prorated for the partial month prior to that date.' : 'You will not be billed until that date.'}</Typography>
                                }
                            </Box>
                            <Box m={2}>
                                <Box display='flex' flexDirection='row' justifyContent="space-between" alignContent="center" mb={4}>
                                    <Typography variant="h4">{product.isRecurring ? "Price per " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : "Subtotal"}</Typography>
                                    <Box display='flex' flexDirection='row'>
                                        <Typography variant="h4">${(product.unitAmount).toFixed(2)} {!product.isRecurring ? ' x ' : ''}</Typography>
                                        {!product.isRecurring &&
                                            <Formik
                                                initialValues={{
                                                    quantity: 1,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    quantity: Yup.number().min(1, "Must be greater than 0.").max(99, "Must be less than 99.").required("Required"),
                                                })}
                                            >
                                                {({
                                                    errors,
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    touched,
                                                    isValid,
                                                    values,
                                                }) => {
                                                    setQuantity(values.quantity);
                                                    setIsFormValid(isValid);
                                                    return (
                                                        <Form onSubmit={() => handleBuyProduct(values)}>
                                                            <Box maxWidth="95px" display="flex" flexDirection="column" justifyContent="center">
                                                                <TextField
                                                                    variant="outlined"
                                                                    name="quantity"
                                                                    label="Quantity"
                                                                    value={values.quantity}
                                                                    error={Boolean(touched.quantity && errors.quantity)}
                                                                    helperText={touched.quantity && errors.quantity}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    ml={2}
                                                                    required
                                                                    margin="dense"
                                                                />
                                                            </Box>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            {!isFreeProduct && product.isRecurring && <Box m={2}>
                                <Box display='flex' flexDirection='row' justifyContent="space-between">
                                    <Typography>Discount Code:</Typography>
                                    {/* <Typography variant="body2" color="textSecondary" mb={1}>Coupon will be applied when you purchase an item.</Typography> */}
                                    <TextField
                                        id="coupon"
                                        label="Enter Code"
                                        type="text"
                                        variant="outlined"
                                        value={couponCode}
                                        onChange={e => setCouponCode(e.target.value)} />
                                </Box>
                            </Box>}
                            {viewReports && !product.isRecurring &&
                                <Box m={2}>
                                    <Box display='flex' flexDirection='row' justifyContent="space-between" alignContent="center" mb={4}>
                                        <Typography>Manual Discount</Typography>
                                        <Formik
                                            initialValues={{
                                                ownerDiscount: 0,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                ownerDiscount: Yup.number().min(0, "Must be greater than 0.").max(product.unitAmount * quantity, "Must be less than " + product.unitAmount * quantity),
                                            })}
                                        >
                                            {({
                                                errors,
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                touched,
                                                isValid,
                                                values,
                                            }) => {
                                                setOwnerDiscount(values.ownerDiscount);
                                                setIsFormValid(isValid);
                                                return (
                                                    <Form onSubmit={() => handleBuyProduct(values)}>
                                                        <Box maxWidth="300px" display="flex" flexDirection="column" justifyContent="center">
                                                            <TextField
                                                                variant="outlined"
                                                                name="ownerDiscount"
                                                                label="Owner Discount"
                                                                value={values.ownerDiscount}
                                                                error={Boolean(touched.ownerDiscount && errors.ownerDiscount)}
                                                                helperText={touched.ownerDiscount && errors.ownerDiscount}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                ml={2}
                                                                required
                                                                margin="dense"
                                                            />
                                                        </Box>
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </Box>
                                </Box>}
                            {!product.isRecurring &&
                                <Box m={2}>
                                    <Box display='flex' flexDirection='row' justifyContent="space-between">
                                        <Typography variant="h4">Total Charges</Typography>
                                        <Typography variant="h4">${((product.unitAmount * (!quantity || quantity === 0 ? 1 : quantity)) - ownerDiscount).toFixed(2)}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Card>
                        {viewReports && !product.isRecurring &&
                            <Card mt={2} p={2}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography>Is Cash Purchase?</Typography>
                                    <Switch name='isCashPurchase' onChange={(val) => setIsCashPurchase(prev => !prev)} checked={isCashPurchase} />
                                </Box>
                            </Card>
                        }
                        {paymentMethodToShow && paymentMethodToShow.length > 0 && !useNewCard && !isFreeProduct && !isCashPurchase &&
                            <React.Fragment>
                                {paymentMethodToShow.map((item, index) => {
                                    const isExpired = isCardExpired(item);
                                    return (
                                        <Card key={item.id} my={2} p={2}>
                                            <Box onClick={() => setSelectedPaymentMethod(item)} disabled={isExpired}
                                                style={{ cursor: (isExpired ? 'default' : 'cursor') }}
                                            >
                                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                    <Box display="flex" flexDirection="row">
                                                        {isExpired && <CardExpired color={theme.palette.error.main} size={24} />}
                                                        {!isExpired && (!selectedPaymentMethod || selectedPaymentMethod.id !== item.id) && <RadioButtonOff size={24} color={theme.palette.iconColor.color} />}
                                                        {!isExpired && selectedPaymentMethod && selectedPaymentMethod.id === item.id && <RadioButtonOn size={24} />}
                                                        {parentPaymentMethods.some(x => x.id === item.id) && <Typography ml={3}>Parent Card {item.isDefault ? '- ' : ''}</Typography>}
                                                        {item.isDefault && <Typography ml={parentPaymentMethods.some(x => x.id === item.id) ? 0 : 3}>Default</Typography>}
                                                    </Box>
                                                    <Box display="flex" flexDirection="column" alignItems='flex-end'>
                                                        <Typography>**** {item.lastFourOnCard}</Typography>
                                                        <Typography variant="caption">{item.expirationMonth}/{item.expirationYear}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    )
                                })}
                            </React.Fragment>
                        }

                        {paymentMethodToShow && paymentMethodToShow.length > 0 && !isFreeProduct && !isCashPurchase &&
                            <Card mt={2} p={2}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography>Enter credit card:</Typography>
                                    <Switch name='useNewCard' onChange={(val) => setUseNewCard(prev => !prev)} checked={useNewCard} />
                                </Box>
                            </Card>
                        }
                        {useNewCard && !isFreeProduct && !isCashPurchase &&
                            <Card mt={2} p={2}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: theme.palette.text.primary,
                                                '::placeholder': {
                                                    color: theme.palette.text.primary,
                                                },
                                            },
                                            invalid: {
                                                color: theme.palette.error.main,
                                            },
                                        },
                                    }}
                                />
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                                    <Typography>Save card for future use:</Typography>
                                    <Switch name='storeCard' onChange={(val) => setStoreCard(prev => !prev)} checked={storeCard} disabled={product.isRecurring} />
                                </Box>
                            </Card>
                        }
                        {error &&
                            <Alert mt={2} severity="error">{error}</Alert>
                        }
                    </React.Fragment>
                }
                {itemPurchased &&
                    <InvoiceCard
                        purchase={itemPurchased} />
                }

            </StyledDialog >
        </React.Fragment >
    )
}

export default BuyAProductModal;