import React from 'react'
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { Helmet } from 'react-helmet-async';
import { withTheme } from "styled-components/macro";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Grid,
    Typography as MuiTypography
} from "@material-ui/core";


import {
    IoMailOutline as EmailIcon,
    IoCallOutline as CallIcon,
    IoChatbubbleEllipsesOutline as TextIcon,
} from "react-icons/io5";

// svxvy import

import { formatPhoneNumber } from '../../../helpers/helperFunctions';
import ContactForm from '../../../components/company/ContactForm';

// constants
const Typography = styled(MuiTypography)(spacing);

const CalloutBox = styled(Box)`
    background: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: 23px 10px 23px 10px;
    display: flex;
    text-align: center;
    justify-content: center;
`;

const ContactPage = (props) => {
    const theme = useTheme();
    const company = useSelector(state => state.company.company);
    // const columnWidth = company.phoneNumber && company.allowTexts ? 4 : 6;

    return (
        <React.Fragment>
            <Helmet title="Contact Us" />
            <Grid container spacing={6}>
                <Grid item xs={false} md={3}></Grid>
                <Grid item xs={12} md={6}>
                    {(company.companyContactEmail || company.phoneNumber) &&
                        <Typography variant="h3" align="center" mt={6} mb={3}>Have a question?</Typography>
                    }
                    {company.companyContactEmail && <Typography variant="body2" align="center" mb={0}>Get in touch or fill out the form below and we'll get back to you shortly!</Typography>}
                </Grid>
                {company.companyContactEmail &&
                    <Grid item xs={12} md={6}>
                        <a href={"mailto:" + company.companyContactEmail} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                            <CalloutBox display="flex" flexDirection="row" >
                                <EmailIcon size={24} color={theme.palette.primary.main} />
                                <Typography variant="body2" align="center" ml={1}>{company.companyContactEmail}</Typography>
                            </CalloutBox>
                        </a>
                    </Grid>
                }
                {company.phoneNumber &&
                    <Grid item xs={12} md={6}>
                        <a href={"tel:" + company.phoneNumber} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>

                            <CalloutBox display="flex" flexDirection="row">
                                <CallIcon size={24} color={theme.palette.primary.main} />
                                {company.allowTexts &&
                                    <React.Fragment>
                                        <Typography variant="body2" align="center" mx={1}>or</Typography>
                                        <TextIcon size={24} color={theme.palette.primary.main} />
                                    </React.Fragment>
                                }
                                <Typography variant="body2" align="center" ml={1}>{formatPhoneNumber(company.phoneNumber)}</Typography>
                            </CalloutBox>
                        </a>
                    </Grid>
                }
                {company.companyContactEmail &&
                    <Grid item xs={12}>
                        <ContactForm />
                    </Grid>
                }
            </Grid>
        </React.Fragment >
    );
}

export default withTheme(ContactPage);
