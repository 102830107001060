import React from "react";
import { withTheme } from "styled-components/macro";

import { Box, Grid } from "@material-ui/core";

// svxvy import

// constants

const DefaultGrid = (props) => {
  return (
    <Box mb={6}>
      <Grid container>
        <Grid item xs={false} lg={2}></Grid>
        <Grid item xs={12} lg={8}>
          {props.children}
        </Grid>
        <Grid item xs={false} lg={2}></Grid>
      </Grid>
    </Box>
  );
};

export default withTheme(DefaultGrid);
