import React from "react";
import moment from 'moment';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Card,
    CardContent,
    CardActions,
    Typography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// constants
const Alert = styled(MuiAlert)(spacing);

const SubscriptionCard = (props) => {
    const { subscription, children } = props;

    // console.log(subscription)
    return (
        <Card>
            <CardContent pb={0}>
                <Typography variant="h4" gutterBottom>Subscription: {subscription.purchasedProduct.name}</Typography>
                {subscription.description && <Typography variant="body2" gutterBottom>{subscription.description}</Typography>}
                {subscription.memo && <Alert severity="error" mb={2}>{subscription.memo}</Alert>}
                {subscription.automaticallyRenew && <Typography gutterBottom>Next Bill: {subscription.nextAmountDue === 0 ? "Free" : "$" + (subscription.nextAmountDue)}</Typography>}
                {subscription.subscriptionId && <Typography gutterBottom>{subscription.automaticallyRenew ? "On:" : "Ends:"} {moment(subscription.expirationDate).format('ll')}</Typography>}
            </CardContent>
            <CardActions>{children}</CardActions>
        </Card>
    );
}

export default SubscriptionCard;