import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import i18n from 'i18n-js';
import { RRule } from 'rrule';

import { useTheme } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Divider as MuiDivider,
    Link,
    List as MuiList,
    ListItem as MuiListItem,
    Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoVideocamOutline as VideoIcon,
    IoCalendarOutline as CalendarIcon,
    IoCashOutline as CostIcon,
    IoLocationOutline as LocationIcon,
    IoTimeOutline as RepeatIcon
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim, getMilliseconds, compareObjects } from '../../../helpers/helperFunctions';
import { dropActivity, enrollInActivity } from '../../../store/actions/activityActions';
import { isToday, isTomorrow } from './ActivitiesListByDay';
import { getActiveSubscriptionObjects, getPriceForUser } from '../../../helpers/commerceFunctions';
import ProfileIcon from '../profile/ProfileIcon';
import ActionButton from '../../framework/ActionButton';
import ParagraphTypography from '../../framework/ParagraphTypography'
import ProfileDetailCard from '../profile/ProfileDetailCard';
import ActivityJoinModal from './ActivityJoinModal';
import SelectUser from "../SelectUser";
import ErrorDialog from "../../framework/ErrorDialog"
import { isLiveSite } from '../../../constants/NetworkConstants';

//constants

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const VideoLink = styled(Typography)`
    text-decoration: none;
    &,
    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.text.primary};
    }
`;

const CardContent = styled(MuiCardContent)`
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin: 0 0 10px 0;
    padding: 0 !important;
`;

const CardContentHead = styled(Box)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 12px 14px 13px 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Chip = styled(MuiChip)`
    height: 20px;
    border-radius: 10px;
    margin-top: 2px;
    margin-left: 5px;
    padding: 0 5px;
    font-weight: 700;
    font-size: 1.2rem;
`;

const List = styled(MuiList)`
    margin: 0;
    padding: 9px 15px 13px 15px;
    columns: 3;

    ${(props) => props.theme.breakpoints.down("xs")} {
        columns: 2;
    }
`;

const ListItem = styled(MuiListItem)`
    padding: 0;
    display: inline-block;
    font-size: 12px;
`;

const ActivityDetail = (props) => {
    const theme = useTheme();

    const { activity, viewStartDate, viewEndDate, hidePeople, hideDrop, showRecurrance, titleAction, editClosed } = props;

    const location = useSelector(state => state.company.locations).find(x => x.id === activity.locationId)

    const address = !location ? activity.manualLocation : location.address + ",+" + location.city + ",+" + location.state + "+" + location.zip;
    // const showMap = (location.city + ",+" + location.state + "+" + location.zip) !== ',++' ? true : false;

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const EMPLOYEE_TEXT = useSelector(state => state.company.employeeText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const loggedInUser = useSelector(state => state.auth.person);
    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);

    const company = useSelector(state => state.company.company);
    const companyId = useSelector(state => state.company.companyId);

    const activityCanceled = activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' &&
            activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

    const whenCanDrop = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfDropStartValue, activity.selfDropStartUnit));

    const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
    const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInActivityInstance(haveAttendeeObject, activity) : false;
    const aFamilySignedUp = activity && loggedInUser ? activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity) : false;

    const instructors = activity.people.filter(a => !a.userIsDeleted && !a.userIsDeleted && a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
    const isRunningActivity = loggedInUser ? peopleRunningActivity.some(x => x.id === loggedInUser.id) : false;

    const attendees = activity.people.filter(a => !a.userIsDeleted && !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const peopleSignedUpforActivity = useSelector(state => state.company.people).filter(p => attendees.find(a => a.appUserId === p.id));


    const people = useSelector(state => state.company.people).filter(p => p.isDeleted ? false : true).sort(compareObjects('displayName', 'asc'));
    const employees = people.filter(p => !p.isDeleted && p.isEmployee);

    const [error, setError] = useState(null);
    const [isDropping, setIsDropping] = useState(false)

    const [activityJoinModalOpen, setActivityJoinModalOpen] = useState(false);

    const [profileToShow, setProfileToShow] = useState(null);
    const [showProfileDialog, setShowProfileDialog] = useState(null);

    /******************************************* events *******************************/
    const dispatch = useDispatch();
    const dropHandler = useCallback(async (id, userToDrop) => {
        setError(null);
        try {
            setIsDropping(true);
            await dispatch(dropActivity(companyId, id, userToDrop.id, viewStartDate, viewEndDate, true));
        } catch (err) {
            setError(err.message)
        }
        setIsDropping(false);
    }, [dispatch, companyId, viewStartDate, viewEndDate])

    const joinHandler = useCallback(async (id, userToRegister, isRunningActivity) => {
        setError(null);
        try {
            await dispatch(enrollInActivity(companyId, id, userToRegister.id, new Date(viewStartDate), new Date(viewEndDate), true, isRunningActivity));
        } catch (err) {
            setError(err.message);
        }
        // setIsJoining(prevState => prevState.splice(prevState.indexOf(person.id, 1)));
        // setIsJoining(prevState => prevState.filter(x => x !== person.id));
    }, [dispatch, companyId, viewStartDate, viewEndDate]);

    return (
        <React.Fragment>
            <Box display="flex" flex={1} flexDirection="column">
                <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" alignContent="center">
                        <Typography variant="h2" mb={0} mr={4}>{activity.title}</Typography>
                        {activityCanceled && <Chip style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }} size="small" label='Canceled' ml={2} />}
                        {activity.requestConfirmation && (currentUserInClass || allowAddEdit) &&
                            <React.Fragment>
                                {!activity.confirmed && !activity.rejected && <Chip style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }} size="small" label={currentUserInClass ? 'Please confirm' : 'Confirmation Pending'} ml={2} />}
                                {activity.confirmed && <Chip style={{ backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText }} size="small" label='Confirmed' ml={2} />}
                                {activity.rejected && <Chip style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }} size="small" label={'Declined' + (activity.rejectReason ? ' - ' + activity.rejectReason : '')} ml={2} />}
                            </React.Fragment>
                        }
                    </Box>
                    {titleAction &&
                        <Box>
                            {titleAction}
                        </Box>
                    }
                </Box>
                {loggedInUser && (loggedInUser.id === '1b0806ae-5efd-44ca-94f6-d423e45b634c' || !isLiveSite() )&&
                    <Typography mb={0} mr={4}>{activity.id + '   - occID: ' + activity.occuranceId}</Typography>
                }
                <ParagraphTypography variant="body1" mx={2} my={2}>{activity.description}</ParagraphTypography>
                <CardContent>
                    {peopleRunningActivity.length > 0 && !allowAddEdit &&
                        <React.Fragment>
                            {peopleRunningActivity.map((profile, index) => {
                                const person = profile;
                                return (
                                    <Box key={person.id} display="flex" flexDirection="row" justifyContent="space-between" style={{ padding: '10px 14px 10px 14px' }}>
                                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <ProfileIcon person={person} />
                                            <Typography style={{ margin: '0 12px 0 10px', fontWeight: 700, fontSize: '1.4rem' }}>{person.displayName}</Typography>
                                            <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.2rem' }}>{EMPLOYEE_TEXT}</Typography>
                                        </Box>
                                        {/* <Button size="small" color='primary' style={{ minWidth: 0, backgroundColor: 'transparent !important', padding: 0, fontWeight: 400, fontSize: '1.4rem' }}>View</Button> */}
                                    </Box>
                                )
                            })}
                            <Divider />
                        </React.Fragment>
                    }
                    <Box display="flex" flexDirection="row" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '17px 13px 13px 13px' }}>
                        <CalendarIcon color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                        <Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography style={{ margin: '0 11px 0 0', fontWeight: 700, fontSize: '1.4rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>{showRecurrance ? 'Starting ' : ''}{moment(activity.displaybleStartDateTime).format('dddd - MMM DD')}</Typography>
                                {isToday(activity.displaybleStartDateTime) && <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.2rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>Today</Typography>}
                                {isTomorrow(activity.displaybleStartDateTime) && <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.2rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>Tomorrow</Typography>}
                                
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography style={{ margin: '0 11px 0 0', fontWeight: 700, fontSize: '1.4rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>{moment(activity.displaybleStartDateTime).format('h:mm a')} - {moment(activity.displaybleStartDateTime).add(activity.duration, 'minutes').format('h:mm a')}</Typography>
                                <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.2rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>{activity.duration} minutes</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {showRecurrance && activity.rrule && activity.rrule.trim().length > 0 &&
                        <Box display="flex" flexDirection="row" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '17px 13px 13px 13px' }}>
                            <RepeatIcon color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                            <Box>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ margin: '0 11px 0 0', fontWeight: 400, fontSize: '1.4rem', textDecoration: (activityCanceled ? 'line-through' : '') }}>{RRule.fromString(activity.rrule).toText()}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {location && !activity.meetingUrl &&
                        <Box display="flex" flexDirection="row" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '17px 13px 13px 13px' }}>
                            <LocationIcon color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography style={{ margin: '0 5px 0 0', fontWeight: 700, fontSize: '1.4rem' }}>{location.name}{activity.activityResources ? ' - ' + activity.activityResources : ''} - {location.address} {location.city} {location.state} {location.zip} - </Typography>
                                <Link
                                    style={{ margin: '0 11px 0 0', fontWeight: 700, fontSize: '1.4rem' }}
                                    underline="none"
                                    color="primary"
                                    variant="body2"
                                    target="_blank"
                                    href={"https://www.google.com/maps/dir/''/" + address}
                                >
                                    Map
                                </Link>
                            </Box>
                        </Box>
                    }
                    {!location && activity.manualLocation && activity.manualLocation.trim().length > 0 &&
                        <Box display="flex" flexDirection="row" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '17px 13px 13px 13px' }}>
                            <LocationIcon color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                            <Typography style={{ margin: '0 5px 0 0', fontWeight: 700, fontSize: '1.4rem' }}>{activity.manualLocation} - </Typography>
                            <Link
                                style={{ margin: '0 11px 0 0', fontWeight: 700, fontSize: '1.4rem' }}
                                underline="none"
                                color="primary"
                                variant="body2"
                                target="_blank"
                                href={"https://www.google.com/maps/dir/''/" + address}
                            >
                                Show Map
                            </Link>
                        </Box>
                    }
                    {activity.meetingUrl &&
                        <Box display="flex" flexDirection="row" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '17px 13px 13px 13px' }}>
                            <Box component={(currentUserInClass || isRunningActivity) ? "a" : 'div'} href={activity.meetingUrl} target="_blank">
                                <VideoIcon
                                    color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                            </Box>
                            <Box>
                                <Box display="flex" flexDirection="column" alignItems="left">
                                    {(currentUserInClass || isRunningActivity) &&
                                        <VideoLink variant="body2" component="a" href={activity.meetingUrl} target="_blank">Click to Join</VideoLink>
                                    }
                                    {!currentUserInClass && !isRunningActivity &&
                                        <Typography style={{ margin: '0 5px 0 0', fontWeight: 700, fontSize: '1.4rem' }}>{activity.meetingUrl}</Typography>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }
                    {activity.price !== null &&
                        <Box display="flex" flexDirection="row" style={{ padding: '13px 13px 13px 13px' }}>
                            <CostIcon color={theme.sidebar.category.svg.color} size="27" style={{ marginTop: '-3px', marginRight: '17px' }} />
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography style={{ margin: '0 11px 0 0', fontWeight: 700, fontSize: '1.4rem' }}>{i18n.toCurrency(loggedInUser ? getPriceForUser(activity, getActiveSubscriptionObjects(loggedInUser.purchases)) : activity.price, { precision: 2, unit: '$' })}</Typography>
                                <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.2rem' }}>Price/{ACTIVITY_TEXT}</Typography>
                            </Box>
                        </Box>
                    }
                </CardContent>
                {allowAddEdit && !hidePeople && editClosed &&
                    <CardContent>
                        <CardContentHead>
                            <Typography mb={0} style={{ fontWeight: 700 }}>{ACTIVITY_TEXT} {EMPLOYEE_TEXT}</Typography>
                            <Chip color="primary" size="small" label={peopleRunningActivity.length} />
                        </CardContentHead>
                        <List>
                            {peopleRunningActivity.map((profile, index) => {
                                const person = profile;
                                return (
                                    <ListItem
                                        key={profile.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setProfileToShow(profile)
                                            setShowProfileDialog(true)
                                        }}>
                                        {person.displayName}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </CardContent>
                }
                {allowAddEdit && !hidePeople && !editClosed &&
                    <SelectUser
                        title={EMPLOYEE_TEXT}
                        selectText={"Select " + EMPLOYEE_TEXT}
                        scrollSelectedPeople
                        showProfile
                        items={employees}
                        initialValue={peopleRunningActivity}
                        handleAdd={(val) => joinHandler(activity.id, val, true)}
                        handleRemove={(val) => dropHandler(activity.id, val)}
                    />
                }

                {allowAddEdit && !hidePeople && editClosed &&
                    <CardContent>
                        <CardContentHead>
                            <Typography mb={0} style={{ fontWeight: 700 }}>{ACTIVITY_TEXT} {CUSTOMERS_TEXT}</Typography>
                            <Chip color="primary" size="small" label={peopleSignedUpforActivity.length} />
                        </CardContentHead>
                        <List>
                            {peopleSignedUpforActivity.map((profile, index) => {
                                const person = profile;
                                return (
                                    <ListItem
                                        key={profile.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setProfileToShow(profile)
                                            setShowProfileDialog(true)
                                        }}>
                                        {person.displayName}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </CardContent>
                }
                {allowAddEdit && !hidePeople && !editClosed &&
                    <SelectUser
                        title={CUSTOMERS_TEXT}
                        selectText={"Select " + CUSTOMERS_TEXT}
                        scrollSelectedPeople
                        showProfile
                        initialValue={peopleSignedUpforActivity}
                        handleAdd={(val) => joinHandler(activity.id, val, false)}
                        handleRemove={(val) => dropHandler(activity.id, val)}
                    />
                }

                {!activityCanceled && loggedInUser && (currentUserInClass || aFamilySignedUp)
                    && !hideDrop && new Date() < whenCanDrop && activity.allowSelfRegister
                    && !activity.isRABSIt &&
                    <Box >
                        <ActionButton
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            submitting={isDropping}
                            onClick={() => (loggedInUser.childProfiles && loggedInUser.childProfiles.length > 0 ? setActivityJoinModalOpen(true) : dropHandler(activity.id, loggedInUser))}
                            mb={2}
                        >
                            Drop {ACTIVITY_TEXT}
                        </ActionButton>
                        {!company.hideDroppedActivityMessage &&
                            <Typography variant="body2">
                                {company.preDeadlineDropActivityMessage ? company.preDeadlineDropActivityMessage.replaceAll('{CLASS_DROP_TIME}', moment(whenCanDrop).format('lll')) :
                                    'If you do not drop this ' + ACTIVITY_TEXT.toLowerCase() + ' before the drop deadline of ' + moment(whenCanDrop).format('lll') + ' you will be billed for the ' + ACTIVITY_TEXT.toLowerCase() + ' even if you do not attend.'}
                            </Typography>
                        }
                    </Box>
                }
                {!activityCanceled && (currentUserInClass || aFamilySignedUp)
                    && !company.hideDroppedActivityMessage &&
                    <Box ml={2}>
                        {activity.allowSelfRegister && new Date() > whenCanDrop && !activity.isRABSite &&
                            <React.Fragment>
                                <Typography variant="body2" >{company.postDeadlineDropActivityMessage ? company.postDeadlineDropActivityMessage :
                                    'This ' + ACTIVITY_TEXT.toLowerCase() + ' can no longer be dropped you will be billed for the ' + ACTIVITY_TEXT.toLowerCase() + ' even if you do not attend.'}</Typography>
                            </React.Fragment>
                        }
                        {(!activity.allowSelfRegister || activity.isRABSite) &&
                            <Typography variant="body2">{
                                company.noDropActivityMessage ? company.noDropActivityMessage :
                                    'To request a change to this ' + ACTIVITY_TEXT.toLowerCase() + ' please contact the business.'}</Typography>
                        }
                    </Box>
                }
            </Box >
            {error &&
                <ErrorDialog message={error} setMessage={setError} />
            }

            {showProfileDialog && profileToShow &&
                <ProfileDetailCard
                    person={profileToShow}
                    open={showProfileDialog}
                    setOpen={setShowProfileDialog}
                    hideCreateClass
                />
            }
            {activityJoinModalOpen &&
                <ActivityJoinModal
                    activity={activity}
                    companyId={companyId}
                    // userToJoin={loggedInUser}
                    open={activityJoinModalOpen}
                    setOpen={setActivityJoinModalOpen}
                    viewStartDate={viewStartDate}
                    viewEndDate={viewEndDate} />
            }
        </React.Fragment>
    );
}

export default ActivityDetail;