import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withTheme } from "styled-components/macro";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Box,
    FormControl,
    FormControlLabel as MuiFormControlLabel,
    RadioGroup,
    Radio,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { rejectActivity } from '../../../store/actions/activityActions';
import ActionButton from '../../framework/ActionButton';
import StyledDialog from '../../framework/StyledDialog';

// constants
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;
const OTHER_REASON = "Other";


const DeclineDialog = (props) => {
    const { activity, open, setOpen, setActivity, viewStartDate, viewEndDate } = props;

    const company = useSelector(state => state.company.company);

    const [rejectingActivity, setRejectingActivity] = useState(false);
    const [reasonError, setReasonError] = useState(null);
    const [declineReason, setDeclineReason] = useState(null);
    const [otherDeclineReason, setOtherDeclineReason] = useState("");

    //==================================================================
    const dispatch = useDispatch();
    const rejectActivityCallback = useCallback(async (activityId, companyId, reason) => {
        try {
            setReasonError(null);
            setRejectingActivity(true);

            if (setActivity) {
                const updatedActivity = await dispatch(rejectActivity(activityId, companyId, reason));
                setActivity(updatedActivity);
            }
            else if (viewStartDate && viewEndDate)
                await dispatch(rejectActivity(activityId, companyId, reason, viewStartDate, viewEndDate, true ))

            setOpen(false);
        } catch (err) {
            setRejectingActivity(false);
            setReasonError(err.message);
        }
    }, [dispatch, viewStartDate, viewEndDate, setActivity, setOpen]);

    const handleReject = () => {
        if (declineReason === null || (declineReason === OTHER_REASON && otherDeclineReason.trim().length === 0)) {
            setReasonError("A reason is required.")
        }
        else {
            const reason = declineReason === OTHER_REASON ? otherDeclineReason : declineReason;
            rejectActivityCallback(activity.id, company.id, reason);
        }
    }

    return (
        <StyledDialog
            style={{ height: "90vh" }}
            open={open}
            setOpen={setOpen}
            aria-labelledby="act-dialog-title"
        >
            <Box display="flex" flexDirection="column">
                <Typography variant='h3'>Reason for Declining</Typography>
                <Box mx={2}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="Decline Reason"
                            name="declineReason"
                            value={declineReason}
                            onChange={event => {
                                setReasonError(null);
                                setDeclineReason(event.target.value);
                            }}
                        >
                            <FormControlLabel value="Time/Date doesn't work" control={<Radio />} label="Time/Date doesn't work" />
                            <FormControlLabel value="I no longer want this appointment" control={<Radio />} label="I no longer want this appointment" />
                            <FormControlLabel value={OTHER_REASON} control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </Box>
                {declineReason && declineReason === OTHER_REASON &&
                    <TextField
                        variant="outlined"
                        label="reason"
                        name="otherReason"
                        value={otherDeclineReason}
                        fullWidth
                        onChange={event => {
                            setOtherDeclineReason(event.target.value);
                        }}
                    />}
                {reasonError &&
                    <Box mt={2}>
                        <Alert severity="error">{reasonError}</Alert>
                    </Box>
                }
                <ActionButton variant='outlined'
                    color='primary'
                    onClick={handleReject}
                    submitting={rejectingActivity}
                    disabled={!declineReason}
                    mt={2}
                >
                    Decline
                </ActionButton>
                <ActionButton variant='outlined'
                    onClick={() => setOpen(false)}
                    disabled={rejectingActivity}
                    mt={2}
                >
                    Cancel
                </ActionButton>
            </Box>
        </StyledDialog>
    );
}

export default withTheme(DeclineDialog);
