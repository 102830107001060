import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    CircularProgress as MuiCircularProgress,
    Card as MuiCard,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCartOutline as PurchaseIcon,
} from "react-icons/io5";

// svxvy imports
import { detailPerson } from "../../../store/actions/peopleActions";
import { compareObjects } from "../../../helpers/helperFunctions";
import { getActiveSubscriptionObjects } from '../../../helpers/commerceFunctions';
import { ACTIVE_MEMBERSHIPS_STATUSES } from '../../../models/Purchase';
import ActionButton from "../../framework/ActionButton";
import Auxcillary from '../../framework/Auxcillary';
import StyledDialog from '../../framework/StyledDialog';
import SubscriptionListItem from "./SubscriptionListItem";
import PurchaseProductModal from "../products/PurchaseProductModal";
import PurchaseListItem from '../purchases/PurchaseListItem';

// constants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const MembershipHeading = styled(Box)`
    padding: 14px 15px 17px 15px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};

    ${(props) => props.theme.breakpoints.up("md")} {

    }
`;

const MembershipItem = styled(Box)`
    padding: 17px 15px 15px 15px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};

    p {
        margin: 0;
    }

    &:last-child {
        border-bottom: none;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 0 0 30px;
        padding: 21px 15px 18px 0;
    }
`;

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;

const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0 26px 0 0;
    padding: 0 2px;
    font-weight: 400;
    font-size: 14px;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    &:last-child {
        margin: 0;
    }
`;

const ICON_SIZE = 24;

const SubscriptionList = (props) => {
    const { displayedUser, companyId, open, setOpen, hidePurchases, isChildAccount, dontFetchPurchases } = props;

    const theme = useTheme();

    const loggedInUser = useSelector(state => state.auth.person)

    const company = useSelector(state => state.company.company);

    const activeSubscriptions = displayedUser && displayedUser.purchases ? getActiveSubscriptionObjects(displayedUser.purchases)
        .filter(x => ((x.expirationDate && x.expirationDate >= new Date()) || x.units > 0) && x.isTentantPurchase && ACTIVE_MEMBERSHIPS_STATUSES.includes(x.status))
        .sort(compareObjects('expirationDate')) : [];
    const errorSubscriptions = displayedUser && displayedUser.purchases ? getActiveSubscriptionObjects(displayedUser.purchases)
        .filter(x => (((x.expirationDate && x.expirationDate < new Date() && x.automaticallyRenew) || (!ACTIVE_MEMBERSHIPS_STATUSES.includes(x.status) && !(x.status === "canceled"))) && x.purchasedProduct && x.purchasedProduct.isRecurring) && x.isTentantPurchase)
        .sort(compareObjects('expirationDate')) : [];

    const completedSubscriptions = displayedUser && displayedUser.purchases ? getActiveSubscriptionObjects(displayedUser.purchases)
        .filter(x => ((x.expirationDate && x.expirationDate < new Date() && x.purchasedProduct && x.purchasedProduct.isRecurring) || (x.purchasedProduct && x.purchasedProduct.units > 0 && x.units === 0)) && x.isTentantPurchase)
        .sort(compareObjects('expirationDate')) : [];
    const purchases = displayedUser && displayedUser.purchases ? displayedUser.purchases
        .filter(x => x.isTentantPurchase && x.status !== "past_due")
        .sort(compareObjects('datePurchased', 'desc')) : [];
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isAddingMembership, setIsAddingMembership] = useState(false);
    const [purchasesToShow, setPurchasesToShow] = useState(25);

    const dispatch = useDispatch();
    const loadPurchases = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            if (loggedInUser.id !== displayedUser.id && !isChildAccount)
                await dispatch(detailPerson(displayedUser.id, companyId, true));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId, displayedUser.id, loggedInUser.id, isChildAccount]);

    useEffect(() => {
        if (!dontFetchPurchases)
            loadPurchases();
    }, [loadPurchases, dontFetchPurchases]);

    // useEffect(() => {
    //     if (displayedUser.id !== loggedInUser.id)
    //         setCurrentUser(people.find(x => x.id === displayedUser.id))
    // }, [people, displayedUser.id, loggedInUser.id]);

    const DisplayComponent = setOpen ? StyledDialog : Auxcillary;

    return (
        <React.Fragment>
            <DisplayComponent
                open={open}
                setOpen={setOpen}
                actions={
                    <React.Fragment>
                        <UtilityButtonWrapper>
                            <UtilityButton
                                variant="text"
                                startIcon={<PurchaseIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                onClick={() => setIsAddingMembership(true)}>
                                Purchase Item
                            </UtilityButton>
                        </UtilityButtonWrapper>
                    </React.Fragment>
                }
            >
                {error && <Alert mb={4} severity="error">
                    {error}
                </Alert>}
                <Box display="flex" flexDirection="column" minHeight={setOpen ? '60vh' : 0} flex={1}>
                    {!company.isRABSite &&
                        <React.Fragment>

                            <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                <MembershipHeading display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography variant="h3" component="h3">Active</Typography>
                                </MembershipHeading>
                                {isLoading && <CircularProgress color="secondary" />}
                                {activeSubscriptions.map((purchase, index) => {
                                    return (
                                        <MembershipItem key={purchase.id}>
                                            <SubscriptionListItem purchase={purchase} />
                                        </MembershipItem>
                                    )
                                })}
                                {activeSubscriptions.length === 0 && <Box p={2}>
                                    <Typography ml={4}>No active subsciptions found.</Typography>
                                </Box>
                                }

                            </Card>
                            {errorSubscriptions.length > 0 &&
                                <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                    <MembershipHeading display="flex" flexDirection="row" justifyContent="space-between">
                                        <Typography variant="h3" component="h3">Errored</Typography>
                                    </MembershipHeading>
                                    {isLoading && <CircularProgress color="secondary" />}
                                    {errorSubscriptions.map((purchase, index) => {
                                        return (
                                            <MembershipItem key={purchase.id}>
                                                <SubscriptionListItem purchase={purchase} />
                                            </MembershipItem>
                                        )
                                    })}
                                </Card>
                            }
                            <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                <MembershipHeading display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography variant="h3" component="h3">Completed</Typography>
                                </MembershipHeading>
                                {isLoading && <CircularProgress color="secondary" />}
                                {completedSubscriptions.map((purchase, index) => {
                                    return (
                                        <MembershipItem key={purchase.id}>
                                            <SubscriptionListItem purchase={purchase} hidePrice />
                                        </MembershipItem>
                                    )
                                })}
                                {completedSubscriptions.length === 0 &&
                                    <Box p={2}>
                                        <Typography ml={4}>No completed subsciptions found.</Typography>
                                    </Box>
                                }
                            </Card>

                        </React.Fragment>
                    }
                    {!hidePurchases && (!purchases || purchases.length === 0) &&
                        <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                            <MembershipHeading display="flex" flexDirection="row" justifyContent="space-between">
                                <Typography variant="h3" component="h3">No purchases have been made yet.</Typography>
                            </MembershipHeading>
                        </Card>
                    }
                    {purchases && purchases.length > 0 && !hidePurchases &&
                        <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                            <MembershipHeading display="flex" flexDirection="row" justifyContent="space-between">
                                <Typography variant="h3" component="h3">Purchases</Typography>
                            </MembershipHeading>
                            {isLoading && <CircularProgress color="secondary" />}
                            {purchases.slice(0, purchasesToShow).map((purchase, index) => {
                                return (
                                    <MembershipItem key={purchase.id}>
                                        <PurchaseListItem purchase={purchase} displayedUser={displayedUser} />
                                    </MembershipItem>
                                )
                            })}
                            {purchasesToShow < purchases.length &&
                                <ActionButton
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setPurchasesToShow(prev => prev + 25)}
                                    mt={2}
                                >
                                    Show More
                            </ActionButton>
                            }
                        </Card>
                    }
                </Box>
            </DisplayComponent>
            {isAddingMembership &&
                <PurchaseProductModal
                    open={isAddingMembership}
                    setOpen={setIsAddingMembership}
                    company={company}
                    isChildAccount={isChildAccount}
                    displayedUser={displayedUser}

                />
            }
        </React.Fragment>
    );
}

export default SubscriptionList;