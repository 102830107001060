
import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Typography as MuiTypography
} from "@material-ui/core";

// svxvy imports
import { ReactComponent as SmartCoreLogo } from "../Assets/Logos/smartcore-logo.svg";

//Constants
const Typography = styled(MuiTypography)(spacing);

// constants
const PoweredBy = styled(Box)`
width: 100%;
padding: 0 11px 5px 0;
text-decoration: none;
`;

const PoweredBySmartCore = props => {
    const theme = useTheme();

    return (
        <PoweredBy component="a" href="https://smartcore.life" target="blank">
            <Typography variant="caption" style={{ color: theme.palette.text.secondary, marginBottom: '6px', display: 'block', fontSize: '12px', lineHeight: '14px' }}>powered by</Typography>
            <SmartCoreLogo fill={theme.palette.text.primary} style={{ width: '127px', height: '23px' }} />
        </PoweredBy>
    )
}

export default PoweredBySmartCore;