import { months } from '../../../components/company/activities/RRuleEditor';
import { groupBy } from 'lodash';

import { daysSinceToday, getActiveSubscriptionObjects } from '../../../helpers/commerceFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import { ACTIVE_MEMBERSHIPS_STATUSES } from '../../../models/Purchase';

export const sumReducer = (previousValue, currentValue) => previousValue + currentValue;

export const BY_MEMBERSHIP = 'BY_MEMBERSHIP';
export const BY_PERSON = 'BY_PERSON';

export const ACTIVE_MEMBERSHIPS_GROUP = 'Active';
export const ERROR_MEMBERSHIPS_GROUP = 'Errored';
export const EXPIRED_MEMBERSHIPS_GROUP = 'Expired';

export const groupPayments = (payments, people, groupBy) => {
    const uniqueAppUserIds = [...(new Set(payments.map(x => x.appUserId)))];

    const groupedData = [];
    for (var i = 0; i < uniqueAppUserIds.length; i++) {
        const appUserId = uniqueAppUserIds[i];
        const data = payments.filter(x => x.appUserId === appUserId).sort(compareObjects('paymentDate', 'desc'));
        const person = people.find(x => x.id === appUserId);
        const name = person ? person.displayName : 'Unknown';
        if (person) {
            groupedData.push({
                key: appUserId,
                payments: data,
                displayName: name,
                person: person,
                maxLate: daysSinceToday(new Date(Math.min(...data.map(e => new Date(e.activityDate))))),
                total: data.map(x => x.amount).reduce(sumReducer)
            });
        }
    }

    return groupedData.sort(compareObjects('displayName'), 'asc');
}

export const groupPurchaseByAppUser = (purchases, people, startDate, endDate) => {
    const uniqueAppUserIds = [...(new Set(purchases.map(x => x.appUserId)))];
    const groupedData = [];
    for (var i = 0; i < uniqueAppUserIds.length; i++) {
        const appUserId = uniqueAppUserIds[i];
        const data = purchases.filter(x => x.appUserId === appUserId).sort(compareObjects('datePurchased', 'desc'));
        const totalPaid = data.map(x => x.amountPaid).reduce(sumReducer);
        const person = people.find(x => x.id === appUserId);
        const name = person ? person.displayName : 'Unknown';

        groupedData.push({
            key: appUserId,
            purchases: data,
            displayName: name,
            person: person,
            total: totalPaid,
            startDate: startDate,
            endDate: endDate,
        });
    }

    return groupedData.sort(compareObjects('displayName'), 'asc');
}

export const groupPurchases = (purchases, people) => {
    const currentDate = new Date();
    const minYear = (new Date(Math.min(...purchases.map(e => new Date(e.datePurchased))))).getFullYear();
    const groupedData = [];
    for (var year = minYear; year <= currentDate.getFullYear(); year += 1) {
        const yearStart = new Date(year, 0, 1, 0, 0, 0, 0);
        const yearEnd = new Date(year + 1, 0, 1, 0, 0, 0, 0);
        const yearPurchases = purchases.filter(x => x.datePurchased >= yearStart && x.datePurchased < yearEnd);
        const yearTotal = yearPurchases && yearPurchases.length > 0 ? yearPurchases.map(x => x.amountPaid).reduce(sumReducer) : 0;
        const heading = currentDate.getFullYear() === year ? 'THIS YEAR' : (currentDate.getFullYear() - 1) === year ? 'LAST YEAR' : year;
        const dataByMonth = [];

        for (var month = 0; month < 12; month += 1) {
            const curMonth = month;
            const monthStart = new Date(year, month, 1, 0, 0, 0, 0);
            const monthEnd = new Date(year, month + 1, 1, 0, 0, 0, 0);
            const monthPurchases = purchases.filter(x => x.datePurchased >= monthStart && x.datePurchased < monthEnd);
            const monthTotal = monthPurchases && monthPurchases.length > 0 ? monthPurchases.map(x => x.amountPaid).reduce(sumReducer) : 0;
            if (monthPurchases.length > 0)
                dataByMonth.push({
                    key: year + '_' + month,
                    month: month,
                    heading: months.find(x => x.id === (curMonth + 1)).fullName,
                    total: monthTotal,
                    groupedByPeople: groupPurchaseByAppUser(monthPurchases, people, monthStart, monthEnd)
                })
        }
        dataByMonth.sort(compareObjects('month', 'desc'))
        if (yearPurchases.length > 0)
            groupedData.push({
                key: year + '',
                heading: heading,
                year: year,
                grandTotal: yearTotal,
                monthData: dataByMonth,
                openByDefault: currentDate.getFullYear() === year || (currentDate.getFullYear() - 1) === year ? true : false,
                isCurrentYear: currentDate.getFullYear() === year,
                yearGroupedByPeople: groupPurchaseByAppUser(yearPurchases, people, yearStart, yearEnd)
            })

    }

    return groupedData.sort(compareObjects('year', 'desc'));
};

export const getMembershipsByPerson = (purchases, people, groupedBy) => {
    // console.log(purchases[0])
    const dataToReturn = {
        activeSubscriptions: [],
        inactiveSubsciptions: [],
        errorSubsciptions: [],
    };

    for (const i in people) {
        const p = people[i];
        const userPurchases = purchases.filter(x => x.appUserId === p.id && x.purchasedProduct && x.purchasedProduct.isRecurring && x.isTentantPurchase);

        const activeSubscriptions = p ? getActiveSubscriptionObjects(userPurchases)
            .filter(x => (((x.expirationDate && x.expirationDate >= new Date()) || x.units > 0) 
                            && x.isTentantPurchase && ACTIVE_MEMBERSHIPS_STATUSES.includes(x.status))
                            || (x.refreshed && x.priorStatus === ACTIVE_MEMBERSHIPS_GROUP )) 
            .sort(compareObjects('expirationDate')) : [];
        const inactiveSubsciptions = p ? getActiveSubscriptionObjects(userPurchases)
            .filter(x => (((x.expirationDate && x.expirationDate < new Date() && x.purchasedProduct 
                            && !x.automaticallyRenew && x.purchasedProduct.isRecurring) && x.isTentantPurchase) 
                            || (x.refreshed && x.priorStatus === EXPIRED_MEMBERSHIPS_GROUP ) ) )
            .sort(compareObjects('expirationDate')) : [];
        const errorSubsciptions = p ? getActiveSubscriptionObjects(userPurchases)
            .filter(x => (((x.expirationDate && x.expirationDate < new Date() && x.automaticallyRenew) || ((!ACTIVE_MEMBERSHIPS_STATUSES.includes(x.status) && !(x.status === "canceled"))) || (x.refreshed && x.priorStatus === ERROR_MEMBERSHIPS_GROUP )) && x.purchasedProduct && x.purchasedProduct.isRecurring) && x.isTentantPurchase)
            .sort(compareObjects('expirationDate')) : [];

        if (activeSubscriptions.length > 0) {
            for (const j in activeSubscriptions) {
                const s = activeSubscriptions[j];
                dataToReturn.activeSubscriptions.push(
                    {
                        key: p.id + '_' + s.id,
                        person: p,
                        personName: p.displayName,
                        subscriptionName: s.purchasedProduct.name,
                        subscription: s
                    }
                )
            }
            dataToReturn.activeSubscriptions.sort(compareObjects('personName', 'asc'));
            dataToReturn.groupedActiveSubscriptions = groupBy(dataToReturn.activeSubscriptions, sub => (groupedBy === BY_MEMBERSHIP ? sub.subscriptionName + "|" + sub.subscription.purchasedProduct.id : sub.person.displayName + "|" + sub.person.id));
        }

        if (inactiveSubsciptions.length > 0) {
            for (const j in inactiveSubsciptions) {
                const s = inactiveSubsciptions[j];
                dataToReturn.inactiveSubsciptions.push(
                    {
                        key: p.id + '_' + s.id,
                        person: p,
                        personName: p.displayName,
                        subscriptionName: s.purchasedProduct.name,
                        subscription: s
                    }
                )
            }
            dataToReturn.inactiveSubsciptions.sort(compareObjects('personName', 'asc'));
            dataToReturn.groupedInactiveSubscriptions = groupBy(dataToReturn.inactiveSubsciptions, sub => (groupedBy === BY_MEMBERSHIP ? sub.subscriptionName + "|" + sub.subscription.purchasedProduct.id : sub.person.displayName + "|" + sub.person.id));
        }

        if (errorSubsciptions.length > 0) {
            for (const j in errorSubsciptions) {
                const s = errorSubsciptions[j];
                dataToReturn.errorSubsciptions.push(
                    {
                        key: p.id + '_' + s.id,
                        person: p,
                        personName: p.displayName,
                        subscriptionName: s.purchasedProduct.name,
                        subscription: s
                    }
                )
            }
            dataToReturn.errorSubsciptions.sort(compareObjects('personName', 'asc'));
            dataToReturn.groupedErrorSubscriptions = groupBy(dataToReturn.errorSubsciptions, sub => (groupedBy === BY_MEMBERSHIP ? sub.subscriptionName + "|" + sub.subscription.purchasedProduct.id : sub.person.displayName + "|" + sub.person.id));
        }

    }

    return dataToReturn;
}

export const getPackagesByPerson = (purchases, people) => {
    // console.log(purchases[0])
    const dataToReturn = {
        active: [],
        inactive: [],
    };

    for (const i in people) {
        const p = people[i];
        const userPurchases = purchases.filter(x => x.appUserId === p.id && x.purchasedProduct && x.purchasedProduct.units > 0 && x.isTentantPurchase);

        const active = p ? userPurchases.filter(x => (x.units > 0 && x.isTentantPurchase))
            // .sort(compareObjects('expirationDate')) 
            : [];
        const inactive = p ? userPurchases.filter(x => (x.units === 0 && x.isTentantPurchase))
            // .sort(compareObjects('expirationDate')) 
            : [];

        if (active.length > 0) {
            for (const j in active) {
                const s = active[j];
                dataToReturn.active.push(
                    {
                        key: p.id + '_' + s.id,
                        person: p,
                        personName: p.displayName,
                        subscriptionName: s.purchasedProduct.name,
                        subscription: s
                    }
                )
            }
            dataToReturn.active.sort(compareObjects('personName', 'asc'));
            dataToReturn.groupedActive = groupBy(dataToReturn.active, sub => sub.subscriptionName);
        }

        if (inactive.length > 0) {
            for (const j in inactive) {
                const s = inactive[j];
                dataToReturn.inactive.push(
                    {
                        key: p.id + '_' + s.id,
                        person: p,
                        personName: p.displayName,
                        subscriptionName: s.purchasedProduct.name,
                        subscription: s
                    }
                )
            }
            dataToReturn.inactive.sort(compareObjects('personName', 'asc'));
            dataToReturn.groupedInactive = groupBy(dataToReturn.inactive, sub => sub.subscriptionName);
        }
    }
    // console.log(dataToReturn)

    return dataToReturn;
}