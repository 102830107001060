import React, { useState, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    FormControlLabel as MuiFormControlLabel,
    Grid,
    Switch,
    TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import { checkCompanyName } from '../../../store/actions/registerCompanyActions'

// svxvy components

import { createCompany } from "../../../store/actions/registerCompanyActions";
import * as NavigationConstants from '../../../constants/NavigationConstants';
import ActionButton from "../../framework/ActionButton";
import { isRABSite } from "../../../constants/NetworkConstants";

// constants

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

export const ACTIVITY = 'Activity';
export const ACTIVITIES = 'Activities';
export const EMPLOYEE = 'Employee';
export const EMPLOYEES = 'Employees';
export const LOCATION = 'Location';
export const LOCATIONS = 'Locations';
export const CUSTOMER = 'Customer';
export const CUSTOMERS = 'Customers';

const RegisterCompanyForm = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const initialValues = {
        companyName: "",
        description: "",
        locations: [{
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            notes: '',
            isVirtual: false,
            photo: null,
        }],
        plan: "shared",
        // locationText: LOCATION,
        // locationsText: LOCATIONS,
        // employeeText: EMPLOYEE,
        // employeesText: EMPLOYEES,
        // activityText: ACTIVITY,
        // activitiesText: ACTIVITIES,
        // customerText: CUSTOMER,
        // customersText: CUSTOMERS,
        isProfileNameAliasAllowed: false,
        isProfilePhoneNumberRequired: false,
        companyContactEmail: '',
        phoneNumber: '',
        allowTexts: false,
    };

    const checkNameHandler = async (value) => {
        try {
            const isValid = await dispatch(checkCompanyName(value));
            return isValid;
        } catch (err) {
            return false;
        }
    };

    Yup.addMethod(Yup.string, 'checkValidName', (message) => {
        return Yup.string().test('checkValidName', message, async (name, message) => {
            if (!name) {
                return message.createError({ path: message.path, message: 'Requried' });;
            }
            if (name.length < 3) {
                return message.createError({ path: message.path, message: 'Company names must be at least 3 characters.' });;
            }
            const isValid = await checkNameHandler(name);
            if (!isValid)
                return message.createError({ path: message.path, message: 'Company name in use or too close to another company name.' });;

            return true;
        });
    });

    const validationSchema = Yup.object().shape({
        companyName: Yup.string()
            .checkValidName(),
        // description: Yup.string().required("Required"),
        locations: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .required("Required"),
            }))

    });

    const saveCompany = useCallback(async (values) => {
        setIsSubmitting(true);
        setError(null);
        try {
            values.isRABSite = isRABSite();

            const company = await dispatch(createCompany(values));
            history.replace("/" + company.sanitizedName + "/" + NavigationConstants.DASHBOARD);
        } catch (err) {
            setError(err.message);
        }
        setIsSubmitting(false);
    }, [dispatch, history]);

    const handleSubmit = (values) => {
        saveCompany(values)
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit} >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                values,
                status,
            }) => (
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="companyName"
                        label="Company Name"
                        value={values.companyName}
                        error={Boolean(touched.companyName && errors.companyName)}
                        fullWidth
                        helperText={touched.companyName && errors.companyName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        placeholder="" />
                    <FieldArray name="locations">
                        {({ insert, remove, push }) =>
                        (
                            <React.Fragment>
                                {values.locations.length > 0 &&
                                    values.locations.map((location, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <TextField
                                                    name="name"
                                                    label="Location Name"
                                                    value={location.name}
                                                    error={Boolean(errors.locations && errors.locations[index] && errors.locations[index].name)}
                                                    helperText={errors.locations && errors.locations[index] && errors.locations[index].name}
                                                    // onBlur={handleBlur}
                                                    onChange={(event) => { setFieldValue(`locations.${index}.name`, event.target.value); }}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="" />
                                                <TextField
                                                    name="address"
                                                    label="Address"
                                                    value={location.address}
                                                    // error={Boolean(touched.address && errors.address)}
                                                    // helperText={touched.address && errors.address}
                                                    // onBlur={handleBlur}
                                                    onChange={(event) => { setFieldValue(`locations.${index}.address`, event.target.value); }}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="" />
                                                <TextField
                                                    name="city"
                                                    label="City"
                                                    value={location.city}
                                                    // error={Boolean(touched.city && errors.city)}
                                                    // helperText={touched.city && errors.city}
                                                    // onBlur={handleBlur}
                                                    onChange={(event) => { setFieldValue(`locations.${index}.city`, event.target.value); }}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="" />
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="state"
                                                            label="State"
                                                            value={location.state}
                                                            // error={Boolean(touched.state && errors.state)}
                                                            // helperText={touched.state && errors.state}
                                                            // onBlur={handleBlur}
                                                            onChange={(event) => { setFieldValue(`locations.${index}.state`, event.target.value); }}
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="zip"
                                                            label="Zip"
                                                            value={location.zip}
                                                            // error={Boolean(touched.zip && errors.zip)}
                                                            // helperText={touched.zip && errors.zip}
                                                            // onBlur={handleBlur}
                                                            onChange={(event) => { setFieldValue(`locations.${index}.zip`, event.target.value); }}
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder="" />
                                                    </Grid>
                                                </Grid>
                                                <FormControlLabel control={
                                                    <Field name='isVirtual' component={Switch} onChange={(val) => setFieldValue(`locations.${index}.isVirtual`, !location.isVirtual)} checked={location.isVirtual} />
                                                } label={'This location can host virtual activities.'} labelPlacement="start"/>
                                            </React.Fragment>
                                        )
                                    })}
                            </React.Fragment>
                        )
                        }
                    </FieldArray>
                    <ActionButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                        submitting={isSubmitting}
                        fullWidth
                    >
                        Register
                    </ActionButton>
                    {error && <Alert my={2} severity="error">
                        {error}
                    </Alert>}
                </form>
            )}
        </Formik >
    )
};

export default RegisterCompanyForm;


