
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    LinearProgress as MuiLinearProgress,
    List,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { fetchActivitiesForCustomer } from "../../../store/actions/activityActions";
import { fetchLocations } from "../../../store/actions/locationActions";
import { compareObjects } from "../../../helpers/helperFunctions";
import ActivityCard from '../activities/ActivityCard';
import StyledDialog from "../../framework/StyledDialog";

// constants

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ActvityListModal = (props) => {
    const { open, setOpen, displayedUser } = props;

    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);

    const companyId = useSelector(state => state.company.companyId);
    const displayeUserId = displayedUser.id;
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [actvitiesToDisplay, setActivitiesToDisplay] = useState(null)
    const [activitiesToShow, setActivitesToShow] = useState(100);

    const startDate = new Date(new Date((new Date()).getTime()).setHours(0, 0, 0, 0));
    const endDate = new Date(new Date((new Date()).setMonth(new Date().getMonth() + 1)).setHours(23, 59, 59, 999));

    const dispatch = useDispatch();
    const loadActivities = useCallback(async () => {
        try {
            setError(null);
            setIsLoading(true);
            const activities = await dispatch(fetchActivitiesForCustomer(companyId, displayeUserId));

            const filteredActivities = activities.filter(x => x.isInActivityInstance(x.people.find(y => y.appUserId === displayeUserId), x));
            filteredActivities.sort(compareObjects('displaybleStartDateTime', 'desc'))
            await dispatch(fetchLocations(companyId));
            setActivitiesToDisplay(filteredActivities)
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId, displayeUserId]);

    useEffect(() => {
        loadActivities();
    }, [loadActivities]);


    return (

        <StyledDialog
            open={open}
            setOpen={setOpen}
        >
            <Box display="flex" flexDirection="column">
                <Typography component="h2" variant="h1" mb={5}>{ACTIVITIES_TEXT} for {displayedUser.displayName}</Typography>
                {error && !isLoading && <Alert severity="error" my={2}>{error}</Alert>}
                {isLoading &&
                    <Box flex={1}>
                        <LinearProgress my={2} color="primary" />
                        <Typography variant={"h2"} align="center" gutterBottom>Loading {ACTIVITIES_TEXT}...</Typography>
                        <LinearProgress my={2} color="secondary" />
                    </Box>
                }
                <List>
                    {actvitiesToDisplay && actvitiesToDisplay.length === 0 &&
                        <Typography variant={"h2"} align="center" gutterBottom>No {ACTIVITIES_TEXT} Taken</Typography>
                    }
                    {actvitiesToDisplay && actvitiesToDisplay.slice(0, activitiesToShow).map((activity, ind) => {
                        return (
                            <Card key={activity.id} mb={2} px={2}>
                                <ActivityCard 
                                    key={activity.id}
                                    activity={activity}
                                    displayedUser={displayedUser}
                                    viewStartDate={startDate}
                                    viewEndDate={endDate}
                                    showDate
                                    hideDurration
                                    dontAllowShowDetails
                                     />
                            </Card>
                        )
                    })
                    }
                    {actvitiesToDisplay && actvitiesToDisplay.length > activitiesToShow &&
                        <Button variant="contained" color="primary" fullWidth onClick={() => setActivitesToShow(prevValue => prevValue + 20)} mb={3}>Show More</Button>
                    }
                </List>

                {error && !isLoading && <Alert severity="error" my={2}>{error}</Alert>}
            </Box>
        </StyledDialog >
    );
}

export default ActvityListModal;
