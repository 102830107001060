import React, { useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import parse from 'html-react-parser';
import i18n from 'i18n-js';

import {
    Box,
    Button,
    Grid,
    Hidden,
    Typography,
} from "@material-ui/core";

// svxvy import
import { formatImage } from "../../companyPublic/LeftRightComponent";
import LoginDialog from '../userManagement/LoginDialog';
import ParagraphTypography from "../../framework/ParagraphTypography";
import StripeWrapper from '../paymentMethods/StripeWrapper'
import BuyAProductModal from './BuyAProductModal';

// constants
const ImageTextBlock = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.paper};
    margin-bottom: 30px;
    
    ${(props) => props.theme.breakpoints.up("md")} {
        margin-bottom: 50px;
    }
`;

const ImageTextBlockImage = styled(Box)`
    width: 100%;
    padding-top: 66%;

    ${(props) => props.theme.breakpoints.up("md")} {
        height: 100%;
        min-height: 420px;
        padding-top: 0;
    }
`;

const ImageTextBlockContent = styled(Box)`
    padding: 19px 27px 30px 27px;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: 50px 27px 50px 27px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
`;


const ProductCard = (props) => {
    const { product } = props;
    const company = useSelector((state) => state.company.company);
    const loggedInUser = useSelector((state) => state.auth.person);
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [showBuyAProductModal, setShowBuyAProductModal] = useState(false);


    const primaryPhoto = product && product.photos && product.photos.length > 0 ? product.photos[0] : null;

    console.log(product)

    return (
        <React.Fragment>
            <ImageTextBlock>
                <Grid container mb={21} >
                    {primaryPhoto &&
                        <Hidden smDown>
                            <Grid item xs={12} md={5}>
                                <ImageTextBlockImage style={{ backgroundImage: 'url(' + formatImage(primaryPhoto.url, null, '.webp') + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}></ImageTextBlockImage>
                            </Grid>
                        </Hidden>
                    }
                    <Grid item xs={false} md={1}></Grid>
                    <Grid item xs={12} md={primaryPhoto ? 5 : 10}>
                        <ImageTextBlockContent>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="flex-end">
                                <Typography variant="h3">{product.name}</Typography>
                                <Typography variant="h4">
                                    {i18n.toCurrency(product.unitAmount, { precision: 2, unit: '$' })}
                                    {(product.isRecurring || product.units > 0) &&
                                        <React.Fragment>
                                            {product.isRecurring ? " / " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : ""}
                                        </React.Fragment>
                                    }
                                </Typography>
                            </Box>
                            {(product.freeTrialDays !== null &&  product.freeTrialDays > 0) && <Typography variant="body2" mb={2}>This membership offers a {product.freeTrialDays} day free trial.</Typography>}
                            {product.description && <ParagraphTypography mb={2} >{parse(product.description)}</ParagraphTypography>}
                            {product.externalUrl && <Button variant="outlined" color="primary"
                                component="a"
                                href={product.externalUrl}
                                target="_blank">
                                Purchase
                            </Button>}
                            {loggedInUser && loggedInUser.companyId === company.id && !product.externalUrl &&
                                <Button variant="outlined"
                                    color="primary"
                                    onClick={() => setShowBuyAProductModal(true)}
                                >
                                    Purchase
                            </Button>}
                            {(!loggedInUser || (loggedInUser.companyId !== company.id)) && !product.externalUrl &&
                                <Button variant="outlined"
                                    color="primary"
                                    onClick={() => setLoginDialogOpen(true)}
                                >
                                    Sign In or Register to Purchase
                                </Button>
                            }
                        </ImageTextBlockContent>
                    </Grid>
                </Grid>
            </ImageTextBlock >
            {loginDialogOpen &&
                <LoginDialog open={loginDialogOpen} setOpen={setLoginDialogOpen} />
            }
            {showBuyAProductModal &&
                <StripeWrapper showTenantPaymentMethods open={showBuyAProductModal}>
                    <BuyAProductModal
                        open={showBuyAProductModal}
                        setOpen={setShowBuyAProductModal}
                        product={product}
                        displayedUser={loggedInUser}
                    />
                </StripeWrapper>
            }
        </React.Fragment>
    );
}

export default ProductCard;