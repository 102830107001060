import * as Colors from "../constants/Colors";

const webTypography = {
  name: Colors.COMPANY_WEB_TYPOGRAPHY,
  typography: {
    fontFamily: [
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "-apple-system",
      "sans-serif",
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      marginBottom: '24px',
      fontWeight: 700,
      fontSize: "3.8rem",
      lineHeight: 1.052,
      '@media (min-width:1200px)': {
        fontSize: "4.8rem",
        lineHeight: 1.1041,
      },
    },
    h2: {
      marginBottom: '15px',
      fontWeight: 700,
      fontSize: "3.2rem",
      lineHeight: 1.1111,
      // '@media (min-width:1200px)': {
      //   marginBottom: '24px',
      //   fontSize: '3.6rem',
      //   lineHeight: 1.0937,
      // },
    },
    h3: {
      marginBottom: "17px",
      fontWeight: 700,
      fontSize: "2.4rem",
      lineHeight: 1.0833,
    },
    h4: {

    },
    h5: {

    },
    h6: {

    },
    subtitle1: {

    },
    subtitle2: {

    },
    body1: {
      // marginBottom: "23px",
      fontWeight: 400,
      fontSize: "1.6rem",
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "1.6rem",
      lineHeight: 1.5,
    },
    button: {
      fontWeight: 700,
      fontSize: "1.6rem",
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "2.0rem",
      lineHeight: 1.5,
      '@media (min-width:1200px)': {
        fontSize: '2.4rem',
      },
    },
    overline: {

    },
  }
};

const appTypography = {
  name: Colors.COMPANY_APP_TYPOGRPAHY,
  typography: {
    fontFamily: [
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "-apple-system",
      "sans-serif",
    ].join(","),
    fontSize: 22,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: { // title 1
      fontWeight: 700,
      fontSize: "2.4rem",
      lineHeight: 1.0416,
      '@media (min-width:768px)': {
        fontSize: "2.8rem",
        lineHeight: 1.0714,
      },
    },
    h2: { // title 2
      fontWeight: 700,
      fontSize: "2.4rem",
      lineHeight: 1.0416,
    },
    h3: { // title 3
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1,
    },
    h4: { // headline
      fontWeight: 700,
      fontSize: "1.6rem",
      lineHeight: 1.125,
    },
    h5: { // label
      fontWeight: 700,
      fontSize: "1.2rem",
      lineHeight: 1.3333,
      // textTransform: "uppercase",
    },
    h6: { // label small
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: 1.4,
      textTransform: "uppercase",
    },
    subtitle1: { // caption
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: 1.3333,
    },
    subtitle2: { // caption small
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.4,
    },
    body1: { // body
      fontWeight: 400,
      fontSize: "1.4rem",
      lineHeight: 1.2857,
    },
    body2: { // body tag
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "1.4rem",
      lineHeight: 1.2857,
    },
    button: { // button
      textTransform: "none",
    },
    caption: {

    },
    overline: {

    },
  }
};

const typographies = [
  webTypography,
  appTypography,
];

export default typographies;
