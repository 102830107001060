import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    CircularProgress as MuiCircularProgress,
    Card as MuiCard,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports
import PurchaseListItem from "./PurchaseListItem";
import { fetchPurchasesForCurrentUser } from "../../../store/actions/paymentActions";
import { fetchPeople } from "../../../store/actions/peopleActions";
import { compareObjects } from "../../../helpers/helperFunctions";
// constants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const PurchaseList = (props) => {
    const { companyId, showSCPurchases } = props;

    const purchases = useSelector(state => state.auth.purchases).filter(x =>  (showSCPurchases ? !x.isTentantPurchase : x.isTentantPurchase) 
                                            && x.status !== "past_due"
                                            && x.companyId === companyId ).sort(compareObjects('datePurchased', 'desc'));
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const loadPurchases = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(fetchPeople(companyId));
            await dispatch(fetchPurchasesForCurrentUser(companyId));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId]);

    useEffect(() => {
        loadPurchases();
    }, [loadPurchases]);

    return (
        <Card mb={6} p={4}>
            <Typography variant="h3" mb={3}>Your Purchased Items</Typography>
            {isLoading && <CircularProgress color="secondary" />}
            {!isLoading && !error && purchases.map((purchase, index) => {
                return (
                    <PurchaseListItem key={purchase.id} purchase={purchase} />
                )
            })}
            {error && <Alert mb={4} variant="outlined" severity="error">
                {error}
            </Alert>}
        </Card>
    );
}

export default PurchaseList;