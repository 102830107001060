const breakpoints = {
  values: {
    xs: 0, // 0
    sm: 768, // 600
    md: 992, // 960
    lg: 1200, // 1280
    xl: 1440, // 1440
  },
};

export default breakpoints;
