import Activity from "../../models/Activity";

import { RRule } from 'rrule';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import * as NetworkConstants from '../../constants/NetworkConstants';
import * as SecurityConstants from '../../constants/SecurityConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';
import { checkClaim, GUID_Generator } from '../../helpers/helperFunctions';


export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const RELOAD_ACTIVITIES_FROM_STORE = 'RELOAD_ACTIVITIES_FROM_STORE';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

export const ENROLL_IN_ACTIVITY = 'ENROLL_IN_ACTIVITY';
export const DROP_ACTIVITY = 'DROP_ACTIVITY';
export const CANCEL_ACTIVITY = 'CANCEL_ACTIVITY';
export const RESTORE_ACTIVITY = 'RESTORE_ACTIVITY';

export const expandActivity = (activityToAdd, startDate, endDate, person, userClaims, hideFullSelfRegisterActivities) => {
    const hasNoRoles = person && (!person.roles || person.roles.length) === 0 ? true : false;

    const viewAllActivities = checkClaim(userClaims, SecurityConstants.VIEW_ALL_ACTIVITIES);
    const currentUserId = person ? person.id : null;
    const childUserIds = person && person.childProfiles ? person.childProfiles.map(x => x.id) : [];

    const activitiesToReturn = [];

    if (activityToAdd.rrule == null || activityToAdd.rrule.trim().length === 0) {
        activitiesToReturn.push(activityToAdd);
    } else {
        // working here
        let options = RRule.parseString(activityToAdd.rrule.trim());
        options.dtstart = new Date(Date.UTC(activityToAdd.startDateTime.getFullYear(),
            activityToAdd.startDateTime.getMonth(),
            activityToAdd.startDateTime.getDate(),
            activityToAdd.startDateTime.getHours(),
            activityToAdd.startDateTime.getMinutes(),
            activityToAdd.startDateTime.getSeconds(),
            activityToAdd.startDateTime.getMilliseconds()));

        const startBound = startDate ? new Date(startDate) : new Date();
        const endBound = endDate ? new Date(endDate) : new Date((new Date()).setMonth(new Date().getMonth() + 6));
        const occurances = new RRule(options).between(startBound, endBound, true);
        const occurancesInPast = (new RRule(options).between(new Date(activityToAdd.startDateTime), new Date(), true)).length;

        for (const x in occurances) {
            // console.log(activityToAdd.title)
            // if (activityToAdd.title === 'PT Session (Laura + Tina)')
            //     console.log(activityToAdd.title,  occurances[x],  occurances[x].getTime(), (activityToAdd.startDateTime.getTimezoneOffset() * 60 * 1000)  )

            const occuranceTime = occurances[x].getTime() + (activityToAdd.startDateTime.getTimezoneOffset() * 60 * 1000);
            const correctedStartTime = new Date(occuranceTime + ((occurances[x].getTimezoneOffset() - activityToAdd.startDateTime.getTimezoneOffset()) * 60 * 1000));

            // if (activityToAdd.title.startsWith('Boxing (Alaska)'))
            // {
            //     console.log(activityToAdd.title, occuranceTime, correctedStartTime, new Date(occuranceTime), activityToAdd.endDateTime, (correctedStartTime > activityToAdd.endDateTime))
            // }
            if (occuranceTime <= startBound)
                continue;
            if (activityToAdd.endDateTime > new Date(2010, 0, 1, 0, 0, 0, 0) && correctedStartTime > activityToAdd.endDateTime) {
                // console.log("not shwowing", activityToAdd.title,occuranceTime )
                continue;
            }
            if (activityToAdd.recurranceExpections.includes(occuranceTime))
                continue;

            const clonedActivity = cloneDeep(activityToAdd);
            clonedActivity.id = clonedActivity.id + "_" + occuranceTime;
            clonedActivity.occuranceId = occuranceTime.toString();
            clonedActivity.originalStartDate = activityToAdd.startDateTime;
            clonedActivity.startDateTime = occurances[x];

            clonedActivity.displaybleStartDateTime = correctedStartTime; //new Date(occuranceTime + ((occurances[x].getTimezoneOffset() - activityToAdd.startDateTime.getTimezoneOffset()) * 60 * 1000));
            clonedActivity.recurranceOccurancesInPast = occurancesInPast + parseInt(x);

            if (hasNoRoles && !clonedActivity.isPublic) {
                //if the user has no roles only show them the instance of the activity that they are in or are marked public
                const attendeeObject = clonedActivity.people.find(x => x.appUserId === currentUserId);
                if (!attendeeObject)
                    continue;
                else if (!attendeeObject.isRunningActivity && !attendeeObject.attendAllInstances && attendeeObject.attendInstanceDates.indexOf(occuranceTime))
                    continue;
            }

            if (!viewAllActivities && !clonedActivity.allowSelfRegister && !clonedActivity.isPublic) {
                //if i can't view all activities and this activity doesn't allow self registers
                //check to see if I'm enrolled in all instances and have not dropped this instance
                //then check to see if I'm attending this instance.
                //if all this is true then allow activity to be added other wise skip adding activity.
                if (!clonedActivity.people.some(x => x.appUserId === currentUserId
                    && ((x.attendAllInstances && x.dropInstanceDates.indexOf(clonedActivity.occuranceId) === -1)
                        || (x.attendInstanceDates.indexOf(clonedActivity.occuranceId) > -1))))
                    continue;
            }
            if (hideFullSelfRegisterActivities && clonedActivity.allowSelfRegister && !clonedActivity.isPublic && !viewAllActivities) {
                //if i can't view all activities and this activity doesn't allow self registers
                //check to see if I'm enrolled in all instances and have not dropped this instance
                //then check to see if I'm attending this instance.
                //if all this is true then allow activity to be added other wise skip adding activity.
                const attendees = clonedActivity.people.filter(a => !a.isRunningActivity && clonedActivity.isInActivityInstance(a, clonedActivity));

                if ((clonedActivity.totalSpaces - attendees.length) <= 0
                    && !clonedActivity.people.some(x => (x.appUserId === currentUserId || childUserIds.some(y => y === x.appUserId))
                        && ((x.attendAllInstances && x.dropInstanceDates.indexOf(clonedActivity.occuranceId) === -1)
                            || (x.attendInstanceDates.indexOf(clonedActivity.occuranceId) > -1))))
                    continue;
            }

            activitiesToReturn.push(clonedActivity);
        }
    }
    return activitiesToReturn;
}

export const fetchActivities = (companyId, startDate, endDate) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!endDate) {
            throw new Error('Must include an end date.');
        }
        // any asych code you want - like service call
        let call = NetworkConstants.SERVER + '/api/activities/' + companyId + '?appVersion=website';
        const revisedEndDate = endDate !== null ? new Date(endDate).setUTCHours(23, 59, 59, 99) : null;
        const revisedStart = startDate ? new Date(startDate).setUTCHours(0, 0, 0, 0) : new Date().setUTCHours(0, 0, 0, 0);
        if (startDate) {
            call = call + '&startDate=' + moment(revisedStart).toISOString() + '&endDate=' + moment(revisedEndDate).toISOString();
        }
        else
            call = call + '&endDate=' + moment(revisedEndDate).toISOString();
        const response = await fetch(call, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const activityData = resData.activities;

        const loadedActivities = [];
        const nonExpandedActivites = [];

        const company = getState().company.company;
        const loggedInUser = getState().auth.person;
        const loggedInUserClaims = getState().auth.claims;

        for (const key in activityData) {
            const activityToAdd = new Activity(activityData[key].id, null);
            // const nonExpandedActivites = new Activity(activityData[key].id, null);
            activityToAdd.update(activityData[key]);

            nonExpandedActivites.push(activityToAdd);
            const expandedActivity = expandActivity(activityToAdd, revisedStart, revisedEndDate, loggedInUser, loggedInUserClaims, company.hideFullSelfRegisterActivities);
            for (const a in expandedActivity)
                loadedActivities.push(expandedActivity[a]);
        }

        dispatch({ type: SET_ACTIVITIES, activities: loadedActivities, nonExpandedActivites: nonExpandedActivites });
    }
}

export const fetchActivitiesForCustomer = (companyId, customerId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!customerId) {
            throw new Error('Must include a customerId.');
        }
        // any asych code you want - like service call
        let call = NetworkConstants.SERVER + '/api/activities/' + companyId + "/" + customerId;
        const response = await fetch(call, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const activityData = resData.activities;

        const loadedActivities = [];

        const company = getState().company.company;
        const loggedInUser = getState().auth.person;
        const loggedInUserClaims = getState().auth.claims;

        for (const key in activityData) {
            const activityToAdd = new Activity(activityData[key].id, null);
            activityToAdd.update(activityData[key]);
            if (company.isRABSite)
                loadedActivities.push(activityToAdd);
            else {
                const expandedActivity = expandActivity(activityToAdd, new Date((new Date()).setMonth(new Date().getMonth() - 360)), new Date((new Date()).setMonth(new Date().getMonth() + 12)), loggedInUser, loggedInUserClaims, company.hideFullSelfRegisterActivities);
                for (const a in expandedActivity)
                    loadedActivities.push(expandedActivity[a]);
            }
        }

        return loadedActivities;
    }
}

export const getActivityDetail = (id, occuranceId) => {
    return async (dispatch, getState) => {
        if (!id) {
            throw new Error('Activity ID is blank.');
        }

        let url = NetworkConstants.SERVER + '/api/activities/detail/' + id;
        if (occuranceId) {
            url = url + "?occuranceId=" + occuranceId;
        }
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            const message = errorResData.errors && errorResData.errors.message ? errorResData.errors.message : 'Something went wrong!';
            throw new Error(message);
        }

        const resData = await response.json();
        const activity = new Activity(resData.id, null);
        activity.update(resData);

        return activity;
    }
}

export const addActivity = (activityValues, companyId, startDate, endDate) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        activityValues.Id = GUID_Generator();
        response = await fetch(NetworkConstants.SERVER + '/api/activities/v2/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                activityValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }


        // Editing an activity is complex its easier to just to refetch all activities.
        if (startDate && endDate)
            dispatch(fetchActivities(companyId, startDate, endDate))
    }
}

export const editActivity = (activityId, instanceId, activityValues, companyId, startDate, endDate, getUpdatedActivities = true) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/' + companyId + '/' + activityId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                activityValues
            ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        // Editing an activity is complex its easier to just to refetch all activities.
        if (getUpdatedActivities)
            dispatch(fetchActivities(companyId, startDate, endDate))
    }
}

export const enrollInActivity = (companyId, id, attendeeId, startDate, endDate, getUpdatedActivities, isRunningActivity) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('Activity ID is blank.');
        }
        let activityId = id;
        let instanceId = "0";
        if (id.indexOf('_') > 0) {
            activityId = id.split('_')[0];
            instanceId = id.split('_')[1].toString();
        }

        const response = await fetch(NetworkConstants.SERVER + '/api/activities/' + companyId + "/" + activityId + "/attend", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    // status: status,
                    activityInstanceDate: instanceId,
                    attendeeId: attendeeId,
                    isRunningActivity: isRunningActivity
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));

        }

        if (getUpdatedActivities)
            await dispatch(fetchActivities(companyId, startDate, endDate))
    };
}

export const dropActivity = (companyId, id, attendeeId, startDate, endDate, getUpdatedActivities) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('Activity ID is blank.');
        }
        let activityId = id;
        let instanceId = "0";
        if (id.indexOf('_') > 0) {
            activityId = id.split('_')[0];
            instanceId = id.split('_')[1].toString();
        }

        const response = await fetch(NetworkConstants.SERVER + '/api/activities/' + companyId + "/" + activityId + "/attend", {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    activityInstanceDate: instanceId,
                    attendeeId: attendeeId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        if (getUpdatedActivities)
            await dispatch(fetchActivities(companyId, startDate, endDate))

    };
}

export const cancelActivity = (companyId, id, statusUpdate, startDate, endDate,) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('Activity ID is blank.');
        }
        let activityId = id;
        let instanceId = "0";
        if (id.indexOf('_') > 0) {
            activityId = id.split('_')[0];
            instanceId = id.split('_')[1].toString();
        }

        const response = await fetch(NetworkConstants.SERVER + '/api/activities/' + companyId + "/" + activityId + "/status", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    occuranceId: instanceId,
                    status: statusUpdate ? statusUpdate : "canceled"
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        // const status = {
        //     activityId: activityId,
        //     occuranceId: instanceId,
        //     status: statusUpdate ? statusUpdate : "canceled"
        // };

        await dispatch(fetchActivities(companyId, startDate, endDate))

        // if (statusUpdate === 'active')
        //     await dispatch({ type: RESTORE_ACTIVITY, activityId: id, status: status });
        // else
        //     await dispatch({ type: CANCEL_ACTIVITY, activityId: id, status: status });
    };
}


export const reloadActivitiesFromStore = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: RELOAD_ACTIVITIES_FROM_STORE
        });
    };
}

export const deleteActivity = (activityId, occuranceId, companyId, startDate, endDate) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/' + companyId + '/' + activityId + '/' + occuranceId, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        // deleting an activity is complex its easier to just to refetch all activities.
        await dispatch(fetchActivities(companyId, startDate, endDate))
    };
}

export const deleteSeries = (activityId, occuranceId, companyId, startDate, endDate) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/deleteseries/' + companyId + '/' + activityId + '/' + occuranceId, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        // deleting an activity is complex its easier to just to refetch all activities.
        await dispatch(fetchActivities(companyId, startDate, endDate))
    };
}


export const confirmActivity = (activityId, companyId, startDate, endDate, reload) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        if (activityId.includes('_'))
            activityId = activityId.split("_")[0];
        response = await fetch(NetworkConstants.SERVER + '/api/activities/confirm/' + companyId + '/' + activityId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        if (reload)
            await dispatch(fetchActivities(companyId, startDate, endDate))
        else {
            const resData = await response.json();
            const activity = new Activity(resData.id, null);
            activity.update(resData);

            return activity;
        }
    }
}


export const rejectActivity = (activityId, companyId, reason, startDate, endDate, reload) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }

        if (activityId.includes('_'))
            activityId = activityId.split("_")[0];

        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/reject/' + companyId + '/' + activityId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    reason: reason
                }
            ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        if (reload)
            await dispatch(fetchActivities(companyId, startDate, endDate))
        else {
            const resData = await response.json();
            const activity = new Activity(resData.id, null);
            activity.update(resData);

            return activity;
        }
    }
}

export const resendConfirmation = (activityId, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!activityId) {
            throw new Error('Activity ID is blank.');
        }

        if (activityId.includes('_'))
            activityId = activityId.split("_")[0];

        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/resendconfirmation/' + companyId + '/' + activityId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                }
            ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

    }
}