import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { useTheme } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Button,
    Chip as MuiChip,
    Typography as MuiTypography,
    CircularProgress as MuiCircularProgress,
    Box,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoVideocamOutline as VideoIcon,
    IoRefreshCircleOutline as RestoreIcon,
    IoAlertCircleSharp as CancelIcon,
    IoTime as ClockIcon,
    IoCheckmarkCircleOutline as CheckIcon,
    // IoPeople as FullIcon,
    // IoAlarmOutline as FinishedIcon,
    IoPencilOutline as PencilIcon,
    IoChatboxEllipses as ChatIcon,
    IoCloseCircleOutline as CancelMenuIcon,
    IoDuplicateOutline as CopyIcon,
    IoTrash as DeleteIcon,
} from "react-icons/io5";

// svxvy imports

import * as SecurityConstants from '../../../constants/SecurityConstants';
import {
    cancelActivity,
    deleteActivity, expandActivity, fetchActivities
} from '../../../store/actions/activityActions';
import { checkClaim, getMilliseconds, compareObjects } from '../../../helpers/helperFunctions';
import ActionButton from '../../framework/ActionButton';
import NonExpandedActivityDetail from "./NonExpandedActivityDetail";
import MessageModal from '../messages/MessageModal';
import StyledDialog from '../../framework/StyledDialog';
import ActivityAddEditModal from './ActivityAddEditModal';
import StripeWrapper from "../paymentMethods/StripeWrapper";
import CustomPurchaseModal from "../products/CustomPurchaseModal";

import LoginDialog from '../userManagement/LoginDialog';
import FileUploader from '../../framework/FileUploader';
import ErrorDialog from '../../framework/ErrorDialog';

// UI Consts
const CircularProgress = styled(MuiCircularProgress)(spacing);
const ChipToStyle = styled(MuiChip)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Chip = styled(ChipToStyle)`
    height: 20px;
    border-radius: 999999px;
    margin-top: -2px;
    padding: 0 9px;
    font-weight: 700;
    font-size: 12px !important;

    span {
        padding: 0;
    }
`;

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding-bottom: 0;
        flex-direction: row;
        align-items: center;
    }
`;

const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 14px;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 26px 0 0;
        padding: 0 2px;
    
        &:last-child {
            margin: 0;
        }
    }
`;

const ICON_SIZE = 24;
const EDIT_CLOSE = 43200000 * 2;

// for some reason I can't do this inline or react blows up.
const stupidFunction = (activity, loggedInUser) => {
    if (!activity || !loggedInUser)
        return false

    return activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity, true);
}

const DeleteClassAction = props => {
    const { ACTIVITY_TEXT, dispatch, companyId, activity, title, hideSpotsRemaining, viewStartDate, viewEndDate } = props;
    const [isRunning, setIsRunning] = useState(false)
    const [error, setError] = useState(null);
    const theme = useTheme();
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);


    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled'
            && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

    const acitivtyFull = activity.spacesRemaining(activity, false) > 0 ? false : true;

    const deleteActivityHandler = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
        try {
            const additionalMessage = '\n\nEnrolled ' + CUSTOMERS_TEXT.toLowerCase() + ' are not notified when you delete a ' + ACTIVITY_TEXT.toLowerCase() + '.  To notify them cancel the ' + ACTIVITY_TEXT.toLowerCase() + ' first.'
            const r = window.confirm("Are you sure you want to delete this " + ACTIVITY_TEXT + "?" + additionalMessage);
            if (!r) {
                return;
            }
            setError(null)
            setIsRunning(true);
            const activityIdToSend = activityId.indexOf('_') >= 0 ? activityId.split('_')[0] : activityId;
            const occuranceId = activityId.indexOf('_') >= 0 ? activityId.split('_')[1] : 0;
            await dispatch(deleteActivity(activityIdToSend, occuranceId, companyId, viewStartDate, viewEndDate));
        } catch (err) {
            setError(err.message);
        }
        setIsRunning(false);
    }, [dispatch, ACTIVITY_TEXT, CUSTOMERS_TEXT]);

    return (
        <React.Fragment>
            <ActionButton
                key={activity.id}
                mr={2}
                mb={2}
                color="primary"
                variant="outlined"
                onClick={() => deleteActivityHandler(activity.id, companyId, viewStartDate, viewEndDate)}
                style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, textDecoration: (activityCanceled ? 'line-through' : 'none') }}
                disabled={isRunning}
            >
                {isRunning && <CircularProgress color="primary" size={20} mr={2} />}
                {title ? title : moment(activity.displaybleStartDateTime).format('MMM DD, YYYY')}
                {hideSpotsRemaining ? ''
                    : acitivtyFull ? " - full" : " - " + activity.spacesRemaining(activity, false) + " spots remaining"}
            </ActionButton>
            {error &&
                <ErrorDialog message={error} setMessage={setError} />
            }
        </React.Fragment>
    )
}


const CancelClassAction = props => {
    const { ACTIVITY_TEXT, dispatch, companyId, activity, title, hideSpotsRemaining, viewStartDate, viewEndDate } = props;
    const [isRunning, setIsRunning] = useState(false)
    const [error, setError] = useState(null);
    const theme = useTheme();

    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled'
            && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

    const acitivtyFull = activity.spacesRemaining(activity, false) > 0 ? false : true;

    const cancelHandler = useCallback(async (id, status) => {
        const r = window.confirm("Are you sure you want to " + (status === 'canceled' ? "cancel" : "restore") + " this " + ACTIVITY_TEXT + "?");
        if (!r) {
            return;
        }

        try {
            setError(null)
            setIsRunning(true);
            await dispatch(cancelActivity(companyId, id, status, viewStartDate, viewEndDate));
        } catch (err) {
            setError(err.message);
        }
        setIsRunning(false);
    }, [dispatch, companyId, ACTIVITY_TEXT, viewStartDate, viewEndDate])

    return (
        <React.Fragment>
            <ActionButton
                key={activity.id}
                mr={2}
                mb={2}
                color="primary"
                variant="outlined"
                onClick={() => cancelHandler(activity.id, activityCanceled ? 'active' : 'canceled')}
                style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, textDecoration: (activityCanceled ? 'line-through' : 'none') }}
                disabled={isRunning}
            >
                {isRunning && <CircularProgress color="primary" size={20} mr={2} />}
                {title ? title : moment(activity.displaybleStartDateTime).format('MMM DD, YYYY')}
                {hideSpotsRemaining ? ''
                    : acitivtyFull ? " - full" : " - " + activity.spacesRemaining(activity, false) + " spots remaining"}
            </ActionButton>
            {error &&
                <ErrorDialog message={error} setMessage={setError} />
            }
        </React.Fragment>
    )
}


const NonExpandedActivityDetailModal = props => {
    const { activityId, setOpen, open, companyId, viewStartDate, viewEndDate, editMode } = props;

    const activity = useSelector(state => state.activity.nonExpandedActivites).find(x => x.id === activityId);
    const company = useSelector(state => state.company.company);
    const theme = useTheme();

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    // const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const loggedInUser = useSelector(state => state.auth.person);
    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);
    // const seeAllPeople = checkClaim(userClaims, SecurityConstants.VIEW_ALL_PEOPLE);

    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

    const durationInMilliSeconds = !activity ? 0 : getMilliseconds(activity.duration, 'minutes');
    // const isInPast = !activity
    //     ? true
    //     : activity.occuranceId !== "0"
    //         ? Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds) : Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds);
    const editClosed = !activity
        ? true
        : Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds + EDIT_CLOSE);

    const instructors = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity);
    const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
    const isRunningActivity = loggedInUser ? peopleRunningActivity.some(x => x.id === loggedInUser.id) : false;

    const attendees = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && a.attendAllInstances);
    const attendeesForNotification = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity);
    const peopleSignedUpforActivity = useSelector(state => state.company.people).filter(p => attendeesForNotification.find(a => a.appUserId === p.id)).sort(compareObjects('displayName', 'asc'));

    const whenCanRegister = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterStartValue, activity.selfRegisterStartUnit));

    const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
    // const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInNonExpandedActivity(haveAttendeeObject, activity) : false;
    const currentUserInClass = activity.isInNonExpandedActivity(haveAttendeeObject, activity);
    const aFamilySignedUp = stupidFunction(activity, loggedInUser);

    const isRecurring = activity && activity.rrule !== null && activity.rrule.trim().length > 0 ? true : false;
    const endDateText = useMemo(() => {
        return activity.getEndDateAsText(isRecurring, activity.rrule, activity.startDateTime, 'MMM D');
    }, [activity, isRecurring]);

    const endDate = useMemo(() => {
        return activity.getEndDate(isRecurring, activity.rrule, activity.startDateTime);
    }, [activity, isRecurring]);

    const isInPast = endDate < Date.now() ? true : false;

    const expandedActivities = useMemo(() => {
        return isRecurring ? expandActivity(activity, viewStartDate, endDate, loggedInUser, userClaims, false) : [];
    }, [activity, viewStartDate, endDate, loggedInUser, userClaims, isRecurring]);
    const futureExpandedActiviies = expandedActivities;
    const whenRegistrationCloses = !activity ? null : new Date(endDate.getTime() + durationInMilliSeconds);

    const [error, setError] = useState(null);

    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
    const [editAllInstances, setEditAllInstances] = useState(false);
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [isCreateCopy, setIsCreateCopy] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [showCustomPurchaseModal, setShowCustomPurchaseModal] = useState(false)
    const childProfiles = loggedInUser && loggedInUser.childProfiles ? loggedInUser.childProfiles.sort(compareObjects('displayName', 'asc')) : [];
    const usersToList = company.isRABSite ? [...childProfiles] : [...[loggedInUser], ...childProfiles];
    const usersToPrepay = loggedInUser ? usersToList.filter(y => !activity.isInNonExpandedActivity(activity.people.find(x => x.appUserId === y.id), activity)).map(x => x.id) : [];
    const activityIsFull = (activity.totalSpaces - attendees.length) <= 0 ? true : false;
    // const childActivities = useMemo(() => {
    //     return expandedActivities ? expandedActivities : expandActivity(activity, viewStartDate, endDate, loggedInUser, userClaims, false);
    // }, [activity, viewStartDate, endDate, loggedInUser, userClaims, expandedActivities]);
    const childActivities = expandActivity(activity, viewStartDate, endDate, loggedInUser, userClaims, false);
    const dispatch = useDispatch();

    const handleJoin = () => {
        setShowCustomPurchaseModal(true)
    }

    const handleEnrollSuccess = useCallback(async () => {
        setError(null);
        try {
            await dispatch(fetchActivities(company.id, viewStartDate, viewEndDate ? viewEndDate : endDate));
        } catch (err) {
            setError(err.message);
        }
    }, [dispatch, company.id, viewStartDate, viewEndDate, endDate]);





    return (
        <React.Fragment>
            {activity && !editMode &&
                <StyledDialog
                    open={open}
                    setOpen={setOpen}
                    aria-labelledby="act-dialog-title"
                    actions={(allowAddEdit || isRunningActivity || (activity.requestConfirmation && !isInPast && (!(activity.confirmed || activity.rejected) || allowAddEdit))) &&
                        <React.Fragment>
                            <UtilityButtonWrapper>
                                {!activityCanceled && !isInPast && activity.meetingUrl &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<VideoIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        component='a'
                                        href={activity.meetingUrl}
                                        target='_blank'
                                    >
                                        Start Video
                                    </UtilityButton>
                                }
                                {!activityCanceled && allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        onClick={() => { setEditAllInstances(isRecurring); setIsCreateCopy(false); setIsEditingModalOpen(true); }}>
                                        Edit
                                    </UtilityButton>
                                }
                                {/* {!activityCanceled && !occuranceIsInPast && isRecurring && allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        onClick={() => { setEditAllInstances(false); setIsCreateCopy(false); setIsEditingModalOpen(true) }}>
                                        Edit this {ACTIVITY_TEXT}
                                    </UtilityButton>
                                } */}
                                {allowAddEdit && <UtilityButton
                                    variant="text"
                                    startIcon={<ChatIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                    onClick={() => setMessageModalOpen(true)}>
                                    Message
                                </UtilityButton>}
                                <UtilityButton
                                    variant="text"
                                    startIcon={activityCanceled
                                        ? <RestoreIcon
                                            size={ICON_SIZE}
                                            style={{ color: theme.palette.success.main }} />
                                        :
                                        <CancelMenuIcon
                                            size={ICON_SIZE}
                                            style={{ color: theme.palette.error.main }}

                                        />}
                                    onClick={() => setShowCancelDialog(true)}
                                    >
                                    {activityCanceled ? 'Reschedule' : 'Cancel'}
                                </UtilityButton>
                                <UtilityButton
                                    variant="text"
                                    startIcon={<CopyIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                    onClick={() => { setEditAllInstances(false); setIsCreateCopy(true); setIsEditingModalOpen(true); }}>
                                    Copy
                                </UtilityButton>
                                <UtilityButton
                                    variant="text"
                                    startIcon={
                                        <DeleteIcon
                                            size={ICON_SIZE}
                                            style={{ color: theme.palette.error.main }}
                                        />}
                                    onClick={() => setShowDeleteDialog(true)}
                                    >
                                    Delete
                                </UtilityButton>
                            </UtilityButtonWrapper>
                        </React.Fragment>
                    }
                    // moreActions={
                    //     !occuranceIsInPast && allowAddEdit &&
                    //     <React.Fragment>

                    //     </React.Fragment>
                    // }
                    primaryAction={
                        <React.Fragment>
                            {!loggedInUser && activity.allowSelfRegister &&
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setLoginDialogOpen(true)}
                                // component={RouterLink} to={baseURL + '/' + NavigationContants.LOGIN}
                                >
                                    Login / Register to Sign-Up for {ACTIVITY_TEXT}
                                </Button>
                            }
                            {loggedInUser &&
                                <React.Fragment>
                                    {activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }}
                                            startIcon={<CancelIcon size={ICON_SIZE} />}
                                        >
                                            Canceled
                                        </Button>
                                    }
                                    {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && activity.allowSelfRegister && !isInPast && whenCanRegister > Date.now() &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<ClockIcon size={ICON_SIZE} />}
                                        >
                                            Registration opens {moment(whenCanRegister).format('MM/DD - LT')}
                                        </Button>
                                    }
                                    {/* {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && !activity.canSelfRegister(activity, loggedInUser, attendees) && !currentUserInClass && activity.allowSelfRegister && Date.now() >= whenCanRegister && !isInPast && (!whenRegistrationCloses || whenRegistrationCloses > Date.now()) &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText }}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                        >
                                            Registration open
                                            <Typography variant="subtitle1" ml={2}>
                                                {moment(activity.displaybleStartDateTime).format("MMM D")}
                                                {endDateText ? ' - ' + endDateText : ''}
                                            </Typography>
                                        </Button>
                                    } */}
                                    {!activityCanceled && loggedInUser
                                        && !activityIsFull
                                        && whenRegistrationCloses >= Date.now()
                                        // && lastActivity.canSelfRegister(lastActivity, loggedInUser, attendees) 
                                        && !currentUserInClass && !aFamilySignedUp && !isRunningActivity &&
                                        <ActionButton
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                            onClick={handleJoin}
                                        >
                                            Register Now!
                                            <Typography variant="subtitle1" ml={2}>
                                                {moment(activity.displaybleStartDateTime).format("MMM D")}
                                                {endDateText ? ' - ' + endDateText : ''}
                                            </Typography>
                                        </ActionButton>
                                    }
                                    {!activityCanceled && activity.allowSelfRegister && !isInPast && whenRegistrationCloses != null && whenRegistrationCloses < Date.now() &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<ClockIcon size={ICON_SIZE} />}
                                        >
                                            Registration Closed
                                        </Button>
                                    }
                                    {(!activityCanceled && (currentUserInClass || aFamilySignedUp) && !isRunningActivity && !isInPast) &&
                                        <ActionButton
                                            fullWidth
                                            // disabled={(loggedInUser && loggedInUser.childProfiles.length > 0) ? false : true}
                                            variant="contained"
                                            style={{ backgroundColor: activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main, color: theme.palette.success.contrastText }}
                                            onClick={handleJoin}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                        >
                                            {loggedInUser && loggedInUser.childProfiles.length > 0 ? 'You or a family member are signed up. Click to make changes.' : 'You\'re signed up.'}
                                        </ActionButton>
                                    }
                                    {/* {(activity.totalSpaces - attendees.length) <= 0 && (isRunningActivity || allowAddEdit) && !isInPast && !activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FullIcon size={ICON_SIZE} />}
                                        >
                                            {attendees.length} {attendees.length === 1 ? CUSTOMER_TEXT : CUSTOMERS_TEXT} in {ACTIVITY_TEXT}
                                        </Button>
                                    } */}
                                    {/* {(activity.totalSpaces - attendees.length) <= 0 && activity.allowSelfRegister && !isRunningActivity && !currentUserInClass && !isInPast && !activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FullIcon size={ICON_SIZE} />}
                                        >
                                            
                                        </Button>
                                    } */}
                                    {/* {!activityCanceled && isInPast &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FinishedIcon size={ICON_SIZE} />}
                                        >
                                            {ACTIVITY_TEXT} has finished
                                        </Button>
                                    } */}
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                >
                    {activity && !editMode &&
                        <React.Fragment>
                            {activity && !activityIsFull &&
                                <Chip
                                    // color="secondary"
                                    size="small"
                                    mb={2}
                                    style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}
                                    label={'Hurry, ' + (activity.totalSpaces - attendees.length) + ' spot' + (activity.totalSpaces - attendees.length === 1 ? '' : 's') + ' remaining'} />
                            }
                            {activity && activityIsFull &&
                                <Chip
                                    // color="secondary"
                                    size="small"
                                    mb={2}
                                    style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}
                                    label={ACTIVITY_TEXT + " is full"} />
                            }

                            {/* <Typography variant="h5" ml={2}>{activity.totalSpaces - attendees.length} spot{activity.totalSpaces - attendees.length === 1 ? '' : 's'} remaining</Typography> */}
                            <NonExpandedActivityDetail
                                activity={activity}
                                viewStartDate={viewStartDate}
                                viewEndDate={viewEndDate}
                                editClosed={editClosed}
                                expandedActivities={futureExpandedActiviies}
                            />
                            {loggedInUser &&
                                <FileUploader activity={activity}
                                    viewStartDate={viewStartDate}
                                    viewEndDate={viewEndDate}
                                    hideUploadButton={!allowAddEdit}
                                    title={ACTIVITY_TEXT + " Files"}
                                />
                            }
                        </React.Fragment>
                    }
                </StyledDialog >
            }
            {loginDialogOpen &&
                <LoginDialog
                    open={loginDialogOpen}
                    setOpen={setLoginDialogOpen} />
            }
            {messageModalOpen &&
                <MessageModal open={messageModalOpen}
                    setOpen={setMessageModalOpen}
                    sendInAppInitialState
                    toUsers={peopleSignedUpforActivity} />
            }
            {isEditingModalOpen &&
                <ActivityAddEditModal
                    open={isEditingModalOpen}
                    setOpen={setIsEditingModalOpen}
                    activity={activity}
                    companyId={companyId}
                    editAllInstances={editAllInstances}
                    viewStartDate={viewStartDate}
                    viewEndDate={viewEndDate}
                    createCopy={isCreateCopy}
                />
            }
            {showCustomPurchaseModal &&
                <StripeWrapper showTenantPaymentMethods open={showCustomPurchaseModal}>
                    <CustomPurchaseModal
                        open={showCustomPurchaseModal}
                        setOpen={setShowCustomPurchaseModal}
                        displayedUser={loggedInUser}
                        itemTitle={activity.title + " - " + moment(activity.displaybleStartDateTime).format('lll')}
                        itemPrice={activity.getProratePrice(isRecurring)}
                        activity={activity}
                        activityId={activity.id}
                        usersToPrepay={usersToPrepay}
                        applyTwoOrMoreMemberDiscount={true}
                        // usersToPrepay={userToJoin}
                        onSuccess={handleEnrollSuccess}
                    />
                </StripeWrapper>
            }
            {error &&
                <ErrorDialog message={error} setMessage={setError} />
            }

            {showCancelDialog &&
                <StyledDialog
                    open={showCancelDialog}
                    setOpen={setShowCancelDialog}
                    aria-labelledby="act-dialog-title"
                >
                    <Typography variant="h3">Manage Drop-ins</Typography>
                    <Box ml={2} mt={4}>
                        <CancelClassAction
                            ACTIVITY_TEXT={ACTIVITY_TEXT}
                            dispatch={dispatch}
                            companyId={companyId}
                            activity={activity}
                            title={activityCanceled ? "Reschedule Session" : "Cancel Session"}
                            hideSpotsRemaining
                            viewStartDate={viewStartDate}
                            viewEndDate={viewEndDate}
                        />
                    </Box>
                    <Box ml={2} mt={4}>
                        {childActivities.map((activityChild) => {
                            return (
                                <CancelClassAction
                                    key={activityChild.id}
                                    ACTIVITY_TEXT={ACTIVITY_TEXT}
                                    dispatch={dispatch}
                                    companyId={companyId}
                                    activity={activityChild}
                                    viewStartDate={viewStartDate}
                                    viewEndDate={viewEndDate}
                                />
                            )
                        })
                        }
                    </Box>
                </StyledDialog>
            }
            {showDeleteDialog &&
                <StyledDialog
                    open={showDeleteDialog}
                    setOpen={setShowDeleteDialog}
                    aria-labelledby="act-dialog-title"
                >
                    <Typography variant="h3">Delete Session</Typography>
                    <Box ml={2} mt={4}>
                        <DeleteClassAction
                            ACTIVITY_TEXT={ACTIVITY_TEXT}
                            dispatch={dispatch}
                            companyId={companyId}
                            activity={activity}
                            title="Delete Session"
                            hideSpotsRemaining
                            viewStartDate={viewStartDate}
                            viewEndDate={viewEndDate}
                        />
                    </Box>
                    <Box ml={2} mt={4}>
                        {childActivities.map((activityChild) => {
                            return (
                                <DeleteClassAction
                                    key={activityChild.id}
                                    ACTIVITY_TEXT={ACTIVITY_TEXT}
                                    dispatch={dispatch}
                                    companyId={companyId}
                                    activity={activityChild}
                                    viewStartDate={viewStartDate}
                                    viewEndDate={viewEndDate}
                                />
                            )
                        })
                        }
                    </Box>
                </StyledDialog>
            }
        </React.Fragment >
    );

}

export default NonExpandedActivityDetailModal;