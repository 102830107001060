import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import { Formik } from "formik";
import { Link as RouterLink } from 'react-router-dom';

import { useSlate, useReadOnly } from "slate-react";
import { Editor, Transforms, Element as SlateElement, Range } from "slate";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Popover as MuiPopover,
    Link,
    Switch,
    FormGroup,
    FormControlLabel as MuiFormControlLabel,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";

// svxvy imports
import { isMarkActive } from "./slateHelpers";
import { isSvxvySite } from '../../../../constants/NetworkConstants';
import {cleanURL} from '../../../../helpers/navigationFunctions';

// styled
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
    margin: 0;

    span {
        margin: 0;
    }

    .MuiSwitch-root {
        margin-left: -12px;
    }
`;



const Popover = styled(MuiPopover)`
.MuiPopover-paper{
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 15px;
}
`;

const linkValidationSchema = Yup.object().shape({
    url: Yup.string()
        .required("Required"),
});

const LinkButton = ({ format, children }) => {
    const editor = useSlate();
    const company = useSelector(state => state.company.company);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { selection } = editor
    const [isDisabled, setIsDisabled] = useState(selection && selection.anchor.offset === selection.focus.offset)

    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })

    const handleSubmit = (values, { setSubmitting }) => {
        insertLink(editor, cleanURL(company.sanitizedName, values.url), values.openInNewWindow, children)
        setAnchorEl(null);
    };

    useEffect(() => {
        if (selection) {
            setIsDisabled(Range.isCollapsed(selection))
        }
    }, [selection])


    return (
        <React.Fragment>
            <Box ml={1} mt={1}>
                <ToggleButton
                    value={format}
                    disabled={isDisabled}
                    selected={isMarkActive(editor, format)}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    style={{ lineHeight: 1 }}
                >
                    {children}
                </ToggleButton>
            </Box>
            <Popover
                id='set-url-popover'
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={4}>
                    <Formik
                        initialValues={{
                            url: link && link[0] && link[0].url ? link[0].url : '',
                            openInNewWindow: link && link[0] ? link[0].openInNewWindow : false,
                        }}
                        validationSchema={linkValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            values,
                            status,
                        }) => (
                            <Box>
                                <Typography>Configure URL:</Typography>
                                <TextField
                                    name="url"
                                    label="Link"
                                    value={values.url}
                                    error={Boolean(touched.url && errors.url)}
                                    helperText={touched.url && errors.url}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    mb={2}
                                    placeholder="" />
                                <FormGroup style={{ margin: 0 }}>
                                    <FormControlLabel control={
                                        <Switch name='openInNewWindow' onChange={(val) => setFieldValue('openInNewWindow', !values.openInNewWindow)} checked={values.openInNewWindow} />
                                    } label={'Open in new window'} />
                                </FormGroup>
                                <Button
                                    mt={3}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Set Link
                                </Button>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

const insertLink = (editor, url, openInNewWindow, children) => {
    if (editor.selection) {
        wrapLink(editor, url, openInNewWindow, children)
    }
}

export const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

export const wrapLink = (editor, url, openInNewWindow, children) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && selection.anchor.offset === selection.focus.offset

    const link = {
        type: 'link',
        url,
        openInNewWindow,
        children: children,
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{ fontSize: 0 }}
    >
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
)

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
}

export const LinkRemoveButton = ({ format, children }) => {
    const editor = useSlate();
    return (
        <Box ml={1} mt={1}>
            <ToggleButton
                value={format}
                onMouseDown={event => {
                    event.preventDefault();
                    unwrapLink(editor)
                }}
                style={{ lineHeight: 1 }}
            >
                {children}
            </ToggleButton>
        </Box>
    );
};

export const LinkComponent = ({ attributes, children, element }) => {
    const company = useSelector(state => state.company.company);
    const baseURL = isSvxvySite() ? "/" + company.sanitizedName : "";
    const readOnly = useReadOnly();

    return (
        <React.Fragment>
            {((element.url && element.url.toLowerCase().startsWith('http')) || !readOnly) &&
                <Link
                    style={{cursor: 'pointer'}}
                    {...attributes}
                    href={element.url}
                    target={element.openInNewWindow ? '_blank' : '_self'}
                    referrerPolicy='same-origin'
                >
                    {/* <InlineChromiumBugfix /> */}
                    {children}
                    {/* <InlineChromiumBugfix /> */}
                </Link>
            }
            {element.url && !element.url.toLowerCase().startsWith('http') && readOnly &&
                <Link
                    {...attributes}
                    style={{cursor: 'pointer'}}
                    component={RouterLink}
                    to={baseURL + (element.url.startsWith('/') ? element.url : "/" + element.url)}
                    target={element.openInNewWindow ? '_blank' : '_self'}
                    referrerPolicy='same-origin'
                >
                    <InlineChromiumBugfix />
                    {children}
                    <InlineChromiumBugfix />
                </Link>
            }
        </React.Fragment>
    )
}



export default LinkButton;


