import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from "formik"
import * as Yup from "yup";
import { useTheme } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Avatar,
    Box,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    FormGroup as MuiFormGroup,
    FormControlLabel as MuiFormControlLabel,
    LinearProgress as MuiLinearProgress,
    TextField as MuiTextField,
    Typography as MuiTypography,
    Switch
} from "@material-ui/core";
import InputMask from "react-input-mask";

import { Alert as MuiAlert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { addPerson, detailPerson, editPerson } from '../../../store/actions/peopleActions';
import { DEFAULT_USER_IMAGE } from '../../../models/Person';
import { checkClaim, GUID_Generator } from '../../../helpers/helperFunctions';
import { addToArray, deleteById } from '../../../helpers/arrayFunctions';
import Auxcillary from '../../framework/Auxcillary';
import ActionButton from '../../framework/ActionButton';
import StyledDialog from '../../framework/StyledDialog';
import UploadPhoto from '../photos/UploadPhoto';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const FormGroup = styled(MuiFormGroup)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const BigAvatar = styled(Avatar)`
  width: 300px;
  height: 300px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

const SCALED_HEIGHT = 300;
const SCALED_IMAGE = 'h_' + SCALED_HEIGHT + ',c_limit';

const ProfileEditCard = (props) => {
    const theme = useTheme();
    const { person, open, setOpen, parentAccountId } = props;

    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowManageRoles = currentUser && checkClaim(currentUserClaims, SecurityConstants.MANAGAE_ROLES);
    const viewExtendedProfile = currentUser && checkClaim(currentUserClaims, SecurityConstants.VIEW_EXTENDED_PROFILE);

    const rolesInStore = useSelector(state => state.company.roles);
    const roles = rolesInStore ? rolesInStore : [];

    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const EMPLOYEE_TEXT = useSelector(state => state.company.employeeText);
    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const company = useSelector(state => state.company.company);
    const companyId = useSelector(state => state.company.companyId);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const personToEdit = person && person.id === currentUser.id ? currentUser : person;
    const personToEditId = person && person.id === currentUser.id ? currentUser.id : person ? person.id : null;
    const personToEditParentAccountId = person && person.id === currentUser.id ? currentUser.parentAccountId : person ? person.parentAccountId : null;

    const [photo, setPhoto] = useState(null);
    const [removePhoto, setRemovePhoto] = useState(false);

    const initialValues = {
        id: personToEdit ? personToEdit.id : GUID_Generator(),
        parentAccountId: personToEdit && personToEdit.parentAccountId ? personToEdit.parentAccountId : parentAccountId ? parentAccountId : null,
        displayName: personToEdit ? personToEdit.displayName : "",
        bio: personToEdit && personToEdit.bio ? personToEdit.bio : "",
        roles: personToEdit && personToEdit.roles ? personToEdit.roles : roles.filter(x => x.isRoleForNewUsers),
        email: personToEdit && personToEdit.email ? personToEdit.email : "",
        phoneNumber: personToEdit && personToEdit.phoneNumber ? personToEdit.phoneNumber : "",
        isPublic: personToEdit ? personToEdit.isPublic : false,
        isEmployee: personToEdit ? personToEdit.isEmployee : false,
        notifyOnNewAccount: personToEdit ? personToEdit.notifyOnNewAccount : false,
        photo: null,
        photoPreview: null,
        address: personToEdit ? personToEdit.address : '',
        birthday: personToEdit && personToEdit.birthday ? personToEdit.birthday : null,
        memberSince: personToEdit && personToEdit.memberSince ? personToEdit.memberSince : null,
        barcodeId: personToEdit ? personToEdit.barcodeId : '',
        employeeSortOrder: personToEdit ? personToEdit.employeeSortOrder : '0',
        customerNotes: personToEdit ? personToEdit.customerNotes : '',
        employeeNotes: personToEdit ? personToEdit.employeeNotes : '',
        dontSendOptionalMessages: personToEdit ? !personToEdit.dontSendOptionalMessages : true,
        referredBy: personToEdit ? personToEdit.referredBy : "",
        additionalEmailNotificaitons: personToEdit ? personToEdit.additionalEmailNotificaitons : "",

    };

    const validationSchema = Yup.object().shape({
        displayName: Yup.string()
            .required("Required")
            .min(3, 'Must be at least 3 characters.'),
        email: initialValues.parenetId != null && !allowManageRoles ? Yup.string()
            .required("Required")
            .email("Not a valid email.") : Yup.string(),
        phoneNumber: initialValues.parenetId != null && !allowManageRoles ? Yup.string()
            .required("Required") : Yup.string(),
        employeeSortOrder: Yup.number()
            .typeError('Must be a number')
            .integer("Must be a whole number.")
            .required("Required"),
        birthday: company.isRABSite ? Yup.date()
            .typeError('Must be a Date')
            .required("Required") : null
    });

    /***************************** profile updates ************************************/
    const dispatch = useDispatch();

    const loadFullProfile = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            if (currentUser && personToEditId && currentUser.id !== personToEditId && currentUser.id !== personToEditParentAccountId)
                await dispatch(detailPerson(personToEditId, companyId, true));
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
        setIsLoading(false);
    }, [dispatch, currentUser, companyId, personToEditId, personToEditParentAccountId]);

    const submitCallback = useCallback(async (companyId, isEditing, profileId, formData, handleOnSuccess) => {
        setError(null);
        setIsSubmitting(true);
        try {
            if (isEditing)
                await dispatch(editPerson(profileId, formData, companyId));
            else
                await dispatch(addPerson(formData, companyId));
            setIsSubmitting(false);
            if (handleOnSuccess)
                handleOnSuccess();
            if (setOpen)
                setOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch, setOpen]);

    const handleSubmit = (values) => {
        const isEditing = person ? true : false;

        const valuesToSend = cloneDeep(values);
        valuesToSend.photoObject = photo ? photo : null;
        valuesToSend.employeeSortOrder = parseInt(values.employeeSortOrder);
        valuesToSend.removePhoto = removePhoto;
        submitCallback(companyId, isEditing, values.id, valuesToSend, props.onSuccess);
    };

    const handleRoleChange = (role, event, roles, setFieldValue) => {
        let updateRoles = [];

        if (event.target.checked)
            updateRoles = addToArray(role, roles);
        else
            updateRoles = deleteById(role.id, roles);
        setFieldValue('roles', updateRoles);
    }

    /***************************** page effects *****************************/
    useEffect(() => {
        if (open) {
            loadFullProfile();
        }
    }, [loadFullProfile, open]);

    function onKeyDown(keyEvent) {
        // Cuased text areas to stop working but blocked submit.
        // if (keyEvent.code === 'Enter' || keyEvent.code === 'NumpadEnter') {
        //     keyEvent.preventDefault();
        // }
    }

    const DisplayComponent = setOpen ? StyledDialog : Auxcillary

    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <DisplayComponent
                    open={open}
                    setOpen={setOpen}
                    primaryAction={
                        <React.Fragment>
                            <ActionButton
                                type="submit"
                                form='profileEdit'
                                variant="contained"
                                color="primary"
                                submitting={isSubmitting}
                                fullWidth
                            >
                                Save changes
                            </ActionButton>
                        </React.Fragment>
                    }
                >
                    {isLoading &&
                        <Box display="flex" flexDirection="column" flex={1} justifyContent="center" minHeight="60vh">
                            <LinearProgress my={2} color="primary" />
                            <Typography variant={"h2"} align="center" gutterBottom>Loading details for edit...</Typography>
                            <LinearProgress my={2} color="secondary" />
                        </Box>
                    }
                    {!isLoading &&
                        <Card p={4} style={{ overflowY: 'auto', borderRadius: '10px', border: '1px solid' + theme.palette.divider }}>
                            {error && <Alert my={4} severity="error">
                                {error}
                            </Alert>}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    status,
                                    setFieldValue,
                                }) => (
                                    <form id='profileEdit' onKeyDown={onKeyDown} onSubmit={handleSubmit}>
                                        <Box display="flex" flex={1} justifyContent="center" flexDirection="column" mb={4}>
                                            <BigAvatar
                                                alt={person ? person.displayName + " bio picture" : 'default profile image'}
                                                src={photo ? photo.url
                                                    : person && !removePhoto ? person.image.replace('/upload', '/upload/' + SCALED_IMAGE) : DEFAULT_USER_IMAGE}
                                            />
                                            <Box display="flex" flex={1} justifyContent="center">
                                                <UploadPhoto
                                                    image={photo ? photo : person ? person.image : null}
                                                    hideImage
                                                    rowButtons
                                                    buttonText={(person && person.image && person.image !== DEFAULT_USER_IMAGE ? 'Change ' : 'Add ') + 'Profile Image'}
                                                    onSuccess={(image) => {
                                                        setRemovePhoto(false)
                                                        setPhoto(image)
                                                    }}
                                                    removePhoto={() => {
                                                        setRemovePhoto(true)
                                                        setPhoto(null)
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <TextField
                                            name="displayName"
                                            label="Display name - visible in the app"
                                            value={values.displayName}
                                            error={Boolean(touched.displayName && errors.displayName)}
                                            helperText={touched.displayName && errors.displayName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="" />
                                        <TextField
                                            name="email"
                                            label="E-mail"
                                            value={values.email}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="" />
                                        <InputMask
                                            name="phoneNumber"
                                            mask="(999) 999 - 9999"
                                            value={values.phoneNumber}
                                            onChange={e => { setFieldValue('phoneNumber', e.target.value, true) }}
                                            disabled={false}
                                            maskChar=" "
                                        >
                                            {() => <TextField
                                                label="Mobile Number"
                                                name="phoneNumber"
                                                value={values.phoneNumber}
                                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                helperText={touched.phoneNumber && errors.phoneNumber}
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />}
                                        </InputMask>
                                        <TextField
                                            name="address"
                                            label="Mailing Address"
                                            value={values.address}
                                            error={Boolean(touched.address && errors.address)}
                                            helperText={touched.address && errors.address}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="" />
                                        {/* <TextField
                                            name="barcodeId"
                                            label={CUSTOMER_TEXT + " ID"}
                                            value={values.barcodeId}
                                            error={Boolean(touched.barcodeId && errors.barcodeId)}
                                            helperText={touched.barcodeId && errors.barcodeId}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="" /> */}
                                        <KeyboardDatePicker inputVariant="outlined"
                                            name="birthday"
                                            margin="normal"
                                            label="Birthday (mm/dd/yy)"
                                            value={values.birthday}
                                            error={Boolean(touched.birthday && errors.birthday)}
                                            helperText={touched.birthday && errors.birthday}
                                            onChange={date => setFieldValue('birthday', date)}
                                            fullWidth
                                            emptyLabel=""
                                            format="MM/dd/yy"
                                        />
                                        {viewExtendedProfile &&
                                            <KeyboardDatePicker inputVariant="outlined"
                                                name="memberSince"
                                                margin="normal"
                                                label="Member Since (mm/dd/yy)"
                                                value={values.memberSince}
                                                onChange={date => setFieldValue('memberSince', date)}
                                                fullWidth
                                                emptyLabel=""
                                                format="MM/dd/yy"
                                            />
                                        }
                                        <TextField
                                            name="customerNotes"
                                            label={"About me"}
                                            value={values.customerNotes}
                                            error={Boolean(touched.customerNotes && errors.customerNotes)}
                                            helperText={touched.customerNotes && errors.customerNotes}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline={true}
                                            variant="outlined"
                                            placeholder=""
                                            maxRows={10}
                                        />
                                        <TextField
                                            name="referredBy"
                                            label={"Referred By"}
                                            value={values.referredBy}
                                            error={Boolean(touched.referredBy && errors.referredBy)}
                                            helperText={touched.referredBy && errors.referredBy}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            placeholder=""
                                        />
                                        {/* <TextField
                                            name="additionalEmailNotificaitons"
                                            label={"Additional Reminder Emails"}
                                            value={values.additionalEmailNotificaitons}
                                            error={Boolean(touched.additionalEmailNotificaitons && errors.additionalEmailNotificaitons)}
                                            helperText={touched.additionalEmailNotificaitons && errors.additionalEmailNotificaitons}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline={true}
                                            variant="outlined"
                                            placeholder=""
                                            maxRows={10}
                                        /> */}
                                        {viewExtendedProfile &&
                                            <TextField
                                                name="employeeNotes"
                                                label={"Private notes (not visible to " + CUSTOMER_TEXT.toLowerCase() + ")"}
                                                value={values.employeeNotes}
                                                error={Boolean(touched.employeeNotes && errors.employeeNotes)}
                                                helperText={touched.employeeNotes && errors.employeeNotes}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline={true}
                                                variant="outlined"
                                                placeholder=""
                                                maxRows={10}
                                            />
                                        }
                                        <Box my={2} ml={2}>
                                            <FormControlLabel control={
                                                <Field name='dontSendOptionalMessages' component={Switch} onChange={(val) => setFieldValue('dontSendOptionalMessages', !values.dontSendOptionalMessages)} checked={values.dontSendOptionalMessages} />
                                            } label={'Send reminder emails'} />
                                        </Box>
                                        {allowManageRoles && <React.Fragment>
                                            <Divider style={{ marginBottom: '20px' }} />
                                            <Typography variant="h4" my={2}>Set attributes of this user:</Typography>
                                            <FormGroup ml={4}>
                                                <FormControlLabel mb={2} control={
                                                    <Field name='isEmployee' component={Switch} onChange={(val) => setFieldValue('isEmployee', !values.isEmployee)} checked={values.isEmployee} />
                                                } label={'Profile is for ' + EMPLOYEE_TEXT + ' - this will allow you to choose this person to run a ' + ACTIVITY_TEXT.toLowerCase() + '.'} />
                                                {values.isEmployee && <FormControlLabel control={
                                                    <Field name='isPublic' component={Switch} onChange={(val) => setFieldValue('isPublic', !values.isPublic)} checked={values.isPublic} />
                                                } label='Profile is public - will show this account on your public website.' />}
                                                <React.Fragment>
                                                    <TextField
                                                        name="bio"
                                                        label={EMPLOYEE_TEXT + " bio app & website"}
                                                        value={values.bio}
                                                        error={Boolean(touched.bio && errors.bio)}
                                                        helperText={touched.bio && errors.bio}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        multiline={true}
                                                        variant="outlined"
                                                        mt={2}
                                                        placeholder=""
                                                        maxRows={25}
                                                    />
                                                    <TextField
                                                        name="employeeSortOrder"
                                                        label={EMPLOYEE_TEXT + " display order"}
                                                        value={values.employeeSortOrder}
                                                        error={Boolean(touched.employeeSortOrder && errors.employeeSortOrder)}
                                                        helperText={touched.employeeSortOrder && errors.employeeSortOrder}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        multiline={true}
                                                        variant="outlined"
                                                        mt={2}
                                                        placeholder=""
                                                        maxRows={25}
                                                    />
                                                </React.Fragment>
                                                {/* <FormControlLabel mt={values.isEmployee ? 2 : 0} control={
                                                    <Field name='notifyOnNewAccount' component={Switch} onChange={(val) => setFieldValue('notifyOnNewAccount', !values.notifyOnNewAccount)} checked={values.notifyOnNewAccount} />
                                                } label={'Notify this user when a new account is created.'} /> */}
                                            </FormGroup>
                                        </React.Fragment>}

                                        {allowManageRoles && roles && roles.length > 0 && <React.Fragment>
                                            <Divider style={{ margin: '15px 0 20px 0' }} />
                                            <Typography variant="h4" my={2}>Set permissions for this user:</Typography>
                                            <FormGroup ml={4}>
                                                {roles.map((role, index) => {
                                                    const checked = values.roles ? values.roles.find(x => x.id === role.id) != null : role.isRoleForNewUsers;
                                                    return (
                                                        <FormControlLabel key={role.id} mb={2} control={
                                                            <Field component={Switch} onChange={(val) => handleRoleChange(role, val, values.roles, setFieldValue)} checked={checked} />
                                                        } label={role.name + (role.description ? " - " + role.description : '')} />
                                                    )
                                                })}
                                            </FormGroup>
                                        </React.Fragment>
                                        }

                                    </form>
                                )}
                            </Formik>
                            {!setOpen &&
                                <React.Fragment>
                                    {error && <Alert my={4} severity="error">
                                        {error}
                                    </Alert>}
                                    <ActionButton
                                        type="submit"
                                        form='profileEdit'
                                        variant="contained"
                                        color="primary"
                                        submitting={isSubmitting}
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        Save changes
                                    </ActionButton>
                                </React.Fragment>
                            }
                            {error && <Alert my={4} severity="error">
                                {error}
                            </Alert>}
                        </Card>
                    }
                </DisplayComponent>
            </Grid>
        </Grid>
    );
}

export default ProfileEditCard;