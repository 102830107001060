import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// UI Imports

import { withTheme } from "styled-components/macro";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoAddCircle as AddIcon
} from "react-icons/io5";



// svxvy imports
import { compareObjects } from '../../../helpers/helperFunctions';

import ManageProductsList from '../../../components/company/products/ManageProductsList';
import ManageProductModal from "../../../components/company/products/ManageProductModal";

// UI Consts 
const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

const HeadingWrapper = styled(Box)`
  margin-bottom: 30px;
  padding-top: 12px;
  display: block;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 19px;
    padding-top: 49px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 12px;
  }
`;

const Heading = styled(Typography)`
  margin-bottom: 25px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 0;
  }
`;

const ManageProducts = (props) => {
  const company = useSelector(state => state.company.company);
  const loggedInUser = useSelector(state => state.auth.person);
  const [isProductManageModalOpen, setIsProductManageModalOpen] = useState(false)

  const products = useSelector(state => state.products.products).filter(x =>(!x.isRecurring && x.units === 0) ).sort(compareObjects('name', 'asc'));
  const inactiveProducts = useSelector(state => state.products.inactiveProducts).filter(x =>(!x.isRecurring && x.units === 0) ).sort(compareObjects('name', 'asc'));


  return (
    <Grid container>
      <Grid item xs={false} sm={1}></Grid>
      <Grid item xs={12} sm={10}>
        <Helmet title="Manage Settings" />

        <HeadingWrapper display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Heading variant='h1' component="h1">Products</Heading>
          {company && company.paymentServicePublicKey &&
            <Button
              variant='contained'
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsProductManageModalOpen(true)}
            >Add New</Button>
          }
        </HeadingWrapper>
        {company && !company.paymentServicePublicKey &&
          <Alert severity="warning">You must integrate with Stripe under 'Manage' then select 'Payment Settings' to setup Products</Alert>
        }
        {company && company.paymentServicePublicKey &&
          <Box>
            <ManageProductsList
              products={products}
              inactiveProducts={inactiveProducts}
              company={company}
              displayedUser={loggedInUser} />
          </Box>
        }
        {!company &&
          <Grid container spacing={6}>
            <Grid item xs={12} lg={8}>
              <p>Hmm.... we couldn't find this company do you want to register the company with svxvy?</p>
            </Grid>
          </Grid>
        }
        {isProductManageModalOpen &&
          <ManageProductModal open={isProductManageModalOpen} setOpen={setIsProductManageModalOpen} manageProducts />
        }
      </Grid>
    </Grid>
  );
}

export default withTheme(ManageProducts);
