import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


import styled from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';


import {
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { fetchMessages } from '../../store/actions/messageActions';
import MessageList from "../../components/company/messages/MessageList";

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  updatingTitle: {
    textAlign: "center"
  },
}));


function Messages() {
  const classes = useStyles();
  const company = useSelector(state => state.company.company);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  /********************* load data ************************ */
  const loadMessages = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchMessages(company.id, false));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  }, [company, dispatch]);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  return (
    <React.Fragment>
      <Helmet title="Messages" />
      {error
        && <Grid container>
          <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>
        </Grid>
      }
      {isLoading
        && <Grid container>
          <Grid item xs={12}>
            <LinearProgress my={2} color="primary" />
            <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading messages...</Typography>
            <LinearProgress my={2} color="secondary" />
          </Grid>
        </Grid>
      }
      <MessageList />
    </React.Fragment>
  );
}

export default Messages;
