import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Dialog,
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports
import * as NavigationConstants from '../../../constants/NavigationConstants';
import * as SecurityConstants from '../../../constants/SecurityConstants';

import { fetchRoles } from '../../../store/actions/companyActions';
import { detailPerson, deletePerson } from '../../../store/actions/peopleActions';
import { checkClaim } from '../../../helpers/helperFunctions';

import ProfileDetailCard from '../../../components/company/profile/ProfileDetailCard';
import ProfileEditCard from '../../../components/company/profile/ProfileEditCard';
import ProductList from "../../../components/company/products/ProductsList";
import SubscriptionList from "../../../components/company/purchases/SubscriptionList";

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

const SCALED_HEIGHT = 400;
const MIN_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
  alert: {
    flex: "1",
  },
  updatingDiv: {
    flex: "1"
  },
  updatingTitle: {
    textAlign: "center"
  },
  media: {
    height: SCALED_HEIGHT,
    width: MIN_WIDTH,
    margin: '0 auto'
  },
}));

const Profile = props => {
  const { userDetailPage } = useParams();
  const urlParams = userDetailPage.split('_');
  const userIdToView = urlParams[1];

  const classes = useStyles();
  const company = useSelector(state => state.company.company);
  const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey);

  const isEmployeePage = props.location.pathname.toLowerCase().indexOf(NavigationConstants.EMPLOYEES.toLowerCase()) >= 0;
  const PROFILE_TEXT = useSelector(state => isEmployeePage ? state.company.employeeText : state.company.customerText);
  const currentUser = useSelector(state => state.auth.person);
  const authState = useSelector(state => state.auth.token);
  const people = useSelector(state => state.company.people);
  const person = people.find(x => x.id === userIdToView);

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSubscriptions, setIsEditingSubscriptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const currentUserClaims = useSelector(state => state.auth.claims);
  const allowEdit = currentUser && (currentUser.id === person.id || checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE)) ? true : false;
  const allowDelete = currentUser && (currentUser.id !== person.id && checkClaim(currentUserClaims, SecurityConstants.ALLOW_DELETE_PEOPLE)) ? true : false;

  const dispatch = useDispatch();


  /***************************** actions *****************************/

  const loadPeople = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (currentUser)
        await dispatch(fetchRoles(company.id));

      if (!authState || currentUser.id !== userIdToView)
        await dispatch(detailPerson(userIdToView, company.id, true));
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [dispatch, userIdToView, company, currentUser, authState]);

  const profileDelete = useCallback(async (personId, companyId, childAccountByOwner) => {
    try {
      setIsDeleting(true);
      await dispatch(deletePerson(personId, companyId, childAccountByOwner));
    } catch (error) {
      setIsDeleting(false);
      setError(error.message);
    }
  }, [dispatch]);

  const handleDelete = () => {
    profileDelete(person.id, company.id, currentUser.id === person.parentAccountId ? true : false)
  }

  /***************************** page effects *****************************/

  useEffect(() => {
    loadPeople();
  }, [loadPeople]);

  /***************************** renderings *****************************/

  if (isLoading)
    return (
      <Grid container spacing={6}>
        {isLoading && <div className={classes.updatingDiv}>
          <LinearProgress my={2} color="primary" />
          <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading {PROFILE_TEXT}...</Typography>
          <LinearProgress my={2} color="secondary" />
        </div>
        }
      </Grid>
    );

  const handleCancelClicked = () => {
    setIsEditing(false);
  }
  const handleUpdateSuccesful = () => {
    setIsEditing(false);
  }

  return (
    <React.Fragment>
      {person && <Helmet title={person.displayName} />}
      {error
        && <Grid container spacing={6}>
          {error && <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>}
        </Grid>
      }
      {person && !person.isDeleted &&
        <Grid container spacing={6}>
          <Grid item>
            <Typography>Profile</Typography>
            {!isDeleting &&
              <Box>
                {allowEdit && <Button color="secondary" onClick={() => alert("send message")}>
                  Message
                </Button>
                }
                {allowEdit && <Button color="secondary" onClick={() => setIsEditingSubscriptions(true)}>
                  Memberships
                </Button>
                }
                {allowEdit && <Button aria-label="edit" className={classes.iconButton} onClick={() => setIsEditing(true)}>
                  Edit
                </Button>}
                {allowDelete &&
                  <Button aria-label="delete" className={classes.iconButton} onClick={handleDelete}>
                    Delete
                  </Button>
                }
              </Box>
            }
            {isDeleting && <CircularProgress color="primary" size={24} mt={4} ml={3.5} />}
          </Grid>
          <Grid item xs={12}>
            <ProfileDetailCard person={person} />
          </Grid>
        </Grid>
      }
      {isEditing &&
        <Dialog
          open={isEditing}
          onClose={() => setIsEditing(false)}
          aria-labelledby="act-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <ProfileEditCard person={person} handleCancel={handleCancelClicked} onSuccess={handleUpdateSuccesful} />
        </Dialog>
      }
      {isEditingSubscriptions &&
        <Dialog
          open={isEditingSubscriptions}
          onClose={() => setIsEditingSubscriptions(false)}
          aria-labelledby="act-dialog-title"
          fullWidth
          maxWidth="md"
        >
          {!paymentServicePublicKey && <Grid item xs={12}><Alert mx={2} severity="error">Payment service is not configured.</Alert></Grid>}
          <Grid item xs={12} lg={6}>
            <SubscriptionList companyId={company.id} displayedUser={person} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ProductList company={company} displayedUser={person} />
          </Grid>
        </Dialog>
      }
      {!person && !isLoading &&
        <React.Fragment>
          <Helmet title={"Profile not found - " + (company ? company.name : "")} />
          <Typography variant="h2">Hmm... we couldn't find this profile.</Typography>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default withTheme(Profile);