import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet-async";


import styled, {
  createGlobalStyle,
} from "styled-components/macro";

import { spacing } from "@material-ui/system";

import {
  Box,
  CssBaseline,
  Hidden,
  Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
import { setTheme } from "../store/actions/themeActions";
import { SET_NO_COMPANY } from "../store/actions/companyActions";
import * as Colors from "../constants/Colors";
import * as authActions from '../store/actions/authActions';
import GAPageTracker from '../components/GAPageTracker';
import { ReactComponent as MuiSmartCoreLogo } from "../Assets/Logos/smartcore-logo.svg";
import GlobalFooter from '../components/GlobalFooter';

// design constants

const Typography = styled(MuiTypography)(spacing);

const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 100vh;
    display: flex;
  }
`;




const BlueBar = styled(Box)`
  width: 100%;
  height: 53px;
  background: #0A7AFF;
  background: linear-gradient(90deg, rgba(10,122,255,1) 0%, rgba(0,234,255,1) 100%);
  padding: 15px 15px 0 15px;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 250px;
    height: 100vh;
    background: #0A7AFF;
    padding: 34px 0 0 28px;
    display: block;
    flex: 0 0 auto;
    position: fixed;
    top: 0;
    left: 0;

    &:after {
      width: 500px;
      height: 500px;
      background: #0A7AFF;
      background: radial-gradient(circle, #00eaff 0%, #0A7AFF 70%);
      border-radius: 999999px;
      display: block;
      position: absolute;
      bottom: -250px;
      left: -250px;
      pointer-events: none;
      content: '';
    }
  }
`;

const SmartCoreLogo = styled(MuiSmartCoreLogo)`
  width: 125px;
  height: 23px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 180px;
    height: 32px;
  }
`;

const BlueBarText = styled(Box)`
  width: 100%;
  padding: 0 28px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;

  & > * {
    color: white;
  }

  h1 {
    margin: 0 0 11px 0;
    text-transform: uppercase;
    font-size: 38px !important;
    line-height: 38px !important;
  }

  p {
    margin: 0;
    font-size: 18px !important;
    line-height: 27px !important;
  }
`;




const AppContent = styled.div`
  width: 100%;
  padding: 30px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 250px);
    margin-left: 250px;
    padding: 0;
    display: flex;
    flex: 1 1 0%;
  }
`;

const AppFlexBox = styled(Box)`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
`;

const AppFlexBoxWrapper = styled(Box)`
  width: 100%;
  max-width: 357px;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 30px 0;
  }
`;



const Presentation = ({ children }) => {
  const didTryLogin = useSelector(state => state.auth.didTryAutoLogin);

  const dispatch = useDispatch();
  const tryLogin = useCallback(async (companyId) => {
    await dispatch({ type: SET_NO_COMPANY });
    await dispatch(setTheme(Colors.SMARTCORE, Colors.COMPANY_WEB_TYPOGRAPHY));
    if (!didTryLogin) {
      try {

        let userData = localStorage.getItem('svxvUserData');
        if (userData) {
          const { token } = JSON.parse(userData);
          //if the user has saved profile data just validate its still valid and refresh the security info
          if (token) {
            try {
              //Change comment on next two lines to force login on each applicaiton load.
              const expirationDate = await dispatch(authActions.refreshLogedInUser(token));
              // const expirationDate =  new Date();

              if (expirationDate <= new Date() || !token) {
                dispatch(authActions.setDidTryAL());
                return;
              }
              else {
                dispatch(authActions.setDidTryAL());
                return;
              }
            } catch (ex) {
              dispatch(authActions.setDidTryAL());
              return;
            }
          }
          else {
            dispatch(authActions.setDidTryAL());
            return;
          }
        }
        else {
          dispatch(authActions.setDidTryAL());
          return;
        }
      } catch (err) {
        return;
      }
    }
  }, [dispatch, didTryLogin]);

  useEffect(() => {
    tryLogin();
  }, [dispatch, tryLogin])


  return (
    <Root>

      <GAPageTracker />
      <CssBaseline />
      <GlobalStyle />
      <Helmet titleTemplate={"%s | SmartCore"}>
        <meta name="description" content={'Optimized business performance from your smartphone or computer.'} />
        <link rel="icon" href={'/favicon.ico'} />
        <link rel="apple-touch-icon" href={'/favicon.ico'} />
      </Helmet>

      <BlueBar>
        <SmartCoreLogo fill={'white'} />
        <Hidden smDown>
          <BlueBarText>
            <Typography component="h1" variant="h1">Balance Your Business</Typography>
            <Typography component="p" variant="body1">Optimized business performance from your smartphone or computer.</Typography>
          </BlueBarText>
        </Hidden>
      </BlueBar>

      <AppContent>
        <AppFlexBox>
          <AppFlexBoxWrapper>
            {children}
          </AppFlexBoxWrapper>
          <GlobalFooter />
        </AppFlexBox>
      </AppContent>
    </Root>
  );
};

export default Presentation;
