import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from "react-router-dom";

import styled, { createGlobalStyle } from "styled-components/macro";
import { spacing } from "@material-ui/system";

import Sidebar from "../components/companyNavigation/CompanySidebar";
import Header from "../components/companyNavigation/CompanyAppBar";
import WebHeader from "../components/companyNavigation/CompanyWebHeader";
import Footer from "../components/companyNavigation/CompanyFooter";
import { Helmet } from 'react-helmet-async';


import {
  Box,
  CssBaseline,
  Fab,
  Grid,
  Hidden,
  LinearProgress as MuiLinearProgress,
  Paper as MuiPaper,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";

import {
  IoCreateOutline as EditIcon,
} from "react-icons/io5";

// svxvy imports
import * as SecurityContants from '../constants/SecurityConstants';
import * as Colors from "../constants/Colors";
import * as authActions from '../store/actions/authActions';
import * as NavigationConstants from "../constants/NavigationConstants";
import { setTheme } from "../store/actions/themeActions";
import { isSvxvySite } from "../constants/NetworkConstants";
import { fetchCompany } from '../store/actions/companyActions';
import { checkClaim } from '../helpers/helperFunctions';
import EditPageModal from '../components/companyPublic/EditPageModal';
import GAPageTracker from "../components/GAPageTracker";
import HeroHome from "../components/companyPublic/HeroHome";
import HeroInternal from "../components/companyPublic/HeroInternal";
import PageText from '../components/companyPublic/PageText';
import GlobalFooter from "../components/GlobalFooter";
import WaiverModal from "../components/company/profile/WaiverModal";
import CompanyEmbedScripts from '../components/company/CompanyEmbedScripts';
import OwnerMemberTally from "../components/company/helpContent/OwnerMemberTally";
import WebsiteReviewContainer from "../components/company/reviews/WebsiteReviewContainer";
// design constans
const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);

const drawerWidth = 250;
const smallDrawerWidth = 75;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: ${smallDrawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;



const MainContent = styled(Paper)`
  display: flex;
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  justify-content: center;
  padding: 10px 15px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 47px 30px;
  }
`;

const PageTextType = styled(Typography)`
    margin-bottom: 67px;
`;

const PortalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
`;

const WebContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
`;

const formatImage = (url, size, format) => {
  if (!url)
    return '';
  const scale = 'w_' + size + ',c_scale/';
  const scaleImage = url.replace('/upload', '/upload/' + scale);
  const changeFormat = [scaleImage.slice(0, scaleImage.lastIndexOf('.')), format].join('');

  return changeFormat;
}

const getThemeName = (company, isPortalPage) => {
  let themeName = Colors.COMPANY_LIGHT;
  if (company)
    if (isPortalPage) {
      if (company.colors.useDarkTheme)
        themeName = Colors.COMPANY_DARK;
      else
        themeName = Colors.COMPANY_LIGHT;
    }
    else {
      if (company.colors.useDarkTheme)
        themeName = Colors.COMPANY_DARK_WEB;
      else
        themeName = Colors.COMPANY_LIGHT_WEB;
    }
  return themeName;
}

const CompanyDashboard = props => {
  const { children, width, loadingCompanies, isPortalPage, hideChrome, hideBanner, pageId } = props;
  const showTallyWarning = pageId &&
    (pageId.toLowerCase() === NavigationConstants.DASHBOARD.toLowerCase() || pageId.toLowerCase() === NavigationConstants.MANAGE.toLowerCase()) ? false : true;
  let { sanitizedCompanyName } = useParams();

  const companies = useSelector(state => state.company.companies);
  const company = isSvxvySite() ? companies.find(x => x.sanitizedName.toLowerCase() === sanitizedCompanyName.toLowerCase()) : companies.find(x => x.websiteDomainName !== null && (x.websiteDomainName.toLowerCase() === window.location.host.toLowerCase() || ('www.' + x.websiteDomainName).toLowerCase() === window.location.host.toLowerCase()));
  const setCompanyId = useSelector(state => state.company.companyId);

  const webPage = useSelector(state => state.company.webPages).find(x => x.pageId === pageId.toLowerCase());
  const homePage = useSelector(state => state.company.webPages).find(x => x.pageId === NavigationConstants.HOME_ID.toLowerCase());
  const defaultPageImage = homePage ? homePage.heroImage : null;

  const companySet = useSelector(state => state.company.company);
  const didTryLogin = useSelector(state => state.auth.didTryAutoLogin);
  const loggedInUser = useSelector(state => state.auth.person);

  const [icon, setIcon] = useState(company ? company.logo : null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [error, setError] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);

  const claims = useSelector(state => state.auth.claims);
  const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);
  const hideContent = webPage && webPage.hideContent && !isOwner ? true : false;

  const [currentTypography, setCurrentTypography] = useState(isPortalPage ? Colors.COMPANY_APP_TYPOGRPAHY : Colors.COMPANY_WEB_TYPOGRAPHY);
  const ConstrainedContent = isPortalPage ? PortalContent : WebContent;

  const [isFirstLoad, setisFirstLoad] = useState(true)

  const dispatch = useDispatch();

  const tryLogin = useCallback(async (companyId) => {
    if (!didTryLogin || (loggedInUser && loggedInUser.companyId !== companyId) || isFirstLoad) {
      setisFirstLoad(true);
      try {
        let userData = localStorage.getItem('svxvUserData');
        if (userData) {
          const { token } = JSON.parse(userData);
          //if the user has saved profile data just validate its still valid and refresh the security info
          if (token) {
            try {
              //Change comment on next two lines to force login on each applicaiton load.
              const expirationDate = await dispatch(authActions.refreshLogedInUser(token));
              // const expirationDate =  new Date();

              if (expirationDate <= new Date() || !token) {
                dispatch(authActions.setDidTryAL());
                return;
              }
              else {
                dispatch(authActions.setDidTryAL());
                return;
              }
            } catch (ex) {
              dispatch(authActions.setDidTryAL());
              return;
            }
          }
          else {
            dispatch(authActions.setDidTryAL());
            return;
          }
        }
        else {
          dispatch(authActions.setDidTryAL());
          return;
        }
      } catch (err) {
        setError(err);
      }
    }
  }, [dispatch, didTryLogin, loggedInUser, isFirstLoad]);

  const loadCompanyInfo = useCallback(async () => {
    setError(null);
    try {
      if (company && company.id !== setCompanyId) {
        await dispatch(fetchCompany(company.id));
        await dispatch(
          setTheme(
            getThemeName(company, isPortalPage),
            isPortalPage ? Colors.COMPANY_APP_TYPOGRPAHY : Colors.COMPANY_WEB_TYPOGRAPHY,
            company
          ));
        setIcon(company.logo);
        await tryLogin(company.id);
        //once the user is logged in get the company again as there may be more infoatin avaible.
        await dispatch(fetchCompany(company.id));
      }
    } catch (err) {
      setError(err.message);
    }
  }, [dispatch, tryLogin, company, setCompanyId, isPortalPage]);

  useEffect(() => {
    loadCompanyInfo();
  }, [loadCompanyInfo]);

  const changeTheme = useCallback(async (company, themeTypography) => {
    await dispatch(
      setTheme(
        getThemeName(company, isPortalPage),
        themeTypography,
        company
      ));
  }, [dispatch, isPortalPage]);

  useEffect(() => {
    if (company)
      changeTheme(company, currentTypography)
  }, [company, currentTypography, changeTheme]);

  useEffect(() => {
    setCurrentTypography(isPortalPage ? Colors.COMPANY_APP_TYPOGRPAHY : Colors.COMPANY_WEB_TYPOGRAPHY);
  }, [isPortalPage]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <GAPageTracker />
      {!loadingCompanies && !companySet && !company && <Redirect to={NavigationConstants.COMPANY_NOT_FOUND} />}
      <CssBaseline />
      <GlobalStyle />
      {isPortalPage &&
        <React.Fragment>
          <Drawer>
            <Hidden smUp implementation="js">
              <Sidebar
                company={companySet}
                PaperProps={{ style: { height: isWidthDown('xs', width) ? 'calc(100vh - 52px)' : '100vh', width: (isWidthUp("md", width) || isWidthDown('xs', width) ? drawerWidth : smallDrawerWidth), marginTop: isWidthDown('xs', width) ? 52 : 0 } }}
                variant="temporary"
                open={mobileOpen}
                anchor={isWidthDown('xs', width) ? 'right' : 'left'}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Sidebar
                company={companySet}
                PaperProps={{ style: { width: isWidthUp("md", width) || isWidthDown('xs', width) ? drawerWidth : smallDrawerWidth } }}
              />
            </Hidden>
          </Drawer>
          {company != null && loggedInUser &&
            <WaiverModal
              dontAllowClose
              waiverForUser={loggedInUser} />
          }
        </React.Fragment>
      }
      <AppContent>
        {isPortalPage &&
          <Hidden smUp>
            <Header
              company={companySet}
              open={mobileOpen}
              onDrawerToggle={handleDrawerToggle}
              loggedInUser={loggedInUser}
            />
          </Hidden>
        }
        {!isPortalPage && companySet && !hideChrome &&
          <React.Fragment>
            <WebHeader
              company={companySet}
              onDrawerToggle={handleDrawerToggle}
              loggedInUser={loggedInUser} />
            {pageId === NavigationConstants.HOME_ID && !hideBanner &&
              <HeroHome
                webPage={webPage}
                company={company}
                pageId={pageId}
              />
            }
            {pageId !== NavigationConstants.HOME_ID && !hideBanner &&
              <HeroInternal
                webPage={webPage}
                company={company}
                pageId={pageId}
                defaultPageImage={defaultPageImage}
              />
            }
            {webPage && webPage.hidePage && isOwner &&
              <Box display="flex" justifyContent="center" alignItems="center" >
                <ConstrainedContent>
                  <Alert severity="warning" >This page is hidden and only visible to owners</Alert>
                </ConstrainedContent>
              </Box>
            }
          </React.Fragment>
        }
        <MainContent>
          {/* px={isWidthUp("md", width) ? 25 : 5} py={isWidthUp("md", width) ? 13 : 5} */}
          {companySet &&
            <ConstrainedContent>
              {company &&
                <Helmet titleTemplate={"%s | " + company.name}>
                  <meta name="description" content={company.description} />
                  <meta property="og:title" content={company.title} />
                  <meta property="og:description" content={company.description} />
                  <meta property="og:image" content={formatImage(icon, 300, '.png')} />

                  <link rel="canonical" href={company.websiteDomainName
                    ? "https://www." + company.websiteDomainName + window.location.pathname
                    : "https://manage.smartcore.life" + window.location.pathname} />
                  <link rel="icon" href={formatImage(icon, 32, '.ico')} />
                  <link rel="apple-touch-icon" href={formatImage(icon, 192, '.png')} />
                </Helmet>
              }
              {webPage && webPage.heroSubtitle && webPage.pageId !== NavigationConstants.HOME_ID.toLowerCase() &&
                <Grid container>
                  <Grid item xs={false} lg={2}></Grid>
                  <Grid item xs={12} lg={8}>
                    <PageTextType component="p" variant="caption" align="center" style={{ margin: '25px' }}>{webPage.heroSubtitle}</PageTextType>
                  </Grid>
                </Grid>
              }
              {webPage && webPage.pageText &&
                <PageText pageText={webPage.pageText} />
              }
              {!hideContent &&
                <React.Fragment>
                  {webPage && webPage.hideContent && isOwner &&
                    <Box display="flex" justifyContent="center" alignItems="center" >
                      <Box display="flex" maxWidth="1440px">
                        <Alert severity="warning" >This content below this warning is hidden and only visible to owners</Alert>
                      </Box>
                    </Box>
                  }
                  {children}
                </React.Fragment>
              }
              {webPage &&
                <React.Fragment>
                  <PageText pageText={webPage.pageTextBottom} />
                </React.Fragment>
              }
              {!isPortalPage && !hideChrome &&
                <WebsiteReviewContainer />
              }
              {isOwner && !isPortalPage &&
                <React.Fragment>
                  {!isEditMode &&
                    <Fab color="secondary" aria-label="edit page" onClick={() => setIsEditMode(true)}>
                      <EditIcon />
                    </Fab>
                  }
                  <EditPageModal open={isEditMode} setOpen={setIsEditMode} webPage={webPage} company={company} pageId={pageId} />
                </React.Fragment>
              }
              {showTallyWarning && <OwnerMemberTally warningOnly />}
            </ConstrainedContent>
          }
          {(!companySet) && !error && <Box display="flex" flexDirection="column">
            <Typography variant="h1" gutterBottom align="center">
              We are assembling the universe one bit at a time.  Please be patient it should just take a second.
            </Typography>
            <Paper mt={3}>
              <LinearProgress my={2} />
              <LinearProgress my={2} />
              <LinearProgress my={2} />
            </Paper>
          </Box>}
          {!loadingCompanies && error &&
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" gutterBottom align={"center"}>
                Oh no something really went wrong.
              </Typography>
              <Typography variant="h2" gutterBottom align={"center"}>
                For help contact <a href="mailto:support@svxvy.com">support@svxvy.com</a>
              </Typography>
              <Typography variant="h4" gutterBottom align={"center"}>
                {error}
              </Typography>
            </Box>
          }
        </MainContent>
        {!isPortalPage && company && !hideChrome &&
          <Footer company={companySet} isOwner={isOwner} />
        }
        {hideChrome &&
          <GlobalFooter />
        }
        <CompanyEmbedScripts />
      </AppContent>
    </Root >
  );
};

export default withWidth()(CompanyDashboard);
