import React from 'react';

import { NavLink } from "react-router-dom";

// UI Imports

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    ListItem,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports

import { isSvxvySite } from '../constants/NetworkConstants';
import { formatImage } from '../helpers/imageHelpers';
import { DEFAULT_COMPANY_LOGO } from '../models/Company';

// UI Consts
const Typography = styled(MuiTypography)(spacing);

const CompanyName = styled(ListItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-botton: 20px;
 
  color: ${(props) => props.theme.header.color};
  padding: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.sidebar.category.svg.colorActive};
  }
`;

const ImageBox = styled(Box)`
  height: auto
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 0;
`;

const Image = styled.img`
  max-width: calc(100vw - 120px);
  max-height: 100px;
  min-width: 40px;

  ${(props) => props.theme.breakpoints.up("565")} {
    max-width: 450px;
  }
  ${(props) => props.theme.breakpoints.down("900")} {
    max-height: 50px;
  }
`;

const CompanyLogo = props => {
    const { company } = props;
    return (
        <CompanyName
            component={NavLink}
            to={isSvxvySite() ? "/" + company.sanitizedName : "/"}
            exact
        >
            {company.logoPhoto && company.logoPhoto !== DEFAULT_COMPANY_LOGO &&
                <ImageBox>
                    <Image
                        src={formatImage(company.logoPhoto.url, null, '.webp')}
                        width={company.logoPhoto && company.logoPhoto.width && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? (400 * company.logoPhoto.scalingFactor / 100) : 'auto'}
                        height={company.logoPhoto && company.logoPhoto.height && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? 'auto' : company.logoPhoto.height > 100 ? (100 * (company.logoPhoto.scalingFactor * 0.01)) : (company.logoPhoto.height * (company.logoPhoto.scalingFactor * 0.01))}
                    />
                </ImageBox>
            }
            {!company.logoPhoto && <Typography variant="h2" mt={4}>{company.name}</Typography>}
        </CompanyName>
    )
}

export default CompanyLogo;