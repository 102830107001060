import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from "lodash";
import { Formik, Field } from "formik"
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    Divider,
    FormGroup as MuiFormGroup,
    FormControlLabel as MuiFormControlLabel,
    Grid,
    TextField as MuiTextField,
    Switch
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCalendarOutline as CalendarIcon,
} from "react-icons/io5";
// svxvy import

import { sendMessage } from '../../../store/actions/messageActions';
import { GUID_Generator } from '../../../helpers/helperFunctions';
import SelectUser from '../SelectUser';
import StyledDialog from "../../framework/StyledDialog";
import ActionButton from "../../framework/ActionButton";

// constants

const Alert = styled(MuiAlert)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const FormGroup = styled(MuiFormGroup)(spacing);
const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
    messageText: Yup.string()
        .required("Required"),
});


const MessageModal = (props) => {
    const { hideUserSelect, emailOnly } = props;
    const theme = useTheme();

    const companyId = useSelector(state => state.company.companyId);


    const [error, setError] = useState();

    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        companyId: companyId,
        toUserIds: props.toUsers ? props.toUsers.map(x => x.id) : [],
        messageText: props.message,
        sendInApp: props.sendInAppInitialState,
        sendEmail: props.sendEmailInitialState,
        isAlert: false,
        alertExpires: new Date((new Date()).setDate(new Date().getDate() + 3)),
        activityId: props.activityId,
        occuranceId: props.occuranceId
    };

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (values,  resetForm, setOpen) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(sendMessage(values));
            setIsSubmitting(false);
            resetForm();
            setOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values, { resetForm}) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.alertId = valuesToSend.isAlert ? GUID_Generator() : null;
        submitCallback(valuesToSend, resetForm, props.setOpen);
    };


    return (
        <StyledDialog
            open={props.open}
            setOpen={props.setOpen}
            aria-labelledby="act-dialog-title"
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        form='messageForm'
                        type="submit"
                        fullWidth
                        submitting={isSubmitting}
                        variant="contained"
                        color="primary"
                        my={3}
                    >
                        Send Message
                    </ActionButton>
                    {/* {isSubmitting && <CircularProgress color="primary" size={24} mt={3} />} */}
                    {error && <Alert mb={4} severity="error">
                        {error}
                    </Alert>}
                </React.Fragment>
            }
        >
            <Box width="100%">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            <form id='messageForm' onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            {!hideUserSelect && <SelectUser selectText="Send message to:"
                                                hideSelf showEveryoneOption
                                                scrollSelectedPeople initialValue={props.toUsers}
                                                hideMessageButton
                                                onChange={(val) => setFieldValue('toUserIds', val.map(x => x.id))}
                                            />}
                                            <TextField
                                                name="messageText"
                                                label="Message"
                                                value={values.messageText}
                                                error={Boolean(touched.messageText && errors.messageText)}
                                                fullWidth
                                                helperText={touched.messageText && errors.messageText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                mt={4}
                                                mb={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid >
                                </Grid >
                                {!emailOnly &&
                                    <React.Fragment>
                                        <Divider />
                                        <FormGroup ml={4} mt={4}>
                                            <FormControlLabel control={
                                                <Field name='sendEmail' component={Switch} onChange={(val) => setFieldValue('sendEmail', !values.sendEmail)} checked={values.sendEmail} />
                                            } label='Send E-mail' />
                                            <Box display="flex" flexDirection="row">
                                                <FormControlLabel my={2} control={
                                                    <Field name='isAlert' component={Switch} onChange={(val) => setFieldValue('isAlert', !values.isAlert)} checked={values.isAlert} />
                                                } label='Set as alert (will show on dashboard)' />
                                                {values.isAlert &&
                                                    <Box ml={3}>
                                                        <KeyboardDatePicker 
                                                            inputVariant="outlined" 
                                                            disablePast
                                                            maxDate={(new Date()).setDate(new Date().getDate() + 45)}
                                                            name="alertExpires"
                                                            margin="normal"
                                                            error={Boolean(touched.alertExpires && errors.alertExpires)}
                                                            label="Alert Expires (mm/dd/yy)"
                                                            value={values.alertExpires}
                                                            onChange={date => setFieldValue('alertExpires', date, true)}
                                                            format="MM/dd/yy"
                                                            required={values.isAlert}
                                                            keyboardIcon={<CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                        </FormGroup>
                                    </React.Fragment>
                                }
                            </form >
                        </React.Fragment >
                    )
                    }
                </Formik >
            </Box >
        </StyledDialog >
    );
}

export default MessageModal;