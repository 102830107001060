import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

export const SET_REVIEWS = 'SET_REVIEWS';
export const REVIEW_CREATED = 'REVIEW_CREATED';
export const REVIEW_EDITED = 'REVIEW_EDITED';
export const REVIEW_DELETED = 'REVIEW_DELETED';

/*********************************** Offering Actions ******************************/

export const fetchReviews = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/review/' + companyId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const reviews = await response.json();
        // console.log(reviews)
        dispatch({ type: SET_REVIEWS, reviews: reviews });
        return true;
    }
}

export const createReview = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/review/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: REVIEW_CREATED, review: resData });
    };
};

export const editReview= (companyId, id, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/review/' + companyId + "/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: REVIEW_EDITED, review: resData });
    };
};

export const deleteReview = (companyId, id) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/review/' + companyId + "/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: REVIEW_DELETED, review: id });
    };
};
