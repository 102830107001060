import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

// UI Imports
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Button as MuiButton,
    DialogContent,
    FormControlLabel as MuiFormControlLabel,
    Grid,
    LinearProgress as MuiLinearProgress,
    MenuItem,
    Typography as MuiTypography,
    Select as MuiSelect,
    Switch,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as MuiAddCircle,
    IoChevronDown as MuiIoChevronDown,
    IoListCircleOutline as ListIcon,
    IoCalendarOutline as CalendarIcon,

} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { BY_DAY, BY_ACTIVITY, BY_PERSON, groupActivities } from './activityHelperFunctions';
import ActivityGroup from './ActivityGroup';
import ActivityAddEditModal from './ActivityAddEditModal';
import { fetchActivities } from '../../../store/actions/activityActions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { fetchLocations } from '../../../store/actions/locationActions';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import { listAovs } from "../../../store/actions/activityOptionActions";
import ActivityCalendar from './ActivityCalendar';
import StyledDialog from '../../framework/StyledDialog';
import GoogleAuthCodeFlow from '../../framework/GoogleAuthCodeFlow';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AddCircle = styled(MuiAddCircle)`
  transform: scale(1.2);
`;

const IoChevronDown = styled(MuiIoChevronDown)``;

const FormControlLabelPublic = styled(MuiFormControlLabel)`
  margin: 0;
  padding: 0 0 0 0;
`;


const Select = styled(MuiSelect)`
    margin: 0 25px 0 0;

    .MuiSelect-select {
        padding: 0 25px 0 0;
        font-weight: 700;
    }

    .MuiTypography-root {
        font-weight: 700;
    }

    .MuiSelect-icon {
        margin-top: 0;
        margin-right: 0;
    }

    svg {
        top: 1px;
        right: 4px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
        margin: 0 0 0 0;
    
    }
`;

const ActivityListHeading = styled(Box)`
    padding: 27px 15px 0 15px;
    
    ${(props) => props.theme.breakpoints.up("sm")} {
        margin-bottom: 18px;
        display: flex;
        fustify-content: space-between;
        align-items: center;
    }
`;

const ActivityListFilters = styled(Box)`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    // & > * {
    //         margin-bottom: 10px !important;
    //         // display: inline-block;
    //         vertical-align: middle;
    //         line-height: 16px;
    //     }

    ${(props) => props.theme.breakpoints.up("sm")} {
        display: flex;
        align-items: baseline;
        flex: 1;

        & > * {
            margin-bottom: 0 !important;
        }
    }

    ${(props) => props.theme.breakpoints.down("md")} {
        flex-direction: column;

        // & > div {
        //     margin-bottom: 0 !important;
        //     display: flex;
        //     justify-content: flex-end;
        //     margin-top: 15px;
        // }
    }
`;

const ViewBox = styled(Box)`
    display: flex;
    border-width: 1px;
    border-color: green;
    
`;
const ViewItem = styled(Box)`
    cursor: pointer;
`;

const AddNewButton = styled(Button)`
    width: 100%;
    min-width: 100px;
    margin-top: 13px;
    margin-bottom: 10px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: auto;
        margin: 0;
    }
`;

const EMPTY_CUSTOMER_ID = 'empty-12345';

const ActivityList = props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const { hideTitle, onlyPublic, onlyMy, noAdmin } = props;
    const companyId = useSelector(state => state.company.companyId);
    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const EMPLOYEE_TEXT = useSelector(state => state.company.employeeText);
    const LOCATIONS_TEXT = useSelector(state => state.company.locationsText);

    const locations = useSelector(state => state.company.locations);

    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);

    const [startDate, setStartDate] = useState(new Date(new Date((new Date()).getTime()).setHours(0, 0, 0, 0)));
    const [endDate, setEndDate] = useState(new Date(new Date((new Date()).setMonth(new Date().getMonth() + 1)).setHours(23, 59, 59, 999)));
    const [activitySort, setActivitySort] = useState(BY_DAY);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [showMyActivities, setshowMyActivities] = useState(onlyMy ? true : false);
    const [selectedCustomer, setSelectedCustomer] = useState([]);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [showCalendar, setShowCalendar] = useState(false);

    const people = useSelector(state => state.company.people).filter(x => !x.isDeleted).sort(compareObjects('displayName', 'asc'));

    const filterPeople = [{ id: EMPTY_CUSTOMER_ID, 'displayName': "Remove Filter" }, ...people];
    const stateActivites = useSelector(state => state.activity.activities);
    const allActivities = stateActivites.filter(
        x => (selectedLocations.length === 0 ? true : (selectedLocations.indexOf(x.locationId) > -1))
            && (selectedCustomer && selectedCustomer.length > 0 ? x.isAnyInActivityInstance(x.people.filter(x => selectedCustomer.includes(x.appUserId)), x) : true)
            && (onlyPublic ? x.isPublic : true));

    const activities = useMemo(() => {
        return groupActivities(startDate,
            endDate,
            allActivities,
            people,
            activitySort)
    }, [startDate, endDate, allActivities, people, activitySort]);

    const loggedInUser = useSelector(state => state.auth.person);
    const loggedInUserId = loggedInUser ? loggedInUser.id : null;
    const childrenIds = useMemo(() => {
        if (loggedInUser && loggedInUser.childProfiles !== null)
            return loggedInUser.childProfiles.map(x => x.id)
        else
            return [];
    }, [loggedInUser]);
    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);

    const sortOptions = [
        {
            id: BY_DAY,
            name: 'Day'
        }, {
            id: BY_ACTIVITY,
            name: ACTIVITY_TEXT,
        }, {
            id: BY_PERSON,
            name: EMPLOYEE_TEXT,
        },
    ]


    //==================================================================
    const dispatch = useDispatch();
    const loadActivities = useCallback(async () => {
        try {
            setError(null);
            setIsLoading(true);
            if (companyId) {
                if (allowAddEdit) {
                    await dispatch(listAovs(companyId));
                    await dispatch(fetchPeople(companyId));
                }
                await dispatch(fetchLocations(companyId));
                await dispatch(fetchActivities(companyId, startDate, endDate));
            }
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId, startDate, endDate, allowAddEdit]);

    useEffect(() => {
        loadActivities();
    }, [loadActivities]);

    useEffect(() => {
        if (showMyActivities) {
            const familyIds = [...childrenIds, loggedInUserId]
            setSelectedCustomer(familyIds)
        }
        else
            setSelectedCustomer([]);
    }, [showMyActivities, childrenIds, loggedInUserId])

    const handleLocationSelect = (loc) => {
        setSelectedLocations(loc);
    }

    return (
        <React.Fragment>
            <Grid justifyContent="space-between" container spacing={6}>
                {error && !isLoading && <Grid item><Alert severity="error">{error}</Alert></Grid>}
                {isLoading &&
                    <Box flex={1}>
                        <LinearProgress my={2} color="primary" />
                        <Typography variant={"h2"} align="center" gutterBottom>Loading {ACTIVITIES_TEXT}...</Typography>
                        <LinearProgress my={2} color="secondary" />
                    </Box>
                }
                {!isLoading && <React.Fragment>
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10}>
                            <ActivityListHeading>
                                <ActivityListFilters>
                                    <Box display="flex" justifyContent='space-between' flex={1} width={isMobile || isTablet ? '100%' : 'auto'}>
                                        {!hideTitle &&
                                            <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"} style={{ margin: (isMobile || isTablet ? '0 0 10px 0' : '0 33px 0 0') }}>
                                                <Typography component="h1" variant="h1">{onlyMy ? 'My ' + (childrenIds && childrenIds.length > 0 ? 'Family\'s ' : '') : ''}{ACTIVITIES_TEXT}</Typography>
                                                {loggedInUserId && !onlyMy &&
                                                    <Box ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
                                                        <FormControlLabelPublic control={
                                                            <Switch name='showMyActivities' onChange={(val) => setshowMyActivities(prevState => !prevState)} checked={showMyActivities || onlyMy} />
                                                        } label={'My ' + (childrenIds && childrenIds.length > 0 ? 'Family\'s ' : '') + ACTIVITIES_TEXT} />
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                        {(isMobile || isTablet) && !noAdmin &&
                                            <GoogleAuthCodeFlow marginBottom={0} marginRight={2} />
                                        }
                                        {allowAddEdit && (isMobile || isTablet) && !noAdmin &&
                                            <Button startIcon={<AddCircle />} variant="contained" color="primary" mb={3} onClick={() => setActivityDialogOpen(true)}>Add {ACTIVITY_TEXT} </Button>
                                        }
                                    </Box>
                                    <Box display="flex" flexDirection='row' alignItems='center' justifyContent={isMobile || isTablet ? 'space-between' : 'flex-start'} width={isMobile || isTablet ? '100%' : 'auto'}>
                                        <ViewBox mr={4}>
                                            <ViewItem px={2} onClick={() => setShowCalendar(false)}>
                                                <ListIcon size={24} />
                                            </ViewItem>
                                            <ViewItem onClick={() => setShowCalendar(true)} >
                                                <CalendarIcon size={24} />
                                            </ViewItem>
                                        </ViewBox>
                                        <React.Fragment>
                                            {!showCalendar &&
                                                <Box display='flex'>
                                                    <Typography component="p" variant="body2" style={{ color: theme.palette.text.secondary, margin: '0 3px 0 10px', whiteSpace: 'nowrap' }}>Sort By</Typography>
                                                    <Select
                                                        labelId="select-locationId-label"
                                                        id="locationId"
                                                        value={activitySort}
                                                        onChange={(event) => setActivitySort(event.target.value)}
                                                        IconComponent={IoChevronDown}
                                                        disableUnderline={true}
                                                        ml={1}
                                                    >
                                                        {sortOptions.map((option) => (
                                                            <MenuItem key={option.id} value={option.id} selected={option === activitySort ? true : false}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            }
                                            {!isTablet && showCalendar &&
                                                <React.Fragment>
                                                    {locations.length > 1 &&
                                                        <Select
                                                            labelId="select-locationId-label"
                                                            id="locationId"
                                                            value={selectedLocations}
                                                            onChange={(event) => handleLocationSelect(event.target.value)}
                                                            displayEmpty
                                                            multiple
                                                            IconComponent={IoChevronDown}
                                                            disableUnderline={true}
                                                            renderValue={(val) => {
                                                                let text = LOCATIONS_TEXT;
                                                                if (selectedLocations.length > 1)
                                                                    text = 'Multiple';
                                                                else if (selectedLocations.length === 1)
                                                                    text = locations.filter(x => x.id === selectedLocations[0])[0].name;

                                                                return (
                                                                    <Typography>{text}</Typography>
                                                                )
                                                            }
                                                            }
                                                        >
                                                            {locations.map((option) => (
                                                                <MenuItem key={option.id} value={option.id} selected={selectedLocations.filter(x => x.id === option.id).length > 0 ? true : false}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    }
                                                    {allowAddEdit && !onlyMy &&
                                                        <Select
                                                            labelId="select-customerId-label"
                                                            id="customerId"
                                                            value={selectedCustomer}
                                                            onChange={(event) => event.target.value === EMPTY_CUSTOMER_ID ? null : setSelectedCustomer([event.target.value])}
                                                            displayEmpty
                                                            IconComponent={IoChevronDown}
                                                            disableUnderline={true}
                                                            disabled={showMyActivities}
                                                            renderValue={(val) => {
                                                                let text = selectedCustomer && people.find(x => x.id === selectedCustomer) ? people.find(x => x.id === selectedCustomer).displayName : CUSTOMER_TEXT;
                                                                return (
                                                                    <Typography>{text}</Typography>
                                                                )
                                                            }
                                                            }
                                                        >
                                                            {filterPeople.map((option) => (
                                                                <MenuItem key={option.id} value={option.id} selected={selectedCustomer}>
                                                                    {option.displayName ? option.displayName : ''}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    }
                                                </React.Fragment>
                                            }
                                            {/* TODO For now adding this button twice because of the view logic but need to refactor. */}
                                            {!showCalendar &&
                                                <Button
                                                    onClick={() => setFilterDialogOpen(true)}
                                                    variant="outlined"
                                                    style={{
                                                        minWidth: 'auto',
                                                        backgroundColor: 'transparent !important',
                                                        // margin: isMobile ? '0 15px 0 0' : '0 34px 0 0',
                                                        // padding: '0',
                                                        fontWeight: '700',
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    Filter
                                                </Button>

                                            }
                                            {(showCalendar && (isMobile)) &&
                                                <Button
                                                    onClick={() => setFilterDialogOpen(true)}
                                                    variant="outlined"
                                                    style={{
                                                        minWidth: 'auto',
                                                        backgroundColor: 'transparent !important',
                                                        // margin: isMobile ? '0 15px 0 0' : '0 34px 0 0',
                                                        // padding: '0',
                                                        fontWeight: '700',
                                                    }}
                                                >
                                                    Filter
                                                </Button>
                                            }

                                        </React.Fragment>
                                        {(!isMobile && !isTablet) && !noAdmin &&
                                            <GoogleAuthCodeFlow marginBottom={0} marginRight={2} />
                                        }
                                    </Box>
                                </ActivityListFilters>
                                {!isMobile && !isTablet && allowAddEdit && !noAdmin &&
                                    <AddNewButton startIcon={<AddCircle />} variant="contained" color="primary" my={1} onClick={() => setActivityDialogOpen(true)}>Add {ACTIVITY_TEXT} </AddNewButton>
                                }
                            </ActivityListHeading>
                            {!showCalendar && activities.length === 0 && !onlyMy &&
                                <Typography variant='h2' align='center' mt={10}>
                                    No {ACTIVITIES_TEXT} Found. Try updating your filters.
                                </Typography>
                            }
                            {!showCalendar && activities.length === 0 && onlyMy &&
                                <Box display="fles" flexDirection="column">
                                    <Typography variant='h2' align='center' mt={10}>
                                        No {ACTIVITIES_TEXT.toLowerCase()} Found.
                                    </Typography>
                                    <Typography variant='h2' align='center' mt={3} mb={10}>
                                        Check out the schedule to find a {ACTIVITY_TEXT.toLowerCase()}.
                                    </Typography>
                                </Box>
                            }
                            {!showCalendar && activities.map((item, ind) => {
                                return (
                                    <ActivityGroup
                                        key={item.key}
                                        heading={item.heading}
                                        headingIsDate={item.headingIsDate}
                                        openByDefault={item.openByDefault}
                                        activitiesByDay={item.activitiesByDay}
                                        navigation={props.navigation}
                                        grouping={activitySort}
                                        startDate={startDate.getTime()}
                                        endDate={endDate.getTime()} />
                                )
                            })}
                            {showCalendar &&
                                <ActivityCalendar activities={allActivities} endDatePassedIn={endDate} />
                            }
                            {/* <ActivitiesListByDay startDate={startDate} endDate={endDate} viewStartDate={startDate} viewEndDate={endDate} /> */}
                        </Grid>
                    </Grid>
                </React.Fragment>}
                {filterDialogOpen && <StyledDialog
                    style={{ height: "90vh" }}
                    open={filterDialogOpen}
                    setOpen={setFilterDialogOpen}
                    aria-labelledby="act-dialog-title"
                    primaryAction={
                        <Button fullWidth variant="outlined" color="secondary" onClick={() => setFilterDialogOpen(false)} mb={3}>Close</Button>
                    }
                >
                    <DialogContent>
                        <Grid container>
                            <Typography component="h2" variant="h3" mb={5}>Filter {ACTIVITIES_TEXT.toLowerCase()}</Typography>
                            {allowAddEdit && !noAdmin &&
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="h4" mb={4}>By {CUSTOMER_TEXT}:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            labelId="select-customerId-label"
                                            id="customerId"
                                            value={selectedCustomer}
                                            onChange={(event) => event.target.value === EMPTY_CUSTOMER_ID ? setSelectedCustomer([]) : setSelectedCustomer([event.target.value])}
                                            displayEmpty
                                            IconComponent={IoChevronDown}
                                            disableUnderline={true}
                                            disabled={showMyActivities}
                                            renderValue={(val) => {
                                                let text = selectedCustomer && people.find(x => x.id === selectedCustomer) ? people.find(x => x.id === selectedCustomer).displayName : CUSTOMER_TEXT;
                                                return (
                                                    <Typography>{text}</Typography>
                                                )
                                            }
                                            }
                                        >
                                            {filterPeople.map((option) => (
                                                <MenuItem key={option.id} value={option.id} selected={selectedCustomer}>
                                                    {option.displayName ? option.displayName : ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            }
                            {(isTablet || !showCalendar) &&
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="h4" mb={4}>By Location:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            labelId="select-locationId-label"
                                            id="locationId"
                                            value={selectedLocations}
                                            onChange={(event) => handleLocationSelect(event.target.value)}
                                            displayEmpty
                                            multiple
                                            IconComponent={IoChevronDown}
                                            disableUnderline={true}
                                            renderValue={(val) => {
                                                let text = LOCATIONS_TEXT;
                                                if (selectedLocations.length > 1)
                                                    text = 'Multiple';
                                                else if (selectedLocations.length === 1)
                                                    text = locations.filter(x => x.id === selectedLocations[0])[0].name;

                                                return (
                                                    <Typography>{text}</Typography>
                                                )
                                            }
                                            }
                                        >
                                            {locations.map((option) => (
                                                <MenuItem key={option.id} value={option.id} selected={selectedLocations.filter(x => x.id === option.id).length > 0 ? true : false}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>}
                            {!showCalendar &&
                                <React.Fragment>
                                    <Grid container display="flex" direction="row" alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Starting After:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker inputVariant="outlined"
                                                name="startDateTime"
                                                margin="normal"
                                                label="Start Date (mm/dd/yy)"
                                                value={startDate}
                                                onChange={date => setStartDate(date)}
                                                fullWidth
                                                format="MM/dd/yy"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container display="flex" direction="row" alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="h4">Ending Before:</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker inputVariant="outlined"
                                                name="endDateTime"
                                                margin="normal"
                                                label="End Date (mm/dd/yy)"
                                                value={endDate}
                                                onChange={date => setEndDate(date)}
                                                fullWidth
                                                format="MM/dd/yy"
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>

                    </DialogContent>
                </StyledDialog>
                }
                {activityDialogOpen && <ActivityAddEditModal
                    open={activityDialogOpen}
                    setOpen={setActivityDialogOpen}
                    companyId={companyId}
                    viewStartDate={startDate} viewEndDate={endDate}
                ></ActivityAddEditModal>}
            </Grid>
        </React.Fragment >
    );
}

export default withTheme(ActivityList);
