import React from 'react';
import { useSelector } from 'react-redux';

// UI Imports
import { Helmet } from 'react-helmet-async';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
  Card as MuiCard,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core";

// svxvy imports
import FileUploader from '../../../components/framework/FileUploader';

// UI Consts
const Card = styled(MuiCard)(spacing);

const MyFiles = props => {
  const theme = useTheme();

  const company = useSelector(state => state.company.company);
  const currentUser = useSelector(state => state.auth.person);

  return (
    <React.Fragment>
      {currentUser && <Helmet title={currentUser.displayName + " files - " + (company ? company.name : "")} />}
      {currentUser &&
        <Grid container>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Card mb={3} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
              <CardContent>
                <Typography variant="h3" component="h2" gutterBottom style={{ marginBottom: '20px' }}>
                  Your Files
                </Typography>

                <FileUploader appUser={currentUser} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
      {!currentUser &&
        <React.Fragment>
          <Helmet title={"Profile not found - " + (company ? company.name : "")} />
          <Typography variant="h2">Hmm... we couldn't find this profile.</Typography>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default MyFiles;