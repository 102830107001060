import React from 'react';
import { useSelector } from 'react-redux';

// UI Imports

import { withTheme } from "styled-components/macro";
import { Helmet } from 'react-helmet-async';

import {
  Grid,
} from "@material-ui/core";

// svxvy imports
import CompanyPaymentServiceSettingsForm from '../../../components/company/manageSettings/CompanyPaymentServiceSettingsForm';
// UI Consts 

const CompanySettings = (props) => {
  const company = useSelector(state => state.company.company);

  return (
    <React.Fragment>
      <Helmet title="Manage Settings" />

      {company &&
        <Grid container>
          <Grid item xs={12}>
            <CompanyPaymentServiceSettingsForm company={company} />
          </Grid>
        </Grid>
      }
      {!company &&
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <p>Hmm.... we couldn't find this company do you want to register the company with svxvy?</p>
          </Grid>
        </Grid>
      }
    </React.Fragment >
  );
}

export default withTheme(CompanySettings);
