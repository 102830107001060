import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// UI Imports
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  // TextField as MuiTextField,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import {
  Trash2 as FeatherTrash2,
  ChevronLeft as FeatherPrev,
  ChevronRight as FeatherNext,
  X as CloseIcon
} from "react-feather";

// svxvy imports
import * as SecurityConstants from "../../../constants/SecurityConstants";
import { deletePhotoFromCompany } from "../../../store/actions/companyActions";
import { uploadPhotoToGallery } from "../../../store/actions/companyActions";
import { formatImage } from "../../../helpers/imageHelpers";
import { checkClaim } from "../../../helpers/helperFunctions";

import UploadPhoto from "./UploadPhoto";
// UI Consts
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

// Return expected parameters for react ImageGallery
const getPhotoGalleryImages = (photos) => {
  const images = Array(photos.length);
  for (let i = 0; i < images.length; i++) {
    images[i] = {
      index: i,
      photoId: photos[i].id,
      big: photos[i].url,
      original: formatImage(photos[i].url, 322, ".webp"),
      thumbnail: formatImage(photos[i].url, 92, ".webp"),
      description: photos[i].description ? photos[i].description : null,
    };
  }
  return images;
};

//STYLES------------------------------------------------------------------------------------
const ImageWrapper = styled(Box)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.text.primary} !important;
  background-size: cover !important;
  padding-top: 75%;
  position: relative;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

const StyledCircularProgress = styled(CircularProgress)`
position: absolute;
top: 15px;
left: 15px;
z-index: 100;
`

// const GridContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   align-content: space-around;
//   justify-content: space-around;
// `;

// const GridItem = styled.div`
//   flex: 25%;
//   padding: 15px;
// `;

//-Dialog
const StyledDialog = styled(Dialog)`
  padding: 0;

  .MuiPaper-root{
    color: #FFFFFF;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: ${(props) => props.theme.palette.background.default};
    padding: 0;
  }
  .MuiButton-root{
    min-width: 0px;
    padding: 0px;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 0 !important;
`;
const DivContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
//-Header
const DivHeader = styled.div`
  display: flex;
  flex: 0.3;
  position: relative;
  justify-content: flex-end;
  align-items: center;
`;
const DivCounter = styled.div`
  position: absolute;
  color: ${(props) => props.theme.palette.background.paper};
  filter: invert(1);
  font-weight: bold;
  font-size: 16px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
`;
const ButtonExit = styled(MuiButton)`
  font-size: 80px;
  height: 48px;
  width: 48px;
  top: 16px;
  right: 48px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: 0;
  transition: box-shadow 0.1s;
  :hover{
    cursor: pointer;
    box-shadow: 0 0 2px grey;
    background-color: ${(props) => props.theme.palette.background.paper};
  }
  svg{
    height: 35px;
    width: 35px;
  }
`;
//-Slider
const DivSliderContainer = styled.div`
  max-height: 100%;
  display: flex;
  flex: 10;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  svg{
    height: 40px;
    width: 40px;
  }
`;
const SliderImage = styled.img`
  display: flex;
  height: calc(100vh - 158px);
  margin-top: -38px;
  padding: 0 30px;
  flex: 6;
  align-items: center;
  object-fit: contain;
  overflow: hidden;
`;
const DivPrev = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
`;
const ButtonPrev = styled(MuiButton)`
  display: flex;
  width: 48px;
  height: 96px;
  margin-left: 28px;
  border-radius: 10px;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0;
  transition: box-shadow 0.1s;
  :hover{
    cursor: pointer;
    box-shadow: 0 0 4px grey;
  }
`;
const DivNext = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
`;
const ButtonNext = styled(MuiButton)`
  display: flex;
  width: 48px;
  height: 96px;
  margin-right: 28px;
  border-radius: 10px;
  border: none;
  color: white;
  align-items: center;
  justify-content: center;
  box-shadow: 0;
  transition: box-shadow 0.1s;
  :hover{
    cursor: pointer;
    box-shadow: 0 0 4px grey;
  }
`;
//END STYLES--------------------------------------------------------------------------------

const ImagesGallery = (props) => {
  const { photos } = props;
  const images = getPhotoGalleryImages(photos);
  const [index, setIndex] = useState(0);

  const companyId = useSelector((state) => state.company.companyId);
  const loggedInUser = useSelector((state) => state.auth.person);
  const currentUserClaims = useSelector((state) => state.auth.claims);
  const allowManagePhotos =
    loggedInUser &&
      checkClaim(
        currentUserClaims,
        SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES
      )
      ? true
      : false;

  const dispatch = useDispatch();

  //Photo Delete----------------------------------------------------------------------------
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteHandler = useCallback(
    async (photoId, companyId) => {
      setDeleteError(null);
      try {
        const r = window.confirm("Are you sure you want to delete this photo?");
        if (r) {
          setIsDeleting(true)
          await dispatch(deletePhotoFromCompany(photoId, companyId));
        }
        setIsDeleting(false)
      } catch (error) {
        setIsDeleting(false)
        setDeleteError(error.message);
      }
    },
    [dispatch]
  );

  const onDeletePress = (event, id) => {
    event.stopPropagation();
    onDeleteHandler(id, companyId);
  };
  //END Photo Delete------------------------------------------------------------------------

  //Photo Upload----------------------------------------------------------------------------
  const [uploadError, setUploadError] = useState(null);
  const [photoScrollDialog, setPhotoScrollDialog] = useState(false);

  const submitCallback = useCallback(
    async (values, companyId) => {
      setUploadError(null);
      try {
        await dispatch(
          uploadPhotoToGallery(
            values,
            companyId,
          )
        );
        setUploadError(null);
      } catch (error) {
        setUploadError(error.message);
      }
    },
    [dispatch]
  );

  const handleSubmit = (values) => {
    submitCallback({ photo: values }, companyId);
  };

  // Image Select
  const handleImageClick = async (index) => {
    setIndex(index);
    setPhotoScrollDialog(true);
  };


  const handleChange = (dir) => {
    if (dir === "next") {
      if (index + 1 >= images.length) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    } else {
      if (index < 1) {
        setIndex(images.length - 1);
      } else {
        setIndex(index - 1);
      }
    }
  };
  //END Photo Upload------------------------------------------------------------------------

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        {allowManagePhotos && <Grid item xs={12}>
          <UploadPhoto
            onSuccess={(image) => {
              handleSubmit(image);
            }}
          />
          {uploadError && (
            <Alert mb={4} severity="error">
              {uploadError}
            </Alert>
          )}
        </Grid>}
        {deleteError && (
          <Grid item xs={12}>
            <Alert mb={4} severity="error">
              {deleteError}
            </Alert>
          </Grid>
        )}
      </Grid>
      {/*---------- NEW GALLERY ----------*/}
      <Grid container spacing={6}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={image.photoId}>
            <ImageWrapper style={{ background: 'url(' + image.original + ') no-repeat center center' }} onClick={() => handleImageClick(image.index)}>
              {allowManagePhotos && !isDeleting &&
                <StyledTooltip title={"Delete Photo"} onClick={event => onDeletePress(event, image.photoId)}>
                  <IconButton color="secondary">
                    <FeatherTrash2 />
                  </IconButton>
                </StyledTooltip>
              }
              {isDeleting && <StyledCircularProgress color="primary" size={20} />}
            </ImageWrapper>
          </Grid>
        ))}
      </Grid>

      {images && images.length > 0 &&
        <StyledDialog open={photoScrollDialog} fullScreen onClose={() => { setPhotoScrollDialog(false); }} >
          <StyledDialogContent>
            <DivContent>
              <DivHeader>
                <ButtonExit onClick={() => { setPhotoScrollDialog(false); }}><CloseIcon /></ButtonExit>
                <DivCounter>{images[index].index + 1}/{images.length}</DivCounter>
              </DivHeader>
              <DivSliderContainer>
                <DivPrev>
                  <ButtonPrev variant="contained" color="primary" onClick={() => { handleChange("prev"); }}><FeatherPrev /></ButtonPrev>
                </DivPrev>
                <SliderImage src={images[index].big} />
                <DivNext>
                  <ButtonNext variant="contained" color="primary" onClick={() => { handleChange("next"); }}><FeatherNext /></ButtonNext>
                </DivNext>
              </DivSliderContainer>
            </DivContent>

          </StyledDialogContent>
        </StyledDialog>
      }
      {/*---------- END NEW GALLERY --------*/}
    </React.Fragment>
  );
};

export default ImagesGallery;
