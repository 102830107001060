import React from "react";
import { useSelector } from "react-redux";

// UI Imports
import { withTheme } from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  // Typography as MuiTypography,
} from "@material-ui/core";

// svxvy components

import { isSvxvySite } from '../../../constants/NetworkConstants';
import LeftRightComponent from '../../../components/companyPublic/LeftRightComponent';


// UI Consts

const CompanyLanding = (props) => {
  const company = useSelector((state) => state.company.company);
  const webPages = useSelector(state => state.company.webPages);

  const baseURl = isSvxvySite() ? "/" + company.sanitizedName : "";

  return (
    <React.Fragment>
      <Helmet>
        <title>Welcome to {company.name}</title>
      </Helmet>
      {webPages.map((webpage, index) => {
        const right = index % 2 === 1 ? true : false;
        return (
          <React.Fragment key={webpage.id}>
            {webpage.highlight &&
              <LeftRightComponent 
                // id={offeringsPage ? offeringsPage.id : NavigationContants.OFFERINGS.toLowerCase()}
                title={webpage.highlightTitle}
                description={webpage.highlightSubtitle}
                imageUrl={webpage.highlightImage ? webpage.highlightImage.url : null}
                ctaText={webpage.highlightCTAText}
                ctaLink={baseURl + '/' + webpage.pageId}
                right={right}
              />}
          </React.Fragment>
        )
      })}
    </React.Fragment >
  );
};

export default withTheme(CompanyLanding);
