export const compareObjects = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export const MILLISECONDS_TO_MINTUES = 60000;
export const MINUTE_TIME_UNIT = 'mins';
export const HOUR_TIME_UNIT = 'hours';

export const calculateDuration = (startTime, endTime, timeUnit = null) => {
    let duration = (endTime - startTime) / MILLISECONDS_TO_MINTUES;

    switch (timeUnit) {
        case MINUTE_TIME_UNIT:
            return duration + ' ' + MINUTE_TIME_UNIT;
        case HOUR_TIME_UNIT:
            return duration + ' ' + HOUR_TIME_UNIT;
        default:
            return duration;
    }

}

export const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';

export const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValidities: updatedValidities,
            inputValues: updatedValues
        };
    }
    return state;
};

const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export const GUID_Generator = () => {
    const guid = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    return guid;
}

export const checkClaim = (claims, claimToCheck) => {
    let hasClaim = false;
    if (claims) {
        claims.forEach(claim => {
            if (claim.type === claimToCheck)
                hasClaim = true;
        });
    }

    return hasClaim;
}

export const isInEmployeeRole = (roles) => {
    let isEmployee = false;
    if (roles) {
        roles.forEach(r => {
            if (r.isEmployeeRole === true)
                isEmployee = true;
        });
    }
    return isEmployee;
}

export const userInRole = (user, role) => {
    let inRole = false;
    if (user.roles) {
        user.roles.forEach(r => {
            if (r.id === role.id)
                inRole = true;
        });
    }
    return inRole;
}

export const userInRoleByRoleName = (user, roleName) => {
    let inRole = false;
    if (user.roles) {
        user.roles.forEach(r => {
            if (r.name.toLowerCase() === roleName.toLowerCase())
                inRole = true;
        });
    }

    return inRole;
}


export const getMilliseconds = (value, unit) => {
    switch (unit) {
        case 'minutes':
            return value * 60 * 1000;
        case 'hours':
            return value * 60 * 60 * 1000;
        case 'days':
            return value * 24 * 60 * 60 * 1000;
        default:
            return value;
    }
}

export const textTruncate = (str, length, ending) => {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

export const ordinalSuffixOf = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const isTomorrow = (someDate) => {
    const today = new Date(new Date().getTime() + 86400000);
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}