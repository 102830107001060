import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

export const AOVS_LIST = 'LIST_AOVS';
export const AOV_CREATED = 'AOV_CREATED';
export const AOV_EDITED = 'AOV_EDITED';
export const AOV_DELETED = 'AOV_DELETED';

/*********************************** Offering Actions ******************************/
export const listAovs = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/activities/listoptionvalues/' + companyId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: null,
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        await dispatch({ type: AOVS_LIST, aovs: resData });
    };
};

export const createAov = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/activities/createoptionvalue/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        await dispatch({ type: AOV_CREATED, aov: resData });
        return resData;
    };
};

export const editAov= (companyId, id, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/activities/editoptionvalue/' + companyId + "/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        await dispatch({ type: AOV_EDITED, aov: resData });
        return resData;
    };
};

export const deleteAov= (companyId, id, formData) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/activities/deleteoptionvalue/' + companyId + "/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        await dispatch({ type: AOV_DELETED, aov: id });
    };
};
