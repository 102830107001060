import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  LinearProgress as MuiLinearProgress,
  Grid,
  Typography as MuiTypography,
  List as MuiList,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoAddCircleOutline as MuiAddCircle,
} from "react-icons/io5";

// svxvy imports
import { fetchLocations } from '../../../store/actions/locationActions';
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import LocationCard from '../../../components/company/location/LocationCard';
import LocationForm from '../../../components/company/location/LocationForm';

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const LocationList = styled(MuiList)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const AddCircle = styled(MuiAddCircle)`
  transform: scale(1.2);
`;

const useStyles = makeStyles((theme) => ({
  alert: {
    flex: "1",
  },
  updatingDiv: {
    flex: "1"
  },
  updatingTitle: {
    textAlign: "center"
  }
}));

const ManageLocations = props => {

  const currentUser = useSelector(state => state.auth.person);
  const currentUserClaims = useSelector(state => state.auth.claims);
  const allowAddEditPeople = currentUser && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);

  const classes = useStyles();

  const isMounted = React.useRef(true); // monitors if users navigates away from the page so no state is updated after the navigation.
  const authState = useSelector(state => state.auth.token);


  const company = useSelector(state => state.company.company);
  const LOCATIONS_TEXT = useSelector(state => state.company.locationsText);
  const LOCATION_TEXT = useSelector(state => state.company.locationText);

  //this is slow for large groups not sure why. 
  const locations = useSelector(state => state.company.locations).filter(x => !x.isDeleted);
  const filteredLocations = locations;
  // .filter(p =>
  //   search.length > 0
  //     ? (p.name.toLowerCase().includes(search.toLowerCase())
  //       || (p.address != null && p.address.toLowerCase().includes(search.toLowerCase()))
  //       || (p.city != null && p.city.toLowerCase().includes(search.toLowerCase()))
  //       || (p.state != null && p.state.toLowerCase().includes(search.toLowerCase()))
  //       || (p.zip != null && p.zip.toLowerCase().includes(search.toLowerCase()))
  //     )
  //     : true);

  filteredLocations.sort(compareObjects('name', 'asc'));

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const dispatch = useDispatch();

  const loadLocations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (await dispatch(fetchLocations(company.id)) && isMounted.current)
        setIsLoading(false);
    } catch (err) {
      if (isMounted.current) {
        setError(err.message);
        setIsLoading(false);
      }
    }
  }, [dispatch, company]);

  //authState is tracked here and forces a reload after login or logout to get the most current state.
  useEffect(() => {
    loadLocations();
  }, [loadLocations, authState]);

  // monitors if users navigates away from the page so no state is updated after the navigation.
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleEditClicked = (location) => {
    setEditingLocation(location);
  }

  return (
    <React.Fragment>
      <Helmet title={LOCATIONS_TEXT + " - " + (company ? company.name : "")} />
      {error
        && <Grid container>
          {error && <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>}
        </Grid>
      }
      {!isLoading &&
        <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: '15px' }}>
              <Typography component="h1" variant="h1">{LOCATIONS_TEXT}</Typography>
              {allowAddEditPeople &&
                <Button startIcon={<AddCircle />} variant="contained" color="primary" my={1} onClick={() => setIsCreating(true)}>Add New {LOCATION_TEXT} </Button>
              }
            </Box>
            <LocationList>
              {filteredLocations.map((location, index) => {
                return (
                  <LocationCard key={location.id} location={location} showActions editClicked={handleEditClicked} />
                )
              })}
            </LocationList>
          </Grid>
        </Grid>
      }
      <LocationForm
        isOpen={isCreating}
        setIsOpen={setIsCreating}
        company={company}
        showInDialog
      />
      {isLoading && !editingLocation
        && <Grid container>
          {isLoading && <div className={classes.updatingDiv}>
            <LinearProgress my={2} color="primary" />
            <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading {LOCATIONS_TEXT}...</Typography>
            <LinearProgress my={2} color="secondary" />
          </div>
          }
        </Grid>
      }
    </React.Fragment>
  )
}

export default withTheme(ManageLocations);