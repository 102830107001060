import { RRule } from 'rrule';
import moment from 'moment';

import { getMilliseconds } from '../helpers/helperFunctions';

class Activity {
    constructor(id, occuranceId
    ) {
        this.id = id;
        this.occuranceId = occuranceId != null ? occuranceId : "0";
    }

    update(activity) {
        this.title = activity.title;
        this.parentSeries = activity.parentSeries;
        this.description = activity.description;
        this.people = activity.people;
        this.icon = activity.icon;
        this.duration = activity.duration;
        this.originalStartDate = new Date(activity.startDateTime);// this is the orgininal start start date of the activity used for editing
        this.startDateTime = new Date(activity.startDateTime);  // this is used by each occurance for its start
        this.displaybleStartDateTime = new Date(activity.startDateTime); // this is used to handle correction for DST issues.
        this.endDateTime = new Date(activity.endDateTime);
        this.rrule = activity.rRule && activity.rRule.endsWith(';') ? activity.rRule.substring(0, activity.rRule.trim().length - 1) : activity.rRule;
        this.recurranceExpections = activity.recurranceExceptions;
        this.totalSpaces = activity.totalSpaces;
        this.locationId = activity.locationId;
        this.manualLocation = activity.manualLocation;
        this.price = activity.price;
        this.seriesPrice = activity.seriesPrice;
        this.allowSelfRegister = activity.allowSelfRegister;
        this.selfRegisterStartValue = activity.selfRegisterStartValue;
        this.selfRegisterStartUnit = activity.selfRegisterStartUnit;
        this.selfRegisterEndValue = activity.selfRegisterEndValue;
        this.selfRegisterEndUnit = activity.selfRegisterEndUnit;
        this.allowSelfDrop = activity.allowSelfDrop;
        this.selfDropStartValue = activity.selfDropStartValue
        this.selfDropStartUnit = activity.selfDropStartUnit
        this.autoCancel = activity.autoCancel;
        this.autoCancelMinutes = activity.autoCancelMinutes;

        // start values not used
        this.allowSelfSchedule = activity.allowSelfSchedule;
        this.selfScheduleStartValue = activity.selfScheduleStartValue;
        this.selfScheduleStartUnit = activity.selfScheduleStartUnit;
        this.allowSelfCancel = activity.allowSelfCancel;
        this.selfCancelStartValue = activity.selfCancelStartValue;
        this.selfCancelStartUnit = activity.selfCancelStartUnit;
        // End values not used
        this.statuses = activity.statuses;
        this.isDeleted = activity.isDeleted;
        this.isPublic = activity.isPublic;
        this.subscriptionPrices = activity.subscriptionPrices;
        this.requirePrepayment = activity.requirePrepayment;
        this.requireCreditCard = activity.requireCreditCard;
        this.splitPayment = activity.splitPayment;
        this.allowedPackages = activity.allowedPackages;
        this.meetingUrl = activity.meetingUrl;
        this.sendReminder = activity.sendReminder;
        this.reminderHours = activity.reminderHours;
        this.reminderMessage = activity.reminderMessage;
        this.requestConfirmation = activity.requestConfirmation;
        this.confirmed = activity.confirmed;
        this.rejected = activity.rejected;
        this.rejectReason = activity.rejectReason;
        this.fileLocation = activity.fileLocation;
        this.calendarColor = activity.calendarColor;
        this.activityResources = activity.activityResources;
        this.allowSeriesRegistration = activity.allowSeriesRegistration;
        this.dontAllowDropIns = activity.dontAllowDropIns;
        this.isPrivate = activity.isPrivate;
        this.privateUntil = activity.privateUntil ? new Date(activity.privateUntil) : null;
        this.twoOrMoreMemberDiscount = activity.twoOrMoreMemberDiscount;
    }

    canSelfRegister = (activity, user, attendees) => {
        if (!user)
            return false;
        const allowSelfRegisterByTime = Date.now() >= activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterStartValue, activity.selfRegisterStartUnit) && Date.now() <= (activity.displaybleStartDateTime.getTime() + getMilliseconds(activity.duration, 'minutes'));
        const userRegister = activity.people.find(x => x.appUserId === user.id && activity.isInActivityInstance(x, activity));

        // if (activity.occuranceId == 1645016400000 && activity.title == 'Open Gym 8am to 12pm') {
        //     console.log(Date.now(),(activity.displaybleStartDateTime.getTime() + getMilliseconds(activity.duration, 'minutes')));
        //     console.log(activity.title, activity.occuranceId, activity.allowSelfRegister, allowSelfRegisterByTime, attendees.length < activity.totalSpaces, !userRegister, activity.startDateTime, activity.displaybleStartDateTime)
        // }

        if (activity.allowSelfRegister && allowSelfRegisterByTime && attendees.length < activity.totalSpaces && !userRegister)
            return true;

        return false;
    }

    canSelfDrop = (activity, user) => {
        const allowSelfDropByTime = Date.now() <= (activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfDropStartValue, activity.selfDropStartUnit));// && Date.now() <= activity.startDateTime;
        const userRegister = activity.people.find(x => x.appUserId === user.id && activity.isInActivityInstance(x, activity) && !x.isRunningActivity);

        if (activity.allowSelfRegister && allowSelfDropByTime && userRegister)
            return true;

        return false;
    }

    isAnyInActivityInstance = (attendees, activity) => {
        if (!attendees || !activity)
            return false;

        for (const a in attendees) {
            if (activity.isInActivityInstance(attendees[a], activity))
                return true;
        }
        return false;
    }

    isInActivityInstance = (attendee, activity) => {
        if (!attendee || !activity)
            return false;

        if (((attendee.attendAllInstances && (activity.occuranceId.length === 0 || attendee.dropInstanceDates.indexOf(activity.occuranceId) === -1)) || (attendee.attendInstanceDates.indexOf(activity.occuranceId) > -1))) {
            if (attendee.userIsDeleted && activity.occuranceId !== 0 && activity.occuranceId > (new Date(attendee.deletedOn)).getTime())
                return false;
            else if (attendee.userIsDeleted && activity.occuranceId === 0 && activity.displaybleStartDateTime > new Date(attendee.deletedOn))
                return false;
            else
                return true;
        }

        return false;
    }

    isInNonExpandedActivity = (attendee, activity) => {
        if (!attendee || !activity)
            return false;
        if (attendee.attendAllInstances) {
            if (attendee.userIsDeleted)
                return false;
            else
                return true;
        }

        return false;
    }

    isFamilyMemberInInstance = (attendees, activity, nonExpandedActivity) => {
        if (!attendees || !activity)
            return false;

        if (nonExpandedActivity) {
            for (const a in attendees) {
                if (activity.isInNonExpandedActivity(attendees[a], activity))
                    return true;
            }
        }
        else {
            for (const a in attendees) {
                if (activity.isInActivityInstance(attendees[a], activity))
                    return true;
            }
        }

        return false;
    }



    userHasValidPackageForActivity = (user) => {
        if (user.purchases && user.purchases.length > 0
            && this.allowedPackages.some(x => user.purchases.some(y => y.units > 0 && !y.wasRefunded && x === y.purchasedProductId))) {
            return true;
        }
        else
            return false;
    }

    createRruleOptions = (rruleString, startDateTime) => {
        let options = RRule.parseString(rruleString.trim());
        options.dtstart = new Date(Date.UTC(startDateTime.getFullYear(),
            startDateTime.getMonth(),
            startDateTime.getDate(),
            startDateTime.getHours(),
            startDateTime.getMinutes(),
            startDateTime.getSeconds(),
            startDateTime.getMilliseconds()));
        return options;
    }

    getEndDateAsText = (isRecurring, rruleString, startDateTime, format) => {
        if (!isRecurring)
            return null;
        else {
            const options = this.createRruleOptions(rruleString, startDateTime);
            if (options.count || options.until) {
                const dates = new RRule(options).all();
                const lastDate = dates[dates.length - 1];

                return moment(lastDate).format(format ? format : "MMM D");
            }
            return 'forever';
        }
    }

    getEndDate = (isRecurring, rruleString, startDateTime) => {
        if (!isRecurring)
            return startDateTime;
        else {
            const options = this.createRruleOptions(rruleString, startDateTime);
            if (options.count || options.until) {
                const dates = new RRule(options).all();

                const lastDate = dates[dates.length - 1];
                return lastDate;
            }
            else {
                const endBound = new Date((new Date()).setMonth(new Date().getMonth() + 24));
                const dates = new RRule(options).between(startDateTime, endBound);
                const lastDate = dates[dates.length - 1];
                return lastDate;
            }
        }
    }

    getInstanceDates = (isRecurring, rruleString) => {
        if (!isRecurring)
            return [];
        else {
            const options = this.createRruleOptions(rruleString, this.startDateTime);
            if (options.count || options.until) {
                const dates = new RRule(options).all();
                return dates;
            }
            else {
                const endBound = new Date((new Date()).setMonth(new Date().getMonth() + 6));
                const dates = new RRule(options).between(this.startDateTime, endBound);
                return dates;
            }
        }
    }

    getProratePrice = (isRecurring) => {
        if (!isRecurring)
            return this.price;

        const allDates = this.getInstanceDates(isRecurring, this.rrule);
        const utcNow = Date.now();
        let future = [];
        for (const d in allDates) {
            if (allDates[d] > utcNow) {
                future = allDates.slice(d);
                break;
            }
        }
        return this.seriesPrice * future.length / allDates.length;
    }

    spacesRemaining = (activity, nonExpandedActivity) => {
        if (nonExpandedActivity)
            return this.totalSpaces - this.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && a.attendAllInstances).length;
        else{
            return this.totalSpaces - this.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && this.isInActivityInstance(a, activity)).length;}
    }

}

export default Activity;