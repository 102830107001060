import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { withStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    CircularProgress as MuiCircularProgress,
    Menu,
    MenuItem as MuiMenuItem,
    IconButton as MuiIconButton,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoEllipsisHorizontal as Ellipsis,
    IoPencil as Pencil,
    IoCloseCircleOutline as CircleTimes,
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
import { formatImage } from '../../../helpers/imageHelpers';
// import { CLOUDINARY_CLOUD_NAME } from '../../../constants/NetworkConstants';
import { deleteLocation } from '../../../store/actions/locationActions';
import LocationForm from './LocationForm';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const IconButton = styled(MuiIconButton)`
    padding: 0;

    &:hover {
        background-color: transparent;
        
        svg {
            fill: ${(props) => props.theme.palette.primary.main};
        }
    }
`;
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 0;

    &:last-child {
        border: none;
    }
`;

const CardContent = styled(MuiCardContent)`
    background-color: transparent;
    padding: 14px 14px 15px 14px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LocationImage = styled(Box)`
    width: 45px;
    height: 45px;
    border-radius: 999999px;
    margin-right: 15px;
`;

const LocAddress1 = styled(Typography)`
    color: ${(props) => props.theme.palette.text.secondary};

    ${(props) => props.theme.breakpoints.up("md")} {
        display: inline-block;
    }
`;

const LocAddress2 = styled(Typography)`
    color: ${(props) => props.theme.palette.text.secondary};

    ${(props) => props.theme.breakpoints.up("md")} {
        display: inline-block;
    }
`;

const MenuItem = styled(MuiMenuItem)`
    padding: 8px 15px 9px 15px;
`;

const StyledMenu = withStyles((theme) => ({
    paper: {
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 0 7px 0',
        left: '0',
    }
}))(((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
)));


const LocationCard = (props) => {
    const [error, setError] = useState(null)
    const company = useSelector(state => state.company.company);
    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowEdit = currentUser && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_LOCATION) ? true : false;
    const allowDelete = currentUser && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_LOCATION) ? true : false;

    const [anchorMenu, setAnchorMenu] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const theme = useTheme();

    const dispatch = useDispatch();

    const locationDelete = useCallback(async (locationId, companyId) => {
        try {
            setIsDeleting(true);
            await dispatch(deleteLocation(locationId, companyId));
        } catch (error) {
            setIsDeleting(false);
            setError(error.message)
        }
    }, [dispatch]);

    // const handleEdit = () => {
    //     if (props.editClicked)
    //         props.editClicked(props.location);
    // }

    const handleDelete = () => {
        locationDelete(props.location.id, company.id)
    }

    return (
        <React.Fragment>
            {props.location && <Card>
                <CardContent >
                    {error && <Alert mb={2} severity="error">
                        {error}
                    </Alert>}
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {props.location.image &&
                            <LocationImage style={{ backgroundImage: 'url(' + formatImage(props.location.image, null, '.webp') + ')', backgroundRepeat: 'no-repeat', backgroundPosition:'center center',  backgroundSize: 'cover' }} />
                        }
                        <Box>
                            <Typography component="h2" variant="body1" style={{ fontWeight: 700 }}>{props.location.name}</Typography>
                            {props.location.address && <LocAddress1 component="p" variant="subtitle1">{props.location.address},&nbsp;</LocAddress1>}
                            <LocAddress2 component="p" variant="subtitle1">{props.location.city} {props.location.state} {props.location.zip}</LocAddress2>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {!isDeleting && (allowEdit || allowDelete) &&
                            <React.Fragment>
                                <IconButton onClick={event => setAnchorMenu(event.currentTarget)}>
                                    <Ellipsis color={theme.palette.iconColor.color} size="24" />
                                </IconButton>
                                <StyledMenu
                                    id="menu-appbar"
                                    anchorEl={anchorMenu}
                                    open={Boolean(anchorMenu)}
                                    onClose={() => setAnchorMenu(null)}
                                    onClick={() => setAnchorMenu(null)}
                                >
                                    {allowEdit && <MenuItem onClick={() => setIsEditing(true)}><Pencil fontSize="24" style={{ color: theme.palette.secondary.main, marginRight: '5px' }} /> Edit Location</MenuItem>}
                                    {allowDelete && <MenuItem onClick={handleDelete}><CircleTimes fontSize="24" style={{ color: theme.palette.error.main, marginRight: '5px' }} />Delete Location</MenuItem>}
                                </StyledMenu>
                            </React.Fragment>
                        }
                        {isDeleting &&
                            <CircularProgress color="primary" size={24} />
                        }
                    </Box>
                </CardContent>
            </Card>
            }
            <LocationForm
                isOpen={isEditing}
                setIsOpen={setIsEditing}
                location={props.location}
                company={company}
                showInDialog
            />
        </React.Fragment>
    );
}

export default LocationCard;