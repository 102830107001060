const LOCAL_SERVER = "http://localhost:5000";
const RAB_TEST_SERVER = "https://rabtestapi.svxvy.com";
const RAB_SERVER = "https://rabapi.svxvy.com";
const TEST_SERVER = "https://testapi.svxvy.com";
const PROD_SERVER = "https://api.svxvy.com";

const LOCA_WEB_SERVER = "http://localhost:3000/";
const RAB_TEST_WEB_SERVER = "https://rabtestmanage.smartcoreapp.com/";
const TEST_WEB_SERVER = "https://testmanage.smartcore.life/";
const PROD_WEB_SERVER = "https://manage.smartcore.life/";

export const getApiUrl = () => {
  if (window.location.hostname.endsWith('rabtestmanage.smartcoreapp.com')) {
    return RAB_TEST_SERVER;
  }

  if (window.location.hostname.endsWith('rab.smartcoreapp.com')) {
    return RAB_SERVER;
  }

  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net'))
    return TEST_SERVER;
  if (process.env.NODE_ENV === 'production')
    return PROD_SERVER;
  else if (window.location.hostname === 'localhost')
    return LOCAL_SERVER;

  return TEST_SERVER;
}
export const getWebUrl = () => {
  if (window.location.hostname.endsWith('rabtestmanage.smartcoreapp.com'))
    return RAB_TEST_WEB_SERVER;
  if (window.location.hostname.endsWith('rab.smartcoreapp.com')) {
    return RAB_SERVER;
  }
  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net'))
    return TEST_WEB_SERVER;
  if (process.env.NODE_ENV === 'production')
    return PROD_WEB_SERVER;
  else if (window.location.hostname === 'localhost')
    return LOCA_WEB_SERVER;

  return LOCA_WEB_SERVER;
}

export const getWebPrefix = () => {
  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net'))
    return 'https://';
  if (process.env.NODE_ENV === 'production')
    return 'https://www.';
  else if (window.location.hostname === 'localhost')
    return 'http://';

  return 'http://';
}

//build test

export const SERVER = getApiUrl();
export const ID_RETRY_TIMES = 3;

export const LOCATION_KEY_VIOLATION = "UNIQUE constraint failed: Locations.Id";


export const LOCAL_SERVER_STRIPE_KEY = 'pk_test_51Ji32zDogPwx59z3JbdPItzYjUsXEDx9hrT9S660hHh0RHKjhILH5iTmDrwtKxQM9nh9xZDdysSPImXf2bthv0R70097w5xOJ0';
export const TEST_SERVER_STRIPE_KEY = 'pk_test_51IOoNxHMTIRv3z2zKHeXQkYn1aQE2Zzas7bOIOQaZiR8g2pltPHjuuouzLWeo8npFGQASt5C5jcU1hUpjsUspdsO00HYZj2Vg3';
export const PROD_SERVER_STRIPE_KEY = 'pk_live_51IOoNxHMTIRv3z2z8iyMVF4hu60W1iCGAbpZUGRMvlRS9lImE0Rj0UKxo2gXJC6yGboUxXY5slfi9Noh9EEwB8HG00n8El79jj';

export const TEST_SERVER_RAB_STRIPE_KEY = 'pk_test_51OIFvmHsj7Y3V6SmG2et1fWr6CC1tXMyStvlIVSOV5suOb96FB6uchANmGPTEyduI8rfuV1iUEMQlyAhfRKAxh9500aRcYEakM';
export const PROD_SERVER_RAB_STRIPE_KEY = 'pk_live_51OIFvmHsj7Y3V6Sm6U6PUVBzDv8oZ3POeWgb1BeE1McFJAUv3HlqZI94HmSfvw89A1wsUgrowbsgbznAELfEUSFd00vOuzq7ss';

export const TEST_CLOUDINDAY_KEY = { cloudName: 'svxvy-test', uploadPreset: 'smartcore-dev' };
export const prod_CLOUDINDAY_KEY = { cloudName: 'svxvy', uploadPreset: 'smartcore' };
export const prod_RAB_CLOUDINDAY_KEY = { cloudName: 'rabsmartcore', uploadPreset: 'smartcore' };

export const getCloudinaryKeys = () => {
  if (window.location.hostname.endsWith('rab.smartcoreapp.com'))
    return prod_RAB_CLOUDINDAY_KEY;
  if (window.location.hostname.endsWith('rabtestmanage.smartcoreapp.com'))
    return TEST_CLOUDINDAY_KEY;

  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net'))
    return TEST_CLOUDINDAY_KEY;
  if (process.env.NODE_ENV === 'production')
    return prod_CLOUDINDAY_KEY;

  return TEST_CLOUDINDAY_KEY;
}

export const getStripePublicKey = () => {
  if (window.location.hostname.endsWith('rabtestmanage.smartcoreapp.com'))
    return TEST_SERVER_RAB_STRIPE_KEY;
  if (window.location.hostname.endsWith('rab.smartcoreapp.com')) {
    return PROD_SERVER_RAB_STRIPE_KEY;
  }
  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net'))
    return TEST_SERVER_STRIPE_KEY;
  if (process.env.NODE_ENV === 'production')
    return PROD_SERVER_STRIPE_KEY;

  return LOCAL_SERVER_STRIPE_KEY;
}

export const isRABSite = (company) => {
  if (window.location.hostname.endsWith('rab.smartcoreapp.com')
    || window.location.hostname.endsWith('rabtestmanage.smartcoreapp.com'))
    return true;
  else if (company && company.isRABSite)
    return true;
  else
    return false;
}

export const isSvxvySite = () => {
  if (window.location.hostname.endsWith('smartcore.life')
    || window.location.hostname.endsWith('smartcoreapp.com')
    || window.location.hostname.endsWith('svxvy.com')
    || window.location.hostname.endsWith('.azurewebsites.net')
    || window.location.hostname.endsWith('localhost')
  )
    return true;
  else
    return false;
}

export const CLOUDINARY_CLOUD_NAME = 'svxvy';

export const isLiveSite = () => {
  if (process.env.NODE_ENV === 'production'
    && !(window.location.hostname === 'svxvy-test-web.azurewebsites.net'
      || window.location.hostname === 'testmanage.smartcore.life'
      || window.location.hostname === 'rabtestmanage.smartcoreapp.com'
      || window.location.hostname === 'testmanage.svxvy.com'
      || window.location.hostname.endsWith('test.svxvy.com')))
    return true;
  return false;
}

const GOOGLE_CLIENT_ID_DEV = '1065741426322-agqmj8is5tp3pjqnhplcu025813dfv0j.apps.googleusercontent.com';
const GOOGLE_CLIENT_ID_QA = '1065741426322-1jj77ca2epdkgpmk92b55em6jt1el78v.apps.googleusercontent.com';
const GOOGLE_CLIENT_ID_PROD = '11089523943-uhj5pl3f9mogghlh292l2r1vaqhvtp8i.apps.googleusercontent.com';

export const getGoogleClientIDKey = (company) => {
  if (window.location.hostname.endsWith('svxvy-test-web.azurewebsites.net')
    || window.location.hostname.endsWith('testmanage.smartcore.life')
    || window.location.hostname.endsWith('testmanage.svxvy.com')
    || window.location.hostname.endsWith('test.svxvy.com')
    || window.location.hostname.endsWith('test.azurefd.net')) {
    return GOOGLE_CLIENT_ID_QA;
  }
  if (process.env.NODE_ENV === 'production') {
    if (window.location.hostname.endsWith('manage.smartcore.life'))
      return GOOGLE_CLIENT_ID_PROD;
    else
      if (company)
        return company.googleClientId;
      else
        return null; // client key should be returned by company
  }
  else if (window.location.hostname.endsWith('localhost') && company && company.googleClientId)
    return GOOGLE_CLIENT_ID_DEV;

  else if (company && company.googleClientId)
    return company.googleClientId
  return GOOGLE_CLIENT_ID_DEV;
}



export const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest", "https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly'


