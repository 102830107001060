import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { spacing } from "@material-ui/system";
import styled, { withTheme } from "styled-components/macro";

import {
  AppBar as MuiAppBar,
  Box,
  Button as MuiButton,
  // CircularProgress as MuiCircularProgress,
  Grid,
  Hidden,
  IconButton,
  ListItem,
  Toolbar,
  Typography as MuiTypography
} from "@material-ui/core";

import {
  IoMenuOutline as MenuIcon,
} from "react-icons/io5";

/********************** svxvy import ***********************/

import * as NavigationConstants from '../../constants/NavigationConstants';
import * as SecurityContants from '../../constants/SecurityConstants';
import { isSvxvySite } from '../../constants/NetworkConstants';
import { formatImage } from '../../helpers/imageHelpers';
import { formatPhoneNumber, checkClaim } from '../../helpers/helperFunctions';
import { replaceDisplayName } from '../../helpers/navigationFunctions';
import { DEFAULT_COMPANY_LOGO } from '../../models/Company';
import { companyPublicRoutes as routes } from "../../routes/indexSvxvy";
import ActionButton from '../framework/ActionButton';
import Sidebar from "./CompanyWebMobileSidebar";


/********************** design items ***********************/


// const CircularProgress = styled(MuiCircularProgress)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AppBar = styled(MuiAppBar)`
  // background-color: transparent;
  color: ${(props) => props.theme.header.color};
`;

const UtilityBar = styled(Toolbar)`
  height: 35px;
  min-height: 0;
  background-color:  ${(props) => props.theme.palette.background.default};
  padding: 0 30px;
  justify-content: center;
`;

const UtilityBarGrid = styled(Grid)`
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: space-between;
  }
`;

const MenuBar = styled(Toolbar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  padding: 20px 30px;
  justify-content: center;
`;

const CompanyName = styled(ListItem)`
  color: ${(props) => props.theme.header.color};
  padding: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.sidebar.category.svg.colorActive};
  }
`;

const MenuBarItem = styled(ListItem)`
  color: ${(props) => props.theme.header.color};
  margin-right: 60px;
  padding: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.palette.primary.main};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-right: 20px;
  }
`;

const UtilityBarItem = styled(ListItem)`
  color: ${(props) => props.theme.palette.text.primary};
  padding: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: 14px;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ImageBox = styled(Box)`
  height: auto
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 0;
`;

const Image = styled.img`
  min-width: 40px;
  // max-width: calc(100vw - 120px);
  // max-height: 100px;

  // ${(props) => props.theme.breakpoints.up("565")} {
  //   max-width: 450px;
  // }
  // ${(props) => props.theme.breakpoints.down("900")} {
  //   max-height: 50px;
  // }
`;


export const hideWebPage = (webPages, pageId, isOwner) => {
  const page = webPages.find(x => x.pageId === pageId.toLowerCase());
  if (page && page.hidePage && !isOwner)
    return true;
  return false;
}

export const formatImageLocal = (url, size, format) => {
  if (!url) return "";
  const scale = '' // size ? "h_" + size + ",c_scale/" : "/";
  const scaleImage = url.replace("/upload", "/upload/" + scale);
  const changeFormat = [
    scaleImage.slice(0, scaleImage.lastIndexOf(".")),
    format,
  ].join("");

  return changeFormat;
};


const CompanyWebHeader = (props) => {
  const { company, loggedInUser, theme } = props; // onDrawerToggle

  const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);
  const webPages = useSelector(state => state.company.webPages);

  const claims = useSelector(state => state.auth.claims);
  const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);

  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <UtilityBar>
          <Box maxWidth={1440} display="flex" flex={1}>
            <UtilityBarGrid container>
              <Grid item>
                {company.phoneNumber &&
                  <UtilityBarItem>

                    <a href={"tel:" + company.phoneNumber} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                      Give us a call {company.allowTexts ? 'or text ' : ''} {formatPhoneNumber(company.phoneNumber)}
                    </a>
                  </UtilityBarItem>
                }
              </Grid>
              <Hidden smDown >
                <Grid item style={{ display: 'flex' }}>
                  {company && routes && routes.filter(x => !x.isPrimaryPage && !x.hideInNavigation && !hideWebPage(webPages, x.id, isOwner)).map((category, index) => {
                    const page = webPages.find(x => x.pageId === category.id.toLowerCase())
                    return (
                      <UtilityBarItem
                        key={category.id}
                        to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                        activeClassName="active"
                        component={NavLink}
                        exact>
                        {page && page.menuText && page.menuText.trim().length > 0 ? page.menuText : replaceDisplayName(category.id, company)}
                      </UtilityBarItem>
                    );
                  })}
                </Grid>
              </Hidden>
            </UtilityBarGrid>
          </Box>
        </UtilityBar>
        <MenuBar>
          <Box maxWidth={1440} display="flex" flex={1}>
            <Grid container alignItems="center" justifyContent="space-between" >
              {company &&
                <Grid item>
                  <CompanyName
                    component={NavLink}
                    to={isSvxvySite() ? "/" + company.sanitizedName : "/"}
                    exact
                  >
                    {company.logoPhoto && company.logoPhoto !== DEFAULT_COMPANY_LOGO &&
                      <ImageBox>
                        <Image
                          src={formatImage(company.logoPhoto.url, null, '.webp')}
                          width={company.logoPhoto && company.logoPhoto.width && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? (400 * company.logoPhoto.scalingFactor / 100) : 'auto'}
                          height={company.logoPhoto && company.logoPhoto.height && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? 'auto' : company.logoPhoto.height > 100 ? (100 * (company.logoPhoto.scalingFactor * 0.01)) : (company.logoPhoto.height * (company.logoPhoto.scalingFactor * 0.01))}

                        // needed for horizontal image
                        // width={company.logoPhoto && company.logoPhoto.width && (company.logoPhoto.height / company.logoPhoto.width > 1.8) ? (company.logoPhoto.width * (company.logoPhoto.scalingFactor*0.01) + 'px') : (company.logoPhoto.width * (company.logoPhoto.scalingFactor*0.01)) + 'px'}
                        // needed for vertical image
                        // height={company.logoPhoto && company.logoPhoto.height && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? (company.logoPhoto.height * (company.logoPhoto.scalingFactor*0.01) + 'px') : (company.logoPhoto.height * (company.logoPhoto.scalingFactor*0.01)) + 'px'}
                        />
                      </ImageBox>
                    }
                    {!company.logoPhoto && <Typography variant="h2" mt={4}>{company.name}</Typography>}
                  </CompanyName>
                </Grid>
              }
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    aria-label="Open drawer"
                    onClick={() => setMobileOpen(true)}
                    style={{ marginRight: '-4px', padding: '0' }}
                  >
                    <MenuIcon size={32} color={theme.header.utilityNav.color} />
                  </IconButton>
                </Grid>
              </Hidden>
              <Hidden smDown >
                <Grid item>
                  <Grid container style={{ alignItems: 'center' }}>
                    {company && routes && routes.filter(x => x.isPrimaryPage && !x.hideInNavigation && !hideWebPage(webPages, x.id, isOwner)).map((category, index) => {
                      const page = webPages.find(x => x.pageId === category.id.toLowerCase())

                      return (
                        <Grid item key={category.id}>
                          <MenuBarItem
                            to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                            activeClassName="active"
                            component={NavLink}
                            exact
                          >
                            {page && page.menuText && page.menuText.trim().length > 0 ? page.menuText : replaceDisplayName(category.id, company)}
                          </MenuBarItem>
                        </Grid>
                      )
                    })}
                    {didTryAutoLogin && loggedInUser && company && loggedInUser.companyId === company.id &&
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          to={
                            isSvxvySite()
                              ? "/" + company.sanitizedName + "/" + NavigationConstants.DASHBOARD
                              : "/" + NavigationConstants.DASHBOARD}
                          component={NavLink}
                        >
                          My Portal
                        </Button>
                      </Grid>
                    }
                    {company && (!loggedInUser || loggedInUser.companyId !== company.id) &&
                      <Grid item>
                        <ActionButton
                          variant="contained"
                          color="primary"
                          size="small"
                          to={
                            isSvxvySite()
                              ? "/" + company.sanitizedName + "/" + NavigationConstants.LOGIN
                              : "/" + NavigationConstants.LOGIN}
                          component={NavLink}
                          disabled={didTryAutoLogin === null ? true : false}
                          submitting={didTryAutoLogin === null ? true : false}
                        >
                          Sign In / Join
                        </ActionButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </MenuBar>
      </AppBar>
      <Hidden mdUp implementation="js">
        <Sidebar
          company={company}
          PaperProps={{ style: { width: '100%', marginTop: '35px', height: 'calc(100% - 35px)' } }}
          variant="temporary"
          open={mobileOpen}
          anchor='right'
          setMobileOpen={setMobileOpen}
          isOwner={isOwner}
        />
      </Hidden>
    </React.Fragment >
  )
};

export default withTheme(CompanyWebHeader);
