import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

import i18n from 'i18n-js';
import moment from "moment";

import { Formik } from "formik";

import { useTheme, withStyles } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Button as MuiButton,
    Box,
    CircularProgress as MuiCircularProgress,
    Grid as MuiGrid,
    ListItem as MuiListItem,
    Menu,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    // IoCloseCircleOutline as DeactivateIcon,
    IoDocumentTextOutline as InvoiceIcon,
    IoEllipsisHorizontal as MuiMenuIcon,
    IoPencilOutline as EditIcon,
    IoRemoveCircleOutline as VoidIcon,
    IoFileTrayFullOutline as TermsIcon,
} from "react-icons/io5";

// svxvy imports
import { revertVoidToUnpaid, updateComment } from '../../../store/actions/paymentActions';
import { daysSinceDate } from '../../../helpers/commerceFunctions';
import { checkClaim } from "../../../helpers/helperFunctions";
import { VOIDED } from "../../../models/Payment";
import * as SecurityConstants from '../../../constants/SecurityConstants';
import ActionButton from "../../framework/ActionButton";
import RefundRequestModal from '../purchases/RefundRequestModal';
import InvoiceCard from './InvoiceCard';
import StyledDialog from '../../framework/StyledDialog';
import ParagraphTypography from '../../framework/ParagraphTypography';

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ListItem = styled(MuiListItem)`
    padding: 8px 15px 9px 15px;
`;

const MenuIcon = styled(MuiMenuIcon)`
    color: ${(props) => props.theme.palette.iconColor.color};

    &:hover {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const StyledMenu = withStyles((theme) => ({
    paper: {
        marginTop: '19px !important',
        marginRight: '15px !important',
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 0 5px 0',
    }
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const ICON_SIZE = 24;

const PurchaseListItem = (props) => {
    const {purchase, displayedUser} = props;
    const theme = useTheme();

    const company = useSelector(state => state.company.company);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const loggedInUser = useSelector(state => state.auth.person);
    const loggedInUserClaims = useSelector(state => state.auth.claims);

    const viewReports = loggedInUser && checkClaim(loggedInUserClaims, SecurityConstants.VIEW_REPORTS) ? true : false;


    const [showTerms, setShowTerms] = useState(false);
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [refundRequestModalOpen, setRefundRequestModalOpen] = useState(false);

    const paymentStatus = purchase.userPayment && purchase.userPayment.paymentStatus ? purchase.userPayment.paymentStatus : '';

    const [isProcessing, setIsProcessing] = useState(false);
    // const [wasUpdateSuccesful, setWasUpdateSuccesful] = useState((purchase.payment.justUpdated && purchase.payment.paymentStatus !== UNPAID_STATUS) ? (purchase.payment.paymentStatus === VOIDED ? "This payment has been voided." : "Succesful - Thanks!") : null);
    const [error, setError] = useState();
    const [commentError, setCommentError] = useState();
    const [wasMarkedUnpaid, setWasMarkedUpaid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);

    /*************************** events *******************************/
    const dispatch = useDispatch();

    const revertToUnpaidCallback = useCallback(async (companyId, purchaseId) => {
        setIsProcessing(true);
        setError(null);
        try {

            await dispatch(revertVoidToUnpaid(companyId, purchaseId));
            setWasMarkedUpaid(true);
            setIsProcessing(false);
        } catch (err) {
            setError(err.message);
            setIsProcessing(false);
        }
    }, [dispatch]);

    const updateCommentCallback = useCallback(async (companyId, purchaseId, comment, displayedUserId) => {
        setIsProcessing(true);
        setCommentError(null);
        try {

            await dispatch(updateComment(companyId, purchaseId, comment));
            setIsProcessing(false);
            setIsEditing(false);
        } catch (err) {
            setCommentError(err.message);
            setIsProcessing(false);
        }
    }, [dispatch]);


    const handleRevertToUnpaid = () => {
        revertToUnpaidCallback(company.id, purchase.id);
    }

    const handleEditComment = (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        updateCommentCallback(company.id, purchase.id, values.comment, purchase.appUserId,)
    }

    // console.log(purchase);
    return (
        <React.Fragment>

            <Box display="flex" flexDirection='column' flex={1}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" flexDirection="column">
                        <Typography component="p" style={{ marginBottom: '2px', fontWeight: '700' }}>{moment(purchase.datePurchased).format('l')} {purchase.wasRefunded ? ' - refunded' : ''}</Typography>
                        {purchase.userPayment
                            && purchase.userPayment.activityDate
                            && daysSinceDate(new Date(purchase.userPayment.activityDate), new Date(purchase.datePurchased)) > 0
                            && paymentStatus !== VOIDED
                            && !purchase.wasRefunded
                            &&
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                {daysSinceDate(new Date(purchase.userPayment.activityDate), new Date(purchase.datePurchased))} days late
                            </Typography>
                        }
                        {purchase.wasRefunded && <Typography variant="subtitle1" component="p" color="textSecondary">Refund reason: {purchase.memo ? purchase.memo : 'none provided'}</Typography>}
                        {/* {purchase.wasRefunded && <Typography variant="subtitle1" component="p">Refund reason: {purchase.memo ? purchase.memo : 'none provided' }</Typography>} */}
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box style={{ marginLeft: '20px' }}>
                            {purchase.purchasedProduct && <Typography variant="subtitle1" component="p">{purchase.purchasedProduct.name}</Typography>}
                            {purchase.userPayment && purchase.userPayment.activityName && <Typography variant="subtitle1" component="p">{purchase.userPayment.activityName} - {moment(purchase.userPayment.activityDate).format('l')}</Typography>}
                            {!purchase.purchasedProduct && purchase.userPayment && !purchase.userPayment.activityName && <Typography variant="subtitle1" component="p">{purchase.description}</Typography>}

                            {!wasMarkedUnpaid && purchase.userPayment && purchase.userPayment.comment && <ParagraphTypography variant="subtitle1" component="p">{purchase.userPayment.comment}</ParagraphTypography>}
                        </Box>
                        <Box style={{ marginLeft: '20px' }}>
                            {!wasMarkedUnpaid && <Typography variant="subtitle1" component="p">{i18n.toCurrency(purchase.amountPaid, { precision: 2, unit: '$' })}</Typography>}
                            {!wasMarkedUnpaid && purchase.userPayment && purchase.userPayment.paymentType && <Typography variant="subtitle1" component="p">{purchase.userPayment.paymentType}</Typography>}
                            {!wasMarkedUnpaid && purchase.userPayment && !purchase.userPayment.paymentType && <Typography variant="subtitle1" component="p">{purchase.userPayment.paymentStatus}</Typography>}
                            {wasMarkedUnpaid && <Typography variant="subtitle1" component="p" style={{ color: theme.palette.success.main }}>Reset to due</Typography>}
                        </Box>
                        <Box style={{ marginLeft: '20px' }}>
                            {!isProcessing && !wasMarkedUnpaid
                                &&
                                <Button
                                    id={purchase.id + "_menu_button"}
                                    aria-label="subscription menu button"
                                    onClick={(event) => setAnchorMenu(event.currentTarget)}
                                    style={{ minWidth: '0', color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: '400', marginTop: '-4px', marginLeft: '15px', padding: 0 }}>
                                    <MenuIcon size="24" />
                                </Button>
                            }
                            {isProcessing && <CircularProgress color="primary" size={20} ml={2} />}
                            <StyledMenu
                                id={purchase.id + "_menu"}
                                anchorEl={anchorMenu}
                                open={Boolean(anchorMenu)}
                                onClose={() => setAnchorMenu(null)}
                            >
                                <Box onClick={() => setAnchorMenu(null)} style={{ padding: 0 }}>
                                    {paymentStatus === VOIDED && viewReports &&
                                        <ListItem
                                            button
                                            onClick={handleRevertToUnpaid}
                                        >
                                            <VoidIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                Mark as Unpaid
                                            </Typography>
                                        </ListItem>
                                    }
                                    {viewReports && purchase.userPayment && Object.keys(purchase.userPayment).length > 0 &&
                                        <ListItem
                                            button
                                            onClick={() => setIsEditing(true)}
                                        >
                                            <EditIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                Edit Comment
                                            </Typography>
                                        </ListItem>
                                    }
                                    {purchase.purchasedProduct && purchase.purchasedProduct.terms &&
                                        <ListItem
                                            button
                                            onClick={() => setShowTerms(true)}
                                        >
                                            <TermsIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                View Terms &amp; Conditions
                                            </Typography>
                                        </ListItem>
                                    }
                                    <ListItem
                                        button
                                        onClick={() => setShowInvoiceModal(true)}
                                    >
                                        <InvoiceIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                                        <Typography variant="body2">
                                            View Invoice
                                            </Typography>
                                    </ListItem>
                                    {/* {displayedUser && viewReports && !purchase.wasRefunded &&
                                        <ListItem
                                            button
                                            onClick={() => setRefundRequestModalOpen(true)}
                                        >
                                            <DeactivateIcon size={ICON_SIZE} style={{ color: theme.palette.error.main, marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                Refund Purchase
                                            </Typography>
                                        </ListItem>
                                    } */}
                                </Box>
                            </StyledMenu>
                        </Box>
                    </Box>
                    {error &&
                        <Box display="flex" flexDirection="column">
                            <Alert severity="error" mr={20}>{error}</Alert>
                        </Box>
                    }
                </Box>
            </Box >
            {purchase.purchasedProduct && purchase.purchasedProduct.terms &&
                <StyledDialog
                    open={showTerms}
                    setOpen={setShowTerms}>
                    <ParagraphTypography>
                        {purchase.purchasedProduct.terms}
                    </ParagraphTypography>
                </StyledDialog>
            }
            {refundRequestModalOpen && <RefundRequestModal
                open={refundRequestModalOpen}
                setOpen={setRefundRequestModalOpen}
                purchase={purchase}
                company={company}
                purchaseUser={displayedUser}
                currentUser={loggedInUser} />}

            {/* edit payment */}

            {isEditing && <StyledDialog
                open={isEditing}
                setOpen={setIsEditing}
                aria-labelledby={'Edit comment'}
                primaryAction={
                    <React.Fragment>
                        {error &&
                            <Alert my={2} severity="error">
                                {error}
                            </Alert>
                        }
                        <ActionButton
                            form="editPaymentForm"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            submitting={isProcessing}
                        >
                            Save
                        </ActionButton>
                    </React.Fragment>
                }
            >
                <Grid item xs={12} mt={2}>
                    <Formik
                        initialValues={{
                            comment: purchase.userPayment ? purchase.userPayment.comment : ''
                        }}
                        onSubmit={handleEditComment}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <form id="editPaymentForm" onSubmit={handleSubmit}>
                                <TextField
                                    name="comment"
                                    label={"Comment - visible to " + CUSTOMER_TEXT.toLowerCase()}
                                    value={values.comment}
                                    error={Boolean(touched.comment && errors.comment)}
                                    helperText={touched.comment && errors.comment}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                    maxRows={3}
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                                {commentError &&
                                    <Box display="flex" flexDirection="column">
                                        <Alert severity="error" mr={20}>{commentError}</Alert>
                                    </Box>
                                }
                            </form>
                        )}
                    </Formik>
                </Grid>
            </StyledDialog>}

            {/* Invoice Modal */}
            {showInvoiceModal &&
                <StyledDialog
                    open={showInvoiceModal}
                    setOpen={setShowInvoiceModal}>
                    <InvoiceCard
                        purchase={purchase} />
                </StyledDialog>
            }
        </React.Fragment >
    );
};

export default PurchaseListItem;
