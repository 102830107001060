import { cloneDeep } from 'lodash';


export const getErrorMessagesFromResponse = (errorResData) => {
    let message = '';
    // console.log(Object.values(errorResData.errors), Array.isArray(errorResData.errors))
    if (errorResData.errors) {
        if (errorResData.errors.Status) {
            Object.keys(errorResData.errors).forEach(function (key) {
                message = message + errorResData.errors[key].join('\n') + '\n';
            });
        }
        else if (!Array.isArray(errorResData.errors))
            message = errorResData.errors.Message ? errorResData.errors.Message : Object.values(errorResData.errors);
        else {
            Object.keys(errorResData.errors).forEach(function (key) {
                if (Array.isArray(errorResData.errors[key]))
                    message = message + errorResData.errors[key].join('\n') + '\n';
                else
                    message = message + errorResData.errors[key] + '\n';
            });
        }
    }
    return message;
}

export const convertNumbersToStringsInObject = (objectToConvert) => {
    const obj = cloneDeep(objectToConvert);
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value == 'number' )
        obj[key] = value.toString();
      }
    return obj;
}