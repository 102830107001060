import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

import styled, { withTheme } from "styled-components/macro";

import {
    Avatar as MuiAvatar,
    Badge,
    Box,
    Button,
    Grid,
    ListItem,
    ListItemAvatar,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// svxvy imports
import { updateMessageStatus } from '../../../store/actions/messageActions';

//style componets
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const MessageItem = (props) => {
    const [error, setError] = useState();
    const company = useSelector(state => state.company.company);

    // const messageSpecificActivityId = props.message.activity ? (props.message.activityId + (props.message.occuranceId ? ("_" + props.message.occuranceId) : "")) : null;
    // const activitity = useSelector(state => state.activity.activities).find(x => x.id === messageSpecificActivityId);

    const dispatch = useDispatch();
    const updateStatus = useCallback(async (companyId, messageId) => {
        try {
            await dispatch(updateMessageStatus(companyId, messageId, true));
        } catch (err) {
            setError(err.message);
        }
    }, [dispatch])


    const handleMessageClicked = () => {
        updateStatus(company.id, props.id)
    }

    return (
        <ListItem divider component={props.allowUpdateReadStatus ? Button : Box} onClick={() => props.allowUpdateReadStatus ? handleMessageClicked() : {}} background="green">
            <ListItemAvatar>
                <Avatar src={props.image} alt="Avatar" />
            </ListItemAvatar>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textPrimary">{props.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography >{props.description}</Typography>
                    {/* {activitity && <Typography >On: {moment(activitity.displaybleStartDateTime).format('llll')}</Typography>} */}
                </Grid>
                {props.showDate && props.sent &&
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textPrimary">Message sent on: {moment(props.sent).format('lll')}</Typography>
                    </Grid>
                }
                {error && <Grid item xs={12}>
                    <Alert severity="error">{error} </Alert>
                </Grid>}
            </Grid>
            {!props.isRead &&
                <Badge color="secondary" variant="dot"></Badge>
            }
        </ListItem>
    );
}

export default withTheme(MessageItem);