import Payment from "./Payment";

class Purchase {
    constructor(id,
        purchasedProduct,
        datePurchased,
        startDate,
        expirationDate,
        automaticallyRenew,
        quantity,
        subscriptionId,
        invoiceId,
        paymentChargeId,
        amountPaid,
        isTentantPurchase,
        description,
        memo,
        wasMarkedUnpaid,
        wasRefunded,
        purchasedProductId,
        userPayment,
        nextAmountDue,
        invoiceUrl,
        invoicePdf,
        companyId,
        appUserId,
        units,
        tax,
        status,
        totalDiscount
    ) {
        this.id = id;
        this.subscriptionId = subscriptionId;
        this.invoiceId = invoiceId;
        this.paymentChargeId = paymentChargeId;
        this.purchasedProductId = purchasedProductId;
        this.purchasedProduct = purchasedProduct;
        this.userPayment = userPayment ?
            new Payment(
                userPayment.id,
                userPayment.appUserId,
                userPayment.userDisplayName,
                userPayment.activityId,
                userPayment.activityName,
                userPayment.activityDate,
                userPayment.occuranceId,
                userPayment.amount,
                userPayment.paymentDate,
                userPayment.paymentType,
                userPayment.paymentStatus,
                userPayment.comment,
                userPayment.allowedPackageIds,
            ) : {};
        this.datePurchased = new Date(datePurchased);
        this.startDate = new Date(startDate);
        this.expirationDate = new Date(expirationDate);
        this.automaticallyRenew = automaticallyRenew;
        this.amountPaid = amountPaid;
        this.nextAmountDue = nextAmountDue;
        this.isTentantPurchase = isTentantPurchase;
        this.description = description;//? description : (purchasedProduct.description ? purchasedProduct.description : purchasedProduct.name); // not sure why I did this originally but comment out as it was showing duplicate on Manange Subscription when description was null
        this.memo = memo;
        this.wasMarkedUnpaid = wasMarkedUnpaid;
        this.wasRefunded = wasRefunded;
        this.quantity = quantity;
        this.invoiceUrl = invoiceUrl;
        this.invoicePdf = invoicePdf;
        this.companyId = companyId;
        this.appUserId = appUserId;
        this.units = units;
        this.tax = tax;
        this.status = status;
        this.totalDiscount = totalDiscount;
    }
}

export const ACTIVE_MEMBERSHIPS_STATUSES =
    [
        'active',
        'paid',
        'trialing',
        'succeeded',
        null
    ]

export default Purchase;