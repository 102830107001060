import React, { useState } from 'react';
import { useDispatch } from "react-redux";


import { Formik } from 'formik';
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    InputAdornment,
    IconButton,
    Typography as MuiTypography,
    TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoEyeOutline as ShowPassword,
    IoEyeOffOutline as HidePassword,
} from "react-icons/io5";

/*********************** svxvy import **************************/

import GetLoginTokenForm from "./GetLoginTokenForm";
import { resetPassword } from "../../../store/actions/authActions"
import ActionButton from '../../framework/ActionButton';

/*********************** class Styles import **************************/
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const PasswordResetForm = props => {
    const { company, onSuccess, additionalComponents, email } = props;


    const [showGetToken, setShowGetToken] = useState(true);
    const [resetSubmitted, setResetSubmitted] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();


    return (
        <React.Fragment>
            {showGetToken && !resetSubmitted &&
                <GetLoginTokenForm
                    onResetSuccess={(value) => { setShowGetToken(false); setEmailOrPhone(value); }}
                    onPasswordSuccess={onSuccess}
                    company={company}
                    additionalComponents={additionalComponents}
                    email={email}
                />
            }
            {!showGetToken &&
                <React.Fragment>
                    <Box>
                        <Typography variant="h2" style={{margin: '0 0 16px 0', fontSize: '28px', lineHeight: '30px'}}>Reset Password</Typography>
                    </Box>
                    <Typography style={{margin: '0 0 26px 0', fontSize: '14px', lineHeight: '18px'}}>
                        Enter authentication code which was sent to your email or mobile phone.
                    </Typography>
                    {/* {!resetSubmitted && <Alert mb={4} severity="success">Your token has been sent.  Once you get it use it the first field below and set a new password.</Alert>} */}
                    <Formik
                        initialValues={{
                            shortToken: "",
                            newPassword: "",
                            confirmNewPassword: "",
                            submit: false,
                        }}
                        validationSchema={Yup.object().shape({
                            newPassword: Yup.string().max(255)
                                .required("Password is required")
                                .matches(
                                    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                                    "Must Contain 8 Characters, One Uppercase, One Lowercase, and a number"
                                ),
                            // confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], "Password does not match")
                            //     .required("Confirm the Password"),
                            shortToken: Yup.string().max(6, "The code is only 6 digits long").required("Enter in the 6 digit code")
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                setResetSubmitted(true);
                                const formValues = {
                                    // companyId: company.id,
                                    emailOrPhone: emailOrPhone,
                                    shortToken: "",
                                    newPassword: ""
                                }
                                formValues.shortToken = values.shortToken;
                                formValues.newPassword = values.newPassword;

                                await dispatch(resetPassword(formValues));
                                if (onSuccess)
                                    onSuccess();
                            } catch (error) {
                                const message = error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>

                                <TextField
                                    name="shortToken"
                                    variant="outlined"
                                    label="Authentication Code"
                                    value={values.shortToken}
                                    error={Boolean(touched.shortToken && errors.shortToken)}
                                    helperText={touched.shortToken && errors.shortToken}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    name="newPassword"
                                    label="New Password"
                                    value={values.newPassword}
                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                    helperText={touched.newPassword && errors.newPassword}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(prevState => !prevState)}
                                                // onMouseDown={setShowPassword(prevState => prevState)}
                                                >
                                                    {showPassword ? <ShowPassword size={24} /> : <HidePassword size={24} />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <ActionButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    submitting={isSubmitting}
                                    mt={3}
                                    mb={3}
                                >
                                    Reset Password
                                </ActionButton>
                                {errors.submit && <Alert mb={4} severity="error">
                                    {errors.submit}
                                </Alert>}
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        onClick={() => setShowGetToken(true)}
                                        color="primary"
                                        style={{margin: '12px 0 0 0', padding: 0}}
                                    >
                                        <Typography variant="body2" style={{fontSize: '14px', lineHeight: '18px'}}>Resend Authentication Code</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </React.Fragment>
            }
        </React.Fragment >
    );
}
export default PasswordResetForm;