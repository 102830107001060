import { getApiUrl } from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';
// import { ADD_ACTIVITY } from './activityActions';
// import Activity from '../../models/Activity';

export const SET_MESSAGES = 'SET_MESSAGES';
export const NEW_SENT_MESSAGES = 'NEW_SENT_MESSAGES';
export const UPDATE_MESSAGE_READ_STATUS = 'UPDATE_MESSAGE_READ_STATUS';

export const fetchMessages = (companyId, isBackground, onlyUnread) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let call = getApiUrl(getState) + '/api/messaging/' + companyId + "?onlyUnread=" + (onlyUnread ? onlyUnread : false);
        let apiError = false;
        let response = null;
        try {
            response = await fetch(call, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
        } catch (ex) {
            apiError = true;
        }

        if (!apiError) {
            if (!response.ok) {
                const errorResData = await response.json();
                if (!isBackground)
                    throw new Error(getErrorMessagesFromResponse(errorResData));
            }
            const resData = await response.json();

            //determine if I need to add any activities based on the messages
            // const allMessages = [...resData.messages, ...resData.fromMessages];
            // const activitiesToAdd = [];
            // const loadedActivities = getState().activity.activities;
            // for (const mId in allMessages) {
            //     const message = allMessages[mId];
            //     const activityId = message.activityId;
            //     if (activityId) {
            //         const rawActivityData = message.activity;
            //         const occuranceId = message.occuranceId
            //         const isRecurring = rawActivityData && (rawActivityData.rRule && rawActivityData.rRule.trim().length > 0) ? true : false;
            //         if (occuranceId || !isRecurring) {
            //             const specificActivityId = activityId + (occuranceId ? ("_" + occuranceId) : "");
            //             const existingActivity = loadedActivities.find(x => x.id === specificActivityId);
            //             const newActivityNotYetLoaded = activitiesToAdd.find(x => x.id === specificActivityId);
            //             if (!existingActivity && !newActivityNotYetLoaded) {
            //                 const activityToAdd = new Activity(specificActivityId, occuranceId);
            //                 activityToAdd.update(rawActivityData);
            //                 if (occuranceId) {
            //                     activityToAdd.startDateTime = new Date(occuranceId);
            //                     activityToAdd.displaybleStartDateTime = new Date(occuranceId);
            //                 }
            //                 if (!activityToAdd.isDeleted)
            //                     activitiesToAdd.push(activityToAdd);
            //             }
            //         }
            //     }
            // }
            // if (activitiesToAdd.length > 0)
            //     dispatch({ type: ADD_ACTIVITY, activities: activitiesToAdd });

            dispatch({ type: SET_MESSAGES, messages: resData.messages, fromMessages: resData.fromMessages, unreadMessageCount: resData.unreadMessageCount });
        }
    }
}

export const updateMessageStatus = (companyId, id, readStatus) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('Message ID is blank.');
        }
        if (!readStatus) {
            throw new Error('Status is blank.');
        }

        const response = await fetch(getApiUrl(getState) + '/api/messaging/' + companyId + "/" + id + "/Status?IsRead=" + readStatus, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: UPDATE_MESSAGE_READ_STATUS, messageId: id, readStatus: readStatus });
    };
}

export const sendMessage = (messageValues) => {
    return async (dispatch, getState) => {
        const response = await fetch(getApiUrl(getState) + '/api/messaging/sendMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                messageValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        dispatch({ type: NEW_SENT_MESSAGES, newSentMessages: resData });

    };
}

export const registerPushNotificationToken = (companyId, token, status, deviceId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }

        const response = await fetch(getApiUrl(getState) + '/api/user/addPushNotificationToken', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    'pushNotificationToken': token ? token : 'NOTSET',
                    'permission': status ? status : 'NONE',
                    'deviceId': deviceId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
    };
}
