import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

// UI Imports
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Divider as MuiDivider,
    Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as MuiAddCircle,
    IoRemoveCircleOutline as MuiMinusCircle,
} from "react-icons/io5";

// svxvy imports

// UI Consts
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AGroupHeading = styled(Box)`
    margin: 3px 0 6px 0;
    padding: 0 30px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        padding: 2px 15px 0 15px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 3px 0 12px 0;
    }
`;



const ReportGroup = props => {
    const theme = useTheme();
    const { heading, total, openByDefault } = props;
    const [showDetails, setShowDetails] = useState(openByDefault);

    return (
        <Box>
            <Divider my={2} />
            <AGroupHeading display="flex" justifyContent="space-between" alignItems="center" onClick={() => setShowDetails(prev => !prev)}>
                <Typography variant="body1" component="h2" style={{color: theme.palette.text.secondary, margin: 0, fontWeight: '700'}}>{heading}{total ? ' - ( ' + total + ' )' : ''}</Typography>
                {!showDetails && <MuiAddCircle size={24} style={{color: theme.palette.iconColor.color}} />}
                {showDetails && <MuiMinusCircle size={24} style={{color: theme.palette.iconColor.color}} />}
            </AGroupHeading>
            {showDetails &&
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            }
        </Box>
    );
}

export default ReportGroup;