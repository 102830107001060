import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Button as MuiButton,
  Box,
  Grid,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoCloudUploadOutline as UploadIcon,
  IoCloseCircleOutline as DeleteIcon,
} from "react-icons/io5";

// svxvy import
import { formatImage } from '../../../helpers/imageHelpers';
import { getCloudinaryKeys } from "../../../constants/NetworkConstants";

//constants
const Button = styled(MuiButton)(spacing);

// const Button = styled(MuiButton)`
//   // padding: 0;
//   font-size: 1.2rem;

//   svg {
//     color: ${(props) => props.theme.palette.primary.main} !important;
//   }
// `;

const keys = getCloudinaryKeys();

const UploadPhoto = (props) => {
  const { image, onSuccess, id, hideImage, buttonText, removePhoto, rowButtons } = props;
  const theme = useTheme();
  const company = useSelector(state => state.company.company);

  const checkUploadResult = (resultEvent) => {
    if (resultEvent.event === "success") {
      const result = {
        id: resultEvent.info.public_id,
        url: resultEvent.info.secure_url,
        isVideo: resultEvent.info.type === 'video' ? true : false,
        height: resultEvent.info.height,
        width: resultEvent.info.width,
      }
      if (onSuccess)
        onSuccess(result);
    }
  };

  return (
    <Box id={"imageUploaderContainer" + id}>
      <Grid container>
        {!hideImage && image &&
          <Grid item xs={6} md={8}>
            <img src={formatImage(image.url, null, '.webp')} style={{ width: '100%' }} alt="location" />
          </Grid>
        }
        <Grid item xs={hideImage ? 12 : 6} md={hideImage ? 12 : 4}>
          <Box display="flex" flex={1} justifyContent="center" flexDirection={rowButtons ? 'row' : 'column'} pl={4}>
            <Button
              style={{
                fontSize: '1.2rem',
              }}
              variant="contained"
              color="primary"
              startIcon={<UploadIcon color={theme.palette.secondary.secondary} />}
              onClick={() => {
                const widget = window.cloudinary.createUploadWidget(
                  {
                    cloudName: keys.cloudName,
                    uploadPreset: keys.uploadPreset,
                    sources: ['local', 'unsplash', 'camera', 'url', 'facebook', 'instagram'],
                    // sources: ['local', 'camera', 'facebook', 'instagram'],
                    folder: company.sanitizedName, //upload files to the specified folder
                    multiple: false,
                    cropping: true,
                    singleUploadAutoClose: false,
                    clientAllowedFormats: ["image"], //restrict uploading to image files only
                    showPoweredBy: false,
                  },
                  (error, result) => {
                    checkUploadResult(result);
                  }
                );
                widget.open()
              }
              }
            >
              {buttonText ? buttonText : 'Upload New Image'}
            </Button>
            {image && removePhoto &&
              <Button
                mt={rowButtons ? 0 : 4}
                ml={rowButtons ? 2 : 0}
                style={{
                  fontSize: '1.2rem',
                }}
                onClick={removePhoto}
                variant="outlined"
                startIcon={<DeleteIcon color={theme.palette.error.main} />}
              >
                Remove Photo
              </Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default UploadPhoto;