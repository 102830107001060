import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import i18n from 'i18n-js';
import parse from 'html-react-parser';

import * as Yup from "yup";
import { Formik } from "formik";

import { useTheme, withStyles } from '@material-ui/core/styles';
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    CircularProgress as MuiCircularProgress,
    Grid as MuiGrid,
    InputAdornment,
    ListItem,
    Menu,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoDocumentTextOutline as ClassPassIcon,
    IoCardOutline as CreditCardIcon,
    IoCashOutline as CashIcon,
    IoAlertCircleOutline as ReminderIcon,
    IoPencilOutline as EditIcon,
    IoRemoveCircleOutline as VoidIcon,
    IoEllipsisHorizontal as MenuIcon,
    IoTrash as DeleteIcon,
} from "react-icons/io5";


// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { daysSinceToday } from '../../../helpers/commerceFunctions';
import { checkClaim } from '../../../helpers/helperFunctions';
import { deleteUserPayment, updatePaymentStatus } from '../../../store/actions/paymentActions';
import { PAY_BY_PROCESSOR, PAID_OUTSIDE_APP, CASH, CREDIT_CARD, PAYMENT_CONFRIMED, VOIDED, UNPAID_STATUS, PAY_BY_PACKAGE, PACKAGE_TYPE } from '../../../models/Payment';
import MessageModal from '../messages/MessageModal';
import StyledDialog from "../../framework/StyledDialog";
import ActionButton from "../../framework/ActionButton";
import ParagraphTypography from "../../framework/ParagraphTypography";

//style componets
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ActionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justifyContent: flex-start;
`;

const StyledMenu = withStyles((theme) => ({
    paper: {
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '0',
        backgroundColor: theme.palette.background.default,
    }
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));


const ICON_SIZE = 24;

const validationSchema = Yup.object().shape({
    amount: Yup.number()
        .min(0, "Must be greater than $0")
        .required("Required"),
});

const PaymentItemSmall = (props) => {
    const theme = useTheme();
    const { company } = props;
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const people = useSelector(state => state.company.people);

    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowUpdatePayments = currentUser && checkClaim(currentUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const companyPurchases = useSelector(state => state.company.purchases);

    let person = people.find(x => x.id === props.payment.appUserId);
    //If I'm logged in as a parent profile then lookup the person in the childAccounts
    if (!person || currentUser.id === props.payment.appUserId)
        person = currentUser.id === props.payment.appUserId ? currentUser : currentUser.childProfiles.find(x => x.id === props.payment.appUserId)

    const parentAccount = person && currentUser.id === person.parentAccountId ? currentUser : people.find(x => x.id === person.parentAccountId);
    const parentAccountPurchases = parentAccount && companyPurchases.length === 0
        ? parentAccount.purchases.filter(p => p.isTentantPurchase)
        : parentAccount ? companyPurchases.filter(p => p.appUserId === parentAccount.id && p.isTentantPurchase) : [];

    /// handle package payment
    const purchases = currentUser.id === props.payment.appUserId
        ? currentUser.purchases.filter(p => p.isTentantPurchase)
        : currentUser.childProfiles && currentUser.childProfiles.find(x => x.id === props.payment.appUserId)
            ? currentUser.childProfiles.find(x => x.id === props.payment.appUserId).purchases.filter(p => p.isTentantPurchase)
            : companyPurchases.filter(p => p.appUserId === props.payment.appUserId && p.isTentantPurchase)

    // const defaultPaymentMethod = (person.id === currentUser.id || person.parentAccountId === currentUser.id)
    const parentPackages = parentAccountPurchases.filter(x => x.units > 0
        && !x.wasRefunded
        && allowUpdatePayments ? true
        : props.payment.allowedPackageIds.includes(x.purchasedProductId))

    const packagesToPayWith = [...purchases.filter(x => x.units > 0
        && !x.wasRefunded
        && allowUpdatePayments ? true
        : props.payment.allowedPackageIds.includes(x.purchasedProductId)), ...parentPackages];

    const parentPaymentMethods = parentAccount && parentAccount.paymentMethods ? parentAccount.paymentMethods.filter(x => x.companyId === company.id) : []
    const paymentMethods = [...(person.paymentMethods ? person.paymentMethods.filter(x => x.companyId === company.id && !x.isParentCard) : []), ...parentPaymentMethods];

    const [isProcessing, setIsProcessing] = useState(false);
    const [wasUpdateSuccesful, setWasUpdateSuccesful] = useState((props.payment.justUpdated && props.payment.paymentStatus !== UNPAID_STATUS) ? (props.payment.paymentStatus === VOIDED ? "This payment has been voided." : "Succesful - Thanks!") : null);
    const [error, setError] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [anchorMenu, setAnchorMenu] = useState(null);

    const dispatch = useDispatch();
    const updateStatus = useCallback(async (payment, paymentStatus, paymentType, paymentMethodId, amount, comment, isUpdatingUserPayment, packageUsed, onSuccess) => {
        setIsProcessing(true);
        setError(null);
        try {
            const requestBody = {
                companyId: company.id,
                userId: payment.appUserId,
                activityId: payment.activityId,
                occuranceId: payment.occuranceId.toString(),
                paymentStatus: paymentStatus,
                paymentType: paymentType,
                paymentMethodId: paymentMethodId,
                packageId: packageUsed ? packageUsed.id : null,
                amount: amount,
                comment: comment,
                isUpdatingUserPayment: isUpdatingUserPayment
            };

            await dispatch(updatePaymentStatus(requestBody, packageUsed));

            if ((amount === payment.amount || paymentStatus === VOIDED) && paymentStatus !== UNPAID_STATUS) {
                if (paymentStatus === VOIDED)
                    setWasUpdateSuccesful("This payment has been voided.");
                else
                    setWasUpdateSuccesful("Succesful - Thanks!")
            }
            // if (onSuccess)
            //     onSuccess();
        } catch (err) {
            setError(err.message);
        }
        setIsProcessing(false);
    }, [dispatch, company])

    const handlePayByCardClicked = (paymentMethod) => {
        if (company.passOnPaymentServiceFee && person.id === currentUser.id) {
            const r = window.confirm('You will be charged a processing fee $.30 plus 2.9% of the total.');
            if (!r) {
                return;
            }
        }
        updateStatus(props.payment, PAY_BY_PROCESSOR, CREDIT_CARD, paymentMethod.id, props.payment.amount, "Processed by " + currentUser.displayName, false, null);
    }

    const handlePayWithPackageClicked = (packageUsed) => {
        updateStatus(props.payment, PAY_BY_PACKAGE, PACKAGE_TYPE, null, props.payment.amount, "Processed by " + currentUser.displayName, false, packageUsed);
    }

    const handlePayByCashClicked = () => {
        if (allowUpdatePayments)
            updateStatus(props.payment, PAYMENT_CONFRIMED, CASH, null, props.payment.amount, "Confirmed by " + currentUser.displayName, false, null);
        else
            updateStatus(props.payment, PAID_OUTSIDE_APP, CASH, null, props.payment.amount, null, false, null);
    }

    // const handleMarkUnpaid = () => {
    //     updateStatus(props.payment, UNPAID_STATUS, null, null, props.payment.amount, null, false);
    // }

    const handlePaymentEdit = () => {
        setIsEditing(previousValue => !previousValue);
    }

    const handleSubmit = (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        updateStatus(props.payment, props.payment.paymentStatus, null, null, Number(values.amount), values.comment, true, setIsEditing(previousValue => !previousValue), null)
    }
    const handleSendMessage = () => {
        setMessageModalOpen(true);
    }

    const handleVoidPayment = () => {
        updateStatus(props.payment, VOIDED, null, null, 0, "Voided by " + currentUser.displayName, false, null);
    }

    const deletePayment = useCallback(async (companyId, userPaymentId) => {
        setAnchorMenu(null);
        setIsProcessing(true);
        setError(null);
        try {
            await dispatch(deleteUserPayment(companyId, userPaymentId));
        } catch (err) {
            setError(err.message);
        }
        setIsProcessing(false);
    }, [dispatch])

    const handleDeletePayment = () => {
        const r = window.confirm('Are you sure you want to delete this payment? ' + props.payment.id);
        if (!r) {
            return;
        }
        deletePayment(company.id, props.payment.id)
    }

    return (
        <React.Fragment>
            {/* <ListItem divider component={Box} pl="10px" pr="6px"> */}
            <Grid container>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" flex={1} justifyContent="space-between">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="body2" mr={2}>{moment(new Date(props.payment.activityDate)).format('l')}</Typography>
                                {daysSinceToday(props.payment.activityDate) > 0 &&
                                    <Typography
                                        variant="body2"
                                        mr={2}
                                        style={{ color: theme.palette.error.main }}
                                    >
                                        {daysSinceToday(props.payment.activityDate)} days late
                                    </Typography>
                                }
                                <Typography variant="body2">{props.payment.activityName}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant='body2'>{i18n.toCurrency(props.payment.amount, { precision: 2, unit: '$' })}</Typography>
                                {isProcessing && <CircularProgress color="primary" size={20} ml={2} />}
                                {!wasUpdateSuccesful && !isProcessing && (allowUpdatePayments || paymentMethods.length > 0 || packagesToPayWith.length > 0) &&
                                    <Button
                                        id={props.payment.id + "_menu_button"}
                                        aria-label="payment menu button"
                                        onClick={(event) => setAnchorMenu(event.currentTarget)}
                                        style={{ minWidth: '0', color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: 400, marginLeft: '15px', padding: 0 }}>
                                        <MenuIcon color={theme.palette.primary.main} size="24" />
                                    </Button>
                                }
                                {wasUpdateSuccesful &&
                                    <Typography variant="body2" ml={2} style={{ color: theme.palette.success.main }}>Processed</Typography>
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {props.payment.comment && <ParagraphTypography>{parse(props.payment.comment)}</ParagraphTypography>}
                    </Grid>

                    {props.payment.paymentStatus === PAID_OUTSIDE_APP && allowUpdatePayments && !isEditing &&
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textPrimary">{CUSTOMER_TEXT} paid outside of app.<br />Click the $ to confirm payment received.</Typography>
                        </Grid>}
                    {wasUpdateSuccesful &&
                        <Grid item>
                            {wasUpdateSuccesful && <Typography variant="h6" color="secondary">{wasUpdateSuccesful}</Typography>}
                        </Grid>
                    }
                    {error && <Grid item xs={12}>
                        <Alert severity="error" mr={20}>{error}</Alert>
                    </Grid>}
                </Grid>
            </Grid>
            {/* </ListItem> */}

            <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} sendInAppInitialState toUsers={[person]}
                message={'You have an oustanding bill of $' + props.payment.amount + ' from the ' + ACTIVITY_TEXT.toLowerCase() + ' ' + props.payment.activityName + ' on ' + moment(props.payment.activityDate).format('llll') + '.\n\nPlease use the website or mobile app to pay.\n\nThanks,\n\n' + currentUser.displayName}
            />

            {/* menu actions */}
            {anchorMenu &&
                <StyledMenu
                    id={props.payment.id + "_menu"}
                    anchorEl={anchorMenu}
                    open={Boolean(anchorMenu)}
                    onClose={() => setAnchorMenu(null)}
                    style={{ padding: 0 }}
                >
                    <ActionsWrapper onClick={() => setAnchorMenu(null)}>
                        {packagesToPayWith && packagesToPayWith.length > 0 &&
                            (packagesToPayWith.map((packageToShow, index) => {
                                if (packageToShow.units < 1)
                                    return <React.Fragment></React.Fragment>;
                                else
                                    return (
                                        <ListItem
                                            key={packageToShow.id}
                                            button
                                            onClick={() => handlePayWithPackageClicked(packageToShow)}
                                        >
                                            <ClassPassIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />
                                            <Typography variant="body2" ml={2}>
                                                {parentPackages.some(x => x.id === packageToShow.id) ? 'Parent Package - ' : ''}
                                                {packageToShow.purchasedProduct.name} - {packageToShow.units} remaining
                                        </Typography>
                                            {!props.payment.allowedPackageIds.includes(packageToShow.purchasedProductId) &&
                                                <Typography variant="body2" ml={1} color='error'>
                                                    - not configured for this {ACTIVITY_TEXT}
                                                </Typography>
                                            }
                                        </ListItem>
                                    )
                            }))

                        }
                        {props.payment.paymentStatus !== PAID_OUTSIDE_APP &&
                            (paymentMethods.map((paymentMethod, index) => {
                                return (
                                    <ListItem
                                        button
                                        onClick={() => handlePayByCardClicked(paymentMethod)}
                                    >
                                        <CreditCardIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />
                                        <Typography variant="body2" ml={2}>
                                            {parentPaymentMethods.some(x => x.id === paymentMethod.id) ? 'Parent Card ' : 'Credit Card '}
                                        - {paymentMethod.lastFourOnCard} {paymentMethod.isDefault ? ' - default card' : ''}
                                        </Typography>
                                    </ListItem>
                                )
                            }))
                        }
                        {allowUpdatePayments &&
                            <ListItem
                                button
                                onClick={handlePayByCashClicked}
                            >
                                <CashIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />
                                <Typography variant="body2" ml={2}>
                                    Paid Cash
                            </Typography>
                            </ListItem>
                        }
                        {/* {allowUpdatePayments && props.payment.paymentStatus === PAID_OUTSIDE_APP && <Tooltip title={"Mark as Unpaid"}>
                                            <IconButton aria-label="edit" onClick={handleMarkUnpaid}>
                                                <ExclamationCircleOutlined size={24} color="black" />
                                            </IconButton>
                                        </Tooltip>} */}
                        {allowUpdatePayments &&
                            <ListItem
                                button
                                onClick={handleSendMessage}
                            >
                                <ReminderIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />
                                <Typography variant="body2" ml={2}>
                                    Send Reminder
                            </Typography>
                            </ListItem>
                        }
                        {allowUpdatePayments &&
                            <ListItem
                                button
                                onClick={handlePaymentEdit}
                            >
                                <EditIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />
                                <Typography variant="body2" ml={2}>
                                    Edit Amount
                            </Typography>
                            </ListItem>
                        }
                        {allowUpdatePayments &&
                            <ListItem
                                button
                                onClick={handleVoidPayment}

                            >
                                <VoidIcon size={ICON_SIZE} style={{ color: theme.palette.error.main }} />
                                <Typography variant="body2" ml={2}>
                                    Void
                            </Typography>
                            </ListItem>
                        }
                    </ActionsWrapper>
                    {allowUpdatePayments &&
                        <ListItem
                            button
                            onClick={handleDeletePayment}
                        >
                            <DeleteIcon size={ICON_SIZE} style={{ color: theme.palette.error.main }} />
                            <Typography variant="body2" ml={2}>
                                Delete
                        </Typography>
                        </ListItem>
                    }
                </StyledMenu>
            }

            {/* edit payment */}

            {isEditing && <StyledDialog
                open={isEditing}
                setOpen={setIsEditing}
                aria-labelledby={'Edit pending payment for' + props.payment.activityName}
                primaryAction={
                    <React.Fragment>
                        {error &&
                            <Alert my={2} severity="error">
                                {error}
                            </Alert>
                        }
                        <ActionButton
                            form="editPaymentForm"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            submitting={isProcessing}
                        >
                            Apply
                        </ActionButton>
                    </React.Fragment>
                }
            >
                <Grid item xs={12} mt={2}>
                    <Formik
                        initialValues={{
                            amount: props.payment.amount,
                            comment: props.payment.comment ? props.payment.comment : ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <form id="editPaymentForm" onSubmit={handleSubmit}>
                                <TextField
                                    name="amount"
                                    label="Payment Amount"
                                    value={values.amount}
                                    error={Boolean(touched.amount && errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    my={2}
                                    placeholder=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                />
                                <TextField
                                    name="comment"
                                    label="Comment - will appear on invoice"
                                    value={values.comment}
                                    error={Boolean(touched.comment && errors.comment)}
                                    helperText={touched.comment && errors.comment}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                    maxRows={3}
                                    variant="outlined"
                                    my={2}
                                    placeholder="" />
                            </form>
                        )}
                    </Formik>
                </Grid>
            </StyledDialog>}
        </React.Fragment>
    );
}

export default withTheme(PaymentItemSmall);