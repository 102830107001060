import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik"
import * as Yup from "yup";
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    TextField as MuiTextField,
    Typography as MuiTypogrpahy,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import

import {editPurchasedPackage} from '../../../store/actions/paymentActions';
import StyledDialog from '../../framework/StyledDialog';
import ActionButton from "../../framework/ActionButton";


// constants

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypogrpahy)(spacing);

const validationSchema = Yup.object().shape({
    units: Yup.number().integer("Must be a whole number")
        .min(0, "Must be greater than or equal to 0"),
});

const EditPurchasedPackageModal = (props) => {
    const { open, setOpen, purchase } = props;

    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const companyId = useSelector(state => state.company.companyId);

    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues = {
        purchaseId: purchase.id,
        companyId: companyId,
        units: purchase.units,
        comment: purchase.memo
    };

    const handleClose = useCallback(() => {
        setIsSubmitting(false);
        setOpen(false);
        setError(null);
    }, [setOpen])

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (values, companyId, purchaseId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(editPurchasedPackage(companyId, purchaseId, values))
            handleClose();
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch, handleClose]);

    const handleSubmit = (values, { resetForm }) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.units = parseInt(valuesToSend.units)
        submitCallback(valuesToSend, companyId, purchase.id);
    };

    return (
        <StyledDialog
            open={open}
            setOpen={handleClose}
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        form="productform"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        my={3}
                        submitting={isSubmitting}
                    >
                        Save
                        </ActionButton>
                </React.Fragment>
            }
        >
            <Box display="flex" flexDirection="column">
                <Typography variant="h1" component="h1" mb={3}>
                    Edit Package
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setSubmitting,
                        touched,
                        values,
                        status,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            <form id="productform" onSubmit={handleSubmit}>
                                <Box>

                                    <TextField
                                        name="units"
                                        label={ACTIVITIES_TEXT + ' remaining'}
                                        value={values.units}
                                        error={Boolean(touched.units && errors.units)}
                                        helperText={touched.units && errors.units}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        placeholder=""
                                        style={{ margin: '0 15px 15px 0' }}
                                    />
                                    <TextField
                                        name="comment"
                                        label={"Comment visible to " + CUSTOMER_TEXT + " (optional)"}
                                        value={values.comment}
                                        error={Boolean(touched.comment && errors.comment)}
                                        fullWidth
                                        helperText={touched.comment && errors.comment}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        multiline
                                        maxRows={10}
                                        mb={3}
                                        placeholder="" />
                                    {error && <Alert mb={4} severity="error">
                                        {error}
                                    </Alert>}
                                </Box>
                            </form>
                        </React.Fragment>
                    )
                    }
                </Formik >
            </Box>
        </StyledDialog >
    );
}

export default EditPurchasedPackageModal;