import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Grid,
    List,
    Typography,
    CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
import { detailPerson } from '../../../store/actions/peopleActions';
import { refreshLogedInUser } from '../../../store/actions/authActions';
import { fetchPaymentMethodsForCurrentUser } from "../../../store/actions/paymentActions";
import PaymentMethodCard from "./PaymentMethodCard";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

const ListPaymentMethods = props => {
    const theme = useTheme();
    const { companyId, userId, showTenantPaymentMethods, dontRefreshLoggedInUser } = props;
    const currentUser = useSelector(state => state.auth.person);
    const personFromStore = useSelector(state => state.company.people).find(x => x.id === userId);
    const displayedUser = currentUser.id === userId ? currentUser : personFromStore ? personFromStore : currentUser.childProfiles.find(x => x.id === userId);

    const paymentMethods = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.filter(x => showTenantPaymentMethods ? x.companyId : !x.companyId) : [];

    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const loadPaymentMethods = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            if (displayedUser.id !== userId && allowAddEdit)
                await dispatch(detailPerson(userId, companyId, true));
            if (currentUser.id === userId && !dontRefreshLoggedInUser) {
                await dispatch(fetchPaymentMethodsForCurrentUser());
                await dispatch(refreshLogedInUser());
            }
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId, currentUser.id, userId, displayedUser.id, allowAddEdit, dontRefreshLoggedInUser]);

    useEffect(() => {
        loadPaymentMethods();
    }, [loadPaymentMethods]);

    return (
        <React.Fragment>
            {paymentMethods && paymentMethods.length > 0 &&
                <Grid item >

                    <Card mb={3} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                        <CardContent style={{ paddingBottom: '5px' }}>
                            <Typography variant="h3" component="h2" gutterBottom>Cards on File</Typography>
                            {isLoading && <Box display='flex' flex={1} justifyContent="center" alignItems="center" minHeight={100}><CircularProgress color="secondary" size={36} /></Box>}
                            {paymentMethods && !isLoading && <List>
                                {paymentMethods.map((paymentMethod, index) => {
                                    return (
                                        <PaymentMethodCard key={paymentMethod.id} paymentMethod={paymentMethod} companyId={props.companyId} userId={props.userId} />
                                    )
                                })}

                            </List>}
                            {error && <Alert mb={4} severity="error">
                                {error.message}
                            </Alert>}
                        </CardContent>
                    </Card>
                </Grid>
            }
        </React.Fragment>
    );
}

export default ListPaymentMethods;