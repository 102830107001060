import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as AddCircle,
    IoCloseCircleOutline as DeleteIcon,
    IoPencilOutline as PencilIcon,
    IoRepeat as RepeatIcon,
    IoCardOutline as CardIcon,
    IoMail as MailIcon,
    IoChatboxEllipses as ChatIcon,
    IoCalendarNumberOutline as AddActivityIcon,
    IoDocumentTextOutline as WaiverIcon,
    IoCartOutline as PurchaseIcon,
    IoBookOutline as RegisteredIcon,
    IoFlagOutline as InactiveIcon,
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import * as NavigationConstants from '../../../constants/NavigationConstants';

import { deletePerson, listChildProfiles, setInactive, setParentAccount, unsetParentAccount } from '../../../store/actions/peopleActions';
import { checkClaim, formatPhoneNumber } from '../../../helpers/helperFunctions';

import ActvityListModal from './ActivityListModal';
import ActivityAddEditModal from '../activities/ActivityAddEditModal';
import ActionButton from '../../framework/ActionButton';
import FileUploader from '../../framework/FileUploader';
import ManagePaymentMethods from '../paymentMethods/ManagePaymentMethods';
import MessageModal from '../messages/MessageModal';
import ParagraphTypography from '../../framework/ParagraphTypography';
import ProfileCard from './ProfileCard';
// import ProductList from '../products/ProductsList';
import ProfileEditCard from './ProfileEditCard';
import ProfileIcon from './ProfileIcon';
import SubscriptionList from '../purchases/SubscriptionList';
import PurchaseProductModal from "../products/PurchaseProductModal";
import StyledDialog from '../../framework/StyledDialog';
import SignedWaiverModal from './SignedWaiverModal'
import AddUser from '../AddUser';


// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.breakpoints.up("md")} {
        flex-direction: row;
        align-items: center;
    }
`;

const MoreUtilityButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
`;


const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;

    width: auto;
    min-width: 0;
    margin: 8px 0 9px 0;
    padding: 0;
    justify-content: left;
    font-weight: 400;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 35px 0 0;

        &:last-child {
            margin: 0;
        }
    }
`;

const CardContent = styled(MuiCardContent)`
    padding: 30px 30px 36px 30px !important;

    ${(props) => props.theme.breakpoints.up("md")} {
        display: flex;
        flex-direction: row;
    }
`;

const ProfileIconWrapper = styled(Box)`
    margin-bottom: 22px;
    text-align: center;

    div {
        margin: 0 auto;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        width: 220px;
        margin-bottom: 0;
        text-align: left;

        div {
            margin: 0;
        }
    }
`;

const ProfileContent = styled(Box)`
    ${(props) => props.theme.breakpoints.up("md")} {
        width: calc(100% - 220px);
    }
`;

const ProfileIntro = styled(Box)`
    text-align: center;

    ${(props) => props.theme.breakpoints.up("md")} {
        text-align: left;
    }
`;

const ContactInfoWrapper = styled(Box)`
    ${(props) => props.theme.breakpoints.up("md")} {
        display: flex;
        flexDirection: row;
    }
`;

const ContactInfo = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 12px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        &:first-child {
            margin-right: 60px;
            // margin-bottom: 0;
        }
    }
`;

const AddMember = styled(ActionButton)`
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 8px 45px 8px 45px;
    white-space: nowrap;
    position: relative;
    top: auto;
    right: auto;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 8px 25px 8px 25px;
  }
`;

const ProfileDetailCard = (props) => {
    const { person, open, setOpen, hideCreateClass, isChildProfile } = props;
    const theme = useTheme();

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const company = useSelector(state => state.company.company);
    const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey);

    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const parentAccount = useSelector(state => state.company.people).find(x => x.id === person.parentAccountId);
    const allowEdit = currentUser && (currentUser.id === person.id || currentUser.id === person.parentAccountId || checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE)) ? true : false;
    const allowDelete = currentUser && (currentUser.id === person.parentAccountId || (currentUser.id !== person.id && checkClaim(currentUserClaims, SecurityConstants.ALLOW_DELETE_PEOPLE))) ? true : false;
    const allowAddEditActivities = currentUser && (currentUser.id !== person.id && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES)) ? true : false;
    const viewAllPeople = currentUser && (currentUser.id !== person.id && checkClaim(currentUserClaims, SecurityConstants.VIEW_ALL_PEOPLE)) ? true : false;
    const allowMessageToAll = currentUser && (currentUser.id !== person.id && checkClaim(currentUserClaims, SecurityConstants.ALLOW_MESSAGE_TO_ALL)) ? true : false;

    const [isEditing, setIsEditing] = useState(false);
    const [isMessaging, setIsMessaging] = useState(false);
    const [isMessagingRegister, setIsMessagingRegister] = useState(false);
    const [isEditingSubscriptions, setIsEditingSubscriptions] = useState(false);
    const [isEditingPaymentMethods, setIsEditingPaymentMethods] = useState(false);
    const [isAddingActivity, setIsAddingActivity] = useState(false);
    const [useCustomerAddress, setUseCustomerAddress] = useState(false);
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [addingNewPerson, setAddingNewPerson] = useState(false);
    const [selectParentAccount, setSelectParentAccount] = useState(false);
    const [isWaiverModalOpen, setIsWaiverModalOpen] = useState(false);
    const [isAddingMembership, setIsAddingMembership] = useState(false);
    const [modifyingParent, setModifyingParent] = useState(false);
    const [isActivityListModalOpen, setIsActivityListModalOpen] = useState(false);

    /***************************** actions *****************************/
    const dispatch = useDispatch();
    const profileDelete = useCallback(async (personId, companyId, childAccountByOwner, parentAccountId) => {
        try {
            setIsDeleting(true);
            await dispatch(deletePerson(personId, companyId, childAccountByOwner));
            if (parentAccountId)
                await dispatch(listChildProfiles(parentAccountId, companyId));
            setOpen(false)
        } catch (error) {
            setIsDeleting(false);
            setError(error.message);
        }
    }, [dispatch, setOpen]);

    const handleDelete = () => {
        profileDelete(person.id, company.id, currentUser.id === person.parentAccountId ? true : false, person.parentAccountId)
    }

    const setOrUsetParent = useCallback(async (personId, companyId, parentAccountId, isSetParentAccount) => {
        try {
            setError(null);
            setModifyingParent(true);
            const values = {
                parentAccountId: parentAccountId,
            }
            if (isSetParentAccount)
                await dispatch(setParentAccount(personId, companyId, values));
            else
                await dispatch(unsetParentAccount(personId, companyId, values));

        } catch (error) {
            setError(error.message);
        }
        setModifyingParent(false);
    }, [dispatch]);

    const handleUnSetParentAccount = () => {
        setOrUsetParent(person.id, company.id, person.parentAccountId, false);
    }

    const handleSetParentAccount = (parentAccount) => {
        setOrUsetParent(person.id, company.id, parentAccount[0].id, true);
    }


    const loadChildProfiles = useCallback(async (personId, companyId) => {
        try {
            await dispatch(listChildProfiles(personId, companyId));
        } catch (error) {
            setError(error.message);
        }
    }, [dispatch]);

    useEffect(() => {
        if (viewAllPeople && !isChildProfile)
            loadChildProfiles(person.id, company.id)
    }, [viewAllPeople, isChildProfile, loadChildProfiles, person.id, company.id])


    const handleSetInactive = useCallback(async (personId, companyId) => {
        try {
            setError(null);
            setIsDeleting(true);
            await dispatch(setInactive(personId, companyId,));
        } catch (error) {
            setError(error.message);
        }
        setIsDeleting(false);
    }, [dispatch]);

    // const handleSetInactive = () => {
    //     setInactiveCallback(person.id, company.id);
    // }

    /***************************** renderings *****************************/
    return (
        <React.Fragment>


            <StyledDialog
                open={open}
                setOpen={setOpen}
                aria-labelledby="act-dialog-title"
                actions={
                    <UtilityButtonWrapper>
                        {allowEdit &&
                            <React.Fragment>
                                <UtilityButton
                                    aria-label="edit"
                                    onClick={() => setIsEditing(true)}
                                    disabled={isDeleting}
                                    startIcon={<PencilIcon color={theme.palette.primary.main} size="24" />}
                                >
                                    Edit Profile
                                </UtilityButton>
                                {paymentServicePublicKey &&
                                    <UtilityButton
                                        onClick={() => setIsEditingSubscriptions(true)}
                                        disabled={isDeleting}
                                        startIcon={<RepeatIcon color={theme.palette.primary.main} size="24" />}
                                    >
                                        Purchases
                                    </UtilityButton>
                                }
                                {paymentServicePublicKey &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<PurchaseIcon size={24} style={{ color: theme.palette.primary.main }} />}
                                        onClick={() => setIsAddingMembership(true)}>
                                        Purchase Item
                                    </UtilityButton>
                                }
                                {paymentServicePublicKey &&
                                    <UtilityButton aria-label="edit"
                                        onClick={() => setIsEditingPaymentMethods(true)}
                                        disabled={isDeleting}
                                        startIcon={<CardIcon color={theme.palette.primary.main} size="24" />}
                                    >
                                        Payment Methods
                                    </UtilityButton>
                                }
                            </React.Fragment>
                        }
                    </UtilityButtonWrapper>
                }
                moreActions={(allowDelete || (allowAddEditActivities && !hideCreateClass)) &&
                    <MoreUtilityButtonWrapper>
                        {allowAddEditActivities && !hideCreateClass &&
                            <UtilityButton aria-label={'Create' + ACTIVITY_TEXT + " for " + CUSTOMER_TEXT}
                                onClick={() => setIsAddingActivity(true)}
                                startIcon={<AddActivityIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                Create {ACTIVITY_TEXT} for {CUSTOMER_TEXT}
                            </UtilityButton>
                        }
                        {allowAddEditActivities && !hideCreateClass &&
                            <UtilityButton aria-label={'See all ' + ACTIVITIES_TEXT + " for " + CUSTOMER_TEXT}
                                onClick={() => setIsActivityListModalOpen(true)}
                                startIcon={<RegisteredIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                See All {ACTIVITIES_TEXT} for {CUSTOMER_TEXT}
                            </UtilityButton>
                        }
                        {allowAddEditActivities && person.address && company.allowManualLocations && !hideCreateClass &&
                            <UtilityButton aria-label={'Create' + ACTIVITY_TEXT + " for " + CUSTOMER_TEXT + "with their Address"}
                                onClick={() => { setUseCustomerAddress(true); setIsAddingActivity(true); }}
                                startIcon={<AddActivityIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                Create {ACTIVITY_TEXT} for {CUSTOMER_TEXT} with their Address
                            </UtilityButton>
                        }
                        {allowMessageToAll && person.email &&
                            <UtilityButton aria-label={'Create' + ACTIVITY_TEXT + " for " + CUSTOMER_TEXT + "with their Address"}
                                onClick={() => setIsMessagingRegister(true)}
                                startIcon={<MailIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                Send {person.isUserRegistered ? 'Reset Password Email' : 'Registration Email'}
                            </UtilityButton>
                        }
                        {allowAddEditActivities &&
                            <UtilityButton aria-label="Set user active"
                                onClick={() => handleSetInactive(person.id, company.id)}
                                startIcon={<InactiveIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                {person.isInactive ? 'Set Active' : 'Set Inactive'}
                            </UtilityButton>
                        }
                        {person.waiverSignedOn &&
                            <UtilityButton aria-label="Sign waiver model"
                                onClick={() => setIsWaiverModalOpen(true)}
                                startIcon={<WaiverIcon color={theme.palette.primary.main} size="24" />}
                                submitting={isDeleting}>
                                Signed Waiver
                            </UtilityButton>
                        }
                        {allowDelete &&
                            <UtilityButton aria-label="delete"
                                onClick={handleDelete}
                                startIcon={<DeleteIcon color={theme.palette.error.main} size="24" />}
                                submitting={isDeleting}>
                                Delete User
                            </UtilityButton>
                        }
                    </MoreUtilityButtonWrapper>
                }
            >
                {error && <Alert mb={4} severity="error">{error}</Alert>}
                <Card mb={3} style={{ border: '1px solid ' + theme.palette.divider }}>
                    <CardContent>
                        <ProfileIconWrapper>
                            <ProfileIcon person={person} variant="large" margin="0 auto" style={{ margin: '0 auto' }} />
                        </ProfileIconWrapper>
                        <ProfileContent>
                            <ProfileIntro>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                                    <Typography variant="h1" my={0} mb={5}>{person.displayName}</Typography>
                                    <Box display="flex" flexDirection="row">
                                        {person.memberSince &&
                                            <Box display="flex" flexDirection="column" mb={5} mr={person.birthday ? 2 : 0}>
                                                <Typography style={{ color: theme.palette.text.secondary }}>Member Since</Typography>
                                                <Typography >{moment(person.memberSince).format('ll')}</Typography>
                                            </Box>
                                        }
                                        {person.birthday &&
                                            <Box display="flex" flexDirection="column" mb={5}>
                                                <Typography style={{ color: theme.palette.text.secondary }}>Birthday</Typography>
                                                <Typography >{moment(person.birthday).format('ll')}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {person && allowAddEditActivities && <Button variant="contained" color="primary" mb={4} startIcon={<ChatIcon size="22" />} onClick={() => setIsMessaging(true)}>Message</Button>}
                                {person.email && allowAddEditActivities && <Button variant="contained" color="primary" mb={4} ml={4} startIcon={<MailIcon size="22" />} component="a" href={"mailto:" + person.email} >Mail</Button>}
                            </ProfileIntro>
                            {(person.phoneNumber || person.email || person.additionalEmailNotificaitons || person.referredBy) &&
                                <React.Fragment>
                                    <Divider mb={3} />
                                    <Box display="flex" flexDirection="column">
                                        {person.phoneNumber &&
                                            <ContactInfo>
                                                <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Phone</Typography>
                                                <a href={"tel:" + person.phoneNumber} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                                                    <Typography style={{ fontWeight: 700 }}>{formatPhoneNumber(person.phoneNumber)}</Typography>
                                                </a>
                                            </ContactInfo>
                                        }
                                        {person.email &&
                                            <ContactInfo>
                                                <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Email</Typography>
                                                <Typography style={{ fontWeight: 700 }}><a href={"mailto:" + person.email} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>{person.email}</a></Typography>
                                            </ContactInfo>
                                        }
                                        {/* {person.additionalEmailNotificaitons &&
                                            <ContactInfo>
                                                <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Additional Reminder Emails</Typography>
                                                <Box display="flex" flexDirection="column">
                                                    {person.additionalEmailNotificaitons.split(';').map(email => {
                                                        return (
                                                            < Typography style={{ fontWeight: 700 }}> <a href={"mailto:" + email} style={{ color: theme.palette.text.primary, textDecoration: 'none' }}>{email}</a></Typography>
                                                        )
                                                    })}
                                                </Box>
                                            </ContactInfo>
                                        } */}
                                        {person.referredBy &&
                                            <React.Fragment>
                                                <Box display="flex" flexDirection="column">
                                                    <ContactInfo>
                                                        <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Referred By </Typography>
                                                        <Typography style={{ fontWeight: 700 }}>{person.referredBy}</Typography>
                                                    </ContactInfo>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    </Box>
                                </React.Fragment>
                            }
                            {person.address &&
                                <ContactInfoWrapper>
                                    <ContactInfo>
                                        <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Address </Typography>
                                        <Typography style={{ fontWeight: 700 }}>{person.address}</Typography>
                                    </ContactInfo>
                                </ContactInfoWrapper>
                            }
                            <Divider mt={3} mb={2} />
                            {person.customerNotes && person.customerNotes.length > 0 &&
                                <React.Fragment>
                                    <Typography mr={2} style={{ color: theme.palette.text.secondary }}>About me:</Typography>
                                    <ParagraphTypography>{person.customerNotes}</ParagraphTypography>
                                    <Divider mt={3} mb={2} />
                                </React.Fragment>
                            }
                            {person.employeeNotes && person.employeeNotes.length > 0 &&
                                <React.Fragment>
                                    <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Employee Notes:</Typography>
                                    <ParagraphTypography>{person.employeeNotes}</ParagraphTypography>
                                    <Divider mt={3} mb={2} />
                                </React.Fragment>
                            }
                            {viewAllPeople && !isChildProfile &&
                                <React.Fragment>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Family Members:</Typography>
                                        {(!person.childProfiles || person.childProfiles.length === 0) &&
                                            <React.Fragment>
                                                <AddMember
                                                    submitting={modifyingParent}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => setSelectParentAccount(true)}
                                                    mr={2}>
                                                    Set Parent Account
                                                </AddMember>
                                            </React.Fragment>
                                        }
                                        <AddMember
                                            startIcon={<AddCircle color={theme.palette.primary.main} />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => setAddingNewPerson(true)}>
                                            Add Child Account
                                        </AddMember>
                                    </Box>
                                    {person && person.childProfiles && person.childProfiles.length > 0 &&
                                        <React.Fragment>
                                            {person.childProfiles.map((p, index) => {
                                                return (
                                                    <ProfileCard key={p.id} person={p} urlBase={NavigationConstants.CUSTOMERS} isChildProfile={true} />
                                                )
                                            })}
                                        </React.Fragment>
                                    }
                                    {(!person.childProfiles || person.childProfiles.length === 0) &&
                                        <Divider mt={3} mb={2} />
                                    }
                                </React.Fragment>
                            }
                            {viewAllPeople && isChildProfile && parentAccount &&
                                <React.Fragment>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography mr={2} style={{ color: theme.palette.text.secondary }}>Parent Account:</Typography>
                                        <ActionButton
                                            submitting={modifyingParent}
                                            variant="outlined"
                                            onClick={handleUnSetParentAccount}
                                        >
                                            Remove Parent
                                        </ActionButton>
                                    </Box>
                                    <ProfileCard key={parentAccount.id} person={parentAccount} urlBase={NavigationConstants.CUSTOMERS} isChildProfile={false} />
                                </React.Fragment>
                            }
                            <FileUploader appUser={person} isDeleting={isDeleting} />
                        </ProfileContent>
                    </CardContent>
                </Card>

            </StyledDialog >
            {/* Editing screens */}
            {
                isMessaging && <MessageModal
                    open={isMessaging}
                    setOpen={setIsMessaging}
                    sendInAppInitialState
                    toUsers={[person]}
                />
            }
            {/* Editing screens */}
            {
                isMessagingRegister && <MessageModal
                    open={isMessagingRegister}
                    setOpen={setIsMessagingRegister}
                    sendEmailInitialState
                    hideUserSelect
                    emailOnly
                    message={person.isUserRegistered
                        ? company.passwordResetMessage
                            ? company.passwordResetMessage + "\n\n{WEBSITE_URL}/register?email=" + person.email + "&showResetForm=true"
                            : "To reset your password with " + company.name + " click the link below.\n\n{WEBSITE_URL}/register?email=" + person.email + "&showResetForm=true"
                        : company.accountRegisterEmailMessage
                            ? company.accountRegisterEmailMessage + "\n\n{WEBSITE_URL}/register?email=" + person.email
                            : "You have been invited the " + company.name + " portal.\n\nTo register click the link below\n\n{WEBSITE_URL}/register?email=" + person.email}
                    toUsers={[person]}
                />
            }
            {
                isEditing && <ProfileEditCard
                    person={person}
                    open={isEditing}
                    setOpen={setIsEditing}
                />
            }
            {
                addingNewPerson &&
                <ProfileEditCard
                    open={addingNewPerson}
                    setOpen={setAddingNewPerson}
                    parentAccountId={person.id}
                />
            }
            {
                isEditingPaymentMethods && <ManagePaymentMethods
                    companyId={company.id}
                    userId={person.id}
                    showTenantPaymentMethods={paymentServicePublicKey ? true : false}
                    open={isEditingPaymentMethods}
                    setOpen={setIsEditingPaymentMethods}
                />
            }
            {
                isEditingSubscriptions && <SubscriptionList
                    companyId={company.id}
                    displayedUser={person}
                    open={isEditingSubscriptions}
                    isChildAccount={currentUser.childProfiles.some(x => x.id === person.id)}
                    setOpen={setIsEditingSubscriptions}
                />
            }
            {
                isAddingActivity && <ActivityAddEditModal
                    open={isAddingActivity}
                    editMode
                    setOpen={setIsAddingActivity}
                    companyId={company.id}
                    initialAttendees={[person]}
                    initialAddress={useCustomerAddress ? person.address : null}
                ></ActivityAddEditModal>
            }
            {
                isWaiverModalOpen &&
                <SignedWaiverModal
                    isWaiverModalOpen={isWaiverModalOpen}
                    setIsWaiverModalOpen={setIsWaiverModalOpen}
                    user={person} />
            }
            {
                isAddingMembership &&
                <PurchaseProductModal
                    open={isAddingMembership}
                    setOpen={setIsAddingMembership}
                    company={company}
                    isChildAccount={currentUser.childProfiles.some(x => x.id === person.id)}
                    displayedUser={person}
                />
            }
            {
                selectParentAccount &&
                <AddUser
                    title="Select Parent Account"
                    open={selectParentAccount}
                    setOpen={setSelectParentAccount}
                    selectText="Send parent account"
                    hideSelf
                    singleSelect
                    scrollSelectedPeople
                    hideMessageButton
                    onChange={(val) => handleSetParentAccount(val)}
                />
            }
            {
                isActivityListModalOpen &&
                <ActvityListModal
                    open={isActivityListModalOpen}
                    setOpen={setIsActivityListModalOpen}
                    displayedUser={person}
                />
            }

        </React.Fragment >
    );
}

export default ProfileDetailCard;