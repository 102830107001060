import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from "formik"
import * as Yup from "yup";
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Box,
    Button as MuiButton,
    CardContent,
    Card as MuiCard,
    Divider as MuiDivider,
    FormGroup,
    FormControlLabel,
    Grid as MuiGrid,
    MenuItem,
    TextField as MuiTextField,
    Typography as MuiTypgraphy,
    Select,
    Switch
} from "@material-ui/core";

// import { Alert as MuiAlert } from "@material-ui/lab";
import InputMask from "react-input-mask";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoChevronDown as ChevronDown,
} from "react-icons/io5";

// svxvy setting

import { editCompanyAttributes } from '../../../store/actions/companyActions';
import { SET_COMPANY_ATTIBUTES } from '../../../store/actions/registerCompanyActions';
import { timezones } from "../../../models/Location";
import ActionButton from "../../framework/ActionButton";
import ErrorDialog from "../../framework/ErrorDialog";
import SelectManageAov, { DESCRIPTION, REMINDER } from "../activities/SelectManageAov";
import SelectFormControl from "../../framework/SelectFormControl";

//constant
// const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypgraphy)(spacing);

const ActionButtonBottom = styled(ActionButton)`
    width: 100%;
    margin: 0;

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: auto;
        margin: 0 0 0 auto;
    }
`;

const TextFieldResponsive = styled(TextField)`
    margin-bottom: 5px !important;

    ${(props) => props.theme.breakpoints.up("sm")} {
        margin-bottom: 13px !important;
    }
`;

const validationSchema = Yup.object().shape({
    locationText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    locationsText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    employeeText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    employeesText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    activityText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    activitiesText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    customerText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    customersText: Yup.string()
        .required("Required")
        .min(3, 'Must be at least 3 characters.'),
    globalReminderHours: Yup.number()
        .typeError('Must be a number')
        .integer("Must be a whole number.")
        .min(0, "Must be greater than 0")
        .required("Required"),
    companyContactEmail: Yup.string().when('hideContactForm', {
        is: false,
        then: Yup.string().email('Must be a valid email').required('required')
    }),
    cancelTextMembershipText: Yup.string().when('allowCustomerCancelMembership', {
        is: false,
        then: Yup.string().required('You must provide details on how to cancel a membership.').max(200, 'Must be less than 200 characters.')
    })
});

const CompanyAttributes = props => {
    const theme = useTheme();

    // const [wasSaveSuccesful, setWasSaveSuccesful] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState();

    const company = useSelector(state => state.company.company);

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    console.log(company)

    const initialValues = {
        locationText: props.company.locationText,
        locationsText: props.company.locationsText,
        employeeText: props.company.employeeText,
        employeesText: props.company.employeesText,
        activityText: props.company.activityText,
        activitiesText: props.company.activitiesText,
        customerText: props.company.customerText,
        customersText: props.company.customersText,
        description: props.company.description ? props.company.description : '',
        isProfileNameAliasAllowed: props.company.isProfileNameAliasAllowed,
        isProfilePhoneNumberRequired: props.company.isProfilePhoneNumberRequired,
        companyContactEmail: props.company.companyContactEmail ? props.company.companyContactEmail : '',
        hideBilling: props.company.hideBilling,
        phoneNumber: props.company.phoneNumber ? props.company.phoneNumber : '',
        allowTexts: props.company.allowTexts,
        instagramLink: props.company.instagramLink ? props.company.instagramLink : '',
        facebookLink: props.company.facebookLink ? props.company.facebookLink : '',
        twitterLink: props.company.twitterLink ? props.company.twitterLink : '',
        vimeoLink: props.company.vimeoLink ? props.company.vimeoLink : '',
        youTubeLink: props.company.youTubeLink ? props.company.youTubeLink : '',
        tickTokLink: props.company.tickTokLink ? props.company.tickTokLink : '',
        embedScripts: props.company.embedScripts ? props.company.embedScripts : '',
        allowManualLocations: props.company.allowManualLocations,
        manualLocationTimezone: props.company.manualLocationTimezone,
        globalReminderMessage: props.company.globalReminderMessage ? props.company.globalReminderMessage : '',
        globalReminderHours: props.company.globalReminderHours ? props.company.globalReminderHours.toString() : '24',
        allowCustomerCancelMembership: props.company.allowCustomerCancelMembership ? props.company.allowCustomerCancelMembership : false,
        cancelTextMembershipText: props.company.cancelTextMembershipText ? props.company.cancelTextMembershipText : '',
        waiverText: props.company.waiverText ? props.company.waiverText : '',
        manuallySortProducts: props.company.manuallySortProducts ? props.company.manuallySortProducts : false,
        hideDroppedActivityMessage: props.company.hideDroppedActivityMessage ? props.company.hideDroppedActivityMessage : false,
        dropActivityWarningMessage: props.company.dropActivityWarningMessage ? props.company.dropActivityWarningMessage : '',
        preDeadlineDropActivityMessage: props.company.preDeadlineDropActivityMessage ? props.company.preDeadlineDropActivityMessage : '',
        postDeadlineDropActivityMessage: props.company.postDeadlineDropActivityMessage ? props.company.postDeadlineDropActivityMessage : '',
        noDropActivityMessage: props.company.noDropActivityMessage ? props.company.noDropActivityMessage : '',
        passwordResetMessage: props.company.passwordResetMessage ? props.company.passwordResetMessage : '',
        accountRegisterEmailMessage: props.company.accountRegisterEmailMessage ? props.company.accountRegisterEmailMessage : '',
        activityRegisterEmailMessage: props.company.activityRegisterEmailMessage ? props.company.activityRegisterEmailMessage : '',
        activityDroppedEmailMessage: props.company.activityDroppedEmailMessage ? props.company.activityDroppedEmailMessage : '',
        hideFullSelfRegisterActivities: props.company.hideFullSelfRegisterActivities ? props.company.hideFullSelfRegisterActivities : false,
        purchaseNotificationEmails: props.company.purchaseNotificationEmails,
    };

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values, isRegistration, handleOnSuccess) => {
        setError(null);
        setIsSubmitting(true);
        // setWasSaveSuccesful(false);
        try {
            if (isRegistration)
                dispatch({ type: SET_COMPANY_ATTIBUTES, attributes: values });
            else
                await dispatch(editCompanyAttributes(values, companyId, ""));
            setIsSubmitting(false);
            // setWasSaveSuccesful(true);
            if (handleOnSuccess)
                handleOnSuccess();
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values) => {
        // console.log(values)
        submitCallback(props.company.id, values, props.isRegistration, props.onSuccess);
    };

    const handleBack = (values) => {
        dispatch({ type: SET_COMPANY_ATTIBUTES, attributes: values });
        if (props.onBack)
            props.onBack();
    };
    // console.log(isMobile)

    const SaveButton = <ActionButtonBottom
        form="companySettings"
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        // startIcon={<SaveIcon />}
        mt={2}
        submitting={isSubmitting}
    >Save</ActionButtonBottom>;

    return (
        <Box>
            {props.showCompanyDescription &&
                <Box display="flex" justifyContent="space-between" mb={4} >
                    <Box display="flex" flex={1}>
                        <Typography variant="h1" style={{ lineHeight: '40px' }}>Company Settings</Typography>
                    </Box>
                </Box>
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    isValid,
                    values,
                    status,
                    setFieldValue,
                }) => {
                    return (
                        <form id="companySettings" onSubmit={handleSubmit}>
                            <Card mb={6} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                <CardContent style={{ padding: '25px 30px 20px 30px' }}>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Typography variant="h3" component="h3" mb={2}>Terminology</Typography>
                                        {SaveButton}
                                    </Box>
                                    <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>Use these settings to customize the labels used in the application to be more specific to your business.</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="activityText"
                                                label="Activity"
                                                value={values.activityText}
                                                fullWidth
                                                error={Boolean(touched.activityText && errors.activityText)}
                                                helperText={touched.activityText && errors.activityText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="activitiesText"
                                                label="Multiple Activities"
                                                value={values.activitiesText}
                                                fullWidth
                                                error={Boolean(touched.activitiesText && errors.activitiesText)}
                                                helperText={touched.activitiesText && errors.activitiesText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="customerText"
                                                label="Customer"
                                                value={values.customerText}
                                                fullWidth
                                                error={Boolean(touched.customerText && errors.customerText)}
                                                helperText={touched.customerText && errors.customerText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="customersText"
                                                label="Multiple Customers"
                                                value={values.customersText}
                                                fullWidth
                                                error={Boolean(touched.customersText && errors.customersText)}
                                                helperText={touched.customersText && errors.customersText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="employeeText"
                                                label="Employee"
                                                value={values.employeeText}
                                                fullWidth
                                                error={Boolean(touched.employeeText && errors.employeeText)}
                                                helperText={touched.employeeText && errors.employeeText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="employeesText"
                                                label="Multiple Employees"
                                                value={values.employeesText}
                                                fullWidth
                                                error={Boolean(touched.employeesText && errors.employeesText)}
                                                helperText={touched.employeesText && errors.employeesText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="locationText"
                                                label="Location"
                                                value={values.locationText}
                                                fullWidth
                                                error={Boolean(touched.locationText && errors.locationText)}
                                                helperText={touched.locationText && errors.locationText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextFieldResponsive
                                                name="locationsText"
                                                label="Multiple Locations"
                                                value={values.locationsText}
                                                fullWidth
                                                error={Boolean(touched.locationsText && errors.locationsText)}
                                                helperText={touched.locationsText && errors.locationsText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                    <Divider id='CONTACT_INFO' mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Typography variant="h3" component="h3" mb={2}>Contact Info</Typography>
                                        {SaveButton}
                                    </Box>
                                    <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>Update how people can get in touch with the business.</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextFieldResponsive
                                                name="companyContactEmail"
                                                label="Contact Form Recipient"
                                                value={values.companyContactEmail}
                                                fullWidth
                                                error={Boolean(touched.companyContactEmail && errors.companyContactEmail)}
                                                helperText={touched.companyContactEmail && errors.companyContactEmail}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InputMask
                                                name="phoneNumber"
                                                mask="(999) 999 - 9999"
                                                value={values.phoneNumber}
                                                onChange={e => { setFieldValue('phoneNumber', e.target.value, true) }}
                                                disabled={false}
                                                maskChar=" "
                                            >
                                                {() => <TextFieldResponsive
                                                    label="Phone Number (displayed on website and apps)"
                                                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                                    helperText={touched.phoneNumber && errors.phoneNumber}
                                                    fullWidth
                                                    variant="outlined"
                                                    my={2}
                                                />}
                                            </InputMask>
                                            <Box ml={4}>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Field name='allowTexts' component={Switch} onChange={(val) => setFieldValue('allowTexts', !values.allowTexts)} checked={values.allowTexts} />
                                                    } label='Contact number accepts texts messages' />
                                                </FormGroup>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {props.showCompanyDescription &&
                                        <React.Fragment>
                                            <Divider mt={3} mb={4} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} id='SOCIAL_LINKS' />
                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Social Links</Typography>
                                                {SaveButton}
                                            </Box>
                                            <Typography variant="subtitle1" component="p" mb={4} style={{ color: theme.palette.text.secondary }}>Will be added as links on the bottom of your website.</Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="instagramLink"
                                                        label="Instagram (full url)"
                                                        value={values.instagramLink}
                                                        fullWidth
                                                        error={Boolean(touched.instagramLink && errors.instagramLink)}
                                                        helperText={touched.instagramLink && errors.instagramLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="facebookLink"
                                                        label="Facebook (full url)"
                                                        value={values.facebookLink}
                                                        fullWidth
                                                        error={Boolean(touched.facebookLink && errors.facebookLink)}
                                                        helperText={touched.facebookLink && errors.facebookLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="twitterLink"
                                                        label="Twitter (full url)"
                                                        value={values.twitterLink}
                                                        fullWidth
                                                        error={Boolean(touched.twitterLink && errors.twitterLink)}
                                                        helperText={touched.twitterLink && errors.twitterLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="vimeoLink"
                                                        label="Vimeo (full url)"
                                                        value={values.vimeoLink}
                                                        fullWidth
                                                        error={Boolean(touched.vimeoLink && errors.vimeoLink)}
                                                        helperText={touched.vimeoLink && errors.vimeoLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="youTubeLink"
                                                        label="YouTube (full url)"
                                                        value={values.youTubeLink}
                                                        fullWidth
                                                        error={Boolean(touched.youTubeLink && errors.youTubeLink)}
                                                        helperText={touched.youTubeLink && errors.youTubeLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextFieldResponsive
                                                        name="tickTokLink"
                                                        label="Tick Tock (full url)"
                                                        value={values.tickTokLink}
                                                        fullWidth
                                                        error={Boolean(touched.tickTokLink && errors.tickTokLink)}
                                                        helperText={touched.tickTokLink && errors.tickTokLink}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        placeholder="" />
                                                </Grid>
                                            </Grid>

                                            <Divider mt={3} mb={4} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} id='WAIVER' />
                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Waiver</Typography>
                                                {SaveButton}
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12} >
                                                    <TextFieldResponsive
                                                        name="waiverText"
                                                        label="Waiver"
                                                        value={values.waiverText}
                                                        error={Boolean(touched.waiverText && errors.waiverText)}
                                                        fullWidth
                                                        helperText={touched.waiverText && errors.waiverText}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" mb={2}>Setting or updating a value here will force all {CUSTOMERS_TEXT.toLowerCase()} to accept before taking any other actions.  </Typography>
                                                </Grid>
                                            </Grid>

                                            <Divider mt={3} mb={4} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} id='REMINDER' />
                                            <Box display="flex" flexDirection="column" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Manage {ACTIVITY_TEXT} Reminders</Typography>
                                                <Typography variant="body" color="textSecondary" mb={2}>Can be used to save reminder text that can be used when adding/editing {ACTIVITIES_TEXT}.</Typography>
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <SelectManageAov type={REMINDER} />
                                                </Grid>
                                            </Grid>
                                            <Divider mt={3} mb={4} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} id='REMINDER' />
                                            <Box display="flex" flexDirection="column" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Manage {ACTIVITY_TEXT} Descriptions</Typography>
                                                <Typography variant="body" color="textSecondary" mb={2}>Can be used to save {ACTIVITY_TEXT} description text that can be used when adding/editing {ACTIVITIES_TEXT}.</Typography>
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <SelectManageAov type={DESCRIPTION} />
                                                </Grid>
                                            </Grid>

                                            <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Membership Settings</Typography>
                                                {SaveButton}
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Box ml={2}>
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Field name='allowCustomerCancelMembership' component={Switch} onChange={(val) => setFieldValue('allowCustomerCancelMembership', !values.allowCustomerCancelMembership)} checked={values.allowCustomerCancelMembership} />
                                                            } label='Allow Members to Cancel Memberships' />
                                                        </FormGroup>
                                                    </Box>
                                                    {!values.allowCustomerCancelMembership &&
                                                        <TextFieldResponsive
                                                            name="cancelTextMembershipText"
                                                            label="Provide instructions on how to cancel:"
                                                            value={values.cancelTextMembershipText}
                                                            error={Boolean(touched.cancelTextMembershipText && errors.cancelTextMembershipText)}
                                                            fullWidth
                                                            helperText={touched.cancelTextMembershipText && errors.cancelTextMembershipText}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            multiline={true}
                                                            maxRows={15}
                                                            variant="outlined"
                                                            my={2}
                                                            placeholder="" />}
                                                </Grid>
                                            </Grid>

                                            <Divider mt={3} mb={4} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} id='COMPANY_ITEMS' />
                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Typography variant="h3" component="h3" mb={2}>Company Items</Typography>
                                                {SaveButton}
                                            </Box>
                                            <Grid container spacing={3} my={2} ml={3}>
                                                <Grid item xs={12} sm={6} >
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='hideBilling' component={Switch} onChange={(val) => setFieldValue('hideBilling', !values.hideBilling)} checked={values.hideBilling} />
                                                        } label='Hide the billing section in the website and app.' />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Box display="flex" flexDirection="column">
                                                        <FormGroup>
                                                            <FormControlLabel control={
                                                                <Field name='allowManualLocations' component={Switch} onChange={(val) => setFieldValue('allowManualLocations', !values.allowManualLocations)} checked={values.allowManualLocations} />
                                                            } label={'Allow ' + ACTIVITY_TEXT.toLowerCase() + ' location to be manually entered. '} />
                                                        </FormGroup>
                                                        {values.allowManualLocations &&
                                                            <Box mt={2}>
                                                                <SelectFormControl
                                                                    fullWidth
                                                                    labelText={"Timezone - used when sending reminders"}
                                                                    labelId="select-locationId-label"
                                                                >
                                                                    <Select
                                                                        labelId="select-locationId-label"
                                                                        id="manualLocationTimezone"
                                                                        fullWidth
                                                                        value={values.manualLocationTimezone}
                                                                        label={"Timezone - used when sending reminders"} /// nned both this line and Input lavel
                                                                        onChange={(event) =>
                                                                            setFieldValue("manualLocationTimezone", event.target.value)
                                                                        }
                                                                        IconComponent={ChevronDown}
                                                                    >
                                                                        {timezones.map((timeezone) => (
                                                                            <MenuItem
                                                                                key={timeezone.key}
                                                                                value={timeezone.key}
                                                                                selected={
                                                                                    timeezone.key === values.timezone ? true : false
                                                                                }
                                                                            >
                                                                                {timeezone.value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </SelectFormControl>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} my={2} ml={3}>
                                                <Grid item xs={12} sm={6} >
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='manuallySortProducts' component={Switch} onChange={(val) => setFieldValue('manuallySortProducts', !values.manuallySortProducts)} checked={values.manuallySortProducts} />
                                                        } label='Manually Sort Memberships/Packages/Products' />
                                                    </FormGroup>
                                                    {!values.manuallySortProducts &&
                                                        <Typography variant="subtitle1" component="p" mt={2} style={{ color: theme.palette.text.secondary }}>
                                                            Items will be sorted by price with the most expensive at the top.
                                                        </Typography>
                                                    }
                                                    {values.manuallySortProducts &&
                                                        <Typography variant="subtitle1" component="p" mt={2} style={{ color: theme.palette.text.secondary }}>
                                                            Set the sort for each item by editing the membership, package or product.
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Field name='hideFullSelfRegisterActivities' component={Switch} onChange={(val) => setFieldValue('hideFullSelfRegisterActivities', !values.hideFullSelfRegisterActivities)} checked={values.hideFullSelfRegisterActivities} />
                                                        } label={'Hide self register ' + ACTIVITIES_TEXT.toLowerCase() + ' that are full from ' + CUSTOMERS_TEXT.toLowerCase()} />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFieldResponsive
                                                    name="purchaseNotificationEmails"
                                                    label="Send email notification when a purchase is made or an file is added to an account - seperate emails with a semicolon - ;"
                                                    value={values.purchaseNotificationEmails}
                                                    error={Boolean(touched.purchaseNotificationEmails && errors.purchaseNotificationEmails)}
                                                    fullWidth
                                                    helperText={touched.purchaseNotificationEmails && errors.purchaseNotificationEmails}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    multiline={true}
                                                    maxRows={15}
                                                    variant="outlined"
                                                    my={2}
                                                    placeholder="" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextFieldResponsive
                                                    name="description"
                                                    label="Company Description (displayed in mobile app)"
                                                    value={values.description}
                                                    error={Boolean(touched.description && errors.description)}
                                                    fullWidth
                                                    helperText={touched.description && errors.description}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    multiline={true}
                                                    maxRows={15}
                                                    variant="outlined"
                                                    my={2}
                                                    placeholder="" />
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                            <TextFieldResponsive
                                                name="embedScripts"
                                                label="Add Scripts to Embed in Website (warning this could break your website)"
                                                value={values.embedScripts}
                                                error={Boolean(touched.embedScripts && errors.embedScripts)}
                                                fullWidth
                                                helperText={touched.embedScripts && errors.embedScripts}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        </Grid> */}
                                        </React.Fragment>
                                    }
                                    {props.showBack && <Button
                                        type="button"
                                        onClick={() => handleBack(values)}
                                        mt={3}>
                                        Back
                                    </Button>}
                                    {!props.showCompanyDescription && <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        mt={3}>
                                        {props.saveButtonText}
                                    </Button>}
                                </CardContent>
                            </Card>

                            {props.showCompanyDescription &&
                                <Card mb={4}>
                                    <CardContent>
                                        <Typography variant="h3" component="h3" mb={2}>Customize Messages</Typography>
                                        <Typography mb={2} style={{ color: theme.palette.text.secondary }}>This area allows you to customize the messages that are show in different parts of the application.</Typography>
                                        <Typography mb={2} style={{ color: theme.palette.text.secondary }}>To use the default message leave the field blank.</Typography>
                                        <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                            <Typography variant="h4" component="h4" mb={2}>{ACTIVITY_TEXT} Drop Messages</Typography>
                                            {SaveButton}
                                        </Box>

                                        <Typography mb={2} style={{ color: theme.palette.text.secondary }}>These messages are shown in an {ACTIVITY_TEXT.toLowerCase()} that allow self registiration after a {CUSTOMER_TEXT.toLowerCase()} is registered.</Typography>
                                        {/* <Grid item xs={12} sm={6} >
                                        <Box ml={2}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Field name='hideDroppedActivityMessage' component={Switch} onChange={(val) => setFieldValue('hideDroppedActivityMessage', !values.hideDroppedActivityMessage)} checked={values.hideDroppedActivityMessage} />
                                                } label={"Don't show any message to " + CUSTOMERS_TEXT.toLowerCase() + " below the drop button"} />
                                            </FormGroup>
                                        </Box>
                                    </Grid> */}
                                        {!values.hideDroppedActivityMessage &&
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4" mr={2}>Drop {ACTIVITY_TEXT.toLowerCase()} warning message </Typography>
                                                    <TextFieldResponsive
                                                        name="dropActivityWarningMessage"
                                                        label={'Shown as a warning when registering if the drop deadline has passed'}
                                                        value={values.dropActivityWarningMessage}
                                                        error={Boolean(touched.dropActivityWarningMessage && errors.dropActivityWarningMessage)}
                                                        fullWidth
                                                        helperText={touched.dropActivityWarningMessage && errors.dropActivityWarningMessage}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <Box display="flex" mx={4}>
                                                        <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>Be advised, the time when this {ACTIVITY_TEXT.toLowerCase()} can be dropped has passed. Do you still want to register?</Typography>
                                                    </Box>
                                                </Grid>
                                                <Divider my={3} />

                                                <Grid item xs={12}>
                                                    <Typography variant="h4" mr={2}>Pre deadline drop {ACTIVITY_TEXT.toLowerCase()} message </Typography>
                                                    <TextFieldResponsive
                                                        name="preDeadlineDropActivityMessage"
                                                        label={"Shown when users are allowed to drop"}
                                                        value={values.preDeadlineDropActivityMessage}
                                                        error={Boolean(touched.preDeadlineDropActivityMessage && errors.preDeadlineDropActivityMessage)}
                                                        fullWidth
                                                        helperText={touched.preDeadlineDropActivityMessage && errors.preDeadlineDropActivityMessage}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <Box display="flex" mx={4}>
                                                        <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>If you do not drop this {ACTIVITY_TEXT.toLowerCase()} before the drop deadline of CLASS_DROP_TIME you will be billed for the {ACTIVITY_TEXT.toLowerCase()} even if you do not attend.</Typography>
                                                    </Box>
                                                    <Box display="flex" mx={6} mt={1}>
                                                        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>To dynamically insert the {ACTIVITY_TEXT.toLowerCase()} drop time use the text {'{'}CLASS_DROP_TIME{'}'}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Divider my={3} />

                                                <Grid item xs={12}>

                                                    <Typography variant="h4" mr={2}>Post deadline drop {ACTIVITY_TEXT.toLowerCase()} message </Typography>
                                                    <TextFieldResponsive
                                                        name="postDeadlineDropActivityMessage"
                                                        label={"Shown after the drop deadline has passed"}
                                                        value={values.postDeadlineDropActivityMessage}
                                                        error={Boolean(touched.postDeadlineDropActivityMessage && errors.postDeadlineDropActivityMessage)}
                                                        fullWidth
                                                        helperText={touched.postDeadlineDropActivityMessage && errors.postDeadlineDropActivityMessage}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <Box display="flex" mx={4}>
                                                        <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>This {ACTIVITY_TEXT.toLowerCase()} can no longer be dropped you will be billed for the {ACTIVITY_TEXT.toLowerCase()} even if you do not attend.</Typography>
                                                    </Box>
                                                </Grid>
                                                <Divider my={3} />

                                                <Grid item xs={12}>
                                                    <Typography variant="h4" mr={2}>Not self register {ACTIVITY_TEXT.toLowerCase()}</Typography>
                                                    <TextFieldResponsive
                                                        name="noDropActivityMessage"
                                                        label={"Message when " + ACTIVITY_TEXT + " is not self-register " + ACTIVITY_TEXT}
                                                        value={values.noDropActivityMessage}
                                                        error={Boolean(touched.noDropActivityMessage && errors.noDropActivityMessage)}
                                                        fullWidth
                                                        helperText={touched.noDropActivityMessage && errors.noDropActivityMessage}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <Box display="flex" mx={4}>
                                                        <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                        <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>To request a change to this {ACTIVITY_TEXT.toLowerCase()} please contact the business.</Typography>
                                                    </Box>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                            <Typography variant="h4" component="h4" mb={2}>Account Messages</Typography>
                                            {SaveButton}
                                        </Box>
                                        <Grid item xs={12}>
                                            <TextFieldResponsive
                                                name="passwordResetMessage"
                                                label="Password Reset Message"
                                                value={values.passwordResetMessage}
                                                error={Boolean(touched.passwordResetMessage && errors.passwordResetMessage)}
                                                fullWidth
                                                helperText={touched.passwordResetMessage && errors.passwordResetMessage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                            <Box display="flex" mx={4}>
                                                <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
                                                    To reset your password with {company.name} click the link below.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldResponsive
                                                name="accountRegisterEmailMessage"
                                                label="Account Register Message"
                                                value={values.accountRegisterEmailMessage}
                                                error={Boolean(touched.accountRegisterEmailMessage && errors.accountRegisterEmailMessage)}
                                                fullWidth
                                                helperText={touched.accountRegisterEmailMessage && errors.accountRegisterEmailMessage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                            <Box display="flex" mx={4}>
                                                <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
                                                    You have been invited the {company.name} portal. To register click the link below.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                            <Typography variant="h4" component="h4" mb={2}>Emails</Typography>
                                            {SaveButton}
                                        </Box>
                                        <Grid item xs={12}>
                                            <TextFieldResponsive
                                                name="activityRegisterEmailMessage"
                                                label={ACTIVITY_TEXT + " registration email"}
                                                value={values.activityRegisterEmailMessage}
                                                error={Boolean(touched.activityRegisterEmailMessage && errors.activityRegisterEmailMessage)}
                                                fullWidth
                                                helperText={touched.activityRegisterEmailMessage && errors.activityRegisterEmailMessage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                            <Box display="flex" mx={4}>
                                                <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
                                                    You are registered for {'{'}NAME{'}'} on {'{'}TIME{'}'}. To add it to your calendar click the link below.</Typography>
                                            </Box>
                                            <Box display="flex" mx={6} mt={1}>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>To dynamically insert the {ACTIVITY_TEXT.toLowerCase()} name use {'{'}NAME{'}'} to insert time use {'{'}TIME{'}'}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextFieldResponsive
                                                name="activityDroppedEmailMessage"
                                                label={ACTIVITY_TEXT + " dropped email"}
                                                value={values.activityDroppedEmailMessage}
                                                error={Boolean(touched.activityDroppedEmailMessage && errors.activityDroppedEmailMessage)}
                                                fullWidth
                                                helperText={touched.activityDroppedEmailMessage && errors.activityDroppedEmailMessage}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={15}
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                            <Box display="flex" mx={4}>
                                                <Typography variant="h4" style={{ color: theme.palette.text.secondary }} mr={2}>Default message:</Typography>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>
                                                    You have dropped the {ACTIVITY_TEXT.toLowerCase()} {'{'}NAME{'}'} on {'{'}TIME{'}'}.</Typography>
                                            </Box>
                                            <Box display="flex" mx={6} mt={1}>
                                                <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>To dynamically insert the {ACTIVITY_TEXT.toLowerCase()} name use {'{'}NAME{'}'} to insert time use {'{'}TIME{'}'}</Typography>
                                            </Box>
                                        </Grid>



                                    </CardContent>
                                </Card>
                            }
                        </form>
                    )
                }
                }
            </Formik >
            <ErrorDialog message={error} setMessage={setError} />
        </Box >
    )
};

export default CompanyAttributes;

