import React from 'react';

import styled from "styled-components/macro";

// svxvy imports
import PoweredBySmartCore from '../PoweredBySmartCore';

//Constants

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: 15px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 15px;
  }
`;


const CompanySidebarFooter = props => {

    return (
        <SidebarFooter>
            <PoweredBySmartCore />
        </SidebarFooter>
    )
}

export default CompanySidebarFooter;