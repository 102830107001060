import React from 'react';
import { useSelector } from 'react-redux';

// UI Imports

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { withTheme } from "styled-components/macro";
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Grid,
  Typography as MuiTypography
} from "@material-ui/core";

// svxvy imports
import CompanyAttributesForm from '../../../components/company/manageSettings/CompanyAttributesForm';

// UI Consts 

const Typography = styled(MuiTypography)(spacing);


const CompanySettings = (props) => {
  const company = useSelector(state => state.company.company);

  return (
    <Grid container>
      <Grid item xs={false} sm={1}></Grid>
      <Grid item xs={12} sm={10}>
        <Box>
          <Helmet title="Manage Settings" />

          {company &&
            <Grid container spacing={6}>
              <Grid item xs={12} >
                <Box mb={8}>
                  <Typography variant="h1" mb={2}>Want a custom domain for you website?</Typography>
                  <Typography variant="body2">Send us an email at support@smartcore.life and we will help set one up for you.</Typography>
                </Box>
                <CompanyAttributesForm company={company} showCompanyDescription saveButtonText={"Save"} successMessage={"Company information updated."} />

              </Grid>
            </Grid>
          }
          {!company &&
            <Grid container spacing={6}>
              <Grid item xs={12} lg={8}>
                <p>Hmm.... we couldn't find this company do you want to register the company with svxvy?</p>
              </Grid>
            </Grid>
          }
        </Box>
      </Grid>
    </Grid>
  );
}

export default withTheme(CompanySettings);
