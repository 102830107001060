
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from '@material-ui/core/styles';
import i18n from 'i18n-js';

import { Formik } from 'formik'
import * as Yup from "yup";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    Card as MuiCard,
    InputAdornment,
    Switch as MuiSwitch,
    TextField as MuiTextField,
    Typography as MuiTypography
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircle as AddCircle,
    IoRadioButtonOffOutline as RadioButtonOff,
    IoRadioButtonOn as RadioButtonOn,
    IoCloseCircle as CardExpired
} from "react-icons/io5";


// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim, GUID_Generator } from '../../../helpers/helperFunctions'
import { customPurchase } from "../../../store/actions/productActions";
import { isCardExpired } from '../../../helpers/commerceFunctions';
import { addPaymentMethod } from "../../../store/actions/paymentActions";
import { addToArray, deleteById } from '../../../helpers/arrayFunctions';

import ActionButton from "../../framework/ActionButton";
import ErrorDialog from '../../framework/ErrorDialog';
import InvoiceCard from '../purchases/InvoiceCard';
import PaymentMethod from '../../../models/PaymentMethod'
import ProfileCard from '../profile/ProfileCard';
import ProfileEditCard from '../profile/ProfileEditCard';
import StyledDialog from "../../framework/StyledDialog"


//consants
const Card = styled(MuiCard)(spacing);
const Switch = styled(MuiSwitch)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);


const CustomPurchaseModal = props => {
    const { open, setOpen, displayedUser, product, itemTitle, itemPrice, usersToPrepay, activity, activityId, applyTwoOrMoreMemberDiscount, onSuccess } = props;
    const theme = useTheme();
    const stripe = useStripe();
    const elements = useElements();

    const company = useSelector(state => state.company.company);

    const loggedInUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowEdit = checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);
    const viewReports = checkClaim(currentUserClaims, SecurityConstants.VIEW_REPORTS)

    const parentAccount = useSelector(state => state.company.people).find(x => x.id === displayedUser.parentAccountId);

    const parentPaymentMethods = allowEdit && displayedUser && displayedUser.parentAccountId
        ? parentAccount.paymentMethods.filter(x => x.companyId === displayedUser.companyId) :
        loggedInUser.childProfiles.some(x => x.id === displayedUser.id) ? loggedInUser.paymentMethods.filter(x => x.companyId === displayedUser.companyId) : [];

    const displayedUserPaymentMethods = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.filter(x => x.companyId === displayedUser.companyId && !x.isParentCard) : [];
    const paymentMethodToShow = [...displayedUserPaymentMethods, ...parentPaymentMethods]

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(displayedUserPaymentMethods.length > 0
        ? displayedUserPaymentMethods.find(x => x.isDefault && !isCardExpired(x))
        : parentPaymentMethods.length > 0 ? parentPaymentMethods.find(x => x.isDefault && !isCardExpired(x)) : null)

    const hasAnyExistingDefaultPaymentMethod = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.filter(x => x.companyId === company.id && x.isDefault).length > 0 : null;

    const [useNewCard, setUseNewCard] = useState(paymentMethodToShow.length === 0 ? true : false);
    const [storeCard, setStoreCard] = useState(true);
    const [itemPurchased, setItemPurchased] = useState(null);

    const [isCashPurchase, setIsCashPurchase] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);

    const [customAmount, setCustomAmount] = useState("0");
    const [description, setDescription] = useState(itemTitle ? itemTitle : 'Custom Purchase');

    const [enrollmentIds, setEnrollmentIds] = useState(!displayedUser.childProfiles || displayedUser.childProfiles.length === 0 ? [] : []);
    const [addingNewPerson, setAddingNewPerson] = useState(false);

    const discount = applyTwoOrMoreMemberDiscount && displayedUser.childProfiles &&
         (displayedUser.childProfiles.length > usersToPrepay.length) && (enrollmentIds.length > 0) // someone registered before
            ? (itemPrice * (enrollmentIds.length) * (-1 * activity.twoOrMoreMemberDiscount / 100))
            : applyTwoOrMoreMemberDiscount && (enrollmentIds.length > 1)
                ? itemPrice * (enrollmentIds.length - 1) * (-1 * activity.twoOrMoreMemberDiscount / 100)
                : 0;
            

// console.log(applyTwoOrMoreMemberDiscount, displayedUser.childProfiles.length, usersToPrepay.length,(displayedUser.childProfiles.length > usersToPrepay.length), (enrollmentIds.length > 0), discount, itemPrice * (enrollmentIds.length) * (-1 * activity.twoOrMoreMemberDiscount / 100))
// console.log( (displayedUser.childProfiles.length > usersToPrepay.length),enrollmentIds.length, (enrollmentIds.length > 0), (itemPrice * (enrollmentIds.length) * (-1 * activity.twoOrMoreMemberDiscount / 100)))
const dispatch = useDispatch();
const customPurchaseCallback = useCallback(async (paymentMethod, values, displayedUserId, companyId, profilePaymentMethods, isCashPurchase) => {
    setError(null);
    setIsSubmitting(true);

    let pm = paymentMethod;
    try {
        if ((useNewCard || !profilePaymentMethods || profilePaymentMethods.length === 0) && !isCashPurchase) {
            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                throw new Error("Error: " + error.code + " " + error.message)
            }
            else {
                pm = paymentMethod;
            }
            pm = new PaymentMethod(GUID_Generator(),
                "stripe",
                paymentMethod.id,
                paymentMethod.card.last4,
                paymentMethod.card.exp_month,
                paymentMethod.card.exp_year,
                paymentMethod.billing_details.address.postal_code,
                companyId,
                !hasAnyExistingDefaultPaymentMethod,
                false);
            pm.addToTenant = true;
            if (storeCard) {
                await dispatch(addPaymentMethod(companyId, displayedUserId, pm, false));
            }
        }

        if (!pm && !isCashPurchase)
            throw new Error("You must select or enter a credit card.")

        const activityIdToSend = activityId && activityId.indexOf('_') >= 0 ? activityId.split('_')[0] : activityId;
        const occuranceId = activityId && activityId.indexOf('_') >= 0 ? activityId.split('_')[1] : "0";

        const valuesToSend = {
            paymentMethodId: pm ? pm.id : null,
            nonStoredPaymentMethodId: pm ? pm.paymentMethodId : null,
            cashPurchase: isCashPurchase,
            activityId: activityIdToSend,
            occuranceId: occuranceId,
            ...values
        }

        const purchase = await dispatch(customPurchase(
            companyId,
            displayedUserId,
            valuesToSend,
            activityId ? true : false
        ));

        setItemPurchased(purchase)
        if (onSuccess)
            onSuccess();
    } catch (err) {
        setError(err.message);
        setIsSubmitting(false);
    }
}, [dispatch, elements, hasAnyExistingDefaultPaymentMethod, storeCard, stripe, useNewCard, activityId, onSuccess]);

const handleCustomPurchase = (values) => {
    // console.log(values)
    // const valuesToSend = {
    //     customAmount: values.customAmount ? parseFloat(values.customAmount) : 0,
    //     description: values.description
    // }

    // console.log(valuesToSend)
    let amountToSend = 0;
    if (activityId) {
        amountToSend = itemPrice * enrollmentIds.length;
        if (discount < 0)
            amountToSend += discount;
    }
    else {
        amountToSend = parseFloat(customAmount);
    }
    const valuesToSend = {
        customAmount: amountToSend,
        description: description,
        enrollmentIds: enrollmentIds.map(x => x.id)
    }
    customPurchaseCallback(selectedPaymentMethod,
        valuesToSend,
        displayedUser.id,
        company.id,
        paymentMethodToShow,
        isCashPurchase
    );
};

const handleSelected = (person) => {
    if (enrollmentIds.find(x => x.id === person.id))
        setEnrollmentIds(deleteById(person.id, enrollmentIds));
    else
        setEnrollmentIds(addToArray(person, enrollmentIds));
}

return (
    <React.Fragment>
        <StyledDialog
            open={open}
            setOpen={setOpen}
            primaryAction={
                <React.Fragment>
                    {!itemPurchased &&
                        <ActionButton my={2}
                            // form='customPurchaseForm'
                            // type="submit"
                            onClick={handleCustomPurchase}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isFormValid || (activityId && enrollmentIds.length === 0)}
                            submitting={isSubmitting}
                        >
                            Purchase Item
                            </ActionButton>
                    }
                    {itemPurchased &&
                        <ActionButton
                            onClick={() => setOpen(false)}
                            fullWidth
                            variant="contained"
                            color="primary">
                            Close
                            </ActionButton>
                    }
                </React.Fragment>
            }
        >
            {!itemPurchased &&
                <React.Fragment>
                    <Card mb={activityId ? 2 : 4}>
                        {activityId &&
                            <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2} ml={2} >
                                <Typography variant="h3">Select Person to Enroll:</Typography>
                                <ActionButton startIcon={<AddCircle />} variant="outlined" color="primary" onClick={() => setAddingNewPerson(true)} >Add Child Account</ActionButton>
                            </Box>
                        }
                        {!activityId &&
                            <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2} ml={2} >
                                <Typography variant="h3">Purchase for:</Typography>
                            </Box>
                        }
                        <ProfileCard
                            person={displayedUser}
                            isSelectable={false}
                            notClickable={true}
                            handleSelected={handleSelected}
                        />
                        {activityId && displayedUser && displayedUser.childProfiles && displayedUser.childProfiles.length > 0 &&
                            <React.Fragment>
                                {displayedUser.childProfiles.map((p, index) => {
                                    return (
                                        <ProfileCard key={p.id}
                                            isSelectable={activityId && usersToPrepay.includes(p.id) ? true : false}
                                            notClickable={!activityId || (usersToPrepay && !usersToPrepay.includes(p.id)) ? true : false}
                                            person={p}
                                            isChildProfile={true}
                                            handleSelected={handleSelected}
                                        />
                                    )
                                })}
                            </React.Fragment>
                        }
                    </Card>


                    <Card>
                        <Box m={2}>

                            <Box display='flex' flexDirection='column' mb={4}>
                                {!itemTitle && <Typography variant="h3">Custom Purchase</Typography>}
                                {activityId && <Typography variant="h3">Items to Purchase:</Typography>}
                                <Formik
                                    initialValues={{
                                        description: 'Custom Purchase',
                                        customAmount: itemPrice ? itemPrice * enrollmentIds.length : 0.00,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        description: Yup.string().required('Required'),
                                        customAmount: Yup.number().min(0, "Must be greater than 0.").required("Required"),
                                    })}
                                    onSubmit={handleCustomPurchase}
                                >
                                    {({
                                        errors,
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        touched,
                                        isValid,
                                        values,
                                    }) => {
                                        setDescription(values.description);
                                        setCustomAmount(values.customAmount);
                                        setIsFormValid(isValid);
                                        return (
                                            <form id='customPurchaseForm' onSubmit={handleCustomPurchase}>
                                                <Box display='flex' flexDirection='row' alignItems="flex-start" justifyContent="space-between" mt={4} >
                                                    {!itemTitle && <TextField
                                                        variant="outlined"
                                                        name="description"
                                                        label="Descritpion"
                                                        value={values.description}
                                                        error={Boolean(touched.description && errors.description)}
                                                        helperText={touched.description && errors.description}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        ml={2}
                                                        required
                                                        margin="dense"
                                                        fullWidth
                                                    />
                                                    }
                                                    {itemTitle &&
                                                        <Typography variant='h4'>{itemTitle}</Typography>
                                                    }
                                                    {(!itemPrice) &&
                                                        <TextField
                                                            variant="outlined"
                                                            name="customAmount"
                                                            label="Amount"
                                                            value={values.customAmount}
                                                            error={Boolean(touched.customAmount && errors.customAmount)}
                                                            helperText={touched.customAmount && errors.customAmount}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            ml={2}
                                                            required
                                                            margin="dense"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            }}
                                                        />
                                                    }
                                                    {itemPrice &&
                                                        <Typography variant='h4'>
                                                            {i18n.toCurrency((itemPrice * enrollmentIds.length), { precision: 2, unit: '$' })} ({itemPrice} x {enrollmentIds.length})
                                                            </Typography>
                                                    }
                                                </Box>
                                                {discount < 0 &&
                                                    <React.Fragment>
                                                        <Box display='flex' flexDirection='row' alignItems="flex-start" justifyContent="space-between" mt={4} >
                                                            <Typography variant='h4'>Discount</Typography>
                                                            <Typography variant='h4'>
                                                                {i18n.toCurrency(discount, { precision: 2, unit: '$' })}
                                                            </Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection='row' alignItems="flex-start" justifyContent="space-between" mt={4} >
                                                            <Typography variant='h4'>Total</Typography>
                                                            <Typography variant='h4'>
                                                                {i18n.toCurrency((itemPrice * enrollmentIds.length) + discount, { precision: 2, unit: '$' })}
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                }
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </Box>
                        </Box>
                    </Card>
                    <Card my={2}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2} pl={2} pb={2}
                            style={{ borderBottom: '1px solid ' + theme.palette.divider }}
                        >
                            <Typography variant="h3">Payment Method</Typography>
                        </Box>
                        {viewReports &&
                            <Box mt={2} p={2}
                                style={{ borderBottom: '1px solid ' + theme.palette.divider }}
                            >
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography>Cash Purchase?</Typography>
                                    <Switch name='isCashPurchase' onChange={(val) => setIsCashPurchase(prev => !prev)} checked={isCashPurchase} />
                                </Box>
                            </Box>
                        }
                        {paymentMethodToShow && paymentMethodToShow.length > 0 && !isCashPurchase && !useNewCard &&
                            <React.Fragment>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2} ml={2} >
                                    <Typography variant="h4">Cards on file:</Typography>
                                </Box>
                                {paymentMethodToShow.map((item, index) => {
                                    const isExpired = isCardExpired(item);
                                    return (
                                        <Box key={item.id} my={2} p={2}>
                                            <Box onClick={() => setSelectedPaymentMethod(item)} disabled={isExpired}
                                                style={{ cursor: (isExpired ? 'default' : 'cursor') }}
                                            >
                                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                    <Box display="flex" flexDirection="row" alignItem="center">
                                                        {isExpired && <CardExpired color={theme.palette.error.main} size={24} />}
                                                        {!isExpired && (!selectedPaymentMethod || selectedPaymentMethod.id !== item.id) && <RadioButtonOff size={24} color={theme.palette.iconColor.color} />}
                                                        {!isExpired && selectedPaymentMethod && selectedPaymentMethod.id === item.id && <RadioButtonOn size={24} />}
                                                        {parentPaymentMethods.some(x => x.id === item.id) && <Typography ml={3}>Parent Card {item.isDefault ? '- ' : ''}</Typography>}
                                                        {item.isDefault && <Typography ml={parentPaymentMethods.some(x => x.id === item.id) ? 0 : 3}>Default</Typography>}
                                                    </Box>
                                                    <Box display="flex" flexDirection="column" alignItems='flex-end'>
                                                        <Typography>**** {item.lastFourOnCard}</Typography>
                                                        <Typography variant="caption">{item.expirationMonth}/{item.expirationYear}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Box>
                                    )
                                })}
                            </React.Fragment>
                        }
                        {paymentMethodToShow && paymentMethodToShow.length > 0 && !isCashPurchase &&
                            <Box mt={2} p={2}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography>Enter credit card:</Typography>
                                    <Switch name='useNewCard' onChange={(val) => setUseNewCard(prev => !prev)} checked={useNewCard} />
                                </Box>
                            </Box>
                        }
                        {useNewCard && !isCashPurchase &&
                            <Box mt={2} p={2}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: theme.palette.text.primary,
                                                '::placeholder': {
                                                    color: theme.palette.text.primary,
                                                },
                                            },
                                            invalid: {
                                                color: theme.palette.error.main,
                                            },
                                        },
                                    }}
                                />
                                {(!product || !product.isRecurring) &&
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                                        <Typography>Save card for future use:</Typography>
                                        <Switch name='storeCard' onChange={(val) => setStoreCard(prev => !prev)} checked={storeCard} />
                                    </Box>
                                }
                            </Box>
                        }
                    </Card>
                </React.Fragment>
            }
            {itemPurchased &&
                <InvoiceCard
                    purchase={itemPurchased} />
            }
        </StyledDialog >
        {error &&
            <ErrorDialog message={error} setMessage={setError} />
        }
        {
            addingNewPerson &&
            <ProfileEditCard
                open={addingNewPerson}
                setOpen={setAddingNewPerson}
                parentAccountId={displayedUser.id}
            />
        }
    </React.Fragment >
)
}

export default CustomPurchaseModal;