import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useLocation } from "react-router";

import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { withTheme } from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { atcb_action } from 'add-to-calendar-button'
import "add-to-calendar-button/assets/css/atcb.css";

import {
    Box,
    Chip as MuiChip,
    Grid,
    LinearProgress as MuiLinearProgress,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleOutline as CheckIcon,
} from "react-icons/io5";

// svxvy import
import { getActivityDetail, confirmActivity, expandActivity } from '../../../store/actions/activityActions';
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import ActivityDetail from '../../../components/company/activities/ActivityDetail';
import NonExpandedActivityDetail from '../../../components/company/activities/NonExpandedActivityDetail';
import ActionButton from '../../../components/framework/ActionButton';
import ActivityAddEditModal from '../../../components/company/activities/ActivityAddEditModal';
import DeclineDialog from '../../../components/company/activities/DeclineDialog';
import LoginDialog from "../../../components/company/userManagement/LoginDialog";
import StripeWrapper from "../../../components/company/paymentMethods/StripeWrapper";
import CustomPurchaseModal from "../../../components/company/products/CustomPurchaseModal";

// constants
const Alert = styled(MuiAlert)(spacing);
const ChipToStyle = styled(MuiChip)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);


const Chip = styled(ChipToStyle)`
    height: 20px;
    border-radius: 999999px;
    margin-top: -2px;
    padding: 0 9px;
    font-weight: 700;
    font-size: 12px !important;

    span {
        padding: 0;
    }
`;

const ICON_SIZE = 24;

// for some reason I can't do this inline or react blows up.
const stupidFunction = (activity, loggedInUser) => {
    if (!activity || !loggedInUser)
        return false

    return activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity, true);
}


const ConfirmActivityPage = (props) => {
    let { activityID } = useParams();
    const { search } = useLocation();
    const theme = useTheme();
    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);

    const occuranceId = new URLSearchParams(search).get('occuranceId');

    const startDate = new Date(new Date((new Date()).getTime()).setHours(0, 0, 0, 0));

    const company = useSelector(state => state.company.company);
    const hideFullSelfRegisterActivities = company ? company.hideFullSelfRegisterActivities : true;

    const loggedInUser = useSelector(state => state.auth.person);
    const loggedInUserClaims = useSelector(state => state.auth.claims);

    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);


    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activity, setActivity] = useState(null);
    const [confirmingActivity, setConfirmingActivity] = useState(false);

    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
    const [showCustomPurchaseModal, setShowCustomPurchaseModal] = useState(false)
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);

    const instructors = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity);
    const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
    const isRunningActivity = loggedInUser ? peopleRunningActivity.some(x => x.id === loggedInUser.id) : false;

    const isRecurring = activity && activity.rrule !== null && activity.rrule.trim().length > 0 ? true : false;
    const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
    const currentUserInClass = activity ? activity.isInNonExpandedActivity(haveAttendeeObject, activity) : false;
    const aFamilySignedUp = stupidFunction(activity, loggedInUser);
    const endDateText = useMemo(() => {
        return activity ? activity.getEndDateAsText(isRecurring, activity.rrule, activity.startDateTime, 'MMM D') : "";
    }, [activity, isRecurring]);

    const endDate = useMemo(() => {
        return activity ? activity.getEndDate(isRecurring, activity.rrule, activity.startDateTime) : null;
    }, [activity, isRecurring]);

    const isInPast = endDate < Date.now() ? true : false;
    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');
    const spacesRemaining = activity ? activity.spacesRemaining(activity, true) : 0;
    // const durationInMilliSeconds = !activity ? 0 : getMilliseconds(activity.duration, 'minutes');

    const childProfiles = loggedInUser && loggedInUser.childProfiles ? loggedInUser.childProfiles.sort(compareObjects('displayName', 'asc')) : [];
    const usersToList = [...[loggedInUser], ...childProfiles];
    const usersToPrepay = loggedInUser && activity ? usersToList.filter(y => !activity.isInNonExpandedActivity(activity.people.find(x => x.appUserId === y.id), activity)).map(x => x.id) : [];


    // const whenRegistrationCloses = !activity ? null : new Date(endDate.getTime() + durationInMilliSeconds);

    //==================================================================
    const dispatch = useDispatch();

    const loadActivityDetail = useCallback(async (activityId) => {
        try {
            setError(null);
            setIsLoading(true);

            let activity = await dispatch(getActivityDetail(activityId, occuranceId));

            if (!activity)
                throw new Error("Oops something went wrong for help contact support@smartcore.life")

            if (occuranceId && activity.rrule && activity.rrule.length > 0) {
                let expandedActivities = expandActivity(activity,
                    new Date(),
                    (new Date(activity.startDateTime).setFullYear(activity.startDateTime.getFullYear() + 1)),
                    loggedInUser,
                    loggedInUserClaims,
                    hideFullSelfRegisterActivities);

                let occurance = expandedActivities.find(x => x.occuranceId === occuranceId);
                if (occurance) {
                    occurance.rrule = null;
                    setActivity(occurance)
                }
            }
            else
                setActivity(activity)
        } catch (err) {
            let message = err.message
            if (message.includes('has been removed')) {
                message = message.substring(0, message.length - 48, '');
            }
            setError(message);
        }
        setIsLoading(false);
    }, [dispatch, loggedInUser, loggedInUserClaims, occuranceId, hideFullSelfRegisterActivities]);


    useEffect(() => {
        //trigger when the activityDialogOpen is closed
        if (activityID !== null && activityID.length > 0 && !activityDialogOpen)
            loadActivityDetail(activityID);
    }, [loadActivityDetail, activityID, activityDialogOpen]);

    const confirmActivityCallback = useCallback(async (activityId, companyId) => {
        try {
            setError(null);
            setConfirmingActivity(true);
            const updatedActivity = await dispatch(confirmActivity(activityId, companyId));
            setActivity(updatedActivity)
        } catch (err) {
            setConfirmingActivity(false);
            setError(err.message);
        }
    }, [dispatch]);

    const handleConfirm = () => {
        confirmActivityCallback(activity.id, company.id);
    }

    const handleSetActivityFromDecline = (updatedActivity) => {
        setActivity(updatedActivity);
    }

    const handleJoin = () => {
        setShowCustomPurchaseModal(true)
    }

    return (
        <React.Fragment>
            <Helmet title="Confirm Activity" />
            {isLoading &&
                <Box flex={1}>
                    <LinearProgress my={2} color="primary" />
                    <Typography variant={"h2"} align="center" gutterBottom>Loading {ACTIVITY_TEXT}...</Typography>
                    <LinearProgress my={2} color="secondary" />
                </Box>
            }
            <Grid justifyContent="space-between" container spacing={6}>
                {error && !isLoading &&
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={false} sm={1}></Grid>
                            <Grid item xs={12} sm={10}>
                                <Alert severity="error">{error}</Alert>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }

                {activity && !isLoading &&
                    <React.Fragment>
                                                <Grid container>
                            <Grid item xs={false} sm={1}></Grid>
                            <Grid item xs={12} sm={10}>
                                <Box display='flex' justifyContent='center' mt={4} mb={4}>
                                    {(activity.requestConfirmation && !activity.confirmed && !activity.rejected) &&
                                        <React.Fragment>
                                            <ActionButton variant='contained'
                                                color='primary'
                                                mr={4}
                                                onClick={handleConfirm}
                                                submitting={confirmingActivity}
                                            >
                                                Accept
                                            </ActionButton>
                                            <ActionButton variant='outlined'
                                                onClick={() => setDeclineDialogOpen(true)}
                                                disabled={confirmingActivity}
                                            >
                                                Decline
                                            </ActionButton>
                                        </React.Fragment>
                                    }
                                    {(activity.confirmed || (!activity.requestConfirmation && activity.requirePrepay)) &&
                                        <Box mb={6}>
                                            <ActionButton
                                                variant='outlined'
                                                color='primary'
                                                onClick={() =>
                                                    atcb_action({
                                                        name: activity.title,
                                                        startDate: moment(activity.displaybleStartDateTime).format('YYYY-MM-DDTHH:mm'),
                                                        endDate: moment(activity.displaybleStartDateTime).add(activity.duration, 'minutes').format('YYYY-MM-DDTHH:mm'),
                                                        timezone: "currentBrowser",
                                                        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
                                                        recurrence: activity && activity.rrule ? activity.rrule : null,
                                                        trigger: "click",
                                                        iCalFileName: activity.title + " Reminder",
                                                    })
                                                }
                                            >
                                                Add to Calendar
                                            </ActionButton>
                                        </Box>
                                    }
                                    {activity.rejected &&
                                        // <Typography>Declined - {activity.declineReason}</Typography>}
                                        <Typography>Declined</Typography>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={false} sm={1}></Grid>
                            <Grid item xs={12} sm={10}>
                                <Box mx={4} mt={4}>
                                    {!company.isRABSite &&
                                        <ActivityDetail activity={activity}
                                            viewStartDate={null}
                                            viewEndDate={null}
                                            hidePeople
                                            hideDrop
                                            showRecurrance={activity.rrule ? true : false}
                                            titleAction={
                                                allowAddEdit ?
                                                    <ActionButton
                                                        variant='outlined'
                                                        color='primary'
                                                        onClick={() => setActivityDialogOpen(true)}>
                                                        Edit
                                                    </ActionButton> : null
                                            }
                                        />
                                    }
                                    {company.isRABSite &&
                                        <Box>
                                            {activity && (spacesRemaining > 0) &&
                                                <Chip
                                                    // color="secondary"
                                                    size="small"
                                                    mb={2}
                                                    style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}
                                                    label={'Hurry, ' + spacesRemaining + ' spot' + (spacesRemaining === 1 ? '' : 's') + ' remaining'} />
                                            }
                                            {activity && (spacesRemaining <= 0) &&
                                                <Chip
                                                    // color="secondary"
                                                    size="small"
                                                    mb={2}
                                                    style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}
                                                    label={ACTIVITY_TEXT + " is full"} />
                                            }
                                            <NonExpandedActivityDetail activity={activity}
                                                viewStartDate={activity.startDateTime}
                                                viewEndDate={null}
                                                hidePeople
                                                hideDrop
                                                expandActivity
                                                showRecurrance={activity.rrule ? true : false}
                                                titleAction={
                                                    allowAddEdit ?
                                                        <ActionButton
                                                            variant='outlined'
                                                            color='primary'
                                                            onClick={() => setActivityDialogOpen(true)}>
                                                            Edit
                                                        </ActionButton> : null
                                                }
                                            />
                                            <React.Fragment>
                                                {!loggedInUser && activity.allowSelfRegister &&
                                                    <ActionButton
                                                        fullWidth
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() => setLoginDialogOpen(true)}
                                                    >
                                                        Login / Register to Sign-Up for {ACTIVITY_TEXT}
                                                    </ActionButton>
                                                }
                                                {loggedInUser &&
                                                    <React.Fragment>
                                                        {/* {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && activity.allowSelfRegister && !occuranceIsInPast && whenCanRegister > Date.now() &&
                                                            <ActionButton
                                                                disabled
                                                                fullWidth
                                                                variant="contained"
                                                                style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                                                startIcon={<ClockIcon size={ICON_SIZE} />}
                                                            >
                                                                Registration opens {moment(whenCanRegister).format('MM/DD - LT')}
                                                            </ActionButton>
                                                        } */}
                                                        {!activityCanceled && loggedInUser
                                                            // && whenRegistrationCloses >= Date.now()
                                                            // && lastActivity.canSelfRegister(lastActivity, loggedInUser, attendees) 
                                                            && !currentUserInClass && !aFamilySignedUp && !isRunningActivity &&
                                                            <ActionButton
                                                                fullWidth
                                                                variant="contained"
                                                                style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                                                                startIcon={<CheckIcon size={ICON_SIZE} />}
                                                                onClick={handleJoin}
                                                            >
                                                                Register Now!
                                                                <Typography variant="subtitle1" ml={2}>
                                                                    {moment(activity.displaybleStartDateTime).format("MMM D")}
                                                                    {endDateText ? ' - ' + endDateText : ''}
                                                                </Typography>
                                                            </ActionButton>
                                                        }
                                                        {(!activityCanceled && (currentUserInClass || aFamilySignedUp) && !isRunningActivity && !isInPast) &&
                                                            <ActionButton
                                                                fullWidth
                                                                // disabled={(loggedInUser && loggedInUser.childProfiles.length > 0) ? false : true}
                                                                variant="contained"
                                                                style={{ backgroundColor: activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main, color: theme.palette.success.contrastText }}
                                                                onClick={handleJoin}
                                                                startIcon={<CheckIcon size={ICON_SIZE} />}
                                                            >
                                                                {loggedInUser && loggedInUser.childProfiles.length > 0 ? 'You or a family member are signed up. Click to make changes.' : 'You\'re signed up.'}
                                                            </ActionButton>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={false} sm={1}></Grid>
                            <Grid item xs={12} sm={10}>
                                <Box display='flex' justifyContent='center' mt={4} mb={8}>
                                    {(activity.requestConfirmation && !activity.confirmed && !activity.rejected) &&
                                        <React.Fragment>
                                            <ActionButton variant='contained'
                                                color='primary'
                                                mr={4}
                                                onClick={handleConfirm}
                                                submitting={confirmingActivity}
                                            >
                                                Accept
                                            </ActionButton>
                                            <ActionButton variant='outlined'
                                                onClick={() => setDeclineDialogOpen(true)}
                                                disabled={confirmingActivity}
                                            >
                                                Decline
                                            </ActionButton>
                                        </React.Fragment>
                                    }
                                    {(activity.confirmed || (!activity.requestConfirmation && activity.requirePrepay)) &&
                                        <Box mb={6}>
                                            <ActionButton
                                                variant='outlined'
                                                color='primary'
                                                onClick={() =>
                                                    atcb_action({
                                                        name: activity.title,
                                                        startDate: moment(activity.displaybleStartDateTime).format('YYYY-MM-DDTHH:mm'),
                                                        endDate: moment(activity.displaybleStartDateTime).add(activity.duration, 'minutes').format('YYYY-MM-DDTHH:mm'),
                                                        timezone: "currentBrowser",
                                                        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
                                                        recurrence: activity && activity.rrule ? activity.rrule : null,
                                                        trigger: "click",
                                                        iCalFileName: activity.title + " Reminder",
                                                    })
                                                }
                                            >
                                                Add to Calendar
                                            </ActionButton>
                                        </Box>
                                    }
                                    {activity.rejected &&
                                        // <Typography>Declined - {activity.declineReason}</Typography>}
                                        <Typography>Declined</Typography>
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                    </React.Fragment>
                }
                {
                    !isLoading && activity == null && <React.Fragment>
                        <Grid container>
                            <Grid item xs={false} sm={1}></Grid>
                            <Grid item xs={12} sm={10}>
                                <Typography variant='h2' align='center' mt={10}>
                                    No {ACTIVITY_TEXT} Found.
                                </Typography>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid >
            {activityDialogOpen &&
                <ActivityAddEditModal
                    open={activityDialogOpen}
                    setOpen={setActivityDialogOpen}
                    companyId={company.id}
                    viewStartDate={startDate}
                    viewEndDate={startDate}
                    activity={activity}
                    editAllInstances={true}
                />
            }
            {declineDialogOpen &&
                <DeclineDialog
                    activity={activity}
                    open={declineDialogOpen}
                    setOpen={setDeclineDialogOpen}
                    setActivity={handleSetActivityFromDecline}
                />
            }
            {loginDialogOpen &&
                <LoginDialog
                    open={loginDialogOpen}
                    setOpen={setLoginDialogOpen} />
            }
            {showCustomPurchaseModal &&
                <StripeWrapper showTenantPaymentMethods open={showCustomPurchaseModal}>
                    <CustomPurchaseModal
                        open={showCustomPurchaseModal}
                        setOpen={setShowCustomPurchaseModal}
                        displayedUser={loggedInUser}
                        itemTitle={activity.title + " - " + moment(activity.displaybleStartDateTime).format('lll')}
                        itemPrice={activity.getProratePrice(isRecurring)}
                        activity={activity}
                        activityId={activity.id}
                        usersToPrepay={usersToPrepay}
                        // usersToPrepay={userToJoin}
                        onSuccess={() => loadActivityDetail(activity.id)}
                    />
                </StripeWrapper>
            }
        </React.Fragment >
    );
}

export default withTheme(ConfirmActivityPage);
