import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

export const OFFERING_CREATED = 'OFFERING_CREATED';
export const OFFERING_EDITED = 'OFFERING_EDITED';
export const OFFERING_DELETED = 'OFFERING_DELETED';
export const WEBPAGE_UPDATED = 'WEBPAGE_UPDATED';

/*********************************** Offering Actions ******************************/
export const createOffering = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/offerings/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: OFFERING_CREATED, offering: resData });
    };
};

export const editOffering = (companyId, id, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/offerings/' + companyId + "/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: OFFERING_EDITED, offering: resData });
    };
};

export const deleteOffering = (companyId, id, formData) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/offerings/' + companyId + "/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: OFFERING_DELETED, offeringId: id });
    };
};

/*********************************** Web Page Actions ******************************/
export const editWebpage = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/editwebpage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: WEBPAGE_UPDATED, webPage: resData });
    };
};

