import React, { useEffect,  useState, useRef } from 'react';

import {
    Box,
    Tabs as MuiTabs,
    Tab as MuiTab,
} from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

const Tabs = styled(MuiTabs)`
    height: 35px;
    min-height: auto;
    max-height: none;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        height: 100%;
    }
`;

export const StyledTab = styled(MuiTab)`
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: 0 29px 0 29px;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;

    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;

const ResizeTabs = props => {
    const { activeTab, setActiveTab, children, maxWidth, ...tabContainerProps } = props;
    const [width, setWidth] = useState(950);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const tabParentRef = useRef(null);

    // This is because of a bug in Mui v4 with Tab and mobile/tablet resizing.  It supposed to be fixed in v5.
    useEffect(() => {
        if (tabParentRef && tabParentRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                // this callback gets executed whenever the size changes
                // when size changes get the width and update the state
                // so that the Child component can access the updated width
                for (let entry of entries) {
                    if (entry.contentRect) {
                        setWidth(entry.contentRect.width);
                    }
                }
            });

            const currentRef = tabParentRef.current;
            // register the observer for the div
            if (resizeObserver)
                resizeObserver.observe(currentRef);

            // unregister the observer
            return () => resizeObserver.unobserve(currentRef);
        }
    }, []);

    return (
        <Box ref={tabParentRef} >
            <Box width={isMobile ? '280px' : width} maxWidth={maxWidth} {...tabContainerProps}>
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons={isMobile || width < maxWidth ? "on" : "off"}
                >
                    {children}
                </Tabs>
            </Box>
        </Box>
    )
}

export default styled(ResizeTabs)(spacing);