import React, { useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from "yup";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import InputMask from "react-input-mask";

// svxvy imports
import { submitContactForm } from "../../store/actions/companyActions";

// style constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required"),
  email: Yup.string().email()
    .required("Required"),
  message: Yup.string()
    .required("Required")
    .min(3, "Must be at least 3 characters."),
});

const ContactForm = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const recaptchaRef = useRef();
  const company = useSelector(state => state.company.company);
  const recaptchaTheme = company && company.colors && company.colors.useDarkTheme ? 'dark' : 'light';
  const companyId = useSelector(state => state.company.companyId);
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const [sendSuccessfull, setSendSuccessfull] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);

  const dispatch = useDispatch();

  const sendContactFormInfo = useCallback(async (values, companyId, resetForm, recaptchaRefObject) => {
    setError(null);
    setSendSuccessfull(false);
    setIsSubmitting(true);
    try {
      await dispatch(submitContactForm(values, companyId));
      setSendSuccessfull(true);
      resetForm({});
      recaptchaRefObject.reset();
      setRecaptcha(null);
    } catch (error) {
      setError(error.message);
      setSendSuccessfull(false);
    }
    setIsSubmitting(false);

  }, [dispatch]);

  const handleSubmit = async (values, { resetForm }) => {
    const recaptchaRefObject = recaptchaRef.current;
    sendContactFormInfo(values, companyId, resetForm, recaptchaRefObject);
  }

  const onChange = (value) => {
    setRecaptcha(value);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        touched,
        values,
      }) => (
        <Card mb={6} style={{ borderRadius: '10px', boxShadow: 'none', padding: '30px 30px 30px 30px' }}>
          <CardContent style={{ padding: 0 }}>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Name*"
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder=""
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="phoneNumber"
                    mask="(999) 999 - 9999"
                    value={values.phoneNumber}
                    onChange={e => { setFieldValue('phoneNumber', e.target.value, true) }}
                    disabled={false}
                    maskChar=" "
                  >
                    {() => <TextField
                      label="Phone"
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      fullWidth
                      variant="outlined"
                    />}
                  </InputMask>
                </Grid>
              </Grid>
              <TextField
                name="message"
                label="Message*"
                value={values.message}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                multiline={true}
                maxRows={15}
                variant="outlined"
                placeholder=""
              />


              <Box display="flex" flexDirection="column" flex={1} justifyContent='center' alignItems="center">
                <Box style={{ width: isMobile ? '280px' : '308px', marginTop: '15px', marginBottom: '28px' }}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    // size="invisible"
                    sitekey="6LcphCEcAAAAAAspPgmgJ0dTkzUm9lT0Pa355GbH"
                    onChange={onChange}
                    theme={recaptchaTheme}
                  />
                </Box>

                {!isSubmitting &&

                  <Button type="submit" variant="contained" color="primary" disabled={!recaptcha} fullWidth style={{ maxWidth: isMobile ? '280px' : '308px' }}>
                    Submit
                  </Button>
                }

                {isSubmitting && <CircularProgress color="primary" size={20} />}
              </Box>
              {error && <Alert my={2} severity="error">
                {error}
              </Alert>}

            </form>
            {sendSuccessfull && <Alert mt={4} severity="success">
              Thanks for reaching out.  We will get back to you as soon as we can.
            </Alert>}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default ContactForm;
