import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Typography as MuiTypography,
} from "@material-ui/core";

const Typography = styled(MuiTypography)(spacing);

const ParagraphTypography = (props) => {

    const useStyles = makeStyles({
        handleNewLine: {
            whiteSpace: 'pre-line',
        },
        hideOverflow: {
            whiteSpace: 'pre-line',
            display: "-webkit-box",
            boxOrient: "vertical",
            lineClamp: props.numberOfLines ? props.numberOfLines : 2,
            overflow: 'hidden'
        },
    });

    const classes = useStyles();

    let classesToApply = classes.handleNewLine;
    if (props.numberOfLines)
        classesToApply = classes.hideOverflow;

    return (
        <Typography {...props} className={classesToApply}>{props.children}</Typography>
    );
}

export default ParagraphTypography;