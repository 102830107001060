import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import i18n from 'i18n-js';
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';


import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment as MuiInputAdornment,
    LinearProgress as MuiLinearProgress,
    List,
    Tabs as MuiTabs,
    Tab as MuiTab,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoSearchOutline as SearchIcon,
} from "react-icons/io5";

// svxvy import

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions'; 
import { fetchPaymentRecords } from '../../../store/actions/paymentActions';
import { fetchCompanyPurchases } from '../../../store/actions/companyActions';
import { isUnpaidPayment } from '../../../helpers/commerceFunctions';
import { sumReducer, groupPayments } from './billingGroupingFunctions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { refreshLogedInUser } from '../../../store/actions/authActions';

import BillingPersonGroup from '../../../components/company/purchases/Reporting/BillingPersonGroup';
import CompanyPurchaseList from '../../../components/company/purchases/CompanyPurchaseList';

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TextField = styled(MuiTextField)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  margin: 0 0 30px 0;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
    margin: 0;
    float: right;

    input {
        width: 250px;
    }
  }
`;

const InputAdornment = styled(MuiInputAdornment)`
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 9px;

    svg {
        width: 18px;
        height: 18px;
        color: ${(props) => props.theme.palette.primary.main};
        opacity: 1;
    }
`;

const Tabs = styled(MuiTabs)`
    height: 35px;
    min-height: auto;
    max-height: none;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        height: 100%;
    }
`;

const Tab = styled(MuiTab)`
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: 0 29px 0 29px;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;

    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;


const DUE = 0;
const PAID = 1;

const Billing = props => {
    const theme = useTheme();
    const company = useSelector(state => state.company.company);
    const people = useSelector(state => state.company.people);

    const currentUser = useSelector(state => state.auth.person);
    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowUpdatePayments = currentUser && checkClaim(currentUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [activeTab, setActiveTab] = useState(DUE);

    const unpaidCompanyPayments = useSelector(state => state.company.payments).filter(x => isUnpaidPayment(x));
    const filteredPayments = groupPayments(unpaidCompanyPayments.filter(p =>
        search.length > 0
            ? (p.userDisplayName.toLowerCase().includes(search.toLowerCase()) || p.activityName.toLowerCase().includes(search.toLowerCase()))
            : true), allowUpdatePayments ? people : [...currentUser.childProfiles, currentUser]);
    const totalDue = unpaidCompanyPayments && unpaidCompanyPayments.length > 0 ? unpaidCompanyPayments.map(x => x.amount).reduce(sumReducer) : 0;
    
    const dispatch = useDispatch();
    /********************* load data ************************ */
    const loadData = useCallback(async () => {
        try {
            setIsLoading(true);
            if (allowUpdatePayments) {
                dispatch(fetchPeople(company.id));
                dispatch(fetchCompanyPurchases(company.id));
            }
            else {
                await dispatch(refreshLogedInUser());
            }
            await dispatch(fetchPaymentRecords(company.id));

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [ dispatch, company, allowUpdatePayments]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <Helmet title="Payments Due" />
                {error &&
                    <Grid container alignContent="center">
                        <Alert mb={4} severity="error">{error}</Alert>
                    </Grid>
                }
                {isLoading
                    && <Grid container alignContent="center">
                        <Grid item xs={12} >
                            <LinearProgress my={2} color="primary" />
                            <Typography variant={"h2"} gutterBottom>Loading payments...</Typography>
                            <LinearProgress my={2} color="secondary" />
                        </Grid>
                    </Grid>
                }
                {!error && !isLoading &&
                    <Box>
                        <Grid container>
                            <Grid item xs={12} md={6} style={{ display: 'flex', marginBottom: '25px' }}>
                                <Typography variant="h1" style={{ margin: '0 30px 0 0' }}>Billing</Typography>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue === 0 ? DUE : PAID)}
                                    indicatorColor="primary"
                                    centered
                                >
                                    <Tab label='Due' />
                                    <Tab label='Paid' />
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {activeTab === DUE &&
                                    <TextField
                                        id="standard-search"
                                        type="search"
                                        placeholder="Search"
                                        onChange={e => setSearch(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>

                        {/* <Box style={{ width: '100%', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                            <Box style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h1" style={{ margin: '0 30px 0 0' }}>Billing</Typography>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue === 0 ? DUE : PAID)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label='Due' />
                                    <Tab label='Paid' />
                                </Tabs>
                            </Box>
                            {activeTab === DUE &&
                                <Box style={{ width: '100%' }}>
                                    <TextField
                                        id="standard-search"
                                        type="search"
                                        placeholder="Search"
                                        onChange={e => setSearch(e.target.value)}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{ margin: '0', float: 'right' }}
                                    />
                                </Box>} 
                        </Box>*/}
                        {/* Show when DUE Tab is seleted */}
                        {activeTab === DUE &&
                            <Card style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '15px 14px 17px 14px' }}>
                                    <Typography variant="h3">Total Due</Typography>
                                    <Typography variant="h3">{i18n.toCurrency(totalDue, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                                <CardContent style={{ padding: '0 14px 0 14px' }}>
                                    {filteredPayments.length === 0 &&
                                        <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                            <Typography variant="h3">You have no outstanding payments due.</Typography>
                                        </Box>
                                    }
                                    {filteredPayments.length > 0 &&
                                        <List style={{ padding: 0 }}>
                                            {filteredPayments.map((group, index) => {
                                                return (
                                                    <BillingPersonGroup key={group.key} group={group} company={company} displayedUser={group.person} />
                                                )
                                            })}
                                        </List>
                                    }
                                </CardContent>
                            </Card>
                        }
                        {activeTab === PAID &&
                            <CompanyPurchaseList />
                        }
                    </Box>
                }
            </Grid>
        </Grid>
    );
}

export default Billing;
