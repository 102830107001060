export const VIEW_ALL_ACTIVITIES = "allowViewAllActivities";
export const ALLOW_ADD_EDIT_ACTIVITIES = "allowAddEditActivities";
export const ALLOW_DELETE_ACTIVITIES = "allowDeleteActivities";
export const ALLOW_ADD_EDIT_LOCATION = "allowAddEditLocation";
export const ALLOW_DELETE_LOCATION = "allowDeleteLocation";
export const VIEW_REPORTS = "allowViewReports";
export const VIEW_ALL_PEOPLE = "allowViewAllPeople";
export const ALLOW_DELETE_PEOPLE = "allowAddEditAllPeople";
export const ALLOW_ADD_EDIT_ALL_PEOPLE = "allowAddEditAllPeople";
export const VIEW_EXTENDED_PROFILE = "allowViewExtendedProfile";
export const MANAGAE_ROLES = "allowManageRoles";
export const ADD_EDIT_ROLES = "allowAddEditRoles";
export const DELETE_ROLES = "allowDeleteRoles";
export const ALLOW_MESSAGE_TO_ALL = "allowMessageToAll";
export const ALLOW_EDIT_COMPANY_ATTRIBUTES = "allowEditCompanyAttributes";
export const UPDATE_PAYMENTS = "updatePayments";
export const ALLOW_VIEW_REPORTS = "allowViewReports";


export const ROLE_OWNER = "Owner";

const ALLOWED_SVXVY_ADMINS = "1b0806ae-5efd-44ca-94f6-d423e45b634c";

export const isSVXVYAdmin = (id) => {
    if (ALLOWED_SVXVY_ADMINS.includes(id))
        return true;
    return false;
} 