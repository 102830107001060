import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { RRule } from 'rrule';

import { useMediaQuery } from "@material-ui/core";

import { Formik, Field, FieldArray } from 'formik'
import * as Yup from "yup";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
    Box,
    Button as MuiButton,
    Card as MuiCardWithSpacing,
    Checkbox as MuiCheckbox,
    FormGroup,
    FormControlLabel as MuiFormControlLabel,
    FormHelperText as MuiFormHelperText,
    Grid as MuiGrid,
    IconButton as MuiIconButton,
    MenuItem,
    Popover as MuiPopover,
    Select,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypography,
    Divider,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { Alert as MuiAlert } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { SketchPicker as MuiSketchPicker } from 'react-color';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as AddIcon,
    IoRemoveCircleOutline as RemoveIcon,
    IoTrash as DeleteIcon,
    IoCalendarOutline as CalendarIcon,
    IoHelpCircleOutline as HelpIcon,
    IoChevronUp as ChevronUp,
    IoChevronDown as ChevronDown,
    IoAlertCircleOutline as NoteIcon,
    // IoRepeat as RepeatIcon,
} from "react-icons/io5";

// svxvy imports
import { addActivity, editActivity, deleteActivity, deleteSeries } from '../../../store/actions/activityActions';
import { GUID_Generator, compareObjects } from '../../../helpers/helperFunctions';
import { EMPTY_LOCATION, EMPTY_LOCATION_ID } from '../../../models/Location';
import SelectUser from '../SelectUser';
import RRuleEditor from './RRuleEditor';
import StyledDialog from '../../framework/StyledDialog';
import ActionButton from '../../framework/ActionButton';
import SelectManageAov, { DESCRIPTION, REMINDER } from './SelectManageAov';
import SelectFormControl from '../../framework/SelectFormControl';
import ErrorDialog from '../../framework/ErrorDialog';

// styled
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Checkbox = styled(MuiCheckbox)(spacing);
const Grid = styled(MuiGrid)(spacing);
const MuiCard = styled(MuiCardWithSpacing)(spacing);


const FormHelperText = styled(MuiFormHelperText)`
  color:${(props) => props.theme.palette.error.main};  
`;

const TextField = styled(MuiTextField)(spacing);
// const TextField = styled(MuiTextField)`
//     p {
//         margin: 0;
//     }
// `;

const SmallTextField = styled(MuiTextField)`
    width: 54px;
    margin: 0 15px 0 0;

    & .MuiOutlinedInput-root {
        & input {
            padding: 13px 0 13px 0;
        }
    }
`;

const SmallSelect = styled(Select)`
    width: 100px;
    margin: 0 15px 0 0;
    .MuiSelect-root {
        padding-top: 15px;
    }
    svg {
        margin-top: 5px !important;
    }
`;

const Typography = styled(MuiTypography)(spacing);

const TypographySelect = styled(MuiTypography)`
    color: ${(props) => props.theme.palette.primary.main};
    padding-right: 5px;
    position: relative;
    font-weight: 700;
    font-size: 1.2rem;

    // &:after {
    //     width: 10px;
    //     height: 7px;
    //     background: red;
    //     display: block;
    //     position: absolute;
    //     top: 2px;
    //     right: 0;
    //     z-index: 1;
    //     content: '';
    //     pointer-events: none;
    // }
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
    margin: 0;
  }
`;

const FormControlLabelPublic = styled(MuiFormControlLabel)`
  margin: 0;
  padding: 0 0 0 15px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 11px 0 0 5px;
  }
`;

const IconButtonPublic = styled(MuiIconButton)`
    margin-top: -8px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        margin-top: 2px;
    }
`;

const Card = styled(MuiCard)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  margin-bottom: 15px;
`;

const Popover = styled(MuiPopover)`
.MuiPopover-paper{
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 15px;
}
`;

const TipPopup = styled(Box)`
    width: 100%;
    max-width: 250px;
    background-color: ${(props) => props.theme.palette.background.paper};
    // border: 1px solid red;
    border-radius: 10px;
    padding: 15px;
`;

const SketchPicker = styled(MuiSketchPicker)`
    background-color: ${(props) => props.theme.palette.background.paper} !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    border-color: green;


    .flexbox-fix {
        background-color: ${(props) => props.theme.palette.background.paper};
        border: none !important;
    }

    label {
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    input {
        box-shadow: none !important;
        border: 1px solid ${(props) => props.theme.palette.divider} !important;
    }
`;

const createAttendeeObject = (id, isEmployee, attendAllInstances, attendInstanceDates, dropInstanceDates) => {
    const ao = { appUserId: id, isRunningActivity: isEmployee, attendAllInstances: attendAllInstances, attendInstanceDates: attendInstanceDates, dropInstanceDates: dropInstanceDates };
    return ao;
};


const createPriceObject = () => {
    return {
        id: GUID_Generator(),
        productId: '',
        price: '',
        usePercent: false,
        discountPercent: '10',
        isNew: true
    }
}

const simpleArrayEquals = (a, b) =>
    a.length === b.length &&
    a.every((v, i) => v === b[i]);

const displayWarning = (valuesToSend, displayedActivity) => {
    const oldRule = RRule.fromString(displayedActivity.rrule);
    const newRule = RRule.fromString(valuesToSend.rrule);
    // console.log(oldRule)
    // console.log(newRule)
    // console.log(oldRule.options.interval !== newRule.options.interval, oldRule.options.interval, newRule.options.interval)
    // console.log(oldRule.options.freq !== newRule.options.freq, oldRule.options.freq, newRule.options.freq)
    // if (oldRule.options.until)
    //     console.log(oldRule.options.until.getTime() !== newRule.options.until.getTime(), oldRule.options.until.getTime(), newRule.options.until.getTime())
    // console.log(!simpleArrayEquals(oldRule.options.byweekday, newRule.options.byweekday), oldRule.options.byweekday, newRule.options.byweekday)
    // console.log(displayedActivity.displaybleStartDateTime.getTime(), valuesToSend.startDateTime.getTime(), displayedActivity.people.some(x => !x.attendAllInstances))

    if ((oldRule.options.interval !== newRule.options.interval
        || oldRule.options.freq !== newRule.options.freq
        || (oldRule.options.until && newRule.options.until && oldRule.options.until.getTime() !== newRule.options.until.getTime())
        || (oldRule.options.byweekday && !simpleArrayEquals(oldRule.options.byweekday, newRule.options.byweekday))
        || displayedActivity.displaybleStartDateTime.getTime() !== valuesToSend.startDateTime.getTime())

        && displayedActivity.people.some(x => !x.attendAllInstances)) {
        const r = window.confirm('Changes to the recurrances may cause users who have registered for a specific class to no longer be registered. They will NOT be alerted to this change.');
        if (!r) {
            return false;
        }
    }
    return true;
}


const ActivityAddEditModal = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const company = useSelector(state => state.company.company)
    const activeSubscriptions = useSelector(state => state.products.products).filter(x => x.isRecurring);
    const inactiveSubscriptions = useSelector(state => state.products.inactiveProducts).filter(x => x.isRecurring);
    const subscriptions = [...activeSubscriptions, ...inactiveSubscriptions]
    subscriptions.sort(compareObjects('name', 'asc'));

    const packages = useSelector(state => state.products.products).filter(x => x.units > 0).sort(compareObjects('name', 'asc'));

    const locations = useSelector(state => state.company.locations).filter(x => !x.isDeleted);
    if (company.allowManualLocations)
        locations.push(EMPTY_LOCATION);

    const people = useSelector(state => state.company.people).filter(p => p.isDeleted ? false : true).sort(compareObjects('displayName', 'asc'));
    const employees = people.filter(p => !p.isDeleted && p.isEmployee);

    const displayedActivity = props.activity;
    // const [displayedActivity, setDisplayedActivity] = useState(props.activity); 
    // const parentSeries = useSelector( state => state.activity.activities).find( x => x.id === props.activity.parentSeries);
    // console.log(props.activity.parentSeries, parentSeries)

    const createCopy = props.createCopy;

    const editAllInstances = props.editAllInstances || !props.activity ? true : false;
    const peopleRunningActivity = displayedActivity ? employees.filter(p => displayedActivity.people.find(a => a.isRunningActivity && a.appUserId === p.id && (editAllInstances ? a.attendAllInstances : displayedActivity.isInActivityInstance(a, displayedActivity, a.attendAllInstances)))) : employees.length === 1 ? employees : [];
    const peopleInActivity = company.isRABSite && createCopy ? []
        : displayedActivity
            ? people.filter(p => displayedActivity.people.find(a => !a.isRunningActivity && a.appUserId === p.id && (editAllInstances ? a.attendAllInstances : displayedActivity.isInActivityInstance(a, displayedActivity, a.attendAllInstances))))
            : props.initialAttendees ? props.initialAttendees : [];

    const activityStartIsInPast = displayedActivity ? Date.now() >= displayedActivity.originalStartDate.getTime() : false;
    const isFirstInstance = displayedActivity ? displayedActivity.originalStartDate.getTime() >= displayedActivity.occuranceId : false;

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const LOCATION_TEXT = useSelector(state => state.company.locationText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);
    const EMPLOYEE_TEXT = useSelector(state => state.company.employeeText);
    const EMPLOYEES_TEXT = useSelector(state => state.company.employeesText);
    const TIME_UNITS = useSelector(state => state.activity.timeUnits);

    // const [isRecurringEvent, setIsRecurringEvent] = useState(displayedActivity && displayedActivity.rrule && displayedActivity.rrule.trim().length > 0 ? true : false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(null)
    const [rrule, setRRule] = useState(props.activity && props.activity.rrule && props.activity.rrule.length > 0
        ? props.activity.rrule
        : company.isRABSite ? 'RRULE:FREQ=WEEKLY;INTERVAL=1;COUNT=8' : '');
    const [isrRuleValid, setIsrRuleValid] = useState(true)
    const [showAdvancedPricing, setShowAdvancedPricing] = useState(displayedActivity
        && ((displayedActivity.subscriptionPrices && displayedActivity.subscriptionPrices.length > 0)
            || (displayedActivity.allowedPackages && displayedActivity.allowedPackages.length > 0)
            || displayedActivity.splitPayment)
        ? true : company.isRABSite ? true : false);

    const isRecurring = rrule && rrule !== null && rrule.trim().length > 0;
    const rruleAsRule = rrule && rrule !== null && rrule.trim().length > 0 ? RRule.fromString(rrule) : null;

    //=================================================================

    const dispatch = useDispatch();

    const { setOpen } = props;
    const handleClose = useCallback(() => {
        setIsSubmitting(false);
        setIsDeleting(false);
        setError(null);
        setRRule(null);
        setOpen(false);
    }, [setOpen])

    const deleteActivityHandler = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
        try {
            setIsDeleting(true);
            setError(null);
            const activityIdToSend = activityId.indexOf('_') >= 0 ? activityId.split('_')[0] : activityId;
            const occuranceId = activityId.indexOf('_') >= 0 ? activityId.split('_')[1] : 0;
            if (editAllInstances && isRecurring)
                await dispatch(deleteSeries(activityIdToSend, occuranceId, companyId, viewStartDate, viewEndDate));
            else
                await dispatch(deleteActivity(activityIdToSend, occuranceId, companyId, viewStartDate, viewEndDate));
            handleClose()
        } catch (err) {
            setIsDeleting(false);
            setError(err.message);
        }
    }, [dispatch, handleClose, editAllInstances, isRecurring]);


    const handleDelete = () => {
        let additionalMessage = '\n\nEnrolled ' + CUSTOMERS_TEXT.toLowerCase() + ' are not notified when you delete a ' + ACTIVITY_TEXT.toLowerCase() + '.  To notify them cancel the ' + ACTIVITY_TEXT.toLowerCase() + ' first.'
        if (displayedActivity.endDateTime.getTime() > 5000)
            additionalMessage += '\n\nThis will only affect the part of the series that ends on ' + moment(displayedActivity.endDateTime).format('lll');

        const r = window.confirm('Are you sure you want to delete this ' + (isRecurring ? 'series' : ACTIVITY_TEXT.toLowerCase()) + '?' + additionalMessage);
        if (r) {
            deleteActivityHandler(displayedActivity.id, props.companyId, props.viewStartDate, props.viewEndDate);
        }
    }

    Yup.addMethod(Yup.object, 'checkRRule', (message) => {
        return Yup.object().test('checkRRule', message, async (name, message) => {
            if (!isrRuleValid) {
                return message.createError({ path: message.path, message: 'Required' });;
            }
            return true;
        });
    });

    // console.log(displayedActivity.subscriptionPrices)
    // console.log(subscriptions)

    //==================================================================
    return (
        <StyledDialog
            open={props.open}
            setOpen={handleClose}
            actions={props.activity && editAllInstances &&
                <ActionButton
                    submitting={isDeleting}
                    variant="text"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                    startIcon={<DeleteIcon size={24} style={{ color: theme.palette.error.main }} />}
                >
                    Delete {isRecurring ? company.isRABSite ? 'Session' : 'Series' : ACTIVITY_TEXT}
                </ActionButton>}
            primaryAction={
                <ActionButton my={2}
                    form="activityEdit"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isDeleting}
                    submitting={isSubmitting}
                >
                    {props.activity ? (props.createCopy ? "Create copy" : "Save") : "Create New " + ACTIVITY_TEXT}
                </ActionButton>
            }
        >
            <Formik
                initialValues={{
                    title: props.activity ? props.activity.title : "",
                    locationId: props.activity ? props.activity.locationId : locations.length === 1 ? locations[0].id : props.initialAddress ? EMPTY_LOCATION_ID : '',
                    manualLocation: props.activity && props.activity.manualLocation ? props.activity.manualLocation : props.initialAddress ? props.initialAddress : "",
                    activityResources: props.activity && props.activity.activityResources ? props.activity.activityResources : "",
                    meetingUrl: props.activity ? props.activity.meetingUrl : '',
                    description: props.activity ? props.activity.description : "",
                    employees: props.activity ? peopleRunningActivity : employees.length === 1 ? employees : [],
                    attendees: peopleInActivity,
                    startDateTime: props.activity ? props.activity.displaybleStartDateTime : props.start ? props.start : null,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    duration: props.activity ? props.activity.duration : props.duration ? props.duration : company.isRABSite ? '45' : '',
                    // rrule: props.activity && props.activity.rrule && props.activity.rrule.length > 0 ? props.activity.rrule : '',
                    totalSpaces: props.activity ? props.activity.totalSpaces.toString() : company.isRABSite ? '12' : '0',
                    price: props.activity ? props.activity.price.toString() : "",
                    seriesPrice: props.activity && props.activity.seriesPrice ? props.activity.seriesPrice.toString() : "",
                    allowSelfRegister: displayedActivity ? displayedActivity.allowSelfRegister : company.isRABSite ? true : false,
                    selfRegisterStartValue: displayedActivity && displayedActivity.selfRegisterStartValue ? displayedActivity.selfRegisterStartValue.toString() : company.isRABSite ? '999' : '24',
                    selfRegisterStartUnit: displayedActivity && displayedActivity.selfRegisterStartUnit ? displayedActivity.selfRegisterStartUnit : company.isRABSite ? TIME_UNITS[2].id : TIME_UNITS[1].id,
                    selfRegisterEndValue: displayedActivity && displayedActivity.selfRegisterEndValue != null ? displayedActivity.selfRegisterEndValue.toString() : '',
                    selfRegisterEndUnit: displayedActivity && displayedActivity.selfRegisterEndUnit ? displayedActivity.selfRegisterEndUnit : TIME_UNITS[1].id,
                    allowSelfDrop: displayedActivity ? displayedActivity.allowSelfDrop : false,
                    selfDropStartValue: displayedActivity && displayedActivity.selfDropStartValue ? displayedActivity.selfDropStartValue.toString() : '1',
                    selfDropStartUnit: displayedActivity && displayedActivity.selfDropStartUnit ? displayedActivity.selfDropStartUnit : TIME_UNITS[1].id,
                    allowSelfCancel: displayedActivity ? displayedActivity.allowSelfCancel : false,
                    selfCancelStartValue: displayedActivity && displayedActivity.selfCancelStartValue ? displayedActivity.selfCancelStartValue.toString() : '24',
                    selfCancelStartUnit: displayedActivity && displayedActivity.selfRegisterStartUnit ? displayedActivity.selfCancelStartUnit : TIME_UNITS[1].id,
                    selfScheduleStartUnit: TIME_UNITS[1].id,
                    isPublic: displayedActivity ? displayedActivity.isPublic : false,
                    subscriptionPrices: displayedActivity ? displayedActivity.subscriptionPrices : [],
                    allowedPackages: displayedActivity ? displayedActivity.allowedPackages : [],
                    requirePrepayment: displayedActivity ? displayedActivity.requirePrepayment : company.isRABSite ? true : false,
                    requireCreditCard: displayedActivity ? displayedActivity.requireCreditCard : false,
                    splitPayment: displayedActivity ? displayedActivity.splitPayment : false,
                    sendReminder: displayedActivity ? displayedActivity.sendReminder : (company.globalReminderMessage && company.globalReminderMessage.length > 0 ? true : false),
                    reminderHours: displayedActivity && displayedActivity.reminderHours ? displayedActivity.reminderHours.toString() : (company.globalReminderMessage && company.globalReminderMessage.length > 0 && company.globalReminderHours ? company.globalReminderHours.toString() : '24'),
                    reminderMessage: displayedActivity ? displayedActivity.reminderMessage : company.globalReminderMessage,
                    requestConfirmation: displayedActivity ? displayedActivity.requestConfirmation : false,
                    confirmed: displayedActivity ? displayedActivity.confirmed : false,
                    rejected: displayedActivity ? displayedActivity.rejected : false,
                    fileLocation: displayedActivity ? displayedActivity.fileLocation : null,
                    calendarColor: displayedActivity && displayedActivity.calendarColor ? displayedActivity.calendarColor : theme.palette.primary.main,
                    autoCancel: displayedActivity ? displayedActivity.autoCancel : false,
                    autoCancelMinutes: displayedActivity ? displayedActivity.autoCancelMinutes + '' : '0',
                    allowSeriesRegistration: displayedActivity ? displayedActivity.allowSeriesRegistration : company.isRABSite ? true : false,
                    dontAllowDropIns: displayedActivity ? displayedActivity.dontAllowDropIns : false,
                    isPrivate: displayedActivity ? displayedActivity.isPrivate : false,
                    privateUntil: displayedActivity ? displayedActivity.privateUntil : null,
                    twoOrMoreMemberDiscount: displayedActivity ? displayedActivity.twoOrMoreMemberDiscount : 0,
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().max(55).required("Required"),
                    activityResources: Yup.string().max(100),
                    totalSpaces: Yup.mixed().when('allowSelfRegister', {
                        is: true,
                        then:
                            Yup.number().typeError('Must be a number').integer("Must be a whole number.").min(0, "Spaces must be greater than 0.").required("Required")
                        ,
                    }),
                    duration: Yup.number().integer("Must be a whole number.").min(1, { ACTIVITY_TEXT } + " must last longer than 0 minutes").required("Required"),
                    price: Yup.number().min(0, ACTIVITY_TEXT + " cost must be greater than 0.").required("Required"),
                    seriesPrice: company.isRABSite
                        ? Yup.number().min(0, ACTIVITY_TEXT + " cost must be greater than 0.").required("Required")
                        : Yup.number().min(0, ACTIVITY_TEXT + " cost must be greater than 0."),
                    startDateTime: Yup.date().required("Required"),
                    selfRegisterStartValue:
                        Yup.mixed().when('allowSelfRegister', {
                            is: true,
                            then: Yup.number()
                                .typeError('Must be a number')
                                .integer("Must be a whole number.")
                                .min(0, "Must be greater or equal to 0.")
                                .required("Required"),
                        }),
                    selfRegisterEndValue:
                        Yup.mixed().when('allowSelfRegister', {
                            is: true,
                            then: Yup.number()
                                .typeError('Must be a number')
                                .integer("Must be a whole number.")
                                .min(0, "Must be greater or equal to 0."),
                        }),
                    selfDropStartValue:
                        Yup.mixed().when('allowSelfRegister', {
                            is: true,
                            then: Yup.number()
                                .typeError('Must be a number')
                                .integer("Must be a whole number.")
                                .min(0, "Must be greater or equal to 0.")
                                .required("Required"),
                        }),
                    reminderHours:
                        Yup.mixed().when('sendReminder', {
                            is: true,
                            then: Yup.number()
                                .typeError('Must be a number')
                                .integer("Must be a whole number.")
                                .min(0, "Must be greater or equal to 0.")
                                .required("Required"),
                        }),
                    subscriptionPrices: Yup.array().of(
                        Yup.object().shape({
                            productId: Yup.string()
                                .required("Required"),
                            usePercent: Yup.boolean(),
                            price: Yup.mixed().when('usePercent', {
                                is: false,
                                then: Yup.number()
                                    .typeError('Must be a number')
                                    .min(0, "Must be greater than $0.00")
                                    .required("Required")
                            }),
                            discountPercent:
                                Yup.mixed().when('usePercent', {
                                    is: true,
                                    then:
                                        Yup.number()
                                            .typeError('Must be a number')
                                            .integer("Must be a whole number.")
                                            .min(1, "Must be greater than 0")
                                            .max(100, "Must be less than or equal to  100")
                                            .required("Required"),
                                })
                        })
                    ),
                    isrRuleFormValid: Yup.object()
                        .checkRRule(),
                })}
                onSubmit={async (values, { setErrors, setStatus }) => {
                    setIsSubmitting(true)
                    try {
                        const valuesToSend = cloneDeep(values);
                        valuesToSend.rrule = rrule;
                        if (!valuesToSend.allowSelfRegister) {
                            valuesToSend.totalSpaces = "0";
                            valuesToSend.selfRegisterStartValue = null;
                            valuesToSend.selfRegisterEndValue = null;
                            valuesToSend.selfDropStartValue = null;
                        }
                        if (!valuesToSend.sendReminder) {
                            valuesToSend.reminderHours = null;
                        }
                        if (valuesToSend.locationId === ''
                            || (valuesToSend.locationId === EMPTY_LOCATION_ID && valuesToSend.manualLocation.trim().length === 0)) {
                            const message = "Please select a location";
                            throw Error(message);
                        }

                        if (values.employees.some(x => values.attendees.find(z => z.id === x.id))) {
                            let alertMessage = 'Please correct errors before saving.';
                            const usersInBothInputs = values.employees.filter(x => values.attendees.find(z => z.id === x.id)).map(x => x.displayName);
                            alertMessage = 'A person cannot be in both ' + EMPLOYEES_TEXT.toLowerCase() + ' and ' + CUSTOMERS_TEXT.toLowerCase() + ' lists.\n\n' + usersInBothInputs.join('\n');
                            throw Error(alertMessage);
                        }
                        // do not allow an event that ends in the past to be created.
                        if (!company.isRABSite && values.startDateTime.getTime() + (values.duration * 60000) < new Date().getTime()) {
                            const message = 'This ' + ACTIVITY_TEXT.toLowerCase() + ' ends in the past.\n\nPlease update the start date/time or the durration.';
                            throw Error(message);
                        }

                        valuesToSend.totalSpaces = valuesToSend.allowSelfRegister ? values.totalSpaces.toString() : "0";
                        valuesToSend.duration = values.duration.toString();

                        valuesToSend.employees = values.employees.map((person) => {
                            return createAttendeeObject(person.id, true, true, person.attendInstanceDates, person.dropInstanceDates);
                        });
                        valuesToSend.attendees = values.attendees.map((person) => {
                            return createAttendeeObject(person.id, false, true, person.attendInstanceDates, person.dropInstanceDates);
                        });

                        for (const row in valuesToSend.subscriptionPrices) {
                            const priceRow = valuesToSend.subscriptionPrices[row];
                            if (priceRow.price !== '')
                                priceRow.price = Number(priceRow.price);
                            else
                                priceRow.price = values.price ? Number(values.price) : 0;

                            if (priceRow.discountPercent !== '')
                                priceRow.discountPercent = Number(priceRow.discountPercent);
                            else
                                priceRow.discountPercent = 0;
                        }

                        if (props.createCopy || !displayedActivity) {
                            await dispatch(addActivity(valuesToSend, props.companyId, props.viewStartDate, props.viewEndDate));
                        }
                        else {
                            let activityIdToSend = displayedActivity.id.indexOf('_') >= 0 ? displayedActivity.id.split('_')[0] : displayedActivity.id;
                            const instanceId = displayedActivity.id.indexOf('_') >= 0 ? displayedActivity.id.split('_')[1] : "";
                            if (!editAllInstances)
                                valuesToSend.occuranceId = displayedActivity.occuranceId;

                            if (!company.isRABSite && displayedActivity.rrule != null && displayedActivity.rrule.trim().length > 0 && editAllInstances && (activityStartIsInPast || !isFirstInstance)) {
                                if (!displayWarning(valuesToSend, displayedActivity)) {
                                    setIsSubmitting(false);
                                    return;
                                }

                                //get people that are not attending all instances and add them to the "new" event.  If the time or date of the recurrance has changed they won't show up.  But it will include them for any other edit.
                                //TODO: figure out when time has changed and send message to those users

                                // const newStartTime = valuesToSend.startDateTime.getTime();
                                const attendeeObjectsForPeopleNotInAllInstances = displayedActivity.people.filter(
                                    a => !a.isRunningActivity
                                        && !a.attendAllInstances
                                        && a.attendInstanceDates.trim().length > 0
                                        && a.attendAllInstances === false
                                        && !valuesToSend.attendees.some(x => x.appUserId === a.appUserId)
                                    // && a.attendInstanceDates.split('|').some(x => x >= (newStartTime-displayedActivity.timezoneOffset)) // this doesn't work when the activity crosses dayligh savings time
                                ).map((person) => {
                                    return createAttendeeObject(person.appUserId, false, false, person.attendInstanceDates, person.dropInstanceDates);
                                });

                                // console.log( displayedActivity.timezoneOffset)
                                // console.log( attendeeObjectsForPeopleNotInAllInstances)
                                valuesToSend.attendees = [...valuesToSend.attendees, ...attendeeObjectsForPeopleNotInAllInstances];

                                const attendeeObjectsForEmployeesNotInAllInstances = displayedActivity.people.filter(
                                    a => a.isRunningActivity
                                        && !a.attendAllInstances
                                        && a.attendInstanceDates.trim().length > 0
                                        && !valuesToSend.employees.some(x => x.appUserId === a.appUserId)
                                    // && a.attendInstanceDates.split('|').some(x => x >= newStartTime)   // this doesn't work when the activity crosses dayligh savings time
                                ).map((person) => {
                                    return createAttendeeObject(person.appUserId, true, false, person.attendInstanceDates, person.dropInstanceDates);
                                });
                                valuesToSend.employees = [...valuesToSend.employees, ...attendeeObjectsForEmployeesNotInAllInstances];

                                valuesToSend.previousActivityId = activityIdToSend;
                                valuesToSend.previousActivityEndDateTime = new Date(valuesToSend.startDateTime < displayedActivity.displaybleStartDateTime ? (valuesToSend.startDateTime.getTime() - 1000) : (displayedActivity.displaybleStartDateTime.getTime() - 1000));
                                valuesToSend.endDateTime = displayedActivity.endDateTime;
                                // throw new Error("not implemented")
                                await dispatch(addActivity(valuesToSend, props.companyId, props.viewStartDate, props.viewEndDate));
                            }
                            else {
                                if (!company.isRABSite && !editAllInstances) {
                                    // editting an occurance in a series.
                                    // 1 - create a new one off activity
                                    // 2 - add its "parent" the API will take care of adding the "exception" 
                                    valuesToSend.occuranceId = displayedActivity.occuranceId;
                                    valuesToSend.rrule = null;
                                    valuesToSend.parentSeries = displayedActivity.id.split('_')[0];
                                    await dispatch(addActivity(valuesToSend, props.companyId, props.viewStartDate, props.viewEndDate));
                                }
                                else {
                                    if (!displayWarning(valuesToSend, displayedActivity)) {
                                        setIsSubmitting(false);
                                        return;
                                    }
                                    console.log(valuesToSend)
                                    await dispatch(editActivity(activityIdToSend, instanceId, valuesToSend, props.companyId, props.viewStartDate, props.viewEndDate));
                                }
                            }
                        }
                        setIsSubmitting(false);
                        handleClose()
                    } catch (error) {
                        const message = error.message || "Something went wrong";
                        setStatus({ success: false });
                        // setErrors({ submit: message });
                        setError(message)
                        setIsSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    // isSubmitting,
                    touched,
                    values,
                }) => (
                    <form id="activityEdit" onSubmit={handleSubmit}>
                        {displayedActivity && (!isRecurring || !editAllInstances) && !createCopy && <Typography component="h2" variant="h1" mb={4}>Edit {ACTIVITY_TEXT}</Typography>}
                        {displayedActivity && (displayedActivity.parentSeries || (isRecurring && !editAllInstances)) && !createCopy &&
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <NoteIcon size={24} style={{ marginRight: '5px' }} color={theme.palette.text.secondary} />
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                >This event is part of a recurring series.</Typography>
                                {/* <Button
                                    ml={2}
                                    startIcon={<RepeatIcon size={24} style={{ color: theme.palette.secondary.main }} />}
                                    onClick={() => setDisplayedActivity(parentSeries)}>
                                    Edit Series</Button> */}
                            </Box>
                        }
                        {displayedActivity && editAllInstances && isRecurring
                            && displayedActivity.originalStartDate !== displayedActivity.displaybleStartDateTime && !createCopy &&
                            <React.Fragment>
                                <Typography component="h2" variant="h1" mb={5}>Edit {company.isRABSite ? 'Session' : 'Series'}</Typography>
                                <Grid container spacing={3}>
                                    {!company.isRABSite &&
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                name="title"
                                                label={"Changes Effective"}
                                                value={moment(displayedActivity.displaybleStartDateTime).format('ll')}
                                                fullWidth
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                                {(popupState) => (
                                                                    <React.Fragment>
                                                                        <IconButton aria-label="Public Help" size="medium" color='primary' ml={2} {...bindTrigger(popupState)}>
                                                                            <HelpIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                        <Popover
                                                                            {...bindPopover(popupState)}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'center',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'center',
                                                                            }}
                                                                        >
                                                                            <TipPopup>
                                                                                <Typography>
                                                                                    Changes made here will take effect as of this date.  Unless you edit the start date.
                                                                                </Typography>
                                                                            </TipPopup>
                                                                        </Popover>
                                                                    </React.Fragment>
                                                                )}
                                                            </PopupState>
                                                            <CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    }

                                    {!company.isRABSite && rruleAsRule && rruleAsRule.options.until &&
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                name="title"
                                                label={"Through"}
                                                value={moment(rruleAsRule.options.until).format('ll')}
                                                fullWidth
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    }
                                    {displayedActivity && !displayedActivity.endDateTime && rruleAsRule && rruleAsRule.origOptions.count &&
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                name="title"
                                                label={ACTIVITIES_TEXT + ' Remaining'}
                                                value={(rruleAsRule.origOptions.count - displayedActivity.recurranceOccurancesInPast)}
                                                fullWidth
                                                disabled={true}
                                            />
                                        </Grid>

                                    }
                                </Grid>
                            </React.Fragment>
                        }
                        {/*{editAllInstances && */}
                        <React.Fragment>
                            <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Details</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        variant="outlined"
                                        name="title"
                                        label={ACTIVITY_TEXT + " Name"}
                                        value={values.title}
                                        error={Boolean(touched.title && errors.title)}
                                        helperText={touched.title && errors.title}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        // my={2}
                                        fullWidth
                                    />
                                    <SelectManageAov type={DESCRIPTION} onSelect={(value) => setFieldValue('description', value.text)} />
                                    <TextField
                                        variant="outlined"
                                        name="description"
                                        label="Description (optional)"
                                        value={values.description}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        maxRows={15}
                                        fullWidth
                                        margin="dense"
                                    />
                                    {locations && locations.length > 1 &&
                                        <SelectFormControl fullWidth labelText={'Select ' + LOCATION_TEXT} labelId="select-locationId-label">
                                            <Select
                                                labelId="select-locationId-label"
                                                id="locationId"
                                                fullWidth
                                                value={values.locationId}
                                                label={"Select " + LOCATION_TEXT} /// nned both this line and Input lavel
                                                onChange={(event) => setFieldValue('locationId', event.target.value)}
                                                IconComponent={ChevronDown}
                                            >
                                                {locations.map((location) => (
                                                    <MenuItem key={location.id} value={location.id} selected={values.locationId === location.id ? true : false}>
                                                        {location.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </SelectFormControl>
                                    }
                                    {values.locationId === EMPTY_LOCATION_ID && company.allowManualLocations &&
                                        <TextField
                                            variant="outlined"
                                            name="manualLocation"
                                            label="Location"
                                            value={values.manualLocation}
                                            error={Boolean(touched.manualLocation && errors.manualLocation)}
                                            helperText={touched.manualLocation && errors.manualLocation}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            multiline
                                            maxRows={15}
                                            fullWidth
                                            margin="dense"
                                        />
                                    }
                                    {values.locationId &&
                                        locations.find(x => x.id === values.locationId) &&
                                        locations.find(x => x.id === values.locationId).isVirtual &&
                                        <TextField
                                            variant="outlined"
                                            name="meetingUrl"
                                            label={"Webcast URL"}
                                            value={values.meetingUrl}
                                            error={Boolean(touched.meetingUrl && errors.meetingUrl)}
                                            helperText={touched.meetingUrl && errors.meetingUrl}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    }
                                    <TextField
                                        variant="outlined"
                                        name="activityResources"
                                        label="Room/Resource"
                                        value={values.activityResources}
                                        error={Boolean(touched.activityResources && errors.activityResources)}
                                        helperText={touched.activityResources && errors.activityResources}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display="flex" flexDirection="column" >
                                        <Box display="flex" flexDirection="row">
                                            <FormGroup style={{ margin: 0 }}>
                                                <FormControlLabelPublic control={
                                                    <Switch name='isPublic' onChange={(val) => setFieldValue('isPublic', !values.isPublic)} checked={values.isPublic} />
                                                } label={'Public'} />
                                            </FormGroup>
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <React.Fragment>
                                                        <IconButtonPublic aria-label="Public Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                            <HelpIcon fontSize="inherit" />
                                                        </IconButtonPublic>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                        >
                                                            <TipPopup>
                                                                <Typography>
                                                                    Setting this to 'on' will show this activity on the public website calendar.
                                                                </Typography>
                                                            </TipPopup>
                                                        </Popover>
                                                    </React.Fragment>
                                                )}
                                            </PopupState>
                                        </Box>
                                        {company.isRABSite &&
                                            <Box display="flex" flexDirection="row">
                                                <FormGroup style={{ margin: 0 }}>
                                                    <FormControlLabelPublic control={
                                                        <Switch name='isPrivate' onChange={(val) => setFieldValue('isPrivate', !values.isPrivate)} checked={values.isPrivate} />
                                                    } label={'Private'} />
                                                </FormGroup>
                                                <PopupState variant="popover" popupId="demo-popup-popover">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButtonPublic aria-label="Public Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                                <HelpIcon fontSize="inherit" />
                                                            </IconButtonPublic>
                                                            <Popover
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                            >
                                                                <TipPopup>
                                                                    <Typography>
                                                                        Setting this to 'on' will hide this class from non-employee accounts.
                                                                </Typography>
                                                                </TipPopup>
                                                            </Popover>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </Box>
                                        }
                                        {values.isPrivate &&
                                            <Box>
                                                <KeyboardDateTimePicker
                                                    inputVariant="outlined"
                                                    disablePast={!company.isRABSite}
                                                    name="privateUntil"
                                                    margin="normal"
                                                    error={Boolean(touched.privateUntil && errors.privateUntil)}
                                                    label="Private Until"
                                                    value={values.privateUntil}
                                                    fullWidth
                                                    onChange={date => setFieldValue('privateUntil', date, true)}
                                                    format="MM/dd/yy hh:mm a"
                                                    style={{ marginBottom: 0 }}
                                                    keyboardIcon={<CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>

                            <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Schedule</Typography>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={4}>
                                    <KeyboardDateTimePicker inputVariant="outlined"
                                        disablePast={!company.isRABSite}
                                        name="startDateTime"
                                        margin="normal"
                                        error={Boolean(touched.startDateTime && errors.startDateTime)}
                                        label="Start Date &amp; Time"
                                        value={values.startDateTime}
                                        fullWidth
                                        onChange={date => setFieldValue('startDateTime', date, true)}
                                        format="MM/dd/yy hh:mm a"
                                        style={{ marginBottom: 0 }}
                                        keyboardIcon={<CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />}
                                    />
                                    {Boolean(errors.startDateTime) &&
                                        <Box ml={3}>
                                            <FormHelperText>Required</FormHelperText>
                                        </Box>
                                    }

                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        name="duration"
                                        label="Duration"
                                        value={values.duration}
                                        error={Boolean(touched.duration && errors.duration)}
                                        helperText={touched.duration && errors.duration}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">min</InputAdornment>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {(editAllInstances || createCopy) &&
                                <RRuleEditor
                                    initialValue={rrule}
                                    occurancesInPast={displayedActivity ? displayedActivity.recurranceOccurancesInPast : 0}
                                    onChange={(val) => {
                                        setIsrRuleValid(val.isFormValid)
                                        setRRule(val.rrule)
                                    }}
                                />
                            }

                            <Box style={{ marginBottom: '20px' }}>
                                <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>Pricing</Typography>
                                <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                    <Grid item xs={6} >
                                        <TextField
                                            variant="outlined"
                                            name="price"
                                            label={"Price per " + ACTIVITY_TEXT}
                                            value={values.price}
                                            error={Boolean(touched.price && errors.price)}
                                            helperText={touched.price && errors.price}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                            style={{ margin: 0 }}
                                        />
                                    </Grid>

                                    {isRecurring && rrule
                                        && (rrule.toLowerCase().includes('count') || rrule.toLowerCase().includes('until'))
                                        && (!props.activity || editAllInstances)
                                        && company.isRABSite &&
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                name="seriesPrice"
                                                label={"Price for Series"}
                                                value={values.seriesPrice}
                                                error={Boolean(touched.seriesPrice && errors.seriesPrice)}
                                                helperText={touched.seriesPrice && errors.seriesPrice}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="dense"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }}
                                                style={{ margin: 0 }}
                                            />
                                        </Grid>
                                    }
                                    {company.isRABSite &&
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                name="twoOrMoreMemberDiscount"
                                                label={"Discount for each additional person:"}
                                                value={values.twoOrMoreMemberDiscount}
                                                error={Boolean(touched.twoOrMoreMemberDiscount && errors.twoOrMoreMemberDiscount)}
                                                helperText={touched.twoOrMoreMemberDiscount && errors.twoOrMoreMemberDiscount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="dense"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                }}
                                                style={{ margin: 0 }}
                                            />
                                        </Grid>
                                    }
                                </Grid>

                                {/* {company.paymentServicePublicKey &&  */}
                                {!company.isRABSite &&

                                    <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                        <React.Fragment>
                                            <Grid item xs={6} sm={3}>
                                                <Box display="flex" onClick={() => setShowAdvancedPricing(prevValue => !prevValue)} style={{ cursor: 'pointer' }}>
                                                    <TypographySelect>
                                                        Advanced Pricing
                                                    </TypographySelect>
                                                    {!showAdvancedPricing && <ChevronDown color={theme.palette.primary.main} />}
                                                    {showAdvancedPricing && <ChevronUp color={theme.palette.primary.main} />}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Box display="flex" alignItems="center">
                                                    {/* {company.isRABSite &&
                                                    <React.Fragment>
                                                        <FormGroup style={{ margin: 0 }}>
                                                            <FormControlLabel style={{ margin: 0 }} control={
                                                                <Field name='requirePrepayment' component={Switch} onChange={(val) => setFieldValue('requirePrepayment', !values.requirePrepayment)} checked={values.requirePrepayment} />
                                                            } label={'Require Prepayment'} />
                                                        </FormGroup>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <React.Fragment>
                                                                    <IconButton aria-label="Public Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                                        <HelpIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <TipPopup>
                                                                            <Typography>
                                                                                Require {CUSTOMERS_TEXT.toLowerCase()} to prepay for this {ACTIVITY_TEXT.toLowerCase()}.
                                                                    </Typography>
                                                                        </TipPopup>
                                                                    </Popover>
                                                                </React.Fragment>
                                                            )}
                                                        </PopupState>
                                                    </React.Fragment>
                                                } */}
                                                    <React.Fragment>
                                                        <FormGroup style={{ margin: 0 }}>
                                                            <FormControlLabel style={{ margin: 0 }} control={
                                                                <Field name='requireCreditCard' component={Switch} onChange={(val) => setFieldValue('requireCreditCard', !values.requireCreditCard)} checked={values.requireCreditCard} />
                                                            } label={'Require CC/Package on file'} />
                                                        </FormGroup>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <React.Fragment>
                                                                    <IconButton aria-label="Public Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                                        <HelpIcon fontSize="inherit" />
                                                                    </IconButton>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <TipPopup>
                                                                            <Typography>
                                                                                Require {CUSTOMERS_TEXT.toLowerCase()} to have a credit card on file or have purchased a package to register.
                                                                            </Typography>
                                                                        </TipPopup>
                                                                    </Popover>
                                                                </React.Fragment>
                                                            )}
                                                        </PopupState>
                                                    </React.Fragment>
                                                </Box>
                                            </Grid>
                                            {showAdvancedPricing &&
                                                <Grid item xs={12}>
                                                    <Card p={4}>
                                                        {!company.isRABSite &&
                                                            <Box mb={4} pb={4}
                                                                display="flex"
                                                                alignItems='center'
                                                                style={{ borderBottom: '1px solid ' + theme.palette.divider }}
                                                            >
                                                                <FormGroup style={{ margin: 0 }}>
                                                                    <FormControlLabel style={{ margin: 0 }} control={
                                                                        <Field name='splitPayment' component={Switch} onChange={(val) => setFieldValue('splitPayment', !values.splitPayment)} checked={values.splitPayment} />
                                                                    } label={'Split Payments'} />
                                                                </FormGroup>
                                                                <Typography variant='subtitle1' color='textSecondary' ml={2}>Split the price evenly between participants when billing.</Typography>
                                                            </Box>
                                                        }
                                                        {subscriptions && subscriptions.length > 0 && !company.isRABSite &&
                                                            <Box
                                                                style={{ borderBottom: packages.length > 0 ? '1px solid ' + theme.palette.divider : null }}
                                                            >
                                                                <FieldArray name="subscriptionPrices">
                                                                    {({ insert, remove, push }) =>
                                                                    (
                                                                        <React.Fragment>
                                                                            <Box display="flex" alignItems='end'>
                                                                                <Typography variant='h4' component="h4">Membership Pricing</Typography>
                                                                                <Typography variant='subtitle1' color='textSecondary' ml={2}>Assign unique pricing for {CUSTOMERS_TEXT.toLowerCase()} with an active membership.</Typography>
                                                                            </Box>
                                                                            {values.subscriptionPrices.length > 0 &&
                                                                                values.subscriptionPrices.map((price, index) => {
                                                                                    return (
                                                                                        <Box display="flex" flexWrap='wrap' mt={2} alignItems="center">
                                                                                            <SelectFormControl
                                                                                                mr={2}
                                                                                                style={{ minWidth: '250px' }}
                                                                                                labelText={'Membership'}
                                                                                                labelId="select-product-label"
                                                                                            >
                                                                                                <Select
                                                                                                    labelId="select-product-label"
                                                                                                    id="productId"
                                                                                                    value={price.productId}
                                                                                                    label={"Membership"} /// both this line and Input label
                                                                                                    onChange={(event) => { setFieldValue(`subscriptionPrices.${index}.productId`, event.target.value); }
                                                                                                    }
                                                                                                    disabled={!price.isNew}
                                                                                                >
                                                                                                    {subscriptions.filter(x => (price.isNew ? x.isActive : true)).map((product) => (
                                                                                                        <MenuItem
                                                                                                            key={product.id}
                                                                                                            value={product.id}
                                                                                                        >
                                                                                                            {product.name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                                {Boolean(errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].productId) &&
                                                                                                    <FormHelperText>{errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].productId}</FormHelperText>
                                                                                                }
                                                                                            </SelectFormControl>

                                                                                            <SelectFormControl
                                                                                                style={{ minWidth: '110px' }}
                                                                                                mr={2}
                                                                                                labelText={'Type'} labelId="select-discountType-label"
                                                                                            >
                                                                                                <Select
                                                                                                    labelId="select-discountType-label"
                                                                                                    id="discountType"
                                                                                                    value={price.usePercent ? 'percentDiscount' : 'price'}
                                                                                                    defaultValue={'price'}
                                                                                                    label={"Type"} /// both this line and Input label
                                                                                                    onChange={(event) => { setFieldValue(`subscriptionPrices.${index}.usePercent`, event.target.value === 'percentDiscount' ? true : false); }
                                                                                                    }
                                                                                                    disabled={!price.isNew}
                                                                                                >
                                                                                                    <MenuItem
                                                                                                        key={'price'}
                                                                                                        value={'price'}
                                                                                                    >
                                                                                                        Price
                                                                                                    </MenuItem>
                                                                                                    <MenuItem
                                                                                                        key={'percentDiscount'}
                                                                                                        value={'percentDiscount'}
                                                                                                    >
                                                                                                        % Discount
                                                                                                    </MenuItem>                                                                                                </Select>
                                                                                                {Boolean(errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].productId) &&
                                                                                                    <FormHelperText>{errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].productId}</FormHelperText>
                                                                                                }
                                                                                            </SelectFormControl>

                                                                                            {!price.usePercent &&
                                                                                                <TextField
                                                                                                    variant="outlined"
                                                                                                    name="price"
                                                                                                    label={"Price per " + ACTIVITY_TEXT}
                                                                                                    value={price.price}
                                                                                                    error={Boolean(errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].price)}
                                                                                                    helperText={errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].price}
                                                                                                    // onBlur={handleBlur}
                                                                                                    onChange={(event) => { setFieldValue(`subscriptionPrices.${index}.price`, event.target.value); }}
                                                                                                    margin="dense"
                                                                                                    style={{ width: '110px', marginBottom: '0 !important' }}
                                                                                                    disabled={!price.isNew}
                                                                                                    InputProps={{
                                                                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            {price.usePercent &&
                                                                                                <TextField
                                                                                                    name="discountPercent"
                                                                                                    label="Percent Off"
                                                                                                    value={price.discountPercent}
                                                                                                    error={Boolean(errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].discountPercent)}
                                                                                                    helperText={errors.subscriptionPrices && errors.subscriptionPrices[index] && errors.subscriptionPrices[index].discountPercent}
                                                                                                    onChange={
                                                                                                        (event) => { setFieldValue(`subscriptionPrices.${index}.discountPercent`, event.target.value); }
                                                                                                    }
                                                                                                    variant="outlined"
                                                                                                    placeholder=""
                                                                                                    disabled={!price.isNew}
                                                                                                    style={{ width: '90px' }}
                                                                                                    margin="dense"
                                                                                                    InputProps={{
                                                                                                        endAdornment:
                                                                                                            <InputAdornment
                                                                                                                position="end">%
                                                                                                            </InputAdornment>
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            <Box ml={2}>
                                                                                                <RemoveIcon
                                                                                                    onClick={() => {
                                                                                                        const r = window.confirm('Are you sure you want to delete this?');
                                                                                                        if (r) {
                                                                                                            remove(index);
                                                                                                        }
                                                                                                    }}
                                                                                                    size={24}
                                                                                                    style={{ cursor: "pointer", color: theme.palette.error.main }} />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })}
                                                                            <Box display="flex" flex={1} justifyContent="flex-start" mb={4}>
                                                                                <Button
                                                                                    mt={2}
                                                                                    p={0}
                                                                                    variant="default"
                                                                                    color="primary"
                                                                                    startIcon={<AddIcon size={24} color={theme.palette.secondary.main} />}
                                                                                    onClick={() => push(createPriceObject())}
                                                                                >
                                                                                    Add Membership Price
                                                                                </Button>
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </Box>
                                                        }
                                                        {packages.length > 0 && !company.isRABSite &&
                                                            <React.Fragment>
                                                                <Box display="flex" alignItems='end'>
                                                                    <Typography variant='h4'>Packages</Typography>
                                                                    <Typography variant='subtitle1' color='textSecondary' ml={2}>Select packages that can be used to pay for this {ACTIVITY_TEXT}</Typography>
                                                                </Box>
                                                                <Box display='flex' flexDirection="row" flexWrap='wrap' mt={2}>
                                                                    {packages.map((product) => {
                                                                        const isSelected = values.allowedPackages.includes(product.id)
                                                                        return (
                                                                            <FormControlLabel
                                                                                key={product.id}
                                                                                style={{ width: 'calc(33% - 8px)', alignItems: 'start', margin: 0, paddingTop: '4px' }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={isSelected}
                                                                                        onChange={(event) => {
                                                                                            const updatedValues = cloneDeep(values.allowedPackages);
                                                                                            if (isSelected)
                                                                                                updatedValues.splice(values.allowedPackages.indexOf(product.id), 1)
                                                                                            else
                                                                                                updatedValues.push(product.id)

                                                                                            setFieldValue('allowedPackages', updatedValues)
                                                                                        }}
                                                                                        style={{ margin: '-4px 0 0 -3px', padding: '0 5px 0 0' }}
                                                                                        name={product.id}
                                                                                    />
                                                                                }
                                                                                label={product.name}
                                                                            />
                                                                            // <MenuItem
                                                                            //     key={product.id}
                                                                            //     value={product.id}
                                                                            // >
                                                                            //     {product.name}
                                                                            // </MenuItem>
                                                                        )
                                                                    })}
                                                                </Box>
                                                                {/* 
                                                                <SelectFormControl
                                                                    fullWidth
                                                                    labelText={'Accepted Packages'} labelId="select-packages-label"
                                                                >
                                                                    <Select
                                                                        labelId="select-packages-label"
                                                                        id="allowedPackages"
                                                                        value={values.allowedPackages}
                                                                        multiple
                                                                        label={"Accepted Packages"} /// both this line and Input lavel
                                                                        onChange={(event) => { setFieldValue('allowedPackages', event.target.value); }}
                                                                    >

                                                                    </Select>
                                                                </SelectFormControl> */}
                                                            </React.Fragment>
                                                        }
                                                    </Card>
                                                </Grid>
                                            }
                                        </React.Fragment>
                                        {/* } */}
                                    </Grid>
                                }
                            </Box>
                        </React.Fragment>
                        {/* } */}

                        <Typography component="h3" variant="h3" style={{ margin: '12px 0 12px 0' }}>People</Typography>
                        <SelectUser
                            title={EMPLOYEES_TEXT}
                            selectText={"Select " + EMPLOYEE_TEXT + "(s)"}
                            scrollSelectedPeople items={employees} initialValue={values.employees}
                            onChange={(val) => setFieldValue('employees', val)}
                        />
                        <SelectUser
                            title={CUSTOMERS_TEXT}
                            selectText={"Select " + CUSTOMERS_TEXT}
                            scrollSelectedPeople initialValue={values.attendees}
                            onChange={(val) => {
                                setFieldValue('attendees', val);
                                setFieldValue('requestConfirmation', val.length > 1 ? false : values.requestConfirmation);
                            }
                            }
                        />
                        {/* {editAllInstances &&  */}
                        <Box>
                            <Box display='flex' flexDirection={isMobile ? 'column-reverse' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} px={2} mb={2}>
                                {!company.isRABSite &&
                                    <FormGroup>
                                        <FormControlLabel style={{ margin: 0 }} control={
                                            <Field name='allowSelfRegister' component={Switch} onChange={(val) => {
                                                setFieldValue('allowSelfRegister', !values.allowSelfRegister);
                                                setFieldValue('requestConfirmation', false);
                                            }
                                            } checked={values.allowSelfRegister} />
                                        } label={'Allow ' + CUSTOMERS_TEXT.toLowerCase() + ' to self register.'} />
                                    </FormGroup>
                                }
                                {!company.isRABSite &&
                                    <Box display="flex" flexDirection='row' justifyContent='flex-start' alignItems='center' ml={isMobile ? 0 : 4}>
                                        <FormGroup>
                                            <FormControlLabel style={{ margin: 0 }} control={
                                                <Field name='requestConfirmation' component={Switch} onChange={(val) => setFieldValue('requestConfirmation', !values.requestConfirmation)} checked={values.requestConfirmation} />
                                            } label={'Request confirmation from ' + CUSTOMER_TEXT.toLowerCase()}
                                                disabled={values.allowSelfRegister || values.attendees.length > 1}
                                            />
                                        </FormGroup>

                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <IconButton aria-label="Request Confirmation" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                        <HelpIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <TipPopup>
                                                            <Typography>
                                                                Send a message to {CUSTOMER_TEXT.toLowerCase()} asking them to confirm the {ACTIVITY_TEXT.toLowerCase()}.
                                                            </Typography>
                                                            <Typography mt={2}>
                                                                This feature cannot be used if more than one {CUSTOMER_TEXT.toLowerCase()} is in this
                                                                {' ' + ACTIVITY_TEXT.toLowerCase()} or it is set to allow self register.
                                                            </Typography>
                                                        </TipPopup>
                                                    </Popover>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </Box>
                                }
                            </Box>
                            {values.allowSelfRegister &&
                                <Card>
                                    <Box display="flex"
                                        flexDirection={isMobile ? "column" : "row"}
                                        // justifyContent="space-between"
                                        alignItems="center"
                                        p={3}>
                                        <TextField
                                            // type="number"
                                            variant="outlined"
                                            name="totalSpaces"
                                            label="Total Spaces"
                                            value={values.totalSpaces}
                                            error={Boolean(touched.totalSpaces && errors.totalSpaces)}
                                            helperText={touched.totalSpaces && errors.totalSpaces}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            // fullWidth
                                            // style={{ margin: 0 }}
                                            mr={6}
                                        // margin="dense"
                                        />
                                        {/* <Box display="flex"
                                            flexDirection="row"
                                            // justifyContent="space-between"
                                            alignItems="center"
                                            maxWidth={isMobile ? 500 : 250}>
                                            <FormGroup>
                                                <FormControlLabel style={{ margin: 0 }} control={
                                                    <Field name='allowSeriesRegistration' component={Switch} onChange={(val) => {
                                                        setFieldValue('allowSeriesRegistration', !values.allowSeriesRegistration);
                                                    }
                                                    } checked={values.allowSeriesRegistration} />
                                                } label={'Allow self registration to whole series'} />
                                            </FormGroup>
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <React.Fragment>
                                                        <IconButton aria-label="Registration Closes Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                            <HelpIcon fontSize="inherit" />
                                                        </IconButton>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                        >
                                                            <TipPopup>
                                                                <Typography>
                                                                    This will allow {CUSTOMERS_TEXT.toLowerCase()} to regester for an entire series instead of a single class.
                                                                </Typography>
                                                            </TipPopup>
                                                        </Popover>
                                                    </React.Fragment>
                                                )}
                                            </PopupState>
                                        </Box> */}
                                        {values.allowSeriesRegistration && isRecurring &&
                                            <Box display="flex"
                                                flexDirection="row"
                                                // justifyContent="space-between"
                                                alignItems="center"
                                                maxWidth={isMobile ? 500 : 250}>
                                                <FormGroup>
                                                    <FormControlLabel style={{ margin: 0 }} control={
                                                        <Field name='dontAllowDropIns' component={Switch} onChange={(val) => {
                                                            setFieldValue('dontAllowDropIns', !values.dontAllowDropIns);
                                                        }
                                                        } checked={values.dontAllowDropIns} />
                                                    } label={"Don't allow drop-ins"} />
                                                </FormGroup>
                                                <PopupState variant="popover" popupId="demo-popup-popover">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton aria-label="Registration Closes Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                                <HelpIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <Popover
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                            >
                                                                <TipPopup>
                                                                    <Typography>
                                                                        This will not allow {CUSTOMERS_TEXT.toLowerCase()} to register for a drop-in within a session.
                                                                    </Typography>
                                                                </TipPopup>
                                                            </Popover>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </Box>
                                        }
                                    </Box>
                                    {!company.isRABSite &&
                                        <React.Fragment>
                                            <Divider />
                                            <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                                <Typography mr={3} style={{ fontWeight: '700' }}>Enrollment Opens</Typography>
                                                <SmallTextField
                                                    variant="outlined"
                                                    name="selfRegisterStartValue"
                                                    // label="Can enroll ahead"
                                                    value={values.selfRegisterStartValue}
                                                    error={Boolean(touched.selfRegisterStartValue && errors.selfRegisterStartValue)}
                                                    helperText={touched.selfRegisterStartValue && errors.selfRegisterStartValue}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    required={values.allowSelfRegister}
                                                    margin="dense"
                                                />
                                                <SelectFormControl style={{ minWidth: '60px', marginTop: '9px' }}>
                                                    <SmallSelect
                                                        id="selfRegisterStartUnit"
                                                        value={values.selfRegisterStartUnit}
                                                        onChange={(event) => setFieldValue('selfRegisterStartUnit', event.target.value)}
                                                        IconComponent={ChevronDown}
                                                    >
                                                        {TIME_UNITS.map((unit) => (
                                                            <MenuItem key={unit.id} value={unit.id} selected={values.selfRegisterStartUnit === unit.id ? true : false}>
                                                                {unit.id}
                                                            </MenuItem>
                                                        ))}
                                                    </SmallSelect>
                                                </SelectFormControl>
                                                <Typography style={{ fontWeight: '700' }}>Closes</Typography>
                                                <PopupState variant="popover" popupId="demo-popup-popover">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton aria-label="Registration Closes Help" size="medium" color='primary' {...bindTrigger(popupState)}>
                                                                <HelpIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <Popover
                                                                {...bindPopover(popupState)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                            >
                                                                <TipPopup>
                                                                    <Typography>
                                                                        Leave this fields blank to keep {ACTIVITY_TEXT.toLowerCase()} open till end of duration.
                                                                        <br /><br />This field can also be negative to leave registration open for a fee minutes into the class.
                                                                    </Typography>
                                                                </TipPopup>
                                                            </Popover>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                                <SmallTextField
                                                    variant="outlined"
                                                    name="selfRegisterEndValue"
                                                    // label="Can enroll ahead"
                                                    value={values.selfRegisterEndValue}
                                                    error={Boolean(touched.selfRegisterEndValue && errors.selfRegisterEndValue)}
                                                    helperText={touched.selfRegisterEndValue && errors.selfRegisterEndValue}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="dense"
                                                />
                                                <SelectFormControl style={{ minWidth: '60px', marginTop: '9px' }}>
                                                    <SmallSelect
                                                        id="selfRegisterEndUnit"
                                                        value={values.selfRegisterEndUnit}
                                                        onChange={(event) => setFieldValue('selfRegisterEndUnit', event.target.value)}
                                                        IconComponent={ChevronDown}
                                                    >
                                                        {TIME_UNITS.map((unit) => (
                                                            <MenuItem key={unit.id} value={unit.id} selected={values.selfRegisterEndUnit === unit.id ? true : false}>
                                                                {unit.id}
                                                            </MenuItem>
                                                        ))}
                                                    </SmallSelect>
                                                </SelectFormControl>
                                                <Typography>before start</Typography>

                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    }
                                    {/* {values.requirePrepayment &&
                                        <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                            <Typography>{ACTIVITIES_TEXT} that require prepayment cannot be dropped by {CUSTOMERS_TEXT.toLowerCase()}</Typography>
                                        </Box>
                                    } */}
                                    {!company.isRABSite &&

                                        <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                            <Typography mr={3} style={{ fontWeight: '700' }}>Can Drop Until</Typography>
                                            <SmallTextField
                                                variant="outlined"
                                                name="selfDropStartValue"
                                                value={values.selfDropStartValue}
                                                error={Boolean(touched.selfDropStartValue && errors.selfDropStartValue)}
                                                helperText={touched.selfDropStartValue && errors.selfDropStartValue}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                style={{ marginRight: '10px', width: '70px' }}
                                                required={values.allowSelfRegister}
                                                margin="dense"
                                            />
                                            <SelectFormControl style={{ minWidth: '60px', marginTop: '9px' }}>
                                                <SmallSelect
                                                    id="selfDropStartUnit"
                                                    value={values.selfDropStartUnit}
                                                    onChange={(event) => setFieldValue('selfDropStartUnit', event.target.value)}
                                                    IconComponent={ChevronDown}
                                                >
                                                    {TIME_UNITS.map((unit) => (
                                                        <MenuItem key={unit.id} value={unit.id} selected={values.selfDropStartUnit === unit.id ? true : false}>
                                                            {unit.id}
                                                        </MenuItem>
                                                    ))}
                                                </SmallSelect>
                                            </SelectFormControl>
                                            <Typography>before start</Typography>
                                        </Box>
                                    }
                                </Card>
                            }
                        </Box>
                        {/* I'm not sure how to implement this server side so holding off for now */}
                        {/* <Card my={2}>
                            <Box display='flex' flexDirection={'row'} alignItems={'center'} p={3}>
                                <FormGroup>
                                    <FormControlLabel style={{ margin: 0 }} control={
                                        <Field name='autoCancel' component={Switch} onChange={(val) => {
                                            setFieldValue('autoCancel', !values.autoCancel);
                                        }
                                        } checked={values.autoCancel} />
                                    } label={'Automatically cancel ' + ACTIVITY_TEXT.toLowerCase() + ' if no ' + CUSTOMERS_TEXT.toLowerCase() + ' enrolled '} />
                                </FormGroup>
                                <Box display='flex' flexDirection={'row'} alignItems={'center'} ml={2}>
                                    <SmallTextField
                                        variant="outlined"
                                        name="autoCancelMinutes"
                                        // label="Can enroll ahead"
                                        value={values.autoCancelMinutes}
                                        error={Boolean(touched.autoCancelMinutes && errors.autoCancelMinutes)}
                                        helperText={touched.autoCancelMinutes && errors.autoCancelMinutes}
                                        disabled={!values.autoCancel}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        margin="dense"
                                    />
                                    <Typography>minutes before start</Typography>
                                </Box>
                            </Box>
                        </Card> */}
                        <Typography component="h3" variant="h3" style={{ margin: '20px 0 12px 0' }}>Notifications</Typography>
                        <FormGroup style={{ margin: '0 0 15px 15px' }}>
                            <FormControlLabel style={{ margin: 0 }} control={
                                <Field name='sendReminder' component={Switch} onChange={(val) => setFieldValue('sendReminder', !values.sendReminder)} checked={values.sendReminder} />
                            } label={'Send reminder'} />
                        </FormGroup>
                        {values.sendReminder &&
                            <Card>
                                <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                    <SelectManageAov type={REMINDER}
                                        onSelect={(value) => {
                                            setFieldValue('reminderHours', value.reminderHours ? value.reminderHours.toString() : "24");
                                            setFieldValue('reminderMessage', value.text);
                                        }}
                                    />
                                </Box>
                                <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                    <Typography mr={3} style={{ fontWeight: '700' }}>Send Reminder</Typography>
                                    <SmallTextField
                                        variant="outlined"
                                        name="reminderHours"
                                        // label="Can enroll ahead"
                                        value={values.reminderHours}
                                        error={Boolean(touched.reminderHours && errors.reminderHours)}
                                        helperText={touched.reminderHours && errors.reminderHours}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={values.sendReminder}
                                        margin="dense"
                                    />
                                    <Typography mr={3} color="textSecondary">hours before start</Typography>
                                </Box>
                                <Box m={3} display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                    <TextField
                                        variant="outlined"
                                        name="reminderMessage"
                                        label="Message"
                                        value={values.reminderMessage}
                                        error={Boolean(touched.reminderMessage && errors.reminderMessage)}
                                        helperText={touched.reminderMessage && errors.reminderMessage}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        maxRows={15}
                                        fullWidth
                                        required={values.sendReminder}
                                        margin="dense"
                                    />
                                    <Typography variant="body2" color="textSecondary" mb={2}>To include the name of the {ACTIVITY_TEXT.toLowerCase()} use {'{'}NAME{'}'} to insert time use {'{'}TIME{'}'} </Typography>
                                </Box>
                            </Card>
                        }

                        {/* } */}
                        {!company.isRABSite &&
                            <React.Fragment>
                                <Typography component="h3" variant="h3" style={{ margin: '20px 0 12px 0' }}>Highlight Color</Typography>
                                <Box p={3}>
                                    <SketchPicker color={values.calendarColor} onChangeComplete={color => { setFieldValue('calendarColor', color.hex); }} />
                                    <Button mt={2} variant='outlined' onClick={() => setFieldValue('calendarColor', theme.palette.primary.main)}>Reset to default color</Button>
                                </Box>
                            </React.Fragment>
                        }
                        {error &&
                            <ErrorDialog message={error} setMessage={setError} />

                        }
                        {displayedActivity && displayedActivity.endDateTime && displayedActivity.endDateTime.getTime() > 5000 && <Alert mt={2} mb={1} severity="info">
                            <Typography variant="body2">You are editing a recurring event that has already been edited.  Changes you make here will change this event until {moment(displayedActivity.endDateTime).format('lll')}</Typography>
                        </Alert>}
                    </form>
                )}
            </Formik>
        </StyledDialog >
    );
}

export default ActivityAddEditModal;