import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCardWithSpacing,
    DialogContent,
    Grid,
    LinearProgress as MuiLinearProgress,
    TextField as MuiTextField,
    InputAdornment as MuiInputAdornment,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
// import DeleteIcon from '@material-ui/icons/Delete';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoSearchOutline as SearchIcon,
} from "react-icons/io5";


// svxvy import
import Person from '../../models/Person';
import ProfileListItem from './profile/ProfileListItem';
import { fetchPeople } from '../../store/actions/peopleActions';
import { compareObjects } from '../../helpers/helperFunctions';
import { addToArray, deleteById } from '../../helpers/arrayFunctions';
import StyledDialog from '../framework/StyledDialog';

const everyone = new Person('00000000-0000-0000-0000-000000000001', '#everyone', '#everyone', '', '', 'any', '', [], false, false, false, false, '00000000-0000-0000-0000-000000000001');
everyone.isSelected = false;
everyone.isUserRegistered = true;

// constants

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const MuiCard = styled(MuiCardWithSpacing)(spacing);
const Typography = styled(MuiTypography)(spacing);

const SearchField = styled(MuiTextField)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }
`;


const InputAdornment = styled(MuiInputAdornment)`
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 9px;

  svg {
    width: 18px;
    height: 18px;
    color: ${(props) => props.theme.palette.primary.main};
    opacity: 1;
  }
`;

const ProfileList = styled(MuiCard)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
`;

const GroupTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};

  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
`;


const getSelectablePeople = (people, showEveryoneOption, hideSelf, loggedInUserId) => {
    let selectable = cloneDeep(people);
    if (hideSelf)
        selectable = deleteById(loggedInUserId, selectable)
    if (showEveryoneOption)
        selectable = addToArray(everyone, selectable, true)
    return selectable;
}

const allOthers = '#';
const alphabetArray = [allOthers, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const extractItems = (items, companyList) => {
    const arrayToReturn = [];
    for (const l in alphabetArray) {
        const letter = alphabetArray[l];
        let data = null;
        if (letter !== allOthers)
            data = items.filter(x => x.displayName.toUpperCase().startsWith(letter));
        else
            data = items.filter(x => x.displayName.charAt(0) === allOthers || !alphabetArray.includes(x.displayName.toUpperCase().charAt(0)));

        if (data.length > 0)
            arrayToReturn.push({ title: letter, data: data })
    }

    return arrayToReturn;
}


const AddUser = (props) => {
    const { items, showEveryoneOption, hideSelf, initialValue, title, onChange, open, setOpen, singleSelect } = props;

    const theme = useTheme();
    const company = useSelector(state => state.company.company);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);
    const loggedInUserId = useSelector(state => state.auth.userId)
    const people = useSelector(state => state.company.people).filter(p => !p.isDeleted);
    people.sort(compareObjects('displayName', 'asc'));

    const selectablePeople = getSelectablePeople(items ? items : people, showEveryoneOption, hideSelf, loggedInUserId);
    const [everyoneSelected, setEveryoneSelected] = useState(false);

    const [search, setSearch] = useState('');

    const peopleToDisplay = everyoneSelected ? selectablePeople.filter(p => p.id === everyone.id) : selectablePeople.filter(p =>
        search.length > 0
            ? p.displayName.toLowerCase().includes(search.toLowerCase()) | (p.email != null && p.email.toLowerCase().includes(search.toLowerCase())) | (p.phoneNumber != null && p.phoneNumber.includes(search))
            : true)

    const groupedPeople = extractItems(peopleToDisplay)

    const [chosenPeople, setChosenPeople] = useState(initialValue ? initialValue : []);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    /******************************* load people from database **************************/

    const loadPeople = useCallback(async () => {
        try {
            setIsLoading(true);
            await dispatch(fetchPeople(company.id));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [company, dispatch]);


    useEffect(() => {
        loadPeople();
    }, [loadPeople]);

    /******************************* events and handlers **************************/

    const handleOnChange = (updatedSelection) => {
        setChosenPeople(updatedSelection);
        if (onChange)
            onChange(updatedSelection);
        if (singleSelect)
            setOpen(false);
    }


    const profileNamePressedHandler = (personSelected, isSelected) => {
        if (personSelected.id === everyone.id && isSelected) {
            setEveryoneSelected(true);
            handleOnChange(deleteById(loggedInUserId, people));
        }
        else if (personSelected.id === everyone.id && !isSelected) {
            setEveryoneSelected(false);
            handleOnChange([]);
        }
        else {
            if (showEveryoneOption) {
                const everyoneLocal = selectablePeople.find(x => x.id === everyone.id);
                everyoneLocal.isSelected = false;
            }
            if (isSelected) {
                handleOnChange(addToArray(personSelected, chosenPeople));
            }
            else {
                handleOnChange(deleteById(personSelected.id, chosenPeople));
            }
        }
    }

    /************************************** selected items **********************************/

    const handleSearchChange = event => {
        setSearch(event.target.value);
    }

    return (
        <StyledDialog
            style={{ height: "90vh" }}
            open={open}
            setOpen={setOpen}
            aria-labelledby="act-dialog-title"
            primaryAction={
                <Button variant="contained" color="primary" fullWidth onClick={() => setOpen(false)} mb={3}>Close</Button>
            }
        >
            <DialogContent style={{ margin: 0, padding: 0 }} >
                {error && <Alert severity="error">{error}</Alert>}
                {isLoading && !error && <React.Fragment><LinearProgress my={2} color="primary" />
                    <Typography variant={"h2"} gutterBottom>Loading {CUSTOMERS_TEXT}...</Typography>
                    <LinearProgress my={2} color="secondary" />
                </React.Fragment>}
                {!isLoading && !error && <React.Fragment>

                    <Typography component="h2" variant="h1" mb={5}>{title}</Typography>
                    <SearchField
                        id="standard-search"
                        type="search"
                        placeholder="Search"
                        onChange={e => handleSearchChange(e)}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {groupedPeople.map((group, index) => {
                        return (
                            <Grid key={group.title} container style={{ paddingTop: '5px' }}>
                                <Grid item xs={12}>
                                    <Box mt={index === 0 ? 2 : 6}>
                                        <Box display="flex" flex={1}>
                                            <GroupTitle variant="h6">{group.title}</GroupTitle>
                                        </Box>
                                        <ProfileList>
                                            {group.data.map((person, index) => {
                                                return (
                                                    <ProfileListItem style={{
                                                        width: '100%',
                                                        maxWidth: 'none',
                                                        backgroundColor: 'transparent !important',
                                                        borderBottom: '1px solid ' + theme.palette.divider,
                                                    }} key={person.id}
                                                        hideCheckbox={singleSelect}
                                                        person={person}
                                                        isSelected={chosenPeople.find(x => x.id === person.id) != null ? true : false}
                                                        profileNamePress={profileNamePressedHandler} />
                                                )
                                            })}
                                        </ProfileList>
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    })}
                </React.Fragment>
                }
            </DialogContent>
        </StyledDialog>

    );
}

export default AddUser;