import React from 'react';
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';

import { NavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  // Badge,
  Box as MuiBox,
  Button,
  Drawer as MuiDrawer,
  IconButton,
  List as MuiList,
  ListItem,
} from "@material-ui/core";
import {
  IoCloseOutline as CloseIcon,
} from "react-icons/io5";



// svxvy imports
import * as NavigationConstants from '../../constants/NavigationConstants';
import { companyPublicRoutes as routes } from "../../routes/indexSvxvy";
import { isSvxvySite } from '../../constants/NetworkConstants';
import { replaceDisplayName } from '../../helpers/navigationFunctions';
import { hideWebPage } from './CompanyWebHeader';
import ActionButton from '../framework/ActionButton';

// style constants
const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  margin-top: 35px;

  .MuiBackdrop-root {
      margin-top: 35px;
  }

  > div {
    1px solid ${(props) => props.theme.sidebar.borderColor};
  }
`;

const PrimaryMenuBarItem = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3.5)}px;  
  padding-bottom: ${(props) => props.theme.spacing(3.5)}px;
  color: ${(props) => props.theme.header.color};
  justify-content: center;

  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.sidebar.category.svg.colorActive};
  }
`;

const SecondaryMenuBarItem = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(4.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(4.5)}px;
  color: ${(props) => props.theme.header.color};
  justify-content: center;
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.sidebar.category.svg.colorActive};
  }
`;


const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  padding: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(3)}px 0 ${(props) => props.theme.spacing(3)}px !important;
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;


const SidebarFooter = styled.div`
  // background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  // padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
`;


const CompanyWebMobileSidebar = ({ classes, staticContext, width, location, company, setMobileOpen, isOwner, ...rest }) => {
  const theme = useTheme();
  const webPages = useSelector(state => state.company.webPages);

  const loggedInUser = useSelector(state => state.auth.person)
  const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);

  /******************************** event handler  ****************************/

  return (
    <React.Fragment>
      <Drawer variant="permanent" {...rest}>
        <Scrollbar style={{ height: 'calc(100% - 85px)', position: 'relative' }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="Open drawer"
              onClick={() => setMobileOpen(false)}
            >
              <CloseIcon size={34} color={theme.header.utilityNav.color} />
            </IconButton>
          </Box>
          <List disablePadding style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
            {company && routes && routes.filter(x => !x.hideInNavigation && !hideWebPage(webPages, x.id, isOwner)).map((category, index) => {
              const page = webPages.find(x => x.pageId === category.id.toLowerCase())
              return (
                <React.Fragment key={category.id}>
                  {category.isPrimaryPage &&
                    <PrimaryMenuBarItem
                      to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                      activeClassName="active"
                      component={NavLink}
                      exact
                      style={{ fontSize: '24px' }}
                    >
                      {page && page.menuText && page.menuText.trim().length > 0 ? page.menuText : replaceDisplayName(category.id, company)}
                    </PrimaryMenuBarItem>
                  }
                  {!category.isPrimaryPage &&
                    <SecondaryMenuBarItem
                      to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                      activeClassName="active"
                      component={NavLink}
                      exact
                      style={{ fontSize: '18px' }}
                    >
                      {page && page.menuText && page.menuText.trim().length > 0 ? page.menuText : replaceDisplayName(category.id, company)}
                    </SecondaryMenuBarItem>
                  }
                </React.Fragment>
              )
            })}
          </List>
        </Scrollbar>
        <SidebarFooter style={{ height: '85px', backgroundColor: theme.palette.background.default, paddingTop: '15px', bottom: '0', display: 'block', textAlign: 'center' }}>
          {didTryAutoLogin && loggedInUser && company && loggedInUser.companyId === company.id &&
            <Button
              variant="contained"
              color="primary"
              size="small"
              to={
                isSvxvySite()
                  ? "/" + company.sanitizedName + "/" + NavigationConstants.DASHBOARD
                  : "/" + NavigationConstants.DASHBOARD}
              component={NavLink}
            >
              My Portal
            </Button>
          }
          {company && (!loggedInUser || loggedInUser.companyId !== company.id) &&
            <ActionButton
              variant="contained"
              color="primary"
              size="small"
              to={
                isSvxvySite()
                  ? "/" + company.sanitizedName + "/" + NavigationConstants.LOGIN
                  : "/" + NavigationConstants.LOGIN}
              component={NavLink}
              disabled={didTryAutoLogin === null ? true : false}
              submitting={didTryAutoLogin === null ? true : false}
            >
              Sign In / Join
            </ActionButton>
          }
        </SidebarFooter>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(CompanyWebMobileSidebar);
