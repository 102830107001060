import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

import * as Yup from "yup";
import { Formik } from 'formik'

import {
    Box,
    Button as MuiButton,
    Typography as MuiTypography,
    TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports

import { checkEmailDoesNotExist } from "../../../store/actions/authActions";
import { addAppProfile } from '../../../store/actions/peopleActions';
import { refreshLogedInUser, logout } from '../../../store/actions/authActions';
import ActionButton from "../../framework/ActionButton";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import PasswordResetForm from "./PasswordResetForm";
import StyledDialog from '../../framework/StyledDialog';

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const LoginDialog = (props) => {
    const { setOpen } = props;
    const company = useSelector(state => state.company.company);
    const companyId = useSelector(state => state.company.companyId);
    const loggedInUser = useSelector(state => state.auth.person);

    const [error, setError] = useState(null);
    const [checkedEmail, setCheckedEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isJoiningCompany, setIsJoiningCompany] = useState(false);

    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [showCheckEmailForm, setShowCheckEmailForm] = useState(true);
    const [showResetForm, setShowResetForm] = useState(false);

    /******************************** event handler  ****************************/
    const dispatch = useDispatch();

    const joinCompany = useCallback(async (companyId, appUserId) => {
        setIsJoiningCompany(true);
        try {
            await dispatch(addAppProfile(companyId, appUserId));
            await dispatch(refreshLogedInUser());
            setIsJoiningCompany(false);
            setOpen(false);

        } catch (err) {
            setIsJoiningCompany(false);
            alert(err.message);
        }
    }, [dispatch, setOpen]);

    const handleSignOut = async () => {
        await dispatch(logout());
        showForm('checkEmail', true)
    };

    /******************************** page effects  ****************************/

    const showForm = (fromName, clearCheckedEmail) => {
        setShowLoginForm(false);
        setShowRegistrationForm(false);
        setShowCheckEmailForm(false);
        setShowResetForm(false);

        if (clearCheckedEmail)
            setCheckedEmail("")

        switch (fromName) {
            case 'login':
                setShowLoginForm(true);
                break;
            case 'checkEmail':
                setShowCheckEmailForm(true);
                break;
            case 'registration':
                setShowRegistrationForm(true);
                break;
            case 'reset':
                setShowResetForm(true);
                break
            default:
                setShowLoginForm(true);
        }
    }

    useEffect(() => {
        showForm('login', true)
    }, [props.open]);

    useEffect(() => {
        if (loggedInUser != null && loggedInUser.companyId === companyId) {
            setOpen(false);
        }
        else if (loggedInUser != null && loggedInUser.companyId !== companyId) {
            setShowLoginForm(false)
        }
    }, [loggedInUser, setOpen, companyId])


    return (
        <StyledDialog
            open={props.open}
            setOpen={props.setOpen}
            aria-labelledby="form-dialog-title"
        >

            <Box>
                {loggedInUser && loggedInUser.companyId !== companyId &&
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Join with Account?</Typography>
                            <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={2}>{company.name} uses SmartCore to run its business.</Typography>
                            <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>{loggedInUser.email} is already registered with SmartCore. Do you want to join {company.name} with this account?</Typography>
                            <ActionButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => joinCompany(company.id, loggedInUser.id)}
                                submitting={isJoiningCompany}
                                mt={4}
                            >
                                Join
                            </ActionButton>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={350} mt={2}>
                            <Typography variant="body2" style={{ margin: '0 0 6px 0', fontSize: '14px', lineHeight: '18px' }}>
                                Don't want to use your existing SmartCore account?
                            </Typography>
                            <Typography
                                variant="body2"
                                color='primary'
                                style={{ margin: '0 0 46px 0', fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}
                                onClick={handleSignOut}
                            >
                                Create a New Account
                            </Typography>
                        </Box>
                    </Box>
                }
                {!loggedInUser &&
                    <React.Fragment>

                        {showLoginForm &&
                            <React.Fragment>
                                <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Sign In</Typography>
                                {checkedEmail &&
                                    <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>
                                        <Typography component="span" variant="body2" style={{ fontSize: '14px', lineHeight: '18px', fontWeight: 700 }} mb={4} mr={1}>{checkedEmail}</Typography>
                                        is already registered with SmartCore.  Sign in to Join {company.name} with this account.
                                    </Typography>
                                }
                                <LoginForm
                                    email={checkedEmail}
                                    setOpen={() => { }}
                                    company={company}
                                    additionalComponents={
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                onClick={() => showForm('reset', true)}
                                                color="primary"
                                                style={{ margin: '-7px 0 26px 0', padding: 0 }}
                                            >
                                                <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>Forgot your Password?</Typography>
                                            </Button>
                                        </Box>}
                                />
                                <Typography align="center" variant="body2">
                                    Don't have an account yet?
                                    <Button color="secondary" onClick={() => showForm('checkEmail', true)}>Sign Up</Button>
                                </Typography>
                            </React.Fragment>
                        }
                        {showRegistrationForm &&
                            <React.Fragment>
                                <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Create Account</Typography>
                                <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>{checkedEmail ? 'Email not found. ' : ''}Create a new SmartCore account to join.</Typography>
                                <RegisterForm
                                    company={company}
                                    onSuccess={() => props.setOpen(false)}
                                    email={checkedEmail}
                                />
                                <Typography align="center" variant="body2">
                                    Already have an account?
                                    <Button color="secondary" onClick={() => showForm('login', true)}>Sign In!</Button>
                                </Typography>
                            </React.Fragment>
                        }
                        {showCheckEmailForm &&
                            <React.Fragment>
                                <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Join</Typography>
                                <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>Enter your email address and we'll check if you need to create a new SmartCore account.</Typography>
                                <Formik
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            email: Yup.string().email()
                                                .required("Required")
                                        })
                                    }
                                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                        setIsSubmitting(true);
                                        try {
                                            const emailExistDoeNotExist = await dispatch(checkEmailDoesNotExist(values.email));
                                            setCheckedEmail(values.email);
                                            if (emailExistDoeNotExist)
                                                showForm('registration')
                                            else
                                                showForm('login')
                                            setIsSubmitting(false);
                                        } catch (error) {
                                            setError(error.message);
                                            setIsSubmitting(false);
                                        }
                                    }}
                                >
                                    {({
                                        errors,
                                        handleSubmit,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue,
                                        touched,
                                        values,
                                    }) => (
                                        <form noValidate onSubmit={handleSubmit}>
                                            {errors.submit && (
                                                <Alert mt={2} mb={2} severity="warning">
                                                    {errors.submit}
                                                </Alert>
                                            )}
                                            <TextField
                                                name="email"
                                                variant='outlined'
                                                label="Email"
                                                value={values.email}
                                                error={Boolean(touched.email && errors.email)}
                                                fullWidth
                                                helperText={touched.email && errors.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                mb={2}
                                            />
                                            <ActionButton
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                my={3}
                                                submitting={isSubmitting}
                                            >
                                                Continue
                                            </ActionButton>
                                            {error && <Alert mb={4} severity="error">
                                                {error}
                                            </Alert>}
                                        </form>
                                    )}
                                </Formik>
                                <Typography align="center" variant="body2">
                                    Already have an account?
                                    <Button color="secondary" onClick={() => showForm('login', true)}>Sign in</Button>
                                </Typography>
                            </React.Fragment>
                        }
                        {showResetForm &&
                            <Box >
                                <PasswordResetForm
                                    onSuccess={() => { showForm('login', true) }}
                                />
                                <Box display="flex" justifyContent="center" mt={4}>
                                    <Button
                                        onClick={() => showForm('login', true)}
                                        color="primary"
                                        style={{ margin: '-6px 0 0 0', padding: 0 }}
                                    >
                                        <Typography variant="body2" style={{ marginBottom: '46px', fontSize: '14px', lineHeight: '18px' }}>Back to Sign In</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </React.Fragment>
                }
            </Box>

        </StyledDialog >
    );
}

export default LoginDialog;
