import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Card,
    CardContent,
    Button,
    Grid,
    Typography as MuiTypography,
    CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { fetchActiveSubscription, cancelSvxvySubscription, reactivateSvxvySubscription, fetchCompanyPurchases } from "../../../store/actions/companyActions";
import { detailPerson, fetchPeople } from '../../../store/actions/peopleActions';
import SubscriptionCard from "../products/SubscriptionCard";
import SelectSvxvySubscription from "./SelectSvxvySubscription";
import { SVXVY_TRIAL_SUBSCRIPTION_ID } from "../../../models/Product";
import StyledDialog from "../../framework/StyledDialog";
import OwnerMemberTally from "../helpContent/OwnerMemberTally";

// constants
const Typography = styled(MuiTypography)(spacing);

const ManageSvxvySubscription = (props) => {
    const companyId = useSelector(state => state.company.companyId);
    const loggedInUser = useSelector(state => state.auth.person);
    const loggedInUserId = loggedInUser.id;
    const subscription = useSelector(state => state.company.subscription);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [changeSubscriptionDialogVisiable, setChangeSubscriptionDialogVisiable] = useState(false);

    /******* cancel subscription states */
    const [isCanceling, setIsCanceling] = useState(false);
    /******* cancel subscription states */

    const dispatch = useDispatch();
    const loadSubscription = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(detailPerson(loggedInUserId, companyId));
            await dispatch(fetchPeople(companyId));
            await dispatch(fetchCompanyPurchases(companyId));
            await dispatch(fetchActiveSubscription(companyId));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId, loggedInUserId]);

    useEffect(() => {
        loadSubscription();
    }, [loadSubscription]);

    const cancelSubscription = useCallback(async (subscrptionId, isCancel) => {
        setError(null);
        setIsCanceling(true);
        try {
            if (isCancel)
                await dispatch(cancelSvxvySubscription(subscrptionId));
            else
                await dispatch(reactivateSvxvySubscription(subscrptionId));
        } catch (err) {
            setError(err.message);
        }
        setIsCanceling(false);
    }, [dispatch]);

    const handlesCancelReactivateSubscription = (isCancel) => {
        cancelSubscription(subscription.id, isCancel);
    }

    return (
        <Grid>
            {isLoading &&
                <Card >
                    <CardContent pb={0}>
                        <Typography gutterBottom>Loading subscription information.</Typography>
                        <CircularProgress color="secondary" size={25} />
                    </CardContent>
                </Card>
            }
            {error &&
                <Card>
                    <CardContent pb={0}>
                        <Alert severity="error">{error}</Alert>
                    </CardContent>
                </Card>
            }
            {!isLoading &&
                <React.Fragment>
                    <OwnerMemberTally hidelink />
                    <SubscriptionCard subscription={subscription}>
                        <Button type="submit" variant="contained" color="primary" mt={3} onClick={() => setChangeSubscriptionDialogVisiable(true)} disabled={isCanceling}>Change plans</Button>
                        {!isCanceling
                            && subscription.expirationDate >= Date.now()
                            && subscription.purchasedProduct.id !== SVXVY_TRIAL_SUBSCRIPTION_ID
                            && !subscription.memo
                            && <Button type="submit"
                                variant="contained"
                                color="primary"
                                mt={3}
                                onClick={() => handlesCancelReactivateSubscription(subscription.automaticallyRenew ? true : false)}>
                                {subscription.automaticallyRenew ? "Cancel Subscription" : "Reactivate Subscription"}
                            </Button>
                        }
                        {isCanceling && <CircularProgress color="secondary" size={20} />}
                    </SubscriptionCard>
                </React.Fragment>
            }
            {changeSubscriptionDialogVisiable &&
                <StyledDialog
                    open={changeSubscriptionDialogVisiable}
                    setOpen={setChangeSubscriptionDialogVisiable}
                    aria-labelledby="form-dialog-title" maxWidth="md"
                >
                    <Box mb={4}>
                        <Typography variant="h2">Purchase Subscription</Typography>
                    </Box>
                    <SelectSvxvySubscription closeAction={setChangeSubscriptionDialogVisiable} />
                </StyledDialog>
            }
        </Grid>
    );
}

export default ManageSvxvySubscription;