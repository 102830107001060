import React from "react";
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { Link as RouterLink, NavLink } from 'react-router-dom';

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

import {
  Box,
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  Typography as MuiTypography
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoLogoInstagram as InstagramIcon,
  IoLogoFacebook as FacebookIcon,
  IoLogoTwitter as TwitterIcon,
  IoLogoVimeo as VimeoIcon,
  IoLogoYoutube as YouTubeIcon,
  IoLogoTiktok as TickTokIcon,

} from "react-icons/io5";


// svxvy imports

import * as NavigationConstants from '../../constants/NavigationConstants';

import { companyPublicRoutes as routes } from "../../routes/indexSvxvy";
import { formatImage } from '../../helpers/imageHelpers';
import { DEFAULT_COMPANY_LOGO } from '../../models/Company';
import { isSvxvySite } from '../../constants/NetworkConstants';
import { hideWebPage } from './CompanyWebHeader';
import { replaceDisplayName } from '../../helpers/navigationFunctions';
import PoweredBySmartCore from "../PoweredBySmartCore";

// constants

const Typography = styled(MuiTypography)(spacing);

const CompanyName = styled(MuiListItem)`
  max-width: 100%;
  color: ${(props) => props.theme.header.color};
  margin-bottom: 20px;
  padding: 0;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.sidebar.category.svg.colorActive};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 66px;
  }
`;

const PrimaryMenuBarItem = styled(Box)` 
  color: ${(props) => props.theme.header.color};
  padding-bottom: 30px;
  display: block;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  font-size: 14px;
  text-decoration: none;
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    display: inline-block;
    padding-top: 9px;
    padding-right: 30px;
    padding-bottom: 0;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    display: inline-block;
    padding-top: 9px;
    padding-right: 15px;
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  background: ${(props) => { return props.theme.footer.background; }};
  padding: 30px 30px 27px 30px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 52px 35px 49px 35px;
  }
`;

const SecondaryFooterItem = styled(MuiListItem)`
  width: auto;
  background-color: transparent !important;
  margin-right: 15px;
  padding: 0;
  display: inline-block;

  span {
    color: ${(props) => props.theme.palette.text.secondary} !important;
  }

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
  
`;

const SocialList = styled(List)`
  margin-top: -3px;
  padding: 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0;
    position: absolute;
    top: 7px;
    left: 0;
  }
`;

const SocialItem = styled(MuiListItem)`
  background-color: transparent !important;
  margin-bottom: 10px;
  padding: 0;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 10;
  }
`;

const ListItemText = styled(MuiListItemText)`
  margin: 0;

  span {
    color: ${(props) => props.theme.footer.color};
    margin: 0;
    font-size: 12px;
  }
`;

const UtilityNav = styled(List)`
  padding: 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: -2px;
  }
`;

const ImageBox = styled(Box)`
  height: auto
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 0;
`;

const Image = styled.img`
  min-width: 40px;
  // max-width: calc(100vw - 120px);
  // max-height: 100px;

  // ${(props) => props.theme.breakpoints.up("565")} {
  //   max-width: 450px;
  // }
  // ${(props) => props.theme.breakpoints.down("900")} {
  //   max-height: 50px;
  }
`;


const CompanyFooter = props => {
  const { company, isOwner } = props;
  const webPages = useSelector(state => state.company.webPages);
  const theme = useTheme();
  const baseURL = isSvxvySite() && company ? "/" + company.sanitizedName : "";


  return (
    <Wrapper>
      <Box maxWidth={1440} style={{ margin: '0 auto' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4}>
            {company &&
              <Grid item>
                <CompanyName
                  component={NavLink}
                  to={isSvxvySite() ? "/" + company.sanitizedName : "/"}
                  exact
                >
                  {company.logoPhoto && company.logoPhoto !== DEFAULT_COMPANY_LOGO &&
                    <ImageBox>
                      <Image
                        src={formatImage(company.logoPhoto.url, null, '.webp')}
                        width={company.logoPhoto && company.logoPhoto.width && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? (400 * company.logoPhoto.scalingFactor / 100) : 'auto'}
                        height={company.logoPhoto && company.logoPhoto.height && (company.logoPhoto.width / company.logoPhoto.height > 1.8) ? 'auto' : company.logoPhoto.height > 100 ? (100 * (company.logoPhoto.scalingFactor * 0.01)) : (company.logoPhoto.height * (company.logoPhoto.scalingFactor * 0.01))}

                      />
                    </ImageBox>
                  }
                  {!company.logoPhoto && <Typography variant="h3" mt={2}>{company.name}</Typography>}
                </CompanyName>
              </Grid>
            }
          </Grid>
          <Grid item xs={8} md={7}>
            {company && routes && routes.filter(x => !x.hideInNavigation && !hideWebPage(webPages, x.id, isOwner)).map((category, index) =>
              <PrimaryMenuBarItem
                key={category.id}
                to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                activeClassName="active"
                component={NavLink}
                exact
              >
                {replaceDisplayName(category.id, company)}
              </PrimaryMenuBarItem>
            )}
          </Grid>
          <Grid item xs={4} md={1} style={{ position: 'relative' }}>
            {company &&
              <SocialList>
                {company.instagramLink &&
                  <SocialItem component="a" href={company.instagramLink} target="blank">
                    <InstagramIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText fontSize="12px">Instagram</ListItemText>
                  </SocialItem>
                }
                {company.facebookLink &&
                  <SocialItem component="a" href={company.facebookLink} target="blank">
                    <FacebookIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText>Facebook</ListItemText>
                  </SocialItem>
                }
                {company.twitterLink &&
                  <SocialItem component="a" href={company.twitterLink} target="blank">
                    <TwitterIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText>Twitter</ListItemText>
                  </SocialItem>
                }
                {company.vimeoLink &&
                  <SocialItem component="a" href={company.vimeoLink} target="blank">
                    <VimeoIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText>Vimeo</ListItemText>
                  </SocialItem>
                }
                {company.youTubeLink &&
                  <SocialItem component="a" href={company.youTubeLink} target="blank">
                    <YouTubeIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText>YouTube</ListItemText>
                  </SocialItem>
                }
                {company.tickTokLink &&
                  <SocialItem component="a" href={company.tickTokLink} target="blank">
                    <TickTokIcon color={theme.palette.primary.main} size="22" style={{ marginRight: '9px' }} /><ListItemText>TickTok</ListItemText>
                  </SocialItem>
                }
              </SocialList>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <PoweredBySmartCore />
          </Grid>
          <Grid item xs={12} md={4}>
            <UtilityNav>
              {isSvxvySite() &&
                <SecondaryFooterItem component={RouterLink} to={"/" + NavigationConstants.LOOKUP_COMPANY}>
                  <ListItemText>Lookup a Company</ListItemText>
                </SecondaryFooterItem>
              }
              <SecondaryFooterItem component="a"
                href="https://www.smartcoreapp.com/faqs"
                target="_blank">
                <ListItemText>Help Center</ListItemText>
              </SecondaryFooterItem>
              <SecondaryFooterItem
                component={NavLink}
                to={baseURL + "/" + NavigationConstants.PRIVACY_POLICY}
                exact>
                <ListItemText>Privacy Policy</ListItemText>
              </SecondaryFooterItem>
              <SecondaryFooterItem
                component={NavLink}
                to={NavigationConstants.EULA}
                exact
                target="_blank">
                <ListItemText>EULA</ListItemText>
              </SecondaryFooterItem>
            </UtilityNav>
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}

export default CompanyFooter;
