import React, { useState } from "react";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Checkbox,
    Typography as MuiTypography,
} from "@material-ui/core";

//svxvy imports

import ProfileIcon from "./ProfileIcon";

// constants
const Typography = styled(MuiTypography)(spacing);
const ProfileRow = styled(Box)`
    // (spacing)
    background-color: transparent;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 0;
    box-shadow: none !important;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }
`;

const CardContent = styled(Box)`
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ProfileListItem = (props) => {
    const { person, hideCheckbox } = props;
    const [isSelected, setIsSelected] = useState(props.isSelected ? props.isSelected : false)


    const nameOnPress = () => {
        if (props.profileNamePress) {
            const localIsSelected = isSelected;
            setIsSelected(previousState => !previousState);
            // props.person.isSelected = !props.person.isSelected;
            props.profileNamePress(props.person, !localIsSelected);
        }
    }

    return (
        <ProfileRow onClick={nameOnPress}>
            <CardContent>
                {!hideCheckbox && <Checkbox checked={isSelected} />}
                <ProfileIcon person={person} />
                <Typography ml={2} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{person.displayName}</Typography>
            </CardContent>
        </ProfileRow>
    );
}

export default ProfileListItem;