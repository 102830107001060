import Company from "../../models/Company";

import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';


import { ADD_COMPANY, SET_COMPANY } from './companyActions';
import { refreshLogedInUser } from './authActions';

export const CHECK_COMPANY_NAME = 'CHECK_COMPANY_NAME';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COMPANY_BASE_DATA = 'SET_COMPANY_BASE_DATA';
export const ADD_EDIT_COMPANY_LOCATION = 'ADD_EDIT_COMPANY_LOCATION';
export const SET_COMPANY_ATTIBUTES = 'SET_COMPANY_ATTIBUTES';
export const SET_COMPANY_OWNER = 'SET_COMPANY_OWNER';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';


export const checkCompanyName = (companyName) => {
    return async (dispatch, getState) => {
        if (companyName.length > 0) {
            // any asych code you want - like service call
            let response = null;
            response = await fetch(NetworkConstants.SERVER + '/api/company/nameavailible?name=' + companyName, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getState().auth.token
                },
            });
            if (!response.ok) {
                const errorResData = await response.json();
                const message = getErrorMessagesFromResponse(errorResData);
                throw new Error(message);
            }
            const resData = await response.json();
            dispatch({ type: SET_COMPANY_NAME, companyName: companyName });
            return resData;
        }
        else {
            dispatch({ type: SET_COMPANY_NAME, companyName: companyName });
            return false;
        }
    };
};

export const createCompany = (values) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        const company = new Company(resData.id, resData.name, resData.description, resData.isProfilePhoneNumberRequired, resData.isProfileNameAliasAllowed, resData.sanitizedName, resData.hideInSharedApp,
            resData.locationText, resData.locationsText,
            resData.employeeText, resData.employeesText,
            resData.activityText, resData.activitiesText,
            resData.customerText, resData.customersText,
            resData.colors, resData.websiteDomainName,
            resData.logo, null,
            resData.companyContactEmail, resData.passOnPaymentServiceFee,
            resData.paymentServiceFeeDescription, resData.hideBilling,
            resData.phoneNumber, resData.allowTexts,
            resData.subscriptionStartDay, resData.freeTrialPeriod, resData.proRateMonth,
            resData.people, resData.webPages, resData.locations, resData.photos, resData.offerings,
            resData.instagramLink, resData.facebookLink, resData.twitterLink,
            resData.vimeoLink, resData.youTubeLink, resData.tickTokLink,
            resData.logoPhoto, resData.products, resData.inactiveProducts,
            resData.allowManualLocations,
            resData.globalReminderMessage, resData.globalReminderHours,
            resData.faqs, resData.googleClientId, resData.googleCalendarAppApproved,
            resData.allowCustomerCancelMembership, resData.cancelTextMembershipText,
            resData.waiverText, resData.embedScripts, resData.manuallySortProducts,
            resData.hideDroppedActivityMessage,
            resData.dropActivityWarningMessage,
            resData.preDeadlineDropActivityMessage,
            resData.postDeadlineDropActivityMessage,
            resData.noDropActivityMessage,
            resData.passwordResetMessage,
            resData.accountRegisterEmailMessage,
            resData.activityRegisterEmailMessage,
            resData.activityDroppedEmailMessage,
            resData.hideFullSelfRegisterActivities,
            resData.usesStripe,
            resData.usesWebsite,
            resData.purchaseNotificationEmails,
            resData.allowedCustomerCount,
            resData.overrideAllowedCustomerCount,
            resData.reviews,
            resData.manualLocationTimezone,
            resData.isRABSite,
        );
        await dispatch({ type: ADD_COMPANY, company: company });
        await dispatch({
            type: SET_COMPANY, company: company, id: resData.id,
            locationText: resData.locationText, locationsText: resData.locationsText,
            employeeText: resData.employeeText, employeesText: resData.employeesText,
            activityText: resData.activityText, activitiesText: resData.activitiesText,
            customerText: resData.customerText, customersText: resData.customersText,
            colors: resData.colors
        });
        await dispatch({ type: RESET_REGISTRATION });
        await dispatch(refreshLogedInUser(getState().auth.token));
        // await dispatch(login(values.emailText, values.passwordText, company.id));
        return company;
    }
}