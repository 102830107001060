import { ALLOWED_PAYMENT_STATUSES } from '../../models/Payment';

import { cloneDeep } from 'lodash';

import {
    ADD_COMPANY, EDIT_COMPANY, DELETE_COMPANY,
    SET_COMPANIES, SET_COMPANY, SET_NO_COMPANY,
    SET_ROLES, SET_COMPANY_SUBSCRIPTION,
    UPLOAD_COMPANY_LOGO, UPLOAD_PHOTO,
    DELETE_PHOTO, SET_PHOTOS,
    CHANGE_COMPANY_LOGO, SET_COMPANY_PURCHASES
} from '../actions/companyActions';

import {
    OFFERING_CREATED, OFFERING_EDITED, OFFERING_DELETED,
    WEBPAGE_UPDATED
} from '../actions/publicWebActions';
import {
    FAQ_CREATED, FAQ_EDITED, FAQ_DELETED,
} from '../actions/faqActions';
import {
    SET_REVIEWS, REVIEW_CREATED, REVIEW_EDITED, REVIEW_DELETED,
} from '../actions/reviewActions';
import { SET_LOCATIONS, ADD_LOCATION, EDIT_LOCATION, DELETE_LOCATION } from '../actions/locationActions';
import { SET_PEOPLE, ADD_PERSON, DELETE_PERSON, EDIT_PERSON, SET_CHILD_PROFILES, ADD_EDIT_CHILD_PROFILE, LOAD_PEOPLE, LOAD_PEOPLE_ERROR } from '../actions/peopleActions';
import { SET_PAYMENT_RECORDS, UPDATE_PAYMENT_STATUS } from '../actions/paymentActions';
import { PRODUCT_UPDATED } from '../actions/productActions';
import { LOGOUT } from '../actions/authActions';

import { addToArray, deleteById } from '../../helpers/arrayFunctions';

export const ACTIVITY = 'Activity';
export const ACTIVITIES = 'Activities';
export const EMPLOYEE = 'Employee';
export const EMPLOYEES = 'Employees';
export const LOCATION = 'Location';
export const LOCATIONS = 'Locations';
export const CUSTOMER = 'Customer';
export const CUSTOMERS = 'Customers';

const initialState = {
    companies: [],
    companyId: '',
    company: null,
    people: [],
    peopleLoading: false,
    locations: [],
    payments: [],
    photos: [],
    webPages: [],
    offerings: [],
    faqs: [],
    reviews: [],
    purchases: [],
    roles: [],
    subscription: null,
    timeFormat: 'h:mma',
    locationText: LOCATIONS,
    locationsText: LOCATION,
    employeeText: EMPLOYEE,
    employeesText: EMPLOYEES,
    activityText: ACTIVITY,
    activitiesText: ACTIVITIES,
    customerText: CUSTOMER,
    customersText: CUSTOMERS,
    allowedPaymentStatuses: ALLOWED_PAYMENT_STATUSES,
}

const updateChildProfiles = (id, childProfiles, people) => {
    const indexOfPersonToUpdate = people.findIndex(item => item.id === id);
    if (indexOfPersonToUpdate > -1) {
        const personToUpdate = cloneDeep(people[indexOfPersonToUpdate]);
        personToUpdate.childProfiles = childProfiles

        const updatedPeople = deleteById(personToUpdate.id, people);
        return addToArray(personToUpdate, updatedPeople);
    }

    return people;
}

const AddEditChildProfile = (id, profile, people) => {
    const indexOfPersonToUpdate = people.findIndex(item => item.id === id);
    if (indexOfPersonToUpdate > -1) {
        const personToUpdate = cloneDeep(people[indexOfPersonToUpdate]);
        if (personToUpdate.childProfiles) {
            const updatedChildrend = deleteById(profile.id, personToUpdate.childProfiles);
            personToUpdate.childProfiles = [...updatedChildrend, profile];
        }

        const updatedPeople = deleteById(personToUpdate.id, people);
        return addToArray(personToUpdate, updatedPeople);
    }

    return people;
}

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            if (state.companyId === "") {
                return { ...initialState, companies: state.companies };
            } else {
                const publicPeople = cloneDeep(state.people).filter(x => x.isPublic);
                return { ...state, companies: state.companies, people: publicPeople, locations: state.locations, payments: [], roles: [] };
            }
        case SET_NO_COMPANY:
            return { ...initialState, companies: state.companies };
        case SET_COMPANIES:
            return { ...state, companies: action.companies };
        case SET_COMPANY:
            return {
                ...state,
                // companies: addToArray(action.company, deleteById(action.id, state.companies)),
                company: action.company, 
                companyId: action.id,
                locations: action.company.locations,
                people: state.people.length > 0 ? state.people : action.company.people,
                webPages: action.company.webPages,
                photos: action.company.photos,
                offerings: action.company.offerings,
                faqs: action.company.faqs,
                reviews: action.company.reviews,
                locationText: action.locationText != null ? action.locationText : LOCATION,
                locationsText: action.locationsText != null ? action.locationsText : LOCATIONS,
                employeeText: action.employeeText != null ? action.employeeText : EMPLOYEE,
                employeesText: action.employeesText != null ? action.employeesText : EMPLOYEES,
                activityText: action.activityText != null ? action.activityText : ACTIVITY,
                activitiesText: action.activitiesText != null ? action.activitiesText : ACTIVITIES,
                customerText: action.customerText != null ? action.customerText : CUSTOMER,
                customersText: action.customersText != null ? action.customersText : CUSTOMERS,
            };
        case ADD_COMPANY:
            return { ...state, companies: addToArray(action.company, state.companies) };
        case EDIT_COMPANY:
            const updatedCompanies = deleteById(action.company.id, state.companies);
            return { ...state, companies: addToArray(action.company, updatedCompanies) };
        case DELETE_COMPANY:
            const deletedCompanies = deleteById(action.companyId, state.companies);
            return { ...state, companies: deletedCompanies };
        case UPLOAD_COMPANY_LOGO:
            const updateCompany = cloneDeep(state.company);
            updateCompany.logo = action.logo;
            return { ...state, company: updateCompany, photos: addToArray(action.photo, state.photos) };
        case UPLOAD_PHOTO:
            return { ...state, photos: addToArray(action.photo, state.photos) }
        case DELETE_PHOTO:
            return { ...state, photos: deleteById(action.photoID, state.photos) }
        case SET_PHOTOS:
            return { ...state, photos: action.photos }
        case CHANGE_COMPANY_LOGO:
            const oldLogo = state.photos.find(x => x.isMain);
            let updatePhotos = [];
            const newLogo = cloneDeep(state.photos.find(x => x.id === action.photoID));
            if (oldLogo !== null) {
                updatePhotos = deleteById(oldLogo, state.photos);
                updatePhotos = deleteById(newLogo.id, updatePhotos.photos);
            } else {
                updatePhotos = deleteById(newLogo.id, state.photos);
            }
            newLogo.isMain = true;
            let updatedCompany = cloneDeep(state.company);
            updatedCompany.logo = newLogo.url
            return { ...state, company: updateCompany, photos: addToArray(newLogo, updatePhotos) }
        case SET_ROLES:
            return { ...state, roles: action.roles };
        case SET_LOCATIONS:
            return { ...state, locations: action.locations };
        case ADD_LOCATION:
            return { ...state, locations: addToArray(action.location, state.locations) };
        case EDIT_LOCATION:
            const updatedLocations = deleteById(action.location.id, state.locations);
            return { ...state, locations: addToArray(action.location, updatedLocations) };
        case DELETE_LOCATION:
            const locationsToUpdate = cloneDeep(state.locations);
            const locationToUpdate = locationsToUpdate.find(x => x.id === action.locationId);
            locationToUpdate.isDeleted = true;
            return { ...state, locations: locationsToUpdate };
        case LOAD_PEOPLE:
            return { ...state, peopleLoading: true };
        case LOAD_PEOPLE_ERROR:
            return { ...state, peopleLoading: false };
        case SET_PEOPLE:
            return { ...state, people: action.people, peopleLoading: false };
        case ADD_PERSON:
            return { ...state, people: addToArray(action.person, state.people) };
        case EDIT_PERSON:
            const updatedPeople = deleteById(action.person.id, state.people);
            return { ...state, people: addToArray(action.person, updatedPeople) };
        case SET_CHILD_PROFILES:
            return { ...state, people: updateChildProfiles(action.id, action.childProfiles, state.people) };
        case ADD_EDIT_CHILD_PROFILE:
            return { ...state, people: AddEditChildProfile(action.id, action.childProfile, state.people) };
        case DELETE_PERSON:
            const peopleToUpdate = cloneDeep(state.people);
            const personToUpdate = peopleToUpdate.find(x => x.id === action.id);
            if (personToUpdate)
                personToUpdate.isDeleted = true;

            return { ...state, people: peopleToUpdate };
        case SET_PAYMENT_RECORDS:
            return { ...state, payments: action.payments };
        case UPDATE_PAYMENT_STATUS:
            let updatedPayments = state.payments;
            if (action.payment) {
                updatedPayments = deleteById(action.payment.id, state.payments);
                updatedPayments = addToArray(action.payment, updatedPayments);
            }
            let updatedPuchases = state.purchases;
            if (action.purchase) {
                updatedPuchases = deleteById(action.purchase.id, state.purchases);
                updatedPuchases = addToArray(action.purchase, updatedPuchases);
            }
            if (action.packageUsed) {
                updatedPuchases = deleteById(action.packageUsed.id, updatedPuchases);
                updatedPuchases = addToArray(action.packageUsed, updatedPuchases);
            }
            return { ...state, payments: updatedPayments, purchases: updatedPuchases };
        case SET_COMPANY_SUBSCRIPTION:
            return { ...state, subscription: action.subscription };
        case SET_COMPANY_PURCHASES:
            return { ...state, purchases: action.purchases };

        // Public Web Site Actions
        case OFFERING_CREATED:
            return { ...state, offerings: addToArray(action.offering, state.offerings) };
        case OFFERING_EDITED:
            return { ...state, offerings: addToArray(action.offering, deleteById(action.offering.id, state.offerings)) };
        case OFFERING_DELETED:
            return { ...state, offerings: deleteById(action.offeringId, state.offerings) };
        case FAQ_CREATED:
            return { ...state, faqs: addToArray(action.faq, state.faqs) };
        case FAQ_EDITED:
            return { ...state, faqs: addToArray(action.faq, deleteById(action.faq.id, state.faqs)) };
        case FAQ_DELETED:
            return { ...state, faqs: deleteById(action.faq, state.faqs) };
        case SET_REVIEWS:
            return { ...state, reviews: action.reviews };
        case REVIEW_CREATED:
            return { ...state, reviews: addToArray(action.review, state.reviews) };
        case REVIEW_EDITED:
            return { ...state, reviews: addToArray(action.review, deleteById(action.review.id, state.reviews)) };
        case REVIEW_DELETED:
            return { ...state, reviews: deleteById(action.review, state.reviews) };
        case WEBPAGE_UPDATED:
            return { ...state, webPages: addToArray(action.webPage, deleteById(action.webPage.id, state.webPages)) };
        case PRODUCT_UPDATED:
            return { ...state, purchases: addToArray(action.purchase, deleteById(action.purchase.id, state.purchases)) }
        default:
            return state;
    }
}

export default companyReducer;