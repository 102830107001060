export const THEME_BASE_LIGHT = '#FFFFFF'; // dark: black
export const THEME_TEXT_LIGHT = '#000000'; // dark: white
export const THEME_GREY_0_LIGHT = '#A5A5A5';
export const THEME_GREY_1_LIGHT = '#F2F2F2'; // dark: #1A1A1A (BACKGROUND COLOR)
export const THEME_GREY_2_LIGHT = '#808080';
export const THEME_GREY_2_TEXT_LIGHT = '#ffffff';
export const THEME_GREY_3_LIGHT = '#A6A6A6'; // dark: #595959 (DISABLED COLOR)
export const THEME_GREY_4_LIGHT = '#D9D9D9'; // dark: #333333 (BORDER COLOR)

export const THEME_BASE_DARK = '#000000';
export const THEME_TEXT_DARK = '#FFFFFF';
export const THEME_GREY_0_DARK = '#A7A7A7';
export const THEME_GREY_1_DARK = '#1A1A1A';
export const THEME_GREY_2_TEXT_DARK = '#ffffff';
export const THEME_GREY_2_DARK = '#808080';
export const THEME_GREY_3_DARK = '#595959';
export const THEME_GREY_4_DARK = '#333333';

export const USER_DEFINABLE_DEFAULT = '#0A7AFF'; // user: blue
export const USER_DEFINABLE_TEXT_DEFAULT = THEME_TEXT_DARK;

export const SYSTEM_ORANGE = '#FF9900';
export const SYSTEM_ORANGE_TEXT = '#ffffff';
export const SYSTEM_BLUE = '#0A7AFF';
export const SYSTEM_BLUE_TEXT = '#ffffff';
export const SYSTEM_RED = '#FF0000';
export const SYSTEM_RED_TEXT = '#ffffff';
export const SYSTEM_GREEN = '#009900';
export const SYSTEM_GREEN_TEXT = '#ffffff';

// export let THEME_BASE = THEME_BASE_LIGHT;
// export let THEME_TEXT = THEME_TEXT_LIGHT;
// export let THEME_GREY_0 = THEME_GREY_0_LIGHT;
// export let THEME_GREY_1 = THEME_GREY_1_LIGHT;
// export let THEME_GREY_2 = THEME_GREY_2_LIGHT;
// export let THEME_GREY_2_TEXT = THEME_GREY_2_TEXT_LIGHT;
// export let THEME_GREY_3 = THEME_GREY_3_LIGHT; // dark: #595959 (DISABLED COLOR)
// export let THEME_GREY_4 = THEME_GREY_4_LIGHT; // dark: #333333 (BORDER COLOR)
// export let USER_DEFINABLE = USER_DEFINABLE_DEFAULT; // user: blue
// export let USER_DEFINABLE_TEXT = USER_DEFINABLE_TEXT_DEFAULT;

// export const setColors = (useDarkTheme, brandColors) => {
//   if (typeof document === 'undefined') {
//     if (useDarkTheme) {
//       THEME_BASE = THEME_BASE_DARK;
//       THEME_TEXT = THEME_TEXT_DARK;
//       THEME_GREY_0 = THEME_GREY_0_DARK;
//       THEME_GREY_1 = THEME_GREY_1_DARK;
//       THEME_GREY_2 = THEME_GREY_2_DARK;
//       THEME_GREY_2_TEXT = THEME_GREY_2_TEXT_DARK;
//       THEME_GREY_3 = THEME_GREY_3_DARK;
//       THEME_GREY_4 = THEME_GREY_4_DARK;
//       USER_DEFINABLE_TEXT = THEME_TEXT_DARK
//     }
//     else {
//       THEME_BASE = THEME_BASE_LIGHT;
//       THEME_TEXT = THEME_TEXT_LIGHT;
//       THEME_GREY_0 = THEME_GREY_0_LIGHT;
//       THEME_GREY_1 = THEME_GREY_1_LIGHT;
//       THEME_GREY_2 = THEME_GREY_2_LIGHT;
//       THEME_GREY_2_TEXT = THEME_GREY_2_TEXT_LIGHT;
//       THEME_GREY_3 = THEME_GREY_3_LIGHT;
//       THEME_GREY_4 = THEME_GREY_4_LIGHT;
//       USER_DEFINABLE_TEXT = THEME_TEXT_LIGHT;
//     }
//     USER_DEFINABLE = brandColors.themePrimary ? brandColors.themePrimary : (brandColors.accentColor ? brandColors.accentColor : USER_DEFINABLE_DEFAULT);
//   }
// }

// const Colors = {
//   primaryColor: '#26495c',
//   accentColor: '#c66b3d',
//   linkColor: '#3FA9FF',
//   iosColorHeader: '#26495c',
//   iosTextColorHeader: 'white',
//   andriodColorHeader: '#26495c',
//   andriodTextColorHeader: 'white',
// }
// export default Colors

// export const THEMES = {
//   DEFAULT: "DEFAULT",
//   DARK: "DARK",
//   SMARTCORE: "SMARTCORE",
//   COMPANY_LIGHT: "COMPANYLIGHT",
//   COMPANY_DARK: "COMPANYDARK"
// };

export const DEFAULT_THEME = "DEFAULT_THEME";
export const DARK =  "DARK";
export const SMARTCORE =  "SMARTCORE";
export const COMPANY_LIGHT =  "COMPANYLIGHT";
export const COMPANY_DARK =  "COMPANYDARK";
export const COMPANY_LIGHT_WEB =  "COMPANYLIGHTWEB";
export const COMPANY_DARK_WEB =  "COMPANYDARKWEB";

export const COMPANY_WEB_TYPOGRAPHY =  "COMPANY_WEB_TYPOGRAPHY";
export const COMPANY_APP_TYPOGRPAHY =  "COMPANY_APP_TYPOGRPAHY";