import {
    FormControl as MuiFormControl,
    InputLabel as MuiInputLabel,
} from "@material-ui/core";


import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

// custom select start (select styling in overrides.js)

const FormControl = styled(MuiFormControl)(spacing);

const SelectControl = styled(FormControl)`
    // margin: 0 0 20px 0;
    margin-bottom:10px;
    margin-top: 0;
    // margin-bottom:20px;
    .MuiSelect-select {
        padding: 27.5px 15px 11.5px 15px;
        z-index: 3;
    }

    fieldset {
        background-color: ${(props) => props.theme.palette.background.paper};
        border: 1px solid ${(props) => props.theme.palette.divider} !important;
        border-radius: 10px;
        top: 0;

        legend {
            display: none;
        }
    }
`;
const SelectInputLabel = styled(MuiInputLabel)`
    color: ${(props) => props.theme.palette.text.primary} !important;
    background-color: ${(props) => props.theme.palette.background.paper} !important;
    opacity: 0.7;
    transform: translate(15px, 20px) !important;
    z-index: 3;

    &.MuiInputLabel-outlined.MuiInputLabel-shrink {
        opacity: 0.5;
        transform: translate(15px, 10px) scale(0.85) !important;
    }
`;

const SelectFormControl = props => {
    const {labelId, labelText, ...rest} = props;
    return (
        <SelectControl variant="outlined" {...rest} >
            {props.labelId && props.labelText && <SelectInputLabel id={labelId}>{labelText}</SelectInputLabel>}
            {props.children}
        </SelectControl>
    )
}

export default SelectFormControl;