import React from 'react';
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    // Button,
    Typography as MuiTypography,
    Grid as MuiGrid
} from "@material-ui/core";

//svxvy imports

import { formatImage } from '../../helpers/imageHelpers';

// constants 
const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const HeroBox = styled(Box)`
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.default};
    position: relative;

    ${(props) => props.theme.breakpoints.up("md")} {
        &:before {
            width: 100%;
            height: 100%;
            // background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, transparent 100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            content: '';
        }
    }
`;

const HeroImage = styled(Box)`
    width: 100%;
    height: 400px;

    ${(props) => props.theme.breakpoints.up("md")} {
        height: 715px;
    }
`;

const FullContainer = styled(Box)`
    width: 100%;
    padding-top: 50px;
    padding-bottom: 60px;
    display: flex;
    text-align: center;
    justify-content: center;

    ${(props) => props.theme.breakpoints.up("md")} {
        background: rgba(0,0,0,0.25);
        color: white;
        backdrop-filter: blur(6px);
        padding: 34px 30px 30px 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
`;


export const defaultImage = 'https://res.cloudinary.com/svxvy/image/upload/v1639592221/default_header_znqwuo.webp'
export const defaultRABImage = 'https://res.cloudinary.com/svxvy/image/upload/v1694120654/RockaBabyRI/lplla2szswoavyz73zau.webp'
const defaultTitle = 'Tagline Goes Right Here';
const defaultSubtitle = 'A short description of your services can go here. We recommend a few medium sentences, so that users can easily consume the content.';

const HeroHome = props => {
    const { webPage } = props;
    const showCallOut = !webPage || webPage.heroTitle || webPage.heroSubtitle ? true : false;
    const company = useSelector(state => state.company.company);

    const image = webPage && webPage.heroImage && webPage.heroImage != null ? formatImage(webPage.heroImage.url, 3000, '.webp') 
                : company.isRABSite ? defaultRABImage : defaultImage;

    return (
        <React.Fragment>
            <HeroBox>
                <HeroImage style={{ backgroundImage: "url(" + image + ")", backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: "cover" }}></HeroImage>
                <FullContainer>
                    <Grid container>
                        <Grid item xs={false} md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            {showCallOut &&
                                <React.Fragment>
                                    <Typography variant="h1" component="h1" align="center" style={{ margin: 0, textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>
                                        {webPage ? webPage.heroTitle : defaultTitle}
                                    </Typography>
                                    <Typography variant="caption" component="p" align="center" mt={5} mb={0} style={{ margin: '17px 0 0 0', textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>
                                        {webPage ? webPage.heroSubtitle : defaultSubtitle}
                                    </Typography>
                                    {/* <Button variant="contained" color="primary" align="center" style={{ margin: '20px 0 0 0' }}>Call to Action</Button> */}
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </FullContainer>
            </HeroBox>
        </React.Fragment >
    );
}

export default HeroHome;