import React, { useState } from 'react'
import i18n from 'i18n-js';
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Card,
    CardContent,
    Divider,
    List,
    Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as ExpandIcon,
    IoRemoveCircleOutline as CollapseIcon

} from "react-icons/io5";
import PurchaseGroupMonth from './PurchaseGroupMonth';

// UI Consts
const Typography = styled(MuiTypography)(spacing);

const PurchaseGroupYear = props => {
    const theme = useTheme();
    const { group } = props;
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <React.Fragment>
            <Divider />
            <Box display="flex"
                flexDirection="row"
                flex={1}
                justifyContent="space-between"
                style={{ padding: '12px 16px 14px 16px', cursor: "pointer" }}
                onClick={() => setIsExpanded(prevValue => !prevValue)}
            >
                <Typography variant="h5" style={{marginTop: '6px'}}>{group.heading}</Typography>
                {isExpanded && <CollapseIcon size={24} style={{color: theme.palette.iconColor.color}} />}
                {!isExpanded && <ExpandIcon size={24} style={{color: theme.palette.iconColor.color}}/>}
            </Box>
            {isExpanded &&
                <Card style={{border: '1px solid ' + theme.palette.divider, borderRadius: '10px'}}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" style={{borderBottom: '1px solid ' + theme.palette.divider, padding: '15px 14px 17px 14px'}}>
                        <Typography variant="h3">{group.year} Total {group.heading === 'THIS YEAR' ? 'YTD' : ''}</Typography>
                        <Typography variant="h3">{i18n.toCurrency(group.grandTotal, { precision: 2, unit: '$' })}</Typography>
                    </Box>
                    <CardContent style={{padding: '0 14px 0 14px'}}>
                        <List style={{padding: 0}}>
                            {group.monthData.map((monthGroup, index) => {
                                return (
                                    <PurchaseGroupMonth group={monthGroup} key={monthGroup.key} />
                                )
                            })}
                        </List>
                    </CardContent>
                </Card>
            }
        </React.Fragment>
    )
}

export default PurchaseGroupYear;