import Activity from "../../models/Activity";

import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';
import { fetchActivities } from "./activityActions";

export const getSaSAddToken = (companyId, appUserId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/getsasaddtoken/' + companyId + "/" + appUserId, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const getFilesForAppUser = (companyId, appUserId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/profiles/getfilesforappuser/' + companyId + "/" + appUserId, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const getSaSReadToken = (companyId, appUserId, blobName) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/profiles/getsasreadtoken/' + companyId + "/" + appUserId + "?blobName=" + encodeURIComponent(blobName), {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const deleteFile = (companyId, appUserId, blobName) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/profiles/deletefile/' + companyId + "/" + appUserId + "?blobName=" + encodeURIComponent(blobName), {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const filedAddedNotification = (companyId, appUserId, files) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/profiles/fileaddednotification/' + companyId + "/" + appUserId, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify({
                files: files
            })
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};



/*********************************************************** activity file actions  ***********************/

export const setFileLocationForActivity = (companyId, activityId, values, startDate, endDate) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/setfilelocation/' + companyId + "/" + activityId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        const activityToAdd = new Activity(resData.id, null);
        activityToAdd.update(resData);

        if (startDate && endDate)
            dispatch(fetchActivities(companyId, startDate, endDate))
    };
};

export const getSaSAddTokenActivity = (companyId, activityId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/getsasaddtoken/' + companyId + "/" + activityId, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const getFilesForActivity = (companyId, activityId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/activities/GetFilesForActivity/' + companyId + "/" + activityId, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const getSaSReadTokenForActivity = (companyId, activityId, blobName) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/activities/getsasreadtoken/' + companyId + "/" + activityId + "?blobName=" + encodeURIComponent(blobName), {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const deleteFileActivity = (companyId, activityId, blobName) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/activities/deletefile/' + companyId + "/" + activityId + "?blobName=" + encodeURIComponent(blobName), {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};

export const filedAddedToActivityNotification = (companyId, activityId, occuranceId, files) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;

        response = await fetch(NetworkConstants.SERVER + '/api/activities/fileaddedtoactivitynotification/' + companyId + "/" + activityId, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify({
                occuranceId: occuranceId,
                files: files
            })
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        return resData;
    };
};
