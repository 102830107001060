import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import styled, { withTheme } from "styled-components/macro";
import { withStyles, useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
  Box,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  Hidden,
  IconButton as MuiIconButton,
  Menu,
  MenuItem as MuiMenuItem,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoVideocamOutline as VideoIcon,
  IoEllipsisHorizontal as Ellipsis,
  IoRefreshCircleOutline as RestoreIcon,
  IoAlertCircleSharp as CancelIcon,
  IoTime as ClockIcon,
  IoCheckmarkCircle as CheckIcon,
  IoPeople as FullIcon,
  IoPersonCircleOutline as PersonIcon,
  IoAlarmOutline as FinishedIcon,
  IoLocationOutline as LocationIcon,
  IoPencilOutline as PencilIcon,
  IoChatboxEllipses as ChatIcon,
  IoCloseCircleOutline as CancelMenuIcon,
  IoDuplicateOutline as CopyIcon,
  IoCheckmarkCircleOutline as ConfirmIcon,
  IoClose as RejectIcon,
  IoMailOutline as ResendConfirmationIcon,
  IoTrash as DeleteIcon,

} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';

import { checkClaim, getMilliseconds } from '../../../helpers/helperFunctions';
import { enrollInActivity, cancelActivity, confirmActivity, resendConfirmation, deleteActivity } from '../../../store/actions/activityActions';
import ActionButton from '../../framework/ActionButton';
import ActivityAddEditModal from './ActivityAddEditModal';
import ActivityDetailModal from './ActivityDetailModal';
import ActivityJoinModal from './ActivityJoinModal';
import DeclineDialog from './DeclineDialog';
import MessageModal from '../messages/MessageModal';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ActivityCardWrapper = styled(Box)`
  padding: 0 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 15px 0 15px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 0 15px 0 0;
  }
`;

const ActivityCardContent = styled(Box)`
  padding: 10px 0;
  align-items: flex-start;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 15px 0 13px 0;
  }
`;

const ActivityTitle = styled(Typography)`
  margin: 0 0 3px 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 0 3px 0;
  }
`;

const IconButton = styled(MuiIconButton)`
    padding: 0;

    &:hover {
        background-color: transparent;
        
        svg {
            fill: ${(props) => props.theme.palette.primary.main};
        }
    }
`;

const MenuItem = styled(MuiMenuItem)`
  margin: 0;
  padding: 8px 15px 9px 15px;
  font-size: 1.4rem;
`;


const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: '10px',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
  },
  list: {
    padding: '8px 0 7px 0',
    left: '0',
  }
}))(((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
)));




const stupidFunction = (activity, loggedInUser) => {
  if (!activity || !loggedInUser || !loggedInUser.childProfiles)
    return false;

  return activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity);
}

const MESSAGE_ICON_SIZE = 16;
const ICON_SIZE = 24;

const ActivityCard = props => {
  const { activity, viewStartDate, viewEndDate, showDate, hideDurration, dontAllowShowDetails, displayedUser } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));


  const company = useSelector(state => state.company.company);
  const location = useSelector(state => state.company.locations).find(x => x.id === activity.locationId)

  const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
  const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
  const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [editAllInstances, setEditAllInstances] = useState(false);

  let loggedInUser = useSelector(state => state.auth.person);
  if (displayedUser)
    loggedInUser = displayedUser;

  const userClaims = useSelector(state => state.auth.claims);
  const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);
  // const seeAllPeople = checkClaim(userClaims, SecurityConstants.VIEW_ALL_PEOPLE);
  const companyId = useSelector(state => state.company.companyId);


  const activityCanceled = !activity ? false :
    activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
    || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

  const durationInMilliSeconds = getMilliseconds(activity.duration, 'minutes');
  const occuranceIsInPast = Date.now() >= activity.displaybleStartDateTime.getTime() + durationInMilliSeconds;

  const instructors = activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity && activity.isInActivityInstance(a, activity));
  const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
  const isRunningActivity = loggedInUser ? peopleRunningActivity.some(x => x.id === loggedInUser.id) : false;

  const attendees = activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
  const peopleSignedUpforActivity = useSelector(state => state.company.people).filter(p => attendees.find(a => a.appUserId === p.id));

  const whenCanDrop = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfDropStartValue, activity.selfDropStartUnit));
  const whenCanRegister = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterStartValue, activity.selfRegisterStartUnit));
  const whenRegistrationCloses = !activity || activity.selfRegisterEndValue === null ? null : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterEndValue, activity.selfRegisterEndUnit));

  const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
  const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInActivityInstance(haveAttendeeObject, activity) : false;
  const aFamilySignedUp = stupidFunction(activity, loggedInUser);

  const isRecurring = activity.rrule !== null && activity.rrule.trim().length > 0 ? true : false;

  const [isJoining, setIsJoining] = useState(false);
  const [isMenuActionRunning, setIsMenuActionRunning] = useState(false);
  const [isCreateCopy, setIsCreateCopy] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [error, setError] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [activityJoinModalOpen, setActivityJoinModalOpen] = useState(false);

  /******************************************* events *******************************/
  const dispatch = useDispatch();

  const joinHandler = useCallback(async (id, whenCanDropLocal, loggedInUserLocal, company) => {
    setError(null);
    try {
      if (new Date() > whenCanDropLocal && !company.hideDroppedActivityMessage) {
        const r = window.confirm(company.dropActivityWarningMessage
          ? company.dropActivityWarningMessage :
          'Be advised, the time when this ' + ACTIVITY_TEXT.toLowerCase() + ' can be dropped has passed. Do you still want to register?');
        if (!r) {
          return;
        }
      }
      setIsJoining(true);
      await dispatch(enrollInActivity(companyId, id, loggedInUserLocal.id, new Date(viewStartDate), new Date(viewEndDate), true, false));

    } catch (err) {
      setError(err.message);
    }
    setIsJoining(false);
  }, [dispatch, companyId, viewStartDate, viewEndDate, ACTIVITY_TEXT]);

  const cancelHandler = useCallback(async (id, status) => {
    if (status === 'canceled') {
      const r = window.confirm("Are you sure you want to cancel this " + ACTIVITY_TEXT + "?");
      if (!r) {
        return;
      }
    }
    try {
      setError(null)
      setIsMenuActionRunning(true);
      await dispatch(cancelActivity(companyId, id, status, viewStartDate, viewEndDate));
    } catch (err) {
      setError(err.message);
    }
    setIsMenuActionRunning(false);
  }, [dispatch, companyId, ACTIVITY_TEXT, viewStartDate, viewEndDate])

  const confirmActivityCallback = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
    try {
      setError(null);
      setIsMenuActionRunning(true);
      await dispatch(confirmActivity(activityId, companyId, viewStartDate, viewEndDate, true));
    } catch (err) {
      setError(err.message);
    }
    setIsMenuActionRunning(false);
  }, [dispatch]);

  const handleConfirm = () => {
    confirmActivityCallback(activity.id, company.id, viewStartDate, viewEndDate);
  }

  const resendConfirmationHandler = useCallback(async (id, companyId,) => {
    try {
      setError(null)
      setIsMenuActionRunning(true);

      await dispatch(resendConfirmation(id, companyId));
    } catch (err) {
      setError(err.message);
    }
    setIsMenuActionRunning(false);
  }, [dispatch])

  const handleJoin = () => {
    if (((loggedInUser.needPaymentMethodForActivity(activity, company) || loggedInUser.requirePrepayment(activity, company)) && !allowAddEdit)
      || (loggedInUser.childProfiles && loggedInUser.childProfiles.length > 0))
      setActivityJoinModalOpen(true);
    else if (!currentUserInClass)
      joinHandler(activity.id, whenCanDrop, loggedInUser, company)
  }

  const deleteActivityHandler = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
    try {
      const additionalMessage = '\n\nEnrolled ' + CUSTOMERS_TEXT.toLowerCase() + ' are not notified when you delete a ' + ACTIVITY_TEXT.toLowerCase() + '.  To notify them cancel the ' + ACTIVITY_TEXT.toLowerCase() + ' first.'
      const r = window.confirm("Are you sure you want to delete this " + ACTIVITY_TEXT + "?" + additionalMessage);
      if (!r) {
        return;
      }
      setIsMenuActionRunning(true);
      setError(null);
      const activityIdToSend = activityId.indexOf('_') >= 0 ? activityId.split('_')[0] : activityId;
      const occuranceId = activityId.indexOf('_') >= 0 ? activityId.split('_')[1] : 0;
      await dispatch(deleteActivity(activityIdToSend, occuranceId, companyId, viewStartDate, viewEndDate));
    } catch (err) {
      setError(err.message);
    }
    setIsMenuActionRunning(false);
  }, [dispatch, ACTIVITY_TEXT, CUSTOMERS_TEXT]);

  /******************************************* Add or drop *******************************/


  const confirmStatus = <React.Fragment>
    {activity.requestConfirmation &&
      <React.Fragment>
        {!activity.confirmed && !activity.rejected && <Typography ml={isDesktop ? 1 : 2} variant='body2' component="p" style={{ color: theme.palette.warning.main, marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>{currentUserInClass ? 'Please confirm' : 'Confirmation Pending'}</Typography>}
        {activity.confirmed && <Typography ml={isDesktop ? 1 : 2} variant='body2' component="p" style={{ color: theme.palette.success.main, marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Confirmed</Typography>}
        {activity.rejected && <Typography ml={isDesktop ? 1 : 2} variant='body2' component="p" style={{ color: theme.palette.error.main, marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Declined</Typography>}
      </React.Fragment>
    }
  </React.Fragment>

  const status = <React.Fragment>
    <Box display="flex" flexDirection={isDesktop || isMobile ? 'column' : 'row'}>
      {activityCanceled &&
        <Box display="flex" mr={2} alignItems='center'>
          <CancelIcon size={MESSAGE_ICON_SIZE} color={theme.palette.error.main} />
          <Typography ml={1} variant='body2' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Canceled</Typography>
        </Box>
      }
      {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && activity.allowSelfRegister && !occuranceIsInPast && whenCanRegister > Date.now() &&
        <Box display="flex" mr={2} alignItems='center'>
          <ClockIcon size={MESSAGE_ICON_SIZE} color={theme.palette.text.secondary} />
          <Box display="flex" flexDirection={isDesktop || isMobile ? 'column' : 'row'} alignItems='center'>
            <Typography ml={1} color="textSecondary" variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration opens</Typography>
            <Typography ml={1} color="textSecondary" variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.6rem' }}>{moment(whenCanRegister).format('MMM. DD, LT')}</Typography>
          </Box>
        </Box>
      }
      {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && !activity.canSelfRegister(activity, loggedInUser, attendees) && !currentUserInClass && activity.allowSelfRegister && Date.now() >= whenCanRegister && !occuranceIsInPast &&
        <Box display="flex" mr={2} alignItems='center'>
          <ClockIcon size={MESSAGE_ICON_SIZE} color={theme.palette.warning.main} />
          <Box>
            {(!whenRegistrationCloses || whenRegistrationCloses > Date.now()) &&
              <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration open</Typography>
            }

            {(whenRegistrationCloses && whenRegistrationCloses <= Date.now()) &&
              <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration Closed</Typography>
            }
          </Box>
        </Box>
      }
      {!activityCanceled && activity.canSelfRegister(activity, loggedInUser, attendees) && !currentUserInClass && !isRunningActivity && !aFamilySignedUp &&
        <Box display="flex" alignItems="start">
          <Hidden lgUp >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mr={2} alignItems={isMobile ? 'flex-start' : 'center'} >
              <Box display="flex" flexDirection='row' alignItems={isMobile ? 'flex-start' : 'center'}>
                <ClockIcon size={MESSAGE_ICON_SIZE} color={theme.palette.warning.main} />
                {(whenRegistrationCloses || whenRegistrationCloses < Date.now()) && <Typography ml={1} mr={isMobile ? 0 : 1.5} variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration open</Typography>}
                {(!whenRegistrationCloses && whenRegistrationCloses > Date.now()) && <Typography ml={1} mr={isMobile ? 0 : 1.5} variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration Closed</Typography>}
              </Box>
              <Box display="flex" flexDirection='row' alignItems={isMobile ? 'flex-start' : 'center'}>
                <FullIcon size={MESSAGE_ICON_SIZE} color={theme.palette.messageButton.main} />
                <Typography color="textSecondary" variant='subtitle1' component="p" ml={1} stye={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.6rem' }}>{attendees.length}/{activity.totalSpaces} registered</Typography>
              </Box>
            </Box>
          </Hidden>
          {(!whenRegistrationCloses || whenRegistrationCloses > Date.now()) &&
            <Hidden mdDown>
              <ActionButton
                fullWidth
                variant="contained"
                onClick={handleJoin}
                submitting={isJoining}
                style={{ backgroundColor: theme.palette.primary.main, color: 'white', borderRadius: '10px', padding: '13px 15px 15px 15px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.2rem' }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography component="span"
                    style={{ fontWeight: "700", fontSize: "1.2rem", lineHeight: "1.2rem" }}
                    marginBottom={1}
                  >Register Now!
                  </Typography>
                  <Typography component="span"
                    style={{ fontWeight: "700", fontSize: "1.2rem", lineHeight: "1.2rem" }}
                    mb={0}
                  >
                    {attendees.length}/{activity.totalSpaces} registered
                  </Typography>
                </Box>
              </ActionButton>
            </Hidden>
          }
          {(whenRegistrationCloses && whenRegistrationCloses < Date.now()) &&
            <Hidden mdDown>
              <Box display="flex" flexDirection='column' mr={2} >
                <Box display="flex" flexDirection='row'>
                  <ClockIcon size={MESSAGE_ICON_SIZE} color={theme.palette.warning.main} />
                  <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.6rem' }}>Registration Closed</Typography>
                </Box>
                <Box display="flex" flexDirection='row'>
                  <FullIcon size={MESSAGE_ICON_SIZE} color={theme.palette.messageButton.main} />
                  <Typography ml={1} color="textSecondary" variant='subtitle1' component="p" stye={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.6rem' }}>{attendees.length}/{activity.totalSpaces} registered</Typography>
                </Box>
              </Box>
            </Hidden>
          }
        </Box>

      }
      {!activityCanceled && (currentUserInClass || aFamilySignedUp) && !isRunningActivity && !occuranceIsInPast &&
        <Box display="flex" alignItems="center" mr={2}>
          {!activity.meetingUrl &&
            <Box display="flex" flexDirection="row" alignItems='center'>
              <CheckIcon size={MESSAGE_ICON_SIZE} color={activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main} />
              <Box display="flex" flexDirection={isDesktop ? 'column' : 'row'} alignItems='center'>
                <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                  Signed up!
                </Typography>
              </Box>
            </Box>
          }
          {activity.meetingUrl &&
            <Box display="flex" flexDirection={isDesktop ? 'column' : 'row'} alignItems="center">
              <Button
                fullWidth
                variant="outlined"
                color='primary'
                startIcon={<VideoIcon />}
                // style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                componet='a'
                href={activity.meetingUrl}
                target='_blank'
                style={{ backgroundColor: 'transparent', color: theme.palette.primary.main, border: '1px solid ' + theme.palette.primary.main, borderRadius: '10px', padding: '9px 15px 9px 15px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.2rem' }}
              >
                Launch {ACTIVITY_TEXT}
              </Button>
            </Box>
          }
        </Box>
      }
      {(activity.totalSpaces - attendees.length) <= 0 && activity.allowSelfRegister && !currentUserInClass && !isRunningActivity && !occuranceIsInPast && !activityCanceled &&
        <Box display="flex" mr={2} alignItems='center'>
          <FullIcon size={MESSAGE_ICON_SIZE} color={theme.palette.messageButton.main} />
          <Box display="flex" flexDirection={isDesktop ? 'column' : 'row'} alignItems="baseline">
            <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>{ACTIVITY_TEXT} full</Typography>
          </Box>
        </Box>
      }
      {!activityCanceled && occuranceIsInPast &&
        <Box display="flex" mr={2} alignItems='center'>
          <FinishedIcon size={MESSAGE_ICON_SIZE} color={theme.palette.messageButton.main} />
          <Box display="flex" flexDirection={isDesktop ? 'column' : 'row'} alignItems="baseline">
            <Typography ml={1} color="textSecondary" variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>{ACTIVITY_TEXT} finished</Typography>
          </Box>
        </Box>
      }
      {((!activity.allowSelfRegister || !activity.canSelfRegister(activity, loggedInUser, attendees)) || (activity.allowSelfRegister && whenCanRegister > Date.now() && allowAddEdit) || activityCanceled) &&
        <Box display="flex" alignItems='center'>
          <FullIcon size={MESSAGE_ICON_SIZE} color={theme.palette.messageButton.main} />
          <Box display="flex" flexDirection={isDesktop ? 'column' : 'row'} alignItems="baseline">
            <Typography color="textSecondary" ml={1} variant='subtitle1' component="p" style={{ marginTop: '2px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>{attendees.length} {activity.allowSelfRegister ? "/ " + activity.totalSpaces + ' registered' : attendees.length === 1 ? CUSTOMER_TEXT + " in " + ACTIVITY_TEXT : CUSTOMERS_TEXT + " in " + ACTIVITY_TEXT}</Typography>
          </Box>
        </Box>
      }

      {confirmStatus &&
        <Box display="flex" alignItems="center" ml={4}>
          {confirmStatus}
        </Box>
      }
    </Box>
  </React.Fragment >

  return (
    <ActivityCardWrapper>
      <ActivityCardContent display="flex">
        <Box width={80} mr={4} onClick={() => setShowDetails(previousValue => !previousValue)} style={{ cursor: "pointer" }}>
          {showDate &&
            <Typography color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '1px 0 3px 0', textDecoration: (activityCanceled ? 'line-through' : 'none'), fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
              {moment(activity.displaybleStartDateTime).format("MMM D, YYYY")}
            </Typography>
          }
          <Typography color={"textPrimary"} style={{ color: activityCanceled ? theme.palette.text.secondary : (showDate ? theme.palette.text.secondary : theme.palette.text.primary), margin: '1px 0 3px 0', textDecoration: (activityCanceled ? 'line-through' : 'none'), fontWeight: showDate ? '400' : '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
            {moment(activity.displaybleStartDateTime).format("h:mm a")}
          </Typography>
          {!hideDurration &&
            <Typography variant="subtitle1" color="textSecondary" style={{ textDecoration: (activityCanceled ? 'line-through' : 'none'), fontSize: '1.2rem', lineHeight: '1.5rem' }}>
              {activity.duration} min
            </Typography>
          }
        </Box>
        <Box flex={1} display="flex" flexDirection="column" mr={4} onClick={() => dontAllowShowDetails ? false : setShowDetails(previousValue => !previousValue)} style={{ cursor: dontAllowShowDetails ? "cursor" : "pointer" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <ActivityTitle component="p" variant="h4" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.8rem' }}>{activity.title}</ActivityTitle>
            {activity.calendarColor && activity.calendarColor !== theme.palette.primary.main &&
              <Box style={{ backgroundColor: activity.calendarColor ? activity.calendarColor : theme.palette.primary.main, borderRadius: 50, width: 12, height: 12, marginLeft: 5, marginBottom: 2 }}></Box>
            }
          </Box>
          <Box flex={1} display="flex" flexDirection="row">
            {location &&
              <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
                <LocationIcon size={16} color={theme.palette.text.secondary} />
                <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                  {location.name}{activity.activityResources ? ' - ' + activity.activityResources : ''}
                </Typography>
              </Box>
            }
            {!location && activity.manualLocation && activity.manualLocation.trim().length > 0 &&
              <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
                <LocationIcon size={16} color={theme.palette.text.secondary} />
                <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                  {activity.manualLocation}{activity.activityResources ? ' - ' + activity.activityResources : ''}
                </Typography>
              </Box>
            }
            {peopleRunningActivity.length > 0 &&
              <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
                <PersonIcon size={16} color={theme.palette.text.secondary} />
                <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 0 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                  {peopleRunningActivity[0].displayName}{peopleRunningActivity.length > 1 ? '(+' + (peopleRunningActivity.length - 1) + ')' : ''}
                </Typography>
              </Box>
            }
          </Box>
          <Hidden lgUp>
            {status}
          </Hidden>
        </Box>
        <Hidden mdDown>
          <Box width={150} mr={4}>
            {status}
          </Box>
        </Hidden>
        {(allowAddEdit || isRunningActivity || (activity.requestConfirmation && !occuranceIsInPast && (!(activity.confirmed || activity.rejected) || allowAddEdit))) &&
          <Hidden xsDown>
            <Box display="flex" justifyContent="center" alignItems="center" width={24}>
              {!isMenuActionRunning &&
                <React.Fragment>
                  <IconButton onClick={event => setAnchorMenu(event.currentTarget)}>
                    <Ellipsis color={theme.palette.iconColor.color} size="24" />
                  </IconButton>
                  <StyledMenu
                    id="menu-appbar"
                    anchorEl={anchorMenu}
                    open={Boolean(anchorMenu)}
                    onClose={() => setAnchorMenu(null)}
                    onClick={() => setAnchorMenu(null)}
                  >
                    <Box display="flex" flexDirection="column">
                      {!activityCanceled && !occuranceIsInPast && activity.meetingUrl &&
                        <MenuItem
                          component='a'
                          href={activity.meetingUrl}
                          target='_blank'
                        >
                          <VideoIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                          Start Video
                        </MenuItem>
                      }
                      {!activityCanceled && !occuranceIsInPast && allowAddEdit &&
                        <MenuItem
                          onClick={() => { setEditAllInstances(true); setIsCreateCopy(false); setIsEditingModalOpen(true); }}>
                          <PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                          Edit {isRecurring ? 'Series' : ACTIVITY_TEXT}
                        </MenuItem>
                      }
                      {!activityCanceled && !occuranceIsInPast && isRecurring && allowAddEdit &&
                        <MenuItem
                          onClick={() => { setEditAllInstances(false); setIsCreateCopy(false); setIsEditingModalOpen(true) }}>
                          <PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                          Edit this {ACTIVITY_TEXT}
                        </MenuItem>
                      }
                      {allowAddEdit && <MenuItem
                        onClick={() => setMessageModalOpen(true)}>
                        <ChatIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                        Message
                      </MenuItem>}
                      {!occuranceIsInPast && allowAddEdit &&
                        <MenuItem
                          onClick={() => cancelHandler(activity.id, activityCanceled ? 'active' : 'canceled')}
                        >
                          {activityCanceled
                            ? <RestoreIcon
                              size={ICON_SIZE}
                              style={{ color: theme.palette.success.main, marginRight: '10px' }} />
                            :
                            <CancelMenuIcon
                              size={ICON_SIZE}
                              style={{ color: theme.palette.error.main, marginRight: '10px' }}

                            />}
                          {activityCanceled ? 'Reschedule' : 'Cancel'} {ACTIVITY_TEXT}
                        </MenuItem>}
                      {allowAddEdit && <MenuItem
                        onClick={() => { setEditAllInstances(false); setIsCreateCopy(true); setIsEditingModalOpen(true); }}>
                        <CopyIcon
                          size={ICON_SIZE}
                          style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                        Copy {isRecurring ? 'Series' : ACTIVITY_TEXT}
                      </MenuItem>}
                      {activity.requestConfirmation && !occuranceIsInPast && !activity.confirmed && !activity.rejected && allowAddEdit && <MenuItem
                        onClick={() => resendConfirmationHandler(activity.id, company.id)}>
                        <ResendConfirmationIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '10px' }} />
                        Resend Confirmation
                      </MenuItem>
                      }
                      {activity.requestConfirmation && !occuranceIsInPast && !activity.confirmed && <MenuItem
                        onClick={handleConfirm}>
                        <ConfirmIcon size={ICON_SIZE} style={{ color: theme.palette.success.main, marginRight: '10px' }} />
                        Confirm
                      </MenuItem>
                      }
                      {activity.requestConfirmation && !occuranceIsInPast && !activity.rejected && (!activity.confirm || allowAddEdit) && <MenuItem
                        onClick={() => setDeclineDialogOpen(true)}>
                        <RejectIcon size={ICON_SIZE} style={{ color: theme.palette.error.main, marginRight: '10px' }} />
                        Decline
                      </MenuItem>
                      }
                      {allowAddEdit && !occuranceIsInPast &&
                        <MenuItem
                          onClick={() => deleteActivityHandler(activity.id, company.id, viewStartDate, viewEndDate)}>
                          <DeleteIcon size={ICON_SIZE} style={{ color: theme.palette.error.main, marginRight: '10px' }} />
                          Delete {ACTIVITY_TEXT}
                        </MenuItem>
                      }
                    </Box>
                  </StyledMenu>
                </React.Fragment>
              }
              {isMenuActionRunning &&
                <CircularProgress color="primary" size={24} />
              }
            </Box>
          </Hidden>
        }
      </ActivityCardContent>
      {error && <Alert severity="error">{error}</Alert>}
      {/* </Box> */}
      {
        showDetails &&
        <ActivityDetailModal
          open={showDetails}
          setOpen={setShowDetails}
          activityId={activity.id}
          companyId={companyId}
          viewStartDate={viewStartDate}
          viewEndDate={viewEndDate}
        />
      }
      {
        isEditingModalOpen
        && <ActivityAddEditModal
          open={isEditingModalOpen}
          editMode
          setOpen={setIsEditingModalOpen}
          activity={activity}
          editAllInstances={editAllInstances}
          createCopy={isCreateCopy}
          companyId={companyId}
          viewStartDate={props.viewStartDate}
          viewEndDate={props.viewEndDate}
        ></ActivityAddEditModal>
      }
      {
        activityJoinModalOpen &&
        <ActivityJoinModal
          activity={activity}
          companyId={companyId}
          // userToJoin={loggedInUser}
          open={activityJoinModalOpen}
          setOpen={setActivityJoinModalOpen}
          viewStartDate={viewStartDate}
          viewEndDate={viewEndDate} />
      }
      {
        declineDialogOpen &&
        <DeclineDialog
          activity={activity}
          open={declineDialogOpen}
          setOpen={setDeclineDialogOpen}
          viewStartDate={viewStartDate}
          viewEndDate={viewEndDate}
        />

      }
      {
        messageModalOpen &&
        <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} sendInAppInitialState toUsers={peopleSignedUpforActivity} />
      }
    </ActivityCardWrapper >
  );
}

export default withTheme(ActivityCard);