export const webOverrides = theme => {
  return ({
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitTextSizeAdjust: "100%",
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          fontSize: "62.5%",
        }
      }
    },
    MuiCard: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      },
    },
    MuiCardHeader: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
    MuiFab: {
      root: {
        position: 'fixed',
        bottom: '4rem',
        right: '4rem',
        zIndex: 2,
      }
    },
    MuiPickersDay: {
      day: {
        fontWeight: "300",
      },
    },
    MuiPickersYear: {
      root: {
        height: "64px",
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
    MuiPickersClock: {
      container: {
        margin: `32px 0 4px`,
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
    MuiPickerDTHeader: {
      dateHeader: {
        "& h4": {
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontWeight: 400,
        },
      },
    },
    MuiPickersTimePicker: {
      hourMinuteLabel: {
        "& h2": {
          fontWeight: 300,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        "& h4": {
          fontWeight: 400,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: "6px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
        padding: '11px 31px 11px 31px',
      },
      sizeSmall: {
        padding: '10px 20px 9px 20px',
        fontSize: '12px',
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '10px',
      }
    },
    MuiTextField: {
      root: {
        margin: '0 0 10px 0',
        "& label": {
          color: '' + theme.palette.text.primary + ' !important',
          opacity: 0.7,
          top: '5px',
          transform: 'translate(15px, 15px)',
          zIndex: '3',
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          opacity: 0.5,
          transform: 'translate(15px, 5px) scale(0.85)',
        },
        "& .MuiOutlinedInput-root": {
          border: '1px solid ' + theme.palette.divider + ' !important',
          borderRadius: '10px',
          margin: '0',
          padding: '0 15px !important',
          "& input": {
            boxShadow: '0 0 0px 1000px ' + theme.palette.background.paper + ' inset',
            padding: '27.5px 0 11.5px 0',
            zIndex: '2',
          },
          "& textarea": {
            boxShadow: '0 0 0px 1000px ' + theme.palette.background.paper + ' inset',
            margin: '27.5px 0 9.5px 0',
            zIndex: '2',
          },
        },
        "& fieldset": {
          backgroundColor: theme.palette.background.paper,
          border: 'none !important',
          top: '0',
          zIndex: '1',
          "& legend": {
            display: 'none !important',
          },
        },
        "& .MuiInputAdornment-positionStart p": {
          marginTop: '18px',
          zIndex: '2',
        },
        "& .MuiInputAdornment-positionEnd": {
          zIndex: '2',
        },
        "& .MuiInputAdornment-positionEnd p": {
          marginTop: '18px',
          zIndex: '2',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: theme.palette.error.main,
        margin: '4px 14px 0 14px !important',
        fontSize: '1.4rem !important',
        lineHeight: '1.8rem !important',
      }
    },
    MuiSwitch: {
      root: {
        width: 51,
        height: 31,
        borderRadius: '999999px',
        margin: '0 10px 0 0 !important',
        padding: 0,
        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      switchBase: {
        backgroundColor: 'transparent !important',
        borderRadius: 0,
        padding: 2,
      },
      thumb: {
        width: 27,
        height: 27,
        backgroundColor: theme.palette.background.paper,
        border: '0.5px solid rgba(0,0,0,0.04)',
        boxShadow: '0 3px 8px 0 rgba(0,0,0,0.15), 0 3px 1px 0 rgba(0,0,0,0.06)',
      },
      track: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.divider,
        borderRadius: '999999px',
        opacity: '1 !important',
      },
      // label: {
      //   color: 'red !important',
      // },
    },
  })
};

export const appOverrides = theme => {
  return (
    {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitTextSizeAdjust: "100%",
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            fontSize: "62.5%",
          }
        }
      },
      MuiCard: {
        root: {
          borderRadius: "6px",
          boxShadow:
            "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        },
      },
      MuiCardHeader: {
        action: {
          marginTop: "-4px",
          marginRight: "-4px",
        },
      },
      MuiFab: {
        root: {
          position: 'fixed',
          bottom: '4rem',
          right: '4rem',
          zIndex: 2,
        }
      },
      MuiPickersDay: {
        day: {
          fontWeight: "300",
        },
      },
      MuiPickersYear: {
        root: {
          height: "64px",
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          marginTop: "6px",
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: "transparent",
          "& > *": {
            backgroundColor: "transparent",
          },
        },
        switchHeader: {
          marginTop: "2px",
          marginBottom: "4px",
        },
      },
      MuiPickersClock: {
        container: {
          margin: `32px 0 4px`,
        },
      },
      MuiPickersClockNumber: {
        clockNumber: {
          left: `calc(50% - 16px)`,
          width: "32px",
          height: "32px",
        },
      },
      MuiPickerDTHeader: {
        dateHeader: {
          "& h4": {
            fontWeight: 400,
          },
        },
        timeHeader: {
          "& h3": {
            fontWeight: 400,
          },
        },
      },
      MuiPickersTimePicker: {
        hourMinuteLabel: {
          "& h2": {
            fontWeight: 300,
          },
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          "& h4": {
            fontWeight: 400,
          },
        },
      },
      MuiChip: {
        root: {
          borderRadius: "6px",
        },
      },
      MuiButton: {
        root: {
          borderRadius: '10px',
          padding: '8px 25px 9px 25px',
        },
        startIcon: {
          marginRight: '6px',
        }
      },
      MuiDialog: {
        paper: {
          borderRadius: '10px',
        }
      },
      MuiTextField: {
        root: {
          margin: '0 0 10px 0',
          "& label": {
            color: '' + theme.palette.text.primary + ' !important',
            opacity: 0.7,
            top: '5px',
            transform: 'translate(15px, 15px)',
            zIndex: '3',
          },
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            opacity: 0.5,
            transform: 'translate(15px, 5px) scale(0.85)',
          },
          "& .MuiOutlinedInput-root": {
            border: '1px solid ' + theme.palette.divider + ' !important',
            borderRadius: '10px',
            margin: '0',
            padding: '0 15px !important',
            "& input": {
              boxShadow: '0 0 0px 1000px ' + theme.palette.background.paper + ' inset',
              padding: '27.5px 0 11.5px 0',
              zIndex: '2',
            },
            "& textarea": {
              boxShadow: '0 0 0px 1000px ' + theme.palette.background.paper + ' inset',
              margin: '27.5px 0 9.5px 0',
              zIndex: '2',
            },
          },
          "& fieldset": {
            backgroundColor: theme.palette.background.paper,
            border: 'none !important',
            top: '0',
            zIndex: '1',
            "& legend": {
              display: 'none !important',
            },
          },
          "& .MuiInputAdornment-positionStart p": {
            marginTop: '18px',
            zIndex: '2',
          },
          "& .MuiInputAdornment-positionEnd": {
            zIndex: '2',
          },
          "& .MuiInputAdornment-positionEnd p": {
            marginTop: '18px',
            zIndex: '2',
          },
        },
      },
      MuiSelect: {
        // margin: '0 0 20px 0',
        root: {
          backgroundColor: 'transparent !important',
          marginRight: '15px'
        },
        select: {
          padding: '27.5px 15px 10.5px 15px',
        },
        // selectBorder: {
        //   '& .MuiOutlinedInput-notchedOutline': {
        //     borderColor: 'red',
        //   },
        // },
        nativeInput: {
          margin: '0',
          padding: '22.5px 15px 10.5px 15px',
        },
        icon: {
          width: '20px',
          color: theme.palette.text.primary,
          fill: theme.palette.text.primary,
          marginTop: '5px',
          // marginRight: '6px',
          // marginLeft: '8px',
          zIndex: 1,
        },
        // "& fieldset": {
        //   backgroundColor: theme.palette.background.paper,
        // },
      },
      // MuiSwitch: {
      //   root: {
      //     "& .MuiSwitch-switchBase": {
      //       color: theme.palette.divider
      //     }
      //   }
      // },
      MuiSwitch: {
        root: {
          width: 51,
          height: 31,
          borderRadius: '999999px',
          margin: '0 10px 0 0 !important',
          padding: 0,
          "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        switchBase: {
          backgroundColor: 'transparent !important',
          borderRadius: 0,
          padding: 2,
        },
        thumb: {
          width: 27,
          height: 27,
          backgroundColor: theme.palette.background.paper,
          border: '0.5px solid rgba(0,0,0,0.04)',
          boxShadow: '0 3px 8px 0 rgba(0,0,0,0.15), 0 3px 1px 0 rgba(0,0,0,0.06)',
        },
        track: {
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.divider,
          borderRadius: '999999px',
          opacity: '1 !important',
        },
        // label: {
        //   color: 'red !important',
        // },
      },
    }
  )
};

const overrides = [
  webOverrides,
  appOverrides,
];

export default overrides;
