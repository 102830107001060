import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { NavLink } from "react-router-dom";

import styled from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAlertCircleOutline as AccountError,
} from "react-icons/io5";


// UI Imports
import {
    Box,
    Button as MuiButton,
    Card,
    Typography as MuiTypography,
    CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

//svxvy imports
import * as SecurityContants from '../../../constants/SecurityConstants';
import * as NavigationContants from '../../../constants/NavigationConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
import { isSvxvySite } from '../../../constants/NetworkConstants';
import { fetchActiveSubscription } from "../../../store/actions/companyActions";
import { isSVXVYAdmin } from "../../../constants/SecurityConstants";

import StyledDialog from "../../framework/StyledDialog";

// Styles
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CustomerBox = styled(Card)`
    display: flex;
    flexd-direction: row;
    justify-content: spaceBetween;
    align-items: center;
    border: 1px solid ${(props) => props.theme.sidebar.borderColor};
    text-decoration: none;

    ${(props) => props.theme.breakpoints.down("xs")} {
        margin-bottom: 10px;
      }
`;

const SubName = styled(Box)`
    border-right: 1px solid ${(props) => props.theme.sidebar.borderColor};
`;

const AddMore = styled(Typography)`
    color: ${(props) => props.theme.palette.secondary.main};
`;

const GRACE_COUNT = 5;

const OwnerMemberTally = props => {
    const { hidelink, warningOnly } = props;
    const theme = useTheme();

    const loggedInUserId = useSelector(state => state.auth.userId)
    const company = useSelector(state => state.company.company);
    const people = useSelector(state => state.company.people);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const peopleLoading = useSelector(state => state.company.peopleLoading);

    const companyId = useSelector(state => state.company.companyId);
    const subscription = useSelector(state => state.company.subscription);

    const claims = useSelector(state => state.auth.claims);
    const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false)

    const activeMembers = people.filter(x => !x.isInactive
        && !x.isDeleted
        && !x.IsHidden
        && x.id !== loggedInUserId
        && (x.daysTillNextActivity != null || x.hasActiveMembership ||
            (x.daysSinceLastActivity != null && x.daysSinceLastActivity >= -60)
        )
    );
    const overGraceCount = activeMembers.length - company.allowedCustomerCount > GRACE_COUNT;
    const baseURL = isSvxvySite() ? "/" + company.sanitizedName : "";

    /******************** effects **********************/

    const dispatch = useDispatch();
    const loadSubscription = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(fetchActiveSubscription(companyId));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, companyId]);

    useEffect(() => {
        if (activeMembers.length > company.allowedCustomerCount && company.allowedCustomerCount !== null)
            setIsAddMemberDialogOpen(true);
    }, [activeMembers.length, company.allowedCustomerCount])


    useEffect(() => {
        loadSubscription();
    }, [loadSubscription]);

    return (
        <React.Fragment>
            {isOwner && !warningOnly &&
                <CustomerBox component={hidelink ? Box : NavLink}
                    to={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.SUBSCRIPTION}
                    style={{ cursor: hidelink ? 'default' : 'pointer' }}
                >
                    <SubName p={2}>
                        {isLoading && <CircularProgress color="secondary" size={25} />}
                        {subscription && subscription.purchasedProduct && !isLoading && !error && <Typography>{subscription.purchasedProduct.name}</Typography>}
                        {error && <Typography>Oops! {error}</Typography>}
                    </SubName>
                    <Box display="flex" flexDirection="row" alignItems="center" m={2}>
                        {!peopleLoading && <Typography variant="body2" >{activeMembers.length} / {company.allowedCustomerCount === null ? 'Unlimited' : company.allowedCustomerCount} Active {CUSTOMERS_TEXT}</Typography>}
                        {peopleLoading && <CircularProgress color="secondary" size={25} />}
                        {subscription && subscription.memo &&
                            <Box ml={2}>
                                <AccountError color={theme.palette.error.main} size={24} />
                            </Box>
                        }
                        {activeMembers && activeMembers.length >= company.allowedCustomerCount && !hidelink &&
                            <AddMore variant="body2" ml={2}>Add More</AddMore>
                        }
                    </Box>
                </CustomerBox>
            }
            {isAddMemberDialogOpen && !hidelink && 
                <StyledDialog
                    open={isAddMemberDialogOpen}
                    setOpen={setIsAddMemberDialogOpen}
                    dontAllowClose={overGraceCount && !isSVXVYAdmin(loggedInUserId)}
                    primaryAction={
                        <React.Fragment>
                            <Button
                                form="createPriceForm"
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                my={3}
                                component={NavLink}
                                to={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.SUBSCRIPTION}
                            >
                                Upgrade now
                            </Button>
                        </React.Fragment>
                    }
                >
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h2" my={0} mb={5}>A Message from Smartcore</Typography>
                        <Typography mb={2} variant="body2">Your current number of {CUSTOMERS_TEXT.toLowerCase()} is over your purchased limit of {company.allowedCustomerCount}.</Typography>
                        {!overGraceCount &&
                            <React.Fragment>
                                <Typography mb={2} variant="body2">We understand that sometimes things get busy and that is ok, we provide a "grace" period to be over of {GRACE_COUNT} {CUSTOMERS_TEXT.toLowerCase()} before we require updgrading.</Typography>
                                <Typography mb={2} variant="body2">You can close this message with the 'x' in the top right corner, but it will reappear on each page until you are under your limit.</Typography>
                                <Typography mb={2} variant="body2">Or you can upgrade your SmartCore account by clicking below.</Typography>
                                <Typography mb={2} variant="body2">Also don't worry your {CUSTOMERS_TEXT.toLowerCase()} can't see this.</Typography>
                            </React.Fragment>
                        }
                        {overGraceCount &&
                            <React.Fragment>
                                <Typography mb={2} variant="body2">You are over also our grace count of {GRACE_COUNT} {CUSTOMERS_TEXT.toLowerCase()}.</Typography>
                                <Typography mb={2} variant="body2">If you believe this is an error please contact <a href="mailto:support@smartcore.life">support@smartcore.life</a></Typography>
                                <Typography mb={2} variant="body2">Otherwise to continue using Smartcore, upgrade your account by clicking below.</Typography>
                                <Typography mb={2} variant="body2">Also don't worry your {CUSTOMERS_TEXT.toLowerCase()} can't see this.</Typography>
                            </React.Fragment>
                        }
                    </Box>
                </StyledDialog>
            }
        </React.Fragment >
    )
}

export default OwnerMemberTally;