import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
// import "react-big-calendar/lib/css/react-big-calendar.css";
import GlobalCalendarStyles from './react-big-calendar';

import { withTheme } from "styled-components/macro";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Grid,
    LinearProgress as MuiLinearProgress,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import { fetchActivities } from '../../../store/actions/activityActions';
import ActivityDetailModal from './ActivityDetailModal';
import ActivityAddEditModal from './ActivityAddEditModal';

// constants
const localizer = momentLocalizer(moment);

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const StyledSpan = styled.span`
`;

const TimeSpan = styled(StyledSpan)`
`;

const TitleSpan = styled(StyledSpan)`
`;

const StyledCalendar = styled(Calendar)`
    .rbc-toolbar {
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
    }
    .rbc-month-view {
        background-color: ${(props) => props.theme.palette.background.paper};
        font-size: 1.4rem;
    }

    // buttons
    .rbc-btn-group { 
        background-color: transparent
    };
    .rbc-btn-group button {
        background-color: ${(props) => props.theme.palette.background.paper};
        color: ${(props) => props.theme.palette.text.primary};
        border: 1px solid ${(props) => props.theme.palette.divider};
        padding: 10px;
        font-size: 1.2rem;

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &.rbc-active {
            background-color: ${(props) => props.theme.palette.primary.main};
            border-color: ${(props) => props.theme.palette.divider};
            color: white;
            font-weight: 700;
        }
    }

    // highlight today
    // .rbc-today {
    //     background-color: #e7f5ff;
    // }

    // different month grey box
    .rbc-off-range-bg {
        background-color: ${(props) => props.theme.palette.background.default};
    }

    // borders
    .rbc-month-view {
        border: 1px solid ${(props) => props.theme.palette.divider};
    }
    .rbc-header {
        border-bottom: 1px solid ${(props) => props.theme.palette.divider};
        font-size: 1.3rem;
        line-height: 17px;
    }
    .rbc-header + .rbc-header,
    .rbc-day-bg + .rbc-day-bg {
        border-left: 1px solid ${(props) => props.theme.palette.divider};
    }
    .rbc-month-row + .rbc-month-row {
        border-top: 1px solid ${(props) => props.theme.palette.divider};
    }

    // event
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        background-color: ${(props) => props.theme.palette.primary.main};
    }
    .rbc-event-content {
        font-weight: 700;
    }

    // show more link
    .rbc-show-more {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const createEventObjects = (activities, showPeopleCount) => {
    const events = [];
    for (const x in activities) {
        const activity = activities[x];
        const time = moment(activity.displaybleStartDateTime).format('LT');
        const timeToDisplay = time;//time.replace(':00 ', '').replace("AM", 'a').replace("PM", 'p').replace(' ', '');

        const attendees = activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
        const attendeesText = " - " + attendees.length + (activity.allowSelfRegister ? " / " + activity.totalSpaces : "");
        const event = {
            id: activity.id,
            title: (isCanceled(activity) ? "Canceled - " : "") + activity.title + (showPeopleCount ? attendeesText : ''),
            timeToDisplay: timeToDisplay,
            start: activity.displaybleStartDateTime,
            end: moment(activity.displaybleStartDateTime).add(activity.duration, 'minutes').toDate(),
            activity: activity
        }
        events.push(event);
    }
    return events;
}

const date = new Date();
const firstDay = moment(date).startOf('month').startOf('week').toDate();
const lastDay = moment(date).endOf('month').endOf('week').toDate();
const DESTOP_INITIAL_VIEW = 'week';


const getViewsToShow = (isMobile, isTablet) => {
    let views = {};

    if (!isMobile && !isTablet) {
        views.month = true;
        views.week = true;
        views.day = true;
    }
    else if (isTablet && !isMobile) {
        views.week = true;
        views.day = true;
    }
    else {
        views.day = true;
    }
    return views;
}

const isCanceled = (activity) => {
    if (activity)
        return activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled';
    return false;
}

const formats = {
    eventTimeRangeFormat: () => {
        return "";
    },
};

const ActivityCalendar = (props) => {
    const { activities, endDatePassedIn } = props;
    const theme = useTheme();
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);

    const people = useSelector(state => state.company.people).filter(p => p.isDeleted ? false : true).sort(compareObjects('displayName', 'asc'));

    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);

    const eventsToShow = createEventObjects(activities, allowAddEdit);


    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(endDatePassedIn ? endDatePassedIn : lastDay);
    const [dateToShow, setSetDateToShow] = useState(date);
    const [currentView, setSetCurrentView] = useState(DESTOP_INITIAL_VIEW);

    const company = useSelector(state => state.company.company);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down("md"));
    const [viewsToShow, setViewsToShow] = useState(getViewsToShow(isMobile, isTablet));

    // effects
    useEffect(() => {
        if (!isTablet && !isMobile)
            setSetCurrentView(DESTOP_INITIAL_VIEW)
        if (isTablet)
            setSetCurrentView('week')
        if (isMobile)
            setSetCurrentView('day')
        setViewsToShow(getViewsToShow(isMobile, isTablet));
    }, [isMobile, isTablet])

    //==================================================================
    const dispatch = useDispatch();
    const loadActivities = useCallback(async () => {
        try {
            setError(null);
            setIsLoading(true);
            if (company.id) {
                await dispatch(fetchActivities(company.id, startDate, endDate));
            }
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, company.id, startDate, endDate]);

    useEffect(() => {
        loadActivities();
    }, [loadActivities]);

    const onNavigate = (date, view) => {
        const start = moment(date).startOf('month').startOf('week').toDate();
        const end = moment(date).endOf('month').endOf('week').toDate();

        setSetDateToShow(date);
        if (start < startDate)
            setStartDate(start);

        if (end > endDate)
            setEndDate(end);
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        const activityCanceled = isCanceled(event.activity);
        const backgroundColor = activityCanceled
            ? theme.palette.text.secondary
            : event.activity.calendarColor ? event.activity.calendarColor : props.theme.palette.primary.main;
        const textColor = activityCanceled ? theme.palette.error.contrastText : props.theme.palette.primary.USER_DEFINABLE_TEXT_DEFAULT;
        var style = {
            backgroundColor: backgroundColor,
            color: textColor,
        };
        return {
            style: style
        };
    }

    return (
        <React.Fragment>
            {error && !isLoading && <Grid item><Alert severity="error">{error}</Alert></Grid>}
            {isLoading &&
                <Box flex={1}>
                    <LinearProgress my={2} color="primary" />
                    <Typography variant={"h2"} align="center" gutterBottom>Loading {ACTIVITIES_TEXT}...</Typography>
                    <LinearProgress my={2} color="secondary" />
                </Box>
            }
            {!isLoading && !error &&
                <Box mx={3} mt={2}>
                    <GlobalCalendarStyles />
                    <StyledCalendar
                        popup
                        selectable={allowAddEdit}
                        titleAccessor={event =>
                            <React.Fragment><TimeSpan>{event.timeToDisplay}</TimeSpan><TitleSpan> - {event.title}</TitleSpan></React.Fragment>
                        }
                        tooltipAccessor={event => {
                            const activity = event.activity;
                            const attendees = activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));

                            let peopleText = "";
                            for (const i in attendees) {
                                const person = people.find(x => x.id === attendees[i].appUserId);
                                if (person)
                                    peopleText += "  * " + person.displayName + "\n";
                            }
                            if (allowAddEdit)
                                return event.title + "\n\n" + peopleText;
                            else
                                return event.title;
                        }}
                        formats={formats}
                        defaultView='week'
                        view={currentView}
                        views={viewsToShow}
                        localizer={localizer}
                        events={eventsToShow}
                        date={dateToShow}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        style={{ height: 750 }}
                        onSelectEvent={event => { setSelectedActivity(event.activity); setSelectedSlot(null); setActivityDialogOpen(true) }}
                        onSelectSlot={event => { setSelectedActivity(null); setSelectedSlot(event); setActivityDialogOpen(true) }}
                        onNavigate={onNavigate}
                        onView={event => setSetCurrentView(event)}
                        eventPropGetter={eventStyleGetter}
                    />
                </Box>
            }
            {/* {!isLoading && !error && eventsToShow.length === 0 && 
                <Box display="flex" flex={1} justifyContent="center">
                    {!currentUser && <Typography variant="h3">Please login to see {ACTIVITIES_TEXT.toLowerCase()}</Typography> }
                </Box>
            } */}
            {selectedActivity &&
                <ActivityDetailModal
                    open={activityDialogOpen}
                    setOpen={setActivityDialogOpen}
                    activityId={selectedActivity.id}
                    companyId={company.id}
                    viewStartDate={startDate}
                    viewEndDate={endDate}
                />
            }
            {selectedSlot &&
                <ActivityAddEditModal
                    open={activityDialogOpen}
                    setOpen={setActivityDialogOpen}
                    companyId={company.id}
                    viewStartDate={startDate}
                    viewEndDate={endDate}
                    start={selectedSlot.start}
                    duration={(selectedSlot.end - selectedSlot.start) / 60 / 1000}
                />
            }
        </React.Fragment>
    );
}

export default withTheme(ActivityCalendar);
