import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import variants from "./variants";
import typographies from "./typography";
import { webOverrides, appOverrides } from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";
import { cloneDeep } from 'lodash';

import * as Colors from "../constants/Colors";

const createTheme = (name, typographyName, company) => {

  let themeConfig = null;
  if (name === Colors.COMPANY_LIGHT || name === Colors.COMPANY_DARK || name === Colors.COMPANY_LIGHT_WEB || name === Colors.COMPANY_DARK_WEB) {
    themeConfig = variants.find((variant) => variant.name === name);
    if (themeConfig && company) {
      let companyTheme = cloneDeep(themeConfig)
      companyTheme.palette.primary.main = company.colors.themePrimary ? company.colors.themePrimary : Colors.USER_DEFINABLE_DEFAULT;
      companyTheme.palette.primary.contrastText = Colors.USER_DEFINABLE_TEXT_DEFAULT;
      themeConfig = companyTheme;
    }
  }
  else
    themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig)
    variants.find((variant) => variant.name === name)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants.find((variant) => variant.name === Colors.SMARTCORE);
  }

  let themeTypography = typographies.find((variant) => variant.name === typographyName);
  if (!themeTypography) {
    console.warn(new Error(`The typography ${typographyName} is not valid`));
    themeTypography = typographies.find((variant) => variant.name === Colors.COMPANY_WEB_TYPOGRAPHY);
  }
  

  const muiTheme = createMuiTheme(
    {
      spacing: 5,
      breakpoints: breakpoints,
      // overrides: themeOverrides.overrides,
      props: props,
      typography: themeTypography.typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );

  muiTheme.overrides = typographyName === Colors.COMPANY_WEB_TYPOGRAPHY ? webOverrides(muiTheme) : appOverrides(muiTheme);

  return muiTheme;

};

export default createTheme;
