import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { makeStyles } from '@material-ui/core/styles';

import {
    Box,
    Dialog,
    MenuItem,
    Typography as MuiTypography,
} from "@material-ui/core";

import {
    IoCardOutline,
    IoLogOutOutline,
    IoPersonCircleOutline,
    IoRepeat,
    IoFileTrayStackedOutline as AccountFilesIcon,
    IoPeopleCircleOutline as FamilyIcon,
    IoDocumentTextOutline as WaiverIcon
} from "react-icons/io5";


/********************** svxvy import ***********************/
import { logout } from "../../store/actions/authActions"
import * as NavigationConstants from "../../constants/NavigationConstants";
import { isSvxvySite } from '../../constants/NetworkConstants';
import ProfileIcon from '../company/profile/ProfileIcon';
import SignedWaiverModal from "../company/profile/SignedWaiverModal";

/********************** design items ***********************/

const useStyles = makeStyles((theme) => ({
    topScrollPaper: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
        justifyContent: 'flex-start',
    },
    noMarginPaper: {
        backgroundColor: theme.palette.background.default,
        marginTop: 0,
        marginLeft: 0,
        width: 250,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
    }
}));

const Typography = styled(MuiTypography)(spacing);

const StyledMenuItem = styled(MenuItem)`
    font: ${(props) => props.theme.typography.body1};
`;

const CompanyUserDropdown = (props) => {
    const { open, setOpen, theme, loggedInUser } = props;
    const classes = useStyles();
    const company = useSelector(state => state.company.company);
    const paymentServiceConfigured = company && company.paymentServicePublicKey && company.paymentServicePublicKey.length > 0 ? true : false;

    const [isWaiverModalOpen, setIsWaiverModalOpen] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleSelectMenu = (locationToGo) => {
        closeMenu();
        const urlToPush = isSvxvySite() ? locationToGo.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : locationToGo;
        history.push(urlToPush);
    }

    const handleSignOut = async () => {
        closeMenu();
        await dispatch(logout());
        const urlToPush = isSvxvySite() ?
            (company ?
                NavigationConstants.COMPANY.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName)
                : "/")
            : NavigationConstants.COMPANY;
        history.push(urlToPush);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={closeMenu}
                classes={{
                    paper: classes.noMarginPaper,
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody,
                }}
            >
                <Box display="flex" flexDirection="row" alignItems="center" mt={3} mb={4} ml={2} onClick={closeMenu} >
                    <ProfileIcon person={loggedInUser} />
                    <Typography variant="body2" ml={3}>Hi, {loggedInUser.displayName}</Typography>
                </Box>
                <Box borderTop={1} borderColor={theme.palette.divider}>
                    <StyledMenuItem onClick={() => handleSelectMenu(NavigationConstants.MY_PROFILE)}><IoPersonCircleOutline fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Profile</Typography></StyledMenuItem>
                    <StyledMenuItem onClick={() => handleSelectMenu(NavigationConstants.MY_FAMILY)}><FamilyIcon fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>My Family</Typography></StyledMenuItem>
                    {company && !company.isRABSite && <StyledMenuItem onClick={() => handleSelectMenu(NavigationConstants.MY_FILES)}><AccountFilesIcon fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Account Files</Typography></StyledMenuItem>}
                    {loggedInUser.waiverSignedOn && <StyledMenuItem onClick={() => { closeMenu(); setIsWaiverModalOpen(true); }}><WaiverIcon fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Signed Waiver</Typography></StyledMenuItem>}
                    {paymentServiceConfigured && <StyledMenuItem onClick={() => handleSelectMenu(NavigationConstants.MY_SUBSCRIPTIONS)}><IoRepeat fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Purchases</Typography></StyledMenuItem>}
                    {paymentServiceConfigured && <StyledMenuItem onClick={() => handleSelectMenu(NavigationConstants.MY_PAYMENT_METHODS)}><IoCardOutline fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Payment Methods</Typography></StyledMenuItem>}
                </Box>
                <Box borderTop={1} borderColor={theme.palette.divider} marginBottom={1}>
                    <StyledMenuItem onClick={handleSignOut}><IoLogOutOutline fontSize={24} color={theme.palette.primary.main} /><Typography ml={2}>Sign out</Typography></StyledMenuItem>
                </Box>
            </Dialog>
            {isWaiverModalOpen &&
                <SignedWaiverModal
                    isWaiverModalOpen={isWaiverModalOpen}
                    setIsWaiverModalOpen={setIsWaiverModalOpen}
                    user={loggedInUser} />
            }
        </React.Fragment>

    );
}

export default CompanyUserDropdown;
