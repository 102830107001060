import React from "react";
import { useSelector } from 'react-redux';
import { Redirect, useParams } from "react-router-dom";

// svxvy imports
import * as NavigationContants from '../../constants/NavigationConstants';
import * as SecurityContants from '../../constants/SecurityConstants';
import { isSvxvySite } from '../../constants/NetworkConstants';
import { checkClaim } from '../../helpers/helperFunctions';

const hideWebPage = (webPages, pageId, isOwner) => {
  const page = webPages.find(x => x.pageId === pageId.toLowerCase());
  if (page && page.hidePage && !isOwner)
    return true;
  return false;
}

const noWebsiteAllowedPages = (pageId) =>{
  const idToCheck = pageId ? pageId.toLowerCase() : '';
  if (idToCheck === NavigationContants.LOGIN.toLowerCase() 
     || idToCheck === NavigationContants.USER_REGISTER.toLowerCase()  
     || idToCheck === 'accountregister'
     || idToCheck === NavigationContants.EULA.toLowerCase() 
     || idToCheck === NavigationContants.PRIVACY_POLICY.toLowerCase()  
    )
      return true;
  else return false;
}

// For routes that can only be accessed by authenticated users
const PublicAuthGuard = (props) => {
  const { pageId, loadingCompanies, children  } = props;
  const { sanitizedCompanyName } = useParams();

  const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);
  const currentUserClaims = useSelector(state => state.auth.claims);
  const isOwner = checkClaim(currentUserClaims, SecurityContants.MANAGAE_ROLES);
  const webPages = useSelector(state => state.company.webPages);
  const company = useSelector(state => state.company.company);
  
  if (loadingCompanies || !didTryAutoLogin)
    return null;
  else if (hideWebPage(webPages, pageId, isOwner))
    return <Redirect to={"/" + (isSvxvySite() ? sanitizedCompanyName : '')} />;
  else if (company && company.usesWebsite === false && !noWebsiteAllowedPages(pageId))
    return <Redirect to={"/" + (isSvxvySite() ? sanitizedCompanyName + "/" + NavigationContants.LOGIN: NavigationContants.LOGIN)} />;
  else
    return children;
}

export default PublicAuthGuard;
