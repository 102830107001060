import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { NavLink } from "react-router-dom";

// UI Imports
import styled, { useTheme } from "styled-components/macro";
import { useMediaQuery } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleOutline as IncompleteIcon,
    IoCheckmarkCircleSharp as CompleteIcon,
    IoHelpCircleOutline as HelpIcon,
    IoRadioButtonOffOutline as RadioOff,
    IoRadioButtonOnOutline as RadioOn,
} from "react-icons/io5";


import {
    Box,
    Card as MuiCard,
    CardContent,
    // Button as MuiButton,
    // Grid,
    IconButton as MuiIconButton,
    Tab as MuiTab,
    Tabs as MuiTabs,
    ListItem as MuiListItem,
    ListItemText as MuiListItemText,
    Popover as MuiPopover,
    Typography as MuiTypography
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

//svxvy imports
import * as SecurityContants from '../../../constants/SecurityConstants';
import * as NavigationContants from '../../../constants/NavigationConstants';
import { USER_DEFINABLE_DEFAULT } from '../../../constants/Colors';
import {
    DEFAULT_COMPANY_LOGO,
    ACTIVITY_DEFAULT_TEXT,
    ACTIVITIES_DEFAULT_TEXT,
    EMPLOYEE_DEFAULT_TEXT,
    EMPLOYEES_DEFAULT_TEXT,
    LOCATION_DEFAULT_TEXT,
    LOCATIONS_DEFAULT_TEXT,
    CUSTOMER_DEFAULT_TEXT,
    CUSTOMERS_DEFAULT_TEXT
} from '../../../models/Company';
import { DEFAULT_USER_IMAGE } from "../../../models/Person";

import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import { isSvxvySite } from '../../../constants/NetworkConstants';
import { editCompanyAttributes } from '../../../store/actions/companyActions';
import ErrorDialog from "../../framework/ErrorDialog";

// UI Consts
const Card = styled(MuiCard)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const Tabs = styled(MuiTabs)`
    height: 35px;
    min-height: auto;
    max-height: none;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        height: 100%;
    }
`;

const Tab = styled(MuiTab)`
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    color: ${(props) => props.theme.palette.text.primary};
    // border-radius: 10px;
    padding: 0 29px 0 29px;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;

    border-right: 1px solid ${(props) => props.theme.palette.divider};

    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;

const LastTab = styled(Tab)`
    border-right: 0 solid ${(props) => props.theme.palette.divider};

`;

const useStyles = makeStyles({
    tabWrapperStyle: {
        flexDirection: 'row',

        '& :first-child': {
            marginBottom: '0 !important',
            marginRight: 5
        },
    },

});

const ListItemSeparated = styled(MuiListItem)`
  width: auto;
  background-color: transparent !important;
  margin: 0 15px 0 0;
  padding: 0;
  display: inline-block;
  position: relative;

  &:after {
    margin-right: 15px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ListItemText = styled(MuiListItemText)`
  margin: 0;

  span {
    margin: 0;
    color: ${(props) => props.theme.palette.secondary.main};
    font-size: 12px;
    line-height: 15px;
  }
`;

const Popover = styled(MuiPopover)`
.MuiPopover-paper{
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 15px;
}
`;

const TipPopup = styled(Box)`
    width: 100%;
    max-width: 250px;
    background-color: ${(props) => props.theme.palette.background.paper};
    // border: 1px solid red;
    border-radius: 10px;
    padding: 15px;
`;

// const Button = styled(MuiButton)(spacing);

const BRANDING = 'Branding';
// const PROFILES = 'Profiles';
const PAYMENTS = 'Payments';
const WEBSITE = 'Website';
// const ACTIVITIES = 'ACTIVITES';
// const CUSTOMERS = 'CUSTOMERS';

const ICON_SIZE = '16px';
const RADIO_ICON_SIZE = '24px';

const TabPanel = props => {
    const { children, value, index, heading, ...other } = props;
    const theme = useTheme();

    return (
        <Card
            style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index &&
                <React.Fragment>
                    {heading &&
                        <Box p={3} style={{ backgroundColor: theme.palette.background.default }}>
                            <Typography variant="h4">{heading}</Typography>
                            <Typography mt={2} variant="body2">Need help? We’re always here to support you. Contact us at <a href="mailto:support@smartcore.life">support@smartcore.life</a></Typography>
                        </Box>
                    }
                    <Box mt={2} mb={3}>
                        {children}
                    </Box>
                </React.Fragment>

            }
        </Card>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const SetupItem = props => {
    const { status, text, secondLine, question, questionChange, isSaving, helperText, navLink, navText, helperLink, topSeperator } = props;
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" mt={2} pt={topSeperator ? 2 : 0} px={3}
            style={{ borderTop: topSeperator ? ('1px solid ' + theme.palette.divider) : 'none' }}
        >
            <Box display="flex" flexDirection="row">
                <Box mr={1} mt={helperText ? .5 : 0}>
                    {status
                        ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                        : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />}
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography>{text}</Typography>
                        {helperText &&
                            <PopupState variant="popover" popupId="help-popup-popover">
                                {(popupState) => (
                                    <React.Fragment>
                                        <IconButton
                                            aria-label="Help"
                                            size="small"
                                            color='primary'
                                            ml={1}
                                            {...bindTrigger(popupState)}>
                                            <HelpIcon fontSize="inherit" />
                                        </IconButton>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <TipPopup>
                                                <Typography>{helperText}</Typography>
                                            </TipPopup>
                                        </Popover>
                                    </React.Fragment>
                                )}
                            </PopupState>
                        }
                        {questionChange
                            ? <Box display="flex" flexDirection="row" alignItems="center" ml={6}>
                                <Box display="flex" flexDirection="row" alignItems="center" mr={6} onClick={() => questionChange(true)}
                                    style={{ cursor: 'pointer' }}
                                    disabled={isSaving}
                                >
                                    {question === true ? <RadioOn size={RADIO_ICON_SIZE} /> : <RadioOff size={RADIO_ICON_SIZE} />}
                                    <Typography ml={1}> Yes</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center"
                                    onClick={() => questionChange(false)}
                                    style={{ cursor: 'pointer' }}>
                                    {question === false ? <RadioOn size={RADIO_ICON_SIZE} /> : <RadioOff size={RADIO_ICON_SIZE} />}<Typography ml={1}>No</Typography>
                                </Box>
                                {isSaving && <Typography ml={4}>Saving...</Typography>}
                            </Box>
                            : <React.Fragment />
                        }
                    </Box>
                    {secondLine && <Typography>{secondLine}</Typography>}
                    <Box display="flex" flexDirection="row" mt={1} alignItems="center">
                        {navLink &&
                            <ListItemSeparated button={true}
                                component={NavLink}
                                to={navLink}
                                exact>
                                <ListItemText primary={navText ? navText : 'Take me there'} />
                            </ListItemSeparated>
                        }
                        {helperLink &&
                            <ListItemSeparated button={true}
                                component="a"
                                href={helperLink}
                                target="_blank">
                                <ListItemText primary='See directions' />
                            </ListItemSeparated>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

SetupItem.propTypes = {
    status: PropTypes.bool.isRequired,
    question: PropTypes.bool,
    isSaving: PropTypes.bool,
    text: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    navLink: PropTypes.string,
    navText: PropTypes.string,
    helperLink: PropTypes.string,
};

const OwnerGettingStarted = props => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const loggedInUser = useSelector(state => state.auth.person);
    const company = useSelector(state => state.company.company);
    const companyId = company.id;

    const allPeople = useSelector((state) => state.company.people);
    const employees = allPeople.filter(x => x.isEmployee && !x.isDeleted);
    const customers = allPeople.filter(x => !x.isEmployee && !x.isDeleted);
    // const locations = useSelector(state => state.company.locations);
    // const activities = useSelector(state => state.activity.activities);

    const classes = useStyles();
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(0);

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);
    const EMPLOYEES_TEXT = useSelector(state => state.company.employeesText);
    // const LOCATION_TEXT = useSelector(state => state.company.locationText);

    const claims = useSelector(state => state.auth.claims);
    const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);

    const baseURL = isSvxvySite() ? "/" + company.sanitizedName : "";

    // branding checks
    const brandingSet = company.logo !== DEFAULT_COMPANY_LOGO
        || (company.colors.themePrimary && company.colors.themePrimary.toUpperCase() !== USER_DEFINABLE_DEFAULT)
        ? true : false;
    const termsSet = company.activityText !== ACTIVITY_DEFAULT_TEXT ||
        company.activitiesText !== ACTIVITIES_DEFAULT_TEXT ||
        company.employeeText !== EMPLOYEE_DEFAULT_TEXT ||
        company.employeesText !== EMPLOYEES_DEFAULT_TEXT ||
        company.locationText !== LOCATION_DEFAULT_TEXT ||
        company.locationsText !== LOCATIONS_DEFAULT_TEXT ||
        company.customerText !== CUSTOMER_DEFAULT_TEXT ||
        company.customersText !== CUSTOMERS_DEFAULT_TEXT ? true : false;
    const companyInfo = company.companyContactEmail || company.phoneNumber ? true : false;
    const brandingChecks = brandingSet && termsSet && companyInfo;

    // employee checks
    const mainProfileSetup = (loggedInUser.image && loggedInUser.image !== DEFAULT_USER_IMAGE) || loggedInUser.bio ? true : false;
    const otherProfilesSetup = employees && employees.length > 1 ? true : false;
    const employeeChecks = mainProfileSetup && otherProfilesSetup;

    // payment checks
    const [usesStripe, setUsesStripe] = useState(company.usesStripe)

    const allProducts = useSelector(state => state.products.products);
    const memberships = allProducts.filter(x => x.isRecurring && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))
    const packages = allProducts.filter(x => x.units > 0 && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))
    const products = allProducts.filter(x => !x.isRecurring && x.units === 0 && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))

    const stripeSetup = company.paymentServicePublicKey && company.paymentServicePublicKey.length > 0 ? true : false;
    const membershipPackageCreated = memberships.length || packages.length > 0 ? true : false;
    const productCreated = products.length > 0 ? true : false;

    const puchaseEmailSet = company.purchaseNotificationEmails.length > 0;

    const hideBilling = company.hideBilling ? true : false;
    const paymentChecks = company.usesStripe === null ? false :
        company.usesStripe
            ? stripeSetup && membershipPackageCreated && productCreated && puchaseEmailSet
            : hideBilling;

    // website checks
    const [usesWebsite, setUsesWebsite] = useState(company.usesWebsite);
    const socialLinks = company.instagramLink || company.facebookLink || company.twitterLink || company.vimeoLink || company.youTubeLink || company.tickTokLink ? true : false;
    const homeSetup = company.webPages.find(x => x.pageId && x.pageId.toLowerCase() === NavigationContants.HOME_ID.toLowerCase()) ? true : false;
    const offeringsSetup = company.webPages.find(x => x.pageId && x.pageId.toLowerCase() === NavigationContants.OFFERINGS.toLowerCase()) || company.offerings.length > 0 ? true : false;
    const galleryImages = company.webPages.find(x => x.pageId && x.pageId.toLowerCase() === NavigationContants.GALLERY.toLowerCase()) || company.photos.length > 0 ? true : false;;
    const faqsAdded = company.faqs.length > 1 ? true : false;
    const membershipsPage = company.webPages.find(x => x.pageId && x.pageId.toLowerCase() === NavigationContants.MEMBERSHIP.toLowerCase()) ? true : false;
    const shopPage = company.webPages.find(x => x.pageId && x.pageId.toLowerCase() === NavigationContants.SHOP.toLowerCase()) ? true : false;
    const customUrl = company.websiteDomainName ? true : false;
    const websiteChecks = socialLinks && homeSetup && offeringsSetup && galleryImages && faqsAdded && membershipsPage && shopPage && customUrl;

    // activties checks
    const allActivities = useSelector(state => state.activity.activities)

    const setWaiver = company.waiverText ? true : false;
    const globalReminder = company.globalReminderMessage ? true : false;
    const createActivity = allActivities.length > 0 ? true : false;
    const activitiesChecks = setWaiver && globalReminder && createActivity;

    // customer checks
    const customersAdded = customers.length > 1 ? true : false;
    const customerChecks = customersAdded;

    const allDone = brandingChecks && employeeChecks && paymentChecks && websiteChecks && activitiesChecks && customerChecks;

    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const tabParentRef = useRef(null);
    const [width, setWidth] = useState(0);

    /***************************** updates *********************************/
    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values) => {
        setError(null);
        setIsSubmitting(true);

        try {
            await dispatch(editCompanyAttributes(values, companyId, "/setup"));
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);


    useEffect(() => {
        const values = {
            usesWebsite: usesWebsite,
            usesStripe: usesStripe,
        }
        if (isOwner
            && (company.usesStripe !== usesStripe || company.usesWebsite !== usesWebsite))
            submitCallback(companyId, values)
    }, [isOwner, usesWebsite, usesStripe, company.usesStripe, company.usesWebsite, companyId, submitCallback])

    // This is because of a bug in Mui v4 with Tab and mobile/tablet resizing.  It supposed to be fixed in v5.
    useEffect(() => {
        if (tabParentRef && tabParentRef.current && isOwner) {
            const resizeObserver = new ResizeObserver((entries) => {
                // this callback gets executed whenever the size changes
                // when size changes get the width and update the state
                // so that the Child component can access the updated width
                for (let entry of entries) {
                    if (entry.contentRect) {
                        setWidth(entry.contentRect.width);
                    }
                }
            });

            const currentRef = tabParentRef.current;
            // register the observer for the div
            if (resizeObserver)
                resizeObserver.observe(currentRef);

            // unregister the observer
            return () => resizeObserver.unobserve(currentRef);
        }
    }, [isOwner]);

    return (
        <React.Fragment>
            {isOwner &&
                //  !allItemsDone &&
                <Card mb={4}>
                    <CardContent>
                        <Box p={3} ref={tabParentRef} >
                            <Typography variant="h3" mb={2}  >Welcome to SmartCore Setup Guide!</Typography>
                            <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>Only owners can see this guide.  Your {CUSTOMERS_TEXT.toLowerCase()} will only see the other content on this page.</Typography>
                            <Box width={isMobile ? '280px' : width} maxWidth={830} my={3}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue)}
                                    aria-label="Startup guide selector"
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons={isMobile || width < 750 ? "on" : "off"}
                                >
                                    <Tab
                                        // value={BRANDING}
                                        label={BRANDING}
                                        icon={brandingChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(0)}
                                        aria-label="Set your sites branding" />
                                    <Tab
                                        // value={PROFILES}
                                        label={EMPLOYEES_TEXT}
                                        icon={employeeChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(1)}
                                        aria-label="Set your sites branding" />
                                    <Tab
                                        // value={PAYMENTS}
                                        label={PAYMENTS}
                                        icon={paymentChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(2)}
                                        aria-label="Set your sites branding" />
                                    <Tab
                                        // value={WEBSITE}
                                        label={WEBSITE}
                                        icon={websiteChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(3)}
                                        aria-label="Set your sites branding" />
                                    <Tab
                                        // value={ACTIVITIES}
                                        label={ACTIVITIES_TEXT}
                                        icon={activitiesChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(4)}
                                        aria-label="Set your sites branding" />
                                    <LastTab
                                        // value={CUSTOMERS}
                                        icon={customerChecks
                                            ? <CompleteIcon style={{ color: theme.palette.success.main }} size={ICON_SIZE} />
                                            : <IncompleteIcon style={{ color: theme.palette.iconColor.color }} size={ICON_SIZE} />
                                        }
                                        label={CUSTOMERS_TEXT}
                                        classes={{
                                            wrapper: classes.tabWrapperStyle,
                                        }}
                                        {...a11yProps(5)}
                                        aria-label="Set your sites branding" />
                                </Tabs>
                            </Box>
                            <TabPanel value={activeTab} index={0} heading={BRANDING}>
                                <SetupItem
                                    status={brandingSet}
                                    text="Add your logo, pick your theme and select your accent color."
                                    // helperText='Add info'
                                    navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_BRANDING}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={termsSet}
                                    text={'Customize what you call your ' + ACTIVITIES_TEXT.toLowerCase() + ', ' + CUSTOMERS_TEXT.toLowerCase() + ', and ' + EMPLOYEES_TEXT.toLowerCase()}
                                    // helperText='Add info'
                                    navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={companyInfo}
                                    text={'Update your company contact information'}
                                    // helperText='Add info'
                                    navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#CONTACT_INFO'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={1} heading={EMPLOYEES_TEXT}>
                                <SetupItem
                                    status={mainProfileSetup}
                                    text="Add an image and bio to your profile."
                                    helperText="The bio can be set in the 'Set attributes of this user' section and will appear on the website and mobile app if the profile is set as public."
                                    navLink={baseURL + '/myprofile'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={otherProfilesSetup}
                                    text={'Add other ' + EMPLOYEES_TEXT.toLowerCase() + ' accounts.'}
                                    // helperText=""
                                    navLink={baseURL + '/' + company.customersText.toLowerCase()}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={2} heading={PAYMENTS}>
                                <SetupItem
                                    status={stripeSetup || company.usesStripe === false}
                                    text="Do you want to take credit cards, have recurring memberships, sell products or packages?"
                                    question={usesStripe}
                                    questionChange={(value) => setUsesStripe(value)}
                                    isSaving={isSubmitting}
                                    secondLine={usesStripe ? "If so you need to integrate SmartCore with Stripe." : ''}
                                    helperText="Stripe is a leader in payment and creditcard processing. This ensures that any credit cards you handle for your clients are totally secure. "
                                    navLink={usesStripe ? (baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.PAYMENT_INTEGARATION) : null}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                {usesStripe &&
                                    <React.Fragment>
                                        {!company.isRABSite && <SetupItem
                                            topSeperator
                                            status={membershipPackageCreated}
                                            text={'Create Memberships and Packages'}
                                            helperText={'Memberships and Packages are ways for ' + CUSTOMERS_TEXT.toLowerCase() + ' to pay for ' + ACTIVITIES_TEXT.toLowerCase()}
                                            navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.MEMBERSHIPS_AND_PACKAGES}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />}
                                        <SetupItem
                                            topSeperator
                                            status={productCreated}
                                            text={'Create products such as sweatshirts.'}
                                            helperText={'Sell physical items to your ' + CUSTOMERS_TEXT.toLowerCase()}
                                            navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.MANAGE_PRODUCTS}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={puchaseEmailSet}
                                            text={'Send an email to specific people when something is purchased.'}
                                            // helperText={'Sell physical items to your ' + CUSTOMERS_TEXT.toLowerCase()}
                                            navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#COMPANY_ITEMS'}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                    </React.Fragment>
                                }
                                {usesStripe === false &&
                                    <React.Fragment>
                                        <SetupItem
                                            topSeperator
                                            status={hideBilling}
                                            text={'Do you want to hide the billing functionaliy?'}
                                            helperText={'If you only want to use SmartCore for scheduling we recommend you hide this.'}
                                            navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#COMPANY_ITEMS'}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                    </React.Fragment>
                                }
                            </TabPanel>
                            <TabPanel value={activeTab} index={3} heading={WEBSITE}>
                                <SetupItem
                                    status={stripeSetup || company.usesWebsite === false}
                                    text="Do you plan to use Smartcore for your website?"
                                    question={usesWebsite}
                                    questionChange={(value) => setUsesWebsite(value)}
                                    isSaving={isSubmitting}
                                    helperText="Setting this to 'no' will configure any external page to redirect to the login page."
                                // navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.MA}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                {usesWebsite &&
                                    <React.Fragment>
                                        <SetupItem
                                            topSeperator
                                            status={socialLinks}
                                            text={'Configure your social links'}
                                            helperText='These will show up in the navigation of your public website.'
                                            navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#SOCIAL_LINKS'}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={homeSetup}
                                            text={'Setup your home page. Including the hero image and tag lines.'}
                                            helperText="This will take your public website. To return here click the 'My Portal' link in the navigation."
                                            navLink={baseURL}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={offeringsSetup}
                                            text='Setup your offerings. These should be general items such as 1 on 1 personal training, small group training, classes...'
                                            // helperText="This will take your public website. To return here click the 'My Portal' link in the navigation."
                                            navLink={baseURL + '/' + NavigationContants.OFFERINGS}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={galleryImages}
                                            text='Add images to your gallery'
                                            // helperText="This will take your public website. To return here click the 'My Portal' link in the navigation."
                                            navLink={baseURL + '/' + NavigationContants.GALLERY}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={faqsAdded}
                                            text={'Add frequently asked questions to help out new and existing ' + CUSTOMERS_TEXT.toLowerCase()}
                                            helperText="Any question added will show on both your public and portal sites."
                                            navLink={baseURL + '/' + NavigationContants.FAQ}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={membershipsPage}
                                            text='Setup your prices page'
                                            secondLine='If you are using Membership and Packages they will automatically appear there.'
                                            helperText="Don't want to show your prices online? See how to hide this page using the help link."
                                            navLink={baseURL + '/' + NavigationContants.MEMBERSHIP}
                                            helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={shopPage}
                                            text='Setup your shop page for the products you sell'
                                            secondLine='If you are using Membership and Packages they will automatically appear there.'
                                            helperText="Not selling products? See how to hide this page using the help link."
                                            navLink={baseURL + '/' + NavigationContants.SHOP}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                        <SetupItem
                                            topSeperator
                                            status={customUrl}
                                            text='Want a custom domain for your website?'
                                            secondLine='No problem we just need to help you a little please contact support@smartcore.life'
                                            helperText="This is included free with any paid plan."
                                        // navLink={baseURL + '/' + NavigationContants.SHOP}
                                        // helperLink={'https://smartcoreapp.com'}
                                        />
                                    </React.Fragment>
                                }
                            </TabPanel>
                            <TabPanel value={activeTab} index={4} heading={ACTIVITIES_TEXT}>
                                <SetupItem
                                    status={setWaiver}
                                    text={'Configure a waiver for your ' + CUSTOMERS_TEXT.toLowerCase()}
                                    helperText='These will show up in the navigation of your public website.'
                                    navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#WAIVER'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={globalReminder}
                                    text={"Set a default " + ACTIVITIES_TEXT.toLowerCase() + " reminder"}
                                    helperText={"Set be overwritten on a " + ACTIVITY_TEXT.toLowerCase() + " by" + ACTIVITY_TEXT.toLowerCase() + " basis."}
                                    navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#REMINDER'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={createActivity}
                                    text={'Create your first ' + ACTIVITIES_TEXT.toLowerCase()}
                                    helperText={'What if I have multiple types of ' + ACTIVITIES_TEXT.toLowerCase() + "? It's ok check our help section."}
                                    navLink={baseURL + '/' + NavigationContants.ACTIVITIES}
                                    helperLink={'https://smartcoreapp.com/'}
                                />
                            </TabPanel>
                            <TabPanel value={activeTab} index={5} heading={CUSTOMERS_TEXT}>
                                <SetupItem
                                    status={allDone}
                                    text={'Share website with your ' + CUSTOMERS_TEXT.toLowerCase() + ' so they can sign up for free'}
                                // helperText=''
                                // navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#WAIVER'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={customersAdded}
                                    text={'Or manually add your ' + CUSTOMERS_TEXT.toLowerCase() + ' and send them an invite'}
                                    // helperText=''
                                    navLink={baseURL + '/' + company.customersText.toLowerCase()}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                                <SetupItem
                                    topSeperator
                                    status={customersAdded}
                                    text={'Moving from another platform and have lots of ' + CUSTOMERS_TEXT.toLowerCase() + '?'}
                                    secondLine={'No problem we are here to help. Contact us at support@smartcore.life to see how we can bulk load your members.'}
                                    helperText=''
                                // navLink={baseURL + '/' + NavigationContants.MANAGE + '/' + NavigationContants.COMPANY_SETTINGS + '/#WAIVER'}
                                // helperLink={'https://smartcoreapp.com'}
                                />
                            </TabPanel>
                        </Box>
                    </CardContent>
                </Card>

            }
            <ErrorDialog message={error} setMessage={setError} />
        </React.Fragment>
    );
}

export default OwnerGettingStarted;