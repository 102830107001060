import React, { useState, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { Formik } from "formik"
import * as Yup from "yup";
import moment from 'moment';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    TextField as MuiTextField,
    MenuItem,
    Select,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { requestRefund } from '../../../store/actions/paymentActions';
import StyledDialog from "../../framework/StyledDialog";
import ActionButton from "../../framework/ActionButton";

// constants

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const validationSchema = Yup.object().shape({
    refundAmount: Yup.number()
        .min(0, "Must be greater than $0"),
    creditAmount: Yup.number()
        .min(0, "Must be greater than $0"),
    outOfBandAmount: Yup.number()
        .min(0, "Must be greater than $0"),
});


const RefundRequest = (props) => {
    const { company, purchase, purchaseUser, currentUser } = props;
    const [error, setError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues = {
        reason: '',
        refundAmount: 0,
        creditAmount: 0,
        outOfBandAmount: 0,
        memo: ''
    };

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (values, setStatus, setSubmitting, resetForm, setOpen) => {
        setError(null);
        setIsSubmitting(true);
        try {
            setStatus({ sent: true });
            const requestBody = {
                companyId: company.id,
                purchaseId: purchase.id,
                reason: values.reason,
                refundAmount: Number(values.refundAmount) === 0 ? null : Number(values.refundAmount),
                creditAmount: Number(values.creditAmount) === 0 ? null : Number(values.creditAmount),
                outOfBandAmount: Number(values.outOfBandAmount) === 0 ? null : Number(values.outOfBandAmount),
                memo: values.memo
            };
            await dispatch(requestRefund(requestBody));
            setIsSubmitting(false);
            resetForm();
            setOpen(false);
        } catch (error) {
            setError(error.message);
            setStatus({ sent: false });
            setIsSubmitting(false);
        }
    }, [dispatch, company.id, purchase.id]);

    const handleSubmit = (values, { resetForm, setStatus, setSubmitting }) => {
        submitCallback(values, setStatus, setSubmitting, resetForm, props.setOpen);
    };
    // duplicate, fraudulent, order_change, or product_unsatisfactory

    return (
        <StyledDialog
            open={props.open}
            setOpen={props.setOpen}
            aria-labelledby="act-dialog-title"
            primaryAction={
                <ActionButton
                    type="submit"
                    form="refundRequestForm"
                    variant="contained"
                    color="primary"
                    fullWidth
                    submitting={isSubmitting}
                >
                    Submit Refund
                </ActionButton>
            }
        >
            <Box p={2}>
                <Typography variant="h2">Process Refund</Typography>
            </Box>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}

                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            {error && <Alert mb={4} severity="error">
                                {error}
                            </Alert>}
                            <form id="refundRequestForm" onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography>{purchaseUser && purchaseUser.id === currentUser.id ? "You paid" : purchaseUser.displayName + " paid"} ${purchase.amountPaid.toFixed(2)}</Typography>
                                            {purchase.purchasedProduct && <Typography>{purchase.purchasedProduct.name}</Typography>}
                                            {purchase.userPayment && <Typography>{purchase.userPayment.activityName} - {moment(purchase.userPayment.activityDate).format('llll')}</Typography>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="reason-label">Refund Reason</InputLabel>
                                                <Select
                                                    labelId="reason-label"
                                                    id="reason-select-outlined"
                                                    value={values.reason}
                                                    onChange={(event) => setFieldValue('reason', event.target.value)}
                                                    label={"Refund Reason"} /// nned both this line and Input lavel
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={"duplicate"}>Duplicate</MenuItem>
                                                    <MenuItem value={"fraudulent"}>Fraudulent</MenuItem>
                                                    <MenuItem value={"order_change"}>Order Change</MenuItem>
                                                    <MenuItem value={"product_unsatisfactory"}>Product Unsatisfactory</MenuItem>
                                                    <MenuItem value={"other"}>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            {purchase.paymentChargeId && <TextField
                                                name="refundAmount"
                                                label="Amount Refund To Credit Card"
                                                value={values.refundAmount}
                                                error={Boolean(touched.refundAmount && errors.refundAmount)}
                                                fullWidth
                                                helperText={touched.refundAmount && errors.refundAmount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }}
                                            />}
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                name="creditAmount"
                                                label="Amount To Users Account"
                                                value={values.creditAmount}
                                                error={Boolean(touched.creditAmount && errors.creditAmount)}
                                                fullWidth
                                                helperText={touched.creditAmount && errors.creditAmount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }} />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                name="outOfBandAmount"
                                                label="Amount Refunded Outside of App"
                                                value={values.outOfBandAmount}
                                                error={Boolean(touched.outOfBandAmount && errors.outOfBandAmount)}
                                                fullWidth
                                                helperText={touched.outOfBandAmount && errors.outOfBandAmount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                                my={2}
                                                placeholder=""
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="memo"
                                                label="Detailed reason for refund"
                                                value={values.memo}
                                                error={Boolean(touched.memo && errors.memo)}
                                                fullWidth
                                                helperText={touched.memo && errors.memo}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline={true}
                                                maxRows={3}
                                                variant="outlined"
                                                mb={2}
                                                placeholder="" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {error && <Alert mb={4} severity="error">
                                    {error}
                                </Alert>}
                            </form>
                        </React.Fragment>
                    )
                    }
                </Formik >
            </DialogContent>
        </StyledDialog >
    );
}

export default RefundRequest;