import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import i18n from 'i18n-js';

import styled, { withTheme } from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";

import {
  Box,
  Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoPersonCircleOutline as PersonIcon,
  IoLocationOutline as LocationIcon,
  IoCheckmarkCircleOutline as CheckIcon,
  IoCashOutline as CostIcon,
} from "react-icons/io5";

// svxvy imports
import { getMilliseconds } from '../../../helpers/helperFunctions';
import NonExpandedActivityDetailModal from './NonExpandedActivityDetailModal';

// UI Consts
const Typography = styled(MuiTypography)(spacing);

const ActivityCardWrapper = styled(Box)`
  padding: 0 15px;
  border: 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0 15px 0 15px;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: 0 15px 0 0;
  }
`;

// const ActivityCardContent = styled(Box)`
//   padding: 10px 0;
//   align-items: flex-start;

//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: 15px 0 13px 0;
//   }
// `;

const ActivityTitle = styled(Typography)`
  margin: 0 0 3px 0;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 0 0 3px 0;
  }
`;

// const stupidFunction = (activity, loggedInUser) => {
//   if (!activity || !loggedInUser || !loggedInUser.childProfiles)
//     return false;

//   return activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity);
// }



const NonExpandedActivityCard = props => {
  const { activity, viewStartDate, viewEndDate, showDate, hideDurration, dontAllowShowDetails } = props;
  const theme = useTheme();

  const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
  const location = useSelector(state => state.company.locations).find(x => x.id === activity.locationId)

  const companyId = useSelector(state => state.company.companyId);

  const activityCanceled = !activity ? false :
    activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
    || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

  const instructors = activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity && activity.isInActivityInstance(a, activity));
  const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
  
  const durationInMilliSeconds = getMilliseconds(activity.duration, 'minutes');
  const isInPast = Date.now() >= activity.displaybleStartDateTime.getTime() + durationInMilliSeconds;

  // const attendees = activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
  // const peopleSignedUpforActivity = useSelector(state => state.company.people).filter(p => attendees.find(a => a.appUserId === p.id));
  // const whenCanRegister = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterStartValue, activity.selfRegisterStartUnit));
  // const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
  // const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInActivityInstance(haveAttendeeObject, activity) : false;
  // const aFamilySignedUp = stupidFunction(activity, loggedInUser);

  const [showDetails, setShowDetails] = useState(false);
  const isRecurring = activity.rrule !== null && activity.rrule.trim().length > 0 ? true : false;
  const endDateText = useMemo(() => {
    return activity.getEndDateAsText(isRecurring, activity.rrule, activity.startDateTime);
  }, [activity, isRecurring]);

  /******************************************* events *******************************/
  // const dispatch = useDispatch();


  /******************************************* Add or drop *******************************/

  return (
    <ActivityCardWrapper display="flex" flexDirection="row">
      {/* <ActivityCardContent > */}
      <Box width={140}
        mr={4}
        ml={1}
        py={2}
        onClick={() => setShowDetails(previousValue => !previousValue)}
        style={{ cursor: "pointer", borderRight: '1px solid ' + theme.palette.divider }}
      >
        <Typography color="textPrimary" variant="h4" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '1px 0 3px 0', textDecoration: (activityCanceled ? 'line-through' : 'none'), fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.8rem' }}>
          {moment(activity.displaybleStartDateTime).format("MMM D")}
          {endDateText ? ' - ' + endDateText : ''}
        </Typography>
        <Typography color="textSecondary" style={{ margin: '1px 0 3px 0', textDecoration: (activityCanceled ? 'line-through' : 'none'), fontWeight: showDate ? '400' : '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
          {moment(activity.displaybleStartDateTime).format("dddd")}{isRecurring ? 's' : ''}
        </Typography>
      </Box>
      <Box
        width={60}
        mr={4}
        py={2}
        onClick={() => setShowDetails(previousValue => !previousValue)}
        style={{ cursor: "pointer" }}>
        <Typography color={"textPrimary"} style={{ color: activityCanceled ? theme.palette.text.secondary : (showDate ? theme.palette.text.secondary : theme.palette.text.primary), margin: '1px 0 3px 0', textDecoration: (activityCanceled ? 'line-through' : 'none'), fontWeight: showDate ? '400' : '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
          {moment(activity.displaybleStartDateTime).format("h:mm a")}
        </Typography>
        {!hideDurration &&
          <Typography variant="subtitle1" color="textSecondary" style={{ textDecoration: (activityCanceled ? 'line-through' : 'none'), fontSize: '1.2rem', lineHeight: '1.5rem' }}>
            {activity.duration} min
            </Typography>
        }
      </Box>
      <Box
        flex={1}
        py={2}
        display="flex"
        flexDirection="column"
        mr={4}
        onClick={() => dontAllowShowDetails ? false : setShowDetails(previousValue => !previousValue)}
        style={{ cursor: dontAllowShowDetails ? "cursor" : "pointer" }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ActivityTitle component="p" variant="h4" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.8rem' }}>{activity.title}</ActivityTitle>
          {activity.calendarColor && activity.calendarColor !== theme.palette.primary.main &&
            <Box style={{ backgroundColor: activity.calendarColor ? activity.calendarColor : theme.palette.primary.main, borderRadius: 50, width: 12, height: 12, marginLeft: 5, marginBottom: 2 }}></Box>
          }
        </Box>
        <Box flex={1} display="flex" flexDirection="row">
          {location &&
            <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
              <LocationIcon size={16} color={theme.palette.text.secondary} />
              <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                {location.name}{activity.activityResources ? ' - ' + activity.activityResources : ''}
              </Typography>
            </Box>
          }
          {!location && activity.manualLocation && activity.manualLocation.trim().length > 0 &&
            <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
              <LocationIcon size={16} color={theme.palette.text.secondary} />
              <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                {activity.manualLocation}{activity.activityResources ? ' - ' + activity.activityResources : ''}
              </Typography>
            </Box>
          }
          {peopleRunningActivity.length > 0 &&
            <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
              <PersonIcon size={16} color={theme.palette.text.secondary} />
              <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                {peopleRunningActivity[0].displayName}{peopleRunningActivity.length > 1 ? '(+' + (peopleRunningActivity.length - 1) + ')' : ''}
              </Typography>
            </Box>
          }
          {!activity.dontAllowDropIns &&
            <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
              <CheckIcon size={16} color={theme.palette.text.secondary} />
              <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                Drop-ins Available
              </Typography>
            </Box>
          }
          <Box display="flex" alignItems="center" style={{ marginBottom: '2px' }}>
            <CostIcon size={16} color={theme.palette.text.secondary} />
            <Typography variant="subtitle1" component="p" color="textPrimary" style={{ color: activityCanceled ? theme.palette.text.secondary : theme.palette.text.primary, margin: '2px 10px 0 5px', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
              {i18n.toCurrency(activity.getProratePrice(isRecurring), { precision: 2, unit: '$' })}{isRecurring && activity.seriesPrice && endDateText !== 'forever' ? (isInPast ? ' prorated' : '') : '/' + ACTIVITY_TEXT}
              {!activity.dontAllowDropIns && endDateText !== 'forever' ? (' or ' + i18n.toCurrency(activity.price, { precision: 2, unit: '$' }) + '/' + ACTIVITY_TEXT) : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* </ActivityCardContent> */}
      {showDetails &&
        <NonExpandedActivityDetailModal
          open={showDetails}
          setOpen={setShowDetails}
          activityId={activity.id}
          companyId={companyId}
          viewStartDate={viewStartDate}
          viewEndDate={viewEndDate}
        />
      }
    </ActivityCardWrapper >
  );
}

export default withTheme(NonExpandedActivityCard);