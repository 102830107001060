import React, { useState } from 'react';
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { NavLink} from "react-router-dom";

import {
  Box,
  Link,
  List as MuiList,
  ListItem,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

// svxvy imports
import { isSvxvySite, getWebUrl, getWebPrefix } from '../../constants/NetworkConstants';
import { compareObjects } from '../../helpers/helperFunctions';



const CompanyBox = styled(Box)`  
  ${(props) => props.theme.breakpoints.up("md")} {
    border-bottom: 1px solid ${(props) => props.theme.sidebar.header.borderColor};
    padding: 0;
  }
`

const CompanyNameDesktopMobile = styled(ListItem)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  padding: 17px 30px 22px 30px;
  font: ${(props) => props.theme.typography.h4};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 17px 15px 22px 15px;
  }
`;

const CompanyNameTablet = styled(ListItem)`
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  border-bottom: 1px solid ${(props) => props.theme.sidebar.header.borderColor};
  padding: 17px 30px 22px 30px;
  font: ${(props) => props.theme.typography.h4};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;


const CompanyDropDownName = styled(ListItem)`
  color: ${(props) => props.theme.sidebar.header.color};
  padding: 11px 15px 12px 15px;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => props.theme.palette.text.secondary} !important;
  margin-top: 3px;
  font-size: 20px !important;
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => props.theme.palette.text.secondary} !important;
  margin-top: 3px;
  font-size: 20px !important;
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;


const CompanyDropdown = props => {
  const { company, loggedInUser, forTablet } = props;
  const companies = useSelector(state => state.company.companies);


  const [isOpen, setIsOpen] = useState(false)
  const isCollapsable = loggedInUser && loggedInUser.additionalProfiles && loggedInUser.additionalProfiles.length > 0 ? true : false;

  const CompanyName = forTablet ? CompanyNameTablet : CompanyNameDesktopMobile;
  // if (loggedInUser)
  //   console.log(loggedInUser.additionalProfiles)
  return (
    <CompanyBox>
      <CompanyName
        // component={NavLink}
        // to={"/" + company.sanitizedName}
        onClick={() => setIsOpen(prevValue => !prevValue)}
      >
        <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
          {company.name}
          <Box>
            {isCollapsable ? (
              isOpen ? (
                <CategoryIconLess />
              ) : (
                <CategoryIconMore />
              )
            ) : null}
          </Box>
        </Box>
      </CompanyName >
      {isOpen &&
        <List style={{ marginTop: '-13px', marginBottom: '14px', padding: 0 }}>
          {loggedInUser && loggedInUser.additionalProfiles.sort(compareObjects('companyName', 'asc')).map((profile, index) => {
            const company = companies.find(x => x.id === profile.companyId);
            return (
              <React.Fragment key={profile.id}>
                {company && <CompanyDropDownName 
                  component={isSvxvySite() ? NavLink : Link}
                  relative
                  href={isSvxvySite()
                    ? "/" + profile.sanitizedCompanyName
                    : company.websiteDomainName ? getWebPrefix() + company.websiteDomainName : getWebUrl() + company.sanitizedName}
                  to={isSvxvySite()
                    ? "/" + profile.sanitizedCompanyName
                    : company.websiteDomainName ? getWebPrefix() + company.websiteDomainName : getWebUrl() + company.sanitizedName}
                    target={isSvxvySite() ? '_blank' : '_blank'}
                >
                  {profile.companyName}
                </CompanyDropDownName>
                }
              </React.Fragment>
            )
          })}
        </List>
      }
    </CompanyBox>
  )
}

export default CompanyDropdown