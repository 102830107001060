import React, { useEffect, useState } from 'react';

// UI imports
import styled from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    Typography as MuiTypography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckboxOutline as CheckedIcon,
    IoSquareOutline as UncheckedIcon
} from "react-icons/io5";


// svxvy import
import ProfileIcon from './ProfileIcon';
import ProfileDetailCard from './ProfileDetailCard';


// UI constants
const Typography = styled(MuiTypography)(spacing);
const ProfileRow = styled(Box)`
    // (spacing)
    background-color: transparent;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 0;
    box-shadow: none !important;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }
`;

// 

const CardContent = styled(Box)`
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

// const UserCheckbox = styled(Checkbox)`
//     color: ${(props) => props.theme.palette.divider};
//     margin-right: 10px;
//     padding: 0;

//     svg {
//         width: 26px;
//         height: 26px;
//     }
// `;

const ProfileCard = (props) => {
    const { person, sendIsClosedUpdate, isChildProfile, notClickable, isSelectable, handleSelected } = props;
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (sendIsClosedUpdate)
            sendIsClosedUpdate();
    }, [detailDialogOpen, sendIsClosedUpdate])

    const handleClick = props => {
        if (isSelectable) {
            setIsSelected(prev => !prev);
            if (handleSelected)
                handleSelected(person);
        }
        else if (notClickable)
            setDetailDialogOpen(false);
        else {
            setDetailDialogOpen(true);
        }
    }

    return (
        <React.Fragment>
            <ProfileRow style={{ cursor: (notClickable ? 'default' : 'cursor') }} onClick={handleClick}>
                <CardContent>
                    <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <ProfileIcon person={person} />
                            <Typography ml={2} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{person.displayName}</Typography>
                        </Box>
                        {isSelectable &&
                            <Box mr={2}>
                                {isSelected && <CheckedIcon size={24} color={theme.palette.iconColor.color} />}
                                {!isSelected && <UncheckedIcon size={24} color={theme.palette.iconColor.color} />}
                            </Box>
                        }
                    </Box>
                </CardContent>
            </ProfileRow>
            {detailDialogOpen &&
                <ProfileDetailCard person={person} open={detailDialogOpen} setOpen={setDetailDialogOpen} isChildProfile={isChildProfile} />
            }
        </React.Fragment>
    );
}

export default ProfileCard;