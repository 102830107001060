import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Card as MuiCard,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";


import { addPaymentMethod } from "../../../store/actions/paymentActions";
import { GUID_Generator } from "../../../helpers/helperFunctions";
import PaymentMethod from "../../../models/PaymentMethod";
import ActionButton from "../../framework/ActionButton";


const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const AddPaymentMethod = props => {
    const theme = useTheme();
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const { companyId, userId, addToTenant } = props;
    const displayedUser = useSelector(state => state.company.people).find(x => x.id === userId);

    const hasAnyExistingDefaultPaymentMethod = displayedUser && displayedUser.paymentMethods
        ? displayedUser.paymentMethods.filter(x => addToTenant ? x.companyId === companyId : x.companyId === null && x.isDefault).length > 0
        : null;

    const handleSubmit = useCallback(async (event) => {
        setError(null);
        setIsSubmitting(true);
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.

        const cardElement = elements.getElement(CardElement);
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                setError(error);
                setIsSubmitting(false);
                return;
            }
            const pm = new PaymentMethod(GUID_Generator(), "stripe", paymentMethod.id, paymentMethod.card.last4, paymentMethod.card.exp_month, paymentMethod.card.exp_year, paymentMethod.billing_details.address.postal_code, companyId, !hasAnyExistingDefaultPaymentMethod, false);
            pm.addToTenant = addToTenant;
            await dispatch(addPaymentMethod(companyId, userId, pm));
            cardElement.clear();
        } catch (ex) {
            setError(ex.message);
        }
        setIsSubmitting(false);
    }, [dispatch, elements, stripe, companyId, userId, hasAnyExistingDefaultPaymentMethod, addToTenant]);

    return (
        <Grid item >
            <Card mb={3} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h3" component="h2" gutterBottom style={{ marginBottom: '20px' }}>
                        Add Card to Profile
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: theme.palette.text.primary,
                                        '::placeholder': {
                                            color: theme.palette.text.primary,
                                        },
                                    },
                                    invalid: {
                                        color: theme.palette.error.main,
                                    },
                                },
                            }}
                        />
                        {error && <Alert mb={4} severity="error">
                            {error.message}
                        </Alert>}
                        <ActionButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            mt={4}
                            submitting={isSubmitting}
                        >
                            Add Card
                        </ActionButton>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default AddPaymentMethod;