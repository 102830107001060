import React from 'react'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { Link as RouterLink } from 'react-router-dom';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoPencilOutline as EditIcon,
    // IoSaveOutline as SaveIcon,
    // IoCloseCircleOutline as CancelIcon,
    // IoTrashOutline as DeleteIcon
} from "react-icons/io5";

import {
    Button,
    Grid,
    Box,
    Link,
    IconButton as MuiIconButton,
    Typography as MuiTypography
} from "@material-ui/core";

// svxvy components

import ParagrpahyTypography from '../framework/ParagraphTypography';

// constants
const IconButton = styled(MuiIconButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ImageTextBlock = styled(Box)`
    background-color: ${(props) => props.theme.palette.background.paper};
    margin-bottom: 30px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: 70%;
        margin-left: 15%;
    }
    
    ${(props) => props.theme.breakpoints.up("md")} {
        width: 100%;
        margin-bottom: 50px;
        margin-left: 0;
    }
`;

const ImageTextBlockReverse = styled(Grid)`
    flex-direction: column-reverse;
    flex-wrap: nowrap;

    ${(props) => props.theme.breakpoints.up("md")} {
        flex-direction: row;
        flex-warp: wrap;
    }
`;

const ImageTextBlockImage = styled(Box)`
    width: 100%;
    padding-top: 66%;

    ${(props) => props.theme.breakpoints.up("md")} {
        height: 100%;
        min-height: 420px;
        padding-top: 0;
    }
`;

const ImageTextBlockContent = styled(Box)`
    padding: 19px 27px 30px 27px;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: 50px 27px 50px 27px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const formatImage = (url, format) => {
    if (!url) return "";
    // const scale = size ? "w_" + size + ",c_scale/" : "/";
    const scale = 'c_thumb,ar_4:3,g_face/';
    const scaleImage = url.replace("/upload", "/upload/" + scale);
    const changeFormat = [
        scaleImage.slice(0, scaleImage.lastIndexOf(".")),
        format,
    ].join("");

    return changeFormat;
};


const LeftRightComponent = (props) => {
    const { id, right, title, description, imageUrl, ctaLink, ctaText, ctaOpenInNewWindow, showEditButton, editAction } = props

    const imageToShow = imageUrl ? formatImage(imageUrl) : null;

    return (
        <React.Fragment key={id}>
            {!right &&
                <ImageTextBlock>
                    <Grid container mb={21}>
                        {imageToShow &&
                            <React.Fragment>
                                <Grid item xs={12} md={5}>
                                    <ImageTextBlockImage style={{ backgroundImage: 'url(' + imageToShow + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}></ImageTextBlockImage>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={false} md={1}></Grid>
                        <Grid item xs={12} md={imageToShow ? 5 : 10}>
                            <ImageTextBlockContent>
                                <Box display='flex' justifyContent='space-between' alignContent='center'>
                                    <Typography component="h2" variant="h2">{title}</Typography>
                                    {showEditButton && editAction &&
                                        <IconButton aria-label="Edit Offering" size="small" color='primary' onClick={editAction}>
                                            <EditIcon size={24} />
                                        </IconButton>
                                    }
                                </Box>
                                <ParagrpahyTypography>{description}</ParagrpahyTypography>
                                {ctaText && ctaLink &&
                                    <React.Fragment>
                                        {!ctaLink.toLowerCase().startsWith('http') &&
                                            <Button variant="outlined"
                                                color="primary"
                                                component={RouterLink}
                                                to={ctaLink}
                                                target={ctaOpenInNewWindow ? '_blank' : '_self'}
                                                referrerPolicy='same-origin'>
                                                {ctaText}
                                            </Button>
                                        }
                                        {ctaLink.toLowerCase().startsWith('http') &&
                                            <Button variant="outlined"
                                                color="primary"
                                                component={Link}
                                                href={ctaLink}
                                                target={ctaOpenInNewWindow ? '_blank' : '_self'}
                                                referrerPolicy='same-origin'>
                                                {ctaText}
                                            </Button>
                                        }
                                    </React.Fragment>
                                }
                            </ImageTextBlockContent>
                            <Grid item xs={false} md={1}></Grid>
                        </Grid>
                    </Grid>
                </ImageTextBlock>
            }
            {
                right &&
                <ImageTextBlock>
                    <ImageTextBlockReverse container mb={21}>
                        <Grid item xs={false} md={1}></Grid>
                        <Grid item xs={12} md={imageToShow ? 5 : 10}>
                            <ImageTextBlockContent>
                                <Box display='flex' justifyContent='space-between' alignContent='center'>
                                    <Typography component="h2" variant="h2">{title}</Typography>
                                    {showEditButton && editAction &&
                                        <IconButton aria-label="Edit Offering" size="small" color='primary' onClick={editAction}>
                                            <EditIcon size={24} />
                                        </IconButton>
                                    }
                                </Box>
                                <ParagrpahyTypography>{description}</ParagrpahyTypography>
                                {ctaText && ctaLink &&
                                    <React.Fragment>
                                        {!ctaLink.toLowerCase().startsWith('http') &&
                                            <Button variant="outlined"
                                                color="primary"
                                                component={RouterLink}
                                                to={ctaLink}
                                                target={ctaOpenInNewWindow ? '_blank' : '_self'}
                                                referrerPolicy='same-origin'>
                                                {ctaText}
                                            </Button>
                                        }
                                        {ctaLink.toLowerCase().startsWith('http') &&
                                            <Button variant="outlined"
                                                color="primary"
                                                component={Link}
                                                href={ctaLink}
                                                target={ctaOpenInNewWindow ? '_blank' : '_self'}
                                                referrerPolicy='same-origin'>
                                                {ctaText}
                                            </Button>
                                        }
                                    </React.Fragment>
                                }
                            </ImageTextBlockContent>
                        </Grid>
                        <Grid item xs={false} md={1}></Grid>
                        {imageToShow &&
                            <Grid item xs={12} md={5}>
                                <ImageTextBlockImage style={{ backgroundImage: 'url(' + imageToShow + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}></ImageTextBlockImage>
                            </Grid>
                        }
                    </ImageTextBlockReverse>
                </ImageTextBlock>
            }
        </React.Fragment >
    );
}

export default LeftRightComponent;
