import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from 'lodash';

import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Element } from "../../framework/RTE/SlateComponents/SlateElement";
import { Leaf } from "../../framework/RTE/SlateComponents/SlateLeaf";

import * as Yup from "yup";
import { Formik } from "formik";


import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoPencilOutline as EditIcon,
    IoSaveOutline as SaveIcon,
    IoCloseCircleOutline as CancelIcon,
    IoTrashOutline as DeleteIcon
} from "react-icons/io5";

import {
    Box,
    Card as MuiCard,
    IconButton as MuiIconButton,
    TextField as MuiTextField,
    CircularProgress as MuiCircularProgress,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy components

import { getRTEValue } from '../../../helpers/rteFunctions';
import { createFaq, editFaq, deleteFaq } from "../../../store/actions/faqActions";
import RTESlateWrapper from '../../framework/RTE/RTESlateWrapper';

// constants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);


export const ItemTypes = {
    CARD: 'card',
}


const validationSchema = Yup.object().shape({
    question: Yup.string()
        .required("Required"),
    // answer: Yup.object
    //     .required("Required"),
});

const FaqCard = (props) => {
    const { id, faq, allowEdit, initialEditState, handleLocalDelete, index, moveCard } = props
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(createEditor()), []);
    const [isEditing, setIsEditing] = useState(initialEditState);

    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [error, setError] = useState(null);

    /********************************* handle DND ****************************/
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    let cardStyle = {}
    
    if (allowEdit && !isEditing) {
        drag(drop(ref))
        cardStyle = { cursor: allowEdit && !isEditing ? 'move' : 'default', opacity }
    }

    /************************************ events *******************************/
    const dispatch = useDispatch();
    const saveCallback = useCallback(async (values) => {
        try {
            setIsSaving(true);
            setError(null);
            if (values.isNew) {
                await dispatch(createFaq(values.companyId, values));
            }
            else {
                await dispatch(editFaq(values.companyId, values.id, values));
            }
            setIsEditing(false)
        } catch (error) {
            setError(error.message);
        }
        setIsSaving(false);
    }, [dispatch]);

    const deleteCallback = useCallback(async (values) => {
        try {
            setIsDeleting(true);
            setError(null);
            await dispatch(deleteFaq(values.companyId, values.id));
        } catch (error) {
            setError(error.message);
            setIsDeleting(false);
        }
    }, [dispatch]);

    /******************************************* Actions *********************************/

    const handleSubmit = (values) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.answer = JSON.stringify(values.answer);
        valuesToSend.sortOrder = faq.sortOrder;

        // console.log(valuesToSend.question)
        // console.log(valuesToSend.answer)
        saveCallback(valuesToSend)
    }

    const handleDelete = () => {
        if (!faq.isNew)
            deleteCallback(faq)
        if (handleLocalDelete) {
            handleLocalDelete(faq);
        }
    }

    return (
        <Card mb={5} ref={(isEditing || !allowEdit) ? null : ref} style={{ ...cardStyle }} data-handler-id={handlerId}>
            <Box px={6} py={4} >
                <Formik
                    initialValues={{
                        id: faq.id,
                        question: faq.question,
                        answer: getRTEValue(faq.answer),
                        companyId: faq.companyId,
                        isNew: faq.isNew
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values,
                        status,
                    }) => (
                        <React.Fragment>
                            {error && <Alert my={2} severity="error">
                                {error}
                            </Alert>}
                            <Box display='flex' flexDirection={isMobile ? 'column-reverse' : 'row'} justifyContent='space-between' alignContent='center'>
                                {!isEditing && <Typography component="h4" variant="h4">{values.question}</Typography>}
                                {isEditing &&
                                    <TextField
                                        name="question"
                                        label="Question"
                                        value={values.question}
                                        error={Boolean(touched.question && errors.question)}
                                        helperText={touched.question && errors.question}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        variant="outlined"
                                        placeholder=""
                                    />
                                }
                                {allowEdit &&
                                    <Box display="flex" mb={isMobile ? 2 : 0} justifyContent='flex-end' >
                                        {!isEditing &&
                                            <IconButton aria-label="Edit FAQ" size="small" color='primary' onClick={() => setIsEditing(true)}>
                                                <EditIcon size={24} />
                                            </IconButton>
                                        }
                                        {isEditing &&
                                            <Box display="flex" flexDirection="row" alignItems='center'>
                                                {!isSaving &&
                                                    <IconButton ml={5}
                                                        aria-label="Save Edit"
                                                        size="small"
                                                        color='primary'
                                                        disabled={isDeleting}
                                                        onClick={handleSubmit}>
                                                        <SaveIcon size={24} />
                                                    </IconButton>
                                                }
                                                {isSaving && <CircularProgress color="primary" size={20} ml={5} />}
                                                <IconButton ml={5}
                                                    aria-label="Cancel Edit"
                                                    size="small"
                                                    color='primary'
                                                    disabled={isSaving || isDeleting}
                                                    onClick={() => setIsEditing(false)}>
                                                    <CancelIcon size={24} />
                                                </IconButton>
                                                {!isDeleting && <IconButton ml={5}
                                                    aria-label="Cancel Edit"
                                                    size="small"
                                                    color='primary'
                                                    disabled={isSaving}
                                                    onClick={handleDelete}>
                                                    <DeleteIcon size={24} />
                                                </IconButton>}
                                                {isDeleting && <CircularProgress color="primary" size={20} ml={5} />}
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                            {!isEditing && <Slate editor={editor} value={values.answer} >
                                <Editable
                                    readOnly
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                />
                            </Slate>}
                            {isEditing &&
                                <RTESlateWrapper
                                    placeholderText='Enter answer here...'
                                    value={values.answer}
                                    setValue={newState => setFieldValue('answer', newState)}
                                />
                            }
                        </React.Fragment>
                    )}
                </Formik>

            </Box>
        </Card>
    );
}

export default FaqCard;
