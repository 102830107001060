import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from "react-router-dom";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  Typography as MuiTypography,
} from "@material-ui/core";

// svxvy components
import { logout } from "../../store/actions/authActions"
import LoginForm from '../../components/company/userManagement/LoginForm';
import RegisterForm from '../../components/company/userManagement/RegisterForm';
import PasswordResetForm from '../../components/company/userManagement/PasswordResetForm';
import RegisterCompanyForm from '../../components/company/registration/RegisterCompanyForm';

// constants
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 30px 0;
  padding: 22px 15px 20px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 22px 30px 20px 30px;
  }
`;


const RegisterPage = props => {

  const loggedInUser = useSelector(state => state.auth.person);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [showCompanyRegistrationForm, setShowCompanyRegistrationForm] = useState(loggedInUser ? true : false);

  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await dispatch(logout());
    setShowLoginForm(false);
    setShowCompanyRegistrationForm(false);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent='center' alignItems='center'>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        {/* {!showLoginForm && !showResetForm && !showCompanyRegistrationForm &&
          <Card>
            <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Get Started</Typography>
            <Typography style={{ margin: '0 0 26px 0', fontSize: '14px', lineHeight: '18px' }}>
              Register a new account or <Typography
                component="span"
                variant='body2'
                color='primary'
                onClick={() => setShowLoginForm(true)}
                ml={1}
                style={{ fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}>Sign In</Typography> with an existing one

            </Typography>
            <RegisterForm
              onSuccess={() => setShowCompanyRegistrationForm(true)}
              submitText='Continue'
            />
          </Card>
        } */}
        {showLoginForm &&
          <Card>
            <Box>
              <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Get Started</Typography>
              <Typography style={{ margin: '0 0 26px 0', fontSize: '14px', lineHeight: '18px' }}>Sign in to use an existing account or
                <Typography
                  component="span"
                  variant='body2'
                  color='primary'
                  onClick={() => setShowLoginForm(false)}
                  ml={1}
                  style={{ fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}>create a new account.</Typography>
              </Typography>
            </Box>
            <LoginForm
              onSuccess={() => { setShowCompanyRegistrationForm(true); setShowLoginForm(false); }}
              additionalComponents={
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={() => { setShowLoginForm(false); setShowResetForm(true); }}
                    color="primary"
                  >
                    <Typography>Forgot your Password?</Typography>
                  </Button>
                </Box>
              }
            />
          </Card>
        }
        {showResetForm &&
          <Card>
            <PasswordResetForm
              onSuccess={() => setShowResetForm(false)}
              additionalComponents={
                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={() => { setShowResetForm(false); setShowLoginForm(true) }}
                    color="primary"
                  >
                    <Typography>Back to Sign In</Typography>
                  </Button>
                </Box>
              }
            />
          </Card>
        }
      </Box>
      <Box display="flex" flexDirection="column" maxWidth={500}>
        {showCompanyRegistrationForm &&
          <Card>
            <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Register Your Business</Typography>
            <Typography style={{ margin: '0 0 26px 0', fontSize: '14px', lineHeight: '18px' }}>
              {loggedInUser && <Typography component="span" variant='body2' mr={1} style={{ fontSize: '14px', lineHeight: '18px' }}>Hi {loggedInUser.displayName}!</Typography>}
              {loggedInUser && loggedInUser.additionalProfiles && loggedInUser.additionalProfiles.length > 1 && <Typography component="span" variant='body2' mr={1}>Thank you for setting up another company with SmartCore.</Typography>}
              Enter information about your business below.  Don't worry, you can change the location information from your dashboard at any time!
            </Typography>
            <RegisterCompanyForm />
          </Card>
        }
        {!showResetForm && loggedInUser && loggedInUser.additionalProfiles && loggedInUser.additionalProfiles.length > 0 &&
          <Card>
            <Typography style={{ margin: 0, fontSize: '14px', lineHeight: '18px' }}>
              Not ready to register your business
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              mt={4}
              p={2}
              to={"/"}
              component={NavLink}
            >
              Go back to business list
            </Button>
          </Card>
        }
        {loggedInUser &&
          <Typography style={{ fontSize: '14px', lineHeight: '18px' }}>
            Not {loggedInUser.displayName} or want to create a new account.
            <Typography
              component="span"
              variant='body2'
              color='primary'
              onClick={handleSignOut}
              ml={1}
              style={{ fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}>Click here.</Typography>
          </Typography>
        }
      </Box>
    </Box>
  );
}

export default RegisterPage;