import React from "react";
import moment from 'moment';

import { useSelector } from 'react-redux'

import { useTheme } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleSharp as SuccessIcon,
    IoCloseCircleSharp as VoidedIcon,
} from "react-icons/io5";

// svxvy import
import { VOIDED } from "../../../models/Payment";
import ParagraphTypography from '../../framework/ParagraphTypography'

// constants


const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const InvoiceCard = (props) => {
    const { purchase } = props;

    const loggedInUser = useSelector(state => state.auth.person);

    let displayedUser = useSelector(state => state.company.people).find(x => x.id === props.purchase.appUserId);
    if (purchase.appUserId === loggedInUser)
        displayedUser = loggedInUser;
    if (!displayedUser)
        displayedUser = loggedInUser.childProfiles.find(x => x.id === purchase.appUserId)

    const theme = useTheme();     

    return (
        <React.Fragment>
            <Card p={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {(!purchase.userPayment || purchase.userPayment.paymentStatus !== VOIDED) &&
                        <React.Fragment>
                            <SuccessIcon color={theme.palette.success.main} size={24} />
                            <Typography
                                style={{ color: theme.palette.text.secondary }}
                                my={2}
                                fontSize={14}>
                                Paid
                            </Typography>
                        </React.Fragment>
                    }
                    {(purchase.userPayment && purchase.userPayment.paymentStatus === VOIDED) &&
                        <React.Fragment>
                            <VoidedIcon color={theme.palette.error.main} size={24} />
                            <Typography style={{ color: theme.palette.text.secondary }}
                                my={2}
                                fontSize={14}>
                                Voided
                            </Typography>
                        </React.Fragment>
                    }
                    {purchase.purchasedProduct && <Typography variant="h3">{purchase.purchasedProduct.name}</Typography>}
                    {purchase.userPayment && purchase.userPayment.activityName && <Typography variant="h3">{purchase.userPayment.activityName} - {moment(purchase.userPayment.activityDate).format('l')}</Typography>}
                    {!purchase.purchasedProduct && (purchase.userPayment && !purchase.userPayment.activityName) && <Typography variant="h3">{purchase.description}</Typography>}
                    <Typography variant="h3" my={2}>${purchase.amountPaid.toFixed(2)}</Typography>
                </Box>

                {displayedUser &&
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography style={{ color: theme.palette.text.secondary }}>Customer</Typography>
                        <Typography>{displayedUser.displayName}</Typography>
                    </Box>
                }
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography style={{ color: theme.palette.text.secondary }}>Payment Date</Typography>
                    <Typography>{moment(purchase.datePurchased).format('l')}</Typography>
                </Box>
                {/* {(!purchase.userPayment || purchase.userPayment.paymentStatus !== VOIDED) &&
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography style={{ color: theme.palette.text.secondary }}>Payment Method</Typography>
                        {purchase.userPayment && <Typography>{purchase.userPayment.paymentType}</Typography>}
                        {!purchase.userPayment && <Typography>Credit Card</Typography>}
                    </Box>} */}
            </Card>
            {purchase.purchasedProduct && purchase.purchasedProduct.messageOnPurchase &&
                <Card mt={4} p={2}>
                    <ParagraphTypography>{purchase.purchasedProduct.messageOnPurchase}</ParagraphTypography>
                </Card>

            }
            {/* {
                purchase.invoiceUrl &&
                <Card mt={4}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        component='a'
                        href={purchase.invoiceUrl}
                        target="_blank"
                        rel="noopener"
                    >
                        View Invoice
                    </Button>
                </Card>
            } */}
        </React.Fragment >
    );
}

export default InvoiceCard;

