import Location, { OperatingHours } from "../../models/Location";

import * as NetworkConstants from '../../constants/NetworkConstants';
import { getFormData } from '../../helpers/imageHelpers';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const ADD_LOCATION = 'ADD_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const UPDATE_LOCATION_IMAGE = 'UPDATE_LOCATION_IMAGE';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const fetchLocations = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/locations/' + companyId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const loadedLocations = [];
        for (const key in resData) {
            const locationHours = [];
            for (const hoursKey in resData[key].hours) {
                locationHours.push(new OperatingHours(resData[key].hours[hoursKey].id, resData[key].hours[hoursKey].rRule, new Date(resData[key].hours[hoursKey].startTime),
                    new Date(resData[key].hours[hoursKey].endTime), resData[key].hours[hoursKey].status));
            }

            loadedLocations.push(new Location(resData[key].id,
                                             resData[key].name,
                                             resData[key].notes, 
                                             resData[key].address, 
                                             resData[key].city, 
                                             resData[key].state, 
                                             resData[key].zip, 
                                             resData[key].companyId, 
                                             locationHours,
                                             resData[key].image, 
                                             resData[key].isDeleted, 
                                             resData[key].lat, 
                                             resData[key].lng, 
                                             resData[key].photo,
                                             resData[key].isVirtual,
                                             resData[key].timezone,
                                             ));
        }
        dispatch({ type: SET_LOCATIONS, locations: loadedLocations });
        return true;
    }
}

export const addLocation = (locationId, locationValues, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!locationId) {
            throw new Error('Location ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/locations/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                locationValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            const message = getErrorMessagesFromResponse(errorResData);
            throw new Error(message);
        }

        const resData = await response.json();
        const locationHours = [];
        for (const hoursKey in resData.hours) {
            locationHours.push(new OperatingHours(resData.hours[hoursKey].id, resData.hours[hoursKey].rRule, new Date(resData.hours[hoursKey].startTime),
                new Date(resData.hours[hoursKey].endTime), resData.hours[hoursKey].status));
        }

        const location = new Location(resData.id,
                                        resData.name, 
                                        resData.notes, 
                                        resData.address, 
                                        resData.city, 
                                        resData.state, 
                                        resData.zip, 
                                        resData.companyId, 
                                        locationHours, 
                                        resData.image, 
                                        resData.isDeleted, 
                                        resData.lat, 
                                        resData.lng, 
                                        resData.photo,
                                        resData.isVirtual,
                                        resData.timezone,
                                        );
        dispatch({ type: ADD_LOCATION, location: location });
    };
};


export const editLocation = (locationId, locationValues, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!locationId) {
            throw new Error('Location ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/locations/' + companyId + '/' + locationId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                locationValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const locationHours = [];
        for (const hoursKey in resData.hours) {
            locationHours.push(new OperatingHours(resData.hours[hoursKey].id, resData.hours[hoursKey].rRule, new Date(resData.hours[hoursKey].startTime),
                new Date(resData.hours[hoursKey].endTime), resData.hours[hoursKey].status));
        }

        const location = new Location(resData.id, 
                                        resData.name, 
                                        resData.notes, 
                                        resData.address, 
                                        resData.city, 
                                        resData.state, 
                                        resData.zip, 
                                        resData.companyId, 
                                        locationHours, 
                                        resData.image, 
                                        resData.isDeleted, 
                                        resData.lat, 
                                        resData.lng, 
                                        resData.photo,
                                        resData.isVirtual,
                                        resData.timezone,);

        dispatch({ type: EDIT_LOCATION, location: location });
    };
};

export const setLocationImage = (file, location) => {
    return async (dispatch, getState) => {
        if (!location && !location.id) {
            throw new Error('Location is null.');
        }

        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/locations/' + location.companyId + '/' + location.id + '/setLocationImage', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: getFormData(file),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        location.image = resData.url;

        dispatch({
            type: EDIT_LOCATION, location: location
        });
    };
};

export const deleteLocation = (locationId, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/locations/' + companyId + '/' + locationId, {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: DELETE_LOCATION, locationId: locationId });
    };
}