import * as Colors from "../../constants/Colors";

import {THEME_SET} from '../actions/themeActions';

const initialState = {
  currentTheme: Colors.SMARTCORE,
  currentTypography: Colors.COMPANY_WEB_TYPOGRAPHY,
  currentCompany: null
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case THEME_SET:
      return {
        ...state,
        currentTheme: actions.theme,
        currentTypography: actions.typography,
        currentCompany: actions.company
      };
    default:
      return state;
  }
}
