import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import * as Yup from "yup";
import { Formik, Field } from "formik";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Card as MuiCard,
    FormControlLabel as MuiFormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypograhy
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import * as NavigationConstants from '../../constants/NavigationConstants';
import { editWebpage } from '../../store/actions/publicWebActions';
import { getRTEValue } from "../../helpers/rteFunctions";
import StyledDialog from "../framework/StyledDialog";
import ActionButton from "../framework/ActionButton";
import UploadPhoto from "../company/photos/UploadPhoto";
import RTESlateWrapper from '../framework/RTE/RTESlateWrapper';

//constants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypograhy)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
    margin: 0;

    span {
        margin: 0;
    }

    .MuiSwitch-root {
        margin-left: -12px;
    }
`;

const validationSchema = Yup.object().shape({
    menuText: Yup.string()
        .max(12, 'Cannot be more than 12 letters'),
});

const EditPageModal = props => {
    const { pageId, webPage, open, setOpen, company } = props;
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [heroImage, setHeroImage] = useState(webPage ? webPage.heroImage : null);
    const [highlightImage, setHighlightImage] = useState(webPage ? webPage.highlightImage : null);

    const dispatch = useDispatch();
    const initialValues = {
        id: webPage ? webPage.id : null,
        pageId: pageId,
        hidePage: webPage ? webPage.hidePage : false,

        menuText: webPage && webPage.menuText ? webPage.menuText : '',
        description: webPage && webPage.description ? webPage.description : '',
        socialImage: null,

        heroImage: null,
        heroTitle: webPage && webPage.heroTitle ? webPage.heroTitle : '',
        heroSubtitle: webPage && webPage.heroSubtitle ? webPage.heroSubtitle : '',

        pageText: webPage ? getRTEValue(webPage.pageText) : getRTEValue(null),
        pageTextBottom: webPage && webPage.pageTextBottom ? getRTEValue(webPage.pageTextBottom) : getRTEValue(null),
        hideContent: webPage ? webPage.hideContent : false,

        highlight: webPage ? webPage.highlight : false,
        highlightImage: null,
        highlightTitle: webPage && webPage.highlightTitle ? webPage.highlightTitle : '',
        highlightSubtitle: webPage && webPage.highlightSubtitle ? webPage.highlightSubtitle : '',
        highlightCTAText: webPage && webPage.highlightCTAText ? webPage.highlightCTAText : '',
    };



    const submitCallback = useCallback(async (companyId, values, setSubmitting, setOpen) => {
        setError(null);
        try {
            setIsSubmitting(true);
            const valuesToSend = cloneDeep(values);
            valuesToSend.pageText = JSON.stringify(values.pageText);
            valuesToSend.pageTextBottom = JSON.stringify(values.pageTextBottom);
            await dispatch(editWebpage(companyId, valuesToSend));
            setIsSubmitting(false)
            if (setOpen)
                setOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values, { setSubmitting }) => {
        values.heroImage = heroImage && webPage && webPage.heroImage && webPage.heroImage.id === heroImage.id ? null : heroImage;
        values.highlightImage = highlightImage && webPage && webPage.highlightImage && webPage.highlightImage.id === highlightImage.id ? null : highlightImage;
        // values.socialImage = socialImage && webPage && webPage.socialImage && webPage.socialImage.id === socialImage.id ? null : socialImage;
        submitCallback(company.id, values, setSubmitting, setOpen);
    };

    return (
        <StyledDialog
            open={open}
            setOpen={setOpen}
            aria-labelledby="act-dialog-title"
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        form="editPageForm"
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        submitting={isSubmitting}
                    >
                        Save
                    </ActionButton>
                </React.Fragment>
            }
        >
            <Box>
                {error && <Alert my={2} severity="error">
                    {error}
                </Alert>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values,
                        status,
                    }) => (
                        <form id="editPageForm" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                {pageId !== NavigationConstants.HOME_ID &&
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Field name='hidePage' component={Switch} onChange={(val) => setFieldValue('hidePage', !values.hidePage)} checked={values.hidePage} />
                                            } label={'Hide this page. Only owners that are logged in will be able to see it.'}></FormControlLabel>
                                        </FormGroup>
                                    </Grid>
                                }
                                {!values.hidePage && <React.Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" mb={2}>Main Fields</Typography>
                                        <Typography mb={2}>Select an image to display on the page</Typography>
                                        <UploadPhoto
                                            id='heroImage'
                                            image={heroImage}
                                            onSuccess={(image) => {
                                                setHeroImage(image);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {pageId !== NavigationConstants.HOME_ID &&
                                            <TextField
                                                name="menuText"
                                                label="Menu Text "
                                                value={values.menuText}
                                                error={Boolean(touched.menuText && errors.menuText)}
                                                helperText={touched.menuText && errors.menuText}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                variant="outlined"
                                                my={2}
                                                placeholder="" />
                                        }
                                        <TextField
                                            name="heroTitle"
                                            label="Main Page Title"
                                            value={values.heroTitle}
                                            error={Boolean(touched.heroTitle && errors.heroTitle)}
                                            helperText={touched.heroTitle && errors.heroTitle}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                        <TextField
                                            name="heroSubtitle"
                                            label="Subtitle"
                                            value={values.heroSubtitle}
                                            error={Boolean(touched.heroSubtitle && errors.heroSubtitle)}
                                            helperText={touched.heroSubtitle && errors.heroSubtitle}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline={true}
                                            maxRows={4}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                        <Card p={2} mb={2}>
                                            <RTESlateWrapper
                                                placeholderText='Enter top of page content here...'
                                                value={values.pageText}
                                                setValue={newState => setFieldValue('pageText', newState)}
                                            />
                                        </Card>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Field name='hideContent' component={Switch} onChange={(val) => setFieldValue('hideContent', !values.hideContent)} checked={values.hideContent} />
                                            } label={'Hide the default content on this page.'} />
                                        </FormGroup>
                                        <Card p={2} mt={2}>
                                            <RTESlateWrapper
                                                placeholderText='Enter bottom of page content here...'
                                                value={values.pageTextBottom}
                                                setValue={newState => setFieldValue('pageTextBottom', newState)}
                                            />
                                        </Card>
                                    </Grid>
                                    {pageId !== NavigationConstants.HOME_ID &&
                                        <Grid item xs={12}>
                                            <Typography variant="h3" mb={2}>Highlight Page Fields</Typography>
                                            <Grid item xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Field name='highlight' component={Switch} onChange={(val) => setFieldValue('highlight', !values.highlight)} checked={values.highlight} />
                                                    } label={'Highlight on home page'}></FormControlLabel>
                                                </FormGroup>
                                            </Grid>
                                            {values.highlight &&
                                                <React.Fragment>
                                                    <Typography mb={2}>Select an image to display the home page</Typography>
                                                    <UploadPhoto
                                                        id='highlightImage'
                                                        image={highlightImage}
                                                        onSuccess={(image) => {
                                                            setHighlightImage(image);
                                                        }}
                                                    />
                                                    {/* <input id="highlightImage"
                                                        name="highlightImage"
                                                        type="file"
                                                        accept="image/*"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            setFieldValue("highlightImage", event.currentTarget.files[0]);
                                                        }}
                                                    /> */}
                                                    <TextField
                                                        name="highlightTitle"
                                                        label="Title on Home Page"
                                                        value={values.highlightTitle}
                                                        error={Boolean(touched.highlightTitle && errors.highlightTitle)}
                                                        helperText={touched.highlightTitle && errors.highlightTitle}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <TextField
                                                        name="highlightSubtitle"
                                                        label="Subtitle on home page"
                                                        value={values.highlightSubtitle}
                                                        error={Boolean(touched.highlightSubtitle && errors.highlightSubtitle)}
                                                        helperText={touched.highlightSubtitle && errors.highlightSubtitle}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        multiline={true}
                                                        maxRows={15}
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                    <TextField
                                                        name="highlightCTAText"
                                                        label="Link Text on home page"
                                                        value={values.highlightCTAText}
                                                        error={Boolean(touched.highlightCTAText && errors.highlightCTAText)}
                                                        helperText={touched.highlightCTAText && errors.highlightCTAText}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        my={2}
                                                        placeholder="" />
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography variant="h3">SEO Fields</Typography>
                                        <TextField
                                            name="description"
                                            label="Page Description for Google Search"
                                            value={values.description}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Typography mb={2}>Select an image to show when this page is shared on social networks</Typography>
                                        <UploadPhoto
                                            id='socialImage'
                                            image={socialImage}
                                            onSuccess={(image) => {
                                                setSocialImage(image);
                                            }}
                                        />
                                    </Grid> */}
                                </React.Fragment>}
                            </Grid>
                        </form>
                    )}
                </Formik>
                {error && <Alert my={2} severity="error">
                    {error}
                </Alert>}
            </Box>
        </StyledDialog >
    );
};

export default EditPageModal;