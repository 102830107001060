import React, { useState } from 'react'
import { useDispatch } from "react-redux";

import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from 'formik'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

/*********************** svxvy import **************************/
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { checkEmailDoesNotExist } from "../../../store/actions/authActions";
import ActionButton from '../../framework/ActionButton';
import LoginForm from './LoginForm';
import PasswordResetForm from './PasswordResetForm';
import RegisterForm from './RegisterForm';
import CompanyLogo from '../../CompanyLogo';

/*********************** class Styles import **************************/
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  width: 100%;
  max-width: 440px;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 30px 0;
  padding: 22px 15px 30px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 22px 30px 30px 30px;
  }
`;


const RegistrationFlow = props => {
    const { company } = props;
    const dispatch = useDispatch();

    const { search } = useLocation();
    const email = new URLSearchParams(search).get('email');
    const showResetFormFromUrl = new URLSearchParams(search).get('showResetForm');

    const [error, setError] = useState(null);
    const [checkedEmail, setCheckedEmail] = useState('');
    const [isUseAuthToken, setIsUseAuthToken] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showCheckEmailForm, setshowCheckEmailForm] = useState(showResetFormFromUrl ? false : true);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showResetForm, setShowResetForm] = useState(showResetFormFromUrl ? true : false);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);

    const passwordResetSuccess = (email) => {
        setCheckedEmail(email);
        setIsUseAuthToken(true);
        setShowResetForm(false);
        setPasswordReset(true);
        setShowLoginForm(true);
    }

    return (
        <React.Fragment>
            {showResetForm &&
                <Card>
                    <PasswordResetForm
                        email={checkedEmail ? checkedEmail : email ? email : ''}
                        onSuccess={(email) => passwordResetSuccess(email)}
                    />
                </Card>
            }
            {showLoginForm &&
                <React.Fragment>
                    <Card>
                        <CompanyLogo company={company} />
                        <Typography variant="h2" component="h2" style={{ marginTop: '23px', marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Sign in</Typography>
                        {!isUseAuthToken && <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>
                            <Typography component="span" variant="body2" style={{ fontSize: '14px', lineHeight: '18px', fontWeight: 700 }} mb={4} mr={1}>{checkedEmail}</Typography>
                            is already registered with SmartCore.  Sign in to Join {company.name} with this account.
                        </Typography>
                        }
                        {isUseAuthToken && checkedEmail && <Typography variant="body2" style={{ margin: '0 0 12px 0', fontSize: '14px', lineHeight: '18px' }}>
                            Use the authention code sent to you in the password field below.
                        </Typography>}
                        <LoginForm email={checkedEmail}
                            company={company}
                            additionalComponents={
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        onClick={() => setShowResetForm(true)}
                                        color="primary"
                                        style={{ margin: '-7px 0 26px 0', padding: 0 }}
                                    >
                                        <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>Forgot your Password?</Typography>
                                    </Button>
                                </Box>}
                        ></LoginForm>
                    </Card>
                    {!passwordReset &&
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography
                                variant="body2"
                                style={{ fontSize: '14px', lineHeight: '18px', textDecoration: 'none', }}>
                                Don't want to use your existing SmartCore account?
                            </Typography>
                            <Typography
                                onClick={() => {
                                    setshowCheckEmailForm(true);
                                    setShowLoginForm(false);
                                    setCheckedEmail(null);
                                    setIsUseAuthToken(false);
                                    setShowRegistrationForm(false);
                                }}
                                variant="body2"
                                color='primary'
                                style={{ margin: '0 0 46px 0', fontSize: '14px', lineHeight: '18px', textDecoration: 'none', cursor: 'pointer' }}>
                                Create a New Account?
                            </Typography>
                        </Box>}
                </React.Fragment>
            }
            {showCheckEmailForm && !showLoginForm &&
                <React.Fragment>
                    <Card>
                        <CompanyLogo company={company} />
                        <Typography variant="h2" component="h2" style={{ marginTop: '23px', marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Join</Typography>
                        <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>Enter your email address and we'll check if you need to create a new SmartCore account.</Typography>
                        <Formik
                            initialValues={{
                                email: email ? email : '',
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    email: Yup.string().email()
                                        .required("Required")
                                })
                            }
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                setIsSubmitting(true);
                                try {
                                    const emailExistDoeNotExist = await dispatch(checkEmailDoesNotExist(values.email));
                                    setCheckedEmail(values.email);
                                    setIsUseAuthToken(false);
                                    if (emailExistDoeNotExist) {
                                        setshowCheckEmailForm(false)
                                        setShowRegistrationForm(true)
                                    }
                                    else
                                        setShowLoginForm(true);
                                    setIsSubmitting(false);
                                } catch (error) {
                                    setError(error.message);
                                    setIsSubmitting(false);
                                }
                            }}
                        >
                            {({
                                errors,
                                handleSubmit,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                touched,
                                values,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    {errors.submit && (
                                        <Alert mt={2} mb={2} severity="warning">
                                            {errors.submit}
                                        </Alert>
                                    )}
                                    <TextField
                                        name="email"
                                        variant='outlined'
                                        label="Email"
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <ActionButton
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        my={3}
                                        submitting={isSubmitting}
                                    >
                                        Continue
                                    </ActionButton>
                                    {error && <Alert mb={4} severity="error">
                                        {error}
                                    </Alert>}
                                </form>
                            )}
                        </Formik>
                    </Card>
                    <Box display="flex" justifyContent="center">
                        <Typography to={NavigationConstants.LOGIN}
                            component={NavLink}
                            variant="body2"
                            color='primary'
                            style={{ marginBottom: '46px', fontSize: '14px', lineHeight: '18px', textDecoration: 'none', }}>
                            Back to Sign In
                        </Typography>
                    </Box>
                </React.Fragment>
            }
            {showRegistrationForm &&
                <React.Fragment>
                    <Card>
                        <CompanyLogo company={company} />
                        <Typography variant="h2" component="h2" style={{ marginTop: '23px', marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Create Account</Typography>
                        <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={4}>{checkedEmail ? 'Email not found. ' : ''}Create a new SmartCore account to join.</Typography>
                        <RegisterForm
                            email={checkedEmail}
                            company={company}></RegisterForm>
                    </Card>
                    <Box display="flex" justifyContent="center">
                        <Typography to={NavigationConstants.LOGIN}
                            component={NavLink}
                            variant="body2"
                            color='primary'
                            style={{ marginBottom: '46px', fontSize: '14px', lineHeight: '18px', textDecoration: 'none', }}>
                            Back to Sign In
                        </Typography>
                    </Box>
                </React.Fragment>
            }
        </React.Fragment >

    )
}

export default RegistrationFlow;