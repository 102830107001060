import Company from "../../models/Company";
import Role from "../../models/Role";
import Purchase from "../../models/Purchase";

import * as NetworkConstants from '../../constants/NetworkConstants';
import * as Colors from "../../constants/Colors";
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';
import { setTheme } from "./themeActions";
import { getFormData } from '../../helpers/imageHelpers';

import Photo from "../../models/Photo";

export const ADD_COMPANY = 'ADD_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_NO_COMPANY = 'SET_NO_COMPANY';
export const SET_ROLES = 'SET_ROLES';
export const SET_COMPANY_SUBSCRIPTION = 'SET_COMPANY_SUBSCRIPTION';
export const UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const SET_PHOTOS = 'SET_PHOTOS';
export const CHANGE_COMPANY_LOGO = 'CHANGE_COMPANY_LOGO';
export const SET_COMPANY_PURCHASES = 'SET_COMPANY_PURCHASES';

export const fetchCompanies = () => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            const message = errorResData.errors && errorResData.errors.Message ? errorResData.errors.Message : 'Something went wrong!';
            throw new Error(message);
        }

        const resData = await response.json();

        dispatch({ type: SET_COMPANIES, companies: resData });
    }
}

export const fetchCompany = (id, isBackground) => {
    return async (dispatch, getState) => {
        if (!id) {
            throw new Error('Company ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/company/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            const message = errorResData.errors && errorResData.errors.Message ? errorResData.errors.Message : 'Something went wrong!';
            if (!isBackground)
                throw new Error(message);
        }

        const resData = await response.json();
        const company = new Company(resData.id, resData.name, resData.description, resData.isProfilePhoneNumberRequired, resData.isProfileNameAliasAllowed, resData.sanitizedName, resData.hideInSharedApp,
            resData.locationText, resData.locationsText,
            resData.employeeText, resData.employeesText,
            resData.activityText, resData.activitiesText,
            resData.customerText, resData.customersText,
            resData.colors, resData.websiteDomainName,
            resData.logo, resData.paymentServicePublicKey,
            resData.companyContactEmail, resData.passOnPaymentServiceFee,
            resData.paymentServiceFeeDescription, resData.hideBilling,
            resData.phoneNumber, resData.allowTexts,
            resData.subscriptionStartDay, resData.freeTrialPeriod, resData.proRateMonth,
            resData.people, resData.webPages, resData.locations, resData.photos, resData.offerings,
            resData.instagramLink, resData.facebookLink, resData.twitterLink,
            resData.vimeoLink, resData.youTubeLink, resData.tickTokLink,
            resData.logoPhoto, resData.products, resData.inactiveProducts,
            resData.allowManualLocations,
            resData.globalReminderMessage, resData.globalReminderHours,
            resData.faqs, resData.googleClientId, resData.googleCalendarAppApproved,
            resData.allowCustomerCancelMembership, resData.cancelTextMembershipText,
            resData.waiverText, resData.embedScripts, resData.manuallySortProducts,
            resData.hideDroppedActivityMessage,
            resData.dropActivityWarningMessage,
            resData.preDeadlineDropActivityMessage,
            resData.postDeadlineDropActivityMessage,
            resData.noDropActivityMessage,
            resData.passwordResetMessage,
            resData.accountRegisterEmailMessage,
            resData.activityRegisterEmailMessage,
            resData.activityDroppedEmailMessage,
            resData.hideFullSelfRegisterActivities,
            resData.usesStripe,
            resData.usesWebsite,
            resData.purchaseNotificationEmails,
            resData.allowedCustomerCount,
            resData.overrideAllowedCustomerCount,
            resData.reviews,
            resData.manualLocationTimezone,
            resData.isRABSite,
        );
        dispatch({
            type: SET_COMPANY, company: company, id: resData.id,
            locationText: resData.locationText, locationsText: resData.locationsText,
            employeeText: resData.employeeText, employeesText: resData.employeesText,
            activityText: resData.activityText, activitiesText: resData.activitiesText,
            customerText: resData.customerText, customersText: resData.customersText,
            colors: resData.colors
        });
        return company;
    }
}

export const editCompanyAttributes = (companyValues, companyId, url) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                companyValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        const company = new Company(resData.id, resData.name, resData.description, resData.isProfilePhoneNumberRequired, resData.isProfileNameAliasAllowed, resData.sanitizedName, resData.hideInSharedApp,
            resData.locationText, resData.locationsText,
            resData.employeeText, resData.employeesText,
            resData.activityText, resData.activitiesText,
            resData.customerText, resData.customersText,
            resData.colors, resData.websiteDomainName,
            resData.logo, resData.paymentServicePublicKey,
            resData.companyContactEmail, resData.passOnPaymentServiceFee,
            resData.paymentServiceFeeDescription, resData.hideBilling,
            resData.phoneNumber, resData.allowTexts,
            resData.subscriptionStartDay, resData.freeTrialPeriod, resData.proRateMonth,
            resData.people, resData.webPages, resData.locations, resData.photos, resData.offerings,
            resData.instagramLink, resData.facebookLink, resData.twitterLink,
            resData.vimeoLink, resData.youTubeLink, resData.tickTokLink,
            resData.logoPhoto, resData.products, resData.inactiveProducts,
            resData.allowManualLocations,
            resData.globalReminderMessage, resData.globalReminderHours,
            resData.faqs, resData.googleClientId, resData.googleCalendarAppApproved,
            resData.allowCustomerCancelMembership, resData.cancelTextMembershipText,
            resData.waiverText, resData.embedScripts, resData.manuallySortProducts,
            resData.hideDroppedActivityMessage,
            resData.dropActivityWarningMessage,
            resData.preDeadlineDropActivityMessage,
            resData.postDeadlineDropActivityMessage,
            resData.noDropActivityMessage,
            resData.passwordResetMessage,
            resData.accountRegisterEmailMessage,
            resData.activityRegisterEmailMessage,
            resData.activityDroppedEmailMessage,
            resData.hideFullSelfRegisterActivities,
            resData.usesStripe,
            resData.usesWebsite,
            resData.purchaseNotificationEmails,
            resData.allowedCustomerCount,
            resData.overrideAllowedCustomerCount,
            resData.reviews,
            resData.manualLocationTimezone,
            resData.isRABSite,
        );
        dispatch({
            type: SET_COMPANY, company: company, id: resData.id,
            locationText: resData.locationText, locationsText: resData.locationsText,
            employeeText: resData.employeeText, employeesText: resData.employeesText,
            activityText: resData.activityText, activitiesText: resData.activitiesText,
            customerText: resData.customerText, customersText: resData.customersText,
            colors: resData.colors
        });
        dispatch({ type: EDIT_COMPANY, company: company });
        dispatch(setTheme(company.colors.useDarkTheme ? Colors.COMPANY_DARK : Colors.COMPANY_LIGHT, company));
    };
};

export const deleteCompany = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        dispatch({ type: DELETE_COMPANY, companyId: companyId })
    }
}

export const uploadPhotoToCompany = (file, isMain, companyId, description) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/uploadPhoto', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: getFormData(file, isMain, description),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        if (isMain) {
            getState().company.company.logo = resData.url;
            dispatch({
                type: UPLOAD_COMPANY_LOGO, logo: resData.url, photo: resData
            });
        } else {
            dispatch({ type: UPLOAD_PHOTO, photo: resData });
        }
    };
};

export const uploadPhotoToGallery = (values, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/addGalleryImage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: UPLOAD_PHOTO, photo: resData });
    };
};

export const deletePhotoFromCompany = (photoID, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        } else if (!photoID) {
            throw new Error('Photo ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/deletePhoto/' + photoID, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        dispatch({ type: DELETE_PHOTO, photoID: photoID })
    }
}

export const changeCompanyLogo = (photoID, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        } else if (!photoID) {
            throw new Error('Photo ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/makeLogo/' + photoID, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        dispatch({ type: UPLOAD_COMPANY_LOGO, photoID: photoID })
    }
}

export const fetchPhotos = (companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/' + companyId + '/photos', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            }
        });
        if (!response.ok) {
            const errorResData = response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData))
        }
        const resData = await response.json();
        const photos = [];
        //let logo = null;
        for (const key in resData) {
            let photo = new Photo(
                resData[key].id,
                resData[key].url,
                resData[key].isMain,
                resData[key].description
            );
            //if(photo.isMain) logo = photo.url;
            photos.push(photo);
        }
        dispatch({ type: SET_PHOTOS, photos: photos });
    }
}

export const fetchRoles = (id) => {
    return async (dispatch, getState) => {
        if (!id) {
            throw new Error('Company ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/roles/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();

        const loadedRoles = [];
        for (const key in resData) {
            loadedRoles.push(new Role(resData[key].id, resData[key].name, resData[key].description, resData[key].companId, resData[key].isRoleForNewUsers, resData[key].isEmployeeRole, resData[key].claims));
        }
        dispatch({ type: SET_ROLES, roles: loadedRoles });
    }
}
export const submitContactForm = (formValues, companyId) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/company/submitcontactform/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                formValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

    };
};

/****************************************************** subsription actions ******************************************************/

export const fetchActiveSubscription = (id) => {
    return async (dispatch, getState) => {
        if (!id) {
            throw new Error('Company ID is blank.');
        }
        const response = await fetch(NetworkConstants.SERVER + '/api/company/activesubscription/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            // body: JSON.stringify(
            //     {
            //         companyId: getState().company.companyId
            //     }
            // ),
        });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        if (resData) {
            const purchase = new Purchase(resData.id,
                resData.purchasedProduct,
                resData.datePurchased,
                resData.startDate,
                resData.expirationDate,
                resData.automaticallyRenew,
                resData.quantity,
                resData.subscriptionId,
                resData.invoiceId,
                resData.paymentChargeId,
                resData.amountPaid,
                resData.isTentantPurchase,
                resData.description,
                resData.memo,
                resData.wasMarkedUnpaid,
                resData.wasRefunded,
                resData.purchasedProductId,
                resData.userPayment,
                resData.nextAmountDue,
                resData.invoiceUrl,
                resData.invoicePdf,
                resData.companyId,
                resData.appUserId,
                resData.units,
                resData.tax,
                resData.status,
                resData.totalDiscount
            );
            dispatch({
                type: SET_COMPANY_SUBSCRIPTION, subscription: purchase
            });
        }
    }
}

export const createSvxvySubscription = (paymentMethodId, productId, coupon) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company/createSvxvySubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    paymentMethodId: paymentMethodId,
                    productId: productId,
                    coupon: coupon,
                    companyId: getState().company.companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        return purchase;
        // dispatch({ type: SET_COMPANY_SUBSCRIPTION, subscription: purchase });
    }
}

export const finalizeSvxvySubscription = (paymentMethodId, subscriptionId) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company/finalizeSvxvySubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    paymentMethodId: paymentMethodId,
                    subscriptionId: subscriptionId,
                    companyId: getState().company.companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        await dispatch({ type: SET_COMPANY_SUBSCRIPTION, subscription: purchase });
        await dispatch(fetchCompany(getState().company.companyId));
    }
}


export const cancelSvxvySubscription = (purchaseId, subscriptionId) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company/cancelSvxvySubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    purchaseId: purchaseId,
                    subscriptionId: subscriptionId,
                    companyId: getState().company.companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        if (purchaseId)
            dispatch({ type: SET_COMPANY_SUBSCRIPTION, subscription: purchase });
    }
}

export const reactivateSvxvySubscription = (purchaseId) => {
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/company/reactivateSvxvySubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {
                    purchaseId: purchaseId,
                    companyId: getState().company.companyId
                }
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchase = new Purchase(resData.id,
            resData.purchasedProduct,
            resData.datePurchased,
            resData.startDate,
            resData.expirationDate,
            resData.automaticallyRenew,
            resData.quantity,
            resData.subscriptionId,
            resData.invoiceId,
            resData.paymentChargeId,
            resData.amountPaid,
            resData.isTentantPurchase,
            resData.description,
            resData.memo,
            resData.wasMarkedUnpaid,
            resData.wasRefunded,
            resData.purchasedProductId,
            resData.userPayment,
            resData.nextAmountDue,
            resData.invoiceUrl,
            resData.invoicePdf,
            resData.companyId,
            resData.appUserId,
            resData.units,
            resData.tax,
            resData.status,
            resData.totalDiscount
        );
        dispatch({ type: SET_COMPANY_SUBSCRIPTION, subscription: purchase });
    }
}


/******************************************************* configure Stripe account **************************************/
export const setCompanyPaymentService = (values, companyId) => {
    if (!companyId) {
        throw new Error('Company ID is blank.');
    }
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/setcompanypaymentservice/' + companyId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        await dispatch(fetchCompany(companyId));
    };
}

export const synchPaymentService = (companyId) => {
    if (!companyId) {
        throw new Error('Company ID is blank.');
    }
    return async (dispatch, getState) => {
        const response = await fetch(NetworkConstants.SERVER + '/api/payment/synchpaymentservice/' + companyId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                {}
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        await dispatch(fetchCompany(companyId));
    };
}



/****************************************************** Purcase actions ******************************************************/

export const fetchCompanyPurchases = (companyId) => {
    return async (dispatch, getState) => {
        let queryUrl = NetworkConstants.SERVER + '/api/company/listcompanypurchases/' + companyId;
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
        const resData = await response.json();
        const purchases = [];
        for (const key in resData) {
            purchases.push(
                new Purchase(resData[key].id,
                    resData[key].purchasedProduct,
                    resData[key].datePurchased,
                    resData[key].startDate,
                    resData[key].expirationDate,
                    resData[key].automaticallyRenew,
                    resData[key].quantity,
                    resData[key].subscriptionId,
                    resData[key].invoiceId,
                    resData[key].paymentChargeId,
                    resData[key].amountPaid,
                    resData[key].isTentantPurchase,
                    resData[key].description,
                    resData[key].memo,
                    resData[key].wasMarkedUnpaid,
                    resData[key].wasRefunded,
                    resData[key].purchasedProductId,
                    resData[key].userPayment,
                    resData[key].nextAmountDue,
                    resData[key].invoiceUrl,
                    resData[key].invoicePdf,
                    resData[key].companyId,
                    resData[key].appUserId,
                    resData[key].units,
                    resData[key].tax,
                    resData[key].status,
                    resData[key].totalDiscount
                ))
        }

        dispatch({ type: SET_COMPANY_PURCHASES, purchases: purchases });
    };
}

