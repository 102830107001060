import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { withStyles, useTheme } from '@material-ui/core/styles';
// UI Imports
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Card as MuiCard,
    CircularProgress as MuiCircularProgress,
    Divider as MuiDivider,
    Hidden,
    IconButton as MuiIconButton,
    Menu,
    MenuItem as MuiMenuItem,
    Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as MuiAddCircle,
    IoRemoveCircleOutline as MuiMinusCircle,
    IoEllipsisHorizontal as Ellipsis,
    IoCloseCircleOutline as CancelMenuIcon,
    IoRefreshCircleOutline as RestoreIcon,
    IoTrash as DeleteIcon,
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { getDateHeading } from './activityHelperFunctions';
import { isToday, isTomorrow, checkClaim, getMilliseconds } from '../../../helpers/helperFunctions';
import { cancelActivity, deleteActivity } from '../../../store/actions/activityActions';
import ActivityCard from './ActivityCard';
import ErrorDialog from '../../framework/ErrorDialog';

// UI Consts
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 10px;

    ${(props) => props.theme.breakpoints.up("lg")} {
        padding: 0 0 0 15px;
    }
`;

const AGroupHeading = styled(Box)`
    margin: 3px 0 6px 0;
    padding: 0 30px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        padding: 2px 15px 0 15px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 3px 0 12px 0;
    }
`;

const AGroupWrapper = styled(Box)`
    margin: 0 0 20px 0;
    padding: 0 15px;

    ${(props) => props.theme.breakpoints.up("sm")} {
        padding: 0;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 0 10px 0;

        &:last-child {
            margin: 0 0 30px 0;
        }
    }
`;

const AGroupDay = styled(Box)`
    margin: 0 0 15px 0;
    padding: 0 15px;
`;

const CardContent = styled(Box)`
    ${(props) => props.theme.breakpoints.up("lg")} {
        border-left: 1px solid ${(props) => props.theme.palette.divider};
        padding-left: 20px;
    }
`;

const IconButton = styled(MuiIconButton)`
    padding: 0;

    &:hover {
        background-color: transparent;
        
        svg {
            fill: ${(props) => props.theme.palette.primary.main};
        }
    }
`;

const MenuItem = styled(MuiMenuItem)`
  margin: 0;
  padding: 8px 15px 9px 15px;
  font-size: 1.4rem;
`;

const ICON_SIZE = 24;

const StyledMenu = withStyles((theme) => ({
    paper: {
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 0 7px 0',
        left: '0',
    }
}))(((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
)));

const ActivityDayGroup = props => {
    const { theme, activities, dayHeading, startDate, endDate, allowAddEdit, companyId } = props;
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);

    const [anchorMenu, setAnchorMenu] = useState(null);
    const [error, setError] = useState(null);
    const [isMenuActionRunning, setIsMenuActionRunning] = useState(false);

    const showMenu = dayHeading > new Date() || isToday(dayHeading);

    const dayLabelText = isToday(dayHeading)
        ? "Today's Future"
        : isTomorrow(dayHeading) ? "Tomorrow's" : moment(dayHeading).format('ddd, MMM DD');
    const alertText = isToday(dayHeading)
        ? "today"
        : isTomorrow(dayHeading) ? "tomorrow" : moment(dayHeading).format('ddd, MMM DD');


    const dispatch = useDispatch();
    const cancelHandler = useCallback(async (status, isDelete) => {
        try {
            setError(null)
            setIsMenuActionRunning(true);
            for (const a in activities) {
                const activity = activities[a];

                const durationInMilliSeconds = getMilliseconds(activity.duration, 'minutes');
                const occuranceIsInPast = Date.now() >= activity.displaybleStartDateTime.getTime() + durationInMilliSeconds;
                if (!occuranceIsInPast) {
                    if (isDelete) {
                        const activityIdToSend = activity.id.indexOf('_') >= 0 ? activity.id.split('_')[0] : activity.id;
                        const occuranceId = activity.id.indexOf('_') >= 0 ? activity.id.split('_')[1] : 0;
                        await dispatch(deleteActivity(activityIdToSend, occuranceId, companyId, startDate, endDate));
                    }
                    else {
                        await dispatch(cancelActivity(companyId, activity.id, status, startDate, endDate));
                    }
                }
            }
        } catch (err) {
            setError(err.message);
        }
        setIsMenuActionRunning(false);
    }, [dispatch, companyId, activities, startDate, endDate])

    const handleStatusChange = (status, isDelete) => {
        let message = "";
        if (isDelete)
            message = "Are you sure you want to delete all " + ACTIVITIES_TEXT.toLowerCase() + " for " + alertText + "? This cannot be undone, users registered for these classes will not be notified."
        else if (status === 'canceled')
            message = "Are you sure you want to cancel all " + ACTIVITIES_TEXT.toLowerCase() + " for " + alertText + "?";
        else
            message = "Are you sure you want to reschedule all " + ACTIVITIES_TEXT.toLowerCase() + " for " + alertText + "?";

        const r = window.confirm(message);
        if (!r) {
            return;
        }

        cancelHandler(status, isDelete);
    }


    return (
        <React.Fragment>
            <AGroupWrapper>
                <Hidden lgUp>
                    <Box display="flex" flexDirection="row" alignItems="baseline" justifyContent="space-between">
                        <AGroupDay display="flex" flexDirection="row" alignItems="baseline">
                            {isToday(dayHeading) && <Typography component="h3" variant="h4" style={{ margin: '0 5px 0 0' }}> Today</Typography>}
                            {isTomorrow(dayHeading) && <Typography component="h3" variant="h4" style={{ margin: '0 5px 0 0' }}>Tomorrow</Typography>}
                            <Typography style={{ margin: '0' }}>{moment(dayHeading).format('ddd, MMM DD')}</Typography>
                        </AGroupDay>
                        {allowAddEdit && showMenu &&
                            <AGroupDay display="flex" flexDirection="row" alignItems="baseline">
                                {!isMenuActionRunning &&
                                    <React.Fragment>
                                        <IconButton onClick={event => setAnchorMenu(event.currentTarget)}>
                                            <Ellipsis color={theme.palette.iconColor.color} size="24" />
                                        </IconButton>

                                    </React.Fragment>
                                }
                                {isMenuActionRunning &&
                                    <CircularProgress color="primary" size={24} />
                                }
                            </AGroupDay>
                        }
                    </Box>
                </Hidden>
                <Card my={2}>
                    <Box display="flex" flex={1}>
                        <Hidden mdDown>
                            <Box display="flex" flexDirection="row" alignItems="baseline" justifyContent="space-between" minWidth={184}>

                                <Box width={130} style={{ padding: '15px 0' }}>
                                    {isToday(dayHeading) && <Typography component="h3" variant="h4" style={{ margin: '0 0 3px 0', fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.8rem' }}>Today</Typography>}
                                    {isTomorrow(dayHeading) && <Typography component="h3" variant="h4" style={{ margin: '0 0 3px 0', fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.8rem' }}>Tomorrow</Typography>}
                                    <Typography style={{ color: theme.palette.text.secondary, margin: '0', fontSize: '1.4rem', lineHeight: '1.8rem' }}>{moment(dayHeading).format('ddd, MMM DD')}</Typography>
                                </Box>
                                {allowAddEdit && showMenu &&
                                    <AGroupDay display="flex" flexDirection="row" alignItems="baseline">
                                        {!isMenuActionRunning &&
                                            <React.Fragment>
                                                <IconButton onClick={event => setAnchorMenu(event.currentTarget)}>
                                                    <Ellipsis color={theme.palette.iconColor.color} size="24" />
                                                </IconButton>
                                            </React.Fragment>
                                        }
                                        {isMenuActionRunning &&
                                            <CircularProgress color="primary" size={24} />
                                        }
                                    </AGroupDay>
                                }
                            </Box>
                        </Hidden>
                        <CardContent display="flex" flex={1} flexDirection="column">
                            {activities.map((activity, index) => {
                                return (
                                    <React.Fragment key={activity.id} >
                                        <ActivityCard activity={activity}
                                            viewStartDate={startDate}
                                            viewEndDate={endDate} />
                                        {activities.length !== (index + 1) && <Divider />}
                                    </React.Fragment>
                                )
                            })}
                        </CardContent>
                    </Box>
                </Card>
            </AGroupWrapper>
            {!isMenuActionRunning &&
                <StyledMenu
                    id="menu-appbar"
                    anchorEl={anchorMenu}
                    open={Boolean(anchorMenu)}
                    onClose={() => setAnchorMenu(null)}
                    onClick={() => setAnchorMenu(null)}
                >
                    <Box display="flex" flexDirection="column">
                        <MenuItem
                            onClick={() => handleStatusChange('canceled', false)}
                        >

                            <CancelMenuIcon
                                size={ICON_SIZE}
                                style={{ color: theme.palette.error.main, marginRight: '10px' }}

                            />
                            Cancel {dayLabelText} {ACTIVITIES_TEXT}
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleStatusChange('active', false)}
                        >
                            <RestoreIcon
                                size={ICON_SIZE}
                                style={{ color: theme.palette.success.main, marginRight: '10px' }} />

                            Reschedule {dayLabelText} {ACTIVITIES_TEXT}
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleStatusChange(null, true)}
                        >
                            <DeleteIcon
                                size={ICON_SIZE}
                                style={{ color: theme.palette.error.main, marginRight: '10px' }} />

                            Delete {dayLabelText} {ACTIVITIES_TEXT}
                        </MenuItem>
                    </Box>
                </StyledMenu>
            }
            {
                error &&
                <ErrorDialog message={error} setMessage={setError} />

            }
        </React.Fragment>
    )
}


const ActivityGroup = props => {
    const theme = useTheme();

    const { heading, headingIsDate, activitiesByDay, openByDefault, startDate, endDate } = props;
    const [showDetails, setShowDetails] = useState(openByDefault);
    const headingToShow = headingIsDate ? getDateHeading(heading, true) : heading;
    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);
    const companyId = useSelector(state => state.company.companyId);
    return (
        <React.Fragment>
            <Box>
                <Divider my={2} />
                <AGroupHeading display="flex" justifyContent="space-between" alignItems="center" onClick={() => setShowDetails(prev => !prev)}>
                    <Typography variant="body1" component="h2" style={{ color: theme.palette.text.secondary, margin: 0, fontWeight: '700' }}>{headingToShow}</Typography>
                    {!showDetails && <MuiAddCircle size={24} style={{ color: theme.palette.iconColor.color }} />}
                    {showDetails && <MuiMinusCircle size={24} style={{ color: theme.palette.iconColor.color }} />}
                </AGroupHeading>
                {showDetails &&
                    activitiesByDay.map((item, ind) => {
                        return (
                            <React.Fragment key={item.key}>
                                <ActivityDayGroup
                                    theme={theme}
                                    activities={item.activities}
                                    dayHeading={item.dayHeading}
                                    allowAddEdit={allowAddEdit}
                                    startDate={startDate}
                                    endDate={endDate}
                                    companyId={companyId}
                                />
                            </React.Fragment>

                        )
                    })
                }
            </Box>
        </React.Fragment>
    );
}

export default ActivityGroup;