import React from "react";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet-async";
import { withTheme } from "styled-components/macro";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { Box, Grid as MuiGrid } from "@material-ui/core";

// svxvy import
import ImagesGallery from "../../../components/company/photos/ImagesGallery";


//constants
const Grid = styled(MuiGrid)(spacing);

const GalleryPage = (props) => {
  const photos = useSelector((state) => state.company.photos).filter(
    (x) => !x.isMain
  );
  return (
    <React.Fragment>
      <Helmet title="Gallery" />
      <Box mb={12}>
        <Grid container>
          <Grid item xs={12}>
            <ImagesGallery photos={photos}></ImagesGallery>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default withTheme(GalleryPage);
