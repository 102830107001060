import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { Formik } from "formik"
import * as Yup from "yup";
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    CardContent,
    Card as MuiCard,
    Divider as MuiDivider,
    FormGroup,
    FormControlLabel,
    Grid as MuiGrid,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypgraphy,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleOutline as SaveIcon,
    IoTrash as DeleteIcon,
} from "react-icons/io5";

// svxvy setting

import { editCompanyAttributes, deleteCompany, SET_NO_COMPANY } from '../../../store/actions/companyActions';
import { setTheme } from "../../../store/actions/themeActions";
import { isSVXVYAdmin } from "../../../constants/SecurityConstants";
import { isSvxvySite } from '../../../constants/NetworkConstants';
import * as Colors from "../../../constants/Colors";
import ActionButton from "../../framework/ActionButton";
import { isLiveSite } from "../../../constants/NetworkConstants";

//constant
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypgraphy)(spacing);

const BrandingHeaderSave = styled(Box)`
    display: none;

    ${(props) => props.theme.breakpoints.up("sm")} {
        display: flex;
        flex-direction: column-reverse
    }
`;

const ActionButtonBottom = styled(ActionButton)`
    width: 100%;
    margin: 0;

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: auto;
        margin: 0 0 0 auto;
    }
`;


const TextFieldResponsive = styled(TextField)`
    margin-bottom: 5px !important;

    ${(props) => props.theme.breakpoints.up("sm")} {
        margin-bottom: 13px !important;
    }
`;

const validationSchema = Yup.object().shape({
    allowedCustomerCount: Yup.number().integer("Must be a whole number.").min(0, "Spaces must be greater than 0.").nullable(true),

});

const CompanyAdvancedSettingsForm = props => {
    const theme = useTheme();
    const history = useHistory();
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const { company } = props;

    const [wasSaveSuccesful, setWasSaveSuccesful] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState();
    const [isDeleting, setIsDeleting] = useState(false);

    const loggedInUser = useSelector(state => state.auth.person)

    const initialValues = {
        websiteDomainName: company.websiteDomainName ? company.websiteDomainName : "",
        allowTexts: company.allowTexts,
        googleClientId: company.googleClientId ? company.googleClientId : "",
        googleClientSecret: company.googleClientSecret ? company.googleClientSecret : "",
        googleCalendarAppApproved: company.googleCalendarAppApproved,
        allowedCustomerCount: company.allowedCustomerCount + "",
        overrideAllowedCustomerCount: company.overrideAllowedCustomerCount,
        isRABSite: company.isRABSite,
    };

    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values, handleOnSuccess) => {
        setError(null);
        setIsSubmitting(true);
        setWasSaveSuccesful(false);
        try {
            await dispatch(editCompanyAttributes(values, companyId, '/advanced'));
            setIsSubmitting(false);
            setWasSaveSuccesful(true);
            if (handleOnSuccess)
                handleOnSuccess();
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values) => {
        submitCallback(props.company.id, values, props.onSuccess);
    };

    const deleteCompanyHandler = useCallback(async (companyId) => {
        try {
            setIsDeleting(true);
            setError(null);
            await dispatch(deleteCompany(companyId));
            await dispatch({ type: SET_NO_COMPANY });
            await dispatch(setTheme(Colors.SMARTCORE, Colors.COMPANY_WEB_TYPOGRAPHY));
            history.replace("/");
        } catch (err) {
            setIsDeleting(false);
            setError(err.message);
        }
    }, [dispatch, history]);

    const handleDelete = () => {
        const r = window.confirm('Are you sure you want to delete this company?');
        if (r) {
            deleteCompanyHandler(props.company.id);
        }
    }


    return (
        <Box>
            <Box>
                <Box display="flex" justifyContent="space-between" mb={4} >
                    <Box display="flex" flex={1}>
                        <Typography variant="h1" style={{ lineHeight: '40px' }}>Advanced Company Settings</Typography>
                    </Box>
                    <BrandingHeaderSave display="flex" flexDirection="column-reverse">
                        <ActionButton
                            form="companySettings"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            startIcon={<SaveIcon />}
                            submitting={isSubmitting}
                        // disabled={
                        //     (
                        //         contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) < 2
                        //     )
                        // }
                        >Save Settings</ActionButton>
                    </BrandingHeaderSave>
                </Box>
                {error && <Alert mb={4} severity="error">
                    {error}
                </Alert>}
                {wasSaveSuccesful && props.successMessage &&
                    <Alert mb={4} severity="success">
                        {props.successMessage}
                    </Alert>
                }
            </Box>
            {!isSVXVYAdmin(loggedInUser.id) &&
                <Box>
                    <Alert mb={4} severity="warning">
                        To change this information contact support@smartcore.life.  As changes here can break features on your website.
                    </Alert>
                </Box>
            }
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                    setFieldValue,
                }) => (
                    <Card mb={6} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                        <CardContent style={{ padding: '25px 30px 20px 30px' }}>
                            <form id="companySettings" onSubmit={handleSubmit}>
                                <Typography variant="h3" component="h3" mb={2}>Domain</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldResponsive
                                            name="websiteDomainName"
                                            label="Custom Domain Name"
                                            value={values.websiteDomainName}
                                            disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                            fullWidth
                                            error={Boolean(touched.websiteDomainName && errors.websiteDomainName)}
                                            helperText={touched.websiteDomainName && errors.websiteDomainName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box display="flex" alignItems="center" height="100%" ml={4}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Switch
                                                        name='allowTexts'
                                                        disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                                        onChange={(val) => setFieldValue('allowTexts', !values.allowTexts)}
                                                        checked={values.allowTexts} />
                                                } label={'Enable sending text messages'} />
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box display="flex" alignItems="center" height="100%" ml={4}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Switch
                                                        name='isRABSite'
                                                        disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                                        onChange={(val) => setFieldValue('isRABSite', !values.isRABSite)}
                                                        checked={values.isRABSite} />
                                                } label={'Is RAB Site'} />
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                <Typography variant="h3" component="h3" mb={2}>Google Settings</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldResponsive
                                            name="googleClientId"
                                            label="Google Console Client ID"
                                            value={values.googleClientId}
                                            disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                            fullWidth
                                            error={Boolean(touched.googleClientId && errors.googleClientId)}
                                            helperText={touched.googleClientId && errors.googleClientId}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldResponsive
                                            name="googleClientSecret"
                                            label="Google Console Client Secret"
                                            value={values.googleClientSecret}
                                            disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                            fullWidth
                                            error={Boolean(touched.googleClientSecret && errors.googleClientSecret)}
                                            helperText={touched.googleClientSecret && errors.googleClientSecret}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Box ml={4}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Switch
                                                        name='googleCalendarAppApproved'
                                                        disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                                        onChange={(val) => setFieldValue('googleCalendarAppApproved', !values.googleCalendarAppApproved)}
                                                        checked={values.googleCalendarAppApproved} />
                                                } label={'Calendar App Approved'} />
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <TextFieldResponsive
                                            name="companyContactEmail"
                                            label="GA"
                                            value={values.companyContactEmail}
                                            fullWidth
                                            error={Boolean(touched.companyContactEmail && errors.companyContactEmail)}
                                            helperText={touched.companyContactEmail && errors.companyContactEmail}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid> */}
                                </Grid>
                                <Divider mt={3} mb={5} style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                <Typography variant="h3" component="h3" mb={2}>Allowed {CUSTOMERS_TEXT}</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldResponsive
                                            name="allowedCustomerCount"
                                            label={"Allowed " + CUSTOMERS_TEXT}
                                            value={values.allowedCustomerCount}
                                            disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                            fullWidth
                                            error={Boolean(touched.allowedCustomerCount && errors.allowedCustomerCount)}
                                            helperText={touched.allowedCustomerCount && errors.allowedCustomerCount}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            placeholder="" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" alignItems="center" height="100%" ml={4}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Switch
                                                        name='overrideAllowedCustomerCount'
                                                        disabled={!isSVXVYAdmin(loggedInUser.id) && isLiveSite()}
                                                        onChange={(val) => setFieldValue('overrideAllowedCustomerCount', !values.overrideAllowedCustomerCount)}
                                                        checked={values.overrideAllowedCustomerCount} />
                                                } label={'Override Allowed'} />
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                )
                }
            </Formik >
            <Box>
                {error && <Alert mb={4} severity="error">
                    {error}
                </Alert>}
                {wasSaveSuccesful && props.successMessage && <Alert mb={4} severity="success">
                    {props.successMessage}
                </Alert>}
                <Box display="flex" justifyContent="space-between" mb={2} style={{ width: '100%' }}>
                    {isSvxvySite() &&
                        // isSVXVYAdmin(loggedInUser.id) &&
                        <ActionButton
                            submitting={isDeleting}
                            variant="text"
                            onClick={handleDelete}
                            disabled={isSubmitting}
                            startIcon={<DeleteIcon size={24} style={{ color: theme.palette.error.main }} />}
                        >
                            Delete Company
                        </ActionButton>
                    }
                    <ActionButtonBottom
                        form="companySettings"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        startIcon={<SaveIcon />}
                        mt={2}
                        submitting={isSubmitting}
                    >Save Settings</ActionButtonBottom>
                </Box>
            </Box>
        </Box >
    )
};

export default CompanyAdvancedSettingsForm;

