import * as NetworkConstants from '../../constants/NetworkConstants';
import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

export const FAQ_CREATED = 'FAQ_CREATED';
export const FAQ_EDITED = 'FAQ_EDITED';
export const FAQ_DELETED = 'FAQ_DELETED';

/*********************************** Offering Actions ******************************/
export const createFaq = (companyId, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        // any asych code you want - like service call
        const response = await fetch(NetworkConstants.SERVER + '/api/faq/' + companyId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: FAQ_CREATED, faq: resData });
    };
};

export const editFaq= (companyId, id, values) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/faq/' + companyId + "/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                values
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        dispatch({ type: FAQ_EDITED, faq: resData });
    };
};

export const deleteFaq= (companyId, id, formData) => {
    return async (dispatch, getState) => {
        if (!companyId) {
            throw new Error('Company ID is blank.');
        }
        if (!id) {
            throw new Error('ID is blank.');
        } const response = await fetch(NetworkConstants.SERVER + '/api/faq/' + companyId + "/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + getState().auth.token
            },
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        dispatch({ type: FAQ_DELETED, faq: id });
    };
};
