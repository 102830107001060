import React, { useState } from 'react';
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { useTheme } from '@material-ui/core/styles';

import { NavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  Box as MuiBox,
  Chip,
  CircularProgress as MuiCircularProgress,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography as MuiTypography
} from "@material-ui/core";

import { companySidebarRoutes as routes } from "../../routes/indexSvxvy";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

// svxvy imports
import * as NavigationConstants from '../../constants/NavigationConstants';
import { showMenuItem, replaceDisplayName } from '../../helpers/navigationFunctions';
import { isSvxvySite } from '../../constants/NetworkConstants';
import CompanyDropdown from './CompanyDropdown';
import CompanySidebarFooter from './CompanySidebarFooter';

// style constants

const FONT_INCREASE = 3;

const Box = styled(MuiBox)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-top: 52px;
    .MuiBackdrop-root {
        margin-top: 52px;
    }
  }

  > div {
    1px solid ${(props) => props.theme.sidebar.borderColor};
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-top: 1px solid ${(props) => props.theme.sidebar.borderColor};
  padding-top: ${(props) => props.theme.spacing(2)}px;
  
  ${(props) => props.theme.breakpoints.up("sm")} {
    border: none;
    padding-top: ${(props) => props.theme.spacing(2)}px;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 0;
  }
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(0.7)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const Category = styled(ListItem)`
  width: 100%;
  height: 47px;
  padding: ${(props) => props.theme.spacing(2.3)}px ${(props) => props.theme.spacing(6)}px !important;
  justify-content: flex-start;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  
  svg {
    color: ${(props) => props.theme.sidebar.category.svg.color};
    font-size: 24px;
  }

  &:hover {
    background-color: transparent;
  }

  &.${(props) => props.activeClassName} {
    svg {
      color: ${(props) => props.theme.sidebar.category.svg.colorActive};
    }

    span {
      color: ${(props) => props.theme.sidebar.category.svg.colorActive};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;

  span {
    color: ${(props) => props.theme.sidebar.color};
    padding: 4px 0 0 ${(props) => props.theme.spacing(2.5)}px;
      font: ${(props) => props.theme.typography.body1};
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => props.theme.palette.text.secondary} !important;
  margin-top: 3px;
  font-size: 20px !important;
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => props.theme.palette.text.secondary} !important;
  margin-top: 3px;
  font-size: 20px !important;
`;

const CircularProgress = styled(MuiCircularProgress)(spacing);

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(13.3)}px;
  padding-top: ${(props) => props.theme.spacing(2.2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.6)}px;
  font-weight: 800;

  span {
    color: ${(props) => props.theme.palette.text.primary};
  }

  &:first-child {
    padding-top: ${(props) => props.theme.spacing(3.2)}px;
  }

  &:hover {
    background-color: transparent;
  }

  &.${(props) => props.activeClassName} {
    span {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding-left: ${(props) => props.theme.spacing(13.3)}px;

    &:first-child {
      padding-top: ${(props) => props.theme.spacing(1.7)}px;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: ${(props) => props.theme.spacing(10.3)}px;

    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.sidebar.borderColor};
      margin-bottom: ${(props) => props.theme.spacing(1.8)}px;
      padding-bottom: ${(props) => props.theme.spacing(5)}px;
    }
  }
`;

const LinkText = styled(ListItemText)`
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize + FONT_INCREASE}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

export const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  theme,
  hideName,
  ...rest
}) => {
  return (
    <Category {...rest}>
      <Box display="flex" flexDirection={'row'} flex={1} justifyContent="space-between" >
        <Box display="flex" flexDirection={'row'} >
          {icon}
          {!hideName &&
            <CategoryText>{name}</CategoryText>
          }
          {badge ? <CategoryBadge label={badge} /> : ""}
        </Box>
        <Box>
          {isCollapsable ? (
            isOpen ? (
              <CategoryIconMore />
            ) : (
              <CategoryIconLess />
            )
          ) : null}
        </Box>
      </Box>
    </Category>
  );
};

export const SidebarLink = ({ name, to, badge, icon, sanitizedCompanyName }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      to={isSvxvySite() ? to.replace(NavigationConstants.COMPANY, "/" + sanitizedCompanyName) : to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};


const CompanyMoreSidebar = ({ classes, staticContext, location, company, ...rest }) => {
  const theme = useTheme();

  const auth = useSelector((state) => state.auth);
  const loggedInUser = useSelector((state) => state.auth.person);
  const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    if (company) {
      routes.forEach((route, index) => {
        const updatePath = isSvxvySite() ? route.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : route.path;
        const isActive = pathName.indexOf(updatePath) === 0;
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/";

        _routes = Object.assign({}, _routes, {
          [index]: isActive || isOpen || isHome,
        });
      });
    }

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <Drawer variant="temporary" {...rest}
      ModalProps={{ style: { zIndex: 10 } }}
    >
      {company &&
        <CompanyDropdown company={company} loggedInUser={loggedInUser} forTablet />
        // <CompanyName component={NavLink} to={"/" + company.sanitizedName} button>
        //   {company.name}
        // </CompanyName>
      }
      <Scrollbar>
        {!didTryAutoLogin &&
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">

            <CircularProgress color="secondary" size={20} />
            <Typography mx={3} color="secondary" variant="h4">Loading menus...</Typography>
          </Box>
        }
        {didTryAutoLogin && <List disablePadding>
          {company &&
            <Items>
              {routes.map((category, index) =>
                <React.Fragment key={index}>
                  {category.header && showMenuItem(category, company, auth) ? (
                    <SidebarSection>{replaceDisplayName(category.header, company)}</SidebarSection>
                  ) : null}

                  {category.children && category.icon && showMenuItem(category, company, auth) ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={replaceDisplayName(category.id, company)}
                        icon={category.icon}
                        button={true}
                        theme={theme}
                        onClick={() => toggle(index)}
                      />
                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map((route, index) => (
                          <React.Fragment key={index}>
                            {showMenuItem(route, company, auth) && <SidebarLink
                              name={replaceDisplayName(route.name, company)}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                              theme={theme}
                              sanitizedCompanyName={company.sanitizedName}
                            />}
                          </React.Fragment>
                        ))}
                      </Collapse>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            </Items>
          }
        </List>}
      </Scrollbar>
      <CompanySidebarFooter />
    </Drawer>
  );
};

export default withRouter(CompanyMoreSidebar);
