import merge from "deepmerge";
import * as Colors from "../constants/Colors";

// base light theme
export const defaultVariant = {
  name: Colors.DEFAULT_THEME,
  palette: {
    type: 'light',
    iconColor: {
      color: Colors.THEME_GREY_3_LIGHT
    },
    primary: {
      main: Colors.USER_DEFINABLE_DEFAULT,
      contrastText: Colors.USER_DEFINABLE_TEXT_DEFAULT,
    },
    secondary: {
      main: Colors.SYSTEM_BLUE,
      contrastText: Colors.SYSTEM_BLUE_TEXT,
    },
    messageButton: {
      main: Colors.THEME_GREY_2_LIGHT,
      contrastText: Colors.THEME_GREY_2_TEXT_DARK,
    },
    success: {
      main: Colors.SYSTEM_GREEN,
      contrastText: Colors.SYSTEM_GREEN_TEXT,
    },
    warning: {
      main: Colors.SYSTEM_ORANGE,
      contrastText: Colors.SYSTEM_ORANGE_TEXT,
    },
    error: {
      main: Colors.SYSTEM_RED,
      contrastText: Colors.SYSTEM_RED_TEXT,
    },
    text: {
      primary: Colors.THEME_TEXT_LIGHT,
      secondary: Colors.THEME_GREY_2_LIGHT,
    },
    divider: Colors.THEME_GREY_4_LIGHT,
    background: {
      default: Colors.THEME_GREY_1_LIGHT,
      paper: Colors.THEME_BASE_LIGHT,
    },
  },
  MuiInputInput: {
    color: 'green'
  },
  header: {
    color: Colors.THEME_TEXT_LIGHT,
    background: Colors.THEME_BASE_LIGHT,
    search: {
      color: Colors.THEME_TEXT_LIGHT,
    },
    indicator: {
      background: Colors.SYSTEM_BLUE,
    },
    utilityNav: {
      color: Colors.THEME_TEXT_LIGHT,
      background: Colors.THEME_GREY_4_LIGHT,
    },
  },
  footer: {
    color: Colors.THEME_TEXT_LIGHT,
    background: Colors.THEME_BASE_LIGHT,
  },
  sidebar: {
    color: Colors.THEME_TEXT_LIGHT,
    background: Colors.THEME_BASE_LIGHT,
    borderColor: Colors.THEME_GREY_4_LIGHT,
    iconColor: Colors.THEME_GREY_3_LIGHT,
    header: {
      color: Colors.THEME_TEXT_LIGHT,
      background: Colors.THEME_BASE_LIGHT,
      borderColor: Colors.THEME_GREY_4_LIGHT,
      brand: {
        color: Colors.SYSTEM_BLUE,
      },
    },
    footer: {
      color: Colors.THEME_TEXT_LIGHT,
      background: Colors.THEME_BASE_LIGHT,
      online: {
        background: Colors.SYSTEM_BLUE,
      },
    },
    badge: {
      color: Colors.THEME_BASE_LIGHT,
      background: Colors.SYSTEM_BLUE,
    },
    // textField: {
    //   color: 'green',
    //   background: 'red',
    // },
    category: {
      svg: {
        color: Colors.THEME_GREY_3_LIGHT,
        colorActive: Colors.USER_DEFINABLE_DEFAULT,
      }
    }
  },
};


// smartcore - same as defaultVariant
const smartCoreVariant = merge(defaultVariant, {
  name: Colors.SMARTCORE,
  sidebar: {
    color: Colors.SYSTEM_BLUE_TEXT,
    background: Colors.SYSTEM_BLUE,
    borderColor: Colors.THEME_GREY_4_LIGHT,
    iconColor: Colors.THEME_GREY_3_LIGHT,
    header: {
      color: Colors.THEME_TEXT_LIGHT,
      background: Colors.THEME_BASE_LIGHT,
      borderColor: Colors.THEME_GREY_4_LIGHT,
      brand: {
        color: Colors.SYSTEM_BLUE,
      },
    },
  }
}
);


// company light - not used, same as defaultVariant
const companyLightVariant = merge(defaultVariant, {
  name: Colors.COMPANY_LIGHT,
});

const companyLightWebVariant = merge(companyLightVariant, {
  name: Colors.COMPANY_LIGHT_WEB,
  palette: {
    background: {
      default: Colors.THEME_GREY_1_LIGHT,
      paper: Colors.THEME_BASE_LIGHT,
    },
  },
});


// company dark - modifies defaultVariant
const companyDarkVariant = merge(companyLightVariant, {
  name: Colors.COMPANY_DARK,
  palette: {
    type: 'dark',
    messageButton: {
      main: Colors.THEME_GREY_2_DARK,
      contrastText: Colors.THEME_GREY_2_TEXT_DARK,
    },
    text: {
      primary: Colors.THEME_TEXT_DARK,
      secondary: Colors.THEME_GREY_2_DARK,
    },
    divider: Colors.THEME_GREY_4_DARK,
    background: {
      default: Colors.THEME_GREY_1_DARK,
      paper: Colors.THEME_BASE_DARK,
    },
  },
  header: {
    color: Colors.THEME_TEXT_DARK,
    background: Colors.THEME_BASE_DARK,
    search: {
      color: Colors.THEME_TEXT_DARK,
    },
    utilityNav: {
      color: Colors.THEME_TEXT_DARK,
      background: Colors.THEME_GREY_4_DARK,
    },
  },
  footer: {
    color: Colors.THEME_TEXT_DARK,
    background: Colors.THEME_BASE_DARK,
  },
  sidebar: {
    color: Colors.THEME_TEXT_DARK,
    background: Colors.THEME_BASE_DARK,
    borderColor: Colors.THEME_GREY_4_DARK,
    header: {
      color: Colors.THEME_TEXT_DARK,
      background: Colors.THEME_BASE_DARK,
      borderColor: Colors.THEME_GREY_4_DARK,
    },
    footer: {
      color: Colors.THEME_TEXT_DARK,
      background: Colors.THEME_BASE_DARK,
    },
    badge: {
      color: Colors.THEME_BASE_DARK,
    },
  },
});

const companyDarkWebVariant = merge(companyDarkVariant, {
  name: Colors.COMPANY_DARK_WEB,
  palette: {
    background: {
      default: Colors.THEME_GREY_1_DARK,
      paper: Colors.THEME_BASE_DARK,
    },
  },
});


const variants = [
  defaultVariant,
  smartCoreVariant,
  companyLightWebVariant,
  companyLightVariant,
  companyDarkVariant,
  companyDarkWebVariant
];

export default variants;
