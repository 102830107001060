import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"
import parse from 'html-react-parser';
import moment from "moment";
import i18n from 'i18n-js';

import { useTheme, withStyles } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Button as MuiButton,
    Box,
    CircularProgress as MuiCircularProgress,
    ListItem as MuiListItem,
    Menu,
    Typography as MuiTypography,
    Hidden,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCloseCircleOutline as DeactivateIcon,
    IoPencilOutline as EditIcon,
    IoDocumentTextOutline as InvoiceIcon,
    IoEllipsisHorizontal as MuiMenuIcon,
    IoFileTrayFullOutline as TermsIcon,
    IoReloadCircleOutline as RefreshIcon
} from "react-icons/io5";

// svxvy imports
import * as SecurityConstants from '../../../constants/SecurityConstants';

import { cancelTenantSubscription, reactivateTenantSubscription, refreshSubscriptionDetails } from "../../../store/actions/productActions";
import { ACTIVE_MEMBERSHIPS_STATUSES } from '../../../models/Purchase';
import { checkClaim } from "../../../helpers/helperFunctions";
import StyledDialog from "../../framework/StyledDialog";
import InvoiceCard from './InvoiceCard';

import ParagraphTypography from '../../framework/ParagraphTypography';
import EditPurchasedPackageModal from '../products/EditPurchasedPackageModal'

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);


const ICON_SIZE = 24;

const MenuIcon = styled(MuiMenuIcon)`
    color: ${(props) => props.theme.palette.iconColor.color};

    &:hover {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const ListItem = styled(MuiListItem)`
    padding: 8px 15px 9px 15px;
`;

const StyledMenu = withStyles((theme) => ({
    paper: {
        marginTop: '19px !important',
        marginRight: '15px !important',
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 0 5px 0',
    }
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));


const SubscriptionListItem = (props) => {
    const { hidePrice } = props;
    const theme = useTheme();
    const company = useSelector(state => state.company.company)
    const companyId = useSelector(state => state.company.companyId)

    const loggedInUser = useSelector(state => state.auth.person);
    const loggedInUserClaims = useSelector(state => state.auth.claims);
    const updatePayments = loggedInUser && checkClaim(loggedInUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [showTerms, setShowTerms] = useState(false)
    const [showEditPurchasePackageModal, setShowEditPurchasedPackageModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const isError = !ACTIVE_MEMBERSHIPS_STATUSES.includes(props.purchase.status) || !props.purchase.status;

    const hideMenu = hidePrice && !updatePayments
        && !(props.purchase.purchasedProduct && props.purchase.purchasedProduct.terms)
        ? true : false;

    const dispatch = useDispatch();
    const subscriptionChange = useCallback(async (purchaseId, companyId, isCancel, userId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            if (isCancel)
                await dispatch(cancelTenantSubscription(purchaseId, companyId, userId));
            else
                await dispatch(reactivateTenantSubscription(purchaseId, companyId, userId));
        } catch (err) {
            setError(err.message);
        }
        setIsSubmitting(false);
    }, [dispatch]);

    const subscriptionRefresh = useCallback(async (purchaseId, companyId, userId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(refreshSubscriptionDetails(purchaseId, companyId, userId));
        } catch (err) {
            setError(err.message);
        }
        setIsSubmitting(false);
    }, [dispatch]);

    const handleCancelSubscription = () => {
        if (company.allowCustomerCancelMembership || updatePayments)
            subscriptionChange(props.purchase.id, companyId, true, props.purchase.appUserId);
        else
            alert(company.cancelTextMembershipText)
    };

    const handleReactivateSubscription = () => {
        subscriptionChange(props.purchase.id, companyId, false, props.purchase.appUserId);
    }

    const handleSubscriptionRefresh = () => {
        subscriptionRefresh(props.purchase.id, companyId, props.purchase.appUserId);
    }


    const expires = <Box width={140} >
        {props.purchase.purchasedProduct && props.purchase.purchasedProduct.units === 0 &&
            <React.Fragment>
                {props.purchase.automaticallyRenew &&
                    <React.Fragment>
                        {props.purchase.status && ACTIVE_MEMBERSHIPS_STATUSES.includes(props.purchase.status) &&
                            <React.Fragment>
                                {props.purchase.purchasedProduct.isRecurring && <Typography variant="subtitle1" component="p">{props.purchase.expirationDate < new Date() ? 'Expired' : 'Next Billing Date'} </Typography>}
                                {props.purchase.purchasedProduct.isRecurring && <Typography variant="subtitle1" component="p">{moment(props.purchase.expirationDate).format('ll')}</Typography>}
                            </React.Fragment>
                        }
                        {(!ACTIVE_MEMBERSHIPS_STATUSES.includes(props.purchase.status) || !props.purchase.status) &&
                            <Typography variant="subtitle1" component="p" style={{ color: theme.palette.error.main }}>
                                {props.purchase.status ? props.purchase.status.replace('_', ' ') : 'error'}
                            </Typography>
                        }
                    </React.Fragment>
                }
                {!props.purchase.automaticallyRenew && !isSubmitting &&
                    <React.Fragment>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            style={{ color: props.purchase.expirationDate > new Date() ? theme.palette.error.main : theme.palette.text.secondary }}>
                            {props.purchase.expirationDate > new Date() ? 'Expires ' : 'Expired '}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            style={{ color: props.purchase.expirationDate > new Date() ? theme.palette.error.main : theme.palette.text.secondary }}>
                            {moment(props.purchase.expirationDate).format('ll')}
                        </Typography>
                    </React.Fragment>
                }
            </React.Fragment>
        }
        {props.purchase.purchasedProduct && props.purchase.purchasedProduct.units > 0 &&
            <Typography
                variant="subtitle1"
                component="p"
                style={{ color: props.purchase.units === 1 ? theme.palette.error.main : theme.palette.text.primary }}
            >
                {props.purchase.units} {props.purchase.units === 1 ? ACTIVITY_TEXT : ACTIVITIES_TEXT} Remaining
            </Typography>
        }
    </Box>;

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                <Box display="flex" flexDirection="column" flex={1} mr={4}>
                    <Typography component="p" style={{ marginBottom: '2px', fontWeight: '700' }}>{props.purchase.purchasedProduct.name}</Typography>
                    <ParagraphTypography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }}>{props.purchase.description ? parse(props.purchase.description) : ""}</ParagraphTypography>
                    {props.purchase.memo &&
                        <Box mt={2}>
                            <ParagraphTypography
                                variant="subtitle1"
                                component="p"
                                style={{ color: isError ? theme.palette.error.main : theme.palette.text.secondary }}
                            >
                                {props.purchase.memo}
                            </ParagraphTypography>
                        </Box>
                    }
                    <Hidden smUp>
                        <Box mt={2}>
                            {expires}
                        </Box>
                    </Hidden>
                </Box>
                <Box display="flex" alignItems="center">
                    <Hidden xsDown>
                        {expires}
                    </Hidden>
                    <Box width={70} style={{ textAlign: 'right' }}>
                        {!hidePrice &&
                            <React.Fragment>
                                <Typography component="p" style={{ fontWeight: '700', marginBottom: '2px' }}>
                                    {i18n.toCurrency(props.purchase.nextAmountDue ? props.purchase.nextAmountDue : props.purchase.amountPaid, { precision: 2, unit: '$' })}
                                    {/* {i18n.toCurrency(props.purchase.purchasedProduct.unitAmount, { precision: 2, unit: '$' })} */}
                                </Typography>
                                <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }}>
                                    {props.purchase.purchasedProduct.isRecurring ? "per " + (props.purchase.purchasedProduct.recurringIntervalCount > 1 ? props.purchase.purchasedProduct.recurringIntervalCount + " " : "") + props.purchase.purchasedProduct.recurringInterval + (props.purchase.purchasedProduct.recurringIntervalCount > 1 ? "s" : "") : ''}
                                </Typography>
                            </React.Fragment>
                        }
                    </Box>
                    <Box>
                        {!hideMenu &&
                            <React.Fragment>
                                <Button
                                    id={props.purchase.id + "_menu_button"}
                                    aria-label="subscription menu button"
                                    onClick={(event) => setAnchorMenu(event.currentTarget)}
                                    style={{ minWidth: '0', color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: '400', marginTop: '-4px', marginLeft: '15px', padding: 0 }}>
                                    <MenuIcon size="24" />
                                </Button>
                                {isSubmitting &&
                                    <CircularProgress mx={4} size={24} color='primary' />

                                }
                            </React.Fragment>
                        }
                        {hideMenu &&
                            <Box width={40} />
                        }
                    </Box>
                </Box>
            </Box>
            {
                error &&
                <Alert mt={2} severity="error">{error}</Alert>
            }

            <StyledMenu
                id={props.purchase.id + "_menu"}
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={() => setAnchorMenu(null)}
            >
                <Box onClick={() => setAnchorMenu(null)} style={{ padding: 0 }}>
                    {props.purchase.purchasedProduct && props.purchase.purchasedProduct.terms &&
                        <ListItem
                            button
                            onClick={() => setShowTerms(true)}
                        >
                            <TermsIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                View Terms &amp; Conditions
                            </Typography>
                        </ListItem>
                    }
                    {props.purchase.purchasedProduct && props.purchase.purchasedProduct.units > 0 && updatePayments &&
                        <ListItem
                            button
                            onClick={() => setShowEditPurchasedPackageModal(true)}
                        >
                            <EditIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                Edit Package Count
                            </Typography>
                        </ListItem>
                    }
                    {props.purchase.invoiceUrl && !hidePrice &&
                        <ListItem
                            button
                            onClick={() => setShowInvoiceModal(true)}
                        >
                            <InvoiceIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                View Invoice
                            </Typography>
                        </ListItem>
                    }
                    {props.purchase.purchasedProduct.isRecurring && props.purchase.automaticallyRenew &&
                        <ListItem
                            button
                            onClick={handleCancelSubscription}
                        >
                            <DeactivateIcon size={ICON_SIZE} style={{ color: theme.palette.error.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                Cancel Membership
                            </Typography>
                        </ListItem>
                    }
                    {!props.purchase.automaticallyRenew && props.purchase.expirationDate > new Date() &&
                        <ListItem
                            button
                            onClick={handleReactivateSubscription}
                        >
                            <EditIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                Reactivate
                            </Typography>
                        </ListItem>
                    }
                    {updatePayments && props.purchase.purchasedProduct && props.purchase.purchasedProduct.isRecurring &&
                        <ListItem
                            button
                            onClick={handleSubscriptionRefresh}
                        >
                            <RefreshIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main, marginRight: '5px' }} />
                            <Typography variant="body2">
                                Refresh From Stripe
                            </Typography>
                        </ListItem>
                    }
                </Box>
            </StyledMenu>
            {showTerms && props.purchase.purchasedProduct && props.purchase.purchasedProduct.terms &&
                <StyledDialog
                    open={showTerms}
                    setOpen={setShowTerms}>
                    <ParagraphTypography>
                        {props.purchase.purchasedProduct.terms}
                    </ParagraphTypography>
                </StyledDialog>
            }
            {showEditPurchasePackageModal &&
                <EditPurchasedPackageModal
                    open={showEditPurchasePackageModal}
                    setOpen={setShowEditPurchasedPackageModal}
                    purchase={props.purchase}
                />
            }

            {/* Invoice Modal */}
            {showInvoiceModal &&
                <StyledDialog
                    open={showInvoiceModal}
                    setOpen={setShowInvoiceModal}>
                    <InvoiceCard
                        purchase={props.purchase} />
                </StyledDialog>
            }
        </Box>
    );
};

export default SubscriptionListItem;
