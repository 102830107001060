import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment as MuiInputAdornment,
    LinearProgress as MuiLinearProgress,
    List,
    Tabs as MuiTabs,
    Tab as MuiTab,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoSearchOutline as SearchIcon,
} from "react-icons/io5";

// svxvy import

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { checkClaim } from '../../../helpers/helperFunctions';
// import { fetchPaymentRecords } from '../../../store/actions/paymentActions';
import { fetchCompanyPurchases } from '../../../store/actions/companyActions';
import { getPackagesByPerson } from './billingGroupingFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { refreshLogedInUser } from '../../../store/actions/authActions';
import PersonSubscription from '../../../components/company/purchases/Reporting/PersonSubscription';
import ReportGroup from '../../../components/framework/ReportGroup';

// UI Consts

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  margin: 0 0 30px 0;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
    margin: 0;
    float: right;

    input {
        width: 250px;
    }
  }
`;

const InputAdornment = styled(MuiInputAdornment)`
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 9px;

    svg {
        width: 18px;
        height: 18px;
        color: ${(props) => props.theme.palette.primary.main};
        opacity: 1;
    }
`;

const Tabs = styled(MuiTabs)`
    height: 35px;
    min-height: auto;
    max-height: none;
    background-color: ${(props) => props.theme.palette.background.paper};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        height: 100%;
    }
`;

const Tab = styled(MuiTab)`
    width: auto;
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    max-height: none;
    color: ${(props) => props.theme.palette.text.primary};
    border-radius: 10px;
    padding: 0 29px 0 29px;
    opacity: 1;
    font-weight: 700;
    font-size: 1.4rem;

    &.Mui-selected {
        background-color: ${(props) => props.theme.palette.primary.main};
        color: white;
    }
`;


const ACTIVE_MEMBERSHIPS = 'Active';
const COMPLETED_PACKAGES = 'Completed';

const PackageReport = props => {
    const theme = useTheme();
    const company = useSelector(state => state.company.company);
    const people = useSelector(state => state.company.people);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const currentUser = useSelector(state => state.auth.person);

    const currentUserClaims = useSelector(state => state.auth.claims);
    const allowUpdatePayments = currentUser && checkClaim(currentUserClaims, SecurityConstants.UPDATE_PAYMENTS) ? true : false;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [activeTab, setActiveTab] = useState(ACTIVE_MEMBERSHIPS);

    const companyPurchases = useSelector(state => state.company.purchases).sort(compareObjects('datePurchased', 'desc'));
    const packages = getPackagesByPerson(allowUpdatePayments ? companyPurchases.filter(p => p.isTentantPurchase) : currentUser.purchases.filter(p => p.isTentantPurchase), people);


    const dispatch = useDispatch();
    /********************* load data ************************ */
    const loadMessages = useCallback(async () => {
        try {
            setIsLoading(true);
            if (allowUpdatePayments) {
                await dispatch(fetchPeople(company.id));
                await dispatch(fetchCompanyPurchases(company.id));
            }
            else {
                await dispatch(refreshLogedInUser());
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
        }
    }, [company, dispatch, allowUpdatePayments]);

    useEffect(() => {
        loadMessages();
    }, [loadMessages]);


    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <Helmet title="Packages" />
                {error &&
                    <Grid container alignContent="center">
                        <Alert mb={4} severity="error">{error}</Alert>
                    </Grid>
                }
                {isLoading
                    && <Grid container alignContent="center">
                        <Grid item xs={12} >
                            <LinearProgress my={2} color="primary" />
                            <Typography variant={"h2"} gutterBottom>Loading purchases...</Typography>
                            <LinearProgress my={2} color="secondary" />
                        </Grid>
                    </Grid>
                }
                {!error && !isLoading &&
                    <Box>
                        <Grid container>
                            <Grid item xs={12} xl={6} style={{ display: 'flex', marginBottom: '25px' }}>
                                <Box display='flex' flexDirection={isMobile ? 'column' : 'row'}>
                                    <Typography variant="h1" style={{ margin: isMobile ? '0 0 20px 0' : '0 30px 0 0' }}>Packages</Typography>
                                    <Tabs
                                        value={activeTab}
                                        onChange={(event, newValue) => setActiveTab(newValue)}
                                        indicatorColor="primary"
                                        centered
                                    >
                                        <Tab value={ACTIVE_MEMBERSHIPS} label={ACTIVE_MEMBERSHIPS} />
                                        <Tab value={COMPLETED_PACKAGES} label={COMPLETED_PACKAGES} />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <TextField
                                    id="standard-search"
                                    type="search"
                                    placeholder="Search"
                                    onChange={e => setSearch(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {activeTab === ACTIVE_MEMBERSHIPS &&
                            <React.Fragment>
                                {packages.active.length === 0 &&
                                    <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                        <Typography variant="h3">No active packages issues identified.</Typography>
                                    </Box>
                                }
                                {packages.active.length > 0 && packages.groupedActive &&
                                    <React.Fragment>
                                        {Object.keys(packages.groupedActive).sort().map((keyValue, index) => {
                                            const filteredData = packages.groupedActive[keyValue].filter(x => x.personName.includes(search));
                                            return (
                                                <ReportGroup
                                                    key={keyValue}
                                                    heading={keyValue}
                                                    total={filteredData.length}
                                                    company={company}>
                                                    <Card style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                                        <CardContent style={{ padding: '0 14px 0 14px' }}>
                                                            <List style={{ margin: '0 0 0 0', padding: 0 }}>
                                                                {filteredData.map((group, index) => {
                                                                    return (
                                                                        <PersonSubscription
                                                                            key={group.key}
                                                                            person={group.person}
                                                                            subscription={group.subscription}
                                                                            company={company} />
                                                                    )
                                                                })}
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </ReportGroup>
                                            )
                                        })}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {activeTab === COMPLETED_PACKAGES &&
                            <React.Fragment>
                                {packages.inactive.length === 0 &&
                                    <Box display="flex" flexDirection="row" justifyContent="center" mx={2} my={2}>
                                        <Typography variant="h3">No completed packages were found.</Typography>
                                    </Box>
                                }
                                {packages.inactive.length > 0 && packages.groupedInactive &&
                                    <React.Fragment>
                                        {Object.keys(packages.groupedInactive).sort().map((keyValue, index) => {
                                            const filteredData = packages.groupedInactive[keyValue].filter(x => x.personName.includes(search));
                                            return (
                                                <ReportGroup
                                                    key={keyValue}
                                                    heading={keyValue}
                                                    total={filteredData.length}
                                                    company={company}>
                                                    <Card style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                                        <CardContent style={{ padding: '0 14px 0 14px' }}>
                                                            <List style={{ margin: '0 0 0 0', padding: 0 }}>
                                                                {filteredData.map((group, index) => {
                                                                    return (
                                                                        <PersonSubscription
                                                                            key={group.key}
                                                                            person={group.person}
                                                                            subscription={group.subscription}
                                                                            company={company} />
                                                                    )
                                                                })}
                                                            </List>
                                                        </CardContent>
                                                    </Card>
                                                </ReportGroup>
                                            )
                                        })}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Box>
                }
            </Grid>
        </Grid>
    );
}

export default PackageReport;
