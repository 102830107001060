import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from "@material-ui/core";

import { BlobServiceClient } from '@azure/storage-blob'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    Card as MuiCard,
    CircularProgress as MuiCircularProgress,
    IconButton as MuiIconButton,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoTrash as DeleteIcon,
} from "react-icons/io5";

//svxvy imports
import { getSaSAddToken, getFilesForAppUser, getSaSReadToken, deleteFile, getSaSReadTokenForActivity, deleteFileActivity, getFilesForActivity, getSaSAddTokenActivity, setFileLocationForActivity, filedAddedNotification, filedAddedToActivityNotification } from '../../store/actions/fileActions';
import { GUID_Generator, textTruncate } from '../../helpers/helperFunctions';
import ActionButton from './ActionButton';

// style constants
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

const EMTPY_GUID = '00000000-0000-0000-0000-000000000000';

const getActivityId = (id) => {
    if (id.includes('_'))
        return id.split('_')[0]
    else
        return id;
}


const FileRow = props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const { dispatch, company, appUser, activity, file, showDelete } = props;
    const [isLoading, setIsLoading] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const [error, setError] = useState(null);


    const handleOpenFile = async (windowRef) => {
        try {
            if (!isDeleted) {
                setIsLoading(true);
                let blobAccess = null;
                if (appUser)
                    blobAccess = await dispatch(getSaSReadToken(company.id, appUser.id, file));
                else if (activity)
                    blobAccess = await dispatch(getSaSReadTokenForActivity(company.id, getActivityId(activity.id), file));

                const fileName = blobAccess.blobUri + "/" + file + "?" + blobAccess.sasToken;
                // if (!window.open(fileName)) {
                //     window.location.href = fileName
                // }
                windowRef.location = fileName
            }
        } catch (ex) {
            setError(ex.message)
        }
        setIsLoading(false);
    }

    const handleDeleteFile = async () => {
        try {
            setIsDeleting(true);
            if (appUser)
                setIsDeleted(await dispatch(deleteFile(company.id, appUser.id, file)));
            else if (activity)
                setIsDeleted(await dispatch(deleteFileActivity(company.id, getActivityId(activity.id), file)));
        } catch (ex) {
            setError(ex.message)
        }
        setIsDeleting(false);
    }

    const handleOpenClick = () => {
        let windowRef = window.open();
        handleOpenFile(windowRef)
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            // mb={2}
            >
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h4"
                        color="primary"
                        style={{ cursor: isDeleted ? 'default' : 'pointer', textDecoration: isDeleted ? 'line-through' : 'none' }}
                        onClick={handleOpenClick}
                    >
                        {isMobile ? textTruncate(file.split('/').pop(), 35) : file.split('/').pop()}
                    </Typography>
                    {isLoading && <CircularProgress ml={2} color="primary" size={14} />}
                </Box>
                <IconButton
                    aria-label="Delete file"
                    size="medium"
                    // color='primary'
                    style={{ color: theme.palette.error.main }}
                    disabled={isLoading || isDeleting || isDeleted}
                    onClick={handleDeleteFile}

                >
                    {isDeleting && <CircularProgress ml={2} color="primary" size={14} />}
                    {!isDeleting && !isDeleted && showDelete && <DeleteIcon fontSize="inherit" />}
                    {isDeleted && <Typography>Deleted</Typography>}
                </IconButton>
            </Box>
            {error && <Alert mb={2} severity="error">
                {error}
            </Alert>}
        </Box>
    )
}

const FileUploader = props => {
    const { submitting, disabled, appUser, activity, title, hideUploadButton, viewStartDate, viewEndDate, isDeleting, ...buttonProps } = props;
    // const ACTIVITY_TEXT = useSelector(state => state.company.activityText);

    const company = useSelector(state => state.company.company);

    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [fileError, setFileError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [files, setFiles] = useState([]);
    const inputFileRef = useRef(null);

    const currentUser = useSelector(state => state.auth.person);

    /************************* fileEvents ************************/
    const dispatch = useDispatch();

    const loadFiles = useCallback(async () => {
        try {
            setIsLoading(true);
            setFileError(null);
            let filesContainer = null
            if (appUser)
                filesContainer = await dispatch(getFilesForAppUser(company.id, appUser.id));
            else if (activity)
                filesContainer = await dispatch(getFilesForActivity(company.id, getActivityId(activity.id)));

            filesContainer.fileDtos.sort((a, b) => a.file.localeCompare(b.file, undefined, { sensitivity: 'base' }))
            setFiles(filesContainer.fileDtos);
        } catch (ex) {
            setFileError(ex.message)
        }
        setIsLoading(false);
    }, [dispatch, company, appUser, activity]);

    const uploadFile = async (files) => {
        const filesUploaded = [];
        try {
            setIsUploading(true);
            setError(null);

            let blobAccess = null;
            if (appUser)
                blobAccess = await dispatch(getSaSAddToken(company.id, appUser.id));
            else if (activity)
                blobAccess = await dispatch(getSaSAddTokenActivity(company.id, getActivityId(activity.id)));
            const blobServiceClient = new BlobServiceClient(`${blobAccess.blobUri}?${blobAccess.sasToken}`);
            const containerClient = blobServiceClient.getContainerClient(company.sanitizedName.toLowerCase());

            let path = null;
            if (appUser)
                path = appUser.id;
            else if (activity) {
                if (activity.fileLocation === EMTPY_GUID) {
                    path = GUID_Generator();
                    await dispatch(setFileLocationForActivity(company.id, getActivityId(activity.id), { fileLocation: path }, viewStartDate, viewEndDate));
                }
                else {
                    path = activity.fileLocation;

                }
            }


            for (let i = 0; i < files.length; i++) {
                const fileToLoad = files[i];
                const fileName = path + "/" + fileToLoad.name;
                const blobClient = containerClient.getBlockBlobClient(fileName);
                const options = { blobHTTPHeaders: { blobContentType: fileToLoad.type } };
                //const uploadBlobResponse = await blobClient.uploadBrowserData(file, options);
                await blobClient.uploadBrowserData(fileToLoad, options);
                await blobClient.setMetadata({ uploadedBy: currentUser.id })
                filesUploaded.push(fileToLoad.name)
            }


            await loadFiles();
        } catch (ex) {
            setError(ex.message + " For help contact support@smartcore.life")
        }

        try {
            filesUploaded.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

            if (appUser) {
                await dispatch(filedAddedNotification(company.id, appUser.id, filesUploaded));
            }
            else if (activity) {
                const [activityId, occuranceId] = activity.id.split('_');
                await dispatch(filedAddedToActivityNotification(company.id, activityId, occuranceId, filesUploaded));
            }


        } catch (ex) {
            setError(ex.message)
        }
        setIsUploading(false);
    }

    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }
        const selectedFiles = e.target.files;
        uploadFile(selectedFiles);
    };


    const showFileDialog = () => inputFileRef.current && inputFileRef.current.click();

    /********************** effects */

    useEffect(() => {
        if (!isDeleting)
            loadFiles();
    }, [loadFiles, isDeleting]);

    if (files && files.length === 0 && hideUploadButton)
        return <React.Fragment />

    return (
        <Card mt={3}>
            <Box display="flex" flexDirection="column" p={3}>
                {title &&
                    <Typography style={{ fontWeight: 700 }} mb={2}>{title}</Typography>
                }
                {!hideUploadButton &&
                    <Box display="flex" flexDirection="column">
                        <input type="file"
                            style={{ display: 'none' }}
                            ref={inputFileRef}
                            multiple={true}
                            onChange={handleFileUpload} />
                        <ActionButton
                            variant="outlined"
                            color="primary"
                            submitting={isUploading}
                            disabled={isUploading || disabled} startIcon={isUploading ? null : buttonProps.startIcon}
                            onClick={() => showFileDialog()}
                            fullWidth={false}
                            mb={4}
                        >
                            Upload Files
                        </ActionButton>
                        {error && <Alert my={2} severity="error">
                            {error}
                        </Alert>}
                    </Box>
                }
                {!isLoading && (!files || files.length === 0) && <Typography mb={3}>No files loaded</Typography>}
                {isLoading && !isUploading &&
                    <Box display="flex">
                        <Typography mr={3}>Loading files</Typography>
                        <CircularProgress color="primary" size={20} />
                    </Box>
                }
                {fileError &&
                    <Alert my={2} severity="error">
                        {fileError}
                    </Alert>
                }
                {files && files.length > 0 &&
                    <Box display="flex" flexDirection="column">
                        {files.map((file, index) => {
                            return (
                                <FileRow
                                    key={file.file}
                                    dispatch={dispatch}
                                    company={company}
                                    appUser={appUser}
                                    activity={activity}
                                    showDelete={file.allowDelete}
                                    file={file.file} />

                            )
                        })}
                    </Box>
                }
            </Box>
        </Card>
    )
}

export default styled(FileUploader)(spacing);