import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from 'lodash';
import { useMediaQuery } from "@material-ui/core";
import jwt_decode from 'jwt-decode';

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

import { Formik } from "formik"

import {
    Box,
    Button as MuiButton,
    FormControlLabel,
    FormControl,
    Radio,
    RadioGroup,
    Switch,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'

// svxvy imports

import { SCOPES, isSvxvySite, isLiveSite } from '../../constants/NetworkConstants';
import * as SecurityConstants from '../../constants/SecurityConstants';
import { checkClaim } from '../../helpers/helperFunctions';
import { validateAuthCodeGetCalendars, setupSycnGoogleCalendar, stopSyncGoogleCalendar } from '../../store/actions/peopleActions';
import ActionButton from './ActionButton';
import StyledDialog from './StyledDialog';

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);


const GoogleGrantAccess = props => {
    const { googleAccount, marginBottom, marginRight, company, setError, setCalendars } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    /************************************* Google API Calls *************************/

    const grantAccess = useGoogleLogin({
        onSuccess: codeResponse => {
            submitCallback(company.id, {
                googleAccountId: googleAccount,
                calendarId: googleAccount,
                googleToken: codeResponse.code,
                googleUseDefaultApp: isSvxvySite()
            })
        },
        flow: 'auth-code',
        redirect_uri: 'postmessage',
        hint: googleAccount,
        prompt: 'consent',
        scope: SCOPES,
    });


    /*************************************** form actions *************************/
    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values) => {
        setError(null);
        setIsSubmitting(true);
        try {
            const valuesToSend = cloneDeep(values);
            const results = await dispatch(validateAuthCodeGetCalendars(companyId, valuesToSend));
            setCalendars(results)
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch, setCalendars, setError]);


    return (
        <Box mr={marginRight} mb={marginBottom}>
            <GoogleButton
                disabled={isSubmitting}
                type="light"
                onClick={() => grantAccess()}
            />
        </Box>
    )

}

const GoogleAuthCodeFlow = props => {
    const { marginBottom, marginRight } = props;
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);

    const company = useSelector(state => state.company.company);
    const currentUser = useSelector(state => state.auth.person);

    const userClaims = useSelector(state => state.auth.claims);
    const viewAllActivities = checkClaim(userClaims, SecurityConstants.VIEW_ALL_ACTIVITIES);

    const [googleAccount, setGoogleAccount] = useState(null);
    const [googleCalendars, setGoogleCalendars] = useState(null)

    const [isSyncModalVisiable, setIsSyncModalVisiable] = useState(false);
    const [error, setError] = useState(null);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    /************************************************** google API calls ****************************/


    const handleGoogleSuccessfulLogin = (response) => {
        const googleJWT = jwt_decode(response.credential);
        setGoogleAccount(googleJWT.email);
    }


    // useGoogleOneTapLogin({
    //     onSuccess: handleGoogleSuccessfulLogin,
    //     onError: message => {
    //         setError("Login failed. try again" + message)
    //     },
    // });

    /*************************************** form actions *************************/
    const dispatch = useDispatch();
    const submitCallback = useCallback(async (companyId, values) => {
        setError(null);
        setIsSubmitting(true);
        try {
            const valuesToSend = cloneDeep(values);
            setIsSyncing(true);
            await dispatch(setupSycnGoogleCalendar(companyId, valuesToSend));
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
        setIsSyncing(false);
    }, [dispatch]);

    const stopSycn = useCallback(async (companyId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            setIsDeleting(true)
            await dispatch(stopSyncGoogleCalendar(companyId));
            setGoogleCalendars(null);
            setGoogleAccount(null);
            setIsSubmitting(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
        setIsDeleting(false)
    }, [dispatch]);

    const handleSubmit = (values) => {
        submitCallback(company.id, values);
    };

    // // If this is a custom domain and the google clinet id is net set don't show the component
    // if (!isSvxvySite() && (!company.googleClientId || company.googleClientId.trim().length === 0))
    //     return (<React.Fragment></React.Fragment>)

    // For now only show the component to "owners"
    if (!viewAllActivities)
        return (<React.Fragment></React.Fragment>)

    // if the google app is not approved then just return unles its the smartcore admin
    // console.log(isLiveSite(), !isSvxvySite(), !company.googleCalendarAppApproved, !SecurityConstants.isSVXVYAdmin(currentUser.id), (!company.googleCalendarAppApproved || !SecurityConstants.isSVXVYAdmin(currentUser.id)))
    // console.log((isLiveSite() && !isSvxvySite() && (!company.googleCalendarAppApproved || !SecurityConstants.isSVXVYAdmin(currentUser.id))))
    if (isLiveSite() && !isSvxvySite() && !company.googleCalendarAppApproved && !SecurityConstants.isSVXVYAdmin(currentUser.id)) {
        return (<React.Fragment></React.Fragment>)
    }

    if (isMobile)
        return <Box />

    return (
        <React.Fragment>
            <Box>
                <Button
                    variant="outlined"
                    onClick={() => setIsSyncModalVisiable(true)}
                    ml={2}
                    mr={marginRight}
                    py={1.5}
                // mb={marginBottom}
                >
                    {isSyncing ? "Sync in Progress"
                        : isDeleting ? "Stopping Sync" :
                            currentUser.googleCalendarName ? "Syncing" : "Sync To Google"}
                </Button>
            </Box>
            {isSyncModalVisiable &&
                <StyledDialog
                    style={{ height: "90vh" }}
                    open={isSyncModalVisiable}
                    setOpen={setIsSyncModalVisiable}
                    aria-labelledby="act-dialog-title"
                    primaryAction={
                        googleCalendars && !currentUser.googleCalendarName ?
                            <ActionButton
                                form="syncCalendarForm"
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                submitting={isSubmitting}
                            >
                                Start Calendar Sync
                            </ActionButton>
                            : null
                    }
                >
                    <Box>
                        {error &&
                            <Alert mb={4} severity="error">{error}</Alert>
                        }
                        {/* User has no google calendar sync */}
                        {currentUser.googleCalendarName &&
                            <React.Fragment>
                                <Box>
                                    <Typography variant='h4' mb={2}>Sync setup</Typography>
                                    <Box pl={2}>
                                        <Typography variant='body1' mb={2}>To calendar -- {currentUser.googleCalendarName}</Typography>
                                        {viewAllActivities &&
                                            <Typography variant='body1' mb={2}>You are {currentUser.googleSyncAllActivities ? "" : " not "} synching all {ACTIVITIES_TEXT.toLowerCase()}.</Typography>
                                        }
                                    </Box>
                                    {isDeleting &&
                                        <Alert severity='info' my={2}>Your calendar is being cleaned up.  You can safely close this dialog and the sync will continue.</Alert>
                                    }
                                    <Box>
                                        {!isDeleting &&
                                            <Typography variant='body1' my={4}>To make changes stop the current sycn and reconfigure it. Stopping the sync will remove all {ACTIVITIES_TEXT.toLowerCase()} from you google calendar.</Typography>

                                        }
                                        <ActionButton
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            submitting={isSubmitting}
                                            onClick={() => stopSycn(company.id)}
                                        >
                                            Stop sync
                                        </ActionButton>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        }
                        {/* User has NOT setup google calendar sync */}
                        {!currentUser.googleCalendarName &&
                            <React.Fragment>
                                {googleAccount && !googleCalendars &&
                                    <Box>
                                        <Typography variant='h4' mb={2}>Step 2: Grant Access.</Typography>
                                        <Typography variant='body1' mb={2}>During the last step we got the Google Account you wanted to use.  During this step you will grant access to the application.</Typography>
                                        <Typography variant='body1' mb={2}>You will be prompted to choose an account.</Typography>
                                        <Typography variant='body1' mb={2}>Make sure to choose the same account as step 1 or you will get an error.</Typography>
                                        <GoogleGrantAccess
                                            company={company}
                                            setCalendars={setGoogleCalendars}
                                            googleAccount={googleAccount}
                                            marginBottom={marginBottom}
                                            marginRight={marginRight}
                                            setError={setError}
                                        />
                                    </Box>}
                                {!googleAccount &&
                                    <Box>
                                        <Typography variant='h4' mb={2}>Step 1: Sign into your google account.</Typography>
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccessfulLogin}
                                            onError={(message) => {
                                                setError("Login failed. try again" + message)
                                            }}
                                        />
                                        <Box mt={6}>
                                            <Alert severity='info'>If you don't see a login button you may have a privacy blocker app that is interfering with the app.</Alert>
                                        </Box>
                                    </Box>
                                }

                                {googleCalendars && googleAccount &&
                                    <Formik
                                        initialValues={{
                                            calendarId: googleAccount,
                                            calendarName: 'Primary',
                                            syncAllActivities: false,
                                        }}
                                        validationSchema={null}
                                        onSubmit={handleSubmit}
                                        disabled={isSyncing}
                                    >
                                        {({
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit,
                                            setValue,
                                            isSubmitting,
                                            touched,
                                            values,
                                            status,
                                            setFieldValue,
                                        }) => (
                                            <form id="syncCalendarForm" onSubmit={handleSubmit}>
                                                <Box>
                                                    <Typography variant='h4' mb={2}>Step 3: Select a calendar and click "Start Calendar Sync"</Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            row
                                                            aria-label="Calendar Id"
                                                            name="calendarId"
                                                            value={values.calendarId}
                                                            disabled={isSyncing}
                                                            onChange={event => {
                                                                const calendar = googleCalendars.find(x => x.id === event.target.value);
                                                                setFieldValue('calendarId', event.target.value);
                                                                setFieldValue('calendarName', calendar.isPrimary ? 'Primary Calendar' : calendar.title);
                                                            }}
                                                        >
                                                            <Box display="flex" flexDirection="column">
                                                                {/* <Typography variant='h4' mb={2}>Select a calendar</Typography> */}
                                                                <Box display="flex" flexDirection="column" pl={2}>
                                                                    {googleCalendars && googleCalendars.length > 0 &&
                                                                        googleCalendars.map((calendar, index) => {
                                                                            // if (calendar.summary === 'Birthdays' || calendar.summary.startsWith('Holidays in'))
                                                                            //     return (<React.Fragment key={calendar.id} />);
                                                                            return (
                                                                                <FormControlLabel key={calendar.id}
                                                                                    disabled={isSyncing}
                                                                                    value={calendar.id}
                                                                                    control={<Radio />}
                                                                                    label={calendar.isPrimary ? 'Primary Calendar' : calendar.title} />

                                                                            )
                                                                        })}
                                                                </Box>

                                                                {viewAllActivities &&
                                                                    <Box mt={3}>
                                                                        <FormControlLabel disabled={isSyncing} control={
                                                                            <Switch disabled={isSyncing}
                                                                                name='syncAllActivities'
                                                                                onChange={(val) => setFieldValue('syncAllActivities', !values.syncAllActivities)}
                                                                                checked={values.syncAllActivities} />
                                                                        } label={'Sync All ' + ACTIVITIES_TEXT + " -- Setting this to on will sync all " + ACTIVITIES_TEXT.toLowerCase() + " onto your calendar."} />
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    {isSyncing &&
                                                        <Alert severity='info' mt={2}>Your calendar is sync'ing this may take a couple of minutes.  You can safely close this dialog and the sync will continue.</Alert>
                                                    }
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>
                                }
                            </React.Fragment>
                        }
                    </Box>
                </StyledDialog>
            }
        </React.Fragment >
    )
}

export default GoogleAuthCodeFlow;