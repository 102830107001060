import React from "react";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    Card as MuiCard,
    Divider as MuiDividier,
    Typography as MuiTypography
} from "@material-ui/core";

// svxvy imports
import ProductListItem from "./ProductListItem";


// constants
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDividier)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ProductList = (props) => {
    const { displayedUser, products, title, couponCode, defaultPaymentMethod, isModalOpenCallback } = props;


    return (
        <React.Fragment>
            {products &&
                <Card mb={6}>
                    <Box p={4} >
                        <Typography variant="h3" mb={3}>{title}</Typography>
                        <Divider style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} my={2}/>
                        {products.map((product, index) => {
                            return (
                                <React.Fragment key={product.id} >
                                    <ProductListItem
                                        product={product}
                                        allowSelection={props.allowSelection}
                                        company={props.company}
                                        couponCode={couponCode}
                                        displayedUser={displayedUser}
                                        isModalOpenCallback={isModalOpenCallback}
                                        paymentMethod={defaultPaymentMethod} />
                                    {products.length !== (index + 1) && <Divider style={{ width: 'calc(100% + 60px)' }} my={2}/>}
                                </React.Fragment>
                            )
                        })}
                    </Box>
                </Card>
            }
        </React.Fragment>

    );
}

export default ProductList;