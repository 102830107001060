class Role {
    constructor(id, name, description, companyId, isRoleForNewUsers, isEmployeeRole, claims) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.companyId = companyId;
        this.isRoleForNewUsers = isRoleForNewUsers;
        this.isEmployeeRole = isEmployeeRole;
        this.claims = claims;
    }
}

export default Role;