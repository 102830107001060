export const THEME_SET = "THEME_SET";

export function setTheme(theme, typography, company) {
  return {
    type: THEME_SET,
    theme: theme,
    typography: typography,
    company: company
  };
}
