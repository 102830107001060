import React from 'react'
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import { withTheme } from "styled-components/macro";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Typography as MuiTypography
} from "@material-ui/core";

// svxvy components 
import ProductCard from '../../../components/company/products/ProductCard';
import { compareObjects } from '../../../helpers/helperFunctions';


// constants
const Typography = styled(MuiTypography)(spacing);


const ShopPage = (props) => {
    const sellableItems = useSelector(state => state.products.products);
    // const products = company.products.sort(compareObjects('unitAmount', 'desc'));
    const company = useSelector(state => state.company.company);
    const products = sellableItems.filter(x => x.units === 0 && !x.isRecurring && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'));

    return (
        <React.Fragment>
            <Helmet title="Shop" />
            {products.length > 0 && <Typography variant='h2'>Products</Typography>}
            {products.map((product, index) => {
                return (
                    <ProductCard
                        key={product.id}
                        product={product}
                    />

                );
            })}
        </React.Fragment>
    );
}

export default withTheme(ShopPage);
