export const getFormData = (file, isMain = false, description) => {
  // as a shared library if this is null then we are in the mobile app.
  let formData = new FormData();
  if (!window.location) {
    let filename = file.split("/").pop();

    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;

    // Upload the image using the fetch and FormData APIs
    // Assume "photo" is the name of the form field the server expects
    formData.append("file", { uri: file, name: filename, type });
  } else {
    formData.append("file", file);
  }
  formData.append("isMain", isMain);
  if (description)
    formData.append("description", description);
  return formData;
};

export const formatImage = (url, size, format) => {
  if (!url) return "";
  const scale = size ? "w_" + size + ",c_scale/" : "/";
  const scaleImage = url.replace("/upload", "/upload/" + scale);
  const changeFormat = [
    scaleImage.slice(0, scaleImage.lastIndexOf(".")),
    format,
  ].join("");

  return changeFormat;
};
