import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from "formik"
import * as Yup from "yup";
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    CircularProgress,
    FormGroup,
    FormControlLabel as MuiFormControlLabel,
    Grid,
    MenuItem,
    Select,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypogrpahy,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoTrash as DeleteIcon,
} from "react-icons/io5";
// svxvy import
import { createAov, deleteAov, editAov, listAovs } from "../../../store/actions/activityOptionActions";
import { compareObjects } from '../../../helpers/helperFunctions';
import ActionButton from "../../framework/ActionButton";
import SelectFormControl from "../../framework/SelectFormControl";
import StyledDialog from '../../framework/StyledDialog';

// constants

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypogrpahy)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

const SmallTextField = styled(MuiTextField)`
    width: 54px;
    margin: 0 15px 0 0;

    & .MuiOutlinedInput-root {
        & input {
            padding: 13px 0 13px 0;
        }
    }
`;

const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0 26px 0 0;
    padding: 0 2px;
    font-weight: 400;
    font-size: 14px;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    &:last-child {
        margin: 0;
    }
`;

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    align-items: center;
`;



export const REMINDER = "REMINDER";
export const DESCRIPTION = "DESCRIPTION";

const SelectManageAov = (props) => {
    const { type, onSelect } = props;
    const theme = useTheme();

    const company = useSelector(state => state.company.company);

    const companyId = useSelector(state => state.company.companyId);
    const aovs = useSelector(state => state.activity.aovs).filter(x => x.type === type);
    aovs.push({ id: '-1', name: '', value: '' })
    aovs.sort(compareObjects('name', 'asc'));

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAov, setSelectedAov] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const showTypePlural = type === REMINDER ? "Reminders" : "Descriptions";
    const showType = type === REMINDER ? "Reminder" : "Description";

    const dispatch = useDispatch();

    const loadAovs = useCallback(async (companyId) => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(listAovs(companyId));
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false)
    }, [dispatch]);

    useEffect(() => {
        loadAovs(companyId)
    }, [companyId, loadAovs])


    const submitCallback = useCallback(async (values, resetForm, companyId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            // let aov = null;
            if (!values.id)
                await dispatch(createAov(companyId, values));
            else
                await dispatch(editAov(companyId, values.id, values));
            if (resetForm)
                resetForm();
            // handleSetSelectAov(aov.id);
            setShowEditor(false);
        } catch (error) {
            setError(error.message);
        }
        setIsSubmitting(false);
    }, [dispatch, setShowEditor]);

    const handleSubmit = (values, { resetForm }) => {
        const valuesToSend = cloneDeep(values);
        if (type === REMINDER)
            valuesToSend.reminderHours = parseInt(valuesToSend.reminderHours);
        submitCallback(valuesToSend, resetForm, companyId);
    };

    const deleteCallback = useCallback(async (companyId, offeringId, setOpen) => {
        setError(null);
        setIsDeleting(true);
        try {
            await dispatch(deleteAov(companyId, offeringId));
            setIsDeleting(false);
            if (setOpen)
                setOpen(false);
            setSelectedAov(null);
        } catch (error) {
            setIsDeleting(false);
            setError(error.message);
        }
    }, [dispatch]);


    const handleDelete = () => {
        deleteCallback(company.id, selectedAov.id, setShowEditor);
    };

    const handleSetSelectAov = (value) => {
        const aovToSet = aovs.find(x => x.id === value)
        setSelectedAov(aovToSet)
        if (onSelect)
            onSelect(aovToSet)
    }

    return (
        <React.Fragment>
            <Box>
                {isLoading &&
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <CircularProgress color="primary" size={20} />
                        <Typography ml={2}>Loading {showTypePlural}...</Typography>
                    </Box>
                }
                <Box display="flex" flexDirection="row" alignItems="center">
                    {aovs && aovs.length > 1 &&
                        <Box mr={4}>
                            <SelectFormControl
                                labelText={"Saved " + showTypePlural} labelId="select-interval-label"
                                style={{ minWidth: 175, maxWidth: 175 }}
                            >
                                <Select
                                    labelId="select-interval-label"
                                    id="selectedAov"
                                    style={{ minWidth: 175, maxWidth: 175 }}
                                    value={selectedAov ? selectedAov.id : null}
                                    label={"Saved " + showTypePlural} /// both this line and Input lavel
                                    onChange={(event) => handleSetSelectAov(event.target.value)}
                                >
                                    {aovs.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </SelectFormControl>
                        </Box>
                    }
                    {selectedAov &&
                        <ActionButton
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowEditor(true)}
                            mr={2}
                            mb={2}
                        >
                            Edit Saved {showType}
                        </ActionButton>
                    }
                    <ActionButton
                        variant="outlined"
                        color="primary"
                        onClick={() => { setSelectedAov(null); setShowEditor(true) }}
                        mb={2}
                    >
                        Add New Saved {showType}
                    </ActionButton>
                </Box>
            </Box>

            {showEditor &&
                <StyledDialog
                    open={showEditor}
                    setOpen={setShowEditor}
                    primaryAction={
                        <React.Fragment>
                            <ActionButton
                                form="aovForm"
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                my={3}
                                submitting={isSubmitting}
                            >
                                Save
                            </ActionButton>
                        </React.Fragment>
                    }
                    actions={
                        <UtilityButtonWrapper>
                            {selectedAov && <UtilityButton
                                type="submit"
                                onClick={handleDelete}
                                disabled={isSubmitting}
                                submitting={isDeleting}
                                ml={10}
                                startIcon={<DeleteIcon size={24} style={{ color: theme.palette.error.main }} />}
                            >
                                Delete
                            </UtilityButton>}
                        </UtilityButtonWrapper>
                    }
                >
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h1" component="h1" mb={3}>
                            Edit {showType}
                        </Typography>
                        <Formik
                            initialValues={{
                                id: selectedAov ? selectedAov.id : null,
                                name: selectedAov ? selectedAov.name : "",
                                text: selectedAov ? selectedAov.text : "",
                                reminderHours: selectedAov ? selectedAov.reminderHours : type === REMINDER ? 24 : null,
                                isDefault: selectedAov ? selectedAov.isDefault : aovs.length === 0 ? true : false,
                                type: type,
                            }
                            }
                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .max(27, "Must be 27 characters or less")
                                    .required("Required"),
                                text: Yup.string()
                                    .max(1500, "Must be 1500 characters or less")
                                    .required("Required"),
                                reminderHours: Yup.mixed().when('type', {
                                    is: REMINDER,
                                    then: Yup.number()
                                        .min(0, "Must be greater than 0")
                                        .required("Required")
                                }),
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setSubmitting,
                                touched,
                                values,
                                status,
                                setFieldValue,
                            }) => (
                                <React.Fragment>
                                    <form id="aovForm" onSubmit={handleSubmit}>
                                        <React.Fragment>
                                            <Box pt={3}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="name"
                                                            label={"Name - Not Shown to " + CUSTOMERS_TEXT}
                                                            value={values.name}
                                                            error={Boolean(touched.name && errors.name)}
                                                            fullWidth
                                                            helperText={touched.name && errors.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            variant="outlined"
                                                            mb={3}
                                                            placeholder="" />

                                                        {type === REMINDER &&
                                                            <Box style={{ borderTop: '1px solid ' + theme.palette.divider }} pt={3}>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    mb={2}
                                                                >To include the name of the {ACTIVITY_TEXT.toLowerCase()} use {'{'}NAME{'}'} to insert time use {'{'}TIME{'}'} </Typography>
                                                            </Box>
                                                        }
                                                        <TextField
                                                            name="text"
                                                            label={showType + " text"}
                                                            value={values.text}
                                                            error={Boolean(touched.text && errors.text)}
                                                            fullWidth
                                                            helperText={touched.text && errors.text}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            variant="outlined"
                                                            multiline
                                                            maxRows={10}
                                                            mb={3}
                                                            placeholder="" />
                                                        {type === REMINDER &&
                                                            <React.Fragment>

                                                                <Box display="flex" justifyContent="start" alignItems="center" flexWrap='wrap'>
                                                                    <Typography mx={1} my={3} color="textSecondary" >Send Reminder</Typography>
                                                                    <SmallTextField
                                                                        variant="outlined"
                                                                        name="reminderHours"
                                                                        // label="Can enroll ahead"
                                                                        value={values.reminderHours}
                                                                        error={Boolean(touched.reminderHours && errors.reminderHours)}
                                                                        helperText={touched.reminderHours && errors.reminderHours}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        margin="dense"
                                                                    />
                                                                    <Typography mr={3} color="textSecondary">hours before start</Typography>
                                                                </Box>
                                                                <Box mt={2} style={{ borderTop: '1px solid ' + theme.palette.divider }} />
                                                                <Box mx={2} mt={3} display="flex" flexDirection="column"  >
                                                                    <FormGroup>
                                                                        <FormControlLabel control={
                                                                            <Field name='isDefault' component={Switch} onChange={(val) => setFieldValue('isDefault', !values.isDefault)} checked={values.isDefault} />
                                                                        } label={'Is Default ' + showType} style={{ color: theme.palette.text.secondary }} />
                                                                    </FormGroup>
                                                                    <Typography mt={3} color="textSecondary">Setting this to the default will auto-populate this when creating a new {ACTIVITY_TEXT}</Typography>
                                                                </Box>
                                                            </React.Fragment>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {error && <Alert mt={2} severity="error">
                                                    {error}
                                                </Alert>}
                                            </Box>
                                        </React.Fragment>
                                    </form>
                                </React.Fragment>
                            )
                            }
                        </Formik >
                    </Box>
                </StyledDialog>
            }
        </React.Fragment >
    );
}

export default SelectManageAov;