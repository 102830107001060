export const LoctionStatus = {
    Open: "Open",
    Close: "Close",
    ByAppointment: "By Appointment Only"
};

class Location {
    constructor(id, name, notes, address, city, state, zip, companyId, hours, image, isDeleted, lat, lng, photo, isVirtual, timezone) {
        this.id = id;
        this.name = name;
        this.notes = notes;
        this.address = address;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.companyId = companyId;
        this.hours = hours;
        this.image = image;
        this.isDeleted = isDeleted;
        this.lat = lat;
        this.lng = lng;
        this.photo = photo;
        this.isVirtual = isVirtual;
        this.timezone = timezone;
    }
}

export class OperatingHours {
    constructor(id, rrule, startTime, endTime, status) {
        this.id = id;
        this.rrule = rrule;
        this.startTime = startTime;
        this.endTime = endTime;
        this.status = status === "" ? LoctionStatus.Open : status;
    }
}

export const DEFAULT_LOCATION_IMAGE = 'https://res.cloudinary.com/svxvy/image/upload/v1639060711/default_location_2_qapazz.png';

export const EMPTY_LOCATION_ID = '00000000-0000-0000-0000-000000000000';
export const EMPTY_LOCATION = new Location(EMPTY_LOCATION_ID, "Enter Location");


export const timezones = [
    {
      // 'America/Anchorage',
      // 'America/Juneau',
      // 'America/Sitka',
      // 'America/Metlakatla',
      // 'America/Yakutat',
      // 'America/Nome',
      // 'America/Adak',
        key:"US/Anchorage",
        value: "Alaska - Anchorage"
    },
    {
        key:"America/Phoenix",
        value: "Arizona"
    },
    {
        key:"America/Chicago",
        value: "Central Time"
    },
    {
        key:"America/New_York",
        value: "Eastern Time"
    },
    {
        key:"Pacific/Honolulu",
        value: "Hawaii"
    },
    {
        key:"America/Denver",
        value: "Mountain Time"
    },
    {
        key:"America/Los_Angeles",
        value: "Pacific Time"
    }
]

export default Location;