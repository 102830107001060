class Company {
    constructor(id, name, description, isProfilePhoneNumberRequired, isProfileNameAliasAllowed, sanitizedName, hideInMobileApp,
        locationText, locationsText, employeeText, employeesText, activityText, activitiesText, customerText, customersText, colors,
        websiteDomainName, logo, paymentServicePublicKey, companyContactEmail, passOnPaymentServiceFee, paymentServiceFeeDescription,
        hideBilling, phoneNumber, allowTexts, subscriptionStartDay, freeTrialPeriod, proRateMonth,
        people, webPages, locations, photos, offerings,
        instagramLink, facebookLink, twitterLink,
        vimeoLink, youTubeLink, tickTokLink,
        logoPhoto, products, inactiveProducts, 
        allowManualLocations,
        globalReminderMessage, globalReminderHours, faqs,
        googleClientId, googleCalendarAppApproved,
        allowCustomerCancelMembership, cancelTextMembershipText,
        waiverText, embedScripts, manuallySortProducts,
        hideDroppedActivityMessage,
        dropActivityWarningMessage,
        preDeadlineDropActivityMessage,
        postDeadlineDropActivityMessage,
        noDropActivityMessage,
        passwordResetMessage,
        accountRegisterEmailMessage,
        activityRegisterEmailMessage,
        activityDroppedEmailMessage,
        hideFullSelfRegisterActivities,
        usesStripe,
        usesWebsite,
        purchaseNotificationEmails,
        allowedCustomerCount,
        overrideAllowedCustomerCount,
        reviews,
        manualLocationTimezone,
        isRABSite,
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.isProfilePhoneNumberRequired = isProfilePhoneNumberRequired;
        this.isProfileNameAliasAllowed = isProfileNameAliasAllowed;
        this.sanitizedName = sanitizedName;
        this.hideInMobileApp = hideInMobileApp;

        this.locationText = locationText;
        this.locationsText = locationsText;
        this.employeeText = employeeText;
        this.employeesText = employeesText;
        this.activityText = activityText;
        this.activitiesText = activitiesText;
        this.customerText = customerText;
        this.customersText = customersText;
        this.colors = colors;
        this.websiteDomainName = websiteDomainName;
        this.logo = logo;
        this.logoPhoto = logoPhoto;
        this.paymentServicePublicKey = paymentServicePublicKey;
        this.companyContactEmail = companyContactEmail;
        this.passOnPaymentServiceFee = passOnPaymentServiceFee;
        this.paymentServiceFeeDescription = paymentServiceFeeDescription;
        this.hideBilling = hideBilling;
        this.phoneNumber = phoneNumber;
        this.allowTexts = allowTexts;
        this.subscriptionStartDay = subscriptionStartDay;
        this.freeTrialPeriod = freeTrialPeriod;
        this.proRateMonth = proRateMonth;
        this.people = people;
        this.webPages = webPages;
        this.locations = locations;
        this.photos = photos;
        this.offerings = offerings;
        this.instagramLink = instagramLink;
        this.facebookLink = facebookLink;
        this.twitterLink = twitterLink;
        this.vimeoLink = vimeoLink;
        this.youTubeLink = youTubeLink;
        this.tickTokLink = tickTokLink;
        this.products = products;
        this.inactiveProducts = inactiveProducts;
        this.allowManualLocations = allowManualLocations;
        this.globalReminderMessage = globalReminderMessage;
        this.globalReminderHours = globalReminderHours;
        this.faqs = faqs;
        this.googleClientId = googleClientId;
        this.googleCalendarAppApproved = googleCalendarAppApproved;
        this.allowCustomerCancelMembership = allowCustomerCancelMembership;
        this.cancelTextMembershipText = cancelTextMembershipText;
        this.waiverText = waiverText;
        this.embedScripts = embedScripts;
        this.manuallySortProducts = manuallySortProducts;
        this.hideDroppedActivityMessage = hideDroppedActivityMessage;
        this.dropActivityWarningMessage = dropActivityWarningMessage;
        this.preDeadlineDropActivityMessage = preDeadlineDropActivityMessage;
        this.postDeadlineDropActivityMessage = postDeadlineDropActivityMessage;
        this.noDropActivityMessage = noDropActivityMessage;
        this.passwordResetMessage = passwordResetMessage;
        this.accountRegisterEmailMessage = accountRegisterEmailMessage;
        this.activityRegisterEmailMessage = activityRegisterEmailMessage;
        this.activityDroppedEmailMessage = activityDroppedEmailMessage;
        this.hideFullSelfRegisterActivities = hideFullSelfRegisterActivities;
        this.usesStripe = usesStripe;
        this.usesWebsite = usesWebsite;
        this.purchaseNotificationEmails = purchaseNotificationEmails;
        this.allowedCustomerCount = allowedCustomerCount;
        this.overrideAllowedCustomerCount = overrideAllowedCustomerCount;
        this.reviews = reviews;
        this.manualLocationTimezone = manualLocationTimezone;
        this.isRABSite = isRABSite;
    }
}

export const DEFAULT_COMPANY_LOGO = 'https://res.cloudinary.com/svxvy/image/upload/v1614188671/default_company_w6rwep.png'

export const ACTIVITY_DEFAULT_TEXT = "Activity";
export const ACTIVITIES_DEFAULT_TEXT = "Activities";
export const EMPLOYEE_DEFAULT_TEXT = "Employee";
export const EMPLOYEES_DEFAULT_TEXT = "Employees";
export const LOCATION_DEFAULT_TEXT = "Location";
export const LOCATIONS_DEFAULT_TEXT = "Locations";
export const CUSTOMER_DEFAULT_TEXT = "Customer";
export const CUSTOMERS_DEFAULT_TEXT = "Customers";

export default Company;