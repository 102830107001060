
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    LinearProgress as MuiLinearProgress,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";


// svxvy imports
import { fetchProducts } from "../../../store/actions/productActions";
import { compareObjects } from '../../../helpers/helperFunctions'
import { detailPerson } from "../../../store/actions/peopleActions";

import ProductList from "./ProductsList";
import StyledDialog from "../../framework/StyledDialog"
import BuyAProductModal from "./BuyAProductModal";
import StripeWrapper from "../paymentMethods/StripeWrapper";
import CustomPurchaseModal from "./CustomPurchaseModal";
//consants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const PurchaseProductModal = props => {
    const { open, setOpen, displayedUser, isChildAccount } = props;
    const displayedUserId = displayedUser.id;

    const company = useSelector(state => state.company.company);
    const currentUser = useSelector(state => state.auth.person);

    const parentAccount = useSelector(state => state.company.people).find(x => x.id === displayedUser.parentAccountId);

    let defaultPaymentMethod = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.find(x => x.isDefault && x.companyId === company.id) : null;
    if (!defaultPaymentMethod && displayedUser.parentAccountId === currentUser.id)
        defaultPaymentMethod = currentUser.paymentMethods ? currentUser.paymentMethods.find(x => x.isDefault && x.companyId === company.id) : null;
    else if (!defaultPaymentMethod && parentAccount)
        defaultPaymentMethod = parentAccount.paymentMethods ? parentAccount.paymentMethods.find(x => x.isDefault && x.companyId === company.id) : null;

    const allProducts = useSelector(state => state.products.products);
    const memberships = allProducts.filter(x => x.isRecurring && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))
    const packages = allProducts.filter(x => x.units > 0 && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))
    const products = allProducts.filter(x => !x.isRecurring && x.units === 0 && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', company.manuallySortProducts ? 'asc' : 'desc'))

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [showBuyAProductModal, setShowBuyAProductModal] = useState(false)
    const [showCustomPurchaseModal, setShowCustomPurchaseModal] = useState(false)

    const dispatch = useDispatch();
    const loadSvxvyProducts = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(fetchProducts(company.id));
            if (currentUser.id !== displayedUserId && !isChildAccount)
                await dispatch(detailPerson(displayedUserId, company.id, true));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch, company, currentUser, displayedUserId, isChildAccount]);

    useEffect(() => {
        loadSvxvyProducts();
    }, [loadSvxvyProducts]);

    return (
        <React.Fragment>
            <StyledDialog
                open={open}
                setOpen={setOpen}
            >
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                {isLoading && !error &&
                    <React.Fragment>
                        <LinearProgress my={2} color="primary" />
                        <Typography variant={"h2"} align="center">Loading Products...</Typography>
                        <LinearProgress my={2} color="secondary" />
                    </React.Fragment>
                }
                {!isLoading &&
                    <React.Fragment>
                        <React.Fragment>
                            {!company.isRABSite &&
                                <ProductList
                                    company={company}
                                    displayedUser={displayedUser}
                                    products={memberships}
                                    title="Recurring Memberships"
                                    defaultPaymentMethod={defaultPaymentMethod}
                                />
                            }
                            {!company.isRABSite &&
                                <ProductList
                                    company={company}
                                    displayedUser={displayedUser}
                                    products={packages}
                                    title="Packages"
                                    defaultPaymentMethod={defaultPaymentMethod}
                                />
                            }
                            <ProductList
                                company={company}
                                displayedUser={displayedUser}
                                products={products}
                                title="Products"
                                defaultPaymentMethod={defaultPaymentMethod}
                            />
                            <Card mb={6}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" p={4} >
                                    <Typography variant="h3" mb={3}>Custom Purchase</Typography>
                                    <Button variant="outlined" color="primary" onClick={() => setShowCustomPurchaseModal(true)} mr={8}>Buy</Button>
                                </Box>
                            </Card>
                        </React.Fragment>
                    </React.Fragment>
                }
            </StyledDialog>
            {showBuyAProductModal &&
                <StripeWrapper showTenantPaymentMethods open={showBuyAProductModal}>
                    <BuyAProductModal
                        open={showBuyAProductModal}
                        setOpen={setShowBuyAProductModal}
                        // product={product}
                        displayedUser={displayedUser}
                    />

                </StripeWrapper>
            }
            {showCustomPurchaseModal &&
                <StripeWrapper showTenantPaymentMethods open={showCustomPurchaseModal}>
                    <CustomPurchaseModal
                        open={showCustomPurchaseModal}
                        setOpen={setShowCustomPurchaseModal}
                        displayedUser={displayedUser}
                    />
                </StripeWrapper>
            }
        </React.Fragment>
    )
}

export default PurchaseProductModal;