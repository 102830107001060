import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import parse from 'html-react-parser';
import i18n from 'i18n-js';
import moment from 'moment';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
import { formatImage } from '../../../helpers/imageHelpers';
import { getActiveSubscriptionObjects } from '../../../helpers/commerceFunctions';
import { ACTIVE_MEMBERSHIPS_STATUSES } from '../../../models/Purchase';

import ActionButton from "../../framework/ActionButton";
import BuyAProductModal from "./BuyAProductModal";
import StripeWrapper from "../paymentMethods/StripeWrapper";
import StyledDialog from "../../framework/StyledDialog";
import ParagraphTypography from "../../framework/ParagraphTypography";
import ManagePaymentMethods from '../paymentMethods/ManagePaymentMethods';

// constants
const Typography = styled(MuiTypography)(spacing);

const Image = styled(Box)`
    height: 150px;
    width: 200px;
`;


const ProductListItem = (props) => {
    const { product, displayedUser, isModalOpenCallback } = props;
    const theme = useTheme();
    const company = useSelector(state => state.company.company);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const [showTerms, setShowTerms] = useState(false);
    const [showBuyAProductModal, setShowBuyAProductModal] = useState(false)

    const primaryPhoto = product && product.photos && product.photos.length > 0 ? product.photos[0] : null;

    const currentSubscriptions = getActiveSubscriptionObjects(displayedUser.purchases);
    const activeSubscription = currentSubscriptions.find(x => x.purchasedProduct.id === product.id && x.expirationDate && new Date(x.expirationDate) >= new Date());
    const errorSubscription = currentSubscriptions.find(x => x.purchasedProduct.id === product.id && (((x.expirationDate && x.expirationDate < new Date() && x.automaticallyRenew) || (!ACTIVE_MEMBERSHIPS_STATUSES.includes(x.status) && !(x.status === "canceled"))) && x.purchasedProduct && x.purchasedProduct.isRecurring));
    const [showManagePaymentMethods, setShowManagePaymentMethods] = useState(false)

    // if (product.isRecurring) {
    //     console.log(currentSubscriptions.map(x => x.purchasedProduct.id));
    //     console.log(product.id, activeSubscription)
    //     console.log(product.id, errorSubscription)
    // }

    const handleBuyProduct = () => {
        setShowBuyAProductModal(true);
    }

    useEffect(() => {
        if (isModalOpenCallback)
            isModalOpenCallback(showBuyAProductModal);
    }, [showBuyAProductModal, isModalOpenCallback]);

    return (
        <React.Fragment>
            <Box >
                <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'}>
                    {primaryPhoto &&
                        <Box width={isMobile ? 'auto' : 200} height={150} mr={isMobile ? 0 : 2} mb={isMobile ? 2 : 0}>
                            <Image style={{ backgroundImage: 'url(' + formatImage(primaryPhoto.url, null, '.webp') + ') ', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }} />
                        </Box>
                    }
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h3" align={isMobile ? "center" : "left"} mb={2}>{product.name}</Typography>
                        {product.freeTrialDays !=null &&  product.freeTrialDays > 0 && <Typography variant="body2" mb={2}>This membership offers a {product.freeTrialDays} day free trial.</Typography>}
                        {product.description && <ParagraphTypography variant="body2" component="p">{parse(product.description)}</ParagraphTypography>}
                        {product.terms && <Typography
                            variant="subtitle1"
                            style={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
                            onClick={() => setShowTerms(true)}
                            mt={1}>View Terms and Conditions</Typography>}
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" ml={isMobile ? 0 : 4} mt={isMobile ? 4 : 0} minWidth={150}>
                        {product.externalUrl && <Button variant="outlined" color="primary"
                            component="a"
                            href={product.externalUrl}
                            target="_blank">
                            Purchase
                        </Button>}
                        {!product.isRecurring && product.units === 0 && !product.externalUrl &&
                            <ActionButton
                                variant="outlined"
                                onClick={handleBuyProduct}
                                color="primary"
                            >
                                <Box display="flex" flexDirection="column">
                                    <Typography>
                                        {i18n.toCurrency(product.unitAmount, { precision: 2, unit: '$' })}
                                        {product.isRecurring ? " / " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : ""}
                                    </Typography>
                                    Buy
                                </Box>
                            </ActionButton>
                        }
                        {((product.isRecurring && ((!activeSubscription || activeSubscription.expirationDate < Date.now()) && !errorSubscription))
                            || product.units > 0) && !product.externalUrl &&
                            <Box display="flex" flexDirection="column" maxWidth={150}>
                                <ActionButton
                                    variant="outlined"
                                    onClick={() => handleBuyProduct()}
                                    color="primary"
                                >
                                    <Box display="flex" flexDirection="column">
                                        <Typography>
                                            {i18n.toCurrency(product.unitAmount, { precision: 2, unit: '$' })}
                                            {product.isRecurring ? " / " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : ""}
                                        </Typography>
                                        <Typography>
                                            {product.isRecurring ? 'Subscribe' : 'Buy'}
                                        </Typography>
                                    </Box>
                                </ActionButton>
                            </Box>
                        }
                        {activeSubscription &&
                            <Box display="flex" flexDirection="column" maxWidth={150}>
                                <Typography>{activeSubscription.automaticallyRenew ? 'Renews' : (activeSubscription.expirationDate > Date.now() ? 'Finishes' : 'Finished')} {moment(activeSubscription.expirationDate).format('ll')}</Typography>
                            </Box>
                        }
                        {errorSubscription &&
                            <ActionButton
                                variant="outlined"
                                onClick={() => setShowManagePaymentMethods(true)}
                                color="primary"
                            >
                                <Box display="flex" flexDirection="column" maxWidth={150}>
                                    <Typography>Past Due - {moment(errorSubscription.expirationDate).format('ll')}</Typography>
                                    <Typography>Add new default credit card.</Typography>
                                    <Typography>Will retry charge in a few days.</Typography>
                                </Box>
                            </ActionButton>
                        }
                    </Box>
                </Box>
            </Box>
            {showBuyAProductModal &&
                <StripeWrapper showTenantPaymentMethods open={showBuyAProductModal}>
                    <BuyAProductModal
                        open={showBuyAProductModal}
                        setOpen={setShowBuyAProductModal}
                        product={product}
                        displayedUser={displayedUser}
                    />
                </StripeWrapper>
            }
            {showTerms && <StyledDialog
                open={showTerms}
                setOpen={setShowTerms}>
                <ParagraphTypography>
                    {product.terms}
                </ParagraphTypography>
            </StyledDialog>}
            {showManagePaymentMethods &&
                <ManagePaymentMethods
                companyId={company.id}
                userId={displayedUser.id}
                showTenantPaymentMethods={true}
                open={showManagePaymentMethods}
                setOpen={setShowManagePaymentMethods}
                dontRefreshLoggedInUser={true}
            />}
        </React.Fragment >
    );
};

export default ProductListItem;
