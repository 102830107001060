import { cloneDeep } from 'lodash';
import Location from '../../models/Location';

import { SET_COMPANY_NAME, SET_COMPANY_BASE_DATA, SET_COMPANY_ATTIBUTES, ADD_EDIT_COMPANY_LOCATION, SET_COMPANY_OWNER, RESET_REGISTRATION } from '../actions/registerCompanyActions';
import { ACTIVITY, ACTIVITIES, EMPLOYEE, EMPLOYEES, LOCATION, LOCATIONS, CUSTOMER, CUSTOMERS } from './companyReducer';

const initialState = {
    companyId: '',
    companyName: '',
    description: '',
    plan: '',
    locationText: LOCATION,
    locationsText: LOCATIONS,
    employeeText: EMPLOYEE,
    employeesText: EMPLOYEES,
    activityText: ACTIVITY,
    activitiesText: ACTIVITIES,
    customerText: CUSTOMER,
    customersText: CUSTOMERS,
    isProfileNameAliasAllowed: false,
    isProfilePhoneNumberRequired: false,
    companyContactEmail: '',
    phoneNumber: '',
    allowTexts: false,
    locations: []
}

const addEditLocation = (idToUpdate, location, locations) => {
    let updatedLocations = cloneDeep(locations);
    let locationToUpdate = updatedLocations.find(item => item.id === idToUpdate);
    if (!locationToUpdate) {
        updatedLocations.push(location);
    }
    else {
        locationToUpdate.name = location.name;
        locationToUpdate.address = location.address;
        locationToUpdate.city = location.city;
        locationToUpdate.state = location.state;
        locationToUpdate.zip = location.zip;
        locationToUpdate.notes = location.notes;
        locationToUpdate.hours = location.hours;
        locationToUpdate.companyId = location.companyId;
    }

    return updatedLocations;
}



const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY_NAME:
            return { ...state, companyName: action.companyName };
        case SET_COMPANY_BASE_DATA:
            const data = { ...state, ...action.attributes };
            return data;
        case SET_COMPANY_OWNER:
            return { ...state, ...action.attributes };
        case SET_COMPANY_ATTIBUTES:
            return { ...state, ...action.attributes }
        case ADD_EDIT_COMPANY_LOCATION:
            const l = new Location(
                    action.attributes.id,
                    action.attributes.name, 
                    "", 
                    action.attributes.address, 
                    action.attributes.city, 
                    action.attributes.state, 
                    action.attributes.zip, 
                    state.companyId, null, null,
                    false, 
                    0,
                    0,
                    null,
                    action.attributes.isVirtual,
                    action.attributes.timezone,);
            return { ...state, locations: addEditLocation(action.attributes.id, l, state.locations) };
        case RESET_REGISTRATION:
            return initialState;
        default:
            return state;
    }
}

export default registrationReducer;