import { LOGOUT } from '../actions/authActions';

const initialState = {
    addEditEntityRoles: ['Owner']
};

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {...initialState};
        default:
            return state;
    }
};

export default permissionsReducer;