import React from "react";
import { useSelector } from "react-redux";


import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

// svxvy import
import * as NavigationConstants from "../../constants/NavigationConstants";

// UI Consts
const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Page404 = props => {
  const loggedInUser = useSelector(state => state.auth.person)

  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Box display="grid">
        <Typography variant="h1" align="center">Ooops... </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          We couldn't find this business.
        </Typography>
        <Box minWidth={200}>
          <Button
            component={Link}
            to={NavigationConstants.REGISTER}
            variant="contained"
            color="primary"
            mt={2}
            fullWidth
            mx="auto"
          >
            Would you like to register it?
          </Button>
          {loggedInUser && <Button
            component={Link}
            to={NavigationConstants.LOOKUP_COMPANY}
            variant="outlined"
            color="primary"
            mt={2}
            fullWidth
            mx="auto"
          >
            Go to my list of businesses
          </Button>
          }
          {!loggedInUser && <Button
            component={Link}
            to={"/"}
            variant="outlined"
            color="primary"
            mt={2}
            fullWidth
            mx="auto"
          >
            Login In to SmartCore
          </Button>
          }
        </Box>
      </Box>
    </Wrapper>
  );
}

export default Page404;
