import * as NavigationConstants from "../constants/NavigationConstants";
import * as SecurityConstants from "../constants/SecurityConstants";
import { isLiveSite } from '../constants/NetworkConstants'

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoHomeOutline as HomeIcon,
  IoGlobeOutline as WebIcon,
  // IoHomeOutline as HomeIcon,
  IoCalendarOutline as CalendarIcon,
  IoChatboxEllipsesOutline as MessageIcon,
  IoCashOutline as BillingIcon,
  IoPeopleOutline as UserIcon,
  IoPeopleCircleOutline as FamilyIcon,
  IoSettingsOutline as ManageIcon,
  IoAnalyticsOutline as ReportIcon,
  IoHelpCircleOutline as FaqIcon,
} from "react-icons/io5";


import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

// Guards
import SvxvyAuthGuard from "../components/svxvyNavigation/SvxvyAuthGuard";

//full pages
import SvxvyLanding from "../pages/svxvyPages/Home.js";
import RegisterCompany from "../pages/svxvyPages/RegisterCompany.js";
import CompanyLookup from "../pages/svxvyPages/CompanyList.js";
import CompanyNotFound404 from "../pages/errorPages/CompanyNotFound";

//dashboard pages
import Dashboard from "../pages/company/Dashboard.js";
import Activities from "../pages/company/Activities.js";
import Profiles from "../pages/company/profile/Profiles.js";
import ProfileView from "../pages/company/profile/Profile.js";
import Messages from "../pages/company/Messages.js";

//company website pages
import CompanyLanding from "../pages/company/publicPages/CompanyLanding.js";
import Offerings from "../pages/company/publicPages/OfferingsPage";
import Schedule from "../pages/company/publicPages/SchedulePage";
import ConfirmActivity from "../pages/company/publicPages/ConfirmActivity";
import Employees from "../pages/company/publicPages/EmployeesPage";
import Membership from "../pages/company/publicPages/MembershipPage";
import Shop from "../pages/company/publicPages/ShopPage";
import Gallery from "../pages/company/publicPages/GalleryPage";
import Faqs from "../pages/company/publicPages/FaqsPage";
import Locations from "../pages/company/publicPages/LocationsPage";
import Contact from "../pages/company/publicPages/ContactPage";
import Privacy from "../pages/company/publicPages/Privacy";

// Report Pages
import Billing from "../pages/company/reports/Billing";
import MembershipReport from "../pages/company/reports/Membership";
import PackageReport from "../pages/company/reports/PackageReport";
// import MyPriorPurchases from "../pages/company/reports/MyPriorPurchases";
// import MonthlyIncome from "../pages/company/reports/MonthlyIncome";

// Company User Pages
import Login from "../pages/company/account/Login";
import AccountRegister from "../pages/company/account/AccountRegister";
import MyProfile from "../pages/company/account/MyProfile";
import MyFamily from "../pages/company/account/MyFamily";
import MyFiles from "../pages/company/account/MyFiles";
import MySubscriptions from "../pages/company/account/MySubscriptions";
import MyPaymentMethods from "../pages/company/account/MyPaymentMethods";

// Company Settings Pages
// import MemberCheckin from "../pages/company/manage/MemberCheckin";
import CompanyBranding from "../pages/company/manage/CompanyBranding";
import CompanySettings from "../pages/company/manage/CompanySettings.js";
import CompanyAdvancedSettings from "../pages/company/manage/CompanyAdvancedSettings.js";
import ManageLocations from "../pages/company/manage/ManageLocations.js";
import ManageReviews from "../pages/company/manage/ManageReviews.js";
import ManageMembershipsAndPackages from "../pages/company/manage/ManageMembershipsAndPackages";
import ManageProducts from "../pages/company/manage/ManageProducts";
import PaymentServiceSettings from "../pages/company/manage/PaymentServiceSettings";
import SubscriptionManage from "../pages/company/manage/SubscriptionManage.js";

const svxvyLandingRoutes = {
  id: "Landing Page",
  path: NavigationConstants.HOME,
  header: "Pages",
  icon: null,
  component: SvxvyLanding,
  children: null
};

const svxvyListCompaniesRoutes = {
  id: "Landing Page",
  path: "/" + NavigationConstants.LOOKUP_COMPANY,
  header: "Pages",
  icon: null,
  component: CompanyLookup,
  guard: SvxvyAuthGuard,
  children: null
};

const companyRegisterRoute = {
  id: "CompanyRegister",
  path: "/" + NavigationConstants.REGISTER,
  header: "Pages",
  icon: null,
  containsHome: true,
  children: null,
  component: RegisterCompany,
};

const svxvyCompanyNotFoundRoutes = {
  id: "404",
  path: "/" + NavigationConstants.COMPANY_NOT_FOUND,
  header: "Pages",
  icon: null,
  component: CompanyNotFound404,
  children: null
};

//////////////////////////////////// company pages 

//////////////////////////////////// Public Pages
const companyLandingRoute = {
  id: NavigationConstants.HOME_ID,
  path: NavigationConstants.COMPANY,
  isHome: true,
  icon: <WebIcon />,
  containsHome: true,
  children: null,
  component: CompanyLanding,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: false
};

const offeringsRoutes = {
  id: NavigationConstants.OFFERINGS,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.OFFERINGS,
  header: null,
  containsHome: true,
  icon: null,
  component: Offerings,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: false
};

const scheduleRoutes = {
  id: NavigationConstants.SCHEDULE,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.SCHEDULE,
  header: null,
  containsHome: true,
  icon: null,
  component: Schedule,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: false
};

const confirmRoutes = {
  id: NavigationConstants.ACITVITY_CONFIRM,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.SCHEDULE + "/:activityID",
  header: null,
  containsHome: true,
  icon: null,
  component: ConfirmActivity,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: true,
  hideBanner: true,

};

const employeesRoutes = {
  id: NavigationConstants.EMPLOYEES,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.EMPLOYEES,
  header: null,
  containsHome: true,
  icon: null,
  component: Employees,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: false
};

const membershipRoutes = {
  id: NavigationConstants.MEMBERSHIP,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.MEMBERSHIP,
  header: null,
  containsHome: true,
  icon: null,
  component: Membership,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: false
};

const shopRoutes = {
  id: NavigationConstants.SHOP,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.SHOP,
  header: null,
  containsHome: true,
  icon: null,
  component: Shop,
  children: null,
  isPortalPage: false,
  isPrimaryPage: true,
  hideInNavigation: false
};

const faqRoutes = {
  id: NavigationConstants.FAQ,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.FAQ,
  header: null,
  containsHome: true,
  icon: null,
  component: Faqs,
  children: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: false
};

const galleryRoutes = {
  id: NavigationConstants.GALLERY,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.GALLERY,
  header: null,
  containsHome: true,
  icon: null,
  component: Gallery,
  children: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: false
};



const locationsRoutes = {
  id: NavigationConstants.LOCATIONS,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.LOCATIONS,
  header: null,
  containsHome: true,
  icon: null,
  component: Locations,
  children: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: false
};

const contactRoutes = {
  id: NavigationConstants.CONTACT,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.CONTACT,
  header: null,
  containsHome: true,
  icon: null,
  component: Contact,
  children: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: false
};

const privacyRoutes = {
  id: NavigationConstants.PRIVACY_POLICY,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.PRIVACY_POLICY,
  header: null,
  containsHome: true,
  icon: null,
  component: Privacy,
  children: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: true
};

const loginRoutes = {
  id: "Login",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.LOGIN,
  header: null,
  containsHome: true,
  icon: null,
  component: Login,
  children: null,
  guard: null,
  guardClaim: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: true,
  hideChrome: true,
};

const registerAccountRoutes = {
  id: "AccountRegister",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.USER_REGISTER,
  header: null,
  containsHome: true,
  icon: null,
  component: AccountRegister,
  children: null,
  guard: null,
  guardClaim: null,
  isPortalPage: false,
  isPrimaryPage: false,
  hideInNavigation: true,
  hideChrome: true,
};

/// portal Pages
const dashboardRoutes = {
  id: NavigationConstants.DASHBOARD,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.DASHBOARD,
  icon: <HomeIcon />,
  containsHome: true,
  children: null,
  component: Dashboard,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true,
  isPrimaryPage: true,
};

const companyRoutes = {
  id: NavigationConstants.ACTIVITIES,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.ACTIVITIES,
  icon: <CalendarIcon />,
  containsHome: true,
  children: null,
  component: Activities,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true,
  isPrimaryPage: true,
};

const customersRoutes = {
  id: "Profiles",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.CUSTOMERS,
  header: null,
  containsHome: true,
  icon: <UserIcon />,
  component: Profiles,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: SecurityConstants.VIEW_ALL_PEOPLE,
  isPortalPage: true,
  isPrimaryPage: true,
};

const customersProfileDisplay = {
  id: NavigationConstants.EMPLOYEES,
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.CUSTOMERS + "/" + NavigationConstants.PROFILE_VIEW,
  header: null,
  containsHome: true,
  icon: <UserIcon />,
  component: ProfileView,
  children: null,
  isPortalPage: true
};

const notificationRoutes = {
  id: "Messages",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.MESSAGES,
  header: null,
  containsHome: true,
  icon: <MessageIcon />,
  component: Messages,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true,
  isPrimaryPage: true,

};

const portalFaqRoutes = {
  id: "FAQ",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.PORTAL_FAQ,
  header: null,
  containsHome: true,
  icon: <FaqIcon />,
  component: Faqs,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true,
  isPrimaryPage: false,

};

const billingRoutes = {
  id: "Billing",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.OUTSTANDING_PAYMNETS,
  header: null,
  containsHome: true,
  icon: <BillingIcon />,
  component: Billing,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true,
  isPrimaryPage: true,

};


const manageRoutes = {
  id: "Manage",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE,
  icon: <ManageIcon />,
  containsHome: true,
  isPortalPage: true,
  children: [
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.COMPANY_BRANDING,
      name: "Company Branding",
      component: CompanyBranding,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.COMPANY_SETTINGS,
      name: "Company Settings",
      component: CompanySettings,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.COMPANY_ADVANCED_SETTINGS,
      name: "Advanced Settings",
      component: CompanyAdvancedSettings,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    // {
    //   path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.MEMBER_CHECKIN,
    //   name: "Check In",
    //   component: MemberCheckin,
    //   guard: SvxvyAuthGuard,
    //   guardClaim: SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES,
    //   isPortalPage: true
    // },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.MANAGE_LOCATIONS,
      name: NavigationConstants.MANAGE_LOCATIONS,
      component: ManageLocations,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.MANAGE_REVIEWS,
      name: "Reviews",
      component: ManageReviews,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.PAYMENT_INTEGARATION,
      name: "Payment Settings",
      component: PaymentServiceSettings,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.MEMBERSHIPS_AND_PACKAGES,
      name: "Memberships / Packages",
      component: ManageMembershipsAndPackages,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.MANAGE_PRODUCTS,
      name: "Products",
      component: ManageProducts,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.MANAGE + "/" + NavigationConstants.SUBSCRIPTION,
      name: "Smartcore Subscription",
      component: SubscriptionManage,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
      isPortalPage: true
    },
  ],
  guard: SvxvyAuthGuard,
  guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES
};

// const membershipReportsRoutes = {
//   id: "Membership Report",
//   path: NavigationConstants.COMPANY + "/" + NavigationConstants.MEMBERSHIP_REPORTS,
//   header: null,
//   containsHome: true,
//   icon: <BillingIcon />,
//   component: MembershipReport,
//   children: null,
//   guard: SvxvyAuthGuard,
//   guardClaim: SecurityConstants.ALLOW_EDIT_COMPANY_ATTRIBUTES,
//   isPortalPage: true,
//   isPrimaryPage: false,
// };


const membershipReportsRoutes = {
  id: "Reporting",
  path: NavigationConstants.COMPANY + "/" + NavigationConstants.REPORTS,
  icon: <ReportIcon />,
  containsHome: true,
  isPortalPage: true,
  guard: SvxvyAuthGuard,
  guardClaim: SecurityConstants.VIEW_REPORTS,
  children: [
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.REPORTS + "/" + NavigationConstants.MEMBERSHIP_REPORTS,
      name: "Memberships",
      component: MembershipReport,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.UPDATE_PAYMENTS,
      isPortalPage: true
    },
    {
      path: NavigationConstants.COMPANY + "/" + NavigationConstants.REPORTS + "/" + NavigationConstants.PACKAGE_REPORTS,
      name: "Packages",
      component: PackageReport,
      guard: SvxvyAuthGuard,
      guardClaim: SecurityConstants.UPDATE_PAYMENTS,
      isPortalPage: true
    },
    
  ]
}

const profileEditRoutes = {
  id: NavigationConstants.MY_PROFILE,
  path: NavigationConstants.MY_PROFILE,
  header: null,
  icon: <AccountCircleOutlinedIcon />,
  component: MyProfile,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true
};

const familyEditRoutes = {
  id: NavigationConstants.MY_FAMILY,
  path: NavigationConstants.MY_FAMILY,
  header: null,
  icon: <FamilyIcon />,
  component: MyFamily,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true
};

const profileFileEditRoutes = {
  id: NavigationConstants.MY_FILES,
  path: NavigationConstants.MY_FILES,
  header: null,
  icon: <AccountCircleOutlinedIcon />,
  component: MyFiles,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true
};

const mySubscriptionsRoutes = {
  id: NavigationConstants.MY_SUBSCRIPTIONS,
  path: NavigationConstants.MY_SUBSCRIPTIONS,
  header: null,
  icon: <AccountCircleOutlinedIcon />,
  component: MySubscriptions,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true
};

const myPaymentMethodRoutes = {
  id: NavigationConstants.MY_PAYMENT_METHODS,
  path: NavigationConstants.MY_PAYMENT_METHODS,
  header: null,
  icon: <AccountCircleOutlinedIcon />,
  component: MyPaymentMethods,
  children: null,
  guard: SvxvyAuthGuard,
  guardClaim: null,
  isPortalPage: true
};


/////////////////////////////////////////// svxvy routes ///////////////////////////////////////////////

/////////////////////////////////// svxvy changes //////////////////////////////////////////
// Routes using the Presentation layout
export const svxvyPageRoutes = [
  svxvyLandingRoutes,
  svxvyListCompaniesRoutes,
  companyRegisterRoute,
  svxvyCompanyNotFoundRoutes,
];

// Using the dashboard route
export const companyLayoutRoutes = isLiveSite() ? [
  // portal routes
  dashboardRoutes,
  companyRoutes,
  profileEditRoutes,
  familyEditRoutes,
  profileFileEditRoutes,
  mySubscriptionsRoutes,
  myPaymentMethodRoutes,
  customersRoutes,
  customersProfileDisplay,
  notificationRoutes,
  billingRoutes,
  membershipReportsRoutes,
  portalFaqRoutes,
  manageRoutes,
  // beleow tihs is public routes
  offeringsRoutes,
  scheduleRoutes,
  confirmRoutes,
  employeesRoutes,
  membershipRoutes,
  shopRoutes,
  faqRoutes,
  galleryRoutes,
  locationsRoutes,
  contactRoutes,
  privacyRoutes,
  loginRoutes,
  registerAccountRoutes,
  companyLandingRoute, // this should always be last
] :
  [
    // portal routes
    dashboardRoutes,
    companyRoutes,
    profileEditRoutes,
    familyEditRoutes,
    profileFileEditRoutes,
    mySubscriptionsRoutes,
    myPaymentMethodRoutes,
    customersRoutes,
    customersProfileDisplay,
    notificationRoutes,
    billingRoutes,
    membershipReportsRoutes,
    portalFaqRoutes,
    manageRoutes,
    // beleow tihs is public routes
    offeringsRoutes,
    scheduleRoutes,
    confirmRoutes,
    employeesRoutes,
    membershipRoutes,
    shopRoutes,
    faqRoutes,
    galleryRoutes,
    locationsRoutes,
    contactRoutes,
    privacyRoutes,
    loginRoutes,
    registerAccountRoutes,
    companyLandingRoute, // this should always be last
  ];

export const companySidebarRoutes = isLiveSite() ? [
  dashboardRoutes,
  manageRoutes,
  companyRoutes,
  notificationRoutes,
  billingRoutes,
  customersRoutes,
  membershipReportsRoutes,
  contactRoutes,
  portalFaqRoutes,
  companyLandingRoute,
] :
  [
    dashboardRoutes,
    manageRoutes,
    companyRoutes,
    notificationRoutes,
    billingRoutes,
    customersRoutes,
    membershipReportsRoutes,
    contactRoutes,
    portalFaqRoutes,
    companyLandingRoute,
  ];


export const companyPublicRoutes = isLiveSite() ? [
  offeringsRoutes,
  scheduleRoutes,
  confirmRoutes,
  employeesRoutes,
  membershipRoutes,
  shopRoutes,
  faqRoutes,
  galleryRoutes,
  locationsRoutes,
  contactRoutes,
  privacyRoutes,
  loginRoutes,
  registerAccountRoutes,
] :
  [
    offeringsRoutes,
    scheduleRoutes,
    confirmRoutes,
    employeesRoutes,
    membershipRoutes,
    shopRoutes,
    loginRoutes,
    faqRoutes,
    galleryRoutes,
    locationsRoutes,
    contactRoutes,
    privacyRoutes,
    registerAccountRoutes,
  ];
