import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import moment from 'moment';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Card as MuiCard,
    Typography as MuiTypography,
    Box,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoCheckmarkCircleOutline as CheckIcon,
    IoCheckmarkCircle as SolidCheckIcon,

} from "react-icons/io5";


// svxvy imports

// import * as SecurityConstants from '../../../constants/SecurityConstants';

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { fetchActivities, enrollInActivity, dropActivity } from '../../../store/actions/activityActions';
import { checkClaim, getMilliseconds, compareObjects } from '../../../helpers/helperFunctions';
import ActionButton from '../../framework/ActionButton';
import ManagePaymentMethods from '../paymentMethods/ManagePaymentMethods';
import ProfileIcon from '../profile/ProfileIcon';
import ProductList from '../products/ProductsList';
import ProfileDetailCard from '../profile/ProfileDetailCard';
import StyledDialog from '../../framework/StyledDialog';
import WaiverModal from "../profile/WaiverModal";
import StripeWrapper from "../paymentMethods/StripeWrapper";
import CustomPurchaseModal from "../products/CustomPurchaseModal";
import ErrorDialog from '../../framework/ErrorDialog'
import SelectSingleUser from '../SelectSingleUser';
import ProfileCard from '../profile/ProfileCard';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing)
const Typography = styled(MuiTypography)(spacing);

const ProfileRow = styled(Box)`
    // (spacing)
    background-color: transparent;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 0;
    box-shadow: none !important;
    padding: 10px;
    display: inline-block;
    width: 100%;

    &:last-child {
        border-bottom: none;
    }
`;

const CardContent = styled(Box)`
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ICON_SIZE = 24;

const ActivityJoinModal = props => {
    const { activity, setOpen, open, companyId, viewStartDate, viewEndDate } = props;

    const loggedInUser = useSelector(state => state.auth.person);
    const [userToJoin, setUserToJoin] = useState(loggedInUser);

    const theme = useTheme();
    const company = useSelector(state => state.company.company);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);

    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);

    const childProfiles = userToJoin.childProfiles ? userToJoin.childProfiles.sort(compareObjects('displayName', 'asc')) : [];
    const usersToList = [...[userToJoin], ...childProfiles];
    const usersToListLength = usersToList.length;


    // const activity = useSelector(state => state.activity.activities).find(x => x.id === activityId);

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);

    const instructors = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));

    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');
    const attendees = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const whenCanDrop = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfDropStartValue, activity.selfDropStartUnit));
    const usersToPrepay = usersToList.filter(y => !activity.isInActivityInstance(activity.people.find(x => x.appUserId === y.id), activity)).map(x => x.id);

    const [isJoining, setIsJoining] = useState([]);
    const [isDropping, setIsDropping] = useState([]);
    const [error, setError] = useState(null);

    const [profileToShow, setProfileToShow] = useState(null);
    const [showProfileDialog, setShowProfileDialog] = useState(null);

    const [subModalOpen, setSubModalOpen] = useState(false);

    const showingLoggedInUser = loggedInUser.id === userToJoin.id ? true : false;

    const allProducts = useSelector(state => state.products.products);
    const memberships = allProducts.filter(x => x.isRecurring && x.isActive).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', 'asc'));
    const packages = allProducts.filter(x => x.units > 0 && x.isActive && (activity.allowedPackages.some(y => y === x.id))).sort(compareObjects(company.manuallySortProducts ? 'productSortOrder' : 'unitAmount', 'asc'))
    const needPaymentInfo = allowAddEdit && showingLoggedInUser ? false : userToJoin.needPaymentMethodForActivity(activity, company) ? true : false;
    const requirePrepayment = allowAddEdit && showingLoggedInUser ? false : userToJoin.requirePrepayment(activity, company) ? true : false;
    let defaultPaymentMethod = userToJoin && userToJoin.paymentMethods ? userToJoin.paymentMethods.find(x => x.isDefault && x.companyId === company.id) : null;
    const [showCustomPurchaseModal, setShowCustomPurchaseModal] = useState(false)
    const [paymentSucceeded, setPaymentSucceeded] = useState(false)

    const haveAttendeeObject = !activity || !userToJoin ? null : activity.people.find(x => x.appUserId === userToJoin.id);
    const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInActivityInstance(haveAttendeeObject, activity) : false;
    const aFamilySignedUp = activity.isFamilyMemberInInstance(activity.people.filter(x => childProfiles.some(y => y.id === x.appUserId)), activity);

    const familyMemberRegistered = currentUserInClass || aFamilySignedUp;

    const dispatch = useDispatch();
    const joinHandler = useCallback(async (id, person) => {
        if (requirePrepayment)
            setShowCustomPurchaseModal(true);
        else {
            setError(null);
            try {
                setIsJoining(prevState => [...prevState, person.id]);
                await dispatch(enrollInActivity(companyId, id, person.id, new Date(viewStartDate), new Date(viewEndDate), true, false));
                if (usersToListLength === 1)
                    setOpen(false)
            } catch (err) {
                setError(err.message);
            }
            // setIsJoining(prevState => prevState.splice(prevState.indexOf(person.id, 1)));
            setIsJoining(prevState => prevState.filter(x => x !== person.id));
        }
    }, [dispatch, companyId, viewStartDate, viewEndDate, usersToListLength, setOpen, requirePrepayment]);

    const dropHandler = useCallback(async (id, person) => {
        try {
            setIsDropping(prevState => [...prevState, person.id]);
            await dispatch(dropActivity(companyId, id, person.id, viewStartDate, viewEndDate, true));
        } catch (err) {
            setError(err.message)
        }
        // setIsDropping(prevState => [...prevState.splice(prevState.indexOf(person.id, 1))]);
        setIsDropping(prevState => prevState.filter(x => x !== person.id));
    }, [dispatch, companyId, viewStartDate, viewEndDate])

    const prePaySucceeded = useCallback(async () => {
        try {
            setPaymentSucceeded(true)
            await dispatch(fetchActivities(companyId, viewStartDate, viewEndDate));
        } catch (err) {
            setError(err.message)
        }
    }, [dispatch, companyId, viewStartDate, viewEndDate])

    return (
        <React.Fragment>
            {error &&
                <ErrorDialog message={error} setMessage={setError} />
            }
            <StyledDialog
                open={open}
                setOpen={setOpen}
                aria-labelledby="act-dialog-title"
            >
                <React.Fragment>
                    {(needPaymentInfo || subModalOpen || requirePrepayment) && !paymentSucceeded && !familyMemberRegistered &&
                        <Box>
                            {!showingLoggedInUser &&
                                <Card mb={3}>
                                    <Box>
                                        <ProfileCard person={userToJoin} />
                                    </Box>
                                </Card>
                            }
                            <Typography variant='h3' mb={4}>To sign-up for this {ACTIVITY_TEXT.toLowerCase()} please:</Typography>
                            {memberships && memberships.length > 0 &&
                                <Box>
                                    <Typography variant='h4' mb={4}>Sign-up for a Membership</Typography>
                                    <ProductList
                                        company={company}
                                        displayedUser={userToJoin}
                                        products={memberships}
                                        title="Memberships"
                                        isModalOpenCallback={value => setSubModalOpen(value)}
                                        defaultPaymentMethod={defaultPaymentMethod}
                                    />
                                </Box>
                            }
                            {packages && packages.length > 0 &&
                                <Box>
                                    <Typography variant='h4' mb={4}>Purchase a Package</Typography>
                                    <ProductList
                                        company={company}
                                        displayedUser={userToJoin}
                                        products={packages}
                                        title="Packages"
                                        isModalOpenCallback={value => setSubModalOpen(value)}
                                        defaultPaymentMethod={defaultPaymentMethod}
                                    />
                                </Box>
                            }
                            {/* <Box mb={2}>
                                <Typography variant='h4' mb={4}>{packages.length > 0 || memberships.length > 0 ? 'Or ' : ''} Just Pay for this {ACTIVITY_TEXT}</Typography>
                                <ActionButton
                                    variant="outlined"
                                    onClick={() => setShowCustomPurchaseModal(true)}
                                >
                                    Buy this {ACTIVITY_TEXT}
                                </ActionButton>
                            </Box> */}

                            {!requirePrepayment && <Box>
                                <Typography variant='h4' mb={4}>{packages.length > 0 || memberships.length > 0 ? 'Or ' : ''} Add a Credit Card</Typography>
                                <ManagePaymentMethods
                                    companyId={company.id}
                                    userId={userToJoin.id}
                                    showTenantPaymentMethods={true}
                                    open={true}
                                />
                            </Box>}
                        </Box>
                    }
                    {paymentSucceeded &&
                        <Box>
                            <Typography variant='h4' mb={4}>{showingLoggedInUser? 'You ' : userToJoin.displayName} {userToJoin.childProfiles && userToJoin.childProfiles.length > 0 ? 'or a family member ' : ''} have signed up for {activity.title} on {moment(activity.displaybleStartDateTime).format('lll')} </Typography>
                        </Box>
                    }
                    {(((!needPaymentInfo && !requirePrepayment) || familyMemberRegistered) && !paymentSucceeded) &&
                        <Box>
                            {activity.allowSelfRegister && new Date() > whenCanDrop && !company.hideDroppedActivityMessage &&
                                <Alert severity="warning" mb={2}>
                                    {company.dropActivityWarningMessage ? company.dropActivityWarningMessage :
                                        'Be advised, the time when this ' + ACTIVITY_TEXT.toLowerCase() + ' can be dropped has passed.'}
                                </Alert>
                            }
                            <Typography variant='h4' mb={4}>Sign-up for {activity.title}</Typography>
                            <Card>
                                {usersToList.map((person, index) => {
                                    return (
                                        <ProfileRow id={person.id}>
                                            <Box display="flex" justifyContent="space-between">
                                                <CardContent
                                                    style={{ cursor: 'pointer' }} onClick={() => {
                                                        setProfileToShow(person)
                                                        setShowProfileDialog(true)
                                                    }}>
                                                    <ProfileIcon person={person} />
                                                    <Typography ml={2} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{person.displayName}</Typography>
                                                </CardContent>

                                                <Box>
                                                    {
                                                        !activityCanceled &&
                                                        activity.canSelfRegister(activity, person, attendees) &&
                                                        !activity.isInActivityInstance(activity.people.find(x => x.appUserId === person.id), activity) &&
                                                        !peopleRunningActivity.some(x => x.id === person.id) &&
                                                        <React.Fragment>
                                                            <WaiverModal
                                                                useButton
                                                                dontAllowClose={false}
                                                                waiverForUser={person} />
                                                            {(person.waiverSigned === company.waiverText || (!company.waiverText || (company.waiverText && company.waiverText.lenght > 0))) &&
                                                                <ActionButton
                                                                    variant="contained"
                                                                    style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                                                                    startIcon={<CheckIcon size={ICON_SIZE} />}
                                                                    onClick={() => joinHandler(activity.id, person)}
                                                                    submitting={(isJoining && isJoining.indexOf(person.id) > -1) ? true : false}
                                                                >
                                                                    Register Now!
                                                                </ActionButton>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        !activityCanceled &&
                                                        activity.allowSelfRegister && new Date() <= whenCanDrop &&
                                                        // !company.isRABSite &&
                                                        // activity.canSelfRegister(activity, person, attendees) &&
                                                        activity.isInActivityInstance(activity.people.find(x => x.appUserId === person.id), activity) &&
                                                        !peopleRunningActivity.some(x => x.id === person.id) &&
                                                        <ActionButton
                                                            variant="outlined"
                                                            // style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.warning.contrastText, minWidth: 170 }}
                                                            // startIcon={<CheckIcon size={ICON_SIZE} />}
                                                            onClick={() => dropHandler(activity.id, person)}
                                                            submitting={(isDropping && isDropping.indexOf(person.id) > -1) ? true : false}
                                                        >
                                                            Drop {ACTIVITY_TEXT}
                                                        </ActionButton>
                                                    }
                                                    {!activityCanceled &&
                                                        // activity.canSelfRegister(activity, person, attendees) &&
                                                        activity.isInActivityInstance(activity.people.find(x => x.appUserId === person.id), activity) &&
                                                        !peopleRunningActivity.some(x => x.id === person.id) &&
                                                        (new Date() > whenCanDrop) &&
                                                        <ActionButton
                                                            fullWidth
                                                            disabled
                                                            variant="contained"
                                                            style={{ backgroundColor: activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main, color: theme.palette.success.contrastText }}
                                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                                        >
                                                            Signed up
                                                        </ActionButton>
                                                    }
                                                    {!activityCanceled && activity.isInActivityInstance(activity.people.find(x => x.appUserId === person.id), activity) && !activity.allowSelfRegister &&
                                                        <Box display="flex" alignItems="center" >
                                                            <SolidCheckIcon size={16} color={activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main} />
                                                            <Typography ml={1} variant='subtitle1' component="p" style={{ marginTop: '1px', fontWeight: '700', fontSize: '1.2rem', lineHeight: '1.5rem' }}>
                                                                Signed up!
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                        </ProfileRow>
                                    )
                                })}

                            </Card>
                            {allowAddEdit &&
                                <SelectSingleUser
                                    selectText={'Sign up a different ' + CUSTOMER_TEXT.toLowerCase()}
                                    // hideSelf
                                    singleSelect
                                    // showEveryoneOption
                                    initialValue={props.toUsers}
                                    closeOnSelect
                                    loadProfileDetailOnSelect
                                    onChange={person => setUserToJoin(person[0])}
                                    hideMessageButton
                                // onChange={(val) => setFieldValue('toUserIds', val.map(x => x.id))}
                                />
                            }
                        </Box>
                    }
                </React.Fragment>

            </StyledDialog >
            {showCustomPurchaseModal &&
                <StripeWrapper showTenantPaymentMethods open={showCustomPurchaseModal}>
                    <CustomPurchaseModal
                        open={showCustomPurchaseModal}
                        setOpen={setShowCustomPurchaseModal}
                        displayedUser={userToJoin}
                        itemTitle={activity.title + " " + moment(activity.displaybleStartDateTime).format('lll')}
                        itemPrice={activity.price}
                        activity={activity}
                        activityId={activity.id}
                        usersToPrepay={usersToPrepay}
                        // usersToPrepay={userToJoin}
                        onSuccess={() => prePaySucceeded()}
                    />
                </StripeWrapper>
            }
            {
                showProfileDialog && profileToShow &&
                <ProfileDetailCard
                    person={profileToShow}
                    open={showProfileDialog}
                    setOpen={setShowProfileDialog}
                    hideCreateClass
                />
            }
        </React.Fragment>
    );

}

export default ActivityJoinModal;