class Payment {
    constructor(id, appUserId, userDisplayName, activityId, activityName, activityDate, occuranceId, amount, paymentDate, paymentType, paymentStatus, comment, allowedPackageIds,
        ) {
        this.id = id; 
        this.appUserId = appUserId;
        this.userDisplayName = userDisplayName;
        this.activityId = activityId;
        this.activityName = activityName;
        // if ((activityName == 'Youth Football Training' || activityName == 'HS Football Training' || activityName == 'Youth Lineman Training') && appUserId === '44e6324e-b438-4153-98e4-0f783957b78b')
        //     console.log(appUserId, activityDate, new Date(activityDate), new Date((new Date(activityDate)).getTime() + (new Date(activityDate).getTimezoneOffset() * 60 * 1000)))
        this.activityDate = new Date(activityDate);// new Date((new Date(activityDate)).getTime() + (new Date(activityDate).getTimezoneOffset() * 60 * 1000));
        this.occuranceId = occuranceId;
        this.amount = amount;
        this.paymentDate = new Date(paymentDate);
        this.paymentType = paymentType;
        this.paymentStatus = paymentStatus;
        this.comment = comment;
        this.allowedPackageIds = allowedPackageIds;
    }
}

export const ALLOWED_PAYMENT_STATUSES =
    [
        "outstanding",
        "Paid outside of app",
        "Pay By Processor",
    ]

export const UNPAID_STATUS = ALLOWED_PAYMENT_STATUSES[0];
export const PAY_BY_PROCESSOR = "Pay By Processor";
export const PAY_BY_PACKAGE = "Pay with Package";
export const PAID_OUTSIDE_APP = "Paid outside of app";
export const PAYMENT_CONFRIMED = "Payment confirmed";
export const VOIDED = "voided";

export const NOT_SET = null;
export const CASH = "Outside App";
export const CREDIT_CARD = "Credit Card";
export const PACKAGE_TYPE = "Package";

export default Payment;