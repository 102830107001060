import React, { useState } from 'react';

import { useMediaQuery } from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme, withStyles } from '@material-ui/core/styles';

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Dialog,
    IconButton as MuiIconButton,
    Hidden,
    Menu
} from "@material-ui/core";

import {
    IoCloseOutline as CloseIcon,
    IoEllipsisHorizontalCircleOutline as CircleDotsIcon,
} from "react-icons/io5";

// constants 

const Button = styled(MuiButton)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

const ActionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    button {
        width: auto;
        min-width: 0;
        background-color: transparent !important;
        margin: 7px 0 8px 0;
        padding: 0;
        justify-content: left;
        font-weight: 400;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        flex-direction: row;

        button {
            margin: 0 35px 0 0;
        }
    }
`;

const MoreActionsWrapper = styled(ActionsWrapper)`
    ${(props) => props.theme.breakpoints.up("md")} {
        flex-direction: column;
        button {
            margin: 7px 0 8px 0;
        }
        
    }
`;


const Card = styled(MuiCard)`
    // (spacing)
    height: 100%;
    background-color: ${(props) => props.theme.palette.background.default};
    box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);

    ${(props) => props.theme.breakpoints.up("md")} {
        height: auto;
        border-radius: 10px;
    }
`;

const CardContent = styled(MuiCardContent)`
    max-height: calc((100%) - 154px);
    padding: 30px !important;
    overflow-y: auto;

    ${(props) => props.theme.breakpoints.up("sm")} {
        max-height: calc(100vh - 218px);
    }
`;

const CardContentNoPrimaryAction = styled(MuiCardContent)`
    max-height: calc((100%) - 30px);
    padding: 30px !important;
    overflow-y: auto;

    ${(props) => props.theme.breakpoints.up("sm")} {
        max-height: calc(100vh - 218px);
    }
`;

// top buttons
const DetailCardHeader = styled(Box)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 8px 15px 11px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: 11px 23px 8px 30px;
    }
`;



const StyledMenu = withStyles({
    paper: {
        minWidth: '175px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
    },
    list: {
        padding: '8px 8px 7px 15px',
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 46, left: 0 }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledDialog = props => {
    const theme = useTheme();
    const { open, setOpen, children, actions, moreActions, primaryAction, dontAllowClose } = props;

    const [anchorMenu, setAnchorMenu] = useState(null);
    const [anchorMoreMenu, setAnchorMoreMenu] = useState(null);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));


    return (
        <Dialog
            open={open}
            onClose={() => { dontAllowClose ? setOpen(true) : setOpen(false) }}
            fullScreen={isSmallScreen ? true : false}
            fullWidth
            maxWidth='sm'
            disableEnforceFocus
        >
            <Card mb={3} style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} >
                <DetailCardHeader>
                    {!actions &&
                        <Box>
                            {/* placeholder when there are no actions to push close button to the right */}
                        </Box>
                    }
                    {actions &&
                        <Hidden mdUp>
                            <Button aria-label="edit" onClick={(event) => setAnchorMenu(event.currentTarget)} style={{ color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: 400, padding: 0 }}>
                                <CircleDotsIcon color={theme.palette.primary.main} size="32" style={{ marginRight: '5px' }} />Actions
                            </Button>
                            <StyledMenu
                                id="menu-profile-detail-actions"
                                anchorEl={anchorMenu}
                                open={Boolean(anchorMenu)}
                                onClose={() => setAnchorMenu(null)}
                                style={{ padding: 0 }}
                            >
                                <ActionsWrapper onClick={() => setAnchorMenu(null)}>{actions}{moreActions}</ActionsWrapper>
                            </StyledMenu>
                        </Hidden>
                    }

                    <Hidden smDown>
                        <Box display="flex">
                            {actions}
                            {moreActions &&
                                <React.Fragment>
                                    <Button aria-label="edit" onClick={(event) => setAnchorMoreMenu(event.currentTarget)} style={{ color: theme.palette.primary.main, backgroundColor: 'transparent', fontWeight: 400, padding: 0 }}>
                                        <CircleDotsIcon color={theme.palette.primary.main} size="24" style={{ marginLeft: '26px', marginRight: '5px' }} />More
                                    </Button>
                                    <StyledMenu
                                        id="menu-moreActions-detail-actions"
                                        anchorEl={anchorMoreMenu}
                                        open={Boolean(anchorMoreMenu)}
                                        onClose={() => setAnchorMoreMenu(null)}
                                        style={{ padding: 0 }}
                                    >
                                        <MoreActionsWrapper onClick={() => setAnchorMoreMenu(null)}>{moreActions}</MoreActionsWrapper>
                                    </StyledMenu>
                                </React.Fragment>
                            }
                        </Box>
                    </Hidden>
                    {!dontAllowClose && <IconButton aria-label="close profile detail modal" component="span" onClick={() => setOpen(false)} style={{ padding: 0 }}>
                        <CloseIcon size={30} />
                    </IconButton>}
                </DetailCardHeader>

                {!primaryAction &&
                    <CardContentNoPrimaryAction>
                        {children}
                    </CardContentNoPrimaryAction>
                }
                {primaryAction &&
                    <React.Fragment>
                        <CardContent>
                            {children}
                        </CardContent>
                        <Box style={{ padding: '15px 30px 15px 30px' }}>
                            {primaryAction}
                        </Box>
                    </React.Fragment>
                }
            </Card>
        </Dialog>
    );
}

export default StyledDialog;