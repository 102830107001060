import { SET_ACTIVITIES, ADD_ACTIVITY, EDIT_ACTIVITY, ENROLL_IN_ACTIVITY, DROP_ACTIVITY, CANCEL_ACTIVITY, RESTORE_ACTIVITY, DELETE_ACTIVITY } from '../actions/activityActions';
import { AOVS_LIST, AOV_CREATED, AOV_EDITED, AOV_DELETED } from '../actions/activityOptionActions';
import { LOGOUT } from '../actions/authActions';
import { cloneDeep } from 'lodash';
import { addToArray, deleteById } from '../../helpers/arrayFunctions';

const initialState = {
    activities: [],
    nonExpandedActivites: [],
    timeUnits: [{ id: "minutes" }, { id: "hours" }, { id: "days" }],
    aovs: []
}

const enrollInActivity = (idToUpdate, attendee, activitiesArray) => {
    let updatedActivities = cloneDeep(activitiesArray);
    let activityToUpdate = updatedActivities.find(item => item.id === idToUpdate);
    if (activityToUpdate) {
        //remove the old atteneee object and add the new one
        const enrollmentIndex = activityToUpdate.people.indexOf(activityToUpdate.people.find(x => x.appUserId === attendee.appUserId));
        if (enrollmentIndex !== -1)
            activityToUpdate.people.splice(enrollmentIndex, 1);
        activityToUpdate.people.push(attendee);
    }
    return updatedActivities;
}

const dropActivity = (idToUpdate, attendee, activitiesArray) => {
    let updatedActivities = cloneDeep(activitiesArray);
    let activityToUpdate = updatedActivities.find(item => item.id === idToUpdate);
    const enrollmentIndex = activityToUpdate.people.indexOf(activityToUpdate.people.find(x => x.appUserId === attendee.appUserId));
    if (enrollmentIndex !== -1)
        activityToUpdate.people.splice(enrollmentIndex, 1);
    if (activityToUpdate.rRule && activityToUpdate.rRule.length > 0)
        activityToUpdate.people.push(attendee);

    return updatedActivities;
}

const cancelActivity = (idToUpdate, status, activitiesArray) => {
    let updatedActivities = cloneDeep(activitiesArray);
    let activityToUpdate = updatedActivities.find(item => item.id === idToUpdate);
    if (activityToUpdate) {
        activityToUpdate.statuses.push(status);
    }
    return updatedActivities;
}

const restoreActivity = (idToUpdate, status, activitiesArray) => {
    let updatedActivities = cloneDeep(activitiesArray);
    let activityToUpdate = updatedActivities.find(item => item.id === idToUpdate);
    if (activityToUpdate) {
        const statusIndexToRemove = activityToUpdate.statuses.indexOf(activityToUpdate.statuses.find(x => x.activityId === status.activityId && x.occuranceId === status.occuranceId));
        activityToUpdate.statuses.splice(statusIndexToRemove, 1);
    }
    return updatedActivities;
}

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return { ...initialState };
        case ADD_ACTIVITY:
            return { ...state, activities: [...state.activities, ...action.activities] };
        case EDIT_ACTIVITY:
            const updatedActivities = deleteById(action.activity.id, state.activities);
            return { ...state, activities: addToArray(action.activity, updatedActivities) };
        case SET_ACTIVITIES:
            return { ...state, activities: action.activities, nonExpandedActivites: action.nonExpandedActivites };
        case ENROLL_IN_ACTIVITY:
            return { ...state, activities: enrollInActivity(action.activityId, action.attendee, state.activities) };
        case DROP_ACTIVITY:
            return { ...state, activities: dropActivity(action.activityId, action.attendee, state.activities) }
        case CANCEL_ACTIVITY:
            return { ...state, activities: cancelActivity(action.activityId, action.status, state.activities) }
        case RESTORE_ACTIVITY:
            return { ...state, activities: restoreActivity(action.activityId, action.status, state.activities) }
        case DELETE_ACTIVITY:
            return { ...state, activities: deleteById(action.activityId, state.activities) }
        case AOVS_LIST:
            return { ...state, aovs: action.aovs };
        case AOV_CREATED:
            return { ...state, aovs: addToArray(action.aov, state.aovs) };
        case AOV_EDITED:
            return { ...state, aovs: addToArray(action.aov, deleteById(action.aov.id, state.aovs)) };
        case AOV_DELETED:
            return { ...state, aovs: deleteById(action.aov, state.aovs) };
        default:
            return state;
    }
}

export default activityReducer; 