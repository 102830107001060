import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components/macro";

import {
    Avatar as MuiAvatar,
    Button,
    IconButton as MuiIconButton,
    ListItem as MuiListItem,
    ListItemText as MuiListItemText,
    CircularProgress as MuiCircularProgress,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useTheme } from '@material-ui/core/styles';
import { Alert as MuiAlert } from "@material-ui/lab";

import {
    CreditCardRounded as CreditCardRoundedIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons";

import { setDefaultPaymentMethod, removePaymentMethod } from "../../../store/actions/paymentActions";
import { isCardExpired } from '../../../helpers/commerceFunctions';

const Alert = styled(MuiAlert)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const ListItem = styled(MuiListItem)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const IconButton = styled(MuiIconButton)(spacing);

const PaymentMethodItem = (props) => {
    const theme = useTheme();
    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSettingDefault, setIsSettingDefault] = useState(false);

    const isExpired = isCardExpired(props.paymentMethod);

    const dispatch = useDispatch();

    const deletePaymentMethod = useCallback(async (id, companyId, userId) => {
        setError(null);
        setIsDeleting(true);
        try {
            await dispatch(removePaymentMethod(id, companyId, userId));
        } catch (err) {
            setError(err.message);
            setIsDeleting(false);
        }
    }, [dispatch]);


    const defaultPaymentMethod = useCallback(async (id, companyId, userId) => {
        setError(null);
        setIsSettingDefault(true);
        try {
            await dispatch(setDefaultPaymentMethod(id, companyId, userId));
        } catch (err) {
            setError(err.message);
        }
        setIsSettingDefault(false);
    }, [dispatch]);

    const handleDeletePressed = () => {
        deletePaymentMethod(props.paymentMethod.id, props.companyId, props.userId);
    }

    const handleDefaultPressed = () => {
        defaultPaymentMethod(props.paymentMethod.id, props.companyId, props.userId);
    }


    return (
        <React.Fragment>
            {props.paymentMethod && <ListItem style={{ padding: 0 }}>
                <Avatar mr={3}>
                    <CreditCardRoundedIcon />
                </Avatar>

                {isExpired && <ListItemText primary={"•••• " + props.paymentMethod.lastFourOnCard} secondary={"Expired: " + props.paymentMethod.expirationMonth + " / " + props.paymentMethod.expirationYear} />}
                {!isExpired && <ListItemText primary={"•••• " + props.paymentMethod.lastFourOnCard} secondary={"Expires: " + props.paymentMethod.expirationMonth + " / " + props.paymentMethod.expirationYear} />}
                {!isSettingDefault && !props.paymentMethod.isParentCard && <Button mx={2} aria-label={props.paymentMethod.isDefault ? "Default Card" : "Set as Default"} onClick={handleDefaultPressed}
                    variant={props.paymentMethod.isDefault || isExpired ? null : "outlined"} style={{ color: isExpired ? theme.palette.error.main : theme.palette.text.primary }} disabled={props.paymentMethod.isDefault || isExpired}>
                    {isExpired ? "Expired" : props.paymentMethod.isDefault ? "Default Card" : "Set as Default"}
                </Button>}
                {props.paymentMethod.isParentCard &&
                    <Typography style={{ color: theme.palette.text.secondary }}>
                        {isExpired && <Typography variant="button" style={{ color: theme.palette.error.main }}>Expired</Typography>}
                        {isExpired ? " - " : ""}Linked from Parent Account
                    </Typography>

                }
                {isSettingDefault && <CircularProgress mx={4.7} color="primary" size={24} />}
                {!props.paymentMethod.isParentCard && !isDeleting && !props.hideActions && <IconButton mx={2} aria-label="Delete" onClick={handleDeletePressed} color="primary">
                    <DeleteIcon />
                </IconButton>}
                {isDeleting && <CircularProgress mx={4.7} color="primary" size={24} />}
            </ListItem>
            }
            {error && <ListItem>
                <Alert mb={4} severity="error">
                    {error}
                </Alert>
            </ListItem>}
        </React.Fragment >
    );
}

export default PaymentMethodItem;