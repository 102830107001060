import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLocation } from "react-router";
import { useHistory, NavLink } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';

// UI Imports

import { Helmet } from 'react-helmet-async';
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { addAppProfile } from '../../../store/actions/peopleActions';
import { refreshLogedInUser, logout } from '../../../store/actions/authActions';
import { ReactComponent as SmartCoreLogo } from "../../../Assets/Logos/smartcore-logo.svg";

import LoginForm from '../../../components/company/userManagement/LoginForm';
import PasswordResetForm from '../../../components/company/userManagement/PasswordResetForm';
import CompanyLogo from '../../../components/CompanyLogo';
import ActionButton from '../../../components/framework/ActionButton';

// UI Consts
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  width: 100%;
  max-width: 440px;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 25px 0;
  padding: 22px 70px 30px 70px;
`;

const Login = props => {
    const theme = useTheme();
    const history = useHistory();
    const { search } = useLocation();
    const returnUrl = new URLSearchParams(search).get('returnURL');

    const company = useSelector(state => state.company.company);
    const companyId = useSelector(state => state.company.companyId);
    const loggedInUser = useSelector(state => state.auth.person);

    const [showResetForm, setShowResetForm] = useState(false);
    const [isJoiningCompany, setIsJoiningCompany] = useState(false);
    const [resetEmail, setResetEmail] = useState("");

    /******************************** event handler  ****************************/
    const dispatch = useDispatch();

    const joinCompany = useCallback(async (companyId, appUserId) => {
        setIsJoiningCompany(true);
        try {
            await dispatch(addAppProfile(companyId, appUserId));
            await dispatch(refreshLogedInUser());
            setIsJoiningCompany(false);
            if (returnUrl)
                history.push(returnUrl);
            else
                history.push(NavigationConstants.DASHBOARD);
        } catch (err) {
            setIsJoiningCompany(false);
            alert(err.message);
        }
    }, [dispatch, history, returnUrl]);


    const handleSignOut = async () => {
        await dispatch(logout());

        history.push(NavigationConstants.REGISTER);
    };

    /******************************** page effects  ****************************/

    useEffect(() => {
        if (loggedInUser != null && loggedInUser.companyId === companyId) {
            if (returnUrl)
                history.push(returnUrl)
            else
                history.push(NavigationConstants.DASHBOARD);
        }
    }, [loggedInUser, history, companyId, returnUrl])


    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Helmet title={"Login - " + (company ? company.name : "")} />
            {loggedInUser && loggedInUser.companyId !== companyId &&
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Card p={4} >
                        <CompanyLogo company={company} />
                        <Box style={{ marginTop: '23px' }}>
                            <Typography variant="h2" component="h2" style={{ marginTop: '23px', marginBottom: '17px', fontSize: '28px', lineHeight: '30px' }}>Join with Account?</Typography>
                            <Typography variant="body2" style={{ marginBottom: '16px', fontSize: '14px', lineHeight: '18px' }} mb={2}>{company.name} uses SmartCore to run its gym.</Typography>
                            <Typography variant="body2" style={{ marginBottom: '26px', fontSize: '14px', lineHeight: '18px' }}>{loggedInUser.email} is already registered with SmartCore. Do you want to join {company.name} with this account?</Typography>
                            <ActionButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => joinCompany(company.id, loggedInUser.id)}
                                submitting={isJoiningCompany}
                            >
                                Join
                            </ActionButton>
                        </Box>
                    </Card>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={350} mb={8}>
                        <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>
                            Don't want to use your existing SmartCore account?
                        </Typography>
                        <Typography
                            variant="body2"
                            color='primary'
                            style={{ margin: '0 0 46px 0', fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}
                            onClick={handleSignOut}
                        >
                            Create a New Account
                        </Typography>
                    </Box>
                </Box>
            }
            {!loggedInUser &&
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                    {!showResetForm &&
                        <Card p={4} >
                            <CompanyLogo company={company} />
                            <Box>
                                <Typography variant="h2" component="h2" style={{ marginTop: '23px', marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Sign In</Typography>
                                {resetEmail && <Typography variant="body2" style={{ margin: '0 0 12px 0', fontSize: '14px', lineHeight: '18px' }}>
                                    Use the authention code sent to you in the password field below.
                                </Typography>}
                            </Box>
                            <LoginForm
                                onSuccess={(value) => setResetEmail(value)}
                                company={company}
                                email={resetEmail}
                                additionalComponents={
                                    <Box display="flex" justifyContent="flex-end">
                                        <Typography
                                            onClick={() => { setResetEmail(""); setShowResetForm(true) }}
                                            color="primary"
                                            variant="body2"
                                            style={{ margin: '-7px 0 26px 0', fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}>
                                            Forgot your Password?
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Card>
                    }
                    {showResetForm &&
                        <React.Fragment>
                            <Card p={4} >
                                <CompanyLogo company={company} />
                                <Box style={{ height: '24px' }}></Box>
                                <PasswordResetForm
                                    onSuccess={(value) => { setResetEmail(value); setShowResetForm(false) }}
                                />
                            </Card>
                            <Box display="flex" justifyContent="center">
                                <Typography
                                    variant="body2"
                                    onClick={() => setShowResetForm(false)}
                                    color="primary"
                                    style={{ backgroundColor: 'transparent !important', marginBottom: '46px', fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}>
                                    Back to Sign In
                                </Typography>
                            </Box>
                        </React.Fragment>
                    }

                    {!showResetForm &&
                        <Card mt={4} p={4}>
                            <Box>
                                <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Join</Typography>
                            </Box>
                            <Typography variant="body2" style={{ margin: '0 0 6px 0', fontSize: '14px', lineHeight: '18px' }}>
                                Create a new account or use your existing SmartCore login to join {company.name}!
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                mt={4}
                                p={2}
                                to={NavigationConstants.USER_REGISTER}
                                component={NavLink}
                            >
                                Join
                            </Button>
                        </Card>
                    }
                </Box>
            }
            <Box component="a" href="https://smartcore.life" target="blank" style={{ marginTop: '4px', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <Typography variant="caption" style={{
                    color: theme.palette.text.secondary,
                    margin: '0 5px 0 0',
                    display: 'inline-block',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textDecoration: 'none'
                }}>powered by</Typography>
                <SmartCoreLogo fill={theme.palette.text.secondary} style={{
                    width: '100px',
                    height: '18px'
                }} />
            </Box>
        </Box>
    )
}

export default withTheme(Login);