import React, {useEffect} from 'react';
import { useLocation } from "react-router";
import ReactGA from 'react-ga';

// svxvy imports
import { isLiveSite } from '../constants/NetworkConstants';
import reportWebVitals from '../reportWebVitals';


const GAPageTracker = props => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname);
        if (isLiveSite()) {
            reportWebVitals(({ id, name, value }) =>
                ReactGA.event({
                    action: name,
                    category: 'Web Vitals',
                    label: id,
                    nonInteraction: true,
                    value: Math.round(
                        name === 'CLS' ? value * 1000 : value
                    ),
                })
            );
        }
    }, [location]);

    return (
        <React.Fragment />
    )

}

export default GAPageTracker;