import React from "react";
import { useSelector } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import {
    Box,
} from "@material-ui/core";

/// svxvy imports
import { getStripePublicKey } from '../../../constants/NetworkConstants';
import Auxcillary from "../../framework/Auxcillary";
import AddPaymentMethod from "./AddPaymentMethod";
import ListPaymentMethods from "./ListPaymentMethods";
import StyledDialog from "../../framework/StyledDialog";

// constants

const ManagePaymentMethods = props => {
    const { userId, open, setOpen, dontRefreshLoggedInUser } = props;

    const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey)
    const shouldLoadStripe = (!props.showTenantPaymentMethods || paymentServicePublicKey) ? true : false;
    const stripeKeyLoaded = props.showTenantPaymentMethods ? paymentServicePublicKey : getStripePublicKey();
    const stripePromise = (open || !props.showTenantPaymentMethods) && shouldLoadStripe ? loadStripe(stripeKeyLoaded) : null;

    const DisplayComponent = setOpen ? StyledDialog : Auxcillary

    return (
        <DisplayComponent
            open={open}
            setOpen={setOpen}
        >
            <Box>

                {shouldLoadStripe && <Elements stripe={stripePromise}>
                    <AddPaymentMethod companyId={props.companyId} userId={userId} addToTenant={props.showTenantPaymentMethods} />
                </Elements>}
                <ListPaymentMethods companyId={props.companyId} userId={userId} showTenantPaymentMethods={props.showTenantPaymentMethods} dontRefreshLoggedInUser={dontRefreshLoggedInUser} />
            </Box>
            {/* </Grid >
            </Grid > */}
        </DisplayComponent>
    );
}

export default ManagePaymentMethods;