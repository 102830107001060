import { isSvxvySite } from './NetworkConstants';

export const PAGE_NOT_FOUND = "/404";

export const HOME_ID = "Public Site";

export const HOME = "/";
export const LOOKUP_COMPANY = "lookup";
export const REGISTER = "Register";
export const COMPANY_NOT_FOUND = "company_404"

export const COMPANY = isSvxvySite() ? "/:sanitizedCompanyName" : "";

// Company Website Pages

export const OFFERINGS = "Offerings";
export const SCHEDULE = "Schedule";
export const ACITVITY_CONFIRM = "Confirm";
export const EMPLOYEES = "Employees";
export const MEMBERSHIP = "Membership";
export const SHOP = "Shop";
export const GALLERY = "Gallery";
export const FAQ = "FAQs";
export const LOCATIONS = "locations";
export const CONTACT = "Contact"
export const LOGIN = "login";
export const USER_REGISTER = "register";
export const EULA = "eula";
export const PRIVACY_POLICY = "privacy";


// Company Portal Pages
export const DASHBOARD = "Dashboard";
export const ACTIVITIES = "activities";
export const PROFILE = "profile";
export const CUSTOMERS = "customers";
export const MESSAGES = "messages";
export const PORTAL_FAQ = "portalfaq";
export const PROFILE_VIEW = ":userDetailPage";

export const MY_PROFILE_LINK = "myprofile";

export const MY_PROFILE = COMPANY + "/myprofile";
export const MY_FAMILY = COMPANY + "/myfamily";
export const MY_FILES = COMPANY + "/myfiles";
export const MY_SUBSCRIPTIONS = COMPANY + "/mypurchases";
export const MY_PAYMENT_METHODS = COMPANY + "/mypaymentmethods";

export const MANAGE = "manage";
export const MEMBER_CHECKIN = "checkin";
export const COMPANY_BRANDING = "branding";
export const COMPANY_SETTINGS = "settings";
export const COMPANY_ADVANCED_SETTINGS = "advanced";
export const MANAGE_LOCATIONS = "managelocations";
export const MANAGE_REVIEWS = "managereviews";
export const PAYMENT_INTEGARATION = "paymentintegration";
export const MEMBERSHIPS_AND_PACKAGES = "membershipandpackages";
export const MANAGE_PRODUCTS = "manageproducts";
export const SUBSCRIPTION = "subscription";

export const REPORTS = "reports";
export const MEMBERSHIP_REPORTS = "memberships"
export const PACKAGE_REPORTS = "packages"
export const OUTSTANDING_PAYMNETS = "outstandingpayments";
export const MY_PURCHASES = "mypurchases";
export const MONTHLY_INCOME = "monthlyincome";
