import React from 'react';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';

// svxvy import
import { DEFAULT_USER_IMAGE, getInitials } from '../../../models/Person'

const useStyles = makeStyles((theme) => ({
    userIconSmall: {
        width: 24,
        height: 24,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: "1.6rem",
        lineHeight: 1.125,
    },
    userIcon: {
        width: 44,
        height: 44,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: "1.6rem",
        lineHeight: 1.125,
    },
    userIconLarge: {
        width: 175,
        height: 175,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: "4rem",
        lineHeight: 1.125,
    }
}));


const ProfileIcon = props => {
    const { person, variant } = props;
    const classes = useStyles();
    const initials = person ? getInitials(person.displayName) : null;

    const variantToUse = variant ? variant : 'normal';
    let classToUse = classes.userIcon;
    if (variantToUse.toLowerCase() === 'small')
        classToUse = classes.userIconSmall;
    else if (variantToUse.toLowerCase() === 'large')
        classToUse = classes.userIconLarge;

    return (
        <React.Fragment>
            {person && person.image !== DEFAULT_USER_IMAGE &&
                <Avatar
                    alt={person.displayName + " profile image"}
                    className={classToUse}
                    src={person.image}
                />
            }
            {person && initials && person.image === DEFAULT_USER_IMAGE &&
                <Avatar
                    className={classToUse}
                    alt={initials + " profile image as initials"}
                >
                    {initials}
                </Avatar>
            }
        </React.Fragment>
    );
}

export default ProfileIcon;