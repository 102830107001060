import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { Formik, Field } from "formik";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useTheme } from "@material-ui/core/styles";

import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
  FormGroup,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Box,
} from "@material-ui/core";

import {
  IoChevronDown as ChevronDown,
  IoTrash as DeleteIcon
} from "react-icons/io5";

// svxvy imports
import { ADD_EDIT_COMPANY_LOCATION } from "../../../store/actions/registerCompanyActions";
import {
  addLocation,
  deleteLocation,
  editLocation,
} from "../../../store/actions/locationActions";

import { GUID_Generator } from "../../../helpers/helperFunctions";
import { timezones } from "../../../models/Location"
import ActionButton from "../../framework/ActionButton";
import Auxcillary from "../../framework/Auxcillary";
import ErrorDialog from "../../framework/ErrorDialog";
import SelectFormControl from "../../framework/SelectFormControl";
import StyledDialog from "../../framework/StyledDialog";
import UploadPhoto from "../photos/UploadPhoto";


// style constants
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const UtilityButton = styled(ActionButton)`
  height: 22px;
  background-color: transparent !important;
  border-radius: 0;
  margin: 0 26px 0 0;
  padding: 0 2px;
  font-weight: 400;
  font-size: 14px;

  .MuiButton-startIcon {
    margin-right: 5px;
  }

  &:last-child {
    margin: 0;
  }
`;

const UtilityButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(3, "Must be at least 3 characters."),
});

const LocationForm = (props) => {
  const { location, company, isOpen, setIsOpen, showInDialog } = props;

  const theme = useTheme();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [photo, setPhoto] = useState(location ? location.photo : null);
  const [isDeleting, setIsDeleting] = useState(false);
  const LOCATION_TEXT = useSelector((state) => state.company.locationText);

  const dispatch = useDispatch();

  const initialValues = {
    id: location && location.id ? location.id : GUID_Generator(),
    name: location && location.name ? location.name : "",
    address: location && location.address ? location.address : "",
    city: location && location.city ? location.city : "",
    state: location && location.state ? location.state : "",
    zip: location && location.zip ? location.zip : "",
    notes: location && location.notes ? location.notes : "",
    timezone:
      location && location.timezone
        ? location.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
    isVirtual: location ? location.isVirtual : false,
    photo: null,
  };

  const submitCallback = useCallback(
    async (
      companyId,
      values,
      setErrors,
      setStatus,
      setSubmitting,
      isRegistration,
      isEditing,
      onSuccess
    ) => {
      try {
        setIsSubmitting(true);
        setError(null);
        if (isRegistration)
          dispatch({ type: ADD_EDIT_COMPANY_LOCATION, attributes: values });
        else {
          if (isEditing) {
            await dispatch(editLocation(values.id, values, companyId));
          } else {
            await dispatch(addLocation(values.id, values, companyId));
          }
        }
        setIsSubmitting(false);
        if (onSuccess) onSuccess();
        if (setIsOpen) setIsOpen(false);
      } catch (error) {
        setError(error.message);
        setIsSubmitting(false);
      }
    },
    [dispatch, setIsOpen]
  );

  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const isEditing = location && location.id ? true : false;
    values.photo =
      photo && location && location.photo && location.photo.id === photo.id
        ? null
        : photo;
    submitCallback(
      company.id,
      values,
      setErrors,
      setStatus,
      setSubmitting,
      props.isRegistration,
      isEditing,
      props.onSuccess
    );
  };

  const deleteCallback = useCallback(
    async (companyId, locationId, setOpen) => {
      setError(null);
      setIsDeleting(true);
      try {
        await dispatch(deleteLocation(locationId, companyId));
        setIsDeleting(false);
        if (setOpen) setOpen(false);
      } catch (error) {
        setIsDeleting(false);
        setError(error.message);
      }
    },
    [dispatch]
  );

  const handleDelete = () => {
    deleteCallback(company.id, location.id, setIsOpen);
  };

  const handleBack = (values) => {
    dispatch({ type: ADD_EDIT_COMPANY_LOCATION, attributes: values });
    if (props.onBack) props.onBack();
  };

  const Container = showInDialog ? StyledDialog : Auxcillary;

  return (
    <Container
      open={isOpen}
      setOpen={setIsOpen}
      aria-labelledby="act-dialog-title"
      actions={
        <UtilityButtonWrapper>
          {location && (
            <UtilityButton
              type="submit"
              onClick={handleDelete}
              disabled={isSubmitting}
              submitting={isDeleting}
              ml={10}
              startIcon={
                <DeleteIcon
                  size={24}
                  style={{ color: theme.palette.error.main }}
                />
              }
            >
              Delete
            </UtilityButton>
          )}
        </UtilityButtonWrapper>
      }
      primaryAction={
        <React.Fragment>
          <ActionButton
            type="submit"
            form="editLocationForm"
            variant="contained"
            color="primary"
            submitting={isSubmitting}
            fullWidth
          >
            Save
          </ActionButton>
        </React.Fragment>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setBoxFieldValue,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          status,
        }) => (
          <Box style={{ width: "100%" }}>
            {props.isRegistration && (
              <React.Fragment>
                <Typography variant="h4" gutterBottom>
                  Location of your business
                </Typography>
                <Typography gutterBottom>
                  Enter your primary location here. Don't worry you can add
                  others later.
                </Typography>
                <Typography gutterBottom>
                  If you have a fully virtual business you can enter a title and
                  not include an address.
                </Typography>
              </React.Fragment>
            )}
            <Typography variant="h1" component="h2">
              {location ? "Edit " : "Add "} {LOCATION_TEXT}
            </Typography>
            <form
              id="editLocationForm"
              onSubmit={handleSubmit}
              style={{ marginTop: "30px" }}
            >
              <Box style={{ marginBottom: "15px" }}>
                <TextField
                  name="name"
                  label="Location Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  my={2}
                  placeholder=""
                />
                <SelectFormControl
                  fullWidth
                  labelText={"Timezone - used when sending reminders"}
                  labelId="select-locationId-label"
                >
                  <Select
                    labelId="select-locationId-label"
                    id="timezone"
                    fullWidth
                    value={values.timezone}
                    label={"Timezone - used when sending reminders"} /// nned both this line and Input lavel
                    onChange={(event) =>
                      setFieldValue("timezone", event.target.value)
                    }
                    IconComponent={ChevronDown}
                  >
                    {timezones.map((timeezone) => (
                      <MenuItem
                        key={timeezone.key}
                        value={timeezone.key}
                        selected={
                          timeezone.key === values.timezone ? true : false
                        }
                      >
                        {timeezone.value}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl>
              </Box>
              <Box style={{ marginBottom: "15px" }}>
                <TextField
                  name="address"
                  label="Address"
                  value={values.address}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  my={2}
                  placeholder=""
                />
                <TextField
                  name="city"
                  label="City"
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  placeholder=""
                />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="state"
                      label="State"
                      value={values.state}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      placeholder=""
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="zip"
                      label="Zip"
                      value={values.zip}
                      error={Boolean(touched.zip && errors.zip)}
                      helperText={touched.zip && errors.zip}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      placeholder=""
                    />
                  </Grid>
                </Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Field
                        name="isVirtual"
                        component={Switch}
                        onChange={(val) =>
                          setFieldValue("isVirtual", !values.isVirtual)
                        }
                        checked={values.isVirtual}
                      />
                    }
                    label={
                      "This " +
                      LOCATION_TEXT.toLowerCase() +
                      " can host virtual activities."
                    }
                    style={{ margin: "15px 0 0 10px" }}
                  />
                </FormGroup>
              </Box>
              <TextField
                name="notes"
                label="Location notes (will be visible in the website and app)"
                value={values.notes}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                multiline={true}
                maxRows={15}
                variant="outlined"
                my={2}
                placeholder=""
              />
              {showInDialog && (
                <UploadPhoto
                  mt={4}
                  id="heroImage"
                  image={photo}
                  onSuccess={(image) => {
                    setPhoto(image);
                  }}
                />
              )}
              {!showInDialog && (
                <Button
                  type="button"
                  onClick={handleBack}
                  disabled={isSubmitting}
                  mr={3}
                >
                  {props.cancelButtonText}
                </Button>
              )}
              {!showInDialog && (
                <Button type="submit" variant="contained" color="primary">
                  {props.nextButtonText}
                </Button>
              )}
              {!showInDialog && isSubmitting && (
                <CircularProgress color="primary" size={20} />
              )}
            </form>
          </Box>
        )}
      </Formik>
      <ErrorDialog message={error} setMessage={setError} />
    </Container>
  );
};

export default LocationForm;
