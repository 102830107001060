import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { spacing } from "@material-ui/system";
import styled, { withTheme } from "styled-components/macro";

import {
  AppBar as MuiAppBar,
  Box,
  CircularProgress as MuiCircularProgress,
  Grid,
  IconButton as MuiIconButton,
  ListItem,
  Toolbar,
  Typography as MuiTypography
} from "@material-ui/core";
import { IoEllipsisHorizontal } from "react-icons/io5";

/********************** svxvy import ***********************/

import * as NavigationConstants from '../../constants/NavigationConstants';
import { isSvxvySite } from '../../constants/NetworkConstants';
import { showMenuItem, replaceDisplayName } from '../../helpers/navigationFunctions';
import { companySidebarRoutes as routes } from "../../routes/indexSvxvy";
import { SidebarCategory } from './CompanySidebar'
import ProfileIcon from '../company/profile/ProfileIcon';
import CompanyUserDropdown from './CompanyUserDropdown'

/********************** design items ***********************/


const CircularProgress = styled(MuiCircularProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const UserIconContainer = styled(ListItem)`
  width: 32px;
  height: 32px;
  padding: 0;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const IconButton = styled(MuiIconButton)`
  width: 32px;
  height: 32px;
  padding: 0;

  svg {
    width: 22px;
    height: 22px;
    color: ${(props) => props.theme.sidebar.category.svg.color};
  }
`;


const CompanyAppBarComponent = (props) => {
  const { company, onDrawerToggle, loggedInUser, theme, open } = props;

  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false)
  const auth = useSelector((state) => state.auth);
  const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar style={{maxHeight: "52px", minHeight: "52px", paddingLeft: "15px", paddingRight: "15px"}}>
          {!didTryAutoLogin &&
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" size={20} />
              <Typography mx={3} color="secondary" variant="h4">Loading menus...</Typography>
            </Box>
          }
          {didTryAutoLogin && loggedInUser && <Grid container alignItems="center" style={{justifyContent: "space-between"}}>
            <Grid item>
              <UserIconContainer onClick={() => setIsUserDropDownOpen(true)} button>
                <ProfileIcon person={loggedInUser} variant="small" />
              </UserIconContainer>
            </Grid>
            {company && routes && routes.map((category, index) =>
              <React.Fragment key={index}>
                {index < 6 && showMenuItem(category, company, auth) && !category.children ? (
                  <Grid item>
                    <SidebarCategory
                      isCollapsable={false}
                      name={replaceDisplayName(category.id, company)}
                      to={isSvxvySite() ? category.path.replace(NavigationConstants.COMPANY, "/" + company.sanitizedName) : category.path}
                      activeClassName={open ? "": "active"} 
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      hideName
                      theme={theme}
                      badge={category.badge}
                    />
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
            <Grid item>
              <IconButton
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <IoEllipsisHorizontal color={open ? theme.sidebar.category.svg.colorActive : null} />
              </IconButton>
            </Grid>
          </Grid>
          }
        </Toolbar>
      </AppBar>
      {loggedInUser && <CompanyUserDropdown open={isUserDropDownOpen} setOpen={setIsUserDropDownOpen} theme={theme} loggedInUser={loggedInUser} />}
    </React.Fragment>
  )
};

export default withTheme(CompanyAppBarComponent);
