import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';

import * as Yup from "yup";
import { Formik } from "formik";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    FormGroup,
    FormControlLabel as MuiFormControlLabel,
    TextField as MuiTextField,
    Typography,
    Switch,
} from "@material-ui/core";

// svxvy imports
import { createReview, editReview } from '../../../store/actions/reviewActions';
import { getRTEValue } from '../../../helpers/rteFunctions';
import ActionButton from '../../framework/ActionButton';
import StyledDialog from "../../framework/StyledDialog";
import ErrorDialog from '../../framework/ErrorDialog';
import RTESlateWrapper from '../../framework/RTE/RTESlateWrapper';

// style constants
const TextField = styled(MuiTextField)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required("Required"),
    reviewerName: Yup.string()
        .required("Required")
});

const ReviewForm = props => {
    const { review, company, isOpen, setIsOpen } = props;
    const theme = useTheme();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const initialValues = {
        id: review && review.id ? review.id : null,
        title: review && review.title ? review.title : '',
        reviewerName: review && review.reviewerName ? review.reviewerName : '',
        showOnWebsite: review ? review.showOnWebsite : false,
        reviewText: review ? getRTEValue(review.reviewText) : getRTEValue(null),
    };

    const submitCallback = useCallback(async (companyId, values, isEditing) => {
        try {
            setIsSubmitting(true);
            setError(null);
            if (isEditing) {
                await dispatch(editReview(companyId, values.id, values));
            }
            else {
                await dispatch(createReview(companyId, values));
            }
            setIsSubmitting(false);
            if (setIsOpen)
                setIsOpen(false);
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch, setIsOpen]);

    const handleSubmit = (values) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.reviewText = JSON.stringify(values.reviewText);

        submitCallback(company.id, valuesToSend, values.id === null ? false : true);
    };

    return (
        <StyledDialog
            open={isOpen}
            setOpen={setIsOpen}
            aria-labelledby="act-dialog-title"
            primaryAction={
                <React.Fragment>
                    <ActionButton
                        type="submit"
                        form="addEditReviewForm"
                        variant="contained"
                        color="primary"
                        submitting={isSubmitting}
                        fullWidth
                    >
                        Save
                    </ActionButton>
                </React.Fragment>
            }
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    status,
                }) => (
                    <Box style={{ width: '100%' }}>
                        <Typography variant="h1" component="h2">{review ? "Edit " : "Add "}Review</Typography>
                        <form id="addEditReviewForm" onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
                            <TextField
                                name="title"
                                label="Review Title"
                                value={values.title}
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                my={2}
                                placeholder="" />
                            <TextField
                                name="reviewerName"
                                label="Review From"
                                value={values.reviewerName}
                                error={Boolean(touched.reviewerName && errors.reviewerName)}
                                helperText={touched.reviewerName && errors.reviewerName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                my={2}
                                placeholder="" />
                            <Box mb={2} mx={2}>
                                <FormGroup style={{ margin: 0 }}>
                                    <FormControlLabel control={
                                        <Switch name='showOnWebsite' onChange={(val) => setFieldValue('showOnWebsite', !values.showOnWebsite)} checked={values.showOnWebsite} />
                                    } label={'Show On Website'} />
                                </FormGroup>
                            </Box>
                            <Box mb={2} style={{ padding: '10px', backgroundColor: theme.palette.background.paper }}>
                                <RTESlateWrapper
                                    placeholderText='Enter review here...'
                                    value={values.reviewText}
                                    setValue={newState => setFieldValue('reviewText', newState)}
                                />
                            </Box>
                        </form>
                    </Box>
                )}
            </Formik>
            <ErrorDialog message={error} setMessage={setError} />
        </StyledDialog>
    )
};

export default ReviewForm;