import React from 'react';
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    // Button,
    Typography as MuiTypography,
    Grid as MuiGrid
} from "@material-ui/core";

//svxvy imports

import * as NavigationContants from '../../constants/NavigationConstants';
import { formatImage } from '../../helpers/imageHelpers';

// constants 
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const HeroBox = styled(Box)`
    width: 100%;
    padding: 0 30px;
    position: relative;
    
    &:before {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.15);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        content: '';
    }
`;

const SlimContainer = styled(Box)`
    height: 315px;
    color: white;
    display: flex;
    align-self: center;
    position: relative;
    z-index: 2;

    ${(props) => props.theme.breakpoints.up("md")} {
        height: 290px;
    }
`;

export const defaultImage = 'https://res.cloudinary.com/svxvy/image/upload/v1639592221/default_header_znqwuo.webp'
export const defaultRABImage = 'https://res.cloudinary.com/svxvy-test/image/upload/v1702305602/SteelCurtainBoxing/q77aff7vn0mnv4pousub.webp'

const defaultTitle = '';

const HeroInternal = props => {
    const { webPage, pageId, defaultPageImage } = props;
    const company = useSelector(state => state.company.company);
    let image = webPage && webPage.heroImage && webPage.heroImage != null ? formatImage(webPage.heroImage.url, 3000, '.webp')
        : company.isRABSite ? defaultRABImage : defaultImage;
    let pageTitle = webPage ? webPage.heroTitle : defaultTitle;

    if (pageId && pageId.toLowerCase() === NavigationContants.LOGIN && !webPage) {
        pageTitle = 'Login';
    }

    if (pageId && pageId.toLowerCase() === NavigationContants.REGISTER && !webPage) {
        pageTitle = 'Sign up';
    }
    if (image === defaultImage)
        image = defaultPageImage ? formatImage(defaultPageImage.url, 3000, '.webp') : image;


    return (
        <React.Fragment>
            <HeroBox style={{ backgroundImage: "url(" + image + ")", backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: "cover" }}>
                <SlimContainer>
                    <Grid container>
                        <Grid item xs={12} md={8} style={{
                            width: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-50%)',
                        }}>
                            <Typography variant="h1" component="h1" align="center" style={{ margin: 0, textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}>
                                {pageTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </SlimContainer>
            </HeroBox>
        </React.Fragment >
    );
}

export default HeroInternal;