import jwt_decode from 'jwt-decode';

import * as NetworkConstants from '../../constants/NetworkConstants';
// import * as SecurityConstants from '../../constants/SecurityConstants';

import { getErrorMessagesFromResponse } from '../../helpers/apiFunctions';

// import { fetchPeople } from './peopleActions';
// import { checkClaim } from '../../helpers/helperFunctions';
import { fetchPaymentRecords, fetchPaymentMethodsForCurrentUser } from './paymentActions';
import { fetchProducts } from './productActions';
import { getProfileFromResponse } from "../../models/Person";

export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const SET_DID_TRY_AUTO_LOGIN = 'SET_DID_TRY_AUTO_LOGIN';

let timer;
const LOGOUT_TIME_CHECK = 15000;

export const setDidTryAL = () => {
    return { type: SET_DID_TRY_AUTO_LOGIN };
}

export const authenticate = (userId, token, person, roles, claims, expiryTimeInMilliSeconds) => {
    return async (dispatch, getState) => {
        dispatch(setLogoutTimer(LOGOUT_TIME_CHECK, expiryTimeInMilliSeconds));
        dispatch({ type: AUTHENTICATE, userId: userId, person: person, token: token, roles: roles, claims: claims });
        if (person.companyId && getState().company && getState().company.companyId) {
            const companyId = getState().company.companyId;
            // if ( checkClaim(claims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES)){
            //     dispatch(fetchPeople(companyId, true));
            // }
            dispatch(fetchPaymentMethodsForCurrentUser(companyId, true));
            dispatch(fetchPaymentRecords(companyId, true));
            dispatch(fetchProducts(companyId));
        }
    }
}

const saveDataToStorage = (token, person) => {
    localStorage.setItem('svxvUserData', JSON.stringify({
        token: token,
        person: person
    }))
}

export const checkEmailDoesNotExist = (email) => {
    return async (dispatch, getState) => {
        if (email.length > 0) {
            // any asych code you want - like service call
            let response = null;
            response = await fetch(NetworkConstants.SERVER + '/api/user/emailnotregistered?email=' + email, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                const errorResData = await response.json();
                const message = getErrorMessagesFromResponse(errorResData);
                throw new Error(message);
            }
            const resData = await response.json();
            return resData;
        }
        else {
            return false;
        }
    };
};

export const signup = (email, password, displayName, userName, phoneNumber, referredBy, companyId) => {
    return async dispatch => {
        const response = await fetch(NetworkConstants.SERVER + '/api/user/register',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    username: userName,
                    displayName: displayName,
                    phoneNumber: phoneNumber,
                    referredBy: referredBy,
                    password: password,
                    companyId: companyId
                })
            });

        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        var decodedToken = jwt_decode(resData.token);

        const person = getProfileFromResponse(resData);
        person.notifyOnNewAccount = false;
        await dispatch(authenticate(resData.id, resData.token, person, resData.roles, resData.claims, parseInt(decodedToken.exp) * 1000));

        saveDataToStorage(resData.token, person);
    };
}

export const login = (emailOrUsername, password, companyId, stayLoggedIn) => {
    return async dispatch => {
        const response = await fetch(NetworkConstants.SERVER + '/api/user/login',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailOrUsername: emailOrUsername,
                    password: password,
                    companyId: companyId
                })
            });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }

        const resData = await response.json();
        var decodedToken = jwt_decode(resData.token);

        const person = getProfileFromResponse(resData);
        person.notifyOnNewAccount = resData.notifyOnNewAccount;
        await dispatch(authenticate(resData.id, resData.token, person, resData.roles, resData.claims, parseInt(decodedToken.exp) * 1000));
        saveDataToStorage(resData.token, person);
    };
}

export const refreshLogedInUser = (token, companyId) => {
    return async (dispatch, getState) => {
        const tokenToUse = token ? token : getState().auth.token;
        const companyIdToUse = companyId ? companyId : getState().company.companyId;
        const response = await fetch(NetworkConstants.SERVER + '/api/user/' + companyIdToUse,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenToUse
                }
            });

        if (!response.ok) {
            // const errorResData = await response.json();
            dispatch(logout());
            throw new Error("Login denied");
        }

        const resData = await response.json();
        var decodedToken = jwt_decode(resData.token);

        const person = getProfileFromResponse(resData);
        person.notifyOnNewAccount = resData.notifyOnNewAccount;
        await dispatch(authenticate(resData.id, resData.token, person, resData.roles, resData.claims, parseInt(decodedToken.exp) * 1000));
        saveDataToStorage(resData.token, person); 
    };
}

export const changePassword = (formValues) => {
    return async (dispatch, getState) => {
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/user/changePassword', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getState().auth.token
            },
            body: JSON.stringify(
                formValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
    };
};

export const requestPasswordResetToken = (formValues) => {
    return async (dispatch, getState) => {
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/user/sendPasswordResetToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                formValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
    };
};

export const resetPassword = (formValues) => {
    return async (dispatch, getState) => {
        // any asych code you want - like service call
        let response = null;
        response = await fetch(NetworkConstants.SERVER + '/api/user/resetPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                formValues
            ),
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(getErrorMessagesFromResponse(errorResData));
        }
    };
};

export const logout = () => {
    clearLogoutTimer();
    localStorage.removeItem('svxvUserData');
    return { type: LOGOUT };
}

const clearLogoutTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
};

const setLogoutTimer = (expirationTime, expiryTimeInMilliSeconds) => {
    return dispatch => {
        timer = setTimeout(() => {
            if (new Date() <= new Date(expiryTimeInMilliSeconds)) {
                dispatch(setLogoutTimer(expirationTime, expiryTimeInMilliSeconds));
            }
            else {
                dispatch(logout());
            }

        }, expirationTime);
    };
}

