import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  Card,
  LinearProgress as MuiLinearProgress,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  InputAdornment as MuiInputAdornment,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoSearchOutline as SearchIcon,
  IoAddCircle as AddCircle
} from "react-icons/io5";

// svxvy imports
import * as NavigationConstants from '../../../constants/NavigationConstants';
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { fetchRoles } from '../../../store/actions/companyActions';
import { fetchPeople } from '../../../store/actions/peopleActions';
import { checkClaim } from '../../../helpers/helperFunctions';
import { compareObjects } from '../../../helpers/helperFunctions';
import ProfileCard from '../../../components/company/profile/ProfileCard';
import ProfileEditCard from '../../../components/company/profile/ProfileEditCard';
import ResizeTabs, { StyledTab } from '../../../components/framework/ResizeTabs';
// UI Consts

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  alert: {
    flex: "1",
  },
  updatingDiv: {
    flex: "1"
  },
  updatingTitle: {
    textAlign: "center"
  },
  // actionGrid: {
  //   backgroundColor: "white"
  // }
}));

const SearchField = styled(MuiTextField)`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 10px;

  &:before,
  &:after {
    display: none !important;
  }

  input {
    width: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    padding: 11px 10px 10px 34px;

    &::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    width: auto;
    margin: 0;
    float: right;

    input {
        width: 250px;
    }
  }
`;

const InputAdornment = styled(MuiInputAdornment)`
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 9px;

  svg {
    width: 18px;
    height: 18px;
    color: ${(props) => props.theme.palette.primary.main};
    opacity: 1;
  }
`;

const ProfileList = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
`;

const GroupTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};

  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
`;

const ProfileHeader = styled(Box)`
  position: relative;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 8px;
    display: flex;
    justifyContent: space-between;
    align-items: center;
  }
`;

const ProfileHeading = styled(Box)`
  ${(props) => props.theme.breakpoints.up("sm")} {
    display: flex;
    flex: 1;
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-bottom: 10px;
  }
`;

const ProfileInteract = styled(Box)`
  margin: 19px 0 10px 0;

  .MuiTextField-root {
    margin: 0 !important;
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin: 0;
    display: flex;
    flex: 1;
    flexDirection: column-reverse;
    align-items: center;
    justify-content: right;
  }
`;

const AddMember = styled(Button)`
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin: 0 0 0 20px;
    padding: 8px 45px 8px 45px;
    white-space: nowrap;
    position: relative;
    top: auto;
    right: auto;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 8px 25px 8px 25px;
  }
`;



const allOthers = '#';
const alphabetArray = [allOthers, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const extractItems = (items, companyList) => {
  const arrayToReturn = [];
  for (const l in alphabetArray) {
    const letter = alphabetArray[l];
    let data = null;
    if (letter !== allOthers)
      data = items.filter(x => x.displayName.toUpperCase().startsWith(letter));
    else
      data = items.filter(x => x.displayName.charAt(0) === allOthers || !alphabetArray.includes(x.displayName.toUpperCase().charAt(0)));

    if (data.length > 0)
      arrayToReturn.push({ title: letter, data: data })
  }

  return arrayToReturn;
}

const ACTIVE = 0;
const NEW = ACTIVE + 1;
const UPCOMING = NEW + 1;
const LESS_THAN_THIRTY_DAYS = UPCOMING + 1;
const LESS_THAN_SIXTY_DAYS = LESS_THAN_THIRTY_DAYS + 1;
const OLDER = LESS_THAN_SIXTY_DAYS + 1;
const NO_ACTIVITY = OLDER + 1;
const HAS_MEMBERSHIP = NO_ACTIVITY + 1;
const INACTIVE = HAS_MEMBERSHIP + 1;

const NUMBER_OF_DAYS = 15;
const NEW_SINCE = new Date((new Date() - (1000 * 60 * 60 * 24 * NUMBER_OF_DAYS)));

const Profiles = props => {
  const theme = useTheme();
  const currentUser = useSelector(state => state.auth.person);
  const currentUserClaims = useSelector(state => state.auth.claims);
  const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
  const allowAddEditPeople = currentUser && checkClaim(currentUserClaims, SecurityConstants.ALLOW_ADD_EDIT_ALL_PEOPLE);

  const classes = useStyles();

  const isMounted = React.useRef(true); // monitors if users navigates away from the page so no state is updated after the navigation.
  const authState = useSelector(state => state.auth.token);

  const [search, setSearch] = useState('');

  const companyId = useSelector(state => state.company.companyId);
  const company = useSelector(state => state.company.company);
  const isEmployeePage = props.location.pathname.toLowerCase().indexOf(NavigationConstants.EMPLOYEES.toLowerCase()) >= 0;
  const PROFILES_TEXT = useSelector(state => isEmployeePage ? state.company.employeesText : state.company.customersText);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [addingNewPerson, setAddingNewPerson] = useState(false);
  const [activeTab, setActiveTab] = useState(ACTIVE);

  //this is slow for large groups not sure why. 
  const people = useSelector(state => state.company.people).filter(x => !x.isDeleted && x.id !== currentUser.id);
  const setToFilter = activeTab === INACTIVE ? people.filter(p => p.isInactive) :
    activeTab === NEW ? people.filter(p => p.memberSince !== null && p.memberSince >= NEW_SINCE && !p.isInactive) :
      activeTab === UPCOMING ? people.filter(p => p.daysTillNextActivity !== null && p.daysTillNextActivity >= 0 && !p.isInactive) :
        activeTab === LESS_THAN_THIRTY_DAYS ? people.filter(p => p.daysTillNextActivity === null && p.daysSinceLastActivity !== null && p.daysSinceLastActivity >= -30 && !p.isInactive) :
          activeTab === LESS_THAN_SIXTY_DAYS ? people.filter(p => p.daysTillNextActivity === null && p.daysSinceLastActivity !== null && p.daysSinceLastActivity < -30 && p.daysSinceLastActivity >= -60 && !p.isInactive) :
            activeTab === OLDER ? people.filter(p => p.daysTillNextActivity === null && p.daysSinceLastActivity !== null && p.daysSinceLastActivity < -60 && !p.isInactive) :
              activeTab === NO_ACTIVITY ? people.filter(p => p.daysSinceLastActivity === null && p.daysTillNextActivity === null && !p.isInactive) :
                activeTab === HAS_MEMBERSHIP ? people.filter(p => p.hasActiveMembership && !p.isInactive) :
                  people.filter(p => !p.isInactive);
  const filteredPeople = setToFilter.filter(p =>
    search.length > 0
      ? p.displayName.toLowerCase().includes(search.toLowerCase()) || (p.email != null && p.email.toLowerCase().includes(search.toLowerCase())) || (p.phoneNumber != null && p.phoneNumber.includes(search))
      : true);
  filteredPeople.sort(compareObjects('displayName', 'asc'));
  const groupedPeople = extractItems(filteredPeople)


  const dispatch = useDispatch();

  const loadPeople = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (companyId) {
        await dispatch(fetchRoles(companyId));
        if (await dispatch(fetchPeople(companyId)) && isMounted.current)
          setIsLoading(false);
      }
    } catch (err) {
      if (isMounted.current) {
        setError(err.message);
        setIsLoading(false);
      }
    }
  }, [dispatch, companyId]);

  //authState is tracked here and forces a reload after login or logout to get the most current state.
  useEffect(() => {
    loadPeople();
  }, [loadPeople, authState]);

  // monitors if users navigates away from the page so no state is updated after the navigation.
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSearchChange = event => {
    setSearch(event.target.value);
  }

  return (
    <React.Fragment>
      <Helmet title={PROFILES_TEXT} />
      {error
        && <Grid container>
          {error && <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>}
        </Grid>
      }
      {!isLoading &&
        <React.Fragment>
          <Grid container style={{ paddingTop: '5px' }}>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
              <Box display="flex" flexDirection="column" flex={1}>
                <ProfileHeader>
                  <ProfileHeading>
                    <Typography variant="h2" component="h2" style={{ lineHeight: '40px' }}>Profiles ({filteredPeople.length})</Typography>
                  </ProfileHeading>
                  <ProfileInteract>
                    <SearchField
                      id="standard-search"
                      type="search"
                      placeholder="Search"
                      onChange={e => handleSearchChange(e)}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {allowAddEditPeople &&
                      <AddMember startIcon={<AddCircle color={theme.palette.primary.contrastText} />} variant="contained" color="primary" onClick={() => setAddingNewPerson(true)} mt={2}>Add Profile</AddMember>
                    }
                  </ProfileInteract>
                </ProfileHeader>
                {allowAddEditPeople &&
                  <ResizeTabs maxWidth={1050} my={3} activeTab={activeTab} setActiveTab={setActiveTab}>
                    <StyledTab label='All Active' />
                    <StyledTab label='New' />
                    <StyledTab label='Upcoming' />
                    <StyledTab label='30 Days' />
                    <StyledTab label='60 Days' />
                    <StyledTab label='Older' />
                    <StyledTab label={'No ' + ACTIVITIES_TEXT} />
                    {!company.isRABSite &&  <StyledTab label='Has Membership' />}
                    <StyledTab label='Inactive' />
                  </ResizeTabs>
                }
              </Box>
            </Grid>
          </Grid>
          {
            groupedPeople.map((group, index) => {
              return (
                <Grid key={group.title} container style={{ paddingTop: '5px' }}>
                  <Grid item xs={false} sm={1}></Grid>
                  <Grid item xs={12} sm={10}>
                    <Box mt={index === 0 ? 2 : 6}>
                      <Box display="flex" flex={1}>
                        <GroupTitle variant="h6">{group.title}</GroupTitle>
                      </Box>
                      <ProfileList>
                        {group.data.map((person, index) => {
                          return (
                            <ProfileCard key={person.id}
                              person={person}
                              isChildProfile={person.parentAccountId ? true : false}
                              urlBase={isEmployeePage ? NavigationConstants.EMPLOYEES : NavigationConstants.CUSTOMERS} />
                          )
                        })}
                      </ProfileList>
                    </Box>
                  </Grid>
                </Grid>
              )
            })
          }
        </React.Fragment >
      }
      {
        addingNewPerson &&
        <ProfileEditCard
          open={addingNewPerson}
          setOpen={setAddingNewPerson}
        />
      }
      {
        isLoading
        && <Grid container>
          <Box flex={1}>
            <LinearProgress my={2} color="primary" />
            <Typography variant={"h2"} align="center" gutterBottom>Loading {PROFILES_TEXT}...</Typography>
            <LinearProgress my={2} color="secondary" />
          </Box>
        </Grid>
      }
    </React.Fragment >
  )
}

export default withTheme(Profiles);