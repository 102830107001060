import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet-async';

import {
  Box,
  ListItem,
  Typography as MuiTypography,
} from "@material-ui/core";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Privacy = props => {
  return (
    <Wrapper>
      <Helmet title="Privacy Policy" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Privacy Policy
      </Typography>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
        <div class="col-12 col-md-8 offset-md-2">
          <p>Your privacy is important to us. It is SmartCore’s policy to respect your privacy regarding any information we may collect from you through our app.</p>
          <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
          <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
          <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
          <p>Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
          <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
          <p>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
          <p>As an SmartCore option Sitecore can be connected to a Google Calendar instance.  In this case we will store your google username, calendar id, and google access token.  If granted, we will read and write SmartCore events onto this calendar any events on this calendar will be able to be read by Sitecore.  However, we do not store or record any non-SmartCore events in our application.<br />This policy is effective as of 1 November 2022.</p>
        </div>
      </Box>

      <Typography component="h1" variant="h1" align="center" gutterBottom>
       Account Deletion
      </Typography>
      <div class="col-12 col-md-8 offset-md-2">
        <p>To delete your SmartCore or Steel Curtain Boxing App Account</p>
        <ol>
          <li>Login to your account on the mobile app</li>
          <li>Tap your profile image in the top left corner</li>
          <li>Select ‘Edit’ profile</li>
          <li>Scroll to bottom and select ‘delete’ account</li>
        </ol>
        <p>Prior payment and class attendance history will be retained.</p>
      </div>
    </Wrapper>
  );
}

export default Privacy;
