import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { useMediaQuery } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";

import {
  Box,
  Card,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
import { compareObjects } from "../../../helpers/helperFunctions";
import { getRTEValue } from "../../../helpers/rteFunctions";
import { Element } from "../../framework/RTE/SlateComponents/SlateElement";
import { Leaf } from "../../framework/RTE/SlateComponents/SlateLeaf";

// UI Consts
const Typography = styled(MuiTypography)(spacing);

const getReviewsToShow = (reviews, numberToShow) => {
  if (!reviews) return [];
  else if (reviews.length <= numberToShow) return reviews;
  else {
    const reviewsToReturn = [];
    while (reviewsToReturn.length <= numberToShow) {
      const review = reviews[Math.floor(Math.random() * reviews.length)];
      if (reviewsToReturn.find((x) => x.id === review.id)) continue;
      else reviewsToReturn.push(review);
    }
    return reviewsToReturn;
  }
};

const ReviewBox = (props) => {
  const { review } = props;
  const editor = useMemo(() => withReact(createEditor()), []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const reviewText = getRTEValue(review.reviewText);

  if (
    reviewText.length === 1 &&
    reviewText[0].children !== null &&
    reviewText[0].children.length === 1 &&
    reviewText[0].children[0].text !== null &&
    reviewText[0].children[0].text.trim() === ""
  )
    return <React.Fragment />;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card style={{ height: "100%", padding:"20px", display:"flex"}}>
          <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <Typography variant="h3" align="center">
                {review.title}
              </Typography>
              {reviewText && (
                <Slate editor={editor} value={reviewText}>
                  <Editable
                    readOnly
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                  />
                </Slate>
              )}
            </Box>
            <Box display="flex" flexDirection="column">
              {review.reviewerName && (
                <Typography variant="h4" align="center">
                  {review.reviewerName}
                </Typography>
              )}
            </Box>
          </Box>
      </Card>
    </Grid>
  );
};

const WebsiteReviewContainer = (props) => {
  const CUSTOMERS_TEXT = useSelector((state) => state.company.customersText);
  const reviews = useSelector((state) => state.company.reviews);
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  reviews.sort(compareObjects("createOn", "desc"));
  const [reviewsToShow] = useState(
    getReviewsToShow(reviews, isTabletUp ? 3 : 2)
  );

  if (reviewsToShow && reviewsToShow.length === 0) return <React.Fragment />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          See what our {CUSTOMERS_TEXT.toLowerCase()} are saying
        </Typography>
      </Grid>
      {reviewsToShow.map((review, index) => {
        return <ReviewBox key={review.id} review={review} />;
      })}
    </Grid>
  );
};

export default WebsiteReviewContainer;
