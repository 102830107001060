import React from "react";


import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import parse from 'html-react-parser';
import i18n from 'i18n-js';

// import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy import
import ParagraphTypography from "../../framework/ParagraphTypography";

// constants

const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);


const SmartCoreProductCard = (props) => {
    const { product, selectedCallback, isSelected } = props;

    return (
        <Card p={2} >
            <Box>
                <Typography variant="h3" mb={2}>{product.name}</Typography>
                <Typography variant="h4" >
                    {i18n.toCurrency(product.unitAmount, { precision: 2, unit: '$' })}
                    {(product.isRecurring || product.units > 0) &&
                        <React.Fragment>
                            {product.isRecurring ? " / " + (product.recurringIntervalCount > 1 ? product.recurringIntervalCount + " " : "") + product.recurringInterval + (product.recurringIntervalCount > 1 ? "s" : "") : ""}
                        </React.Fragment>
                    }
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" component="span" mb={2}>+ sales tax where applicable</Typography>
                {product.description && <ParagraphTypography mt={2}>{parse(product.description)}</ParagraphTypography>}
            </Box>
            <Button variant="outlined" color="primary" onClick={selectedCallback} disabled={isSelected} mt={2}>
                {isSelected ? 'Current Subscription' : 'Calculate Tax'}
            </Button>
        </Card >
    );
}

export default SmartCoreProductCard;