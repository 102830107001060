import React from "react";
import { useSelector } from 'react-redux';

import parse from 'html-react-parser';

const options = {
    replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === 'remove') {
            return <></>;
        }
    },
};

const CompanyEmbedScripts = props => {
    const company = useSelector(state => state.company.company);

    if (!company || !company.embedScripts)
        return <React.Fragment></React.Fragment>;
    else
        return parse(company.embedScripts, options);
}

export default CompanyEmbedScripts;