

import React, { useCallback, useState } from "react";
import { useDispatch } from 'react-redux';
import { Formik } from "formik"
import { cloneDeep } from 'lodash';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    Divider as MuiDivider,
    Grid,
    IconButton as MuiIconButton,
    Typography as MuiTypography,
    Hidden
} from "@material-ui/core";

import { SketchPicker as MuiSketchPicker } from 'react-color';
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoAddCircleOutline as IncreaseSizeIcon,
    IoRemoveCircleOutline as DecreaseSizeIcon,
    IoCheckmarkCircleOutline as SaveIcon,
} from "react-icons/io5";

// svxvy import

import { editCompanyAttributes } from '../../../store/actions/companyActions'
import { formatImage } from '../../../helpers/imageHelpers';
import { SET_COMPANY_ATTIBUTES } from '../../../store/actions/registerCompanyActions'
import * as Colors from "../../../constants/Colors";
import ActionButton from "../../framework/ActionButton";
import UploadPhoto from "../photos/UploadPhoto";
import { DEFAULT_COMPANY_LOGO } from "../../../models/Company";

// constants

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ThemeHighlight = styled(Box)`
    width: 24px;
    height: 24px;
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 999999px;
`;

const Image = styled.img`
max-width: 100%;
max-height: 100%;
object-fit: contain;
// background-color: green;
`

const OutterImageBox = styled(Box)`
    height: auto;
    border: 1px solid ${(props) => props.theme.palette.divider};
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const ImageBox = styled(Box)`
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ColorBoxWrapper = styled(Box)`
    text-align: center;
`;

const ColorBox = styled(Box)`
    height: 72px;
    width: 72px;
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin: 0 0 5px 0;

    &.MuiButtonBase-root {
        border-radius: 10px;
    }
`;

const BrandingHeaderSave = styled(Box)`
    display: none;

    ${(props) => props.theme.breakpoints.up("sm")} {
        display: flex;
        flex-direction: column-reverse
    }
`;

const BrandingCardContent = styled(Card)`
    padding: 25px 30px 30px 30px;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: 30px 30px 30px 30px;
    }
`;

const ColorPickerWrapper = styled(Box)`
    ${(props) => props.theme.breakpoints.up("sm")} {
        display: flex;
        flex-direction: row;
    }
`;

const ThemeSelectButton = styled(Button)`

    margin: 0 0 20px 0;
    justify-content: left;

    &.MuiButton-outlinedPrimary {
        border: 1px solid ${(props) => props.theme.palette.primary.main};
    }

    ${(props) => props.theme.breakpoints.up("sm")} {
        margin-right: 20px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
        width: 100%;
    }

    .MuiButton-label {
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    svg {
        color: ${(props) => props.theme.palette.text.secondary};
        font-size: 28px !important;
    }

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: auto;
        min-width: 139px;
        margin: 0 20px 20px 0;
        display: inline-flex;
    }
`;

const SketchPicker = styled(MuiSketchPicker)`
    background-color: ${(props) => props.theme.palette.background.paper} !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    border-color: green;


    .flexbox-fix {
        background-color: ${(props) => props.theme.palette.background.paper};
        border: none !important;
    }

    label {
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    input {
        box-shadow: none !important;
        border: 1px solid ${(props) => props.theme.palette.divider} !important;
    }
`;

const ActionButtonBottom = styled(ActionButton)`
    width: 100%;
    margin: 0;

    ${(props) => props.theme.breakpoints.up("sm")} {
        width: auto;
        margin: 0 0 0 auto;
    }
`;

const LogoWrapper = styled(Box)`
    margin-bottom: 30px;

    // ${(props) => props.theme.breakpoints.up("lg")} {
    //     max-width: 50%;
    // }
`;

function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function hexToRGB(hexColor) {
    if (hexColor.startsWith('#'))
        hexColor = hexColor.substring(1, hexColor.length)
    var aRgbHex = hexColor.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    return aRgb;
}

function contrast(rgb1, rgb2) {

    var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return ((brightest + 0.05)
        / (darkest + 0.05)).toFixed(2);
}

const CompanyAttributes = props => {
    const { company, theme } = props;
    const [wasSaveSuccesful, setWasSaveSuccesful] = useState(false);
    const [logo, setLogo] = useState(company.logoPhoto ? company.logoPhoto : { url: DEFAULT_COMPANY_LOGO });

    const initialValues = {
        themePrimary: theme.palette.primary.main,
        useDarkTheme: company.colors.useDarkTheme,
        logoPercent: company.logoPhoto ? company.logoPhoto.scalingFactor : 100,
    };

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const submitCallback = useCallback(async (companyId, values, isRegistration, onSuccess) => {
        setError(null);
        setIsSubmitting(true);
        setWasSaveSuccesful(false);
        try {
            if (isRegistration)
                dispatch({ type: SET_COMPANY_ATTIBUTES, attributes: values });
            else
                await dispatch(editCompanyAttributes(values, companyId, '/design'));
            setIsSubmitting(false);
            setWasSaveSuccesful(true);
            if (onSuccess)
                onSuccess();
        } catch (error) {
            setError(error.message);
            setIsSubmitting(false);
        }
    }, [dispatch]);

    const handleSubmit = (values) => {
        const valuesToSend = cloneDeep(values);
        valuesToSend.logo = company && logo.url !== company.logo && logo.url !== DEFAULT_COMPANY_LOGO ? logo : null;
        valuesToSend.clearPhoto = logo.url === DEFAULT_COMPANY_LOGO ? true : false;
        valuesToSend.scalingFactor = values.logoPercent;
        submitCallback(company.id, valuesToSend, props.isRegistration, props.onSuccess);
    };

    return (
        <Grid container>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={10}>
                <Box>
                    <Box>
                        <Box display="flex" justifyContent="space-between" mb={3.6} >
                            <Box display="flex" flex={1}>
                                <Typography variant="h1" component="h1" style={{ lineHeight: '40px' }}>Company Branding</Typography>
                            </Box>
                            <BrandingHeaderSave display="flex" flexDirection="column-reverse">
                                <ActionButton
                                    form="companybranding"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    startIcon={<SaveIcon />}
                                    submitting={isSubmitting}
                                // disabled={
                                //     (
                                //         contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) < 2
                                //     )
                                // }
                                >Save Settings</ActionButton>
                            </BrandingHeaderSave>
                        </Box>
                        {error && <Alert mb={4} severity="error">
                            {error}
                        </Alert>}
                        {wasSaveSuccesful &&
                            <Alert mb={4} severity="success">
                                Succesfully saved.
                            </Alert>
                        }
                    </Box>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        // innerRef={ref}
                    >
                        {({
                            handleSubmit,
                            values,
                            setFieldValue,
                        }) => (
                            <Card mb={4} style={{ border: '1px solid ' + theme.palette.divider, borderRadius: '10px' }}>
                                <BrandingCardContent>
                                    <form id="companybranding" onSubmit={handleSubmit}>
                                        <Typography variant="h3" component="h3" mb={2}>Theme</Typography>
                                        <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }} mb={3}>Select the theme, this will affect your public website, member portal and mobile app.</Typography>
                                        <Box mt={4} mb={2}>
                                            <ThemeSelectButton
                                                variant="outlined"
                                                startIcon={<ThemeHighlight bgcolor="white" />}
                                                color={values.useDarkTheme ? "default" : "primary"}
                                                onClick={() => setFieldValue('useDarkTheme', false)}
                                            >
                                                Light
                                            </ThemeSelectButton>
                                            <ThemeSelectButton
                                                variant="outlined"
                                                startIcon={<ThemeHighlight bgcolor="black" />}
                                                ml={3}
                                                color={!values.useDarkTheme ? "default" : "primary"}
                                                onClick={() => setFieldValue('useDarkTheme', true)}
                                            >
                                                Dark
                                            </ThemeSelectButton>
                                        </Box>
                                        <Divider style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} />
                                        <Typography variant="h3" component="h3" mt={5} mb={2}>Logo</Typography>
                                        <Hidden xsDown>
                                            <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }} mb={5}>Logo is shown to scale as it appears on your site.  Uploaded graphics should be at least 800x800 pixels to display properly.</Typography>
                                        </Hidden>
                                        <Hidden smUp>
                                            <Typography variant="subtitle1" component="p" style={{ color: theme.palette.text.secondary }} mb={5}>Logo adjustments and changes can only be made on a computer or tablet.</Typography>
                                        </Hidden>
                                        <Hidden xsDown>
                                            <LogoWrapper>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="subtitle1" style={{ fontWeight: '700' }}>Scale</Typography>
                                                        <IconButton
                                                            onClick={() => setFieldValue('logoPercent', values.logoPercent - 10)}
                                                            disabled={values.logoPercent === 10}
                                                            style={{ backgroundColor: 'transparent !important', color: theme.palette.primary.main, marginTop: '-1px', padding: '0 5px 0 10px' }}
                                                        >
                                                            <DecreaseSizeIcon />
                                                        </IconButton>
                                                        <Typography variant="subtitle1" style={{ fontWeight: '700' }}>{values.logoPercent}%</Typography>
                                                        <IconButton
                                                            onClick={() => setFieldValue('logoPercent', values.logoPercent + 10)}
                                                            disabled={values.logoPercent === 100}
                                                            style={{ backgroundColor: 'transparent !important', color: theme.palette.primary.main, marginTop: '-1px', padding: '0 5px' }}
                                                        >
                                                            <IncreaseSizeIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <UploadPhoto
                                                        image={logo}
                                                        hideImage
                                                        rowButtons
                                                        onSuccess={(image) => {
                                                            setLogo(image);
                                                        }}
                                                        removePhoto={() => setLogo({ url: DEFAULT_COMPANY_LOGO })}
                                                    />
                                                </Box>
                                                <OutterImageBox bgcolor={values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT}>
                                                    <ImageBox
                                                        bgcolor={values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT}
                                                        >
                                                        <Image
                                                            src={formatImage(logo.url, null, '.webp')}
                                                            width={logo && logo.width && (logo.width / logo.height > 1.8) ? (400 * values.logoPercent / 100) : 'auto'}
                                                            height={logo && logo.height && (logo.width / logo.height > 1.8) ? 'auto' : logo.height > 100 ? (100 * (values.logoPercent * 0.01)) : (logo.height * (values.logoPercent * 0.01))}
                                                        // style={{ maxWidth: '100%', maxHeight: '100px' }}
                                                        />
                                                    </ImageBox>
                                                </OutterImageBox>
                                            </LogoWrapper>
                                        </Hidden>
                                        <Divider style={{ width: 'calc(100% + 60px)', marginLeft: '-30px' }} my={4} />
                                        <Typography variant="h3" component="h3" mt={5} mb={2}>Accent Color</Typography>
                                        <Typography variant="subtitle1" component="p" mb={5} style={{ color: theme.palette.text.secondary }}>Used on the website, portal and mobile app.</Typography>

                                        <ColorPickerWrapper>
                                            <Box display='flex' mr={4} mb={6} style={{ height: '88px' }}>
                                                <ColorBoxWrapper style={{ borderRight: '1px solid ' + theme.palette.divider, paddingRight: '30px' }}>
                                                    <ColorBox
                                                        component={Button}
                                                        bgcolor={company.colors.themePrimary}
                                                        mr={2}
                                                        onClick={() => setFieldValue('themePrimary', company.colors.themePrimary)}>
                                                    </ColorBox>
                                                    <Typography variant="h5" style={{ textTransform: 'none' }}>Current Color</Typography>
                                                </ColorBoxWrapper>
                                                <ColorBoxWrapper style={{ marginLeft: '30px' }}>
                                                    <ColorBox bgcolor={values.themePrimary} mr={2}>
                                                    </ColorBox>
                                                    <Typography variant="h5" style={{ textTransform: 'none' }}>New Color</Typography>
                                                </ColorBoxWrapper>
                                            </Box>
                                            <Box>
                                                <SketchPicker color={values.themePrimary} onChangeComplete={color => { setFieldValue('themePrimary', color.hex); setWasSaveSuccesful(false) }} />
                                            </Box>
                                        </ColorPickerWrapper>
                                        <Typography variant="h6" my={3}>Contrast Ratio using the {values.useDarkTheme ? 'Dark' : 'LIGHT'} Theme: {contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT))}</Typography>
                                        {/* <Typography variant="h6" mt={3}>Contrast Ratio using the Dark Theme: {contrast(hexToRGB(values.themePrimary), hexToRGB(Colors.THEME_BASE_DARK))}</Typography> */}
                                        {(
                                            contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) < 2
                                        ) &&
                                            <Alert severity="error">Your contrast ration cannot be below 2 which is not allowed please choose anothor color or switch to the {values.useDarkTheme ? 'light' : 'dark'} theme.</Alert>
                                        }
                                        {(
                                            contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) < 4.5
                                        ) &&
                                            (contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) >= 2)
                                            &&
                                            <Alert severity="warning">Your contrast ratio is below Level AA guidelines and some users may find it difficult to read you may want to choose anothor color or switch to the {values.useDarkTheme ? 'light' : 'dark'} theme.</Alert>
                                        }
                                        {contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) >= 4.5 &&
                                            <Alert severity="success">Your contrast ratio is the same or better than the minimum compliance standards.</Alert>
                                        }
                                    </form>
                                </BrandingCardContent>
                            </Card>
                        )
                        }
                    </Formik >
                    <Box>
                        {error &&
                            <Alert mb={4} severity="error">
                                {error}
                            </Alert>
                        }
                        {wasSaveSuccesful &&
                            <Alert mb={4} severity="success">
                                Succesfully saved.
                            </Alert>
                        }
                        <Box display="flex" justifyContent="flex-end" mb={2} style={{ width: '100%' }}>
                            <ActionButtonBottom
                                form="companybranding"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                startIcon={<SaveIcon />}
                                mt={2}
                                submitting={isSubmitting}
                            // disabled={
                            //     (
                            //         contrast(hexToRGB(values.themePrimary), hexToRGB(values.useDarkTheme ? Colors.THEME_BASE_DARK : Colors.THEME_BASE_LIGHT)) < 2
                            //     )
                            // }
                            >Save Settings</ActionButtonBottom>
                        </Box>
                    </Box>
                </Box >
            </Grid>
        </Grid>


    )
};

export default CompanyAttributes;

