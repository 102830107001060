import { cloneDeep } from 'lodash';


export const deleteById = (idToRemove, arrayToUpdate) => {
    let updatedArray = cloneDeep(arrayToUpdate);
    const itemtoRemoveIndex = updatedArray.findIndex(item => item.id === idToRemove);
    if (itemtoRemoveIndex >= 0) {
        updatedArray.splice(itemtoRemoveIndex, 1);
    }
    return updatedArray;
}

export const addToArray = (itemToAdd, arrayToUpdate, addToFront = false) => {
    let updatedArray = cloneDeep(arrayToUpdate);
    if (addToFront)
        updatedArray.unshift(itemToAdd);
    else
        updatedArray.push(itemToAdd);
    return updatedArray;
}