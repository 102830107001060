import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import {
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports
import { fetchRoles } from '../../../store/actions/companyActions';

import ProfileEditCard from '../../../components/company/profile/ProfileEditCard';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

const SCALED_HEIGHT = 400;
const MIN_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
  alert: {
    flex: "1",
  },
  updatingDiv: {
    flex: "1"
  },
  updatingTitle: {
    textAlign: "center"
  },
  media: {
    height: SCALED_HEIGHT,
    width: MIN_WIDTH,
    margin: '0 auto'
  },
}));

const Profile = props => {
  const classes = useStyles();

  const company = useSelector(state => state.company.company);
  const currentUser = useSelector(state => state.auth.person);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const loadPeople = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      await dispatch(fetchRoles(company.id));
      setIsLoading(false);
    } catch (err) {
        setError(err.message);
        setIsLoading(false);
    }
  }, [dispatch, company]);

  useEffect(() => {
    loadPeople();
  }, [loadPeople]);


  if (isLoading)
    return (
      <Grid container spacing={6}>
        {isLoading && <div className={classes.updatingDiv}>
          <LinearProgress my={2} color="primary" />
          <Typography variant={"h2"} className={classes.updatingTitle} gutterBottom>Loading profile...</Typography>
          <LinearProgress my={2} color="secondary" />
        </div>
        }
      </Grid>
    );

  return (
    <React.Fragment>
      {currentUser && <Helmet title={currentUser.displayName + " - " + (company ? company.name : "")} />}
      {error
        && <Grid container spacing={6}>
          {error && <Alert className={classes.alert} mb={4} severity="error">{error}</Alert>}
        </Grid>
      }
      {currentUser &&
        <Grid container spacing={6}>
          <ProfileEditCard person={currentUser} />
        </Grid>
      }
      {!currentUser && !isLoading &&
        <React.Fragment>
          <Helmet title={"Profile not found - " + (company ? company.name : "")} />
          <Typography variant="h2">Hmm... we couldn't find this profile.</Typography>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default withTheme(Profile);