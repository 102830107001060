import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { withTheme } from "styled-components/macro";

// svxvy import
import { replaceDisplayName } from '../../../helpers/navigationFunctions';
import DefaultGrid from '../../../components/companyPublic/DefaultGrid';
import FaqList from '../../../components/company/faqs/FaqList'
// import EditOfferingModal from '../../../components/companyPublic/EditOfferingModal';

// constants


const FaqsPage = (props) => {
    const company = useSelector(state => state.company.company);

    let location = useLocation();
    const pageName = replaceDisplayName(location.pathname.split('/').slice(-1)[0], company);

    return (
        <React.Fragment>
            <Helmet title={pageName} />
            <DefaultGrid>
                <FaqList />
            </DefaultGrid>
        </React.Fragment>
    );
}

export default withTheme(FaqsPage);
