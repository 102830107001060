import React from "react";
import { useSelector } from "react-redux";


// import CacheBuster from 'react-cache-buster';
import ReactGA from 'react-ga';


// import styled from "styled-components/macro";
// import { spacing } from "@material-ui/system";

import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

// svxvy imports
// import { version } from '../package.json';
import createTheme from "./theme";
import Routes from "./routes/Routes";

// design consttans
// const LinearProgress = styled(MuiLinearProgress)(spacing);
// const Paper = styled(MuiPaper)(spacing);
// const Typography = styled(MuiTypography)(spacing);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});


// const UpdatingAppVersion = () => {
//   return (
//     <React.Fragment>
//       <Typography variant="h1" gutterBottom align="center">
//         We are downloading the latest version of the website.  It should just take a second.
//       </Typography>
//       <Paper mt={3}>
//         <LinearProgress my={2} />
//         <LinearProgress my={2} />
//         <LinearProgress my={2} />
//       </Paper>
//     </React.Fragment>
//   )
// }

ReactGA.initialize('UA-180886332-2')

function App() {
  // const isProduction = process.env.NODE_ENV === 'production';
  const theme = useSelector((state) => state.themeReducer);

  // this is not working don't know why
  // <CacheBuster
  //   currentVersion={version}
  //   isEnabled={true} //If false, the library is disabled.
  //   isVerboseMode={true} //If true, the library writes verbose logs to console.
  //   loadingComponent={<UpdatingAppVersion />} //If not pass, nothing appears at the time of new version check.
  // >
  return (

    <StylesProvider jss={jss}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={createTheme(theme.currentTheme, theme.currentTypography, theme.currentCompany)}>
          <ThemeProvider theme={createTheme(theme.currentTheme, theme.currentTypography, theme.currentCompany)}>
            <Routes />
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
}

export default App;
