import { cloneDeep } from 'lodash';

import { AUTHENTICATE, LOGOUT, SET_DID_TRY_AUTO_LOGIN } from '../actions/authActions';
import {
    SET_PAYMENT_METHODS, ADD_PAYMENT_METHOD, SET_AS_DEFAULT_PAYMENT_METHOD, REMOVE_PAYMENT_METHOD,
    SET_PURCHASES
} from '../actions/paymentActions';
import { PRODUCT_PURCHASED, PRODUCT_UPDATED } from '../actions/productActions';
import { UPDATE_PAYMENT_STATUS } from '../actions/paymentActions';

import { addToArray, deleteById } from "../../helpers/arrayFunctions";

const initialState = {
    token: null,
    userId: null,
    person: null,
    roles: null,
    claims: null,
    paymentMethods: [],
    purchases: [],
    didTryAutoLogin: null,
};

const updateDefaultPaymentMethod = (paymentMethodId, companyId, paymentMethods) => {
    const updatedPaymentMethods = cloneDeep(paymentMethods);
    const oldDefaultMethod = updatedPaymentMethods.find(x => x.isDefault && x.companyId === companyId);
    if (oldDefaultMethod)
        oldDefaultMethod.isDefault = false;
    const newDefaultMethod = updatedPaymentMethods.find(x => x.id === paymentMethodId && x.companyId === companyId);
    if (newDefaultMethod)
        newDefaultMethod.isDefault = true;
    return updatedPaymentMethods;
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            return { ...state, token: action.token, userId: action.userId, person: action.person, roles: action.role, claims: action.claims, didTryAutoLogin: true };
        case SET_DID_TRY_AUTO_LOGIN:
            return { ...state, didTryAutoLogin: true };
        case SET_PAYMENT_METHODS:
            return { ...state, paymentMethods: cloneDeep(action.paymentMethods) };
        case ADD_PAYMENT_METHOD:
            if (action.userId === state.userId) {
                const pm = action.paymentMethod;
                if (state.paymentMethods.length === 0)
                    pm.isDefault = true;
                return { ...state, paymentMethods: addToArray(pm, state.paymentMethods) };
            }
            return { ...state };
        case SET_AS_DEFAULT_PAYMENT_METHOD:
            if (action.userId === state.userId)
                return { ...state, paymentMethods: updateDefaultPaymentMethod(action.paymentMethodId, action.companyId, state.paymentMethods) };
            return { ...state };
        case REMOVE_PAYMENT_METHOD:
            if (action.userId === state.userId)
                return { ...state, paymentMethods: deleteById(action.paymentMethodId, state.paymentMethods) };
            return { ...state };
        case SET_PURCHASES:
            return { ...state, purchases: action.purchases };
        case PRODUCT_PURCHASED:
            return { ...state, purchases: addToArray(action.purchase, state.purchases) };
        case PRODUCT_UPDATED:
            return { ...state, purchases: addToArray(action.purchase, deleteById(action.purchase.id, state.purchases)) }
        case UPDATE_PAYMENT_STATUS:
            const personToUpdate = cloneDeep(state.person);
            let updatedPuchases = personToUpdate.purchases;
            if (action.purchase) {
                updatedPuchases = deleteById(action.purchase.id, updatedPuchases);
                updatedPuchases = addToArray(action.purchase, updatedPuchases);
            }
            if (action.packageUsed) {
                updatedPuchases = deleteById(action.packageUsed.id, updatedPuchases);
                updatedPuchases = addToArray(action.packageUsed, updatedPuchases);
            }

            personToUpdate.purchases = updatedPuchases;
            return { ...state, person: personToUpdate };
        case LOGOUT:
            return { ...initialState, didTryAutoLogin: true };
        default:
            return state;
    }
};

export default authReducer;