import React from 'react';
// import { useSelector } from 'react-redux';


// UI Imports
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
// import ActivityList from '../../components/company/activities/ActivityList';
import ActivityList from '../../components/company/activities/ActivityList';
import MessageAlertList from '../../components/company/messages/MessageAlertList';
import OwnerGettingStarted from "../../components/company/helpContent/OwnerGettingStarted";
import OwnerMemberTally from '../../components/company/helpContent/OwnerMemberTally';

// UI Consts
const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Header = styled(Box)`
  position: relative;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 8px;
    display: flex;
    justifyContent: space-between;
    align-items: center;
  }
`;

const Heading = styled(Box)`
  ${(props) => props.theme.breakpoints.up("sm")} {
    display: flex;
    flex: 1;
  }
`;


const DashboardPage = props => {
  // const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);

  return (
    <React.Fragment>
      <Helmet title={"Dashboard"} />

      <React.Fragment>
        <Grid container style={{ paddingTop: '5px' }}>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Header>
              <Heading>
                <Typography variant="h2" component="h2" style={{ lineHeight: '40px' }}>Dashboard</Typography>
              </Heading>
              <OwnerMemberTally/>
            </Header>
            <OwnerGettingStarted />
            <MessageAlertList />
            <Card>
              <CardContent>
                <ActivityList
                  onlyMy
                  noAdmin
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    </React.Fragment>
  );
}

export default DashboardPage;
