import { Editor } from "slate";

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);

    if (format === 'link')
        return marks && marks.link ? true : false;
    else
        return marks ? marks[format] === true : false;
};

