import React, { useState, useCallback } from 'react';
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { Formik, Field } from 'formik';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    FormControlLabel as MuiFormControlLabel,
    FormGroup,
    Switch,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
// import InputMask from "react-input-mask";

/*********************** svxvy import **************************/

import { requestPasswordResetToken } from '../../../store/actions/authActions'
import ActionButton from '../../framework/ActionButton';

/*********************** class Styles import **************************/
const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

const GetLoginTokenForm = props => {
    const { onResetSuccess, onPasswordSuccess, additionalComponents, email } = props;
    const [isResetting, setIsResetting] = useState(false);
    const [isGettingPassword, setIsGettingPassword] = useState(false);

    const [error, setError] = useState(null);

    // const [phoneToken, setPhoneToken] = useState(true);
    // const phoneExplanation = ("Enter your mobile number. If it is a registered number you will get a text " +
    //     "message with a 6 digit number from 1-833-758-7477 which you can use to " + (resetPass ? "reset your password in the next screen" : "login"));
    // const emailExplanation = ("Enter yor email. If it is a registered email you will get an email " +
    //     "with a 6 digit number from support@smartcore.com which you can use to " + (resetPass ? "reset your password in the next screen" : "login"));

    const dispatch = useDispatch();

    const submitCallback = useCallback(async (values, resetForm, isReset, onSuccess) => {
        try {
            if (isReset)
                setIsResetting(true);
            else
                setIsGettingPassword(true);
            setError(null);
            await dispatch(requestPasswordResetToken(values));
            resetForm();
            setIsResetting(false);
            setIsGettingPassword(false);
            if (onSuccess)
                onSuccess(values.email);
        } catch (error) {
            const message = error.message || "Something went wrong";
            setError(message);
            setIsResetting(false);
            setIsGettingPassword(false);
        }
    }, [dispatch]);

    const handleSubmit = (values, resetForm, isReset, onSuccess) => {
        submitCallback(values, resetForm, isReset, onSuccess);
    };


    return (
        <Formik
            initialValues={{
                email: email ? email : "",
                sendAsEmail: true,
                sendAsText: false,
                submit: false,
            }}
            validationSchema={
                (Yup.object().shape({
                    email: Yup.string().email().required("Required"),
                }))}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                touched,
                values,
                resetForm
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box>
                        <Typography variant="h2" style={{ margin: '0 0 16px 0', fontSize: '28px', lineHeight: '30px' }}>Authentication Code</Typography>
                    </Box>
                    <Typography style={{ margin: '0 0 26px 0', fontSize: '14px', lineHeight: '18px' }}>
                        Get an authentication code to reset your password. Or get a one-time password to sign in without resetting.
                    </Typography>
                    <TextField
                        type="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    {/* } */}
                    <Box display="flex" ml={4} >
                        <FormGroup>
                            <FormControlLabel control={
                                <Field name='sendAsEmail' component={Switch} onChange={(val) => setFieldValue('sendAsEmail', !values.sendAsEmail)} checked={values.sendAsEmail} />
                            } label={'Send in Email'} />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={
                                <Field name='sendAsText' component={Switch} onChange={(val) => setFieldValue('sendAsText', !values.sendAsText)} checked={values.sendAsText} />
                            } label={'Send as Text'} />
                        </FormGroup>
                    </Box>
                    <ActionButton
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        submitting={isResetting}
                        disabled={isGettingPassword || (!values.sendAsEmail && !values.sendAsText)}
                        mt={2}
                        onClick={() => handleSubmit(values, resetForm, true, onResetSuccess)}
                    >
                        Reset Password
                    </ActionButton>
                    <ActionButton
                        // type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        submitting={isGettingPassword}
                        disabled={isResetting || (!values.sendAsEmail && !values.sendAsText)}
                        mt={2}
                        p={2}
                        onClick={() => handleSubmit(values, resetForm, false, onPasswordSuccess)}
                    >
                        Get One-Time Password
                    </ActionButton>
                    {error && <Alert mt={6} severity="error">
                        {error}
                    </Alert>}
                    {additionalComponents}
                </form>
            )}
        </Formik>
    );
}
export default GetLoginTokenForm;