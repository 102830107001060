import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { withTheme } from "styled-components/macro";

import {
    Button,
    Box,

} from "@material-ui/core";

// svxvy import
import * as SecurityContants from '../../../constants/SecurityConstants';
import { replaceDisplayName } from '../../../helpers/navigationFunctions';
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import LeftRightComponent from '../../../components/companyPublic/LeftRightComponent';
import EditOfferingModal from '../../../components/companyPublic/EditOfferingModal';

// constants

const OfferingsPage = (props) => {
    const offerings = useSelector(state => state.company.offerings).sort(compareObjects('sortOrder'));
    const company = useSelector(state => state.company.company);

    const claims = useSelector(state => state.auth.claims);
    const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);
    const [editOfferingOpen, setEditOfferingOpen] = useState(false);
    const [offeringToEdit, setOfferingToEdit] = useState(null);

    let location = useLocation();
    const pageName = replaceDisplayName(location.pathname.split('/').slice(-1)[0], company);

    return (
        <React.Fragment>
            <Helmet title={pageName} />
            {isOwner &&
                <Box style={{ margin: ' 0 auto 30px' }}>
                    <Button variant="contained" color="primary" onClick={() => { setOfferingToEdit(null); setEditOfferingOpen(true) }}>Add Offering</Button>
                    <EditOfferingModal open={editOfferingOpen} setOpen={setEditOfferingOpen} offering={offeringToEdit} />
                </Box>
            }

            {offerings.map((offering, index) => {
                const right = index % 2 === 1 ? true : false;
                return (
                    <LeftRightComponent
                        key={offering.id}
                        // id={offering.id}
                        title={offering.title}
                        description={offering.description}
                        imageUrl={offering.photo ? offering.photo.url : null}
                        ctaText={offering.ctaText}
                        // ctaLink={baseURl + '/' + NavigationContants.EMPLOYEES}
                        // ctaLink={baseURl + '/' + webpage.pageId}
                        ctaLink={offering.ctaLink}
                        ctaOpenInNewWindow={offering.ctaOpenInNewWindow}
                        right={right}
                        showEditButton={isOwner}
                        editAction={() => { setOfferingToEdit(offering); setEditOfferingOpen(true) }}
                    />

                );
            })}
        </React.Fragment>
    );
}

export default withTheme(OfferingsPage);
