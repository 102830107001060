import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

// UI Imports

import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button as MuiButton,
  Card,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoAddCircle as AddCircle
} from "react-icons/io5";

// svxvy imports
import * as NavigationConstants from '../../../constants/NavigationConstants';
import ProfileCard from '../../../components/company/profile/ProfileCard';
import ProfileEditCard from '../../../components/company/profile/ProfileEditCard';
// UI Consts

const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ProfileList = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
`;


const ProfileHeader = styled(Box)`
  position: relative;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 8px;
    display: flex;
    justifyContent: space-between;
    align-items: center;
  }
`;

const ProfileHeading = styled(Box)`
  ${(props) => props.theme.breakpoints.up("sm")} {
    display: flex;
    flex: 1;
  }
`;

const ProfileInteract = styled(Box)`
  margin: 19px 0 10px 0;

  .MuiTextField-root {
    margin: 0 !important;
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin: 0;
    display: flex;
    flex: 1;
    flexDirection: column-reverse;
    align-items: center;
    justify-content: right;
  }
`;

const AddMember = styled(Button)`
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin: 0 0 0 20px;
    padding: 8px 45px 8px 45px;
    white-space: nowrap;
    position: relative;
    top: auto;
    right: auto;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 8px 25px 8px 25px;
  }
`;


const MyFamily = props => {
  const theme = useTheme();
  const currentUser = useSelector(state => state.auth.person);

  const [addingNewPerson, setAddingNewPerson] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="My Family" />
      <React.Fragment>
        <Grid container style={{ paddingTop: '5px' }}>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <ProfileHeader>
              <ProfileHeading>
                <Typography variant="h2" component="h2" style={{ lineHeight: '40px' }}>My Family</Typography>
              </ProfileHeading>
              <ProfileInteract>
                <AddMember startIcon={<AddCircle color={theme.palette.primary.contrastText} />} variant="contained" color="primary" onClick={() => setAddingNewPerson(true)} mt={2}>Add Child Account</AddMember>
              </ProfileInteract>
            </ProfileHeader>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: '5px' }}>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            {currentUser.childProfiles && currentUser.childProfiles.length > 0 &&
              <ProfileList>
                {currentUser.childProfiles.map((person, index) => {
                  return (
                    <ProfileCard key={person.id} person={person} urlBase={NavigationConstants.CUSTOMERS} />
                  )
                })}
              </ProfileList>
            }
          </Grid>
        </Grid>
      </React.Fragment>
      {
        addingNewPerson &&
        <ProfileEditCard
          open={addingNewPerson}
          setOpen={setAddingNewPerson}
          parentAccountId={currentUser.id}
        />
      }
    </React.Fragment >
  )
}

export default withTheme(MyFamily);