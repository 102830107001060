import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import i18n from 'i18n-js';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    Button as MuiButton,
    Card,
    CardContent,
    CircularProgress as MuiCircularProgress,
    Grid,
    TextField as MuiTextField,
    Typography as MuiTypography
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports
import SmartCoreProductCard from "./SmartCoreProductCard";
import { fetchProducts } from "../../../store/actions/productActions";
import { cancelSvxvySubscription, createSvxvySubscription, finalizeSvxvySubscription } from "../../../store/actions/companyActions";
import { fetchPurchasesForCurrentUser } from "../../../store/actions/paymentActions";
import { deleteById } from "../../../helpers/arrayFunctions";
import { SVXVY_TRIAL_SUBSCRIPTION_ID } from "../../../models/Product";
import { compareObjects } from '../../../helpers/helperFunctions';
import ActionButton from "../../framework/ActionButton";

// constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const SelectSvxvySubscription = (props) => {

    // const defaultPaymentMethod = useSelector(state => state.auth.paymentMethods).find(x => x.isDefault && !x.companyId)
    const userId = useSelector(state => state.auth.userId);

    const loggedInUser = useSelector(state => state.auth.person);
    const userFromStore = useSelector(state => state.company.people).find(x => x.id === userId);
    const displayedUser = userId === loggedInUser.id ? loggedInUser : userFromStore;
    const defaultPaymentMethod = displayedUser && displayedUser.paymentMethods ? displayedUser.paymentMethods.find(x => x.companyId === null && x.isDefault) : null;

    const products = useSelector(state => state.products.smartcoreProducts).filter(x => x.isActive).sort(compareObjects('unitAmount', 'asc'));;
    let productsToDisplay = props.showSvxvyTrial ? products : deleteById(SVXVY_TRIAL_SUBSCRIPTION_ID, products);
    const currentSubscription = useSelector(state => state.company.subscription);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPurchaseSubmitting, setIsPurchaseSubmitting] = useState(false);
    const [isSubmissionSuccesful, setIsSubmissionSuccesful] = useState(false);
    const [error, setError] = useState(null);
    const [couponCode, setCouponCode] = useState("");

    const [subscriptionToBuy, setSubscriptionToBuy] = useState(null);


    const dispatch = useDispatch();

    const loadSvxvyProducts = useCallback(async () => {
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(fetchProducts());
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }, [dispatch]);

    const createSubscription = useCallback(async (paymentMethodId, updatedProduct, coupon, companyId) => {
        setError(null);
        setIsSubmitting(true);
        try {
            const subscription = await dispatch(createSvxvySubscription(paymentMethodId, updatedProduct.id, coupon));
            setSubscriptionToBuy(subscription);
            setIsSubmitting(false);
        } catch (err) {
            setError(err.message);
            setIsSubmitting(false);
        }
    }, [dispatch, setSubscriptionToBuy]);

    const purchaseSubscription = useCallback(async (paymentMethodId, subscriptionId, companyId) => {
        setError(null);
        setIsPurchaseSubmitting(true);
        try {
            await dispatch(finalizeSvxvySubscription(paymentMethodId, subscriptionId));
            setSubscriptionToBuy(null);
            setIsPurchaseSubmitting(false);
            setIsSubmissionSuccesful(true);
            await dispatch(fetchPurchasesForCurrentUser(companyId));
        } catch (err) {
            setError(err.message);
            setIsPurchaseSubmitting(false);
        }
    }, [dispatch, setSubscriptionToBuy]);

    const cancelDraftSubscription = useCallback(async (subscriptionId) => {
        try {
            await dispatch(cancelSvxvySubscription(null, subscriptionId));
        } catch (err) {
        }
    }, [dispatch]);

    useEffect(() => {
        loadSvxvyProducts();
    }, [loadSvxvyProducts]);

    const handledSelectedProduct = (updatedProduct) => {
        createSubscription(defaultPaymentMethod.id, updatedProduct, couponCode, loggedInUser.companyId);
    };

    const handledPurchase = () => {
        purchaseSubscription(defaultPaymentMethod.id, subscriptionToBuy.subscriptionId, loggedInUser.companyId);
    };

    const handleBack = () => {
        cancelDraftSubscription(subscriptionToBuy.subscriptionId)
        setIsSubmitting(false);
        setSubscriptionToBuy(null);
    }

    return (
        <React.Fragment>
            {isLoading && <CircularProgress color="secondary" />}
            {!isLoading && !isSubmitting && !isSubmissionSuccesful && !subscriptionToBuy &&
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    {products &&
                        <Grid container spacing={2}>
                            {defaultPaymentMethod && <Grid item xs={12}>
                                <TextField id="coupon" fullWidth label="Discount Code" type="text" variant="outlined" value={couponCode} onChange={e => setCouponCode(e.target.value)} />
                            </Grid>}
                            {error && <Grid item xs={12}><Alert mb={4} variant="outlined" severity="error">{error}</Alert></Grid>}
                            {defaultPaymentMethod && productsToDisplay.map((product, index) => {
                                return (
                                    <Grid item key={product.id} xs={12} sm={6} >
                                        <SmartCoreProductCard
                                            product={product}
                                            coupon={couponCode}
                                            isSelected={(product.id === currentSubscription.purchasedProduct.id && currentSubscription.expirationDate >= Date.now())}
                                            allowSelection={defaultPaymentMethod && !isSubmitting}
                                            selectedCallback={() => handledSelectedProduct(product)} />
                                    </Grid>
                                )
                            })}
                            {!defaultPaymentMethod && <Grid item xs={12}>
                                <Alert severity="error">You need to add a credit card to your profile to sign-up.</Alert>
                            </Grid>}
                        </Grid>
                    }
                </Grid>}
            {subscriptionToBuy &&
                <Box>
                    <Card>
                        <CardContent>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
                                <Typography>{subscriptionToBuy.purchasedProduct.name}</Typography>
                                <Typography>{subscriptionToBuy.quantity} x {i18n.toCurrency(subscriptionToBuy.purchasedProduct.unitAmount * subscriptionToBuy.quantity, { precision: 2, unit: '$' })}</Typography>
                            </Box>
                            {subscriptionToBuy.totalDiscount > 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
                                    <Typography>Discount</Typography>
                                    <Typography> - {i18n.toCurrency(subscriptionToBuy.totalDiscount, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                            }
                            {subscriptionToBuy.tax > 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
                                    <Typography>Sales Tax</Typography>
                                    <Typography>{i18n.toCurrency(subscriptionToBuy.tax, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                            }

                            {subscriptionToBuy.tax > 0 &&
                                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
                                    <Typography>Amount Due</Typography>
                                    <Typography>{i18n.toCurrency(subscriptionToBuy.nextAmountDue, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                            }
                            <Box display="flex" flexDirection="row" justifyContent="space-between" mb={4}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={handleBack}>
                                    Back
                                </Button>
                                <ActionButton
                                    variant="contained"
                                    color="primary"
                                    submitting={isPurchaseSubmitting}
                                    onClick={handledPurchase}>
                                    Purchase
                                </ActionButton>
                            </Box>
                            {error && <Box><Alert mb={4} variant="outlined" severity="error">{error}</Alert></Box>}

                        </CardContent>
                    </Card>

                    <Box display="flex" flexDirection="column" my={2} px={2}>
                        <Typography variant="body" mb={2}>If you are upgrading your subscription the first payment, due today will be prorated based on the amount of time remaining in your subscription. Subsequent payments will be what is shown here.</Typography>
                        <Typography variant="body">If you are downgrading your subscription this will be billed when your current subscription ends.</Typography>
                    </Box>
                </Box>
            }
            {isSubmitting && !isSubmissionSuccesful &&
                <Grid container direction="column">
                    <Box display="flex" justifyContent="center">
                        <CircularProgress my={2} color="primary" />
                    </Box>
                    {/* <Typography variant="h5">Please wait while your subscription is updated.</Typography> */}
                </Grid>
            }
            {isSubmissionSuccesful &&
                <Grid container direction="column">
                    <Typography variant="h5" gutterBottom>You new subscription is: {currentSubscription.description ? currentSubscription.description : currentSubscription.purchasedProduct.name}</Typography>
                    {!currentSubscription.description && <Typography gutterBottom>Starts: {moment(currentSubscription.startDate).format('ll')}</Typography>}
                    {!currentSubscription.description && <Typography gutterBottom>Renews: {moment(currentSubscription.expirationDate).format('ll')}</Typography>}
                    <Button type="submit" variant="contained" color="primary" mt={3} onClick={() => props.closeAction(false)}>Close</Button>
                </Grid>
            }
        </React.Fragment>
    );
}

export default SelectSvxvySubscription;