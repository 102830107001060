import { combineReducers } from "redux";

import themeReducer from "./themeReducer";

import companyReducer from './companyReducer'
import activityReducer from './activityReducer';
import authReducer from './authReducer';
import permissionsReducer from './permissionsReducer';
import messagingReducer from './messagingReducer';
import registrationReducer from './registrationReducer';
import productsReducer from './productsReducer';

export const rootReducer = combineReducers({
  themeReducer,
  company: companyReducer,
  activity: activityReducer,
  auth: authReducer,
  permissions: permissionsReducer,
  message: messagingReducer,
  registration: registrationReducer,
  products: productsReducer
});
