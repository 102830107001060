import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import update from 'immutability-helper'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { withTheme } from "styled-components/macro";

import {
    Button,
    Box,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import * as SecurityContants from '../../../constants/SecurityConstants';
import { deleteById } from '../../../helpers/arrayFunctions';
import { checkClaim, compareObjects, GUID_Generator } from '../../../helpers/helperFunctions';
import { editFaq } from "../../../store/actions/faqActions";
import FaqCard from './FaqCard';

// constants
const Alert = styled(MuiAlert)(spacing);

const faqDefaultVaues = { question: 'Do I work here?', answer: 'yes', sortOrder: 0, isNew: true, isEditing: true }

const createNewFaq = (companId, sortOrder) => {
    const newFaq = cloneDeep(faqDefaultVaues);
    newFaq.id = GUID_Generator();
    newFaq.companyId = companId;
    newFaq.sortOrder = sortOrder;
    return newFaq;
}


const FaqList = (props) => {
    const company = useSelector(state => state.company.company);
    const companyFaqs = useSelector(state => state.company.faqs);
    const [faqs, setFaqs] = useState(companyFaqs ? companyFaqs.sort(compareObjects('sortOrder')) : [])

    const claims = useSelector(state => state.auth.claims);
    const isOwner = checkClaim(claims, SecurityContants.MANAGAE_ROLES);

    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleLocalDelete = (faq) => {
        const updatedFaqs = deleteById(faq.id, faqs);
        setFaqs(updatedFaqs)
    }

    const handleAddFaq = () => {
        const updatedFaqs = cloneDeep(faqs);
        updatedFaqs.push(createNewFaq(company.id, faqs.length))
        setFaqs(updatedFaqs)
    }

    const dispatch = useDispatch();
    const saveCallback = useCallback(async (values) => {
        try {
            setIsSaving(true);
            setError(null);
            if (values.isNew) {
                // This one hasn't been saved so ignore it.
                // await dispatch(createFaq(values.companyId, values));
            }
            else {
                await dispatch(editFaq(values.companyId, values.id, values));
            }
        } catch (error) {
            setError(error.message);
        }
        setIsSaving(false);
    }, [dispatch]);

    const handleDragUpdate = useCallback((dragIndex, hoverIndex, prevCards) => {
        const updatedFaqs = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            })
        for (const i in updatedFaqs)
        {
            updatedFaqs[i].sortOrder = parseInt(i);
            saveCallback(updatedFaqs[i])
        }
        return updatedFaqs;
    }, [saveCallback])

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        if (isOwner)
            setFaqs((prevCards) => handleDragUpdate(dragIndex, hoverIndex, prevCards),)
    }, [handleDragUpdate, isOwner])


    return (
        <Box display='flex' flexDirection='column' flex={1}>
            {isOwner &&
                <Box style={{ margin: ' 0 auto 30px' }}>
                    <Button variant="contained" color="primary" onClick={handleAddFaq}>Add FAQ</Button>
                </Box>
            }
            {isSaving && <Alert my={2} severity="info">
                Saving the reorder list.
            </Alert>}
            {error && <Alert my={2} severity="error">
                Error saving reorder - {error}
            </Alert>}
            <DndProvider backend={HTML5Backend} >
                {faqs.map((faq, index) => {
                    return (
                        <FaqCard
                            key={faq.id}
                            id={faq.id}
                            faq={faq}
                            index={index}
                            moveCard={moveCard}
                            allowEdit={isOwner}
                            initialEditState={faq.isEditing}
                            handleLocalDelete={handleLocalDelete}
                        // editAction={() => { setOfferingToEdit(offering); setEditOfferingOpen(true) }}
                        />

                    );
                })}
            </DndProvider>
        </Box>
    );
}

export default withTheme(FaqList);
