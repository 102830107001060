import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    TextField as MuiTextField,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

/*********************** svxvy import **************************/
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { login } from "../../../store/actions/authActions";
import { isSvxvySite } from '../../../constants/NetworkConstants';
import ActionButton from '../../framework/ActionButton';

/*********************** class Styles import **************************/

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const LoginForm = props => {
    const { company, onSuccess, setOpen, additionalComponents, email } = props
    const history = useHistory();
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={{
                firstNameText: "",
                email: email ? email : "",
                password: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .max(255)
                    .required("Required"),
                password: Yup.string().max(255).required("Required"),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                try {
                    setError(null);
                    setIsSubmitting(true);
                    await dispatch(
                        login(values.email, values.password, company ? company.id : null)
                    );
                    resetForm();
                    if (onSuccess)
                        onSuccess()
                    else if (setOpen)
                        setOpen(false);
                    else {
                        const startOfUrl = isSvxvySite() ? "/" + company.sanitizedName + '/' : ''
                        history.push(startOfUrl + NavigationConstants.DASHBOARD);
                    }

                } catch (error) {
                    const message = error.message || "Something went wrong";

                    setError(message);
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setIsSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm,
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <TextField
                        type="email"
                        name="email"
                        variant="outlined"
                        label="Email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    <TextField
                        type="password"
                        name="password"
                        variant="outlined"
                        label="Password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="dense"
                    />
                    {additionalComponents}
                    <ActionButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        submitting={isSubmitting}
                    >
                        Sign in
                    </ActionButton>
                    {error && <Alert mb={3} severity="error" >
                        {error}
                    </Alert>}
                </form>
            )}
        </Formik>
    );
}

export default LoginForm;