import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { withTheme } from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
// https://react-icons.github.io/react-icons/icons?name=io5
import {
  IoAddCircle as AddIcon
} from "react-icons/io5";

// svxvy imports
import SubscriptionList from "../../../components/company/purchases/SubscriptionList";
import PurchaseProductModal from "../../../components/company/products/PurchaseProductModal";

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

const HeadingWrapper = styled(Box)`
  margin-bottom: 30px;
  padding-top: 12px;
  display: block;

  ${(props) => props.theme.breakpoints.up("sm")} {
    margin-bottom: 19px;
    padding-top: 49px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 12px;
  }
`;

const MySubscriptions = (props) => {

  const company = useSelector((state) => state.company.company);
  const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey)
  const currentUser = useSelector((state) => state.auth.person);
  const { search } = useLocation();
  const [showPurchaseSubscription, setShowPurchaseSubscription] = useState((new URLSearchParams(search).get('showAddOpen')) ? true : false);



  return (
    <Grid container>
      <Grid item xs={false} sm={1}></Grid>
      <Grid item xs={12} sm={10}>
        <Helmet title={company.isRABSite ? "Purchases" : "Memberships"} />
        <HeadingWrapper display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h1" component="h1">{company.isRABSite ? "Purchases" : "Memberships / Packages"}</Typography>
          {company && company.paymentServicePublicKey &&
            <Button
              variant='contained'
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowPurchaseSubscription(true)}
            >Make Purchase</Button>
          }
        </HeadingWrapper>
        {company && (
          <Grid container spacing={6}>
            {!paymentServicePublicKey && <Grid item xs={12}><Alert mx={2} severity="error">Payment service is not configured.</Alert></Grid>}
            <Grid item xs={12}>
              <SubscriptionList companyId={company.id} displayedUser={currentUser} />
            </Grid>
          </Grid>
        )}
        {!company && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <p>
                Hmm.... we couldn't find this company do you want to register the
                company with svxvy?
              </p>
            </Grid>
          </Grid>
        )}
        <PurchaseProductModal
          open={showPurchaseSubscription}
          setOpen={setShowPurchaseSubscription}
          displayedUser={currentUser}
          isChildAccount={false}
        />
      </Grid>
    </Grid>
  );
};

export default withTheme(MySubscriptions);
