import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { useTheme } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Button,
    Typography as MuiTypography,
    Box,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoVideocamOutline as VideoIcon,
    IoRefreshCircleOutline as RestoreIcon,
    IoAlertCircleSharp as CancelIcon,
    IoTime as ClockIcon,
    IoCheckmarkCircleOutline as CheckIcon,
    IoPeople as FullIcon,
    IoAlarmOutline as FinishedIcon,
    IoPencilOutline as PencilIcon,
    IoChatboxEllipses as ChatIcon,
    IoCloseCircleOutline as CancelMenuIcon,
    IoDuplicateOutline as CopyIcon,
    IoCheckmarkCircleOutline as ConfirmIcon,
    IoClose as RejectIcon,
    IoMailOutline as ResendConfirmationIcon,
    IoTrash as DeleteIcon,
} from "react-icons/io5";

// svxvy imports

import * as SecurityConstants from '../../../constants/SecurityConstants';
import { enrollInActivity, cancelActivity, confirmActivity, resendConfirmation, deleteActivity } from '../../../store/actions/activityActions';
import { checkClaim, getMilliseconds, compareObjects } from '../../../helpers/helperFunctions';
import ActionButton from '../../framework/ActionButton';
import ActivityDetail from "./ActivityDetail";
import MessageModal from '../messages/MessageModal';
import StyledDialog from '../../framework/StyledDialog';
import ActivityAddEditModal from './ActivityAddEditModal';
import ActivityJoinModal from './ActivityJoinModal';
import LoginDialog from '../userManagement/LoginDialog';
import DeclineDialog from './DeclineDialog';
import FileUploader from '../../framework/FileUploader';

// UI Consts
const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

const UtilityButtonWrapper = styled(Box)`
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding-bottom: 0;
        flex-direction: row;
        align-items: center;
    }
`;

const UtilityButton = styled(ActionButton)`
    height: 22px;
    background-color: transparent !important;
    border-radius: 0;
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 14px;

    .MuiButton-startIcon {
        margin-right: 5px;
    }

    ${(props) => props.theme.breakpoints.up("md")} {
        margin: 0 26px 0 0;
        padding: 0 2px;
    
        &:last-child {
            margin: 0;
        }
    }
`;

const ICON_SIZE = 24;
const EDIT_CLOSE = 43200000*2;

// for some reason I can't do this inline or react blows up.
const stupidFunction = (activity, loggedInUser) => {
    if (!activity || !loggedInUser)
        return false

    return activity.isFamilyMemberInInstance(activity.people.filter(x => loggedInUser.childProfiles.some(y => y.id === x.appUserId)), activity);
}

const ActivityDialog = props => {
    const { activityId, setOpen, open, companyId, viewStartDate, viewEndDate, editMode } = props;

    const activity = useSelector(state => state.activity.activities).find(x => x.id === activityId);
    const company = useSelector(state => state.company.company);
    const theme = useTheme();

    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const CUSTOMER_TEXT = useSelector(state => state.company.customerText);
    const CUSTOMERS_TEXT = useSelector(state => state.company.customersText);

    const loggedInUser = useSelector(state => state.auth.person);
    const userClaims = useSelector(state => state.auth.claims);
    const allowAddEdit = checkClaim(userClaims, SecurityConstants.ALLOW_ADD_EDIT_ACTIVITIES);
    // const seeAllPeople = checkClaim(userClaims, SecurityConstants.VIEW_ALL_PEOPLE);

    const activityCanceled = !activity ? false :
        activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status === 'canceled'
        || (activity.statuses.find(x => x.occuranceId === 0)?.status === 'canceled' && activity.statuses.find(x => x.occuranceId.toString() === activity.occuranceId)?.status !== 'active');

    const durationInMilliSeconds = !activity ? 0 : getMilliseconds(activity.duration, 'minutes');
    const occuranceIsInPast = !activity
        ? true
        : activity.occuranceId !== "0"
            ? Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds) : Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds);
    const editClosed = !activity
        ? true
        : Date.now() >= (activity.displaybleStartDateTime.getTime() + durationInMilliSeconds + EDIT_CLOSE);

    const instructors = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const peopleRunningActivity = useSelector(state => state.company.people).filter(p => instructors.find(a => a.appUserId === p.id));
    const isRunningActivity = loggedInUser ? peopleRunningActivity.some(x => x.id === loggedInUser.id) : false;

    const attendees = !activity ? [] : activity.people.filter(a => !a.userIsDeleted && !a.isRunningActivity && activity.isInActivityInstance(a, activity));
    const peopleSignedUpforActivity = useSelector(state => state.company.people).filter(p => attendees.find(a => a.appUserId === p.id)).sort(compareObjects('displayName', 'asc'));

    const whenCanDrop = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfDropStartValue, activity.selfDropStartUnit));
    const whenCanRegister = !activity ? new Date() : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterStartValue, activity.selfRegisterStartUnit));
    const whenRegistrationCloses = !activity || activity.selfRegisterEndValue === null ? null : new Date(activity.displaybleStartDateTime.getTime() - getMilliseconds(activity.selfRegisterEndValue, activity.selfRegisterEndUnit));

    const haveAttendeeObject = !activity || !loggedInUser ? null : activity.people.find(x => x.appUserId === loggedInUser.id);
    const currentUserInClass = haveAttendeeObject && !haveAttendeeObject.isRunningActivity ? activity.isInActivityInstance(haveAttendeeObject, activity) : false;
    const aFamilySignedUp = stupidFunction(activity, loggedInUser);

    const isRecurring = activity && activity.rrule !== null && activity.rrule.trim().length > 0 ? true : false;

    const [isJoining, setIsJoining] = useState(false);
    const [isMenuActionRunning, setIsMenuActionRunning] = useState(false);
    const [error, setError] = useState(null);

    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
    const [editAllInstances, setEditAllInstances] = useState(false);
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [isCreateCopy, setIsCreateCopy] = useState(false);

    const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
    const [activityJoinModalOpen, setActivityJoinModalOpen] = useState(false);

    const dispatch = useDispatch();
    const joinHandler = useCallback(async (id, whenCanDropLocal, loggedInUserLocal, company) => {
        setError(null);
        try {
            if (new Date() > whenCanDropLocal && !allowAddEdit && !company.hideDroppedActivityMessage) {
                const r = window.confirm(company.dropActivityWarningMessage
                    ? company.dropActivityWarningMessage :
                    'Be advised, the time when this ' + ACTIVITY_TEXT.toLowerCase() + ' can be dropped has passed. Do you still want to register?');
                if (!r) {
                    return;
                }
            }
            setIsJoining(true);
            await dispatch(enrollInActivity(companyId, id, loggedInUserLocal.id, new Date(viewStartDate), new Date(viewEndDate), true, false));

        } catch (err) {
            setError(err.message);
        }
        setIsJoining(false);
    }, [dispatch, companyId, viewStartDate, viewEndDate, allowAddEdit, ACTIVITY_TEXT]);

    const handleJoin = () => {

        if (((loggedInUser.needPaymentMethodForActivity(activity, company) || loggedInUser.requirePrepayment(activity, company)) && !allowAddEdit)
            || (loggedInUser.childProfiles && loggedInUser.childProfiles.length > 0))
            setActivityJoinModalOpen(true);
        else if (!currentUserInClass)
            joinHandler(activity.id, whenCanDrop, loggedInUser, company)
    }

    const cancelHandler = useCallback(async (id, status) => {
        if (status === 'canceled') {
            const r = window.confirm("Are you sure you want to cancel this " + ACTIVITY_TEXT + "?");
            if (!r) {
                return;
            }
        }

        try {
            setError(null)
            setIsMenuActionRunning(true);
            await dispatch(cancelActivity(companyId, id, status, viewStartDate, viewEndDate));
        } catch (err) {
            setError(err.message);
        }
        setIsMenuActionRunning(false);
    }, [dispatch, companyId, ACTIVITY_TEXT, viewStartDate, viewEndDate])

    const confirmActivityCallback = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
        try {
            setError(null);
            setIsMenuActionRunning(true);
            await dispatch(confirmActivity(activityId, companyId, viewStartDate, viewEndDate, true));
        } catch (err) {
            setError(err.message);
        }
        setIsMenuActionRunning(false);
    }, [dispatch]);

    const handleConfirm = () => {
        confirmActivityCallback(activity.id, company.id, viewStartDate, viewEndDate);
    }

    const resendConfirmationHandler = useCallback(async (id, companyId,) => {
        try {
            setError(null)
            setIsMenuActionRunning(true);

            await dispatch(resendConfirmation(id, companyId));
        } catch (err) {
            setError(err.message);
        }
        setIsMenuActionRunning(false);
    }, [dispatch])

    const deleteActivityHandler = useCallback(async (activityId, companyId, viewStartDate, viewEndDate) => {
        try {
            const additionalMessage = '\n\nEnrolled ' + CUSTOMERS_TEXT.toLowerCase() + ' are not notified when you delete a ' + ACTIVITY_TEXT.toLowerCase() + '.  To notify them cancel the ' + ACTIVITY_TEXT.toLowerCase() + ' first.'
            const r = window.confirm("Are you sure you want to delete this " + ACTIVITY_TEXT + "?" + additionalMessage );
            if (!r) {
                return;
            }
            setError(null)
            setIsMenuActionRunning(true);
            const activityIdToSend = activityId.indexOf('_') >= 0 ? activityId.split('_')[0] : activityId;
            const occuranceId = activityId.indexOf('_') >= 0 ? activityId.split('_')[1] : 0;
            await dispatch(deleteActivity(activityIdToSend, occuranceId, companyId, viewStartDate, viewEndDate));
        } catch (err) {
            setError(err.message);
        }
        setIsMenuActionRunning(false);
    }, [dispatch, ACTIVITY_TEXT, CUSTOMERS_TEXT]);

    return (
        <React.Fragment>
            {activity && !editMode &&
                <StyledDialog
                    open={open}
                    setOpen={setOpen}
                    aria-labelledby="act-dialog-title"
                    actions={(allowAddEdit || isRunningActivity || (activity.requestConfirmation && !occuranceIsInPast && (!(activity.confirmed || activity.rejected) || allowAddEdit))) &&
                        <React.Fragment>
                            <UtilityButtonWrapper>
                                {!activityCanceled && !occuranceIsInPast && activity.meetingUrl &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<VideoIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        component='a'
                                        href={activity.meetingUrl}
                                        target='_blank'
                                    >
                                        Start Video
                                    </UtilityButton>
                                }
                                {!activityCanceled && !occuranceIsInPast && allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        disabled={isMenuActionRunning}
                                        onClick={() => { setEditAllInstances(true); setIsCreateCopy(false); setIsEditingModalOpen(true); }}>
                                        Edit {isRecurring ? 'Series' : ACTIVITY_TEXT}
                                    </UtilityButton>
                                }
                                {!activityCanceled && !occuranceIsInPast && isRecurring && allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<PencilIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                        disabled={isMenuActionRunning}
                                        onClick={() => { setEditAllInstances(false); setIsCreateCopy(false); setIsEditingModalOpen(true) }}>
                                        Edit this {ACTIVITY_TEXT}
                                    </UtilityButton>
                                }
                                {allowAddEdit && <UtilityButton
                                    variant="text"
                                    startIcon={<ChatIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                    disabled={isMenuActionRunning}
                                    onClick={() => setMessageModalOpen(true)}>
                                    Message
                                </UtilityButton>}
                                {allowAddEdit && !occuranceIsInPast &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={activityCanceled
                                            ? <RestoreIcon
                                                size={ICON_SIZE}
                                                style={{ color: theme.palette.success.main }} />
                                            :
                                            <CancelMenuIcon
                                                size={ICON_SIZE}
                                                style={{ color: theme.palette.error.main }}

                                            />}
                                        onClick={() => cancelHandler(activity.id, activityCanceled ? 'active' : 'canceled')}
                                        submitting={isMenuActionRunning}>
                                        {activityCanceled ? 'Reschedule' : 'Cancel'} {ACTIVITY_TEXT}
                                    </UtilityButton>}
                                {/* {!activity.requestConfirmation && allowAddEdit && <UtilityButton
                                    variant="text"
                                    startIcon={<CopyIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                    disabled={isMenuActionRunning}
                                    onClick={() => { setEditAllInstances(false); setIsCreateCopy(true); setIsEditingModalOpen(true); }}>
                                    Copy {isRecurring ? 'Series' : ACTIVITY_TEXT}
                                </UtilityButton>
                                } */}
                                {activity.requestConfirmation && !occuranceIsInPast && !activity.rejected && !activity.confirmed && !allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<ConfirmIcon size={ICON_SIZE} style={{ color: theme.palette.success.main }} />}
                                        disabled={isMenuActionRunning}
                                        onClick={handleConfirm}>
                                        Confirm
                                    </UtilityButton>
                                }
                                {activity.requestConfirmation && !occuranceIsInPast && !activity.rejected && !activity.confirmed && !allowAddEdit &&
                                    <UtilityButton
                                        variant="text"
                                        startIcon={<RejectIcon size={ICON_SIZE} style={{ color: theme.palette.error.main }} />}
                                        disabled={isMenuActionRunning}
                                        onClick={() => setDeclineDialogOpen(true)}>
                                        Decline
                                    </UtilityButton>
                                }
                            </UtilityButtonWrapper>
                            {!allowAddEdit && <Box />}
                        </React.Fragment>
                    }
                    moreActions={
                        !occuranceIsInPast && allowAddEdit && <React.Fragment>
                            <UtilityButton
                                variant="text"
                                startIcon={<CopyIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                disabled={isMenuActionRunning}
                                onClick={() => { setEditAllInstances(false); setIsCreateCopy(true); setIsEditingModalOpen(true); }}>
                                Copy {isRecurring ? 'Series' : ACTIVITY_TEXT}
                            </UtilityButton>
                            <UtilityButton
                                variant="text"
                                startIcon={
                                    <DeleteIcon
                                        size={ICON_SIZE}
                                        style={{ color: theme.palette.error.main }}

                                    />}
                                onClick={() => deleteActivityHandler(activity.id, company.id, viewStartDate, viewEndDate)}
                                submitting={isMenuActionRunning}>
                                Delete {ACTIVITY_TEXT}
                            </UtilityButton>
                            {!activity.confirmed &&
                                <UtilityButton
                                    variant="text"
                                    startIcon={<ResendConfirmationIcon size={ICON_SIZE} style={{ color: theme.palette.secondary.main }} />}
                                    disabled={isMenuActionRunning}
                                    onClick={() => resendConfirmationHandler(activity.id, company.id)}>
                                    Resend Confirmation
                                </UtilityButton>
                            }
                            {!activity.confirmed &&
                                <UtilityButton
                                    variant="text"
                                    startIcon={<ConfirmIcon size={ICON_SIZE} style={{ color: theme.palette.success.main }} />}
                                    disabled={isMenuActionRunning}
                                    onClick={handleConfirm}>
                                    Confirm
                                </UtilityButton>
                            }
                            {!activity.rejected &&
                                <UtilityButton
                                    variant="text"
                                    startIcon={<RejectIcon size={ICON_SIZE} style={{ color: theme.palette.error.main }} />}
                                    disabled={isMenuActionRunning}
                                    onClick={() => setDeclineDialogOpen(true)}>
                                    Decline
                                </UtilityButton>
                            }
                        </React.Fragment>
                    }
                    primaryAction={
                        <React.Fragment>
                            {!loggedInUser && activity.allowSelfRegister &&
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setLoginDialogOpen(true)}
                                // component={RouterLink} to={baseURL + '/' + NavigationContants.LOGIN}
                                >
                                    Login / Register to Sign-Up for {ACTIVITY_TEXT}
                                </Button>
                            }
                            {loggedInUser &&
                                <React.Fragment>
                                    {activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText }}
                                            startIcon={<CancelIcon size={ICON_SIZE} />}
                                        >
                                            Canceled
                                        </Button>
                                    }
                                    {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && activity.allowSelfRegister && !occuranceIsInPast && whenCanRegister > Date.now() &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<ClockIcon size={ICON_SIZE} />}
                                        >
                                            Registration opens {moment(whenCanRegister).format('MM/DD - LT')}
                                        </Button>
                                    }
                                    {!activityCanceled && (activity.totalSpaces - attendees.length) >= 1 && !activity.canSelfRegister(activity, loggedInUser, attendees) && !currentUserInClass && activity.allowSelfRegister && Date.now() >= whenCanRegister && !occuranceIsInPast && (!whenRegistrationCloses || whenRegistrationCloses > Date.now()) &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText }}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                        >
                                            Registration open
                                            <Typography variant="h5" ml={2}>{activity.totalSpaces - attendees.length} spot{activity.totalSpaces - attendees.length === 1 ? '' : 's'} remaining</Typography>
                                        </Button>
                                    }
                                    {!activityCanceled && loggedInUser && activity.canSelfRegister(activity, loggedInUser, attendees) && !currentUserInClass && !aFamilySignedUp && !isRunningActivity && (!(whenRegistrationCloses != null) || whenRegistrationCloses > Date.now()) &&
                                        <ActionButton
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                            onClick={handleJoin}
                                            submitting={isJoining}
                                        >
                                            Register Now!
                                            <Typography variant="h5" ml={2}>{activity.totalSpaces - attendees.length} spot{activity.totalSpaces - attendees.length === 1 ? '' : 's'} remaining</Typography>
                                        </ActionButton>
                                    }
                                    {!activityCanceled && activity.allowSelfRegister && !occuranceIsInPast && whenRegistrationCloses != null && whenRegistrationCloses < Date.now() &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<ClockIcon size={ICON_SIZE} />}
                                        >
                                            Registration Closed
                                        </Button>
                                    }
                                    {(!activityCanceled && (currentUserInClass || aFamilySignedUp) && !isRunningActivity && !occuranceIsInPast) &&
                                        <ActionButton
                                            fullWidth
                                            // disabled={(loggedInUser && loggedInUser.childProfiles.length > 0) ? false : true}
                                            variant="contained"
                                            style={{ backgroundColor: activity.requestConfirmation && !activity.confirmed ? theme.palette.warning.main : theme.palette.success.main, color: theme.palette.success.contrastText }}
                                            onClick={handleJoin}
                                            startIcon={<CheckIcon size={ICON_SIZE} />}
                                        >
                                            {loggedInUser && loggedInUser.childProfiles.length > 0 ? 'You or a family member are signed up. Click to make changes.' : 'You\'re signed up.'}
                                        </ActionButton>
                                    }
                                    {(activity.totalSpaces - attendees.length) <= 0 && (isRunningActivity || allowAddEdit) && !occuranceIsInPast && !activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FullIcon size={ICON_SIZE} />}
                                        >
                                            {attendees.length} {attendees.length === 1 ? CUSTOMER_TEXT : CUSTOMERS_TEXT} in {ACTIVITY_TEXT}
                                        </Button>
                                    }
                                    {(activity.totalSpaces - attendees.length) <= 0 && activity.allowSelfRegister && !isRunningActivity && !currentUserInClass && !occuranceIsInPast && !activityCanceled &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FullIcon size={ICON_SIZE} />}
                                        >
                                            {ACTIVITY_TEXT} is full
                                        </Button>
                                    }
                                    {!activityCanceled && occuranceIsInPast &&
                                        <Button
                                            disabled
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: theme.palette.messageButton.main, color: theme.palette.messageButton.contrastText }}
                                            startIcon={<FinishedIcon size={ICON_SIZE} />}
                                        >
                                            {ACTIVITY_TEXT} has finished
                                        </Button>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                >
                    {activity && !editMode &&
                        <React.Fragment>
                            {error && <Alert my={2} severity="error">
                                {error}
                            </Alert>}
                            <ActivityDetail
                                activity={activity}
                                viewStartDate={viewStartDate}
                                viewEndDate={viewEndDate}
                                editClosed={editClosed}
                            />
                            {loggedInUser &&
                                <FileUploader activity={activity}
                                    viewStartDate={viewStartDate}
                                    viewEndDate={viewEndDate}
                                    hideUploadButton={!allowAddEdit}
                                    title={ACTIVITY_TEXT + " Files"}
                                />
                            }
                            {error && <Alert my={2} severity="error">
                                {error}
                            </Alert>}
                        </React.Fragment>
                    }
                </StyledDialog >
            }
            {
                loginDialogOpen &&
                <LoginDialog open={loginDialogOpen} setOpen={setLoginDialogOpen} />
            }
            {
                messageModalOpen &&
                <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} sendInAppInitialState toUsers={peopleSignedUpforActivity} />
            }
            {
                isEditingModalOpen && <ActivityAddEditModal
                    open={isEditingModalOpen}
                    setOpen={setIsEditingModalOpen}
                    activity={activity}
                    companyId={companyId}
                    editAllInstances={editAllInstances}
                    viewStartDate={viewStartDate}
                    viewEndDate={viewEndDate}
                    createCopy={isCreateCopy}
                />
            }
            {activityJoinModalOpen &&
                <ActivityJoinModal
                    activity={activity}
                    companyId={companyId}
                    // userToJoin={loggedInUser}
                    open={activityJoinModalOpen}
                    setOpen={setActivityJoinModalOpen}
                    viewStartDate={viewStartDate}
                    viewEndDate={viewEndDate} />
            }
            {declineDialogOpen &&
                <DeclineDialog
                    activity={activity}
                    open={declineDialogOpen}
                    setOpen={setDeclineDialogOpen}
                    viewStartDate={viewStartDate}
                    viewEndDate={viewEndDate}
                />

            }
        </React.Fragment >
    );

}

export default ActivityDialog;