
import React, { useState } from 'react'
import i18n from 'i18n-js';
import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Card as MuiCard,
    Hidden,
    List as MuiList,
    ListItem as MuiListItem,
    Typography as MuiTypography,
} from "@material-ui/core";

//svxvy import
import ProfileIcon from '../../profile/ProfileIcon';
import PaymentItemSmall from '../PaymentItemSmall';
import PurchaseListItem from '../PurchaseListItem';
import StyledDialog from '../../../framework/StyledDialog';
import ProfileDetailCard from '../../profile/ProfileDetailCard';

// UI Consts
const Card = styled(MuiCard)(spacing);
const List = styled(MuiList)(spacing);
const Typography = styled(MuiTypography)(spacing);

const ListItem = styled(MuiListItem)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 10px 0 10px 0;

    &:last-child {
        border: none;
    }
`;

const PurchaseListItemContainer = styled(MuiListItem)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 10px 10px 10px 10px;

    &:last-child {
        border: none;
    }
`;

const BillingPersonGroup = props => {
    const theme = useTheme();
    const { group, company, displayedUser } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <ListItem key={group.key}>
                {/* <Box display="flex" flex={1} flexDirection="column"> */}
                <Box
                    display="inline-block"
                    width='100%'
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsExpanded(prevValue => !prevValue)}>
                    <Box display="flex"
                        flex={1}
                        justifyContent='space-between'
                    >
                        <Box display="flex" flexDirection="row" alignItems="center" flex={.8} minWidth='0px'>
                            <Hidden xsDown>
                                <ProfileIcon person={group.person} />
                            </Hidden>
                            <Typography ml={isMobile ? 0 : 4} style={{ fontWeight: 700, fontSize: '1.6rem', textOverflow: 'ellipsis', overflow: 'hidden' }}>{group.displayName}</Typography>
                        </Box>
                        <Box display="flex" flexDirection={isMobile ? 'column-reverse' : 'row'} alignItems={isMobile ? 'flex-end' : 'center'}>
                            <Box display="flex" justifyContent="flex-end">
                                {group.maxLate > 0 && <Typography style={{ color: theme.palette.error.main }} mr={isMobile ? 0 : 8}>{group.maxLate} days late</Typography>}
                            </Box>
                            <Box minWidth={70} display="flex" justifyContent="flex-end">
                                <Typography variant="h4">{i18n.toCurrency(group.total, { precision: 2, unit: '$' })}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ListItem>
            <StyledDialog
                open={isExpanded}
                setOpen={setIsExpanded}
                aria-labelledby={'billing detail for ' + group.person}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                >
                    <Card mb={4}
                        style={{ cursor: "pointer" }}
                        onClick={() => setDetailDialogOpen(true)}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            flex={1}
                            justifyContent="space-between"
                            m={2}
                        >
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Hidden xsDown>
                                    <ProfileIcon person={group.person} />
                                </Hidden>
                                <Typography style={{ marginLeft: '16px', fontWeight: 700, fontSize: '1.6rem' }}>{group.displayName}</Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card>
                        {group.payments &&
                            <React.Fragment>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '15px 14px 17px 14px' }}>
                                    <Typography variant="h4">Total Due</Typography>
                                    <Typography variant="h4">{i18n.toCurrency(group.total, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                                <List px={2}>
                                    {group.payments.map((payment, index) => {
                                        return (
                                            <ListItem key={payment.id}>
                                                <PaymentItemSmall company={company} payment={payment} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </React.Fragment>
                        }
                        {isExpanded && group.purchases &&
                            <React.Fragment>
                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ borderBottom: '1px solid ' + theme.palette.divider, padding: '15px 14px 17px 14px' }}>
                                    <Typography variant="h4">Total Paid</Typography>
                                    <Typography variant="h4">{i18n.toCurrency(group.total, { precision: 2, unit: '$' })}</Typography>
                                </Box>
                                <List>
                                    {group.purchases.map((purchase, index) => {
                                        return (
                                            <PurchaseListItemContainer key={purchase.id}>
                                                <PurchaseListItem company={company} purchase={purchase} displayedUser={displayedUser} />
                                            </PurchaseListItemContainer>
                                        )
                                    })}
                                </List>
                            </React.Fragment>
                        }
                    </Card>
                </Box>
            </StyledDialog>
            {detailDialogOpen &&
                <ProfileDetailCard person={group.person} open={detailDialogOpen} setOpen={setDetailDialogOpen} isChildProfile={false} />
            }
        </React.Fragment>

    )
}

export default BillingPersonGroup;