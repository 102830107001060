// import {
//     Link,
// } from "@material-ui/core";

export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    // if (leaf.link) {
    //     children = <Link href={leaf.link.href}
    //         target={leaf.link.target ? leaf.link.target : '_self'}
    //         rel={leaf.link.target === '_blank' ? 'noreferrer' : 'next'}>
    //         {children}
    //     </Link>
    // }

    return <span {...attributes}>{children}</span>;
};
