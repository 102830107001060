import Purchase from "./Purchase";
import { getActiveSubscriptionObjects, getPriceForUser, isCardExpired } from '../helpers/commerceFunctions';

class Person {
    constructor(id, displayName, username, image, bio, email, phoneNumber, roles, isUserRegistered, isDeleted,
        isEmailConfirmed, isPhoneConfirmed, companyId, sanitizedDisplayName, isEmployee, isPublic,
        paymentMethods, purchases, additionalProfiles, birthday, address, customerNotes, employeeNotes, barcodeId,
        googleCalendarName, googleSyncAllActivities, childProfiles, parentAccountId,
        waiverSigned, waiverSignedOn, employeeSortOrder, memberSince,
        isInactive, daysSinceLastActivity, daysTillNextActivity, hasActiveMembership,
        dontSendOptionalMessages, referredBy,
        additionalEmailNotificaitons
    ) {
        this.id = id;
        this.username = username;
        this.displayName = displayName;
        this.image = image;
        this.bio = bio;
        this.roles = roles;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.isUserRegistered = isUserRegistered;
        this.isDeleted = isDeleted;
        this.companyId = companyId;
        this.sanitizedDisplayName = sanitizedDisplayName;
        this.isEmailConfirmed = isEmailConfirmed;
        this.isPhoneConfirmed = isPhoneConfirmed;
        this.isEmployee = isEmployee;
        this.isPublic = isPublic;
        this.paymentMethods = paymentMethods;
        this.additionalProfiles = additionalProfiles;
        this.birthday = birthday;
        this.address = address;
        this.customerNotes = customerNotes;
        this.employeeNotes = employeeNotes;
        this.barcodeId = barcodeId;
        this.googleCalendarName = googleCalendarName;
        this.googleSyncAllActivities = googleSyncAllActivities;
        this.purchases = getPurchaseData(purchases);
        this.parentAccountId = parentAccountId;
        this.childProfiles = childProfiles;
        this.waiverSigned = waiverSigned;
        this.waiverSignedOn = waiverSignedOn;
        this.employeeSortOrder = employeeSortOrder;
        this.memberSince = memberSince ? new Date(memberSince) : null;
        this.isInactive = isInactive;
        this.daysSinceLastActivity = daysSinceLastActivity;
        this.daysTillNextActivity = daysTillNextActivity;
        this.hasActiveMembership = hasActiveMembership;
        this.dontSendOptionalMessages = dontSendOptionalMessages;
        this.referredBy = referredBy;
        this.additionalEmailNotificaitons = additionalEmailNotificaitons;

        for (const i in childProfiles) {
            childProfiles[i].purchases = getPurchaseData(childProfiles[i].purchases);
        }
    }

    hasTenantPaymentMethod = (company) => {
        if (this.paymentMethods && this.paymentMethods.length > 0)
            return this.paymentMethods.some(x => x.companyId === company.id && !isCardExpired(x));
        else
            return false;
    }

    requirePrepayment = (activity, company) => {
        if (activity.requirePrepayment
            && !this.hasTenantPaymentMethod(company)
            && !activity.userHasValidPackageForActivity(this)
            && getPriceForUser(activity, getActiveSubscriptionObjects(this.purchases)) > 0)
            return true;
        else
            return false;
    }

    needPaymentMethodForActivity = (activity, company) => {
        if (activity.requireCreditCard
            && !this.hasTenantPaymentMethod(company)
            && !activity.userHasValidPackageForActivity(this)
            && getPriceForUser(activity, getActiveSubscriptionObjects(this.purchases)) > 0)
            return true;
        else
            return false;
    }

}

const getPurchaseData = (purchases) => {
    const purchasesData = []
    for (const pId in purchases) {
        const rawPurchase = purchases[pId];
        const purchase = new Purchase(rawPurchase.id,
            rawPurchase.purchasedProduct,
            rawPurchase.datePurchased,
            rawPurchase.startDate,
            rawPurchase.expirationDate,
            rawPurchase.automaticallyRenew,
            rawPurchase.quantity,
            rawPurchase.subscriptionId,
            rawPurchase.invoiceId,
            rawPurchase.paymentChargeId,
            rawPurchase.amountPaid,
            rawPurchase.isTentantPurchase,
            rawPurchase.description,
            rawPurchase.memo,
            rawPurchase.wasMarkedUnpaid,
            rawPurchase.wasRefunded,
            rawPurchase.purchasedProductId,
            rawPurchase.userPayment,
            rawPurchase.nextAmountDue,
            rawPurchase.invoiceUrl,
            rawPurchase.invoicePdf,
            rawPurchase.companyId,
            rawPurchase.appUserId,
            rawPurchase.units,
            rawPurchase.tax,
            rawPurchase.status,
            rawPurchase.totalDiscount
        );
        purchasesData.push(purchase);
    }

    return purchasesData;
}

export const DEFAULT_USER_IMAGE = 'https://res.cloudinary.com/svxvy/image/upload/v1614188671/default_user_icon_lkuamb.png';

export const getInitials = (name) => {
    return name.charAt(0).toUpperCase() + (name.split(' ').length > 1 ? name.split(' ')[1].charAt(0).toUpperCase() : '')
}

export const getProfileFromResponse = (resData) => {
    const personToAdd = new Person(resData.id, resData.displayName, resData.username, resData.image, resData.bio,
        resData.email, resData.phoneNumber, resData.roles, resData.isUserRegistered,
        resData.isDeleted, resData.emailConfirmed, resData.phoneNumberConfirmed, resData.companyId,
        resData.sanitizedDisplayName, resData.isEmployee, resData.isPublic,
        resData.paymentMethods, resData.purchases, resData.additionalProfiles,
        resData.birthday, resData.address, resData.customerNotes, resData.employeeNotes, resData.barcodeId,
        resData.googleCalendarName, resData.googleSyncAllActivities, resData.childProfiles, resData.parentAccountId,
        resData.waiverSigned, resData.waiverSignedOn, resData.employeeSortOrder, resData.memberSince,
        resData.isInactive, resData.daysSinceLastActivity, resData.daysTillNextActivity, resData.hasActiveMembership,
        resData.dontSendOptionalMessages, resData.referredBy,
        resData.additionalEmailNotificaitons
    );
    personToAdd.notifyOnNewAccount = resData.notifyOnNewAccount;
    return personToAdd;
}

export default Person;