import { cloneDeep } from 'lodash';

import { UNPAID_STATUS, PAID_OUTSIDE_APP } from '../models/Payment';


export const getActiveSubscriptionObjects = (purchases) => {
    const purchaseToReturn = new Map();
    for (var i = 0; purchases && i < purchases.length; i++) {
        const purchase = purchases[i];
        if (purchase.purchasedProduct && purchase.purchasedProduct.units > 0)
            purchaseToReturn.set(purchase.id, cloneDeep(purchase));
        if (!purchase.subscriptionId)
            continue;
        if (!purchaseToReturn.get(purchase.subscriptionId) || purchase.expirationDate > purchaseToReturn.get(purchase.subscriptionId).expirationDate)
            purchaseToReturn.set(purchase.subscriptionId, cloneDeep(purchase));
    }
    return Array.from(purchaseToReturn.values());
};

export const getPriceForUser = (activity, subscriptions) => {
    let price = activity.price;
    for (const sp in activity.subscriptionPrices) {
        const subscriptionPrice = activity.subscriptionPrices[sp];
        const hasSubscription = subscriptions.find(x => x.purchasedProductId === subscriptionPrice.productId);

        if (hasSubscription && hasSubscription.datePurchased <= activity.displaybleStartDateTime) {
            if (!subscriptionPrice.usePercent && price > subscriptionPrice.price)
                price = subscriptionPrice.price;
            else if (subscriptionPrice.usePercent && price > (price * ((100 - subscriptionPrice.discountPercent) / 100)))
                price = price * ((100 - subscriptionPrice.discountPercent) / 100);
        }
    }

    return price;
}

export const isUnpaidPayment = (item) => {
    return item.paymentStatus === UNPAID_STATUS || item.paymentStatus === PAID_OUTSIDE_APP || item.justUpdated
}

export const daysSinceToday = (date) => {
    const now = new Date();
    const todayNoTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const dateNoTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return Math.floor((todayNoTime - dateNoTime) / 86400000);
}

export const daysSinceDate = (date, fromDate) => {
    const fromDateNoTime = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0, 0);
    const dateNoTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    return Math.floor((fromDateNoTime - dateNoTime) / 86400000);
}


export const isCardExpired = (paymentMethod) => {
    const expiredDate = new Date(paymentMethod.expirationYear, paymentMethod.expirationMonth, 1, 0, 0, 0, 0);
    const today = new Date();
    const isExpired = expiredDate < today ? true : false;
    return isExpired;
}