import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from 'formik'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";


import {
    Box,
    Checkbox as MuiCheckbox,
    FormControlLabel as MuiFormControlLabel,
    InputAdornment,
    IconButton,
    Typography as MuiTypography,
    TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import InputMask from "react-input-mask";

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoEyeOutline as ShowPassword,
    IoEyeOffOutline as HidePassword,
} from "react-icons/io5";

/*********************** svxvy import **************************/

import * as NavigationConstants from '../../../constants/NavigationConstants';
import { signup } from "../../../store/actions/authActions";
import { isSvxvySite } from '../../../constants/NetworkConstants';
import ActionButton from '../../framework/ActionButton';

/*********************** class Styles import **************************/
const Alert = styled(MuiAlert)(spacing);
const CheckboxSpacing = styled(MuiCheckbox)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Checkbox = styled(CheckboxSpacing)`
    color: ${(props) => props.theme.palette.divider};
    margin-right: 10px;
    padding: 0;

    svg {
        width: 26px;
        height: 26px;
    }
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  span {
      margin: 0;
  }
`;

const RegisterForm = (props) => {
    const { submitText, company, onSuccess, email } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        firstNameText: Yup.string().max(255).required("Required"),
        lastNameText: company && !company.isProfileNameAliasAllowed ? Yup.string().max(255).required("Required") : Yup.string(),
        // userNameText: Yup.string().max(255).required("Required"),
        email: Yup.string().max(255).required("Required"),
        cellPhoneNumber: Yup.string().required("Required"),
        password: Yup.string().max(255)
            .required("Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase,and One Number"
            ),
        // passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], "Password does not match")
        //     .required("Confirm the Password")
    });

    return (
        <Formik
            initialValues={{
                firstNameText: "",
                lastNameText: "",
                userNameText: "",
                email: email ? email : "",
                cellPhoneNumber: "",
                referredBy: "",
                password: "",
                passwordConfirmation: "",
                readAgreement: false,
                agreeToTerms: false,
                submit: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                const displayName = (values.firstNameText + " " + values.lastNameText).trim();
                setIsSubmitting(true);
                try {
                    await dispatch(
                        signup(values.email, values.password, displayName, values.email, values.cellPhoneNumber, values.referredBy, company ? company.id : null)
                    );
                    if (onSuccess)
                        onSuccess();
                    else {
                        const startOfUrl = isSvxvySite() ? "/" + company.sanitizedName + '/' : ''
                        history.push(startOfUrl + NavigationConstants.DASHBOARD);
                    }
                } catch (error) {
                    setError(error.message);
                    setIsSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={2} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}
                    <TextField
                        name="firstNameText"
                        variant='outlined'
                        label="First Name"
                        value={values.firstNameText}
                        error={Boolean(touched.firstNameText && errors.firstNameText)}
                        fullWidth
                        helperText={touched.firstNameText && errors.firstNameText}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mb={2}
                    />
                    {(!company || (company && !company.isProfileNameAliasAllowed)) && <TextField
                        name="lastNameText"
                        variant='outlined'
                        label="Last Name"
                        value={values.lastNameText}
                        error={Boolean(touched.lastNameText && errors.lastNameText)}
                        fullWidth
                        helperText={touched.lastNameText && errors.lastNameText}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mb={2}
                    />}
                    <TextField
                        name="email"
                        variant='outlined'
                        label="Email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mb={2}
                    />
                    <InputMask
                        name="cellPhoneNumber"
                        mask="(999) 999 - 9999"
                        value={values.cellPhoneNumber}
                        onChange={e => { setFieldValue('cellPhoneNumber', e.target.value, true) }}
                        disabled={false}
                        maskChar=" "
                    >
                        {() => <TextField
                            label="Mobile Phone"
                            error={Boolean(touched.cellPhoneNumber && errors.cellPhoneNumber)}
                            helperText={touched.cellPhoneNumber && errors.cellPhoneNumber}
                            fullWidth
                            variant="outlined"
                        />}
                    </InputMask>
                    {company && !company.isRABSite &&
                        <TextField
                            name="referredBy"
                            label={"How did you hear about us?"}
                            value={values.referredBy}
                            error={Boolean(touched.referredBy && errors.referredBy)}
                            helperText={touched.referredBy && errors.referredBy}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            placeholder=""
                        />
                    }
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        variant='outlined'
                        label="Password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mb={2}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(prevState => !prevState)}
                                    // onMouseDown={setShowPassword(prevState => prevState)}
                                    >
                                        {showPassword ? <ShowPassword size={24} /> : <HidePassword size={24} />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box mx={1}>
                        <FormControlLabel
                            style={{ paddingTop: '8px', paddingLeft: '8px' }}
                            control={
                                <Checkbox
                                    checked={values.readAgreement}
                                    onChange={(val) => setFieldValue('readAgreement', !values.readAgreement)}
                                    style={{ margin: '0 10px 0 0' }}
                                    name={'readAgreement'}
                                />
                            }
                            label={<Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>
                                I have read the
                                <Typography
                                    component="a"
                                    href="https://smartcore.life/eula"
                                    target="_blank"
                                    variant="body2"
                                    color="primary"
                                    style={{ paddingLeft: '4px', fontSize: '14px', lineHeight: '18px', cursor: 'pointer', textDecoration: 'none' }}>
                                    License Agreement
                                </Typography>
                            </Typography>}
                        />
                        <FormControlLabel
                            style={{ paddingTop: '8px', paddingLeft: '8px' }}
                            control={
                                <Checkbox
                                    checked={values.agreeToTerms}
                                    onChange={(val) => setFieldValue('agreeToTerms', !values.agreeToTerms)}
                                    style={{ margin: '0 10px 0 0' }}
                                    name={'agreeToTerms'}
                                />
                            }
                            label={<Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>
                                I agree to all terms in the
                                <Typography
                                    component="a"
                                    href="https://smartcore.life/eula"
                                    target="_blank"
                                    variant="body2"
                                    color="primary"
                                    style={{ paddingLeft: '4px', fontSize: '14px', lineHeight: '18px', cursor: 'pointer', textDecoration: 'none' }}>
                                    License Agreement
                                </Typography>
                            </Typography>}
                        />
                    </Box>

                    <ActionButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        my={3}
                        disabled={!values.readAgreement || !values.agreeToTerms}
                        submitting={isSubmitting}
                    >
                        {submitText ? submitText : 'Create Account'}
                    </ActionButton>
                    {error && <Alert mb={4} severity="error">
                        {error}
                    </Alert>}
                </form>
            )}
        </Formik>

    );
}

export default RegisterForm;