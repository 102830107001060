import React from "react";
import { useSelector } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

/// svxvy imports
import { getStripePublicKey } from '../../../constants/NetworkConstants';

// constants

const StripeWrapper = props => {
    const {open, showTenantPaymentMethods} = props;

    const paymentServicePublicKey = useSelector(state => state.company.company.paymentServicePublicKey)
    const shouldLoadStripe = (!showTenantPaymentMethods || paymentServicePublicKey) ? true : false;
    const stripeKeyLoaded = showTenantPaymentMethods ? paymentServicePublicKey : getStripePublicKey();
    const stripePromise = (open || !showTenantPaymentMethods) && shouldLoadStripe ? loadStripe(stripeKeyLoaded) : null;


    return (
        <Elements stripe={stripePromise}>
            {props.children}
        </Elements>
    );
}

export default StripeWrapper;