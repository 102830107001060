import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Yup from "yup";

// import { cloneDeep } from 'lodash'

import { Formik } from 'formik';

import { RRule } from 'rrule';

import { useTheme } from '@material-ui/core/styles';
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
    Box,
    // Button as MuiButton,
    Grid as MuiGrid,
    Hidden,
    MenuItem,
    Select,
    TextField,
    FormHelperText as MuiFormHelperText,
    // Typography as MuiTypography
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardDatePicker } from "@material-ui/pickers";

// svxvy components

import SelectFromControl from '../../framework/SelectFormControl';

// https://react-icons.github.io/react-icons/icons?name=io5
import {
    IoChevronDown as ChevronDown,
    IoCalendarOutline as CalendarIcon,
} from "react-icons/io5";

// style components
// const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const FormHelperText = styled(MuiFormHelperText)`
  color:${(props) => props.theme.palette.error.main};  
`;


export const defaultRRule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: [],
    interval: 1,
    count: 10
});

const NEVER = 'Does not repeat';

const RRuleEditor = props => {
    const theme = useTheme();
    const company = useSelector(state => state.company.company)

    const { onChange } = props;
    // const [rrule, setRrule] = useState(props.initialValue && props.initialValue.length > 0 ? RRule.fromString(props.initialValue) : null)
    const rrule = props.initialValue && props.initialValue.length > 0 ? RRule.fromString(props.initialValue) : null;
    const countInitialVal = rrule && rrule.origOptions ? rrule.origOptions.count ? props.occurancesInPast > 0 ? (rrule.origOptions.count - props.occurancesInPast) : rrule.origOptions.count : '' : 10;
    const initialFormValues = {
        freq: rrule && rrule.origOptions && rrule.origOptions.freq ? rrule.origOptions.freq : NEVER,
        interval: rrule && rrule.origOptions ? rrule.origOptions.interval ? rrule.origOptions.interval : 1 : 1,
        byweekday: rrule && rrule.origOptions && rrule.origOptions.byweekday ? rrule.options.byweekday : [], // this appears to be a bug in RRULE where the correct value to check is origOptions but the correct value to read from is options
        bymonth: rrule && rrule.options && rrule.options.bymonth ? rrule.options.bymonth : [],
        count: countInitialVal,
        until: rrule && rrule.options && rrule.options.until ? rrule.options.until : null,
        end: !rrule ? END_AFTER : rrule.options.until ? END_BY : countInitialVal === '' ? FOREVER : END_AFTER
    };
    const [isFormValid, setIsFormValid] = useState(true);
    // const [end, setEnd] = useState(!rrule ? END_AFTER : rrule.options.until ? END_BY : initialFormValues.count === '' ? FOREVER : END_AFTER,)
    const ACTIVITIES_TEXT = useSelector(state => state.company.activitiesText);
    const ACTIVITY_TEXT = useSelector(state => state.company.activityText);
    const [formValues, setFormValues] = useState(initialFormValues)
    const untilValues = company.isRABSite ? until_no_foever : until;

    useEffect(() => {
        if (!formValues.freq || formValues.freq === NEVER) {
            onChange({ rrule: '', isFormValid: true })
        }
        else {
            if (formValues.end === END_BY && formValues.until instanceof Date && isNaN(formValues.until)) {
                return; // if the data is not valid then dont' try and create a new RRule
            }
            const valuesToUse = { ...formValues };
            if (valuesToUse.end === END_BY && valuesToUse.until) {
                if (valuesToUse.until instanceof Date)
                    valuesToUse.until.setHours(23, 59, 59, 99);
                valuesToUse.until = valuesToUse.until.toISOString();
            }
            else {
                delete valuesToUse.until;
            }
            delete valuesToUse.end;
            const newRule = new RRule(valuesToUse, true);
            onChange({ rrule: newRule.toString(), isFormValid: isFormValid });
        }
    }, [formValues, isFormValid, onChange])

    return (
        <React.Fragment>
            <Formik
                initialValues={initialFormValues}
                validationSchema={Yup.object().shape({
                    interval: Yup.number()
                        .typeError('Must be a number')
                        .integer("Must be a whole number.")
                        .min(1, "Must be greater than 0")
                        .max(100, "Must be less than or equal to  100")
                        .required("Required"),
                    count: Yup.mixed().when('end', {
                        is: END_AFTER,
                        then: Yup.number()
                            .typeError('Must be a number')
                            .min(1, "Must be greater than 0")
                            .required("Required after")
                    }),
                    until: Yup.mixed().when('end', {
                        is: END_BY,
                        then: Yup.date().required("Required until")
                    }),
                })
                }
            >
                {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                    isValid,
                    touched,
                    values,
                }) => {
                    setFormValues(values);
                    setIsFormValid(isValid);
                    // console.log(values, isValid, errors)
                    return (
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={4}>
                                    <SelectFromControl fullWidth labelId="select-freq-label" labelText="Repeats" >
                                        {/* <InputLabel id="select-freq-label"></InputLabel> */}
                                        <Select
                                            labelId="select-freq-label"
                                            id="freq"
                                            value={values.freq}
                                            label={"Repeats"} /// nned both this line and Input lavel
                                            onChange={(event) => setFieldValue('freq', event.target.value)}
                                            IconComponent={ChevronDown}
                                        >
                                            {frequency.map((time) => (
                                                <MenuItem
                                                    key={time.id}
                                                    value={time.id}
                                                >
                                                    {time.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </SelectFromControl>
                                </Grid>
                                {values.freq !== NEVER &&
                                    <React.Fragment>
                                        <Grid item xs={6} sm={4}>
                                            <TextField
                                                variant="outlined"
                                                name="interval"
                                                label="Every"
                                                value={values.interval}
                                                error={Boolean(touched.interval && errors.interval)}
                                                helperText={touched.interval && errors.interval}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="dense"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{frequency.find(x => values.freq === x.id).singleName}</InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                        <Hidden xsDown>
                                            <Grid sm={4}></Grid>
                                        </Hidden>
                                        {/* {values.freq === RRule.WEEKLY &&
                                            <Grid xs={12}>
                                                <Box style={{ marginBottom: '7px', padding: '0 5px' }}>
                                                    {daysOfWeek.map((day, index) => {
                                                        const isSelected = values.byweekday.includes(day.id)
                                                        return (
                                                            <Button
                                                                key={day.id}
                                                                variant={isSelected ? 'contained' : 'outlined'}
                                                                style={{
                                                                    width: 'calc(14.2857142857% - 5px)',
                                                                    minWidth: '0',
                                                                    backgroundColor: isSelected ? '' : theme.palette.background.paper,
                                                                    border: '1px solid ' + theme.palette.divider,
                                                                    padding: '12px 0 12px 0',
                                                                    fontWeight: isSelected ? '400' : '700',
                                                                }}
                                                                // mr={index === 6 ? 0 : 2}
                                                                mx={0.5}
                                                                color={isSelected ? 'primary' : 'default'}
                                                                onClick={(event) => {
                                                                    const updatedValues = cloneDeep(values.byweekday);
                                                                    if (isSelected)
                                                                        updatedValues.splice(values.byweekday.indexOf(day.id), 1)
                                                                    else
                                                                        updatedValues.push(day.id)
                                                                    setFieldValue('byweekday', updatedValues)
                                                                }}
                                                            >
                                                                {day.name}
                                                            </Button>
                                                        )
                                                    })}
                                                </Box>
                                            </Grid>
                                        } */}

                                        {/* {values.freq === RRule.WEEKLY &&
                                            <Grid item sm={4}>
                                                <SelectFromControl fullWidth labelId="select-day-label" labelText="On Days (Optional)" >
                                                    <Select
                                                        labelId="select-day-label"
                                                        id="byweekday"
                                                        multiple
                                                        value={values.byweekday}
                                                        label={"By Day of Week"} /// both this line and Input lavel
                                                        onChange={(event) => setFieldValue('byweekday', event.target.value)}
                                                        IconComponent={ChevronDown}
                                                        >
                                                        {daysOfWeek.map((day) => (
                                                            <MenuItem
                                                                key={day.id}
                                                                value={day.id}
                                                            >
                                                                {day.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </SelectFromControl>
                                            </Grid>
                                        } */}
                                        {/* {values.freq === RRule.MONTHLY &&

                                            <Grid item sm={12}>
                                                <SelectFromControl fullWidth labelId="select-month-label" labelText="In Months (Optional)" >
                                                    <Select
                                                        labelId="select-month-label"
                                                        id="bymonth"
                                                        multiple
                                                        value={values.bymonth}
                                                        label={"By Month"} /// both this line and Input lavel
                                                        onChange={(event) => setFieldValue('bymonth', event.target.value)}
                                                        IconComponent={ChevronDown}
                                                        >
                                                        {months.map((month) => (
                                                            <MenuItem
                                                                key={month.id}
                                                                value={month.id}
                                                            >
                                                                {month.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </SelectFromControl>
                                            </Grid>
                                        } */}
                                        <Grid item xs={6} sm={4}>
                                            <SelectFromControl fullWidth labelId="select-until-label" labelText="Until" >
                                                {/* <InputLabel id="select-freq-label"></InputLabel> */}
                                                <Select
                                                    labelId="select-until-label"
                                                    id="end"
                                                    value={values.end}
                                                    label={"Until"} /// nned both this line and Input lavel
                                                    onChange={(event) => {
                                                        setFieldValue('end', event.target.value, true);
                                                        switch (event.target.value) {
                                                            case FOREVER:
                                                                // console.log(FOREVER)
                                                                setFieldValue('count', '', false);
                                                                setFieldValue('until', null, false);
                                                                break;
                                                            case END_AFTER:
                                                                // console.log(END_AFTER)
                                                                setFieldValue('until', null, true);
                                                                setFieldValue('count', '', true);
                                                                break;
                                                            case END_BY:
                                                                // console.log(END_BY)
                                                                setFieldValue('count', '', true);
                                                                setFieldValue('until', null, true);
                                                                break;
                                                            default:
                                                                // console.log('default')
                                                                setFieldValue('count', '', true);
                                                                setFieldValue('until', null, true);
                                                        }
                                                        // setEnd(event.target.value);
                                                        // alert('end ' + event.target.value);
                                                    }}
                                                    IconComponent={ChevronDown}
                                                >
                                                    {untilValues.map((time) => (
                                                        <MenuItem
                                                            key={time.id}
                                                            value={time.id}
                                                        >
                                                            {time.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </SelectFromControl>
                                        </Grid>

                                        {values.end === END_AFTER &&
                                            <Grid item xs={6} sm={4} >
                                                <TextField
                                                    variant="outlined"
                                                    name="count"
                                                    label={props.occurancesInPast && props.occurancesInPast > 0 ? 'Remaining ' + ACTIVITIES_TEXT : 'Number of ' + ACTIVITIES_TEXT}
                                                    value={values.count}
                                                    error={Boolean(touched.count && errors.count)}
                                                    // helperText={touched.count && errors.count}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="dense"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{values.count > 1 ? ACTIVITIES_TEXT : ACTIVITY_TEXT}</InputAdornment>
                                                    }}
                                                />
                                                {Boolean(errors.count) &&
                                                    <Box ml={3}>
                                                        <FormHelperText>{errors.count}</FormHelperText>
                                                    </Box>
                                                }
                                            </Grid>
                                        }
                                        {values.end === END_BY &&
                                            <Grid item xs={6} sm={4}>
                                                <KeyboardDatePicker inputVariant="outlined" disablePast
                                                    name="until"
                                                    margin="normal"
                                                    error={Boolean(touched.until && errors.until)}
                                                    label="End On (mm/dd/yy)"
                                                    value={values.until}
                                                    fullWidth
                                                    onChange={date => setFieldValue('until', date, true)}
                                                    format="MM/dd/yy"
                                                    keyboardIcon={<CalendarIcon style={{ color: theme.palette.primary.main, fontSize: '24px' }} />}
                                                />
                                                {Boolean(errors.until) &&
                                                    <Box ml={3}>
                                                        <FormHelperText>Required</FormHelperText>
                                                    </Box>
                                                }
                                            </Grid>
                                        }

                                    </React.Fragment>
                                }
                            </Grid>
                            {/* <RRuleFormikDisplay rrule={rrule} values={values} initialValues={initialFormValues} /> */}
                        </Box>
                    )
                }}
            </Formik >
        </React.Fragment >

    );
};

// const daysOfWeek = [
//     {
//         id: RRule.SU.weekday,
//         name: 'Sun',
//         returnName: 'SU',
//     },
//     {
//         id: RRule.MO.weekday,
//         name: 'Mon',
//         returnName: 'MO',
//     }, {
//         id: RRule.TU.weekday,
//         name: 'Tue',
//         returnName: 'TU',
//     }, {
//         id: RRule.WE.weekday,
//         name: 'Wed',
//         returnName: 'WE',
//     }, {
//         id: RRule.TH.weekday,
//         name: 'Thu',
//         returnName: 'TR',
//     }, {
//         id: RRule.FR.weekday,
//         name: 'Fri',
//         returnName: 'FR',
//     }, {
//         id: RRule.SA.weekday,
//         name: 'Sat',
//         returnName: 'SA',
//     },
// ]

const frequency = [
    {
        id: NEVER,
        name: NEVER
    },
    {
        id: RRule.DAILY,
        name: 'Daily',
        singleName: 'Day(s)',
    }, {
        id: RRule.WEEKLY,
        name: 'Weekly',
        singleName: 'Week(s)',
    }, {
        id: RRule.MONTHLY,
        name: 'Monthly',
        singleName: 'Months(s)',
    },
    {
        id: RRule.YEARLY,
        name: 'Yearly',
        singleName: 'Year(s)',
    },
    //  {
    //     id: RRule.HOURLY,
    //     name: 'Hourly',
    //     singleName: 'Hour(s)',
    // },
]

export const months = [
    {
        id: 1,
        name: 'Jan',
        fullName: 'January'
    }, {
        id: 2,
        name: 'Feb',
        fullName: 'February'
    }, {
        id: 3,
        name: 'Mar',
        fullName: 'March'
    }, {
        id: 4,
        name: 'Apr',
        fullName: 'April'
    }, {
        id: 5,
        name: 'May',
        fullName: 'May'
    }, {
        id: 6,
        name: 'Jun',
        fullName: 'June'
    }, {
        id: 7,
        name: 'Jul',
        fullName: 'July'
    }, {
        id: 8,
        name: 'Aug',
        fullName: 'August'
    }, {
        id: 9,
        name: 'Sep',
        fullName: 'September'
    }, {
        id: 10,
        name: 'Oct',
        fullName: 'October'
    }, {
        id: 11,
        name: 'Nov',
        fullName: 'November'
    }, {
        id: 12,
        name: 'Dec',
        fullName: 'December'
    }
]

const FOREVER = 'Forever'
const END_AFTER = 'End after'
const END_BY = 'End on'

const until = [
    {
        id: FOREVER,
        name: FOREVER
    }, {
        id: END_AFTER,
        name: END_AFTER,
    }, {
        id: END_BY,
        name: END_BY,
    },
]

const until_no_foever = [
    // {
    //     id: FOREVER,
    //     name: FOREVER
    // },
    {
        id: END_AFTER,
        name: END_AFTER,
    }, {
        id: END_BY,
        name: END_BY,
    },
]

export default RRuleEditor;