import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Button as MuiButton,
    Grid,
    List,
    Tabs,
    Tab,
    TextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy imports
import MessageItem from "./MessageItem";
import { checkClaim, compareObjects } from '../../../helpers/helperFunctions';
import * as SecurityConstants from '../../../constants/SecurityConstants';
import { updateMessageStatus } from '../../../store/actions/messageActions';
import MessageModal from './MessageModal';

//UI Constants
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const MessageList = props => {
    const userClaims = useSelector(state => state.auth.claims);
    const allowMessageToAll = checkClaim(userClaims, SecurityConstants.ALLOW_MESSAGE_TO_ALL);

    const toMessages = useSelector(state => state.message.messages).sort(compareObjects('sent', 'desc'));
    const fromMessages = useSelector(state => state.message.fromMessages).sort(compareObjects('sent', 'desc'));
    const company = useSelector(state => state.company.company);
    const [toMessagesToShow, setToMessagesToShow] = useState(50);
    const [fromMessagesToShow, setFromMessagesToShow] = useState(50);
    const [value, setValue] = useState(0);
    const [search, setSearch] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [error, setError] = useState(null);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchChange = event => {
        setSearch(event.target.value);
    }
    const dispatch = useDispatch();
    const updateStatus = useCallback(async (companyId, messageId) => {
        try {
            await dispatch(updateMessageStatus(companyId, messageId, true));
        } catch (err) {
            setError(err.message);
        }
    }, [dispatch])

    const handleReadAll = () => {
        for (const message of toMessages.filter(x => !x.isRead))
            updateStatus(company.id, message.id)
    }

    return (
        <React.Fragment>
            <MessageModal open={messageModalOpen} setOpen={setMessageModalOpen} sendInAppInitialState />
            <Grid container>
                <Grid container alignContent="space-between" display="flex">
                    <Grid item xs={6}>
                        <TextField id="standard-search" fullWidth label="Search by person" type="search" variant="outlined" onChange={e => handleSearchChange(e)} />
                    </Grid>
                    <Grid item xs={2} lg={4}></Grid>
                    <Grid item xs={4} lg={2}>
                        {allowMessageToAll && <Button variant="contained" color="primary" my={1} mr={2} onClick={handleReadAll}>Mark All Read</Button>}
                        {allowMessageToAll && <Button variant="contained" color="primary" my={1} onClick={() => setMessageModalOpen(true)}>Send Message</Button>}
                    </Grid>
                    {error && <Grid item xs={12}><Alert m={2} mr={6} severity="error">{error} </Alert></Grid>}
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={value} onChange={handleChange} aria-label="In app messages">
                        <Tab label="Received Messages" {...a11yProps(0)} aria-label="Received messages" />
                        <Tab label="Sent Messages" {...a11yProps(1)} aria-label="Sent messages" />
                    </Tabs>
                </Grid>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12}>
                        <List>
                            {toMessages.filter(x => x.fromUserDisplayName.toLowerCase().includes(search.toLowerCase())).slice(0, toMessagesToShow).map((message, index) => {
                                return (
                                    <MessageItem key={message.id} id={message.id}
                                        title={message.fromUserDisplayName}
                                        description={message.messageText}
                                        image={message.fromUserImageUrl}
                                        isRead={message.read}
                                        sent={message.sent}
                                        showDate
                                        allowUpdateReadStatus
                                        accentColor={company.colors.accentColor}
                                        message={message}
                                    />
                                )
                            })}
                        </List>
                    </Grid>
                    {toMessagesToShow < toMessages.length &&
                        <Grid item xs={12}><Box display="flex" flex={1}><Button mx="auto" variant="contained" color="primary"
                            onClick={() => setToMessagesToShow(toMessagesToShow + 50)}>Show More</Button>
                        </Box></Grid>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} lg={8}>
                        <List>
                            {fromMessages.filter(x => x.toUserDisplayName.toLowerCase().includes(search.toLowerCase())).slice(0, fromMessagesToShow).map((message, index) => {
                                return (
                                    <MessageItem key={message.id} id={message.id}
                                        title={message.toUserDisplayName}
                                        description={message.messageText}
                                        image={message.toUserImageUrl}
                                        isRead={message.read}
                                        sent={message.sent}
                                        showDate
                                        accentColor={company.colors.accentColor}
                                        message={message}
                                    />
                                )
                            })}
                        </List>
                    </Grid>
                    {fromMessagesToShow < fromMessages.length &&
                        <Grid item xs={12} lg={8}><Box display="flex" flex={1}><Button mx="auto" variant="contained" color="primary"
                            onClick={() => setFromMessagesToShow(fromMessagesToShow + 50)}>Show More</Button>
                        </Box></Grid>
                    }
                </TabPanel>
            </Grid>
        </React.Fragment>
    );
}

export default MessageList;
