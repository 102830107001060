import React from 'react';
import { useSelector } from 'react-redux';

// UI Imports

import { withTheme } from "styled-components/macro";
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Grid,
} from "@material-ui/core";

// svxvy imports

import ManageSvxvySubscription from "../../../components/company/subscription/ManageSvxvySubscription";
import PurchaseList from '../../../components/company/purchases/PurchaseList';
import ManagePaymentMethods from "../../../components/company/paymentMethods/ManagePaymentMethods";

// UI Consts

const CompanyManage = (props) => {
  const company = useSelector(state => state.company.company);
  const currentUser = useSelector(state => state.auth.person);

  return (
    <React.Fragment>
      <Helmet title="Manage Subscription" />
      {company &&
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <ManageSvxvySubscription />
            <Box mt={4}>
              <PurchaseList companyId={company.id} showSCPurchases />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <ManagePaymentMethods showTenantPaymentMethods={false} userId={currentUser.id} companyId={company.id} />
          </Grid>
        </Grid>
      }
      {!company &&
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <p>Hmm.... we couldn't find this company do you want to register the company with svxvy?</p>
          </Grid>
        </Grid>
      }
    </React.Fragment >
  );
}

export default withTheme(CompanyManage);
