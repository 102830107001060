import React, { useState } from "react";
import parse from 'html-react-parser';

import * as Yup from "yup";
import { Formik } from "formik";

import { useSlate, useReadOnly } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import {
    ReactEditor,
} from 'slate-react'

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
// import { useTheme } from '@material-ui/core/styles';

import {
    Box,
    Button as MuiButton,
    Popover as MuiPopover,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";

// svxvy imports
import { isMarkActive } from "./slateHelpers";

// styled
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Popover = styled(MuiPopover)`
.MuiPopover-paper{
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-radius: 10px;
    margin-bottom: 15px;
}
`;

const linkValidationSchema = Yup.object().shape({
    url: Yup.string()
        .required("Required"),
});


const VideoButton = ({ format, children }) => {
    const editor = useSlate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'video',
    })

    const handleSubmit = (values, { setSubmitting }) => {
        insertNode(editor, values.url, children)
        // insertLink(editor, cleanURL(company.sanitizedName, values.url), values.openInNewWindow, children)
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box ml={1} mt={1}>
                <ToggleButton
                    value={format}
                    selected={isMarkActive(editor, format)}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    style={{ lineHeight: 1 }}
                >
                    {children}
                </ToggleButton>
            </Box>
            <Popover
                id='set-url-popover'
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={4}>
                    <Formik
                        initialValues={{
                            url: link && link[0] && link[0].url ? link[0].url : '',
                            openInNewWindow: link && link[0] ? link[0].openInNewWindow : false,
                        }}
                        validationSchema={linkValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            values,
                            status,
                        }) => (
                            <Box>
                                <Typography>Insert Video</Typography>
                                <TextField
                                    name="url"
                                    label="Embed Link"
                                    value={values.url}
                                    error={Boolean(touched.url && errors.url)}
                                    helperText={touched.url && errors.url}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    mb={2}
                                    placeholder="" />
                                {/* <FormGroup style={{ margin: 0 }}>
                                    <FormControlLabel control={
                                        <Switch name='openInNewWindow' onChange={(val) => setFieldValue('openInNewWindow', !values.openInNewWindow)} checked={values.openInNewWindow} />
                                    } label={'Open in new window'} />
                                </FormGroup> */}
                                <Button
                                    mt={3}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Insert
                                </Button>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

export const insertNode = (editor, url, children) => {
    const text = { text: '' }
    const voidNode = {
        type: 'video',
        url,
        children: [text],
    }
    Transforms.insertNodes(editor, voidNode)

    // Transforms.insertNodes(editor, video)
}

// export const removeNode = (editor, node) => {
//     console.log(node)
//     // Transforms.delete(editor, node);
// }

export const VideoElement = ({ attributes, children, element }) => {
    const editor = useSlate();
    const readOnly = useReadOnly();
    // const theme = useTheme();

    const { url } = element
    return (
        <div {...attributes}>
            <div contentEditable={false}>
                <div>
                    {parse(url)}
                </div>
                {!readOnly &&
                    <Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box mr={2}>
                                <Typography variant="h4">Video Instructions:</Typography>
                                <Typography variant="body2">
                                    1.	Use the full embed script for the video.
                                </Typography>
                                <Typography variant="body2">
                                    2.	Change the width to be 100%
                                </Typography>
                            </Box>
                            {/* <Button
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: theme.palette.error.main }}
                                onClick={() => removeNode(editor, element)}
                            >
                                Remove Video
                            </Button> */}
                        </Box>

                        <UrlInput
                            url={url}
                            onChange={val => {
                                const path = ReactEditor.findPath(editor, element)
                                const newProperties = {
                                    url: val,
                                };
                                Transforms.setNodes(editor, newProperties, {
                                    at: path,
                                })
                            }}
                        />

                    </Box>

                }
            </div>
            {children}
        </div>
    )
}

const UrlInput = ({ url, onChange }) => {
    const [value, setValue] = useState(url);

    return (
        <TextField
            name="url"
            label="Embed Link"
            value={value}
            onChange={e => {
                const newUrl = e.target.value
                setValue(newUrl)
                onChange(newUrl)
            }}
            fullWidth
            multiline
            lines={3}
            variant="outlined"
            my={2}
            placeholder="" />
    )
}

export default VideoButton; 