import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

// UI Imports

import { Helmet } from 'react-helmet-async';
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
    Box,
    Card as MuiCard,
    Typography as MuiTypography,
} from "@material-ui/core";

// svxvy imports
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { addAppProfile } from '../../../store/actions/peopleActions';
import { refreshLogedInUser, logout } from '../../../store/actions/authActions';
import { isSvxvySite } from '../../../constants/NetworkConstants';
import { ReactComponent as SmartCoreLogo } from "../../../Assets/Logos/smartcore-logo.svg";

import ActionButton from '../../../components/framework/ActionButton';
import CompanyLogo from '../../../components/CompanyLogo';
import RegistrationFlow from '../../../components/company/userManagement/RegisrationFlow';


// UI Consts
const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  width: 100%;
  max-width: 440px;
  border-radius: 10px;
  box-shadow: 0 0.5px 0 0 rgba(0,0,0,0.2);
  margin: 0 0 30px 0;
  padding: 22px 15px 30px 15px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 22px 30px 30px 30px;
  }
`;


const Register = props => {
    const theme = useTheme();
    const history = useHistory();
    const company = useSelector(state => state.company.company);
    const companyId = useSelector(state => state.company.companyId);
    const loggedInUser = useSelector(state => state.auth.person);

    const [isJoiningCompany, setIsJoiningCompany] = useState(false);

    /******************************** event handler  ****************************/
    const dispatch = useDispatch();

    const joinCompany = useCallback(async (companyId, appUserId) => {
        setIsJoiningCompany(true);
        try {
            await dispatch(addAppProfile(companyId, appUserId));
            await dispatch(refreshLogedInUser());
            setIsJoiningCompany(false);
            history.push(
                isSvxvySite()
                    ? "/" + company.sanitizedName + "/" + NavigationConstants.DASHBOARD
                    : NavigationConstants.DASHBOARD
            );
        } catch (err) {
            setIsJoiningCompany(false);
            alert(err.message);
        }
    }, [dispatch, company.sanitizedName, history]);


    const handleSignOut = async () => {
        await dispatch(logout());

        history.push(NavigationConstants.REGISTER);
    };

    /******************************** page effects  ****************************/

    useEffect(() => {
        if (loggedInUser != null && loggedInUser.companyId === companyId) {
            history.push(NavigationConstants.DASHBOARD);
        }
    }, [loggedInUser, history, companyId])

    return (

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Helmet title={"Login - " + (company ? company.name : "")} />
            {loggedInUser &&
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Card p={4} >
                        <CompanyLogo company={company} />
                        <Box mt={4}>
                            <Typography variant="h2" component="h2" style={{ marginBottom: '24px', fontSize: '28px', lineHeight: '30px' }}>Join with Account?</Typography>
                            <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }} mb={2}>{company.name} uses SmartCore to run its business.</Typography>
                            <Typography variant="body2" style={{ fontSize: '14px', lineHeight: '18px' }}>{loggedInUser.email} is already registered with SmartCore. Do you want to join {company.name} with this account?</Typography>
                            <ActionButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => joinCompany(company.id, loggedInUser.id)}
                                submitting={isJoiningCompany}
                                mt={4}
                            >
                                Join
                            </ActionButton>
                        </Box>
                    </Card>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth={350} mb={8}>
                        <Typography variant="body2" style={{ margin: '0 0 6px 0', fontSize: '14px', lineHeight: '18px' }}>
                            Don't want to use your existing SmartCore account?
                        </Typography>
                        <Typography
                            variant="body2"
                            color='primary'
                            style={{ margin: '0 0 46px 0', fontSize: '14px', lineHeight: '18px', cursor: 'pointer' }}
                            onClick={handleSignOut}
                        >
                            Create a New Account
                        </Typography>
                    </Box>
                </Box>
            }
            {!loggedInUser &&
                <RegistrationFlow company={company}></RegistrationFlow>
            }
            <Box component="a" href="https://smartcore.life" target="blank" style={{marginTop: '4px', display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                <Typography variant="caption" style={{
                    color: theme.palette.text.secondary,
                    margin: '0 5px 0 0',
                    display: 'inline-block',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textDecoration: 'none'
                }}>powered by</Typography>
                <SmartCoreLogo fill={theme.palette.text.secondary} style={{
                    width: '100px',
                    height: '18px'
                }} />
            </Box>
        </Box>
    )
}

export default withTheme(Register);