
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"

import moment from 'moment';
// import { useTheme } from '@material-ui/core/styles';

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useMediaQuery } from "@material-ui/core";

import {
    Box,
    Hidden,
    ListItem as MuiListItem,
    Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

//svxvy import
import { refreshSubscriptionDetails } from "../../../../store/actions/productActions";
import { ACTIVE_MEMBERSHIPS_STATUSES } from '../../../../models/Purchase';

import ProfileIcon from '../../profile/ProfileIcon';
import ProfileDetailCard from '../../profile/ProfileDetailCard';
import ActionButton from "../../../framework/ActionButton";

// UI Consts
const Alert = styled(MuiAlert)(spacing);

const Typography = styled(MuiTypography)(spacing);

const ListItem = styled(MuiListItem)`
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: 10px 0 10px 0;

    &:last-child {
        border: none;
    }
`;




const PersonSubscription = props => {
    // const theme = useTheme();
    const { person, subscription, showRefresh, priorStatus } = props;
    const companyId = useSelector(state => state.company.companyId)

    const [showProfileDetail, setShowProfileDetail] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const subscriptionRefresh = useCallback(async (purchaseId, companyId, userId, priorStatusToTrack) => {
        setError(null);
        setIsSubmitting(true);
        try {
            await dispatch(refreshSubscriptionDetails(purchaseId, companyId, userId, priorStatusToTrack ));
        } catch (err) {
            setError(err.message);
        }
        setIsSubmitting(false);
    }, [dispatch]);

    const handleSubscriptionRefresh = () => {

        subscriptionRefresh(subscription.id, companyId, subscription.appUserId, priorStatus);
    }

    return (
        <React.Fragment>
            <ListItem key={person.id}>
                {/* <Box display="flex" flex={1} flexDirection="column"> */}
                <Box
                    display="inline-block"
                    width='100%'
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowProfileDetail(true)}>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} flex={1} justifyContent='space-between' >
                        <Box display="flex" flexDirection="row" alignItems="center" flex={.8} minWidth='0px'>
                            <Hidden xsDown>
                                <ProfileIcon person={person} />
                            </Hidden>
                            <Typography ml={isMobile ? 0 : 4} style={{ fontWeight: 700, fontSize: '1.6rem', textOverflow: 'ellipsis', overflow: 'hidden' }}>{person.displayName}</Typography>
                        </Box>
                        <Box display="flex" flexDirection={'column'} alignItems={'flex-start'} justifyContent='center'>
                            <Box minWidth={70} display="flex" justifyContent="flex-end" alignItems="center">
                                {/* Show information for a subscription */}
                                {subscription.purchasedProduct && subscription.purchasedProduct.isRecurring &&
                                    <React.Fragment>
                                        {ACTIVE_MEMBERSHIPS_STATUSES.includes(subscription.status) &&
                                            <Typography>{subscription.automaticallyRenew ? 'Renews' : (subscription.expirationDate > Date.now() ? 'Finishes' : 'Finished')} {moment(subscription.expirationDate).format('ll')}</Typography>
                                        }
                                        {!ACTIVE_MEMBERSHIPS_STATUSES.includes(subscription.status) && <Typography>{subscription.status.replace('_', ' ')}{subscription.expirationDate ? ' - ' + moment(subscription.expirationDate).format('ll') : ''}</Typography>}
                                    </React.Fragment>
                                }
                                {/* Show information for a package */}
                                {subscription.purchasedProduct && subscription.purchasedProduct.units > 0 &&
                                    <Typography>{subscription.units} remaining </Typography>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {showRefresh && !subscription.refreshed &&
                        <ActionButton
                            submitting={isSubmitting}
                            variant="outlined"
                            color="secondary"
                            onClick={handleSubscriptionRefresh}
                            disabled={isSubmitting}
                            ml={2}
                        >
                            Refresh
                                </ActionButton>
                    }
                </Box>
                {subscription.refreshed &&
                    <Typography color="textSecondary" ml={4} >Refreshed</Typography>
                }
            </ListItem>
            {error && <Alert severity="error">{error}</Alert>}
            {
                showProfileDetail &&
                <ProfileDetailCard person={person} open={showProfileDetail} setOpen={setShowProfileDetail} />
            }
        </React.Fragment >

    )
}

export default PersonSubscription;