import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment';


import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { withTheme } from "styled-components/macro";

import {
    Box,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

// svxvy import
import { compareObjects } from '../../../helpers/helperFunctions';
import { fetchMessages } from '../../../store/actions/messageActions';

// constants
const Alert = styled(MuiAlert)(spacing);

const MessageAlertList = (props) => {
    const companyId = useSelector(state => state.company.companyId);

    const toMessages = useSelector(state => state.message.messages).sort(compareObjects('sent', 'desc'));
    const alertMessags = toMessages.filter( x => x.isAlert && new Date(x.alertExpires) > Date.now());
    const dispatch = useDispatch();
    const loadMessages = useCallback(async () => {
        try {
            dispatch(fetchMessages(companyId, false));
        } catch (error) {
            //   setError(error.message);
        }
    }, [companyId, dispatch]);

    useEffect(() => {
        loadMessages();
    }, [loadMessages]);

    return (
        <React.Fragment>
            {alertMessags && alertMessags.length > 0 &&
                <Box mb={4}>
                    {alertMessags.map((message, index) => {
                        return (
                            <Alert 
                                key={message.id}
                                severity="info"
                                mb={index < (alertMessags.length - 1) ? 2 : 0}>
                                {message.messageText}
                                {/* {moment(new Date(message.alertExpires)).format('lll')} */}
                            </Alert>
                        )
                    })}
                </Box>
            }
        </React.Fragment>
    );
}

export default withTheme(MessageAlertList);
